/**
 * <避難所状況詳細画面>
 * @module app/shelter/ShelterDetailPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/array',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/ShelterDetailPage.html', // テンプレート文字列
    'dojo/json',
    // 'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/ConfirmDialog',
    'idis/control/Router',
    'idis/consts/STORAGE_KEY',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/control/Locator',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/map/IdisMap',
    'dojo/on',
    'app/config',
    'leaflet',
    'idis/view/dialog/IdisDialog',
    'idis/util/storage/LocalStorage',
    'app/provide/consts/DeliveredStatus',
    'app/map/baselayer/BaseLayerPane',
    'app/model/DisasterInfo',
    './_ShelterPageBase',
    'idis/consts/USER_TYPE',
    'app/shelter/consts/CrowdedStatus',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/layout/BorderContainer',
    'dijit/layout/AccordionContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/TitlePane',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/shelter/ShelterDeleteDialog',
    'app/provide/ProvideShelterRegisterDialog',
    'app/sample/view/SampleGrid'
], function(module, array, declare, lang, locale, domStyle, template, json, InfoDialog, ConfirmDialog, Router, STORAGE_KEY,
        UserInfo, IdisRest, Requester, Locator, ACL, Loader, DialogChain, IdisMap, on, config,
        leaflet, IdisDialog, LocalStorage, DeliveredStatus, BaseLayerPane, DisasterInfo, _ShelterPageBase, USER_TYPE, CrowdedStatus) {
    /**
     * 避難所詳細画面
     * @class ShelterDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ShelterPageBase,
        /** @lends module:app/shelter/ShelterDetailPage~ShelterDetailPage# */
        {
            // テンプレート文字列
            templateString: template,
            store: null,
            innerShelter: null,

            /**
             * 災害ID
             */
            _disasterId: null,

            /**
             * 施設ID
             */
            _facilityId: null,

            /**
             * 避難所の市町村コード
             */
            _municipalityCd: null,

            /**
             * 避難所の市町村名
             */
            _municipalityName: null,

            /**
             * 避難所の市町村コード
             */
            _shelterMunicipalityCd: null,

            /**
             * 避難状況ID
             */
            _shelterId: null,

            /**
             * レイヤ名
             */
            _layerName: null,

            /**
             * ステータス
             */
            _lastStatus: null,

            /**
             * レイヤURL
             */
            _layerUrl: null,

            /**
             * 被害有無
             */
            _damageFlg: null,

            /**
             * バージョン管理
             */
            _version: null,

            /**
             * 報告連番
             */
            _seqNum: null,

            /**
             * 施設に紐づく避難所情報の削除フラグ
             */
            _deleteAllFlg: false,

            /**
             * 詳細画面初期化中フラグ
             */
            _isInitDetailSet: true,

            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'shelterId',
                    target: '/api/shelters'
                });

                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);

                this._disasterId = DisasterInfo.getDisasterId();
                if(!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }

                this._municipalityCd = Locator.getQuery().municipalityCd;
                this._shelterId = Locator.getQuery().shelterId;

                this._scenarioId = Locator.getQuery().scenarioId;
                this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
                this._eventId = Locator.getQuery().eventId;
            },
            startup: function() {
                this.inherited(arguments);

                // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
                if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    domStyle.set(this.overviewLabel, 'display', 'none');
                }

                //取消対象選択ダイアログに変更イベントを追加
                var deletePage = this.innerDeleteDialog;
                deletePage.on('send', lang.hitch(this, function(value) {
                    this._deleteAllFlg = (value.deleteTarget === '1') ? false : true;
                    this.deleteShelterButtonClick();
                }));

                // 要請情報の表示制御
                this.showHideRequestContent();
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
                this.initForm();
                this.own(this.shelterDeleteDialog);
            },

            /**
             * マップを初期化する。
             */
            initMap: function(lat, lng) {
                //中心アイコンを非表示にする
                LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');

                // マップの生成
                var latlng = [lat, lng];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend : false,
                    minZoom: 9,
                    drawControlTooltips: false}
                ).setView(latlng, 14);
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);

                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                    this.pointLat = e.latlng.lat;
                    this.pointLng = e.latlng.lng;
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                })));

                //周辺施設ボタンを表示
                this.showMarkersFlg = true;
                domStyle.set(this.showMarkersButton, 'display', '');
            },

            /**
             * マーカーを追加する。
             */
            addMark: function(lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function() {
                this.map.removeLayer(this.marker);
            },


            /**
             * フォームを初期化する。
             */
            initForm: function() {
                if(this._simulateFlg){
                    Requester.get('/data/simulation/' + this._eventId + '.json')
                    .then(lang.hitch(this, function(item) {
                        var data = item.shelterPostForm;
                        data.subData = item.shelterConversionForm;
                        this.initFormValue(data);
                    }), function(error) {
                        console.error('イベントの情報取得でエラー発生', error);
                    });
                } else {
                    Requester.get('/api/shelters/' + this._shelterId).then(lang.hitch(this, function(data) {
                        this.initFormValue(data);
                    }));
                }
            },

            /**
            * 詳細情報を設定する
            */
                initFormValue: function(data) {
                    var item = data;
                    if(item.status === '0'){
                        item.shelterStartTime = null;
                    }
                    // 定員超過は開設済みとして扱う
                    if(item.status === '2'){
                        item.status = '1';
                    }

                    this.form.set('value', item);
                    // 報告日時
                    this.reportTime.set('value',item.reportTime);

                    // 開設日時 (開設準備中だった場合、開設日時を入力しない＆「準備中」ステータス選択肢を表示)
                    this._lastStatus = item.status;
                    if(item.status === '0'){
                        this.shelterStartTime.set('value',item.shelterStartTime);
                        domStyle.set(this.inPreparationArea, 'display', '');
                        // 「準備中」である場合は、次報に「閉鎖」を選べない（開設を経ずに閉鎖はできない）
                        domStyle.set(this.closeArea, 'display', 'none');
                        // 準備中は混雑状況表示しない
                        domStyle.set(this.crowdedStatusArea, 'display', 'none');
                    } else {
                        // 準備中以外のステータスから「開設準備中」には戻せない。
                        domStyle.set(this.inPreparationArea, 'display', 'none');
                    }

                    // 閉鎖日時
                    this.shelterEndTime.set('value',item.shelterEndTime);
                    // 受取可能時間(開始)
                    this.receivableTimeFrom.set('value',item.receivableTimeFrom);
                    // 受取可能時間(終了)
                    this.receivableTimeTo.set('value',item.receivableTimeTo);
                    // 避難所名
                    if(this._simulateFlg){
                        this.facilityName.innerHTML = item.facilityId + ' ' + item.subData.facilityName;
                        this._facilityName = item.subData.facilityName;
                    } else {
                        this.facilityName.innerHTML = (item.civilProtectionEvacShFlg === '1' ? '【避難施設(国民保護)】' : '' ) +
                                                        ' ' + (item.shelterAdmId ? item.shelterAdmId : '' ) +
                                                        ' ' + item.facilityName;
                    }
                    // 収容人数
                    if (item.capacity){
                        this.capacity.innerHTML = item.capacity+ '人';
                        this._capacity = item.capacity;
                    } else {
                        this.capacity.innerHTML = '未設定';
                    }
                    //添付ファイル
                    this.attachFileList = [];
                    // プレビューを初期化
                    this.clearPreview();

                    this.displayTimeAreasByStatus(item.status);

                    var shelterCategory = [];

                    // 指定緊急避難場所
                    if(item.edesignatedEvacShFlg==='1'){
                        //this.floodFlg.set('checked',true);
                        shelterCategory.push('指定緊急');
                    }

                    // 指定避難所
                    if(item.designatedEvacShFlg==='1'){
                        //this.floodFlg.set('checked',true);
                        shelterCategory.push('指定');
                    }

                    // 福祉避難所
                    if(item.welfareEvacShFlg==='1'){
                        //this.floodFlg.set('checked',true);
                        shelterCategory.push('福祉');
                    }

                    // 臨時避難所
                    if(item.temporaryEvacShFlg==='1'){
                        //this.floodFlg.set('checked',true);
                        shelterCategory.push('臨時');
                    }

                    // 避難施設（国民保護）
                    if(item.civilProtectionEvacShFlg==='1'){
                        //this.floodFlg.set('checked',true);
                        shelterCategory.push('国民保護');
                    }

                    // その他避難所
                    if(item.otherEvacShFlg==='1'){
                        //this.floodFlg.set('checked',true);
                        shelterCategory.push('その他');
                    }

                    if (shelterCategory.length > 0) {
                        this.shelterType.innerHTML = shelterCategory.join(', ') || '&nbsp;';
                    } else {
                        this.shelterType.innerHTML = '未設定';
                    }

                    this._shelterMunicipalityCd = item.municipalityCd;
                    this._shelterId = item.shelterId;
                    this._facilityId = item.facilityId;
                    this._municipalityCd = item.municipalityCd;
                    this._municipalityName = item.municipalityName;
                    this._version = item.version;
                    this._layerName = item.layerName;
                    this._layerUrl = item.layerUrl;
                    this._damageFlg = item.damageFlg;
                    this._lastShelters = item.lastShelters; // 過去報
                    // seqNumの降順に並んでいるので、リストの先頭が直前報
                    this._lastShelter = this._lastShelters.length > 0 ? this._lastShelters[0] : null;
                    this._initialStatus = item.status;
                    this._seqNum = item.seqNum;
                    if(data.latitude && data.longitude) {
                        // マップを初期化
                        this.initMap(data.latitude,data.longitude);
                    } else {
                    //this.initMap();
                    this.initMap(config.map.latitude,config.map.longitude);
                    }

                    // 添付ファイルを設定
                    if(item.attachFiles) {
                        for(var i=0; i<item.attachFiles.length; i++) {
                            this.attachFileList.push(item.attachFiles[i]);
                            this.showPreview(item.attachFiles[i], false);
                        }
                    }
                    this._activeFlg = item.activeFlg;
                    // item.municipalityCdを取得した後に
                    // setButtonStatusを呼ぶ必要がある。
                    this.setButtonStatus(item);

                    Requester.get('/api/facility/' + this._facilityId, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(data) {
                        this._municipalityCd = data.districtDto.municipalityCd;
                        //「周辺被害表示」ボタンがアクティブになっていたら、一旦閉じる
                        if(this.showMarkersFlg){
                            this.showShelterMarkers();
                        }
                        //周辺被害を再取得
                        var param = '?municipalityCd=' + this._municipalityCd;
                        //param += '&activeFlg=1&hasNoParentFlg=0';
                        Requester.get('/api/facility' + param, {
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(lang.hitch(this, function(data) {
                            Requester.get('/api/shelters', {
                                query: {
                                    disasterId: this._disasterId,
                                    municipalityCd: this._municipalityCd
                                }
                            }).then(lang.hitch(this, function(shelterData) {
                                this.enabledAroundShelterButton = true;
                                var items = data.items;
                                this.setShelterMarkers(items, shelterData.items, this._facilityId);
                                //周辺被害はデフォルト表示
                                this.showShelterMarkers();
                            }), lang.hitch(this, function(error) {
                                this.chain.infoError(error);
                            }));
                        }), lang.hitch(this, function(error) {
                            this.chain.infoError(error);
                        }));
                        //「周辺被害表示」ボタンを表示
                        domStyle.set(this.showMarkersButton, 'display', '');
                    }), function(error) {
                        console.log(error);
                    });
                },

            /**
            * 更新・削除ボタンの活性/非活性を初期化する。
            */
            setButtonStatus: function(data){
                // ユーザーの市町村情報をセット
                var _userMunicipalityCds = UserInfo.getMunicipalityCds();
                var cityMunicFlg = false;
                _userMunicipalityCds.forEach(function(value){
                    if (config.municInfo.cityDistrictMunicCds.indexOf(value) !== -1) {
                        cityMunicFlg = true;
                    }
                });
                if (cityMunicFlg) {
                    _userMunicipalityCds.push(config.municInfo.cityMunicCd);
                }

                // 災害IDが選択中の災害と不一致の場合、更新不可エラーダイアログを表示
                // （監視ページの別災害レイヤーのポップアップから遷移時発生）
                if (Number(data.disasterId) !== Number(this._disasterId)) {
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.correctButton.domNode, 'display', 'none');
                    domStyle.set(this.updateButton.domNode, 'display', 'none');
                    this.chain.info('現在選択中の災害に紐づかない情報のため、編集・更新ができません。<br>' +
                        'この情報を編集・更新する場合、監視ページに戻り、災害名を変更してください。', 'エラー');
                    return;
                }

                // 避難所がユーザの管理対象市町でない場合は、各種ボタンを非表示
                if(_userMunicipalityCds.indexOf(data.municipalityCd) === -1){
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.correctButton.domNode, 'display', 'none');
                    domStyle.set(this.updateButton.domNode, 'display', 'none');
                }

                // 最新報でない場合は「更新」「訂正」ボタンを非表示にする
                if(data.activeFlg !== '1'){
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.updateButton.domNode, 'display', 'none');
                }

                // 災害シミュレーションの場合は「削除」「続報」ボタンを非表示にする
                if(this._simulateFlg) {
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.correctButton.domNode, 'display', '');
                    domStyle.set(this.updateButton.domNode, 'display', 'none');
                }
            },

            //パンくずリストのボタンを押下したときの挙動
            onShelterAdminPageLinkClick: function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('shelter/admin');
            },
            onShelterListPageLinkClick: function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('shelter', {
                    municipalityCd : this._municipalityCd
                });
            },

            /**
            * 避難所状況を訂正する。
            */
            onCorrectButtonClick: function() {
                var status = this.form.get('value').status;
                if(this._activeFlg === '0' && this._lastStatus !== status) {
                    if (!this.infoDialog) {

                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'ステータスは訂正できません。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                } else {
                    this.onSubmit(false);
                }
                return false;
            },

            /**
            * 避難所状況（続報）を登録する。
            */
            onFollowUpButtonClick: function() {
                this.onSubmit(true);
            },

            validateForm: function(){
                var status = this.form.get('value').status;

                if(!this.reportTime.validate() || !this.reportTime._date.displayedValue ||
                        !this.reportTime._time.displayedValue) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '報告日時が選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                } else if(status !== '0' && (
                    !this.shelterStartTime.validate() || !this.shelterStartTime._date.displayedValue ||
                        !this.shelterStartTime._time.displayedValue)) {
                    if (!this.infoDialog) {

                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '開設日時が入力されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                //閉鎖日時が入力されている場合、その入力値が不正ならエラー
            } else if (status === '9' && (
                !this.shelterEndTime.validate() || !this.shelterEndTime._date.displayedValue ||
                    !this.shelterEndTime._time.displayedValue)){
                if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '閉鎖日時が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            } else if((this.shelterEndTime._date.displayedValue || this.shelterEndTime._time.displayedValue) &&
                    !this.shelterEndTime.validate()) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '閉鎖日時の入力値が不正です'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                //閉鎖時刻が開設日時より前に設定されている場合、エラー
                else if(status === '9' &&
                    (this.shelterEndTime._date.displayedValue && this.shelterEndTime._time.displayedValue &&
                    //閉鎖日が開設日より前の場合
                    (this.shelterEndTime._date.displayedValue < this.shelterStartTime._date.displayedValue ||
                    //または閉鎖日と開設日が同日かつ、時間が前の場合
                    (this.shelterEndTime._date.displayedValue === this.shelterStartTime._date.displayedValue &&
                    this.shelterEndTime._time.displayedValue < this.shelterStartTime._time.displayedValue)))){
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '閉鎖日時が開設日時の前に設定されています'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                }
                // 要請有無がありの場合に要請内容を入力していない場合、エラー
                else if(this.requestFlgActive.checked && !this.requestContent.value) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '要請内容を入力してください'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                // 開設済みの場合に混雑状況を入力していない場合、エラー
                else if(status === '1' && !this.crowdedStatus.value) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '混雑状況を入力してください'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                else if(!this.form.isValid()) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '無効な値が入力されています'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog=null;
                    return false;
                }
                return true;
            },


            // 更新ダイアログを表示する
            onSubmit: function(followupFlg) {
                if(this.validateForm()){
                    var sendData = this.form.get('value');

                    // 開設準備中の場合、模擬的に開設時間をセットする。
                    if(sendData.status === '0'){
                        sendData.shelterStartTime = new Date().getTime();
                    }

                    // 閉鎖以外のの場合、閉鎖時間を削除する（一度削除を選んで時間を入力してから他のステータスに変えた時の対策）
                    if(sendData.status !== '9'){
                        delete sendData.shelterEndTime;
                    }

                    //添付ファイルIDをセット
                    if(this.attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i=0; i<this.attachFileList.length; i++) {
                            attachFileIds.push(this.attachFileList[i].shelterAtcFileId);
                        }
                        sendData.attachFileIds = attachFileIds.join(',');
                    } else {
                        sendData.attachFileIds = '';
                    }
                    delete sendData.shelterAtcFile;
                    sendData.facilityId = this._facilityId;
                    sendData.disasterId = this._disasterId;
                    sendData.damageFlg = this._damageFlg;

                    this._sendData = sendData;

                    if(followupFlg) {
                        // 続報登録
                        this.showShelterDialog(sendData, false, false);
                    } else {
                        // 訂正
                        if(this._activeFlg === '1' && sendData.status !== '0'){
                            this.showShelterDialog(sendData, true, false);
                        } else {
                            this.correctShelter(null);
                        }
                    }
                }
            },

            followUpShelter: function(shelterInfo){
                var message = '更新します。よろしいですか？';
                if(shelterInfo && this._simulateFlg !== 'true'){
                    message = '<h1 style="text-align:center;background-color: orange;">更新を実施すると情報が外部に発信されます。</h1>';
                    message += '更新及びLアラート等への配信を行います。\nよろしいですか？';
                }
                this.chain.confirm(message, lang.hitch(this, function(chain)  {
                    delete this._sendData.version;
                    delete this._sendData.layerName;
                    delete this._sendData.layerUrl;
                    if(shelterInfo){
                        this._sendData.twitterFlg = shelterInfo.twitterFlg;
                        this._sendData.prefMailFlg = shelterInfo.prefMailFlg;
                        this._sendData.twitterMunicFlg = shelterInfo.twitterMunicFlg;
                        this._sendData.yahooFlg = shelterInfo.yahooFlg;;
                    }

                    if(this._activeFlg === '1' && shelterInfo){
                        this._sendData.sendPostForm = shelterInfo.lalertForm;
                    }

                    var jsonStr = json.stringify(this._sendData);
                    var promise = Requester.post('/api/shelters',{
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(data){
                        this._shelterId = data.shelterId;

                        chain.info('更新が完了しました。', '完了', lang.hitch(this, function(){
                            if(this._activeFlg === '1' && shelterInfo){
                                var dialog = this.shelterRegisterDialog.getChildren()[0];
                                dialog.closeDialog();
                            }
                            chain.hide();
                            Router.moveTo('shelter', {
                                municipalityCd: this._municipalityCd
                            });
                        }));

                    }), function(error){
                        // 失敗時
                        console.error(error);
                        chain.infoError(error);
                    });
                    Loader.wait(promise);
                }));
            },

            correctShelter: function(shelterInfo){
                if(shelterInfo){
                    this._sendData.twitterFlg = shelterInfo.twitterFlg;
                    this._sendData.prefMailFlg = shelterInfo.prefMailFlg;
                    this._sendData.twitterMunicFlg = shelterInfo.twitterMunicFlg;
                    this._sendData.yahooFlg = shelterInfo.yahooFlg;
                }

                var sendData = this._sendData;

                sendData.shelterId = this._shelterId;
                sendData.version = this._version;
                sendData.layerName = this._layerName;
                sendData.layerUrl = this._layerUrl;

                if(this._activeFlg === '1' && shelterInfo){
                    sendData.sendPostForm = shelterInfo.lalertForm;
                }

                var jsonStr = json.stringify(sendData);

                var message = '訂正します。よろしいですか？';
                if(shelterInfo && this._simulateFlg !== 'true'){
                    message = '<h1 style="text-align:center;background-color: orange;">訂正を実施すると情報が外部に発信されます。</h1>';
                    message += '訂正及びLアラートへの配信を行います。\nよろしいですか？';
                }
                // 災害シミュレーションから遷移してきた場合
                if(this._simulateFlg){
                    switch (this.form.value.status) {
                        case '1':
                            if(this.form.value.evaqueeNum > this.form.value.capacity) {
                                this._statusName = '定員超過';
                            } else {
                                this._statusName = '開設済み';
                            }
                            break;
                        case '9':
                            this._statusName = '閉鎖';
                            break;
                        default:
                    }
                    var subSendData = {};
                    subSendData.statusName = this._statusName;
                    subSendData.facilityName = this._facilityName;
                    jsonStr = json.stringify({
                        shelterPostForm: this._sendData,
                        shelterConversionForm: subSendData,
                        scenarioId: this._scenarioId,
                        eventId: this._eventId});

                    this.chain.confirm(message, lang.hitch(this, function(chain){
                        var promise = Requester.put('/api/simulations/event/' + this._eventId,{
                            data: jsonStr,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(lang.hitch(this, function(data){
                            this._shelterId = data.shelterId;

                            chain.info('避難所イベントの訂正が完了しました。', '完了', lang.hitch(this, function(){
                                chain.hide();
                                if(this._activeFlg === '1' && shelterInfo){
                                    var dialog = this.shelterRegisterDialog.getChildren()[0];
                                    dialog.closeDialog();
                                }
                                Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                            }));
                        }), function(error){
                            // 失敗時
                            console.error(error);
                            chain.infoError(error);
                        });
                        Loader.wait(promise);
                    }));
                } else {
                    this.chain.confirm(message, lang.hitch(this, function(chain){
                        var promise = Requester.put('/api/shelters/' + this._shelterId,{
                            data: jsonStr,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(lang.hitch(this, function(data){
                            this._shelterId = data.shelterId;

                            chain.info('訂正が完了しました。', '完了', lang.hitch(this, function(){
                                chain.hide();
                                if(this._activeFlg === '1' && shelterInfo){
                                    var dialog = this.shelterRegisterDialog.getChildren()[0];
                                    dialog.closeDialog();
                                }
                                Router.moveTo('shelter', {
                                    municipalityCd: this._municipalityCd
                                });
                            }));

                        }), function(error){
                            // 失敗時
                            console.error(error);
                            chain.infoError(error);
                        });
                        Loader.wait(promise);
                    }));
                }
            },

            showDeleteDialog: function() {
                // 削除対象を選択できるのは、最新報かつ第2報以上のときとする
                if(this._activeFlg === '1' && this._seqNum > 1){
                    this.shelterDeleteDialog.show();
                }else{
                    this.deleteShelterButtonClick();
                }

            },

            /**
            * 背景地図ダイアログを表示する。
            */
            showBaseLayerDialog: function() {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({map: this.map})
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            },

            /**
            * トイレに関する詳細項目を表示する。
            */
            showToiletStatus: function() {
                var toiletStatus = this.form.get('value').toiletDmgFlg;
                if(toiletStatus === '1'){
                    domStyle.set(this.toiletTableRow, 'display', 'table-Row');
                } else {
                    domStyle.set(this.toiletTableRow, 'display', 'none');
                }
            },

            //キャンセルボタンを押下したら前の画面に戻る
            cancelButtonClick: function() {
                this.leave();
            },

            /**
            * 配信情報(開設避難所情報)ダイアログを表示する。
            */
            showShelterDialog: function(obj, isCorrect, isCancel) {
                Requester.get('/api/facility/' + obj.facilityId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this,function(item) {
                    //非公開の避難所が更新/訂正/取消されていた場合、配信ダイアログを挟まずに避難所を登録する
                    if(!item.publicFlg || item.publicFlg !== '1'){
                        if(!isCorrect && !isCancel){
                            return this.followUpShelter(null);
                        } else if (isCorrect){
                            return this.correctShelter(null);
                        } else if(isCancel){
                            return this.deleteShelter(null);
                        }
                    }

                    // 未配信の訂正・取消の場合も、外部連携は不要。
                    if (this._deliveredStatus===DeliveredStatus.UN_DELIVERED) {
                        if (isCorrect){
                            return this.correctShelter(null);
                        } else if(isCancel){
                            return this.deleteShelter(null);
                        }
                    }

                    // 「開設準備中」の避難所を取消する場合
                    if(this._lastStatus === '0' && isCancel){

                        // 開設準備中の前に開設や閉鎖をしていない場合は外部連携は不要。
                        var openHistory = array.filter(this._lastShelters, function(item){
                            return item.status === '1' || item.status === '9';
                        });
                        if (openHistory.length === 0) {
                            return this.deleteShelter(null);
                        }

                    // 「開設準備中」の避難所を閉鎖する場合も、外部連携は不要。
                    } else if (this._lastStatus === '0' && obj.shelterEndTime) {
                        if(!isCorrect && !isCancel){
                            return this.followUpShelter(null);
                        } else if (isCorrect){
                            return this.correctShelter(null);
                        } else if(isCancel){
                            return this.deleteShelter(null);
                        }
                    }

                    // 「開設準備中」の避難所を「開設準備中」のまま続報登録する場合も、外部連携は不要。
                    if (this._lastStatus === '0' && obj.status === '0') {
                        if(!isCorrect && !isCancel){ // 続報登録
                            return this.followUpShelter(null);
                        }
                    }

                    if (isCancel && !this._deleteAllFlg && this._lastShelter){
                        // 前報があり、「最新報のみを取消」を選んだ場合、
                        // Lアラートに連携するのは前報の内容なので、
                        // objを前報で置き換える
                        obj =  this._lastShelter;
                    }

                    // 日付の形式を、Lアラート用にフォーマットする
                    var shelterStartTime = this.formatDate(obj.shelterStartTime);
                    var shelterEndTime = null;
                    if(obj.shelterEndTime){
                        shelterEndTime = this.formatDate(obj.shelterEndTime);
                    }

                    var cancelOpenInfo = false;

                    var sort = '開設';
                    var shelterStartOrEndTime = shelterStartTime;
                    // 閉鎖日時がない、または開設準備中だった場合は「閉鎖」扱いとする
                    if (obj.shelterEndTime && obj.shelterEndTime !== null) {
                        sort = '閉鎖';
                        shelterStartOrEndTime = shelterEndTime;
                    } else if (obj.status === '0' && obj.seqNum !== 1){
                        // ここまで来た状態でstatusが開設準備中であるケース＝開設情報を「最新報のみ取消」したケースのみ
                        // かつ、status=開設準備中であり第一報でないなら、一度閉鎖した後に開設準備中した場合である。
                        sort = '閉鎖';
                        // 厳密には「閉鎖」のアクションではないが、便宜上報告日時を閉鎖日時とする
                        shelterStartOrEndTime = this.formatDate(obj.reportTime);
                    } else if (obj.status === '0' && obj.seqNum === 1){
                        // ここまで来た状態でstatusが開設準備中であるケース＝開設情報を「最新報のみ取消」したケースのみ
                        // かつ、status=開設準備中であり第一報なら、開設した情報自体を取り消すので、単純な「取消」と同じ扱い。
                        // 後でisRemovingフラグを立てるため、ここは別の変数で管理しておく
                        cancelOpenInfo = true;
                    }

                    obj.evaqueeNum = isNaN(obj.evaqueeNum) ? 0 : Number(obj.evaqueeNum);
                    obj.evaqueeActNum = isNaN(obj.evaqueeActNum) ? 0 : Number(obj.evaqueeActNum);
                    obj.evacHouseholdNum = isNaN(obj.evacHouseholdNum) ? 0 : Number(obj.evacHouseholdNum);
                    obj.evacHouseholdActNum = isNaN(obj.evacHouseholdActNum) ? 0 : Number(obj.evacHouseholdActNum);
                    obj.carEvaqueeNum = isNaN(obj.carEvaqueeNum) ? 0 : Number(obj.carEvaqueeNum);

                    var evacShelterTypeOfDisaster = '';
                    var evacShelterTypeOfDisasterList = [];
                    if (item.floodShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('洪水');
                    }
                    if (item.sedimentDisasterShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('崖崩れ、土石流及び地滑り');
                    }
                    if (item.stormSurgeShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('高潮');
                    }
                    if (item.earthquakeShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('地震');
                    }
                    if (item.tsunamiShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('津波');
                    }
                    if (item.fireShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('大規模な火事');
                    }
                    if (item.landsideWaterShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('内水氾濫');
                    }
                    if (item.volcanoShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('火山現象');
                    }
                    if (evacShelterTypeOfDisasterList && evacShelterTypeOfDisasterList.length !== 0) {
                        evacShelterTypeOfDisaster = evacShelterTypeOfDisasterList.join(',');
                    }

                    var evacSuitableShelter = '指定なし';
                    if(item.edesignatedEvacShFlg === '1' && item.designatedEvacShFlg === '1' ){
                        evacSuitableShelter = '指定緊急避難場所兼避難所';
                    } else if(item.edesignatedEvacShFlg === '1'){
                        evacSuitableShelter = '指定緊急避難場所';
                    } else if (item.designatedEvacShFlg === '1' ){
                        evacSuitableShelter = '指定避難所';
                    } else {
                        evacSuitableShelter = '指定なし';
                    }

                    var evaqueeData = [{
                        facilityId: obj.facilityId,
                        evacShelterName: item.facilityName,
                        evacShelterNameKana: item.facilityNameKana,
                        evacShelterType: item.facilityTypeName,
                        evacShelterSort: sort,
                        evacShelterLatitude: item.latitude,
                        evacShelterLongitude: item.longitude,
                        address: item.address,
                        capacity: item.capacity,
                        evaqueeNum: obj.evaqueeNum ? obj.evaqueeNum : 0,
                        evaqueeActNum: obj.evaqueeActNum ? obj.evaqueeActNum : 0,
                        evacHouseholdNum: obj.evacHouseholdNum ? obj.evacHouseholdNum : 0,
                        evacHouseholdActNum: obj.evacHouseholdActNum ? obj.evacHouseholdActNum : 0,
                        carEvaqueeNum: obj.carEvaqueeNum ? obj.carEvaqueeNum : 0,
                        evacShelterDatetime: shelterStartOrEndTime,
                        evacTopical: obj.comment ? obj.comment : '',
                        evacSuitableShelter: evacSuitableShelter,
                        evacShelterTypeOfDisaster:evacShelterTypeOfDisaster,
                        crowdedStatus: obj.crowdedStatus,
                        spaceStatus: CrowdedStatus.getLabel(obj.crowdedStatus)
                    }];

                    var isRemoving = false;
                    if ( isCancel && ( !this._lastShelter || this._deleteAllFlg )){
                        // 取り消しかつ前報がない
                        // または取消かつ全報を取り消す場合
                        isRemoving = true;
                    } else if (cancelOpenInfo){
                        // 開設準備中→開設した後、開設の情報を「最新報のみ取消」した場合
                        isRemoving = true;
                    }

                    var args = {};

                    //  Lアラート更新種別をセット。
                    // ひとまずは「更新/訂正(=02)」を入れておくが、
                    // isCancel=trueの場合は、条件に応じてあとでinitDialog内で内容が変更される。
                    var distributionType = '02';

                    // 「開設準備中」→「開設」の時、および「（任意のステータス）」→「閉鎖」になった時は、防災情報メールを送る。
                    var isPrefMailDefault = false;
                    var shelterStartName = '';
                    var shelterEndName = '';

                    // 準備中(閉鎖扱い)から準備中の場合ステータスが変わらないため配信対象外
                    // ※外部連携ダイアログ自体表示されないはずだが一応設定
                    if(obj.status === '0' && this._initialStatus === '9') {
                        isPrefMailDefault = true;
                    } else if(obj.status !== '0' && obj.status !== '9' && !isCorrect) { // 開設の条件を選択、訂正の場合は配信しない
                        // 元が開設扱いではない場合、開設に切り替わることになるので配信対象
                        if(this._initialStatus !== '1'&& this._initialStatus !== '2' && this._initialStatus !== '3') {
                            isPrefMailDefault = true;
                            // 施設名は新規開設の時のみ表示する
                            shelterStartName = item.facilityName;
                        }
                    } else if (obj.status === '9') {
                        // 閉鎖の場合は閉鎖する避難所として外部連携本文に入れる
                        shelterEndName = item.facilityName;
                    }

                    args.shelterArgs = {
                        sendCategory : '02',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        distributionType: distributionType,
                        isPrefMailDefault: isPrefMailDefault,
                        municipalityCd: this._municipalityCd,
                        seqNum: this._seqNum
                    };
                    args.facilityId = obj.facilityId;
                    args.evacOrderArgs = null;
                    args.newShelterNames = shelterStartName;
                    args.endShelterNames = shelterEndName;
                    args.municipalityName = this._municipalityName;
                    args.reporterName = this.reporterName.value;
                    args.reportTime = this.reportTime._date.displayedValue + ' ' +
                                            this.reportTime._time.displayedValue;

                    // ダイアログの最初の子要素が登録画面
                    var dialog = this.shelterRegisterDialog.getChildren()[0];

                    // ダイアログの画面を初期化する。
                    Loader.wait(dialog.initDialog(args, isCorrect, isCancel, isRemoving))
                    .then(lang.hitch(this, function(){
                        // ダイアログのsendイベントを受け取れるようにする
                        dialog.on('send', lang.hitch(this, function(evt) {
                            if(!isCorrect && !isCancel){
                                return this.followUpShelter(evt.value);
                            } else if (isCorrect){
                                return this.correctShelter(evt.value);
                            } else if(isCancel){
                                return this.deleteShelter(evt.value);
                            }
                        }));
    
                        // ダイアログを破棄できないように設定する。
                        this.shelterRegisterDialog.set('closable', false);
    
                        // ダイアログを表示する。
                        this.shelterRegisterDialog.show();
                    }));
                }));
            },

            deleteShelterButtonClick: function() {
                Requester.get('/api/shelters/' + this._shelterId).then(lang.hitch(this, function(data) {
                    if (data.shelterStartTime !== null) {
                        data.shelterStartTime = new Date(data.shelterStartTime);
                    }
                    if (data.shelterEndTime !== null) {
                        data.shelterEndTime = new Date(data.shelterEndTime);
                    }

                    if(this._activeFlg === '1'){
                        this.shelterDeleteDialog.hide();
                        this.showShelterDialog(data, false, true);
                    } else {
                        this.deleteShelter(null);
                    }
                }), lang.hitch(this, function(err) {
                    // 失敗時
                    if(err.response.data && typeof(err.response.data) === 'string'){
                        err.response.data = json.parse(err.response.data);
                    }
                    this.chain.infoError(err);
                }));
            },

            deleteShelter: function(shelterInfo){
                var message = '取消します。よろしいですか？';
                if(shelterInfo){
                    message = '<h1 style="text-align:center;background-color: orange;">取消を実施すると情報が外部に発信されます。</h1>';
                    message += '取消及びLアラートへの配信を行います。\nよろしいですか？';
                }

                var sendData = {};
                if(this._activeFlg === '1' && shelterInfo){
                    sendData.sendPostForm = shelterInfo.lalertForm;
                }
                sendData.facilityId = this._facilityId;
                sendData.version = this._version;
                var jsonStr = json.stringify(sendData);
                var delUrl = (this._deleteAllFlg === true) ? '/api/shelters/delAll/' : '/api/shelters/del/';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.put(delUrl + this._shelterId,{
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function() {

                        chain.info('取消が完了しました。', '完了', lang.hitch(this, function(){
                            if(this._activeFlg === '1' && shelterInfo){
                                var dialog = this.shelterRegisterDialog.getChildren()[0];
                                dialog.closeDialog();
                            }
                            chain.hide();
                            Router.moveTo('shelter', {
                                municipalityCd: this._municipalityCd
                            });
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                    Loader.wait(promise);
                });
            }
        });
});

/**
 * 招集メール配信ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoSendAutoMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/text!./templates/ConvoSendAutoMailDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button',
    'app/convocation/view/mail/ConvoAutoTempInsertDialog',
    'app/convocation/view/mail/ConvoSendAutoMailGrid'
], function(module, declare, lang, array, domStyle, template, IdisRest, _PageBase,
    Loader, Requester, DialogChain, InfoDialog) {
    /**
     * 招集メール配信ダイアログ。
     * @class 招集メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoSendAutoMailDialog~ConvoSendAutoMailDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // 招集配信設定ID
            distSettingId: null,

            // メール種別
            convoMailType: null,

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'settingId',
                    target: '/api/convoMails/conditions'
                });
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.templateInsertDialog);
                this.initAutoTempInsertGrid();
            },

            /**
             * 招集メール配信設定一覧用グリッドの初期化
             */
            initAutoTempInsertGrid: function() {
                var page = this.templateInsertDialog.getChildren()[0];
                var filter = new this.store.Filter();
                filter = filter.eq('count', -1);
                filter = filter.eq('convoMailType', '1'); // 招集メールの配信設定のみを対象とする
                page.sendAutoMailGrid.set('collection', this.store.filter(filter));
                // グリッドの挿入ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'insert'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.sendAutoMailGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
                    this.distSettingId = evt.item.settingId;
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    var promise = Requester.get('/api/convoMails/conditions/' + this.distSettingId).
                    then(lang.hitch(this, function(data) {
                        // 配信設定の件名と本文をformに設定する
                        this.form.set('value', data);
                        // メール種別の退避
                        this.convoMailType = data.convoMailType;
                        Requester.get('/api/convoMails/conditions/groups/' + this.distSettingId).
                        then(lang.hitch(this, function(data) {
                            // 招集グループを設定
                            var groupIds = '';
                            array.forEach(data.items, function(item, i) {
                                if (i > 0) {
                                    groupIds += ',';
                                }
                                groupIds += item.convoGroup;
                            });
                            this.groupId.set('value', groupIds);
                        }), lang.hitch(this, function(err) {
                            this.chain.infoError(err);
                        }));
                        this.displayItems(data);
                    }), lang.hitch(this, function(err) {
                        this.chain.infoError(err);
                    }));
                    //ローダーの表示
                    Loader.wait(promise);
                    this.templateInsertDialog.hide();
                }));
            },

            /**
             * ページの初期化
             */
            initPage: function() {
                this.form.reset();
                this.distSettingId = null;
            },

            /**
             * 送信ボタンが押下された際の処理。
             */
            onSubmit: function() {
                try {
                    // 入力チェックを通過した場合にのみ処理を実施
                    if (this.validate()) {
                        var formData = {
                            'mailType' : '1', // FIXME 項目名の修正
                            'distSettingId' : this.distSettingId,
                            'convoMailType' : this.convoMailType
                        };
                        // 送信イベントを発行する
                        this.emit('send', {value: formData});
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 入力値の妥当性チェック
             */
            validate: function() {
                // 招集配信設定IDの必須チェック
                if (!this.distSettingId) {
                    InfoDialog.show('入力エラー', '配信設定が選択されていません。');
                    return false;
                }
                return true;
            },

            /**
             * テンプレートから挿入ボタンが押下された際の処理。
             */
            onSelectTempButton: function() {
                // テンプレート選択ダイアログの表示
                this.templateInsertDialog.show();
            },

            /**
             * dataにより項目の表示・非表示を制御
             */
            displayItems: function(data) {
                // 対象項目一覧
                var items = [
                    'question1Text', 'answer1aText', 'answer1bText', 'answer1cText', 'answer1dText', 'answer1eText',
                    'question2Text', 'answer2aText', 'answer2bText', 'answer2cText', 'answer2dText', 'answer2eText',
                    'question3Text', 'answer3aText', 'answer3bText', 'answer3cText', 'answer3dText', 'answer3eText',
                    'question4Text', 'answer4aText', 'answer4bText', 'answer4cText', 'answer4dText', 'answer4eText',
                    'question5Text', 'answer5aText', 'answer5bText', 'answer5cText', 'answer5dText', 'answer5eText'];
                array.forEach(items, lang.hitch(this, function(item) {
                    this.displayItem(data, item);
                }));
            },

            /**
             * dataのname項目の表示・非表示を制御
             */
            displayItem: function(data, name) {
                if (data[name]) {
                    domStyle.set(name + 'Div', 'display', '');
                } else {
                    domStyle.set(name + 'Div', 'display', 'none');
                }
            }
        });
});

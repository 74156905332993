/**
 * 地震震度レベル
 * @module app/model/EarthquakeIntensityLevel
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        idProperty: 'level',
        data: [
            {
            level: "9",
            label: "7"
            }, {
                level: "8",
                label: "6強"
            }, {
                level: "7",
                label: "6弱"
            }, {
                level: "6",
                label: "5強"
            }, {
                level: "5",
                label: "5弱"
            }, {
                level: "4",
                label: "4"
            }, {
                level: "3",
                label: "3"
            }
        ]
    });
});

define([
    'module',
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DistrictSelectDialog.html',
    'idis/service/Requester',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'dijit',
    'dijit/tree/ObjectStoreModel',
    'idis/view/tree/CheckTree',
    // 以下、変数で受けないモジュール
    'dojo/store/Memory',
    'idis/view/draw/leaflet.draw.custom',
    'idis/view/form/ColorSelector',
    'idis/view/page/_PageBase',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/ColorSelector',
    'idis/view/form/OpacitySlider',
    'leaflet-plugins/layer/vector/KML',
    'dijit/form/DropDownButton',
    'dijit/TooltipDialog',
    'dijit/ColorPalette',
    'dijit/form/DropDownButton',
    'dijit/form/HorizontalSlider',
    'leaflet-measure-path'
], function (module, dojo, array, declare, lang, template, Requester, _IdisWidgetBase, 
    DialogChain, IdisDialog, dijit, ObjectStoreModel, CheckTree
) {
    var content = declare(_IdisWidgetBase, {
        templateString: template,
        baseClass: 'districtSelectDialog-Container',
        widgetsInTemplate: true
    });

    var container = declare(module.id.replace(/\//g, '.'), [IdisDialog], {
        // Tree
        _districtTree: null,

        // 選択済みアイテム
        _selectedItem: null,

        // 行政区域作図ダイアログの選択タイプ
        _distType: null,

        // 市区町村レイヤー
        _cityLayerPromise: null,

        constructor: function (options) {
            lang.mixin(this, options);
            this.title = '行政区域';
            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
            this.own(this.inner);

            this._selectedItem = new Array();
        },

        // override
        postCreate: function () {
            this.inherited(arguments);
            this.set('content', this.inner);
            this._addEventHandlers();
        },

        /*---------- 表示制御系 ----------*/

        /**
         * 処理名：地区選択ダイアログ表示。
         * 処理概要：地区選択ダイアログを表示する。
         *
         * @param なし
         * @return なし
         */
        show: function () {
            this.inherited(arguments);
            //検索欄のリセット
            dijit.byId('DistrictSelectDialog-filterInput').set('value', '');
            window._districtDialog.checkNumber = [];

            this.emit('district-get-type');

            // Tree階層取得、生成
            this.createDistrictSelectTree();

            // ダイアログの位置を強制的に変更
            dojo.style(dijit.byId('DistrictSelectDialog').domNode, 'left', '700px');
            dojo.style(dijit.byId('DistrictSelectDialog').domNode, 'top', '200px');
            dojo.style(dijit.byId('DistrictSelectDialog').domNode, 'height', '');
        },

        /**
         * 処理名：地区選択ダイアログ非表示。
         * 処理概要：地区選択ダイアログを非表示にする。
         *
         * @param なし
         * @return なし
         */
        hideDialog: function () {
            // 地区選択ダイアログ非表示
            dijit.registry.byId('DistrictSelectDialog').close();
            dijit.registry.byId('DistrictSelectDialog')._wasShown = false;
        },

        _addEventHandlers: function () {
            if (dijit.byId('DistrictSelectDialog-CloseButton')) {
                dijit.byId('DistrictSelectDialog-CloseButton').on(
                    'click',
                    lang.hitch(this, function () {
                        this.hideDialog();
                    })
                );
            }
            if (dijit.byId('DistrictSelectDialog-DLDeselectButton')) {
                dijit.byId('DistrictSelectDialog-DLDeselectButton').on(
                    'click',
                    lang.hitch(this, function () {
                        this.allDeselect();
                    })
                );
            }
            if (dijit.byId('DistrictSelectDialog-filterInput')) {
                dijit.byId('DistrictSelectDialog-filterInput').on(
                    'keyup',
                    lang.hitch(this, function () {
                        this.onFilterInputChange();
                    })
                );
            }
        },

        /**
         * 処理名：Tree生成。
         * 処理概要：Treeを生成する。
         *
         * @return なしCreate
         */
        createDistrictSelectTree: function () {
            if (this._districtTree !== null) {
                // ツリーデータ破棄
                if (this._districtTree.model !== null) {
                    this._districtTree.model.destroy();
                    //this._districtTree.model = null;
                    delete this._districtTree.model;
                }
                this._districtTree.destroy();
                this._districtTree = null;
            }

            // 全都道府県・市区町村jsonファイル取得
            var type = this._distType;
            Requester.get('../../../data/master/draw/allCity.json').then(
                lang.hitch(this, function (data) {
                    // 都道府県の場合は都道府県分だけを取り出す
                    // 抽出したデータ
                    var filteredData = [];
                    if (type === 'pref') {
                        array.forEach(
                            data,
                            lang.hitch(self, function (item) {
                                if (item && (item.id === 'root' || item.parent === 'root')) {
                                    filteredData.push(item);
                                }
                            })
                        );
                    }

                    var treeData = type === 'pref' ? filteredData : data;
                    // storeの作成
                    var store = new dojo.store.Memory({
                        data: treeData,
                        getChildren: function (object) {
                            return this.query({ parent: object.id });
                        },
                        getParentIdentity: function (object) {
                            var result = null;
                            var parent = this.query({ id: object.parent });
                            if (parent[0] && parent[0].id) {
                                result = parent[0].id;
                            }
                            return result;
                        }
                    });

                    // modelの作成
                    var model = new ObjectStoreModel({
                        store: store,
                        query: { id: 'root' },
                        mayHaveChildren: function (item) {
                            var hasChildren = store.getChildren(item).length === 0 ? false : true;
                            return hasChildren;
                        }
                    });

                    // treeの作成
                    this._districtTree = new CheckTree({
                        style: 'height: 420px',
                        model: model,
                        showRoot: false,
                        openOnClick: false,
                        branchCheckBox: false,
                        leafCheckBox: true,
                        treeDistType: this._distType,
                        onClick: this.selectTree4Label,
                        onOpen: function (event) {
                            var checkState = '';
                            if (event.checked === true) {
                                var node = this.getNodesByItem(event);
                                checkState = node[0].get('checked');
                            } else {
                                checkState = '';
                            }

                            if (event.parent === 'root') {
                                var children = store.getChildren(event);
                                for (var i = 0; i < children.length; i++) {
                                    if (children[i].designated === '1') {
                                        var tNode = this.getNodesByItem(children[i]);
                                        tNode[0]._checkBox.domNode.style.display = '';
                                    }
                                }
                            }
                        },
                        isDisabled: function (item) {
                            if (this.treeDistType === 'city') {
                                return item.infoCategoryCd === 'T002' || item.parent === 'root';
                            } else {
                                return item.infoCategoryCd === 'T002';
                            }
                        }
                    });
                    this._districtTree.placeAt(dojo.byId('DistrictSelectDialog-DLTree'));
                    this._districtTree.startup();
                    this._districtTree.on(
                        'check-box-click',
                        lang.hitch(this, function (treeEvt) {
                            this.selectTree4CheckBox(treeEvt.item, treeEvt.treeNode);
                        })
                    );
                    this.data = treeData;
                })
            );
        },

        /**
         * 処理名：Treeのチェックボックス選択。
         * 処理概要：Treeのチェックボックスをクリックした時の処理。
         *
         * @param item 選択した情報
         * @param treeNode
         * @param textBox
         * @return なし
         */
        selectTree4CheckBox: function (item, treeNode) {
            // チェックボックスがチェック不可の状態であれば、処理しない
            if ('_checkBox' in treeNode) {
                if ('disabled' in treeNode._checkBox) {
                    if (treeNode._checkBox.disabled === true) {
                        return;
                    }
                }
            }
            if (item.designated === '0') {
                // 選択した情報取得
                var selectItem = this.getSelectTreeInfo(item);
                //地区コード取得
                var layers = selectItem[0].id;
                //番号被りのフラグ
                var duplicationFlag = false;
                if (window._districtDialog.checkNumber.length !== 0) {
                    for (var i = 0; i < window._districtDialog.checkNumber.length; i++) {
                        if (window._districtDialog.checkNumber[i] === layers) {
                            window._districtDialog.checkNumber.splice(i, 1);
                            duplicationFlag = true;
                        }
                    }
                }
                if (!duplicationFlag) {
                    window._districtDialog.checkNumber.push(layers);
                }
                var textBox = this._districtTree.isChecked(item);
                this.emit('district-on-dist-change', { layers, textBox });
            } else if (item.designated === '1') {
                // 政令指定都市の場合
                var children = this._districtTree.model.store.getChildren(item);
                // 市区町村コードからあらかじめレイヤをロードしておく
                this.loadCityLayer(children[0].id.substr(0, 2)).then(
                    lang.hitch(this, function () {
                        for (var i = 0; i < children.length; i++) {
                            // 選択した情報取得
                            var childrenItem = this.getSelectTreeInfo(children[i]);
                            //地区コード取得
                            var childrenLayers = childrenItem[0].id;
                            //番号被りのフラグ
                            var childrenDuplicationFlag = false;
                            if (window._districtDialog.checkNumber.length !== 0) {
                                for (var j = 0; j < window._districtDialog.checkNumber.length; j++) {
                                    if (window._districtDialog.checkNumber[j] === childrenLayers) {
                                        childrenDuplicationFlag = true;
                                    }
                                }
                            }
                            if (!childrenDuplicationFlag) {
                                window._districtDialog.checkNumber.push(childrenLayers);
                            }
                            var textBox = this._districtTree.isChecked(item);
                            this.emit('district-on-dist-change', { layers: childrenLayers, textBox: textBox });
                        }
                    })
                );
            }
        },

        /**
         * 処理名：Treeのチェックボックス選択。
         * 処理概要：Treeのチェックボックスをクリックした時の処理。
         *
         * @param item 選択した情報
         * @return 選択した情報の配列
         */
        getSelectTreeInfo: function (item) {
            this._selectedItem = new Array();
            this.getChildrenInfo(item);

            return this._selectedItem;
        },

        /**
         * 処理名：Treeの子情報取得。
         * 処理概要：Treeの子情報を取得する。
         *
         * @param item 選択した情報
         * @return 選択した情報の配列
         */
        getChildrenInfo: function (item) {
            var dataArray = null;
            // 選択した情報
            if (!item.children) {
                dataArray = this.multipleLayerId(item);
                for (var i = 0; i < dataArray.length; i++) {
                    this._selectedItem.push(dataArray[i]);
                }
            } else {
                for (var j = 0; j < item.children.length; j++) {
                    this.getChildrenInfo(item.children[j]);
                }
            }
        },

        /**
         * 処理名：複数レイヤーID。
         * 処理概要：複数レイヤーIDを単レイヤーIDの配列に変換する。
         *
         * @param item 選択した情報
         * @return 複数レイヤーIDに対応した配列
         */
        multipleLayerId: function (item) {
            var dataArray = new Array();
            if (item.lId instanceof Array) {
                // レイヤーID複数
                for (var i = 0; i < item.lId.length; i++) {
                    var data = {
                        id: item.id,
                        lId: item.lId[i],
                        jsonType: item.jsonType[i],
                        tilecreationFlag: item.tilecreationFlag[i],
                        dRealtimeFlag: item.dRealtimeFlag[i],
                        maxZoom: item.maxZoom[i],
                        minZoom: item.minZoom[i],
                        overlay: item.overlay[i],
                        layerUrl: item.layerUrl[i],
                        lStyleUrl: item.lStyleUrl[i],
                        lDatacheckUrl: item.lDatacheckUrl,
                        categoryCode: item.mCategoryCode || item.dCategoryCode
                    };
                    dataArray.push(data);
                }
            } else {
                dataArray.push(item);
            }

            return dataArray;
        },

        /**
         * 処理名：全選択解除。
         * 処理概要：全ての選択を解除する。
         *
         * @param なし
         * @return なし
         */
        allDeselect: function () {
            //選択済みの地区を非選択に
            for (var i = 0; i < window._districtDialog.checkNumber.length; i++) {
                this.emit('district-on-dist-change', { layers: window._districtDialog.checkNumber[i], textBox: false });
            }
            //検索欄のリセット
            dijit.byId('DistrictSelectDialog-filterInput').set('value', '');
            // 選択済みの地区のリセット
            window._districtDialog.checkNumber = [];
            //ツリーのリセット
            this.createDistrictSelectTree();
        },

        /**
         * 被害情報ツリー要素クリック時の動作。
         * @param {Object} item 選択したツリー要素
         * @param {string} [item.dCategoryCode] 情報種別
         * @returns None
         */
        selectTree4Label: function (item, treeNode, evt) {
            if (this.isIconNode(evt.target, treeNode)) {
                this.setChecked(item, !this.isChecked(item));
            } else {
                this._onExpandoClick({ node: treeNode });
            }

            var arg = arguments;
            if (arg.callee.caller.nom !== '_onCheckBoxClick') {
                if (!treeNode.get('checked')) {
                    treeNode.set('checked', true);
                } else {
                    treeNode.set('checked', false);
                }
                this.emit('check-box-click', { item, treeNode });
            }
        },

        onFilterInputChange: function () {
            var value = dojo.byId('DistrictSelectDialog-filterInput').value;
            this.updateTree(value);
        },

        loadCityLayer: function (code) {
            if (!this._cityLayerPromise) {
                this.emit('district-load-city-layer', { code });
            }

            return this._cityLayerPromise;
        },

        /**
         * ツリー要素検索時の動作。
         * @param {string} value 検索語
         * @returns None
         */
        updateTree: function (value) {
            //検索欄が空の場合は通常のツリーを表示
            if (value === '') {
                if (this._districtTree !== null) {
                    // ツリーデータ破棄
                    if (this._districtTree.model !== null) {
                        this._districtTree.model.destroy();
                        delete this._districtTree.model;
                    }
                    this._districtTree.destroy();
                    this._districtTree = null;
                }
                var store = new dojo.store.Memory({
                    data: this.data,
                    getChildren: function (object) {
                        return this.query({ parent: object.id });
                    },
                    getParentIdentity: function (object) {
                        var result = null;
                        var parent = this.query({ id: object.parent });
                        if (parent[0] && parent[0].id) {
                            result = parent[0].id;
                        }
                        return result;
                    }
                });
                // modelの作成
                var model = new ObjectStoreModel({
                    store: store,
                    query: { id: 'root' },
                    mayHaveChildren: function (item) {
                        var hasChildren = store.getChildren(item).length === 0 ? false : true;
                        return hasChildren;
                    }
                });

                // treeの作成
                this._districtTree = new CheckTree({
                    style: 'height: 420px',
                    model: model,
                    showRoot: false,
                    openOnClick: false,
                    branchCheckBox: false,
                    leafCheckBox: true,
                    treeDistType: this._distType,
                    onClick: this.selectTree4Label,
                    onOpen: function (event) {
                        var checkState = '';
                        if (event.checked === true) {
                            var node = this.getNodesByItem(event);
                            checkState = node[0].get('checked');
                        } else {
                            checkState = '';
                        }
                        if (event.parent === 'root') {
                            var children = this.getChildren(event);
                            for (var i = 0; i < children.length; i++) {
                                if (children[i].designated === '1') {
                                    var tNode = this.getNodesByItem(children[i]);
                                    tNode[0]._checkBox.domNode.style.display = '';
                                }
                            }
                        }
                    },
                    isDisabled: function (item) {
                        if (this.treeDistType === 'city') {
                            return item.infoCategoryCd === 'T002' || item.parent === 'root';
                        } else {
                            return item.infoCategoryCd === 'T002';
                        }
                    }
                });
                this._districtTree.placeAt(dojo.byId('DistrictSelectDialog-DLTree'));
                this._districtTree.startup();
                this._districtTree.on(
                    'check-box-click',
                    lang.hitch(this, function (treeEvt) {
                        console.log('checkbox-evt');
                        this.selectTree4CheckBox(treeEvt.item, treeEvt.treeNode);
                    })
                );
                return;
            }
            //データの作成
            var filterData = [];
            filterData.push(this.data[0]);
            //検索語を含む地区を抽出
            for (var i = 0; i < this.data.length; i++) {
                if (this.data[i]['name'].indexOf(value) !== -1) {
                    filterData.push(this.data[i]);
                }
            }
            //親があるなら抽出
            for (var i = 0; i < filterData.length; i++) {
                if (filterData[i]['name'] !== 'root' && (filterData[i]['parent'] !== null && filterData[i]['parent'] !== 'root')) {
                    var parentData = this.searchParent(filterData[i]['parent']);
                    if (!this.checkParent(filterData, parentData['id'])) {
                        filterData.push(parentData);
                    }
                }
            }
            if (filterData.length >= 2) {
                if (this._districtTree !== null) {
                    // ツリーデータ破棄
                    if (this._districtTree.model !== null) {
                        this._districtTree.model.destroy();
                        delete this._districtTree.model;
                    }
                    this._districtTree.destroy();
                    this._districtTree = null;
                }
                var store = new dojo.store.Memory({
                    data: filterData,
                    getChildren: function (object) {
                        return this.query({ parent: object.id });
                    },
                    getParentIdentity: function (object) {
                        var result = null;
                        var parent = this.query({ id: object.parent });
                        if (parent[0] && parent[0].id) {
                            result = parent[0].id;
                        }
                        return result;
                    }
                });
                // modelの作成
                var model = new ObjectStoreModel({
                    store: store,
                    query: { id: 'root' },
                    mayHaveChildren: function (item) {
                        var hasChildren = store.getChildren(item).length === 0 ? false : true;
                        return hasChildren;
                    }
                });

                // treeの作成
                this._districtTree = new CheckTree({
                    style: 'height: 420px',
                    model: model,
                    showRoot: false,
                    openOnClick: false,
                    branchCheckBox: false,
                    leafCheckBox: true,
                    treeDistType: this._distType,
                    onClick: this.selectTree4Label,
                    onOpen: function (event) {
                        var checkState = '';
                        if (event.checked === true) {
                            var node = this.getNodesByItem(event);
                            checkState = node[0].get('checked');
                        } else {
                            checkState = '';
                        }
                        if (event.parent === 'root') {
                            var children = store.getChildren(event);
                            for (var i = 0; i < children.length; i++) {
                                if (children[i].designated === '1') {
                                    var tNode = this.getNodesByItem(children[i]);
                                    tNode[0]._checkBox.domNode.style.display = '';
                                }
                            }
                        }
                    },
                    isDisabled: function (item) {
                        if (this.treeDistType === 'city') {
                            return item.infoCategoryCd === 'T002' || item.parent === 'root';
                        } else {
                            return item.infoCategoryCd === 'T002';
                        }
                    }
                });
                this._districtTree.placeAt(dojo.byId('DistrictSelectDialog-DLTree'));
                this._districtTree.startup();
                this._districtTree.on(
                    'check-box-click',
                    lang.hitch(this, function (treeEvt) {
                        console.log('checkbox-evt');
                        this.selectTree4CheckBox(treeEvt.item, treeEvt.treeNode);
                    })
                );
            } else {
                if (this._districtTree !== null) {
                    // ツリーデータ破棄
                    if (this._districtTree.model !== null) {
                        this._districtTree.model.destroy();
                        delete this._districtTree.model;
                    }
                    this._districtTree.destroy();
                    this._districtTree = null;
                }
            }
        },
        /**
         * 親データの抽出。
         * @param {string} value parentId
         * @returns parentData
         */
        searchParent: function (value) {
            for (var i = 0; i < this.data.length; i++) {
                if (this.data[i]['id'] === value) {
                    var parentData = this.data[i];
                    return parentData;
                }
            }
        },
        /**
         * 親データのがすでに抽出されているか。
         * @param data 抽出データ
         * @param {string} value parentId
         * @returns T/F
         */
        checkParent: function (data, value) {
            for (var i = 0; i < data.length; i++) {
                if (data[i]['id'] === value) {
                    return true;
                }
            }
        }
    });

    return container;
});

/**
* Spectee管理画面、詳細画面共通モジュール。
* @module app/spectee/mobile/_SpecteeMobileAdminPageBase
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/SpecteeMobileAdminPage.html',
    'idis/view/page/_PageBase',
    'app/config',
    'idis/service/GeoService',
    'idis/service/Requester',
    'leaflet',
    'idis/view/dialog/IdisDialog',
    'app/map/baselayer/BaseLayerPane',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './SpecteeMobileGrid'
], function (module, array, declare, lang, domClass, domStyle, template, _PageBase,
    config, GeoService, Requester, leaflet, IdisDialog, BaseLayerPane) {

    // GeoServiceを初期化
    var _geoService = new GeoService({ url: config.geocode && config.geocode.url });

    /**
    * Spectee情報画面。
    * @class _SpecteeAdminPageBase
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/spectee/mobile_SpecteeMobileAdminPageBase~_SpecteeMobileAdminPageBase# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--spectee',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            store: null,
            /**
             * 災害ID
             */
            _disasterId: null,
            /**
             * 市町コード
             */
            _municipalityCd: null,
            /**
             * 使用するgrid名
             * @type {String}
             */
            GRID_NAME: null,
            /**
             * お気に入り情報
             * @type {String}
             */
            IS_FAVORITE: '1',
            /**
             * page種別(管理画面)
             * @type {String}
             */
            PAGE_TYPE_ADMIN: 'ADMIN_PAGE',
            /**
             * page種別(詳細画面)
             * @type {String}
             */
            PAGE_TYPE_DETAIL: 'DETAIL_PAGE',
            /**
             * SNS種別(Twitter)
             * @type {String}
             */
            SNSTYPE_TWITTER: 1,
            /**
             * SNS種別(Instagram)
             * @type {String}
             */
            SNSTYPE_INSTAGRAM: 2,
            /**
             * SNS種別(Facebook)
             * @type {String}
             */
            SNSTYPE_FACEBOOK: 3,
            /**
             * SNS種別(youtube)
             * @type {String}
             */
            SNSTYPE_YOUTUBE: 4,
            /**
             * SNS種別(Tiktok)
             * @type {String}
             */
            SNSTYPE_TIKTOK: 5,

            /**
             * 位置情報確定レベル(緯度経度・住所なし)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_NONE: '0',
            /**
             * 位置情報確定レベル(都道府県)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_PREF: '1',
            /**
             * 位置情報確定レベル(市町村)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_CITY: '2',
            /**
             * 位置情報確定レベル(区町丁目)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_TOWN: '3',
            /**
             * 位置情報確定レベル(位置情報確定)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_COMFIRMED: '4',
            /**
             * gridの選択行id
             * @type {String}
             */
            gridSelectedId: null,
            /**
             * マウスオーバーイベントで使用する「詳細」「お気に入り登録・解除」ボタン列のマウスオーバー状態
             * @type {boolean}
             */
            mouseOvered: false,
            /**
             * 「詳細」「お気に入り登録・解除」ボタンをクリックした時点の
             * 「詳細」「お気に入り登録・解除」ボタン列のマウスオーバー状態
             * @type {boolean}
             */
            wasMouseOvered: false,
            /**
             * 選択行の情報
             * @type {Object}
             */
            selectedRow: {},
            //地図上の描画
            drawnNewsIds: [],
            divIconMarkers: [],

            constructor: function () {
                //地図アイコンに関する情報を初期化
                this.drawnNewsIds = [];
                this.divIconMarkers = [];
                this._events = [];
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function (prefecture, city, town, item) {
                console.debug('start geocoding (address: ' +
                    prefecture ? prefecture : '' +
                        city ? city : '' + town ? town : '' + ')');

                if (!prefecture) {
                    console.log('not input address');
                    // this.chain.info('住所情報を取得できませんでした。', 'エラー');
                    if (this.marker) {
                        this.removeMark();
                    }
                    // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                    this.setPopup(item, true, false);
                    return;
                }
                var address = prefecture + city + town;
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, item);
                        if (this.map) {
                            this.map.setView(latlng, this.map.getZoom());
                        }
                    } else {
                        console.debug('address is not correct');
                        // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                        this.setPopup(item, true, true);
                        if (this.marker) {
                            this.removeMark();
                        }
                    }
                })).otherwise(lang.hitch(this, function () {
                    console.debug('arcgis service error');
                    // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                    this.setPopup(item, true, true);
                    if (this.marker) {
                        this.removeMark();
                    }
                }));

                console.debug('end reverse geocoding (latitude: ' +
                    this.pointLat + ', longitude: ' + this.pointLng + ')');
            },

            /**
             * マーカーを追加する。
             */
            addMark: function (lat, lng, item) {
                if (this.marker) {
                    this.removeMark();
                }
                if (this.map) {
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                }
                // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                this.setPopup(item, false, false);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.map) {
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * ポップアップの中身を作る。
             * isDialog: ダイアログ表示のケースか否か
             * isError: arcgisサービス側でのエラー発生の有無
             */
            setPopup: function (item, isDialog, isError) {
                // クリック時のポップアップを作成
                var loc = null;
                var sns = null;
                // 詳細画面も本処理を使用するので、どちらのページから呼び出されたかを判定する
                // 詳細ボタンクリックとgrid行選択のイベントが同時に走るため、formの有無で判断する
                if (this.form && this.form.get('value').favorite &&
                    (this.form.get('value').favorite[0] === this.IS_FAVORITE)) {
                    loc = item.locationInfo;
                    sns = item.snsInfo;
                } else {
                    if (item.locationInfo[0]) {
                        loc = item.locationInfo[0];
                    }
                    if (item.snsInfo[0]) {
                        sns = item.snsInfo[0];
                    }
                }

                // 日付をyyyy-MM-dd HH:mm形式にする
                if (item && item.updDate && item.updDate.length > 17) {
                    item.updDate = item.updDate.substr(0, 16);
                }
                var pref = loc && loc.prefecture ? loc.prefecture : '';
                var city = loc && loc.city ? loc.city : '';
                var town = loc && loc.town ? loc.town : '';
                var address = pref + city + town;

                var snsType = null;
                if (sns) {
                    if (this.form && this.form.get('value').favorite &&
                        this.form.get('value').favorite[0] === this.IS_FAVORITE) {
                        snsType = 'snsType';
                        snsType = sns[snsType];
                    } else {
                        snsType = 'sns_type';
                        snsType = sns[snsType];
                    }
                }

                // ポップアップ内のHTML要素を組み立てる
                var popupMinWidth = 0;
                var contentMaxWidth = 0;
                var faceBookTiktokContentMaxWidth = 0;
                if (snsType === this.SNSTYPE_FACEBOOK) {
                    faceBookTiktokContentMaxWidth = this.getMaxWidth(sns.embed);
                    if (!faceBookTiktokContentMaxWidth) {
                        faceBookTiktokContentMaxWidth = 200;
                    }
                    popupMinWidth = faceBookTiktokContentMaxWidth + 40;
                    contentMaxWidth = faceBookTiktokContentMaxWidth + 20;
                } else {
                    popupMinWidth = 240;
                    contentMaxWidth = 220;
                    faceBookTiktokContentMaxWidth = 200;
                }

                var content = '<table style="border-collapse: collapse; border: solid 1px black;">';
                content += '<tr style="border: solid 1px black; background-color: #f0f8ff"><td>' +
                    '<div style="font-weight:bold; max-width:' + contentMaxWidth + 'px; ' +
                    'text-align:left; font-size:0.9em; margin: 10px">' +
                    '<span style="font-weight:normal; font-size:0.7em;">' + item.updDate + '</span><br>';
                content += address ? '<span>' + address + '</span><br>' : '';
                content += '<span style="color:#941f57">' + '[第' + item.reportNo + '報] ' + '</span>' +
                    '<span style="color:#191970">' + item.title + '</span><br>';
                content += item.note ? '<span style="word-wrap: break-word; font-weight:normal; ' +
                    'max-width:' + contentMaxWidth + 'px; font-size:0.8em;">' + item.note + '</span>' : '';
                content += '</div></td></tr>';

                var type = null;
                var url = null;
                var mediaUrl = 'media_url';
                var mediaType = 'media_type';
                // コンテンツの有無と、コンテンツが画像、動画のいずれであるかを判定する
                // 詳細画面も本処理を使用するので、どちらのページから呼び出されたかを判定する
                // 詳細ボタンクリックとgrid行選択のイベントが同時に走るため、formの有無で判断する
                if (sns) {
                    if (this.form && this.form.get('value').favorite &&
                        this.form.get('value').favorite[0] === this.IS_FAVORITE) {
                        url = sns.mediaUrl;
                        if (sns.mediaType === 'photo') {
                            type = 'photo';
                        } else if (sns.mediaType === 'video') {
                            type = 'video';
                        }
                    } else {
                        if (sns.media && sns.media[0]) {
                            url = sns.media[0][mediaUrl];
                            if (sns.media[0][mediaType] === 'photo') {
                                type = 'photo';
                            } else if (sns.media && sns.media[0] && sns.media[0][mediaType] === 'video') {
                                type = 'video';
                            }
                        }
                    }

                    // 画像、動画エリア Twitterのみ 他のSNSはembedに埋め込まれている
                    if (snsType === this.SNSTYPE_TWITTER) {
                        content = this.buildPopupElementForTwitter(content, url, type);
                    }

                    // Spectee側で組み立てられた埋め込み部分のうち不要な部分を削る
                    sns.embed = this.trimEmbed(sns);

                    if (snsType === this.SNSTYPE_YOUTUBE) {
                        // Youtubeの場合の処理
                        sns.embed = this.buildPopupElementForYoutube(sns, contentMaxWidth);
                    } else if (snsType === this.SNSTYPE_FACEBOOK || snsType === this.SNSTYPE_TIKTOK) {
                        // FacebookかTiktokの場合の処理
                        sns.embed = this.buildPopupElementForFacebookTiktok(sns, snsType, faceBookTiktokContentMaxWidth);
                    }

                    content += '<tr><td style="max-width:' + contentMaxWidth + 'px;">';
                    content += sns && sns.embed ? '<div style="font-size:0.9em; margin: 10px; max-width:' +
                        contentMaxWidth + 'px;">' + sns.embed + '</div>' : '';
                    content += '</td></tr>';
                }
                content += '</table><br>';

                // 地図エリアの高さを取得し、ポップアップがエリア内に収まるよう調整する
                var popupMaxHeight = null;
                if (this.mapPane && this.mapPane.h) {
                    popupMaxHeight = Math.floor(this.mapPane.h * 0.85);
                }
                var customOptions =
                {
                    'minWidth': popupMinWidth,
                    'maxHeight': popupMaxHeight
                };
                if (isDialog) {
                    // gridの行選択を解除する
                    delete this.grid.selection[this.gridSelectedId];
                    // 選択中クラスが付与されてしまうので、強制的にリムーブする
                    domClass.remove(this.selectedRow.element, 'dgrid-selected');

                    if (!this.wasMouseOvered[0]) {
                        var messageTag = null;
                        if (isError) {
                            var prefName = config.municInfo.prefName;
                            if (pref && pref !== prefName) {
                                messageTag = '<p>' + prefName + '外の住所情報から<br>緯度経度の特定はできません。</p>';
                            } else {
                                messageTag = '<p>正常に緯度経度が取得できませんでした。</p>';
                            }
                        } else {
                            messageTag = '<p>住所情報がありませんでした。</p>';
                        }
                        content = messageTag + content;
                        // ダイアログを表示する
                        this.chain.info('<div style="text-align: center;">' + content + '</div>', 'Spectee情報');
                    }
                } else if (this.marker) {
                    this.marker.bindPopup('<div style="text-align: center;">' + content + '</div>', customOptions);
                }
            },

            /**
             * Spectee側で組み立てられた埋め込み部分のうち不要な箇所を削る。
             */
            trimEmbed: function (sns) {
                // blockquoteタグを削る
                if (sns && /^(<blockquote)/.test(sns.embed)) {
                    var target = sns.embed.indexOf('>');
                    sns.embed = sns.embed.substring(target + 1);

                    var lastTarget = sns.embed.lastIndexOf('<');
                    sns.embed = sns.embed.substring(lastTarget, 0);
                }
                // 不要な改行を削る
                if (sns && /^(<br><br>)/.test(sns.embed)) {
                    sns.embed = sns.embed.replace(/<br><br>/g, '<br>');
                }
                return sns.embed;
            },

            /**
             * Twitter用のコンテンツを組み立てる。
             */
            buildPopupElementForTwitter: function (content, url, type) {
                // Twitterの場合は画像、動画を抜き出し横幅を固定
                // 画像、動画を読み込んだ後、ポップアップをリサイズする
                content += '<tr><td style="width:100%; max-width:220px;">';
                if (url) {
                    if (type === 'photo') {
                        content += '<div style="max-width: 160px; margin-top: 10px; ' +
                            'margin-left: 30px; margin-right: 30px;">';
                        // 画像の場合
                        var image = new Image();
                        image.src = url;
                        // 画像サイズを取得し終えた時点でポップアップを更新する
                        image.onload = lang.hitch(this, function () {
                            if (this.marker) {
                                this.marker._popup.update();
                            }
                        });
                        content += '<img src="' + url + '" style="width:100%; height:auto;"></img></div>' +
                            '<a href="' + url + '" target="_blank" style="font-size:0.8em;">画像へのリンク</a>';
                    } else if (type === 'video') {

                        content += '<div style="max-width: 160px; margin-top: 10px; ' +
                            'margin-left: 30px; margin-right: 30px;">';
                        // 動画の場合
                        var element = document.createElement('video');
                        var videoOriginWidth = 0;
                        var videoOriginHeight = 0;
                        var videoHeight = 0;
                        element.src = url;
                        // 動画サイズを取得し終えた時点でポップアップ内の動画サイズを書き換えポップアップを更新する
                        element.onloadedmetadata = lang.hitch(this, function () {
                            // 元の動画サイズを取得する
                            videoOriginWidth = element.videoWidth;
                            videoOriginHeight = element.videoHeight;
                            // 元の動画サイズと、200pxとの比率を算出し、正しい高さを取得する
                            var magni = parseInt(videoOriginWidth, 10) / 200;
                            videoHeight = Math.floor(videoOriginHeight / magni);
                            // ポップアップの生成処理時にheight:auto;としていた箇所を正しい高さに書き換える
                            if (this.marker) {
                                this.marker._popup._content =
                                    this.marker._popup._content.replace('height:auto;', 'height:' + videoHeight + 'px;');
                                this.marker._popup.update();
                            }
                        });

                        content += '<video controls preload="metadata" style="width:100%; height:auto;"><source src="' +
                            url + '"></video></div>';
                        content += '<a href="' + url + '" target="_blank" style="font-size:0.8em;">動画へのリンク</a>';
                    }
                }
                content += '</td></tr>';
                return content;
            },

            /**
             * Youtube用のコンテンツを組み立てる。
             */
            buildPopupElementForYoutube: function (sns) {
                // Youtubeの場合の処理 動画の縦横サイズを固定する
                // 元々縦横が指定されているため、サイズの記述部分を切り出して200×150に書き換える
                var wigthTarget = sns.embed.lastIndexOf('width="');
                sns.embed = sns.embed.substring(wigthTarget + 7);
                var wigthTargetEnd = sns.embed.indexOf('"');
                sns.embed = sns.embed.substring(wigthTargetEnd + 1);

                var heightTarget = sns.embed.lastIndexOf('height');
                sns.embed = sns.embed.substring(heightTarget + 8);
                var heightTargetEnd = sns.embed.indexOf('"');
                sns.embed = sns.embed.substring(heightTargetEnd + 1);

                sns.embed = '<iframe width="200px" height="150px"' + sns.embed;
                return sns.embed;
            },

            /**
             * Facebook/Tiktok用のコンテンツを組み立てる。
             */
            buildPopupElementForFacebookTiktok: function (sns, snsType, contentMaxWidth) {
                // FacebookかTiktokの場合は横幅を固定後、元と同じ比率になるよう縦幅を調整する
                var forward = sns.embed.substring(0, sns.embed.lastIndexOf(' width="') + 7);
                var backward = sns.embed.substring(sns.embed.lastIndexOf(' width="') + 8);
                backward = backward.substring(backward.indexOf('"') + 1);
                var originWidth = this.trimStrings(sns.embed, forward, backward);

                sns.embed = forward + '"' + contentMaxWidth + '"' + backward;

                if (snsType === this.SNSTYPE_TIKTOK) {
                    var magni = parseInt(originWidth, 10) / contentMaxWidth;

                    var forwardHeight = sns.embed.substring(0, sns.embed.lastIndexOf(' height="') + 8);
                    var backwardHeight = sns.embed.substring(sns.embed.lastIndexOf(' height="') + 9);
                    backwardHeight = backwardHeight.substring(backwardHeight.indexOf('"') + 1);

                    var originHeight = this.trimStrings(sns.embed, forwardHeight, backwardHeight);
                    var height = Math.floor(parseInt(originHeight, 10) / magni);

                    sns.embed = forwardHeight + '"' + height.toString() + 'px"' + backwardHeight;
                }
                return sns.embed;
            },

            /**
             * Facebook/Tiktokコンテンツの縦横比調整時に不要な文字列を削る。
             */
            trimStrings: function (embed, strForReplace1, strForReplace2) {
                var str = embed.replace(strForReplace1, ' ');
                str = str.replace(strForReplace2, ' ');
                str = str.replace(/"/g, '');
                str = str.replace(/ /g, '');
                str = str.replace('px', '');
                return str;
            },

            getMaxWidth: function (embed) {
                var returnValue = 240;
                if (embed !== '') {
                    // クエリストリング毎に分割
                    var params = embed.slice(1).split(' ');
                    // クエリストリング確認用
                    for (var i = 0; i < params.length; i++) {
                        var param = params[i].split('=');
                        var key = param[0];
                        var value = param[1];

                        // 該当するクエリストリングは無視
                        if (key === 'width') {
                            returnValue = this.trimStrings(value, ' ', ' ');
                            returnValue = parseInt(returnValue, 10);
                        }
                    }
                }
                return returnValue;
            },

            isEmpty: function(str) {
                if (str === void 0) {
                    return true;
                }
                if (str === null) {
                    return true;
                }
                if(str === ''){
                    return true;
                }
                if(str === '0'){
                    return true;
                }
                return false;
            }
        }
    );
});
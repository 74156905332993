/**
 * メールテンプレート登録画面用モジュール。
 * @module app/broadnotify/BroadnotifyTemplateRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/BroadnotifyTemplateRegisterDialog.html',
    './_BroadnotifyTemplateDialogBase',
    'idis/model/UserInfo'
    // 以下、変数から参照されないモジュール
], function(module, declare, lang, domStyle, template, _BroadnotifyTemplateDialogBase, UserInfo) {

    /**
     * メールテンプレート登録画面。
     * @class MailtemplateRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BroadnotifyTemplateDialogBase,
        /** @lends module:app/broadnotify/BroadnotifyTemplateRegisterDialog~BroadnotifyTemplateRegisterDialog# */ {
        
        // テンプレート文字列
        templateString: template,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 通知元を設定する
            this.initForm();
        },

        initForm: function(){
            this.broadnotifyAdmin.innerHTML = UserInfo._userInfo.userName;
            this.notifyGroupSendIdSelector._refresh();
        },

        /**
         * メールテンプレートを新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }
                // 入力値を取得
                var userId = UserInfo.getId();
                var formValues = this.form.get('value');
                formValues.broadnotifyAdminId = userId;

                // 元の画面にregisterイベントを発行し、入力値を渡す
                this.emit('register', {value: formValues});
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        
        /**
         * ダイアログを初期化する。
         */
        initDialog: function() {

            this.form.reset();
        }
    });
});

define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/control/Router'
], function (module, dojo, declare, lang, IdisGrid, helper, Router) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('scenarioId', 'シナリオID', { sortable: false }),
            helper.column('scenarioName', 'シナリオ名', { sortable: false }),
            helper.column('comment', '備考', { sortable: false }),
            {
                field: 'status', label: '再生',
                sortable: false,
                renderCell: function (item) {
                    var actions = dojo.create('div');

                    var explainSpan = dojo.create('span');
                    var explainDiv = null;
                    if (item.status === '1') {
                        // 再生中
                        explainDiv = dojo.create('a', { href: '#' });
                        explainDiv.innerHTML = '再生中';
                        explainDiv.onclick = function () {
                            Router.moveTo('simulation/history', { simulationId: item.simulationId });
                        };
                        actions.appendChild(explainDiv);
                    } else {
                        // 停止中
                        explainSpan.innerHTML = '-';
                    }
                    actions.appendChild(explainSpan);
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return actions;
                }
            },
            helper.column('crtTimestamp', '作成日時', { sortable: false }),
            helper.column('updTimestamp', '最終更新日時', { sortable: false }),
            helper.buttonColumn('playHistory', '再生履歴'),
            helper.buttonColumn('list', '一覧')
        ]
    });
});

/**
 * FAX送信履歴画面用モジュール。
 * @module app/broadnotify/BroadnotifyPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/BroadnotifyPage.html',
    './_BroadnotifyPageBase',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/date/locale',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/view/grid/IdisGrid',
    'idis/view/form/AclButton',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/control/Router',
    'dojo/_base/array',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/DateTimeInput',
    'app/view/form/BroadnotifyTerminalMultiSelector',
    'app/broadnotify/broadnotifyOutPutDialog',
    './BroadnotifyGrid',
    './BroadnotifyRegisterDialog',
    './BroadnotifyTemplateSelectorDialog'
], function(module, declare, lang, Deferred, json, template, _BroadnotifyPageBase, dom, domStyle, locale, registry,
    Dialog, Memory, Rest, ACL, IdisGrid, AclButton, IdisRest, Loader, DialogChain, UserInfo, Requester, Router, array) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var filterStore = {};
    /**
     * 緊急通知履歴画面
     * @class BroadnotifyPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BroadnotifyPageBase,
    /** @lends module:app/broadnotify/BroadnotifyPage~BroadnotifyPage# */ {
        // テンプレート文字列
        templateString: template,
        // インターバルの時間(min)
        REQUEST_INTERVAL: 1,
        // 緊急通知インターバルの時間
        //BROADNOTIFY_INTERVAL: 60,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotify',

        /**
         * ストア
         */
        store: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'broadnotifyDetailId',
                target: '/api/broadnotify',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerBroadnotifyRegisterDialog);
            this.own(this.formDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.receiptTypeOptions();
            this.initGrid();
            this.initPage();
            this.initRegisterButton();
        },

        destroy: function() {
            this.inherited(arguments);
            clearInterval(this.timer);
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.broadnotifyGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));

            // 保管した検索条件をセットする
            this.setFilterData();
            this.updateGridQuery();
        },

        /**
         * 検索条件を初期化する
         */
        receiptTypeOptions: function() {
            var select = this.receiptType;
            var optionText = ['全表示','要','不要'];

            array.forEach(optionText, function(item) {
                var option = document.createElement('option');
                option.text = item;
                option.value = item;
                select.addOption(option);
            });
        },
        /**
         * 緊急通知履歴画面を初期化する。
         */
        initPage: function() {
        	// 新規登録ダイアログを初期化
            this.initRegisterDialog();
        },

        /**
         * 新規登録ボタンの表示制御。
         */

        initRegisterButton: function() {
            var url = '/api/broadnotify/terminal/exist/?userId=' + UserInfo.getId();
            var deferred = new Deferred();
            var registerButton = this.registerButton;

            Requester.get(url).then(lang.hitch(this, function(data) {
                if(data) {
                    this.initRegisterDialog();
                } else {
                    domStyle.set(registerButton.domNode, 'display', 'none');
                }

            }), function(error) {
                deferred.reject(error);

            });
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            var dialog = this.broadnotifyRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                // 追加確認ダイアログを表示
                this.chain.confirm('緊急通知を送信します。よろしいですか？', function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 緊急通知履歴を検索する。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 緊急通知送信ダイアログを表示する。
         */
        showRegisterDialog: function() {
            this.innerBroadnotifyRegisterDialog.initDialog();
            this.broadnotifyRegisterDialog.show();
        },
        /**
         * テンプレート管理ボタンが押されたときに呼び出される。
         */
        goTemplate: function() {
            Router.moveTo('broadnotify/template');
        },

        /**
         * 緊急通知送信履歴詳細画面へ遷移する。
         */
        onDetailButtonClick: function(object) {
            Router.moveTo('broadnotify/detail', {
                broadnotifyDetailId : object.broadnotifyDetailId
            });
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            if(value.broadnotifyDateTimeFrom){
                var broadnotifyDateTimeFrom = new Date(value.broadnotifyDateTimeFrom).getTime();
                filter = filter.eq('broadnotifyDateTimeFrom', broadnotifyDateTimeFrom);
            }
            
            if(value.broadnotifyDateTimeTo){
                var broadnotifyDateTimeTo = new Date(value.broadnotifyDateTimeTo).getTime();
                filter = filter.eq('broadnotifyDateTimeTo', broadnotifyDateTimeTo);
            }

            if(value.notifyType && value.notifyType !== ''){
                filter = filter.eq('notifyType', value.notifyType);
            }

            if(value.senderName && value.senderName !== ''){
                filter = filter.eq('senderName', value.senderName);
            }

            switch (value.receiptType) {
                case '不要': 
                    filter = filter.eq('receiptType', '0');
                    break;

                case '要':
                    filter = filter.eq('receiptType', '1');
                    break;

                case '全表示':
                    filter = filter.eq('receiptType', '');
                    break;
                
                default:
                    break;
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.broadnotifyGrid.set('collection', collection);

            //検索条件の保管
            this.setFilterStore();
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');

            // 送信日時From
            if(value.broadnotifyDateTimeFrom){
                filterStore.broadnotifyDateTimeFrom = value.broadnotifyDateTimeFrom;
            }
            // 送信日時To
            if(value.broadnotifyDateTimeTo){
                filterStore.broadnotifyDateTimeTo = value.broadnotifyDateTimeTo;
            }
            // 通知種別
            if(value.notifyType){
                filterStore.notifyType = value.notifyType;
            }

            // 受確応答
            if(value.receiptType){
                filterStore.receiptType = value.receiptType;
            }

            // 受確応答
            if(value.senderName){
                filterStore.senderName = value.senderName;
            }
        },

        /**
          * 保管した検索条件をフォームにセットする
          */
        setFilterData: function() {
            // 送信日時From
            var broadnotifyDateTimeFrom;
            if(filterStore.broadnotifyDateTimeFrom){
                broadnotifyDateTimeFrom = filterStore.broadnotifyDateTimeFrom;
            } else {
                broadnotifyDateTimeFrom = new Date();
                broadnotifyDateTimeFrom.setDate(broadnotifyDateTimeFrom.getDate() - 1);
            }
            this.broadnotifyDateTimeFrom.set('value', broadnotifyDateTimeFrom);
            // 送信日時To
            if(filterStore.broadnotifyDateTimeTo){
                this.broadnotifyDateTimeTo.set('value', filterStore.broadnotifyDateTimeTo);
            }
            // 通知種別
            if(filterStore.notifyType){
                this.notifyType.set('value', filterStore.notifyType);
            }
            // 受確応答
            if(filterStore.receiptType){
                this.receiptType.set('value', filterStore.receiptType);
            }
            // 受確応答
            if(filterStore.senderName){
                this.senderName.set('value', filterStore.senderName);
            }
            
        },

        /**
         * 帳票出力ダイアログを表示する。
         */
        onOutputButtonClick: function() {
            var dialog = this.formDialog.getChildren()[0];

            // 現在の検索条件を帳票出力ダイアログに渡す
            var value = this.form.get('value');
            dialog.initDialog(value);

            this.formDialog.show();
        }
    });
});

/**
 * 被害情報更新画面における被害情報更新ダイアログ
 * @module app/view/page/<ウィジェット名>
 */
define([
  'module', // モジュールのパスを返す
  'dojo/_base/declare', // Dojoのクラス定義用モジュール
  'dojo/_base/lang',
  'dojo/text!./templates/ShelterDeleteDialog.html', // テンプレート文字列
  'idis/view/page/_PageBase', // 共通基底クラス
  'app/model/DisasterInfo',
  'idis/view/dialog/InfoDialog',
  'idis/view/dialog/DialogChain',
  // 以下、変数で受けないモジュール
  'idis/view/form/Button',
  'idis/view/form/DateTimeInput',
  'dijit/form/RadioButton',
  'app/view/form/MunicipalitySelector',
  'dijit/form/Form'
], function(module, declare, lang, template, _PageBase, DisasterInfo, InfoDialog, DialogChain) {
  /**
   * 被害情報更新画面における被害情報更新ダイアログ
   * @class <ウィジェット名>
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
    {
      // テンプレート文字列
      templateString: template,

      constructor: function() {
        // ダイアログ連鎖を登録
        this.chain = DialogChain.get(this);
      },

      onSubmit: function() {
        var deleteTarget = this.form.get('value').deleteTarget;
        this.emit('send', {
          deleteTarget: deleteTarget
        });
      }


    });
});

/**
 * 詳細ダイアログ内用。
 * @module app/view/ConvoMailTempDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ConvoMailTempDetailDialog.html',
    'idis/view/page/_PageBase',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton'
], function(module, declare, template, _PageBase, UserInfo, ACL, DialogChain) {
    /**
     * 詳細ダイアログ内の画面。
     * @class ConvoMailTempDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/ConvoMailTempDetailDialog~ConvoMailTempDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
            // ダイアログの連鎖関係を取得
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            this.setButtonStatus();
        },

        /**
         * 更新ボタンが押された際の処理。
         */
        onSubmit: function() {
            try {
                var value = this.form.get('value');
                if (this.form.validate() && this.validateForm(value)) {
                    // 実際の登録処理はPage側でupdateイベントを監視しているメソッドが実施
                    this.emit('update', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 削除ボタンが押された際の処理。
         */
        deleteButtonClick: function(){
            try {
                // 実際の登録処理はPage側でdeleteイベントを監視しているメソッドが実施
                // 削除するテンプレート情報のコードはpage側で付与する
                this.emit('delete');
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * @param value formの情報
         * formの値の妥当性を判定する。
         */
        validateForm: function(value) {
            if (!value.distributionBody) {
                this.chain.info('本文を入力してください。', '入力エラー');
                return false;
            }

            return true;
        },
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            this.updateButton.setDisabled(true);
            this.deleteButton.setDisabled(true);
            if ( role === ACL.ADMIN_USER) {
                this.updateButton.setDisabled(false);
                this.deleteButton.setDisabled(false);
            }
        }
    });
});


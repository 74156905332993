define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/ready',
    'dojo/text!./templates/CsvFileReadDialog.html',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/InfoDialog',
    'dojo/request/iframe',
    'dojo/request/xhr',
    'dijit',
    // 以下、変数で受けないモジュール
    'dijit/registry'
], function (module, dojo, declare, lang, ready, template, _IdisWidgetBase,
    DialogChain, IdisDialog, InfoDialog, iframe, xhr, dijit
) {
    var content = declare(_IdisWidgetBase, {
        templateString: template,
        baseClass: 'csvFileReadDialog-Container',
        widgetsInTemplate: true
    });

    var container = declare(module.id.replace(/\//g, '.'), [IdisDialog], {

        //二度押しを防止するためのフラグ
        submitFlag: false,

        constructor: function (options) {
            lang.mixin(this, options);
            this.title = 'ファイルを開く';
            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
            this.own(this.inner);
        },

        // override
        postCreate: function () {
            this.inherited(arguments);
            this.set('content', this.inner);
            this._addEventHandlers();
        },

        /**
         * 処理名：ファイルを開くダイアログ表示。
         * 処理概要：ファイルを開くダイアログを表示する。
         *
         * @param なし
         * @return なし
         */
        dispDialog: function () {
            // ファイル名の初期化
            if (dijit.registry.byId('CsvFileReadDialog-FileUpload').displayInput.value.length !== 0) {
                dijit.registry.byId('CsvFileReadDialog-FileUpload').reset();
            }

            // ダイアログを表示
            this.show();
        },

        /**
         * 処理名：選択ファイル名を表示名にセット。
         * 処理概要：選択されたファイル名を表示名にセットする。
         *
         * @param dataArray 選択ファイルオブジェクト
         * @return なし
         */
        selectedFile: function (dataArray) {
            dojo.forEach(dataArray, function (data) {
                var lastpoint = data.name.lastIndexOf('.') === -1 ? data.name.length : data.name.lastIndexOf('.');
            });
        },

        /**
         * 処理名：ファイルを開くダイアログ非表示。
         * 処理概要：ファイルを開くダイアログを非表示にする。
         *
         * @param なし
         * @return なし
         */
        hideDialog: function () {
            // ファイルを開くダイアログ非表示
            this.hide();
        },

        /**
         * 処理名：ファイル読み込みダイアログのイベント管理。
         * 処理概要：ファイルを開くダイアログの表示・非表示やファイル読み込みイベントを追加する。
         *
         * @param なし
         * @return なし
         */
        _addEventHandlers: function () {
            if (dijit.byId('CsvFileReadDialog-OpenButton')) {
                dijit.byId('CsvFileReadDialog-OpenButton').on(
                    'click',
                    lang.hitch(this, function () {
                        this.doRead();
                    })
                );
            }
            if (dijit.byId('CsvFileReadDialog-CancelButton')) {
                dijit.byId('CsvFileReadDialog-CancelButton').on(
                    'click',
                    lang.hitch(this, function () {
                        this.hideDialog();
                    })
                );
            }
            if (dijit.byId('CsvFileReadDialog-FileUpload')) {
                dijit.byId('CsvFileReadDialog-FileUpload').on(
                    'change',
                    lang.hitch(this, function (dataArray) {
                        this.selectedFile(dataArray);
                    })
                );
            }
        },

        /**
         * 処理名：ダブルクォーテーション・改行コードのエスケープ処理。
         * 処理概要：ダブルクォーテーションを \" へエスケープする。改行コードの置換
         *
         * @param 対象文字列
         * @return エスケープ後の文字列
         */
        doEscape: function (word) {
            if (typeof word === 'string') {
                word = word.replace(/</g, '&lt;');
                word = word.replace(/>/g, '&gt;');
            }
            return word;
        },

        /**
         * 処理名：ファイル読込。
         * 処理概要：ファイルを読込む。
         *
         * @param なし
         * @return なし
         */
        doRead: function () {
            var errMsg = '';
            // 入力チェック

            // ファイル形式
            var fileType = dijit.byId('CsvFileReadDialog-FileTypeList').get('value');
            var filename = dijit.registry.byId('CsvFileReadDialog-FileUpload').displayInput.value;
            if (dijit.registry.byId('CsvFileReadDialog-FileUpload').displayInput.value.length === 0) {
                errMsg += 'ファイル名を指定してください。';
            } else {
                var f = filename.split('.');
                var ext = f[f.length - 1].toLowerCase();

                switch (fileType) {
                    case '1': // csv
                        if (ext !== 'csv') {
                            errMsg += 'ファイル形式が異なります。CSVの場合、csvファイルを選択してください。';
                        }
                        break;
                    case '2': //txt
                        if (ext !== 'txt') {
                            errMsg += 'ファイル形式が異なります。TXTの場合、txtファイルを選択してください。';
                        }
                        break;
                    default:
                        break;
                }
            }

            if (errMsg.length !== 0) {
                InfoDialog.show('入力エラー', errMsg);
                return;
            }
            // 2度押し防止
            if (this.submitFlag) {
                return;
            }
            this.submitFlag = true;

            var errorMessage =
                'ファイルの読込に失敗しました。<br />ファイル形式が正しくないかネットワークに接続できません。';

            // ファイル読込
            var param = '?fileType=5';
            var selectedFiles = dijit.byId('CsvFileReadDialog-FileUpload')._files;

            var formData = new FormData();
            for (var i = 0; i < selectedFiles.length; i++) {
                formData.append('file_' + i, selectedFiles[i]);
            }
            var self = this;
            ready(
                function () {
                    // アップロード実行
                    xhr('/api/draw/registerFiles', {
                        handleAs: 'json',
                        data: formData,
                        method: 'POST',
                        headers: {
                            'Content-Type': false
                        },
                        //sync: true
                        preventCache: false
                    }).then(
                        function (data) {
                            ready(function () {
                                param = '?filePath=' + data.url;
                                // アップロード実行
                                iframe
                                    .post('/api/draw/readCsv' + param, {
                                        form: 'districtFileForm',
                                        headers: { 'Content-Type': 'multipart/form-data; charset=utf-8' },
                                        handleAs: 'json'
                                    })
                                    .then(
                                        function (data) {
                                            self.emit('district-csv-change', { data, filename });
                                        },
                                        function (err) {
                                            this.submitFlag = false;
                                            // 失敗の場合
                                            InfoDialog('システムエラー', errorMessage);
                                        },
                                        function (evt) {
                                            // 途中経過用
                                        }
                                    );
                            });
                        },
                        function (err) {
                            self.submitFlag = false;
                            // 失敗の場合
                            InfoDialog('システムエラー', errorMessage);
                        },
                        function (evt) {
                            // 途中経過用
                        }
                    );
                }
            );
            self.submitFlag = false;
            // ファイルを開くダイアログ非表示
            this.hideDialog();
        }
    });

    return container;
});

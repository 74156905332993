/**
 * 避難状況・報告概況画面用モジュール。
 * @module app/view/page/EvacOrderAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'idis/control/Router',
    'dojo/text!./templates/SimulationEventHistoryListPage.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'idis/control/Locator',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/simulation/SimulationEventHistoryListGrid'
], function(module, declare, lang, domStyle, json, Router, template, _PageBase, DialogChain, Locator,
    IdisRest, Requester, Loader){
    /**
     * 災害シミュレーションシナリオ一覧画面
     * @class SimulationEventHistoryListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/SimulationEventHistoryListPage# */ {
        // テンプレート文字列
        templateString: template,

        _scenarioId: null,
        _simulationId: null,
        _status: null,

        store: null,

        scenarioStore: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        constructor: function() {
            this.chain = DialogChain.get(this);
            // URLからシミュレーションIDを取得
            this._simulationId = Locator.getQuery().simulationId;
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.initGrid();
            this.initListPage();
        },

        initGrid: function() {
            this.store = new IdisRest({
                target: '/api/simulations/history/events/' + this._simulationId
            });
            this.grid.set('collection', this.store.filter());
        },

        initListPage: function() {
            Requester.get('/api/simulations/history/detail/' + this._simulationId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                this._scenarioId = data.scenarioId;
                this.scenarioName.innerHTML = data.scenarioName;
                this.comment.innerHTML = data.comment;
                this.disasterName.innerHTML = data.disasterName;
                this.startTimestamp.innerHTML = data.startTimestamp;
                this._status = data.status;
                // 「再生」ボタンが押下された or イベントのステータスが「再生中」の場合
                if(this._status === '1'){
                    this.status.innerHTML = '再生中';
                    domStyle.set(this.playButton, 'display', 'none');
                    domStyle.set(this.playEndButton, 'display', '');
                } else {
                    this.status.innerHTML = '再生終了';
                    domStyle.set(this.playButton, 'display', '');
                    domStyle.set(this.playEndButton, 'display', 'none');
                }
            }));
        },

        statusButtonClick: function(){
            // 再生中の場合
            if(this.status.innerHTML === '再生中'){
                // イベント再生を終了する
                var message = '再生を終了します。';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.post('/api/simulations/end/' + this._simulationId, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function() {
                        chain.infoComplete(function() {
                            this.chain.hide();
                            this.status.innerHTML = '再生終了';
                            domStyle.set(this.playButton, 'display', '');
                            domStyle.set(this.playEndButton, 'display', 'none');
                        });
                    }), lang.hitch(this, function(error) {
                        chain.infoError(error);
                        this.confirmDialog.hide();
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                });
            } else {
                 //イベント一覧画面へ遷移する
                Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
            }

        },

        /**
         * イベント一覧画面へ遷移する。
         */
        onScenarioPageLinkClick: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('simulation');
        }
    });
});

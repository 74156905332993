/**
 * 職員招集 自動招集条件詳細画面用モジュール。
 * @module app/convocation/view/condition/ConvoAutoMailDetailPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoAutoMailDetailPage.html', // テンプレート文字列
    'dojo/dom-style',
    'dojo/_base/lang',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/convocation/view/condition/_ConvoAutoMailPageBase',// 基底クラス
    'idis/model/UserInfo',
    'idis/consts/ACL',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'app/convocation/view/condition/ConvoAddGroupDialog',
    'app/convocation/view/condition/ConvoAutoMailRegisterGrid',
    'app/view/form/KisyoAreaMultiSelector',
    'app/view/form/PrefectureSelector',
    'app/view/form/WeatherWarnSelector'
], function(module, declare, template, domStyle, lang,
    Locator, Router, Requester, Loader, _ConvoAutoMailPageBase,
    UserInfo, ACL) {
    /**
     * 職員招集 自動招集条件詳細画面。
     * @class ConvoAutoMailDetailPage
     * @extends module:app/convocation/view/condition/_ConvoAutoMailPageBase~_ConvoAutoMailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ConvoAutoMailPageBase,
        /** @lends module:idis/view/page/ConvoAutoMailDetailPage~ConvoAutoMailDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.cndId = Locator.getQuery().id;
            },

            /**
             * DOMノードを生成する関数。
             */
            buildRendering: function() {
                // 継承元のモジュールの処理を実施する
                this.inherited(arguments);
                // 画面項目の初期化
                this.initPage();
                // 画面が破棄された際にダイアログも破棄する
                this.own(this.selectGroupDialog);
            },

            startup: function() {
                this.inherited(arguments);
                this.setButtonStatus();
            },

            /**
             * 画面項目の初期化を行う。
             */
            initPage: function() {
                // 招集条件名と招集パターンの取得
                Requester.get('/api/convoMails/conditions/' + this.cndId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    // 招集条件名、招集パターンをformに設定
                    this.form.set('value', data);

                    // 自動配信の場合に該当ウィジェットの編集不可・無効化
                    if (data.distType !== '99') {
                        this.settingName.set('readonly', true);
                        this.deleteButton.setDisabled(true);
                    }

                    // メール種別の非活性化
                    this.convoMailType.set('disabled', true);

                	// 質問と回答のチェック状態の復元
                    if (data.question1Text !== null) {
                        this.question1.set('checked', true);
                        this.question1Text.setDisabled(false);
                    }
                    if (data.answer1aText !== null) {
                        this.answer1a.set('checked', true);
                        this.answer1aText.setDisabled(false);
                    }
                    if (data.answer1bText !== null) {
                        this.answer1b.set('checked', true);
                        this.answer1bText.setDisabled(false);
                    }
                    if (data.answer1cText !== null) {
                        this.answer1c.set('checked', true);
                        this.answer1cText.setDisabled(false);
                    }
                    if (data.answer1dText !== null) {
                        this.answer1d.set('checked', true);
                        this.answer1dText.setDisabled(false);
                    }
                    if (data.answer1eText !== null) {
                        this.answer1e.set('checked', true);
                        this.answer1eText.setDisabled(false);
                    }
                    if (data.question2Text !== null) {
                        this.question2.set('checked', true);
                        this.question2Text.setDisabled(false);
                    }
                    if (data.answer2aText !== null) {
                        this.answer2a.set('checked', true);
                        this.answer2aText.setDisabled(false);
                    }
                    if (data.answer2bText !== null) {
                        this.answer2b.set('checked', true);
                        this.answer2bText.setDisabled(false);
                    }
                    if (data.answer2cText !== null) {
                        this.answer2c.set('checked', true);
                        this.answer2cText.setDisabled(false);
                    }
                    if (data.answer2dText !== null) {
                        this.answer2d.set('checked', true);
                        this.answer2dText.setDisabled(false);
                    }
                    if (data.answer2eText !== null) {
                        this.answer2e.set('checked', true);
                        this.answer2eText.setDisabled(false);
                    }
                    if (data.question3Text !== null) {
                        this.question3.set('checked', true);
                        this.question3Text.setDisabled(false);
                    }
                    if (data.answer3aText !== null) {
                        this.answer3a.set('checked', true);
                        this.answer3aText.setDisabled(false);
                    }
                    if (data.answer3bText !== null) {
                        this.answer3b.set('checked', true);
                        this.answer3bText.setDisabled(false);
                    }
                    if (data.answer3cText !== null) {
                        this.answer3c.set('checked', true);
                        this.answer3cText.setDisabled(false);
                    }
                    if (data.answer3dText !== null) {
                        this.answer3d.set('checked', true);
                        this.answer3dText.setDisabled(false);
                    }
                    if (data.answer3eText !== null) {
                        this.answer3e.set('checked', true);
                        this.answer3eText.setDisabled(false);
                    }
                    if (data.question4Text !== null) {
                        this.question4.set('checked', true);
                        this.question4Text.setDisabled(false);
                    }
                    if (data.answer4aText !== null) {
                        this.answer4a.set('checked', true);
                        this.answer4aText.setDisabled(false);
                    }
                    if (data.answer4bText !== null) {
                        this.answer4b.set('checked', true);
                        this.answer4bText.setDisabled(false);
                    }
                    if (data.answer4cText !== null) {
                        this.answer4c.set('checked', true);
                        this.answer4cText.setDisabled(false);
                    }
                    if (data.answer4dText !== null) {
                        this.answer4d.set('checked', true);
                        this.answer4dText.setDisabled(false);
                    }
                    if (data.answer4eText !== null) {
                        this.answer4e.set('checked', true);
                        this.answer4eText.setDisabled(false);
                    }
                    if (data.question5Text !== null) {
                        this.question5.set('checked', true);
                        this.question5Text.setDisabled(false);
                    }
                    if (data.answer5aText !== null) {
                        this.answer5a.set('checked', true);
                        this.answer5aText.setDisabled(false);
                    }
                    if (data.answer5bText !== null) {
                        this.answer5b.set('checked', true);
                        this.answer5bText.setDisabled(false);
                    }
                    if (data.answer5cText !== null) {
                        this.answer5c.set('checked', true);
                        this.answer5cText.setDisabled(false);
                    }
                    if (data.answer5dText !== null) {
                        this.answer5d.set('checked', true);
                        this.answer5dText.setDisabled(false);
                    }
                    if (data.answer5eText !== null) {
                        this.answer5e.set('checked', true);
                        this.answer5eText.setDisabled(false);
                    }

                    // 招集メールの場合のみ質問と回答を表示
                    if (this.convoMailType.value === '1') {
                        domStyle.set(this.qaArea, 'display', '');
                    } else {
                        domStyle.set(this.qaArea, 'display', 'none');
                    }

                }), lang.hitch(this, function(err) {
                    this.chain.infoError(err);
                }));
                // 条件対象グループ情報の取得
                var promise = Requester.get('/api/convoMails/conditions/groups/' + this.cndId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                })
                .then(lang.hitch(this, function(data) {
                    // グリッドに一件ずつ追加
                    data.items.forEach(lang.hitch(this, function(item) {
                        this.addDistGroupInfo({
                            id: '' + item.dpDistributionGroupId,
                            name: item.convoGroup,
                            empNum: item.convoStaffNum
                        });
                    }));
                }), lang.hitch(this, function(err) {
                    this.chain.infoError(err);
                }));
                // ローダの表示
                Loader.wait(promise);
            },

            /**
             * グループ追加ボタンが押下された際にグループ選択ダイアログを表示する。
             */
            addGroupButtonClick: function() {
                this.selectGroupDialog.show();
                if (this.convoMailType.value) {
                    // グループ選択ダイアログを取得し、グループ種別によるフィルターをかける
                    var page = this.selectGroupDialog.getChildren()[0];
                    var selector = page.dpDistributionGroupCd;
                    selector.model.setFilter(lang.hitch(this, function(item, isLeaf, model) {
                        return isLeaf && item.groupType === this.convoMailType.value;
                    }));
                    // グループ選択ダイアログにグループ種別を渡す
                    selector.groupType = this.convoMailType.value;
                }
            },

            /**
             * 更新ボタンが押下された際の挙動
             */
            onSubmit: function() {
                // 入力値チェック(実際の処理は基底クラス側で定義済み)
                if (!this.validate()) {
                    return false;
                }

                this.chain.confirmPut(function(chain) {
                    var value = this.form.get('value');
                    value.groupCd = this.getGroupCdList();
                    value.settingId = this.cndId;
                    var promise = Requester.put('/api/convoMails/conditions', {
                        data: value
                    }).then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('convocation/automail');
                        });
                    }, function(err) {
                        chain.infoError(err);
                    });
                    // ローダの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 削除ボタンが押下された際の処理。
             */
            deleteButtonClick: function() {
                // 削除確認ダイアログの表示
                this.chain.confirmDel(function(chain) {
                    var promise = Requester.del('/api/convoMails/conditions/' + this.cndId)
                    .then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('convocation/automail');
                        });
                    }, function(err) {
                        chain.infoError(err);
                    });
                    // ローダの表示
                    Loader.wait(promise);
                });
            },

            // パンくずリストのボタンを押下したときの挙動
            onConvoAutoMailAdminPageLinkClick : function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/automail');
            },
            setButtonStatus: function(){
                var role = UserInfo.getRoleCd();
                this.updateButton.setDisabled(true);
                this.deleteButton.setDisabled(true);
                if ( role === ACL.ADMIN_USER) {
                    this.updateButton.setDisabled(false);
                }
                if ( role === ACL.ADMIN_USER) {
                    this.deleteButton.setDisabled(false);
                }
            },

            /**
             * 質問と回答のチェック連動グレーアウト
             */
            onQ1CheckBox: function() {
                this.question1Text.setDisabled(true);
                if (this.question1.checked) {
                    this.question1Text.setDisabled(false);
                }
            },
            onA1aCheckBox: function() {
                this.answer1aText.setDisabled(true);
                if (this.answer1a.checked) {
                    this.answer1aText.setDisabled(false);
                }
            },
            onA1bCheckBox: function() {
                this.answer1bText.setDisabled(true);
                if (this.answer1b.checked) {
                    this.answer1bText.setDisabled(false);
                }
            },
            onA1cCheckBox: function() {
                this.answer1cText.setDisabled(true);
                if (this.answer1c.checked) {
                    this.answer1cText.setDisabled(false);
                }
            },
            onA1dCheckBox: function() {
                this.answer1dText.setDisabled(true);
                if (this.answer1d.checked) {
                    this.answer1dText.setDisabled(false);
                }
            },
            onA1eCheckBox: function() {
                this.answer1eText.setDisabled(true);
                if (this.answer1e.checked) {
                    this.answer1eText.setDisabled(false);
                }
            },
            onQ2CheckBox: function() {
                this.question2Text.setDisabled(true);
                if (this.question2.checked) {
                    this.question2Text.setDisabled(false);
                }
            },
            onA2aCheckBox: function() {
                this.answer2aText.setDisabled(true);
                if (this.answer2a.checked) {
                    this.answer2aText.setDisabled(false);
                }
            },
            onA2bCheckBox: function() {
                this.answer2bText.setDisabled(true);
                if (this.answer2b.checked) {
                    this.answer2bText.setDisabled(false);
                }
            },
            onA2cCheckBox: function() {
                this.answer2cText.setDisabled(true);
                if (this.answer2c.checked) {
                    this.answer2cText.setDisabled(false);
                }
            },
            onA2dCheckBox: function() {
                this.answer2dText.setDisabled(true);
                if (this.answer2d.checked) {
                    this.answer2dText.setDisabled(false);
                }
            },
            onA2eCheckBox: function() {
                this.answer2eText.setDisabled(true);
                if (this.answer2e.checked) {
                    this.answer2eText.setDisabled(false);
                }
            },
            onQ3CheckBox: function() {
                this.question3Text.setDisabled(true);
                if (this.question3.checked) {
                    this.question3Text.setDisabled(false);
                }
            },
            onA3aCheckBox: function() {
                this.answer3aText.setDisabled(true);
                if (this.answer3a.checked) {
                    this.answer3aText.setDisabled(false);
                }
            },
            onA3bCheckBox: function() {
                this.answer3bText.setDisabled(true);
                if (this.answer3b.checked) {
                    this.answer3bText.setDisabled(false);
                }
            },
            onA3cCheckBox: function() {
                this.answer3cText.setDisabled(true);
                if (this.answer3c.checked) {
                    this.answer3cText.setDisabled(false);
                }
            },
            onA3dCheckBox: function() {
                this.answer3dText.setDisabled(true);
                if (this.answer3d.checked) {
                    this.answer3dText.setDisabled(false);
                }
            },
            onA3eCheckBox: function() {
                this.answer3eText.setDisabled(true);
                if (this.answer3e.checked) {
                    this.answer3eText.setDisabled(false);
                }
            },
            onQ4CheckBox: function() {
                this.question4Text.setDisabled(true);
                if (this.question4.checked) {
                    this.question4Text.setDisabled(false);
                }
            },
            onA4aCheckBox: function() {
                this.answer4aText.setDisabled(true);
                if (this.answer4a.checked) {
                    this.answer4aText.setDisabled(false);
                }
            },
            onA4bCheckBox: function() {
                this.answer4bText.setDisabled(true);
                if (this.answer4b.checked) {
                    this.answer4bText.setDisabled(false);
                }
            },
            onA4cCheckBox: function() {
                this.answer4cText.setDisabled(true);
                if (this.answer4c.checked) {
                    this.answer4cText.setDisabled(false);
                }
            },
            onA4dCheckBox: function() {
                this.answer4dText.setDisabled(true);
                if (this.answer4d.checked) {
                    this.answer4dText.setDisabled(false);
                }
            },
            onA4eCheckBox: function() {
                this.answer4eText.setDisabled(true);
                if (this.answer4e.checked) {
                    this.answer4eText.setDisabled(false);
                }
            },
            onQ5CheckBox: function() {
                this.question5Text.setDisabled(true);
                if (this.question5.checked) {
                    this.question5Text.setDisabled(false);
                }
            },
            onA5aCheckBox: function() {
                this.answer5aText.setDisabled(true);
                if (this.answer5a.checked) {
                    this.answer5aText.setDisabled(false);
                }
            },
            onA5bCheckBox: function() {
                this.answer5bText.setDisabled(true);
                if (this.answer5b.checked) {
                    this.answer5bText.setDisabled(false);
                }
            },
            onA5cCheckBox: function() {
                this.answer5cText.setDisabled(true);
                if (this.answer5c.checked) {
                    this.answer5cText.setDisabled(false);
                }
            },
            onA5dCheckBox: function() {
                this.answer5dText.setDisabled(true);
                if (this.answer5d.checked) {
                    this.answer5dText.setDisabled(false);
                }
            },
            onA5eCheckBox: function() {
                this.answer5eText.setDisabled(true);
                if (this.answer5e.checked) {
                    this.answer5eText.setDisabled(false);
                }
            }
        });
});

/**
 * 市町切替用モジュール。
 * @module idis/view/form/MunicipalitySelectForm
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/MunicipalitySelectForm.html',
    'dojo/topic',
    '../../control/Locator',
    '../_IdisWidgetBase',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/_TemplatedMixin',
    'app/view/form/RegionSelector',
    'dijit/form/Select'
],function(module, array, declare, lang, template,
    topic, Locator, _IdisWidgetBase, USER_TYPE, UserInfo, Requester, config){

    /**
     * 自動更新間隔（ミリ秒）
     * @type {number}
     * @private
     */
    var _UPDATE_INTERVAL = 5 * 60 * 1000; // 5分ごとに更新
    var PREF_CD = config.municInfo.prefMunicCd;
    
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/form/MunicipalitySelectForm~MunicipalitySelectForm# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 市町情報配列。
         * @type {Object[]}
         * @private
         */
        municipalityList: [],

        _isSecondOrLaterSelectedMunic: false,

        // DOM要素を構築する
        buildRendering: function() {
            this.inherited(arguments);

            // セレクトボックスのスタイル設定
            this.municipalitySelect.set('style', {width: '10em'});
            this.municipalitySelect.set('style', {color: '#4c4c4c'});

            Requester.get('/data/municipality/municipalityList.json').then(lang.hitch(this,function(obj) {
                // 市町緯度経度情報を保持する。
                this.municipalityList = obj.municipalities;

                // vaeluにmunicipalityCd、labelに市町名セットしてオプション配列を組み立てる
                var options = array.map(this.municipalityList, function(munic) {
                    return {
                        value: munic.municipalityCd,
                        label: munic.name
                    };
                });
                // セレクトボックスにオプションをセットする。
                this.municipalitySelect.set('options', options);

                // ユーザーに応じて、市町を選択
                var selectedMunicipality = '';
                switch (UserInfo.getUserType()) {
                    case USER_TYPE.MUNICIPALITY:
                        // 市町ユーザーの場合、所属市町を選択
                        selectedMunicipality = UserInfo.getMunicipalityCd();
                        break;
                    case USER_TYPE.PREFECTURE:
                    case USER_TYPE.REGION:
                        // 県ユーザ・振興局ユーザーの場合、長崎県を選択
                        selectedMunicipality = PREF_CD;
                        break;
                    default:
                        // その他ユーザの場合、長崎県を選択
                        selectedMunicipality = PREF_CD;
                        break;
                }

                this.municipalitySelect.set('value', selectedMunicipality);
                // 初期値の位置に地図を移動させる。
                this.selectMunicipality(selectedMunicipality);
            }));
        },

        /**
         * 最後に選択した市町を一定時間ごとに再選択し、情報を最新化する。
         * @param {string} municipalityCd 市町コード
         * @private
         */
        _reserveUpdate: function(municipalityCd) {
            this._updateTimeoutId = setTimeout(lang.hitch(this, function() {
                // 引数の市町コードから振興局を取得する
                var municipality = this._lastMunicipality = array.filter(this.municipalityList, function(munic) {
                    return munic.municipalityCd === municipalityCd;
                })[0];
                // 同一市町情報の最新化を要請
                topic.publish(module.id + '::selected',
                    {municipalityCd: municipalityCd, regionCd: municipality.regionCd});
                // 次回の処理を予約
                this._reserveUpdate(municipalityCd);
            }), _UPDATE_INTERVAL);
        },

        /**
         * 市町を切り替えて地図を移動する。その後、ユーザ情報に選択している市町を設定する。
         */
        selectMunicipality: function(municipalityCd){
            // ユーザー独自の中心座標をinitMapで設定しているため初回は中断する
            if(!this._isSecondOrLaterSelectedMunic){
                this._isSecondOrLaterSelectedMunic = true;
                return;
            }
            // 更新を予約していた場合は解除
            clearTimeout(this._updateTimeoutId);
            //引数の市町コードから市町の緯度経度を取得する
            var municipality = this._lastMunicipality = array.filter(this.municipalityList, function(munic) {
                return munic.municipalityCd === municipalityCd;
            })[0];
            // URLに反映する。
            Locator.pushState({
                ll: municipality.latlng.lat + ',' + municipality.latlng.lng,
                z: municipality.zoom
            });

            UserInfo.setSelectedMunicipalityCd(municipalityCd);
            UserInfo.setSelectedRegionCd(municipality.regionCd);

            //緯度経度を指定してMonitorページにPubする
            topic.publish(module.id + '::selected', {
                municipalityCd: municipalityCd,
                regionCd: municipality.regionCd,
                latlng: municipality.latlng,
                zoom: municipality.zoom
            });
            console.debug('Selecte Municipality Latlng is :' + municipality.latlng.lat + ',' + municipality.latlng.lng);

            // 一定時間後の最新化を予約
            this._reserveUpdate(municipalityCd);
        }
    });
});

define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/_base/array',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
	'dojo/dom-construct',
	'dojo/topic',
	'dgrid/Selection',
	'dgrid/Selector',
	'dgrid/Keyboard'
], function (module, declare, lang, array, IdisGrid, helper, domCon, topic, Selection, Selector, Keyboard) {

	// var DAMAGE_ATC_FILE_THUMBNAIL_SUFFIX = '_thumbnail';

	return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--damage',


		/**
		 * 各列の定義
		 * @type {Object[]}
		 */

		columns: [
			// ボタン
			helper.buttonColumn('detail', '詳細'),
			{
				field: 'attachFiles',
				id: 'attachFiles',
				label: '写真',
				sortable: false,
				renderCell: function (items) {
					var attachNode = domCon.create('span');
					if (items.attachFiles && items.attachFiles.length !== 0) {
						var attachFile = null;
						array.some(items.attachFiles, function (item) {
							var fileName = item.attachFileName;
							var delFlg = item.delFlg;
							if (delFlg === 'true') {
								return false;
							}
							if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
								fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
								fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
								fileName.indexOf('.gif') !== -1) {
								attachFile = item;
								return true;
							}
							return false;
						});
						if (attachFile) {
							var path = attachFile.attachFilePath.replace('out', 'data');
							var node = domCon.create('img', {
								src: path,
								width: '100%',
								style: { maxWidth: '10em' }
							});
							domCon.place(node, attachNode);
							domCon.place('<br>', attachNode);
						}
						return attachNode;
					}
				}
			},
			{ field: 'admNum', label: '管理番号'},
			// {field: 'reportName', label: '報告名', sortable: false},

			helper.column('seqNum', '連番', {
				formatter: function (value) {
					return '第' + value + '報';
				}
			}),
			{
				field: 'damageAddress', label: '住所', formatter: function (value, item) {
					return value + (item.damageAddressNum || '');
				}
			},
			helper.column('damageTypeList', '被害種別', {
				formatter: function (val) {
					var damageTypeList = val.split(',');
					var damageType = {
						'01': '住家被害',
						'02': 'ライフライン（電気、水道等）',
						'03': '文教施設（学校等）',
						'04': '病院',
						'05': '庁舎等施設',
						'06': '土砂災害',
						'07': '火災',
						'08': '河川',
						'09': '道路',
						'10': '農地・農業用施設被害',
						'14': '林野被害',
						'15': '港湾被害',
						'11': 'その他被害',
						'12': '簡易報告',
						'13': '現地画像'
					};
					for (var i = 0; i < damageTypeList.length; i++) {
						damageTypeList[i] = damageType[damageTypeList[i]];
					}
					return damageTypeList.join('、');
				}
			}),
			helper.column('humanDamageFlg', '人的被害', {
				formatMap: { '0': 'あり', '1': 'なし' }
			}),
			{
				field: 'urgencyType',
				label: '緊急度',
				className: lang.hitch(this, function (item) {
					// ヘッダーの場合はclassNameを返さない
					if (!item) {
						return;
					}
					if (item.urgencyType === '1') {
						return 'low-priority';
					} else if (item.urgencyType === '2') {
						return 'normal-priority';
					} else if (item.urgencyType === '3') {
						return 'high-priority';
					}
				}),
				formatter: lang.hitch(this, function (value, item) {
					if (!item) {
						return;
					}
					if (item.urgencyType === '0') {
						return '-';
					} else if (item.urgencyType === '1') {
						return '低';
					} else if (item.urgencyType === '2') {
						return '中';
					} else if (item.urgencyType === '3') {
						return '高';
					} else {
						return 'なし';
					}
				})
			},
			helper.column('hldOrganizationName', '対応課', {
				formatter: function (value) {
					if (!value) {
						return '-';
					}
					if (value.length > 20) {
						return value.substring(0, 19) + '...';
					}
					return value;
				},
				sortable: false
			}),
			helper.column('hldStatus', '対応状況', {
				formatMap: {
					'0': '確認中',
					'1': '対応待ち',
					'2': '対応中',
					'3': '対応完了'
				}
			}),
			{ field: 'reportCrtTimestamp', label: '報告日時' },
			{ field: 'reportUpdTimestamp', label: '更新日時' },
			{ field: 'reportAuthorName', label: '報告者名', sortable: false },
			helper.column('reportStatus', '報告状況', {
				formatMap: { '11': '庁内', '31': '県', '41': '公開' },
				classMap: {'11':'not-reported', '12':'head-reported', '21':'bureau-reported',
					'31':'pref-reported', '41':'all-reported'}
			})
		],

		renderRow: function (item) {
			var div = this.inherited(arguments);
			topic.publish(module.id + '::drawIcon', item);
			return div;
		}

	});
});

/**
 * 避難状況画面の地区選択ツリー用モデル
 * @module app/evacorder/EvacOrderStoreModel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojox/lang/functional/object',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
    // './Reason'
], function(module, array, declare, df, CacheStoreModel, CacheTreeRest /*, Reason*/) {
    /**
     * 各避難区分のツリー表示
     * @type {Object}
     * @private
     */

    /**
     * 避難状況の地区選択用ツリー
     * @param {Object} kwArgs
     * @param {string} kwArgs.disasterId 災害ID
     * @param {string} kwArgs.municipalityCd 市町村コード
     * @class EvacOrderStoreModel
     * @extends module:idis/store/CacheStoreModel~CacheStoreModel
     */
    return declare(CacheStoreModel, /** @lends module:app/evacorder/EvacOrderStoreModel~EvacOrderStoreModel# */ {
        constructor: function(kwArgs) {
            // 地区一覧用storeを生成
            this.store = new CacheTreeRest({
                target: '/api/evacorders/latestSituation',
                targetQuery: {
                    disasterId: kwArgs.disasterId,
                    municipalityCd: kwArgs.municipalityCd
                }
            });
        }
    });
});

/**
 * 施設管理画面用モジュール。
 * @module app/facility/FacilityAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityAdminPage.html',
    'dojo/request/iframe',
    'idis/control/Router',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    './FacilityGrid',
    './FacilityTypeGrid',
    './FacilityTypeRegisterDialog',
    './FacilityFormDialog',
    './FacilityFileUploadDialog',
    './FacilityTypeDetailDialog',
    'app/view/form/CustomizableFacilitiesSelector'
], function(module, declare, lang, Deferred, JSON, domStyle, template, iframe, Router, _PageBase,
        IdisRest, Loader, DialogChain, Requester, DisasterInfo, UserInfo, Region) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var filterStore = {};
    /**
     * 施設管理画面
     * @class FacilityAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/facility/FacilityAdminPage~FacilityAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--facility',

        // 施設情報用ストア
        facilityStore: null,

        // 施設種別用ストア
        facilityTypeStore: null,

        // グリッド(施設情報)
        facilityGrid: null,

        // グリッド(施設種別)
        facilityTypeGrid: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.facilityTypeRegisterDialog);
            this.own(this.facilityTypeDetailDialog);
            this.own(this.facilityFormDialog);
            this.own(this.fileUploadDialog);
            this.initRegisterPage();
            this.initDetailPage();
            this.initSelectBox(); //施設種別のデータを取得した後、フィルタに条件をセットする
            this.initFileUploadDialog();
            //管理ユーザ以外は施設種別管理タブを非表示にする。
            if (!UserInfo.isSysAdmin()){
                this.tabContainer.removeChild(this.facilityTypeTab);
            }
        },

        startup: function() {
            this.inherited(arguments);
            this.initFacilityGrid();
            this.initFacilityTypeGrid();
        },

        constructor: function() {
            this.facilityStore = new IdisRest({
                idProperty: 'facilityId',
                target: '/api/facility'
            });
            this.facilityTypeStore = new IdisRest({
                idProperty: 'facilityTypeId',
                target: '/api/facility/category'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        // 施設種別を避難所に設定した時の処理
        onChangeFacilityTypeIdFlg: function(){
            var facilityTypeId = this.form.get('value').facilityTypeId;

            if(facilityTypeId === '01'){
                // 避難所区分を表示
                domStyle.set(this.shelterCategory, 'display', '');
                // 検索欄の表示を調整
                this.searchContentPane.resize({h: (facilityTypeId === '01' ? 158 : 0)});
                this.borderContainer.layout();
            } else {
                /// 検索欄の表示を調整
                this.searchContentPane.resize({h: (facilityTypeId !== '01' ? 128 : 0)});
                this.borderContainer.layout();
                // 避難所区分を非表示
                domStyle.set(this.shelterCategory, 'display', 'none');
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateFacilityGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.facilityStore.Filter();
            if (value.searchAreaCd) {
                filter = filter.eq('searchAreaCd', value.searchAreaCd);
            }
            if (value.facilityTypeId) {
                filter = filter.eq('facilityTypeId', value.facilityTypeId);
            }

            if (value.facilityName) {
                filter = filter.eq('facilityName', value.facilityName);
            }

            if (value.address) {
                filter = filter.eq('address', value.address);
            }

            if (value.availableFlg) {
                filter = filter.eq('availableFlg', value.availableFlg);
            }

            if (value.publicFlg) {
                filter = filter.eq('publicFlg', value.publicFlg);
            }

            // 避難所区分
            var flg1 = value.welfareEvacShFlg;
            if (flg1 && flg1.length === 0) {
                filter = filter.eq('welfareEvacShFlg', '');
            }else{
                filter = filter.eq('welfareEvacShFlg', '1');
            }
            var flg2 = value.eDesignatedEvacShFlg;
            if (flg2 && flg2.length === 0) {
                filter = filter.eq('eDesignatedEvacShFlg', '');
            }else{
                filter = filter.eq('eDesignatedEvacShFlg', '1');
            }
            var flg3 = value.designatedEvacShFlg;
            if (flg3 && flg3.length === 0) {
                filter = filter.eq('designatedEvacShFlg', '');
            }else{
                filter = filter.eq('designatedEvacShFlg', '1');
            }
            var flg4 = value.temporaryEvacShFlg;
            if (flg4 && flg4.length === 0) {
                filter = filter.eq('temporaryEvacShFlg', '');
            }else{
                filter = filter.eq('temporaryEvacShFlg', '1');
            }
            var flg5 = value.civilProtectionEvacShFlg;
            if (flg5 && flg5.length === 0) {
                filter = filter.eq('civilProtectionEvacShFlg', '');
            }else{
                filter = filter.eq('civilProtectionEvacShFlg', '1');
            }
            var flg6 = value.otherEvacShFlg;
            if (flg6 && flg6.length === 0) {
                filter = filter.eq('otherEvacShFlg', '');
            }else{
                filter = filter.eq('otherEvacShFlg', '1');
            }

            // filterに対応するcollectionを取得
            var collection = this.facilityStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.facilityGrid.set('collection', collection);
            this.setFilterStore();
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
			}));
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');
            filterStore = value;
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
            //保管されていれば値をセット
            this.form.set('value', filterStore);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateFacilityGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ファイルアプロードダイアログ初期化処理
         */
        initFileUploadDialog: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.fileUploadDialog;
            var page = dialog.getChildren()[0];
            // 更新ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function(evt) {
                console.log(evt.attachFile._files);
                //ファイルが空の場合は処理を中断
                if(evt.attachFile._files.length === 0) {
                    return;
                }
                // 選択されたファイルタイプに応じて呼び出すAPIを変更
                var type = evt.fileType;
                var reqid = null;
                // 拡張子をファイルによって変更
                var ex = null;
                // 政令指定市の避難勧告等発令状況一覧
                if (type === '01') {
                    reqid = 'FACILITY_LIST_IN';
                    ex = '.xlsx';
                }
                // リクエスト先のxoblosAPIのパス
                var url = '/bousai/Upload/Upload?reqid=' + reqid + '&disid=' + this._disasterId;
                // xoblosから見たときのファイルパス
                var path = '\\192.168.11.31\\in\\upload\\' + evt.FileId + '\\' + evt.FileId + ex;
                // ファイルをサーバーにPOSTする
                // TODO:pathを正しく設定
                var promise = iframe.post(url, {
                    path: path,
                    errpath: path,
                    errmsgpath: path,
                    handleAs: 'json',
                    mimeType: 'application/json'
                }).then(lang.hitch(this, function() {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                    // ファイルアップローダー部品をリセットする
                    evt.attachFile.reset();
                    // ダイアログを非表示
                    dialog.hide();
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    evt.attachFile.reset();
                    this.chain.infoError(error);
                }));
                // ローダーの表示
                Loader.wait(promise);
            }));
        },

        showFileUploadDialog: function() {
            this.fileUploadDialog.show();
        },

        initFacilityGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.facilityGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.onDetailButtonClick(evt.item);
            }));
        },


        initFacilityTypeGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            this.facilityTypeGrid.set('collection', this.facilityTypeStore.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.facilityTypeGrid.on('typeDetailButtonClick', lang.hitch(this, function(evt) {
                this.showFacilityTypeDetailDialog(evt.item);
            }));
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.facilityTypeRegisterDialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('sendregister', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(lang.hitch(this, function(chain) {
                    var promise = this.facilityTypeStore.add(evt.value);
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        chain.infoComplete();
                        dialog.hide();
                        this.initSelectBox();
                        this.initFacilityTypeGrid();
                    }),function(err) {
                        chain.infoError(err);
                    });
                }));
            }));
        },

        /**
         * 詳細画面のフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.facilityTypeDetailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('sendupdate', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    var promise = this.facilityTypeStore.put(evt.value);
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        chain.infoComplete();
                        dialog.hide();
                        this.initSelectBox();
                        this.initFacilityTypeGrid();
                    }), function(err) {
                        chain.infoError(err);
                    });
                });
            }));
            page.on('senddelete', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    var promise = this.facilityTypeStore.remove(evt.value.facilityTypeId);
                    Loader.wait(promise).then(lang.hitch(this,function() {
                        dialog.hide();
                        chain.infoComplete();
                        this.initSelectBox();
                        this.initFacilityTypeGrid();
                    }), function(err) {
                        chain.infoError(err);
                    });
                });
            }));
        },

        // 施設種別セレクトボックスの初期化を行う
        initSelectBox: function(){
            var promise = Requester.get('/api/facility/category');
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '&nbsp;',
                    value: ''
                }];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function(object){
                    newOptions.push({
                    label: object.facilityTypeName,
                    value: object.facilityTypeId
                    });
                });
                this.facilityTypeSelectBox.set('options',newOptions);
                //フィルタのデータをセットする。(データの取得と非同期処理が必要)
                this.setFilterData();
                this.updateFacilityGridQuery(this.form.get('value'));
            }));
        },

        onRegisterButtonClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            this.setFilterStore();
            // 施設登録画面へ遷移
            Router.moveTo('facility/register', {
                type : ''
            });
        },

        onDetailButtonClick: function(object) {
            this.setFilterStore();
            // 施設情報詳細画面へ遷移
            Router.moveTo('facility/detail', {
                facilityId: object.facilityId
            });
        },

        showFacilityTypeRegisterDialog: function() {
            this.innerResisterDialog.setPage();
            this.facilityTypeRegisterDialog.show();
        },

        showFacilityTypeDetailDialog: function(object) {
            // ダイアログの最初の子要素が詳細画面
            var dialog = this.facilityTypeDetailDialog;
            var page = dialog.getChildren()[0];
            // 画面上のフォームをリセット
            page.form.reset();
            // 行データの内容をフォームに反映
            page.form.set('value',object);
            // 詳細ダイアログを表示
            this.innerDetailDialog.setPage(object);
            this.facilityTypeDetailDialog.show();
        },

        filterByType: function(value) {
            alert(value);
        },

		//帳票出力
        outputListExcel : function() {
            this.innerfacilityFormDialog.initDialog();
            this.facilityFormDialog.show();
		},

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();
            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '';
                switch (inputFileName) {
                    case 'FacilityList.xlsx':
                        baseFileName = '施設情報一覧.xlsx';
                        break;
                }
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        }
    });
});

define([
    'leaflet',
], function(leaflet) {

    var Touch = leaflet.Map.TouchExtend = leaflet.Handler.extend({

        initialize: function (map) {
            this._map = map;
            this._container = map._container;
            this._pane = map._panes.overlayPane;
        },
    
        addHooks: function () {
            leaflet.DomEvent.on(this._container, 'touchstart', this._onTouchStart, this);
            leaflet.DomEvent.on(this._container, 'touchend', this._onTouchEnd, this);
            leaflet.DomEvent.on(this._container, 'touchcancel', this._onTouchCancel, this);
            leaflet.DomEvent.on(this._container, 'touchleave', this._onTouchLeave, this);
            leaflet.DomEvent.on(this._container, 'touchmove', this._onTouchMove, this);
        },
    
        removeHooks: function () {
            leaflet.DomEvent.off(this._container, 'touchstart', this._onTouchStart, this);
            leaflet.DomEvent.off(this._container, 'touchend', this._onTouchEnd, this);
            leaflet.DomEvent.off(this._container, 'touchcancel', this._onTouchCancel, this);
            leaflet.DomEvent.off(this._container, 'touchleave', this._onTouchLeave, this);
            leaflet.DomEvent.off(this._container, 'touchmove', this._onTouchMove, this);
        },
    
        _touchEvent: function (e, type) {
            // #TODO: fix the pageX error that is do a bug in Android where a single touch triggers two click events
            // _filterClick is what leaflet uses as a workaround.
            // This is a problem with more things than just android. Another problem is touchEnd has no touches in
            // its touch list.
            console.log(type);
            if (type === 'touchend') {
                this._map.fire(type, {});
                return;
            }
            if (!e.touches.length) { return; }
            var containerPoint = this._map.mouseEventToContainerPoint(e.touches[0]),
                layerPoint = this._map.mouseEventToLayerPoint(e.touches[0]),
                latlng = this._map.layerPointToLatLng(layerPoint);
    
            this._map.fire(type, {
                latlng: latlng,
                layerPoint: layerPoint,
                containerPoint: containerPoint,
                pageX: e.touches[0].pageX,
                pageY: e.touches[0].pageY,
                originalEvent: e
            });
        },
    
        _onTouchStart: function (e) {
            if (!this._map._loaded) { return; }
    
            var type = 'touchstart';
            this._touchEvent(e, type);
    
        },
    
        _onTouchEnd: function (e) {
            if (!this._map._loaded) { return; }
    
            var type = 'touchend';
            this._touchEvent(e, type);
        },
    
        _onTouchCancel: function (e) {
            if (!this._map._loaded) { return; }
    
            var type = 'touchcancel';
            this._touchEvent(e, type);
        },
    
        _onTouchLeave: function (e) {
            if (!this._map._loaded) { return; }
    
            var type = 'touchleave';
            this._touchEvent(e, type);
        },
    
        _onTouchMove: function (e) {
            if (!this._map._loaded) { return; }
    
            var type = 'touchmove';
            this._touchEvent(e, type);
        }
    });
    return Touch;
});

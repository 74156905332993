/**
 * 気象警報・注意報用の基底モジュール。
 * @module app/weatherinfo/view/_weatherinfoPageBase
 */
define([
    'module',
	'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'idis/view/page/_PageBase',
    '../model/kindCode',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector'
], function(module, array, declare, lang, domClass, _PageBase, kindCode) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            this.setSpecialColumnsDispaly();
            this.weatherInfoGrid.set('collection', this.store.filter());
        },

        /**
         * 特別警報の表示非表示を設定する
         */
        setSpecialColumnsDispaly: function() {
            // 特別警報コードがないなら、列を非表示にする
            var noSpecialWarn = true;
            this.store.filter().forEach(function(item){
                if(item&&noSpecialWarn){
                    noSpecialWarn = array.every(Object.keys(item), function(code) {
                        return kindCode.getSpecialCodes().indexOf(code)<0;
                    });
                }
            }).then(lang.hitch(this, function(){
                if(noSpecialWarn){
                    domClass.add(this.weatherInfoGrid.domNode, 'is-no-spWarn');
                } else {
                    domClass.remove(this.weatherInfoGrid.domNode, 'is-no-spWarn');
                }
                this.borderContainer.resize();
            }));
        }
    });
});

/**
 * 火山詳細画面
 * @module app/volcano/eruption/VolcanoDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/VolcanoDetailPage.html',
    '../_DetailPaneBase',
    'app/config',
    'idis/control/Locator',
    'idis/service/Requester',
    'dojo/_base/array',
    'idis/util/DateUtils',
    'dstore/Memory',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    './VolcanoDetailGrid'
], function(module, declare, lang, template, _PageBase,
    config, Locator, Requester, array, DateUtils, Memory) {
    /**
     * 避難所詳細画面
     * @class VolcanoDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/volcano/eruption/VolcanoDetailPage~VolcanoDetailPage# */
        {
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--volcano',

            // テンプレート文字列
            templateString: template,

            /** 火山警報・注意報（対象市町村の防災対応等） */
            // レイヤーID
            MUNICIPLAITY_ALERT_LAYER_ID: '61',

            /** 火山警報・注意報（火山） */
            // レイヤーID
            VOLCANO_ALERT_LAYER_ID: '58',

            // 警報状況解除
            VOLCANO_WARNING_CONDITION_RELEASE: '解除',

            // 火山名
            _VOLCANO_NAME: '',

            constructor: function() {
                this.inherited(arguments);
                this.volcanoEventId = Locator.getQuery().volcanoEventId;
            },

            startup: function() {
                this.inherited(arguments);
                this.initMap();
                this.setContent();
                this.initGrid();
            },


            /**
             * グリッドを初期化する。
             */
            initGrid: function() {
                Requester.get('/api/volcanos/detail?volcanoEventId=' + this.volcanoEventId)
                    .then(lang.hitch(this, function(response) {

                        //IDを振る
                        array.forEach(response, lang.hitch(this, function(row, idx) {
                            row.id = idx;
                        }));

                        //gridに入れる内容を熊本県に絞る
                        var responseUsedInGrid = response.items.filter(function(item) {
                            var strAreaCode = String(item.areaICode);
                            return strAreaCode.length > 2 &&
                                strAreaCode.substring(0, 2) === config.municInfo.prePrefCd;
                        });

                        //Grid用のstoreを作りつつ、gridにset
                        this.volGrid.set('collection', new Memory({
                            data: responseUsedInGrid,
                            idProperty: 'id'
                        }));

                        // 対象市町村の防災対応等があれば、市町村別レイヤーを表示
                        this._hazMunicWarning = array.some(responseUsedInGrid, function(item) {
                            return [41, 42, 43, 48, 49, 45].indexOf(item.vwCode) > -1;
                        });
                        if (this._hazMunicWarning) {
                            this.initMunicLayer(response.items);
                        }
                    }));
            },

            /**
             * 火山警報・注意報（対象市町村の防災対応等）レイヤーを表示する
             *
             * @param {List} data 噴火警報データ
             */
            initMunicLayer: function(data) {
                // レイヤーデータ取得
                // 解除以外の警報のみ地図表示対象とする
                var layerTargetList = array.filter(data, function(item) {
                    return item.condition !== this.VOLCANO_WARNING_CONDITION_RELEASE;
                }, this);
                // レイヤーに必要な情報を取得
                var layerData = {
                    lalerts : array.map(layerTargetList, function(item) {
                        return {
                                    areasCode: item.areaICode.toString(10),
                                    maxVolcanoWarningCode: item.vwCode,
                                    warnings: [{
                                        volcanoName : this._VOLCANO_NAME,
                                        warningCode : item.vwCode,
                                        warningName : item.vwName
                                    }]
                                };
                }, this)};
                // レイヤーを追加
                this.addGeoJSONLayerWithStatus(layerData,
                    this.MUNICIPLAITY_ALERT_LAYER_ID, this.POLYGON_BASE_LAYER_URL);
            },

            /**
             * 火山警報・注意報（火山）レイヤーを表示する
             *
             * @param {List} data 噴火警報データ
             */
            initVolcanoLayer: function(data) {
                // レイヤーデータ取得
                // 解除なら表示しない
                if (data.condition === this.VOLCANO_WARNING_CONDITION_RELEASE) {
                    return;
                }
                // レイヤーに必要な情報を取得
                var layerData = { lalerts : [{
                        pvName: data.volcanoName,
                        // 警報コードの下一桁＝警戒レベル
                        level: String(data.vwcode).substring(1)
                }]};
                // 火山別警報レイヤーを追加
                // レイヤーを追加
                this.addGeoJSONLayerWithStatus(layerData,
                    this.VOLCANO_ALERT_LAYER_ID, this.POINT_BASE_LAYER_URL);
            },

            /**
             * コンテンツの中身を表示する
             */
            setContent: function() {
                Requester.get('/api/volcanos/detail/dtlContent?volcanoEventId=' + this.volcanoEventId)
                    .then(lang.hitch(this, function(response) {
                        // volcano
                        var rowsHeaderTopBriefInfo = document.getElementById('rowsHeaderTopBriefInfo');
                        rowsHeaderTopBriefInfo.style.backgroundColor = '#999999';
                        rowsHeaderTopBriefInfo.innerHTML = '<b>&lt;噴火警報・予報&gt;</b>';
                        var rowsReportDateTime = document.getElementById('rowsReportDateTime');
                        rowsReportDateTime.style.backgroundColor = 'lightgrey';
                        rowsReportDateTime.innerHTML = '&lt;発表時刻&gt;';
                        var reportDateTime = document.getElementById('reportDateTime');
                        reportDateTime.innerHTML = DateUtils.format(response.reportDatetime);

                        var rowVolName = document.getElementById('rowVolName');
                        rowVolName.style.backgroundColor = 'lightgrey';
                        rowVolName.innerHTML = '&lt;火山名&gt;';
                        var volName = document.getElementById('volName');
                        volName.innerHTML = response.volcanoName;

                        var rowEdOffice = document.getElementById('rowEdOffice');
                        rowEdOffice.style.backgroundColor = 'lightgrey';
                        rowEdOffice.innerHTML = '&lt;編集官署&gt;';
                        var edOffice = document.getElementById('edOffice');
                        edOffice.innerHTML = response.volEdOffice;

                        var overview = document.getElementById('overview');
                        overview.style.backgroundColor = '#AAAAAA';
                        overview.innerHTML = '&lt;発表概要&gt;';

                        var rowHeadLine = document.getElementById('rowHeadLine');
                        rowHeadLine.style.backgroundColor = 'lightgrey';
                        rowHeadLine.innerHTML = '&lt;見出し&gt;';
                        var headLine = document.getElementById('headLine');
                        headLine.innerHTML = response.volHeadline;

                        var rowVolActivity = document.getElementById('rowVolActivity');
                        rowVolActivity.style.backgroundColor = 'lightgrey';
                        rowVolActivity.innerHTML = '&lt;詳細&gt;';
                        var volActivity = document.getElementById('volActivity');
                        volActivity.innerHTML = response.volActivity;

                        var rowVolPrevention = document.getElementById('rowVolPrevention');
                        rowVolPrevention.style.backgroundColor = 'lightgrey';
                        rowVolPrevention.innerHTML = '&lt;防災上の注意&gt;';
                        var volPrevention = document.getElementById('volPrevention');
                        volPrevention.innerHTML = response.volPrevention;

                        this.layout();
                        // 火山名を取得
                        this._VOLCANO_NAME = response.volcanoName;
                        // 火山別レイヤーを表示
                        this.initVolcanoLayer(response);
                    })
                    );
            },

            /**
             * 凡例ダイアログを表示する。
             */
            toggleLegendDialog: function() {
                var layerIds = [this.VOLCANO_ALERT_LAYER_ID];
                if (this._hazMunicWarning) {
                    layerIds.push(this.MUNICIPLAITY_ALERT_LAYER_ID);
                }
                this._toggleLegendDialog(layerIds);
            }
        }
    );
});
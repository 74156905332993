/**
 * 危機管理型水位計観測局選択用入力パーツ
 * @module app/view/form/CrisisManageObservationSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, TreeChecker, CacheStoreModel, CacheTreeRest) {
    /**
     * 危機管理型水位計観測局選択用パーツ。
     * @class CrisisManageObservationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/form/CrisisManageObservationSelector~CrisisManageObservationSelector# */ {
        // 選択ダイアログのタイトル
        title: '危機管理型水位計観測局選択',

        constructor: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/observationStation/crisisManegeObservation4Tree'
                })
            });
        }
    });
});

/**
* 被害報画面用モジュール。
* @module app/damage/DamageReportPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/text!./templates/DamageReportPage.html',
    'dojo',
    'dojo/dom-construct',
    'dojo/topic',
    './_DamageReportPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/map/IdisMap',
    '../config',
    'dijit/form/Select',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/NumberTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/RadioButton',
    'dojox/form/Uploader',
    '../view/form/DisasterSelector',
    'app/view/form/MunicOrganizationSelector',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    './DamageReportGrid',
    'app/damage/DamageReportFormDialog',
    'app/damage/DamageReportFileUploadDialog',
    'app/view/form/CustomizableMunicipalitySelector'
], function (module, declare, lang, array, domStyle, template, dojo, domCon, topic,
    _DamageReportPageBase, Locator, Router, IdisRest, UserInfo, UserType,
    IdisMap, config, Select) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var damageFilterStore = {};

    /**
    * 被害報画面。
    * @class DamageReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/DamageReportPage~DamageReportPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            //grid中に写真のカラムを表示するか
            displayPicFlg: true,

            //地図上の描画
            drawnDamageIds: [],
            divIconMarkers: [],

            /**
             * 地図を動的に描画・操作するために発行するトピック
             */
            DRAW_ICON: 'app/damage/DamageReportGrid' + '::drawIcon',


            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'damageReportId',
                    target: '/api/damageReports',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });

                //地図アイコンに関する情報を初期化
                this.drawnDamageIds = [];
                this.divIconMarkers = [];
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.formDialog);
                this.own(this.fileUploadDialog);
                this.initFileUploadDialog();

            },

            startup: function () {

                this.inherited(arguments);

                // 新規登録ボタン・定時報告ボタンの活性/非活性を初期化する
                this.initButtonStatus();

                // 地図を初期化する
                this.initMap(config.map.latitude, config.map.longitude);

                // グリッドが描画された際に地図にも位置情報を描画する
                var drawIconEvent = topic.subscribe(this.DRAW_ICON, lang.hitch(this, function (arg) {
                    this.addMarker(arg);
                }));

                this.own(topic.subscribe(this.DAMAGE_TYPE_SELECTED, lang.hitch(this, function (data) {
                    this.setDamageType(data);
                })));
    
                this.own(topic.subscribe(this.DAMAGE_TYPE_RELEASED, lang.hitch(this, function (data) {
                    this.releaseDamageType(data);
                })));

                this._events.push(drawIconEvent);

                // 初期選択する市町村情報をセット
                this.initMunicipalitySelector();
                this.onMunicipalityChange();

                // // 県のユーザ以外の場合、概況リンクを不可視
                // if(UserInfo.getUserType() !== USER_TYPE.PREFECTURE) {
                // // if (UserInfo.getUserType() === UserType.MUNICIPALITY &&
                // //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                //     domStyle.set(this.overviewLabel, 'display', 'none');
                // }
                // その他関係機関ユーザの場合、新規登録ボタンを不可視（4機関共有ユーザは可視。暫定）
                // if(UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                //     if(!UserInfo.isShareRole()){
                //         domStyle.set(this.registerButton.domNode, 'display', 'none');
                //         domStyle.set(this.fileUploadButton.domNode, 'display', 'none');
                //     }
                // } else
                if (UserInfo.getUserType() === UserType.PREFECTURE &&
                    this._municipalityCd.toString().substring(0,1) === 'D'){
                        // ログインユーザが長崎県かつ選択された被害報告がその他関係機関の場合、新規登録ボタンを不可視（暫定）
                        domStyle.set(this.registerButton.domNode, 'display', 'none');
                        domStyle.set(this.fileUploadButton.domNode, 'display', 'none');
                }

                // 市町ユーザの場合、概況ページのリンクを非表示
                if(UserInfo.getUserType() === UserType.MUNICIPALITY){
                    domStyle.set(this.overviewLabel, 'display', 'none');
                }
    
                // グリッドを初期化する
                this.initGrid();
            },

            //報告状況セレクタの選択肢をユーザによって制御する
            initReportStatusSelector: function (isMunic) {
                // 管理者ユーザ・市町ユーザのいずれでもない場合 または 市町ユーザが他市町の情報を参照している場合は
                // 「市町内管理」を選ばせない
                if ((!UserInfo.isSysAdmin() && UserInfo.getUserType() !== UserType.MUNICIPALITY) ||
                    (UserInfo.getUserType() === UserType.MUNICIPALITY && isMunic &&
                    this._municipalityCd !== UserInfo.getMunicipalityCd())) {
                    this.reportStatus = new Select({
                        name: 'reportStatus',
                        style: 'width: 7em;',
                        options: [
                            { label: '&nbsp;', value: '' },
                            { label: '県報告（外部公開不可）', value: '31' },
                            { label: '県報告（外部公開可能）', value: '41' }
                        ]
                    });
                } else {
                    this.reportStatus = new Select({
                        name: 'reportStatus',
                        style: 'width: 7em;',
                        options: [
                            { label: '&nbsp;', value: '' },
                            { label: '庁内共有（県へ報告しない）', value: '11' },
                            { label: '県報告（外部公開不可）', value: '31' },
                            { label: '県報告（外部公開可能）', value: '41' }
                        ]
                    });
                }
                this.reportStatusWrapper.innerHTML = '';
                domCon.place(this.reportStatus.domNode, this.reportStatusWrapper);
            },

            selectRecord: function (arg) {
                // 緯度経度がある場合のみ地図を移動させる
                var lat = arg.latitude;
                var lng = arg.longitude;
                if (lat && lng) {
                    this.map.setView([lat, lng]);
                }

                // 画面右下の詳細表示Paneを操作する
                if (this.guideMessage) {
                    domStyle.set(this.guideMessage, 'display', 'none');
                }
                if (this.selectedDamage) {
                    domStyle.set(this.selectedDamage, 'display', '');

                    this.selectedContent.innerHTML = arg.content ? arg.content : '原因/内容は未登録です。';

                    var self = this;
                    if (arg.attachFiles) {
                        this.selectedPicure.innerHTML = '';
                        array.forEach(arg.attachFiles, function (attachFile) {
                            // 削除済みのファイルは表示しない
                            var delFlg = attachFile.delFlg;
                            if (delFlg === 'true') {
                                return false;
                            }
                            // 画像でないファイルは表示しない
                            var fileName = attachFile.attachFileName;
                            if (fileName.indexOf('.jpg') === -1 && fileName.indexOf('.jpeg') === -1 &&
                                fileName.indexOf('.png') === -1 && fileName.indexOf('.JPG') === -1 &&
                                fileName.indexOf('.JPEG') === -1 && fileName.indexOf('.PNG') === -1 &&
                                fileName.indexOf('.gif') === -1) {
                                return false;
                            }
                            var path = attachFile.attachFilePath.replace('out', 'data');
                            var img = '<img src="' + path + '" alt="画像を読み込めません。" style="width: 100px">';
                            var dom = domCon.toDom(img);
                            domCon.place(dom, self.selectedPicure);
                        });
                    }
                    if (!this.selectedPicure.innerHTML) {
                        this.selectedPicure.innerHTML = '表示する写真はありません。';
                    }
                }
            },


            // TODO:要確認 新規登録ボタンについては、FUNC_CDとは別に「関係機関は表示しない」という暫定措置をとる
            initButtonStatus: function () {
                if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    domStyle.set(this.registerButton, 'display', 'none');
                }

                // 閲覧権限以上を持っていないユーザは「定時報告」ボタンを非表示とする
                if(!UserInfo.hasAuthz('F05009')){
                    domStyle.set(this.ScheduledReportButton.domNode, 'display','none');
                }
            },

            /**
             * ファイルアプロードダイアログ初期化処理
             */
            initFileUploadDialog: function () {
                // var municipalityCds = UserInfo.getMunicipalityCds();
                var options = [];

                // 避難所状況一覧は全ユーザに追加する。
                options.push({
                    value: '04',
                    label: '被害情報一覧'
                });

                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.fileUploadDialog;
                var page = dialog.getChildren()[0];
                page.initOptions(options);
                // 登録ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function () {
                    dialog.hide();
                    this.updateGridQuery(this.form.get('value'));
                }));
            },

            /**
             * 一覧画面に初期表示する市町村のコードを取得する。
             *
             */
            initMunicipalitySelector: function () {

                if (Locator.getQuery().municipalityCd) {
                    // 概況画面から遷移した場合は、選択されていた市町村を設定する。
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                    // 詳細画面から遷移した場合もこのブロックに入る
                    if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                        // 市町ユーザの場合、管轄選択ボタンを非表示
                        this.municipalityCd.set('noAllButton', true);
                    }
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                    // 管轄選択ボタンを非表示
                    this.municipalityCd.set('noAllButton', true);
                } else if (UserInfo.getUserType() === UserType.REGION) {
                    // 県民局ユーザの場合、自分の県民局を設定
                    this._municipalityCd = UserInfo.getRegionCd();
                // } else if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                //     // その他関係機関ユーザの場合、組織カテゴリコードを設定
                //     this._municipalityCd = UserInfo.getOrganization().deptCd;
                }

                //市町セレクタがある場合、初期表示を設定する。
                if (this.municipalityCd && this._municipalityCd) {
                    this.municipalityCd.set('value', this._municipalityCd);
                }
            },

            showFileUploadDialog: function () {
                this.fileUploadDialog.show();
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.damageReportGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                // グリッドをクリックした際の処理
                this.damageReportGrid.on('dgrid-select', lang.hitch(this, function (evt) {
                    this.selectRecord(evt.rows[0].data);
                }));

                //添付ファイルリンクを押下した場合
                this.damageReportGrid.on('attachFileLinkClick', lang.hitch(this, function (evt) {
                    var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                    this.downloadFile(url, evt.item.attachFileName);
                }));

                // 保管した検索条件をセットする
                this.setFilterData();

                this.updateGridQuery(this.form.get('value'));
            },

            /**
             * マップを初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = [latitude, longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 14);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);

            },

            /**
             * 市町村コードを再セットする。
             */
            onMunicipalityChange: function () {
                console.debug('市町村が変更されました');
                this._municipalityCd = this.municipalityCd.get('value');
                var isMunic = false;
                // 空でも、政令指定都市でも、県民局でもなければ市町選択と判断
                if (this._municipalityCd && this._municipalityCd !== config.municInfo.cityMunicCd &&
                    config.municInfo.prefRegCdGudge
                        .indexOf(this._municipalityCd) === -1) {
                    isMunic = true;
                }

                // 初回のみ
                // if(firstFlg){
                //     //市町ユーザが自市町のページに入ってきた場合のみ、対応課をデフォルト設定して戻る
                //     if(UserInfo.getUserType() === UserType.MUNICIPALITY && this._municipalityCd &&
                //             UserInfo.getMunicipalityCd() === this._municipalityCd) {
                //         // ユーザの組織情報
                //         var organ = UserInfo.getOrganization();
                //         var organizationCd = organ.unitCd ? 'U' + organ.unitCd :
                //             organ.sectCd ? 'S' + organ.sectCd :
                //             'D' + organ.deptCd;
                //         if(this.hldOrganization){
                //             this.hldOrganization.onMunicipalityChange(this._municipalityCd);
                //             this.hldOrganization.set('value', organizationCd);
                //         }
                //         return false;
                //     }
                // }

                //対応課の選択情報を初期化の上、ツリーを再構築
                if (this.hldOrganization) {
                    //値が入っていたら初期化する
                    if (this.hldOrganization.get('value')) {
                        this.hldOrganization.set('value', '');
                    }
                    if (!isMunic) {
                        // 市町を選択していない場合、または地域を選択した場合はセレクタを表示しない
                        this.hldOrganization.set('disabled', 'true');
                    } else {
                        this.hldOrganization.set('disabled', '');
                        // 市町・組織と対応課を連動させる
                        this.hldOrganization.onMunicipalityChange(this._municipalityCd);
                    }

                }
                // 選択した市町によって報告状況のセレクトボックス内容を変更する
                this.initReportStatusSelector(isMunic);
            },

            /**
             * 検索
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 検索パラメーターの設定
             */
            updateGridQuery: function (value) {

                this.removeMarkers();
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);

                // 市町村コード
                if (this.municipalityCd.value) {
                    // ※その他関係機関の場合、市町コードには組織カテゴリコード（Dxxxxx）が設定されている
                    filter = filter.eq('municipalityCd', this.municipalityCd.value);
                }

                // 対応課
                if (this.hldOrganization.value) {
                    filter = filter.eq('hldOrganization', this.hldOrganization.value.slice(1));
                }

                // キーワード
                if (value.keyword) {
                    filter = filter.eq('keyword', value.keyword);
                }

                if (this.reportStatus.value) {
                    filter = filter.eq('reportStatus', this.reportStatus.value);
                }

                // 被害種別、被害種別リストの値をFORMにセットし直す
                value.damageType = this._damageType;
                value.damageTypeList = this._damageTypeList;

                // 被害種別
                if (value.damageType) {
                    filter = filter.eq('damageType', value.damageType);
                }

                // 被害種別リスト
                if (value.damageTypeList) {
                    filter = filter.eq('damageTypeList', value.damageTypeList);
                }

                // 対応状況
                if (value.hldStatus) {
                    filter = filter.eq('hldStatus', value.hldStatus);
                }

                // 緊急度
                if (value.urgencyType) {
                    filter = filter.eq('urgencyType', value.urgencyType);
                }

                // 管理番号
                if (value.admNum) {
                    filter = filter.eq('admNum', value.admNum);
                }

                // 子被害表示
                if (value.childReport.length === 0) {
                    filter = filter.eq('hasNoParentFlg', '1');
                } else {
                    filter = filter.eq('hasNoParentFlg', '0');
                }

                // 報告日時FROM
                if (value.reportCrtDateTimeFrom) {
                    var reportCrtDateTimeFrom = new Date(value.reportCrtDateTimeFrom).getTime();
                    filter = filter.eq('reportCrtDateTimeFrom', reportCrtDateTimeFrom);
                }

                // 報告日時TO
                if (value.reportCrtDateTimeTo) {
                    var reportCrtDateTimeTo = new Date(value.reportCrtDateTimeTo).getTime();
                    filter = filter.eq('reportCrtDateTimeTo', reportCrtDateTimeTo);
                }

                // 更新日時FROM
                if (value.reportUpdDateTimeFrom) {
                    var reportUpdDateTimeFrom = new Date(value.reportUpdDateTimeFrom).getTime();
                    filter = filter.eq('reportUpdDateTimeFrom', reportUpdDateTimeFrom);
                }

                // 更新日時TO
                if (value.reportUpdDateTimeTo) {
                    var reportUpdDateTimeTo = new Date(value.reportUpdDateTimeTo).getTime();
                    filter = filter.eq('reportUpdDateTimeTo', reportUpdDateTimeTo);
                }

                // 過去報表示
                if (value.pastReport.length === 0) {
                    filter = filter.eq('activeFlg', '1');
                } else {
                    filter = filter.eq('activeFlg', '0');
                }

                // 子被害表示
                if (value.deletedReport.length !== 0) {
                    filter = filter.eq('deletedReportFlg', '1');
                }

                // 人的被害あり
                if (value.humanDamageFlg.length !== 0) {
                    filter = filter.eq('humanDamageFlg', '0');
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.damageReportGrid.set('collection', collection);

                // 画面右下の詳細表示Paneを戻す
                this.guideMessage.style.display = '';
                this.selectedDamage.style.display = 'none';

                // 外部公開未処理件数更新
                collection.fetch().then(lang.hitch(this, this.updateNonPublicCount));
            },

            /**
             * 外部公開未処理件数更新
             */
            updateNonPublicCount: function(items) {
                var count = 0;
                array.forEach(items, function(item) {
                    if(['11', '31'].indexOf(item.reportStatus) > -1){
                        count++;
                    }
                });
                this.nonPublicCnt.innerHTML = count;
                // なければ非表示
                domStyle.set(this.nonPublicAlert, 'display', count > 0 ? '' : 'none');
            },

            onOpenFilterClick: function () {
                this.openingDetailFilter = true;
                domStyle.set(this.openFilterLink, 'display', 'none');
                domStyle.set(this.closeFilterLink, 'display', '');
                domStyle.set(this.reportTimeForm, 'display', '');
                domStyle.set(this.updTimeForm, 'display', '');
                domStyle.set(this.aboveSearchButtonDiv, 'display', 'none');
                this.borderContainer.resize();
            },

            onCloseFilterClick: function () {
                this.openingDetailFilter = false;
                domStyle.set(this.openFilterLink, 'display', '');
                domStyle.set(this.closeFilterLink, 'display', 'none');
                domStyle.set(this.reportTimeForm, 'display', 'none');
                domStyle.set(this.updTimeForm, 'display', 'none');
                domStyle.set(this.aboveSearchButtonDiv, 'display', '');
                this.borderContainer.resize();
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function () {
                //初期化する
                damageFilterStore = {};
                var value = this.form.get('value');

                // 報告日時FROM
                if (value.reportCrtDateTimeFrom) {
                    damageFilterStore.reportCrtDateTimeFrom = value.reportCrtDateTimeFrom;
                }

                // 報告日時TO
                if (value.reportCrtDateTimeTo) {
                    damageFilterStore.reportCrtDateTimeTo = value.reportCrtDateTimeTo;
                }

                // 更新日時FROM
                if (value.reportUpdDateTimeFrom) {
                    damageFilterStore.reportUpdDateTimeFrom = value.reportUpdDateTimeFrom;
                }

                // 更新日時TO
                if (value.reportUpdDateTimeTo) {
                    damageFilterStore.reportUpdDateTimeTo = value.reportUpdDateTimeTo;
                }

                // 過去報表示
                if (value.pastReport.length > 0) {
                    damageFilterStore.pastReport = 'checked';
                }

                // 被害種別
                if (value.damageType) {
                    damageFilterStore.damageType = value.damageType;
                }

                // 被害種別リスト
                if (value.damageTypeList) {
                    damageFilterStore.damageTypeList = value.damageTypeList;
                }

                // 対応状況
                if (value.hldStatus) {
                    damageFilterStore.hldStatus = value.hldStatus;
                }

                // 緊急度
                if (value.urgencyType) {
                    damageFilterStore.urgencyType = value.urgencyType;
                }

                // 市町村コード
                if (value.municipalityCd) {
                    damageFilterStore.municipalityCd = value.municipalityCd;
                }

                // 対応課
                if (value.hldOrganization) {
                    damageFilterStore.hldOrganization = value.hldOrganization;
                }

                // キーワード
                if (value.keyword) {
                    damageFilterStore.keyword = value.keyword;
                }

                // 報告状況
                if (value.reportStatus) {
                    damageFilterStore.reportStatus = value.reportStatus;
                }

                // 管理番号
                if (value.admNum) {
                    damageFilterStore.admNum = value.admNum;
                }

                // 子被害表示
                if (value.childReport.length > 0) {
                    damageFilterStore.childReport = 'checked';
                }

                // 削除済み被害表示
                if (value.deletedReport.length > 0) {
                    damageFilterStore.deletedReport = 'checked';
                }

                // 人的被害あり
                if (value.humanDamageFlg.length > 0) {
                    damageFilterStore.humanDamageFlg = 'checked';
                }

                // 詳細検索項目を開いているかを保存する
                damageFilterStore.openingDetailFilter = this.openingDetailFilter;

            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function () {
                //保管されていれば値をセット

                // 報告日時FROM
                if (damageFilterStore.reportCrtDateTimeFrom) {
                    this.reportCrtDateTimeFrom.set('value', damageFilterStore.reportCrtDateTimeFrom);
                }

                // 報告日時TO
                if (damageFilterStore.reportCrtDateTimeTo) {
                    this.reportCrtDateTimeTo.set('value', damageFilterStore.reportCrtDateTimeTo);
                }

                // 更新日時FROM
                if (damageFilterStore.reportUpdDateTimeFrom) {
                    this.reportUpdDateTimeFrom.set('value', damageFilterStore.reportUpdDateTimeFrom);
                }

                // 更新日時TO
                if (damageFilterStore.reportUpdDateTimeTo) {
                    this.reportUpdDateTimeTo.set('value', damageFilterStore.reportUpdDateTimeTo);
                }

                // 過去報表示
                if (damageFilterStore.pastReport) {
                    this.pastReport.set('checked', 'checked');
                }

                // 被害種別
                if (damageFilterStore.damageType) {
                    this.damageType.set('value', damageFilterStore.damageType);
                }

                // 被害種別リスト
                if (damageFilterStore.damageTypeList) {
                    this.damageTypeList.set('value', damageFilterStore.damageTypeList);
                }

                // 対応状況
                if (damageFilterStore.hldStatus) {
                    this.hldStatus.set('value', damageFilterStore.hldStatus);
                }

                // 緊急度
                if (damageFilterStore.urgencyType) {
                    this.urgencyType.set('value', damageFilterStore.urgencyType);
                }

                // 市町村コード（その他関係機関の場合は組織カテゴリコードをセットする）
                if (!this._municipalityCd && damageFilterStore.municipalityCd ||
                    this._municipalityCd === config.municInfo.otherRelationOrg) {
                    this.municipalityCd.set('value', damageFilterStore.municipalityCd);
                }

                // 対応課
                if (damageFilterStore.hldOrganization) {
                    this.hldOrganization.set('value', damageFilterStore.hldOrganization);
                }

                // 報告状況
                if (damageFilterStore.reportStatus) {
                    this.reportStatus.set('value', damageFilterStore.reportStatus);
                }

                // キーワード
                if (damageFilterStore.keyword) {
                    this.keyword.set('value', damageFilterStore.keyword);
                }

                // 管理番号
                if (damageFilterStore.admNum) {
                    this.admNum.set('value', damageFilterStore.admNum);
                }

                // 子被害表示
                if (damageFilterStore.childReport) {
                    this.childReport.set('checked', 'checked');
                }

                // 削除済み被害表示
                if (damageFilterStore.deletedReport) {
                    this.deletedReport.set('checked', 'checked');
                }

                // 人的被害あり
                if (damageFilterStore.humanDamageFlg) {
                    this.humanDamageFlg.set('checked', 'checked');
                }

                //詳細検索フォームを開いているかどうか
                if (damageFilterStore.openingDetailFilter) {
                    this.onOpenFilterClick();
                }
            },

            outputListExcel: function () {
                this.formDialog.show();
            },


            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                //検索条件の保管
                this.setFilterStore();
                this._removeEvents();

                //新規画面に移動
                var municipalityCd = this.municipalityCd.value;
                if (municipalityCd &&
                    config.municInfo.prefRegCdGudge.indexOf(municipalityCd) === -1 &&
                    municipalityCd !== config.municInfo.cityMunicCd) {
                    Router.moveTo('report/register', { municipalityCd: municipalityCd });
                } else {
                    Router.moveTo('report/register');
                }

            },

            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('report/detail', {
                    damageReportId: object.damageReportId
                });
            },

            /**
             * 被害状況概況一覧画面へ遷移する。（フィルタの保存が必要なので独自に定義）
             */
            onDamageReportAdminPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/admin');
            },

            /**
             * 定時報告一覧画面を表示（フィルタの保存が必要なので独自に定義）
             */
            showScheduledReportPage: function () {
                //検索条件の保管
                this.setFilterStore();
                this._removeEvents();
                // 定時報告画面へ遷移
                Router.moveTo('report/sche');
            },

            /**
             * 消防庁被害報告一覧画面を表示
             */
            showFDMAReportPage: function () {
                //検索条件の保管
                this.setFilterStore();
                this._removeEvents();
                //消防庁被害報告一覧画面へ遷移
                Router.moveTo('fdmaReport');
            },

            /**
             * grid描画時、被害情報の位置情報をもとに地図にもプロットする
             */
            addMarker: function (arg) {
                // すでに描画されていた場合は戻る。
                if (this.drawnDamageIds.indexOf(arg.damageReportId) !== -1) {
                    return false;
                }

                var marker = this.makeDamageMarker(arg);
                if (marker) {
                    // マップの描画が追いつかない場合があるため、タイミングを調整
                    setTimeout(lang.hitch(this, function() {
                        marker.addTo(this.map).on('click', lang.hitch(this, function() {
                            this.clickMarker(arg);
                        }));
                    }), 1000);
                    this.divIconMarkers.push(marker);
                    this.drawnDamageIds.push(arg.damageReportId);
                }
            },

            //地図上のマーカー押下時の挙動
            clickMarker: function (arg) {
                this.damageReportGrid.clearSelection();
                this.damageReportGrid.select(arg.damageReportId);
            },

            /**
             * 地図上のアイコンを削除する
             */
            removeMarkers: function () {
                var map = this.map;
                array.forEach(this.divIconMarkers, function (divIcon) {
                    map.removeLayer(divIcon);
                });

                this.drawnDamageIds.length = 0;
            },

            switchPictureDisp: function () {
                if (this.displayPicFlg) {
                    dojo.byId('switchPicButton').innerHTML = '写真表示';
                    this.damageReportGrid.styleColumn('attachFiles', 'display: none;');
                    this.displayPicFlg = false;
                } else {
                    dojo.byId('switchPicButton').innerHTML = '写真非表示';
                    this.damageReportGrid.styleColumn('attachFiles', 'display: table-cell;');
                    this.displayPicFlg = true;
                }
                this.gridPane.resize();

            }

        });
});

/**
 * 水位共通カラム一覧
 * @module app/river/grid/RiverLevelObservationCommonColumns
 */
define([
    'app/view/grid/CommonColumn'
    // 以下、変数で受けないモジュール
], function(CommonColumn) {
    return {
        tideLevel: {
            field: 'tideLevel', id: 'tideLevel',
            label: '潮位[m]',
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'tideLevel', 2);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                return CommonColumn.getClassNameExcess(item, 'tideLevel', item.excess);
        }},
        tideLevelTp: {
            field: 'tideLevelTp', id: 'tideLevelTp',
            label: 'TP潮位[m]',
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'tideLevelTp', 2);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                return CommonColumn.getClassNameExcess(item, 'tideLevelTp');
        }},
        tideLevelAstronomical: {
            field: 'tideLevelAstronomical', id: 'tideLevelAstronomical',
            label: '天文潮位[m]',
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'tideLevelAstronomical', 2);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                return CommonColumn.getClassNameExcess(item, 'tideLevelAstronomical');
        }},
        tideLevelAstronomicalTp: {
            field: 'tideLevelAstronomicalTp', id: 'tideLevelAstronomicalTp',
            label: '天文潮位[m]',
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'tideLevelAstronomicalTp', 2);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                return CommonColumn.getClassNameExcess(item, 'tideLevelAstronomicalTp');
        }}
    };
});
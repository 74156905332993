/**
 * 施設管理 ファイル読み込みダイアログ用モジュール。
 * @module app/facility/FacilityFileUploadDialog
 */
define([
    'module',
    'app/model/DisasterInfo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityFileUploadDialog.html',
    'dojo/request/iframe',
    'dstore/RequestMemory',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos'
], function (module, DisasterInfo, array, declare, lang, Deferred, domStyle, template, iframe, RequestMemory, USER_TYPE, Requester,
    UserInfo, DialogChain, InfoDialog, _PageBase, Loader) {
    /**
     * 施設管理ファイルアップロードダイアログ
     * @class FacilityFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        _fileId: null,

        infoDialog : null,

        _disasterId: null,

        FACILITY_REPORT_REQID: 'FACILITY_LIST_IN',

        // 施設一覧を選択中かどうか
        _selectedFacilityList: true,

        constructor: function() {
            // 入力帳票の各種パスを取得する。
            this.storepath = new RequestMemory({
                idProperty: 'reqid',
                target: '/data/report/xoblos-config.json'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
            }
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.errorMessageDialogForXoblos);
        },

        startup: function () {
            this.inherited(arguments);
            // 関係機関ユーザーは国交省入力不可
            if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                domStyle.set(this.mulitRadioButton, 'display', 'none');
            }
            // 種別選択による入力フォーム初期セット
            this.onSelectFlgChange();
        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function () {
            // 入力チェック
            if (this.validate()) {
                if (this._selectedFacilityList) {
                    // 施設一覧入力
                    this.postXoblos();
                } else {
                    // 国交省避難所データ取り込み
                    this.postSheltersFromMLIT();
                }
            }
        },

        /**
         * 国交省避難所CSVファイル取り込みAPI実行
         */
        postSheltersFromMLIT: function () {
            // ファイルをサーバーにPOSTする
            var promise = iframe.post('/api/facility/upload', {
                form: this.mlitForm.id,
                handleAs: 'json',
                // 60分間はタイムアウトしないようにする
                timeout: 60 * 60 * 1000
            }).then(lang.hitch(this, function(result) {
                console.log(result);
                this.chain.info('処理が完了しました。<br>' + result.messages.join('<br>'), '読み込み結果');
            }), lang.hitch(this, function(error) {
                console.log(error);
                this.chain.infoError(error);
            }));
            // ローダーの表示
            Loader.wait(promise);
        },

        /**
         * Xoblosファイル取り込みAPI実行
         */
        postXoblos: function () {
            var reqid = this.FACILITY_REPORT_REQID;
            var reqParams = {};
            this.storepath.fetch().then(lang.hitch(this, function(data) {
                data.some(function(data) {
                    if (reqid === data.reqid) {
                        reqParams = data;
                        return true; //break;
                    }
                });
            })).then(lang.hitch(this, function() {
                // ファイルをサーバーにアップロードするAPIのURL
                var url = '/api/xoblos/uploadReport' +
                        '?reqid=' + reqid +
                        '&disid=' + this._disasterId +
                        '&path=' + reqParams.pathweb;

                iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(ret) {
                    // アップロードしたファイルパスとエラーメッセージjson、
                    // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                    var promise = Requester.post('/api/xoblos/upload',{
                        data: {
                            reqid: reqid,
                            disid: this._disasterId,
                            filename: ret.fileName
                        }
                    }).then(lang.hitch(this, function(data) {
                        // ResultCode=0以外（xoBlosエラー）の場合にはエラーダイアログを表示する。
                        if (data.ResultCode !== 0){
                            var err = new Error('xoblos failed');
                            err.data = data;
                            throw err;
                        } else {
                            // xoblosエラーが出なかった場合は、返ってきたJSONデータをJavaに転送し、登録処理を行う
                            var regapi = reqParams.regapi;
                            return  Requester.post(regapi, {
                                data:{
                                    // ResultCodeが0の場合は、帳票に含まれる施設情報の一覧がレスポンスのdataに入っている。
                                    items: data.data
                                }
                            });
                        }
                    })).then(lang.hitch(this, function(data) {
                    // 成功した場合は完了ダイアログを表示する。
                        if(data.nonExistentFacility.length === 0){
                            this.chain.infoComplete(function() {
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.facilListFile.reset();
                                this.emit('upload');
                            });
                        } else {
                            var message = '';
                            message += 'ファイル読み込みを完了しました。<br>';
                            message += 'ただし下記は、入力情報に誤りがあるため、登録・更新を行いませんでした。<br><br>';
                            message += '<table style="margin-left: auto;margin-right: auto">';
                            message += '<tr><th style="text-align:center"><b>施設情報</b></th><tr>';
                            array.forEach(data.nonExistentFacility, function(errorFacilityList){
                                message += '<tr><td>' + errorFacilityList;
                                message += '</td></tr>';
                            });
                            message += '</table>';

                            var title = '完了';
                            // ダイアログにメッセージを表示
                            this.chain.info(message, title, function(){
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.facilListFile.reset();
                                this.emit('upload');
                            });
                        }
                    }), lang.hitch(this, function(error) {

                        // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                        if (error.data) {
                            // エラーダイアログに結果コードを渡す
                            var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                            errPage.initErrorMessageDialogForXoblos(
                                error.data.ResultCode,
                                reqParams.errpathweb + this._disasterId + '/' + ret.fileName);
                            this.errorMessageDialogForXoblos.show();
                            this.facilListFile.reset();
                        } else {
                            // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                            this.chain.infoError(error, function () {
                                this.chain.hide();
                                this.facilListFile.reset();
                            });
                        }
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                }));
            }));
        },

        /**
         * 入力チェック
         */
        validate: function() {
            var messages = [];
            //ファイルが空の場合は処理を中断
            if (this._selectedFacilityList) {
                // 施設一覧入力
                if (this.facilListFile.getFileList().length === 0) {
                    messages.push('ファイルが選択されていません');
                }
            } else {
                // 国交省避難所データ取り込み
                if (this.designatedEvacShCsv.getFileList().length === 0) {
                    messages.push('指定避難所CSVファイルが選択されていません');
                }
                if (this.eDesignatedEvacShCsv.getFileList().length === 0) {
                    messages.push('指定緊急避難場所CSVファイルが選択されていません');
                }
            }
            if (messages.length > 0) {
                InfoDialog.show('入力チェック', messages.join('<br>'));
                return false;
            }
            return true;
        },

        /**
         * 種別選択 
         */
        onSelectFlgChange: function(evt) {
            // 施設一覧かどうか
            this._selectedFacilityList = !evt || evt.target.value === '0';  // evt = null → 初期表示
            // 表示制御
            domStyle.set(this.facilListFileArea, 'display', this._selectedFacilityList ? '' : ' none');
            domStyle.set(this.designatedEvacShCsvArea, 'display', this._selectedFacilityList ? 'none' : '');
            domStyle.set(this.eDesignatedEvacShCsvArea, 'display', this._selectedFacilityList ? 'none' : '');
            // フォームリセット
            this.facilListFile.reset();
            this.designatedEvacShCsv.reset();
            this.eDesignatedEvacShCsv.reset();
        },

        // 施設一覧ファイル選択
        loadFacilListFile: function(files) {
            this.loadFile(files).then(lang.hitch(this, function(fileId) {
                this._facilListFileId = fileId;

                var form = new FormData();
                form.append('report', this.facilListFile._files[0]);
            }), lang.hitch(this, function() {
                // 失敗なら、添付ファイル入力フォームリセット
                this.facilListFile.reset();
            }));
        },

        // 指定避難所CSVファイル選択
        loadDesignatedEvacShCsv: function(files) {
            this.loadFile(files).then(lang.hitch(this, function(fileId) {
                this._designatedEvacShCsvId = fileId;
            }), lang.hitch(this, function() {
                // 失敗なら、添付ファイル入力フォームリセット
                this.designatedEvacShCsv.reset();
            }));
        },

        // 指定緊急避難場所CSVファイル選択
        loadEDesignatedEvacShCsv: function(files) {
            this.loadFile(files).then(lang.hitch(this, function(fileId) {
                this._eDesignatedEvacShCsvId = fileId;
            }), lang.hitch(this, function() {
                // 失敗なら、添付ファイル入力フォームリセット
                this.eDesignatedEvacShCsv.reset();
            }));
        },

        /*
         * ファイル読み込みで呼ばれる
         */
        loadFile: function (files) {
            var deferred = new Deferred();
            //ファイルが空の場合は処理を中断
            if (files.length === 0) { return deferred.resolve(); }

            // ファイル種別チェック
            if (!this.validateFile(files, this._selectedFacilityList)) {
                return deferred.reject();
            }
            return deferred.promise;
        },

        /**
         * 取り込みファイルの形式チェック
         * @param {[]} files
         *       選択ファイル
         * @param {Boolean} isFacilityList
         *       T: 施設一覧(Excel), F: 国交省データ(CSV)
         */
        validateFile: function(files, isFacilityList) {
            var errMessage = null;
            if (isFacilityList) {
                var fileName = files[0].name;
                // 施設一覧
                if (fileName.indexOf('.xls') === -1 && fileName.indexOf('.xlsx') === -1 &&
                    fileName.indexOf('.xlsm') === -1) {
                    errMessage = 'エクセルファイルを選択してください。';
                }
            } else {
                // 国交省データ
                if (array.some(files, function(file) { return file.name.indexOf('.csv') === -1;})) {
                    errMessage = 'CSVファイルを選択してください。';
                }
            }

            // ファイル不正
            if (!!errMessage) {
                this.infoDialog = new InfoDialog({
                    title: 'エラー',
                    content: errMessage
                });
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            return true;
        }
    });
});

/**
 * 津波警報レベル
 * @module app/model/KikikuruRiskLevel
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        idProperty: 'level',
        data: [
            {
                level: "3",
                label: "大津波警報"
            }, {
                level: "2",
                label: "津波警報"
            }, {
                level: "1",
                label: "津波注意報"
            }
        ]
    });
});
/**
* 災害情報画面用モジュール。
* @module app/view/page/DisasterAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.pubStatus === 1 ? 'is-public' : '';
        },
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('disasterId', '災害No'),
            {field: 'disasterName', label: '災害名称', sortable: false,
                className: lang.hitch(this, function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }else{
                        return 'disasterName-content';
                    }
                }),
                formatter: function(value, item) {
                    return item.disasterName;
                    }
            },
            {field: '_item', label: '災害モード', sortable: false,
                formatter: function(value, item) {
                    switch (item.trainingFlg) {
                        case false: return '本番';
                        case true: return '訓練(練習)';
                        default: return '';
                    }
                }
            },
            {field: 'disasterType', label: '災害種別',
                formatter: lang.hitch(this, function(item){
                    var type = null;
                    switch(item) {
                        case '01':
                            type = '風水害';
                            break;
                        case '02':
                            type = '地震・津波';
                            break;
                        case '05':
                            type = '火山';
                            break;
                        case '04':
                            type = '国民保護';
                            break;    
                        case '99':
                            type = 'その他';
                            break;
                    }
                    return type;
                })
            },
            {field: 'startTimestamp', label: '発生期間（開始）', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'endTimestamp', label: '発生期間（終了）', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {field: 'reportFlg', label: '災害情報登録',
                formatter: lang.hitch(this, function(item){
                var result = '登録可';
                if(item === true) {
                    result = '登録不可';
                }
                return result;
            })},
            helper.column('comment', '備考', {sortable: false})
        ]
    });
});

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/PrintPane.html',
    'idis/consts/CONTENT_TYPE',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/_IdisWidgetBase',
    'idis/service/Requester',
    'idis/view/draw/_OptionConverterMixin',
    'idis/view/Loader',
    'leaflet',
    './PrintAreaRectangle',
    './PrintUtils',
    // 以下、変数で受けないモジュール
    'dijit/form/Select',
    'idis/view/form/WordCountTextBox'
], function (module, declare, lang, on, json, topic, template,
    CONTENT_TYPE, ConfirmDialog, InfoDialog, _IdisWidgetBase,
    Requester, _OptionConverterMixin, Loader, leaflet, PrintAreaRectangle, PrintUtils) {

    /**
     * 印刷サービスURL
     */
    var PRINT_SERVICE_URL = '/api/print/execute';
    var DOWNLOAD_SERVICE_URL = '/api/print/download?fileName=';

    var content = declare('_PrintDialogContent', _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        baseClass: 'printDialog-Container',

        widgetsInTemplate: true
    });

    return declare(module.id.replace(/\//g, '.'), [ConfirmDialog], {

        /**
         * 印刷対象の地図
         */
        map: null,

        /**
         * 印刷対象の地図のレイヤーを管理するインスタンス
         */
        layerControl: null,

        /**
         * ダイアログのタイトル
         */
        title: '印刷設定',

        /**
         * コンストラクター
         * mapとlayerControl必須
         */
        constructor: function (options) {
            lang.mixin(this, options);
            this.inherited(arguments);
            this.inner = new content();
        },

        /**
         * DOM生成後の処理
         */
        postCreate: function () {
            this.inherited(arguments);
            this.set('content', this.inner);

            // 「範囲選択」ボタンククリック時
            this.own(on(this.inner.selectAreaButton, 'click', lang.hitch(this, function () {
                this._changeSelectMode();
            })));

            // メイン地図画面から、他の地図機能が呼ばれたときに発行される
            this.own(topic.subscribe('/app/view/page/MapPage::disablePrint',
                lang.hitch(this, function () {

                    // ダイアログを閉じる
                    this.hide();
                })));

        },

        /**
         * ダイアログを閉じる
         * override
         */
        hide: function () {
            this.inherited(arguments);

            // FIXME
            // 描画モードをオフのまま閉じると、
            // _reset()内の_changeSelectMode()によって描画モードがオンになってしまうため
            // ここで明示的にオンにしておく
            if (typeof this._drawAreaRectangle !== 'undefined') {
                this._drawAreaRectangle.enable();
            }
            // 初期化
            this._reset();
        },

        /**
         * 初期化
         */
        _reset: function () {

            // 印刷タイトルの初期化
            this.inner.title.set('value', '');

            // エリア選択の初期化
            this._changeSelectMode();

            if (typeof this._printFeatureGroup !== 'undefined') {

                // 既存の範囲選択エリアを削除する
                this._printFeatureGroup.clearLayers();

                // 地図からフィーチャーを削除
                this.map.removeLayer(this._printFeatureGroup);
            }

            // プロパティーを削除（表示時に再生成する）
            delete this._drawAreaRectangle;
            delete this._printFeatureGroup;

            // ボタンの表示切替
            this.inner.selectAreaButton.set('label', '範囲選択');

            // 地図上へのイベントリスナー削除（表示時に再設定する）
            this.map.off('print:area-created');

        },

        /**
         * OKボタンが押されたときに呼び出される
         * _OKDialogMixingの実装
         */
        onOK: function () {

            // 描画モードを終了
            this._drawAreaRectangle.disable();

            // ボタンの表示切替
            this.inner.selectAreaButton.set('label', '範囲選択');

            // ローディング表示
            // FIXME Loaderの標準的な使い方にする
            var loader = Loader.get();
            if (loader.isShown()) {
                return; // 2度押し防止
            }

            loader.show().then(lang.hitch(this, function (loader) {

                // 入力チェック ここから-----
                if (!this._checkArea()) {
                    // ローディングを隠す
                    loader.hide();
                    // エラーダイアログ表示
                    InfoDialog.show('失敗', '印刷範囲を選択してください。');
                    return;
                }
                if (!this._checkZoomLevel()) {
                    // ローディングを隠す
                    loader.hide();
                    // エラーダイアログ表示
                    InfoDialog.show('失敗', '印刷可能なズームレベルは（8～16）です。');
                    return;
                }
                if (!this._checkTitle()) {
                    // ローディングを隠す
                    loader.hide();
                    // エラーダイアログ表示
                    InfoDialog.show('失敗', 'タイトルは30文字以内で入力してください。');
                    return;
                }
                // 入力チェック ここまで-----

                // 印刷範囲の緯度経度
                var southWest = this._printFeatureGroup.getBounds().getSouthWest();
                var northEast = this._printFeatureGroup.getBounds().getNorthEast();

                // タイトル
                var title = this.inner.title.get('value');

                var dpi = 96;

                // 地図に表示されているレイヤーを取得
                var layers = this.layerControl.layers;

                for (var key in this.map.drawControls) {
                    if (this.map.drawControls.hasOwnProperty(key)) {
                        // 表示されているレイヤーのみが印刷対象
                        if (this.map.hasLayer(this.map.drawControls[key].featureGroup)) {

                            // 当システムの作図レイヤーに対応したプロパティ形式に変換する
                            // 当システムで扱っているGeoJSONレイヤーはfeatureGroupのレイヤー階層と異なるため作りなおし（featureをはさむ）
                            var tanJsons = this.map.drawControls[key].featureGroup.toGeoJSON();
                            tanJsons.features = [];
                            this.map.drawControls[key].featureGroup.eachLayer(function (layer) { //jshint ignore:line
                                // featureGroupの中の個々のレイヤーのプロパティに埋め込む
                                var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                                options = _OptionConverterMixin.optionConverter(options, 'export');
                                var feature = layer.toGeoJSON();
                                feature.properties = options;
                                tanJsons.features.push(feature);
                            });

                            // GeoJsonからレイヤーを生成する
                            // 作図種別として認識されるようにする
                            var drawingLayer = leaflet.geoJson(tanJsons, { infoCategoryCd: 'D108' });

                            // JSONデータ生成メソッドに渡す用に追加
                            // layersのキーは重複を避けるためprefixをつけておく
                            //layers['D' + key] = this.map.drawControls[key].featureGroup;
                            layers['D' + key] = drawingLayer;
                        }
                    }
                }

                // 印刷サービスに渡すJSONデータを生成
                var exportJson = PrintUtils.createExportJson(title, this.map, southWest, northEast, dpi, layers);
                console.debug(module.id + '#onOK:' + json.stringify(exportJson));

                // 印刷サービス実行
                Requester.post(PRINT_SERVICE_URL, {
                    headers: {
                        'Content-Type': CONTENT_TYPE.FORM
                    },
                    timeout: 180000,    // 3分
                    data: {
                        'Web_Map_as_JSON': json.stringify(exportJson),
                        Format: 'PNG8',
                        'Layout_Template': ''
                    }
                })
                    .then(function (response) {
                        console.debug(module.id + '#onOK:' + json.stringify(response));
                        // ArcGIS結果にエラーがない場合はダウンロード実行
                        if (!response.error) {
                            // ローディングを隠す
                            loader.hide();

                            var fileName = response.results[0].value.fileName;

                            // FIXME ダウンロードサービスの実行に失敗した場合、ページ遷移する
                            // 良い対処方法はないか? XHR経由でのダウンロードとどちらが良いか?
                            window.location.href = DOWNLOAD_SERVICE_URL + fileName;

                        } else {
                            // ローディングを隠す
                            loader.hide();
                            // エラーダイアログ表示
                            InfoDialog.show('失敗', '印刷画像生成に失敗しました。');
                        }

                    }, function () {
                        // ローディングを隠す
                        loader.hide();
                        // エラーダイアログ表示
                        InfoDialog.show('失敗', '印刷画像生成に失敗しました。');
                    });

            }));

        },

        /**
         * 画像をダウンロードする（未使用）
         * @param {String} ファイル名
         * @return {Promise}
         */
        _download: function (fileName) {
            return Requester.get(DOWNLOAD_SERVICE_URL + fileName, {
                'handleAs': 'arraybuffer'
            });
        },

        /**
         * 印刷範囲が選択されているかをチェック
         * @return {Boolean} true（選択済み） false（未選択）
         */
        _checkArea: function () {

            var southWest = this._printFeatureGroup.getBounds().getSouthWest();
            var northEast = this._printFeatureGroup.getBounds().getNorthEast();

            if (typeof southWest === 'undefined' || typeof northEast === 'undefined') {
                return false;
            }

            return true;
        },

        /**
         * ズームレベルの妥当性チェック
         * 印刷可能レベルは5 ~ 15
         * @return {Boolean} true（印刷可能レベル） false（印刷不可レベル）
         */
        _checkZoomLevel: function () {
            var zoom = this.map.getZoom();
            if (zoom < 4 || zoom > 16) {
                return false;
            }
            return true;
        },

        /**
         * タイトルの妥当性チェック
         * 入力可能な長さは30文字
         * @return {boolean} true(印刷可能) false（印刷不可）
         */
        _checkTitle: function () {

            if (!this.inner.title.isValid()) {
                return false;
            }

            return true;
        },

        /**
         * ダイアログ表示
         */
        show: function () {
            this.inherited(arguments);

            // 描画選択用のフィーチャー生成
            this._setDrawFeature();
        },

        /**
         * 範囲選択用の描画フィーチャー生成
         */
        _setDrawFeature: function () {

            // 描画オブジェクト
            // 範囲選択は繰り替えし行われるためリピートモード有効
            if (!this._drawAreaRectangle) {
                this._drawAreaRectangle = new PrintAreaRectangle(this.map, {
                    repeatMode: true
                });
            }

            // 地図に表示するフィーチャー
            if (!this._printFeatureGroup) {
                this._printFeatureGroup = leaflet.featureGroup();
                this._printFeatureGroup.addTo(this.map);
            }

            // 地図へのイベント定義
            this.map.on('print:area-created', lang.hitch(this, function (e) {

                var layer = e.layer;

                // 既存の範囲選択エリアを削除する
                this._printFeatureGroup.clearLayers();

                // 新規の範囲選択エリアを追加する
                this._printFeatureGroup.addLayer(layer);

                // TODO ミニマップへの矩形追加

            }));

        },

        /**
         * 「範囲選択」を制御する
         */
        _changeSelectMode: function () {

            if (typeof this._drawAreaRectangle !== 'undefined') {
                if (this._drawAreaRectangle.enabled()) {
                    // 選択中の場合は、選択モードを無効化
                    this._drawAreaRectangle.disable();

                    // ボタンの表示切替
                    this.inner.selectAreaButton.set('label', '範囲選択');
                } else {
                    // 選択中でない場合は、選択モードを有効化
                    this._drawAreaRectangle.enable();

                    // ボタンの表示切替
                    this.inner.selectAreaButton.set('label', '範囲選択終了');
                }
            }
        }


    });
});

/**
 * 帳票ディスプレイログイン用モジュール。
 * @module app/formDisplay/DisplayLoginPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DisplayLoginPage.html',
    'dojo/dom-style',
    'idis/control/Router',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function(module, declare, lang, template, domStyle, Router, _PageBase, Requester, Loader) {
    /**
     * ログイン画面
     * @class DisplayLoginPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/view/page/stationsPage~stationsPage# */ {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        /**
         * DOMノードを生成する前の処理。
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 表示ボタンがクリックされた時の挙動。
         */
        onDisplayButton: function() {
            if (!this.form.validate()) {
                return false;
            }
            var value = this.form.get('value');
            var url = null;
            var promise = Requester.post('/api/formDisplay/login', {
                data : value
            }).then(lang.hitch(this, function() {
                // ログイン成功時の処理
                url = '/data/display/' + value.screenId + '.html';
                window.open(url);
                // 成功時、防災システム側はトップ画面へ遷移させる
                Router.moveTo('monitoring');
            }), lang.hitch(this,function(err) {
                // 処理失敗時はエラーログを出力
                console.error(err);
            }));
            if (url === null) {
                domStyle.set(this.errorMessageNode, 'display', '');
            }
            Loader.wait(promise);
            console.debug('[表示]ボタンがクリックされました。');
        }

    });
});

/**
 * ダム画面の凡例UIウィジェット
 * @module app/dam/DamLegend
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DamLegend.html',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    '../view/ObservatoryLegend'
], function(module, declare, template, _PageBase) {
    /**
     * ダム画面の凡例UIウィジェット。
     * @class DamLegend
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/observation/rain/DamLegend~DamLegend# */ {
        // テンプレート文字列
        templateString: template
    });
});


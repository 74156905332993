/**
 * 施設画面のUIウィジェット
 * @module app/beginner/ShelterDetail
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ShelterDetail.html',
    'idis/view/page/_PageBase',
    'dojo/_base/lang',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'dojo/dom-style',
    'dojo/Deferred',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    // 以下、変数から参照されないモジュール
    'app/view/form/BeginnerShelterSelector'
], function(module, declare, template, _PageBase, lang, _WidgetBase, _TemplatedMixin, Router, UserInfo, UserType, domStyle, Deferred, DisasterInfo, DialogChain, Requester) {

    /**
     * 避難所情報のUIウィジェット。
     * @class ShelterDetail
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    /**

    /**
     * メニュー用オブジェクト
     * @class Menu
     */

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/beginner/ShelterDetail~ShelterDetail# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--beginner/shelterdetail',

        /**
         * マニュアルファイルPATH
         */
        FAIL_PATH: '/data/manual/',

        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            this.initShelterLink();
            this.getShelterIds();

        },

        startup: function() {
        },

        initShelterLink: function(){
            // 権限毎にリンクを出し分ける
            console.debug(UserInfo.getUserType());

            domStyle.set(this.shelterLink1, 'display', 'none');
            domStyle.set(this.shelterLink2, 'display', 'none');
            domStyle.set(this.shelterLink3, 'display', 'none');
            domStyle.set(this.shelterLink4, 'display', 'none');
            domStyle.set(this.shelterLink5, 'display', 'none');

            switch (UserInfo.getUserType()) {
                // 市町ユーザーの場合
                case UserType.MUNICIPALITY:
                        domStyle.set(this.shelterLink1, 'display', '');
                        domStyle.set(this.shelterLink2, 'display', '');
                        domStyle.set(this.shelterLink3, 'display', '');
                        domStyle.set(this.shelterLink4, 'display', '');
                        switch (UserInfo.getRoleCd()) {
                            // 市町 健康福祉
                            case 'R03013':
                                domStyle.set(this.shelterLink5, 'display', '');
                                break;
                        }
                        break;
                // 県・振興局ユーザーの場合
                case UserType.REGION:
                case UserType.PREFECTURE:
                        domStyle.set(this.shelterLink5, 'display', '');
                        break;
                    // 開発用
                default:
                        domStyle.set(this.shelterLink1, 'display', '');
                        domStyle.set(this.shelterLink2, 'display', '');
                        domStyle.set(this.shelterLink3, 'display', '');
                        domStyle.set(this.shelterLink4, 'display', '');
                        domStyle.set(this.shelterLink5, 'display', '');
                        break;
            }

        },

        /**
         * 「マニュアルを確認する」がクリックされた時の挙動。
         */
        onShelterManualDlLink: function() {
            var failname = 'shelter.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '避難所管理マニュアル');
        },

        /**
         * 「入力例を確認する」がクリックされた時の挙動。
         */
        onShelterSampleDlLink: function() {
            var failname = 'shelter_sample.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '避難所入力例');
        },

        onShelterLink1Click: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[避難所情報：開設中の避難所を確認したい。]がクリックされました');
            // 避難所情報一覧画面へ遷移
                Router.moveTo('shelter', {
                    municipalityCd : UserInfo.getMunicipalityCd(),
                    type : 'admin'
                });
        },

        onShelterLink2Click: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[避難所情報：避難所を開設したい。]がクリックされました');
            // 避難所情報登録画面へ遷移
                Router.moveTo('shelter/register');
        },

        onShelterLink3Click: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[避難所情報：避難所の状況を更新したい。]がクリックされました');

            var _facilityId = this.facilityId.get('value');
            var _shelterId = {};

            // 入力チェック（避難所選択）
            if (!_facilityId) {
                this.chain.info('避難所を選んでください。', '入力エラー');
            return false;
            }

            for(var item of this.fsIdPeers){
                if(item.facilityId === _facilityId){
                    _shelterId = item.shelterId;
                }
            }

            // 避難所情報詳細画面へ遷移
                Router.moveTo('shelter/detail', {
                    // beginnerFlg: true,
                    municipalityCd : UserInfo.getMunicipalityCd(),
                    shelterId: _shelterId
                });

        },

        onShelterLink4Click: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[避難所情報：複数の避難所を開設したい。複数の避難所を閉鎖したい。]がクリックされました');
            // 一括操作画面へ遷移
            this._municipalityCd = UserInfo.getMunicipalityCd();
                // 市町の地区コードは市町コードの市町部分(3~5桁目)+000
                var districtCd = this._municipalityCd.substr(2, 3) + '000';
                if (districtCd !== '000000') {
                    this._districtCd = districtCd;
                }
            Router.moveTo('shelter/operating', {
                municipalityCd : this._municipalityCd,
                districtCd : this._districtCd
            });

        },

        onShelterLink5Click: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[避難所情報：開設中の避難所を確認したい。]がクリックされました');
            // 避難所情報概況画面へ遷移
                Router.moveTo('shelter/admin');
        },

        /**
         * 避難所IDを取得する
         */
        getShelterIds: function() {

            var dfd = new Deferred();

            var url = '/api/shelters/beginner/shelterIds?municipalityCd=' + UserInfo.getMunicipalityCd()
                + '&disasterId=' + DisasterInfo.getDisasterId();

            Requester.post(url,{
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function (data) {
                this.fsIdPeers = data.items;
            }), function (error) {
                    console.error('避難所ID取得でエラー発生', error);
                    dfd.resolve({"unreadCount":0});
            });
            return dfd.promise;
        },

    });
});

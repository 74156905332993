/**
 * 道路情報パネル
 * @module app/monitor/TrafficPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/TrafficPanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function (module, array, declare, lang, locale, domConstruct, domStyle, JSON, template, topic,
    dojo, Router, UserInfo, Requester, DialogChain, Loader, _PageBase, DisasterInfo) {
    /**
     * 道路情報パネル
     * @class TrafficPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/TrafficPanel~TrafficPanel# */ {
            // テンプレート文字列
            templateString: template,

            /**
             * 最大表示件数
             */
            MAX_COUNT: 5,

            /**
             * 市町村コード
             */
            _municipalityCd: null,

            /**
             * 地域コード
             */
            _regionCd: null,

            /**
             * constructor
             */
            constructor: function () {
                this.chain = DialogChain.get(this);
                this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
                this._regionCd = UserInfo.getSelectedRegionCd();
                console.debug('道路情報表示対象の市町村コード：' + this._municipalityCd);
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);
                this.initTable();

                // 市町村切替時に道路情報を更新
                this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                    lang.hitch(this, function (payload) {
                        this._municipalityCd = payload.municipalityCd;
                        this._regionCd = payload.regionCd;
                        this.initTable();
                    })));

                // 災害切替時に道路情報を更新
                this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                    lang.hitch(this, function () {
                        this.initTable();
                    })));
            },

            /**
             * テーブルを初期化します
             */
            initTable: function () {
                // 道路情報を設定
                this.setTrafficInfo();
            },

            /**
             * 道路情報を設定します
             */
            setTrafficInfo: function () {
                var self = this;

                // 振興局(ブランクの場合は県を取得する)
                var regionCd = this._regionCd || UserInfo.DEFAULT_MUNICIPALITY_CD;
                var promise = Requester.get('/data/traffic/list/' + regionCd + '.json', {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    console.debug('道路情報（' + regionCd + '）：' +
                        JSON.stringify(item));

                    // 道路情報概況を設定
                    self.setTrafficOverview(self, item);
                    // 道路情報一覧を設定
                    self.setTrafficList(self, item);

                }, function (error) {
                    console.log('道路情報が見つかりません。初期値をセットします。' + (error.message ? ('(' + error.message + ')') : ''));
                    if (error.response.status === 404) {
                        var item = { 'items': [] };
                        self.setTrafficOverview(self, item);
                        self.setTrafficList(self, item);
                    } else {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 道路情報概況を設定します
             */
            setTrafficOverview: function (self, data) {
                var trafficPanelSummaryTbody = dojo.byId('trafficPanelSummaryTbody');
                domConstruct.empty(trafficPanelSummaryTbody);

                // 災害ID絞り込み
                var list = [];
                var disasterId = parseInt(DisasterInfo.getDisasterId(), 10);
                array.forEach(data.items, function (item) {
                    if (item.disasterId === disasterId || item.disasterId === null) {
                        list.push(item);
                    }
                });

                var html = '';
                if (list.length === 0) {
                    html += '<tr>';
                    html += '<td colspan="3" style="text-align: left;">現在、通行規制は発生していません。</td>';
                    html += '</tr>';
                } else {

                    // 規制種別ごとに現在/今後の件数をカウント
                    var time = (new Date()).getTime();
                    var group = list.reduce(function (result, current) {
                        // IE11がfind非対応のためforEachで抽出
                        var element = null;
                        array.forEach(result, function (item) {
                            if (current.regContent === item.regContent) {
                                element = item;
                            }
                        });
                        if (element) {
                            if (current.regStartTimestamp <= time) {
                                element.currentNum++;
                            } else {
                                element.afterNum++;
                            }
                        } else {
                            result.push({
                                regContent: current.regContent,
                                regContentCd: current.regContentCd,
                                currentNum: (current.regStartTimestamp <= time) ? 1 : 0,
                                afterNum: (current.regStartTimestamp > time) ? 1 : 0
                            });
                        }
                        return result;
                    }, []).sort(function (a, b) {
                        return (a.regContentCd < b.regContentCd) ? -1 : 1;
                    });

                    array.forEach(group, function (item) {
                        html += '<tr>';
                        html += '<td style="text-align: center; width:50%">' + item.regContent + '</td>';
                        html += '<td style="text-align: center;">' + item.currentNum + '箇所</td>';
                        html += '<td style="text-align: center;">' + item.afterNum + '箇所</td>';
                        html += '</tr>';
                    });
                }

                var dom = domConstruct.toDom(html);
                domConstruct.place(dom, trafficPanelSummaryTbody);
            },

            /**
             * 道路情報一覧を設定します
             */
            setTrafficList: function (self, data) {
                var trafficPanelListTbody = dojo.byId('trafficPanelListTbody');
                domConstruct.empty(trafficPanelListTbody);

                // 災害ID絞り込み
                var list = [];
                var disasterId = parseInt(DisasterInfo.getDisasterId(), 10);
                array.forEach(data.items, function (item) {
                    if (item.disasterId === disasterId || item.disasterId === null) {
                        list.push(item);
                    }
                });

                // 先頭5件のみ表示
                if (list.length > this.MAX_COUNT) {
                    list.length = this.MAX_COUNT;
                }

                array.forEach(list, function (item) {
                    var datetimeString = self.formatDateTime(item.regStartTimestamp);
                    var html = '<tr>';
                    html += '<td style="padding-left:10px; width:30%;">' + datetimeString + '</td>';
                    html += '<td style="width:55%;">' + item.regionName + ' ' + item.roadName + ' ';
                    html += item.regContent + '</td>';
                    html += '<td style="text-align: right; padding-right:10px; width:15%; color: blue;">';
                    html += '<u><a href="?id=' + item.trafficRegulationId;
                    html += '&p=traffic/detail">詳細&gt;</a></u></td>';
                    html += '</tr>';
                    var dom = domConstruct.toDom(html);
                    domConstruct.place(dom, trafficPanelListTbody);
                });
            },

            /**
             * 'yyyy-MM-dd HH:mm' 形式に変換
             */
            formatDateTime: function (val) {
                var timestamp = new Date(val);
                var dateLabel = locale.format(timestamp, {
                    selector: 'date',
                    datePattern: 'yyyy/MM/dd'
                });
                var timeLabel = locale.format(timestamp, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                return dateLabel + '&nbsp;' + timeLabel;
            },

            /**
             * 通行規制情報一覧ページに遷移
             */
            onTrafficLinkClick: function (evt) {
                evt.preventDefault();
                Router.moveTo('traffic');
            }

        });
});

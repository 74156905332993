/**
 * 部詳細ダイアログ用モジュール。　
 * @module app/organization/DeptDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DeptDetailDialog.html',
    './_DeptDialogBase',
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    '../config',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'dijit/form/ValidationTextBox'
], function(module, declare, lang, domStyle, template, _DeptDialogBase,
    Loader, Requester, USER_TYPE, UserInfo, config, Region) {
    /**
     * 部詳細ダイアログ
     * @class DeptDetailDialog
     * @extends module:app/organization/_DeptDialogBase~_DeptDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _DeptDialogBase,
    /** @lends module:app/view/page/DeptRegisterDialog~DeptRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,
        // バージョン
        _version: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームを初期化する。
         */
        initForm: function(object) {
            this._deptCd = object.deptCd;
            var self = this;
            var page = this.form;

            // 振興局ユーザの場合のみ、自治体ダイアログのクリアボタンを非表示にする
            if(UserInfo.getUserType() === USER_TYPE.REGION){
                domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
            }
            // 市町村ユーザの場合のみ、自治体ダイアログ用選択ボタンを非表示にする
            if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                    UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                this.municipalityCd.set('noAllButton', true);
            }
            // 自治体名に登録されている振興局、または道を表示する
            Loader.wait(Requester.get('/api/organization/depts/' + this._deptCd)).then(function (data) {
                if(!data.municipalityCd){
                    data.municipalityCd = data.regionCd;
                } else if(data.municipalityCd && data.municipalityCd === config.municInfo.prefMunicCd){
                    data.municipalityCd = config.municInfo.prefCd;
                }
                page.set('value', data);
                self._version = data.version;
            });
        },

        /**
         * 部を削除する。
         */
        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            value.deptCd = this._deptCd;
            value.version = this._version;
            this.emit('remove', {value: value});
        },

        /**
         * 部を更新する。
         */
        onSubmit: function() {
            try {
                console.debug('部詳細ダイアログの[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.deptCd = this._deptCd;
                value.version = this._version;

                // 振興局コード/市町村コード取得用の変数
                var municipalityCd = null;
                var regionCd = null;

                // 道・振興局・市町村のいずれかのコードを判定・取得する
                this.isRegion(value.municipalityCd).then(lang.hitch(this, function(isRegion){
                    if(isRegion){
                        regionCd = value.municipalityCd;
                    } else if(value.municipalityCd &&
                            value.municipalityCd !== config.municInfo.prefCd){
                        municipalityCd = value.municipalityCd;
                    } else if (value.municipalityCd &&
                            value.municipalityCd === config.municInfo.prefCd) {
                        municipalityCd = config.municInfo.prefMunicCd;
                    }
                }));

                // json形式で、Resourceに渡すための変数を作成する
                var param = {
                    'deptCd': value.deptCd,
                    'deptName': value.deptName,
                    'municipalityCd': municipalityCd,
                    'regionCd': regionCd,
                    'version': value.version
                };

                //this.emit('update', {value: value});
                this.emit('update', {value: param});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();
            this.initForm(object);
        }
    });
});

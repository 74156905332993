/**
 * 避難発令判断支ルール一覧/登録/詳細画面ベースモジュール。
 * @module app/evacrecommendRule/_EvacRecommendRulePageBase
 */
define([
    'module',
    'dijit/form/Select',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/dom-construct',
    'dojo/topic',
    'idis/control/Locator',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'app/config',
    'app/evacorder/EvacOrderTypeModel',
    'app/evacorder/Reason',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector'
], function (module,Select,
    array, declare, domCon, topic,
    Locator, IdisRest, DialogChain, _PageBase,
    config, EvacOrderTypeModel, Reason) {
    /**
     * 避難発令判断支ルール一覧/登録/詳細画面ベース
     * @class _EvacRecommendRulePageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/evacrecommendRule/_EvacRecommendRulePageBase~_EvacRecommendRulePageBase# */ {
            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--evacRecommendRule',

            // ストア
            store: null,

            constructor: function () {
                this.store = new IdisRest({
                    idProperty: 'ruleSetId',
                    target: '/api/evacrecommend/ruleset'
                });
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);
                // 前画面から市町コードが渡されていたら初期セット
                var municipalityCd = Locator.getQuery().municipalityCd;
                if (this._isValidMunicipalityCd(municipalityCd)) {
                    this.municipalityCd.set('value', municipalityCd);
                }
            },

            /**
             * 発令理由セレクタ初期化
             */
            initIssueReasonTypeSelector: function(required) {
                var options = this._ruleSetId ? [] : [{ label: '&nbsp;', value: '' }];
                array.forEach(Reason.data, function (item) {
                    // 避難情報発令判断支援が対象とする発令理由のみを表示
                    if (item.recommendable) {
                        options.push({
                            label: item.name,
                            value: item.id
                        });
                    }
                });
                this.issueReasonType = new Select({
                    name: 'issueReasonType',
                    style: 'width: 15em;',
                    required: required,
                    options: options
                });
                this.issueReasonTypeWrapper.innerHTML = '';
                domCon.place(this.issueReasonType.domNode, this.issueReasonTypeWrapper);
            },

            /**
             * 発令内容セレクタ初期化
             */
            initEvacOrderTypeSelector: function(required) {
                var options = this._ruleSetId ? [] : [{ label: '&nbsp;', value: '' }];
                array.forEach(EvacOrderTypeModel, function (item) {
                    // 避難情報発令判断支援が対象とする避難区分のみを表示
                    if (item.available && item.labelWithoutLevel !== '解除') {
                        options.push({
                            label: item.labelWithoutLevel,
                            value: item.evacOrderTypeCd
                        });
                    }
                });
                this.evacOrderType = new Select({
                    name: 'evacOrderType',
                    style: 'width: 15em;',
                    required: required,
                    options: options
                });
                
                // 発令内容を変更したらイベント発生
                this.evacOrderType.on('change', function(evacOrderType){
                    topic.publish('evacOrderTypeSelector::change', evacOrderType);
                });
                this.evacOrderTypeWrapper.innerHTML = '';
                domCon.place(this.evacOrderType.domNode, this.evacOrderTypeWrapper);
            },

            /**
             * 選択可能な市町村コードかどうか
             */
            _isValidMunicipalityCd: function(municipalityCd) {
                // 県、県民局、政令指定都市でなければOK
                return municipalityCd && [config.municInfo.prefMunicCd, config.municInfo.cityMunicCd].indexOf(municipalityCd) < 0 &&
                    config.municInfo.prefRegCdGudge.indexOf(municipalityCd) < 0;
            },
        });
});

/**
 * <観測局一覧画面>
 *
 * @module app/observationstation/ObservationStationsPageGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',

    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, IdisGrid, helper, CommonColumn) {
    /**
     * 観測局一覧画面
     *
     * @class ObservationStationsPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */

    var formatter = function(value){
        return value ? value : '-';
    };

    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/observationstation/ObservationStationsPageGrid~ObservationStationsPageGrid# */
        {
        columns: [
            helper.buttonColumn('detail', '詳細'),
            CommonColumn.observatoryName,
            helper.column('dataKind', '種別',{
                formatter: function(item) {
                    var formatmap = {
                        '1' : '雨量',
                        '2' : '水位',
                        '3' : '危機管理型水位計',
                        '4' : '流域',
                        '6' : 'ダム',
                        '7' : '市内気象情報'
                    };
                    return !formatmap[item] ? '-' : formatmap[item];
                }
            }),
			helper.column('observatoryAddress', '所在地', { formatter: formatter }),
			{
                field: 'riverSysName',
                label: '水系',
                formatter: lang.hitch(this, function(item){
                    if(!item){
                        return '---';
                    } else {
                        return item;
                    }
                })
            },
            CommonColumn.managerCd
        ]
	});
});

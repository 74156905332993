/**
 * 招集グループ一覧グリッド
 * @module app/convocation/view/group/ConvoGroupListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
                helper.buttonColumn('detail', '詳細'),
                helper.column('groupName', 'グループ', {sortable: false}),
                helper.column('orderNum', '並び順'),
                helper.column('count', '登録人数')
            ]
    });
});
/**
 * チャットグループ登録用ダイアログモジュール。
 * @module app/chat/ChatGroupRegisterDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/text!./templates/ChatGroupRegsiterDialog.html',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    './_ChatGroupDialogBase',
    // 以下、変数で受けないモジュール
    'app/view/form/DisasterPreventionMunicOrgSelector',
    'idis/view/form/WordCountTextarea'
], function(module, leaflet, declare, lang, on, popup, TooltipDialog, template, ACL, UserInfo, _ChatGroupDialogBase) {

    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            // ツールチップをセット
            this.setAvailableTips();
        },

        /**
         * チャットグループを新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');

                if (value.chatGroupUseType === '1') { // 災害時
                    if (this.municipalitySelector.get('value').substr(0,1) === 'D') {
                        value.municipalityCd = this.getDpInfo(this.municipalitySelector.get('value'), 'parentId');
                    }
                    value.dpDeptCd = this.getDpInfo(this.municipalitySelector.get('value'), 'type');
                    // チェックボックスがdisableの場合があるため明示的にセットする
                    // if (this.osakaCityFlg.checked) {
                    //     value.osakaCityFlg = ['on'];
                    // } else {
                    //     value.osakaCityFlg = [];
                    // }
                } else if (value.chatGroupUseType === '2') { // 常時
                    value.municipalityCd = '';
                    value.dpDeptCd = '';
                    value.osakaCityFlg = ['on']; // デフォルトのチェックありをセットしておく
                    value.responseHqFlg = [];
                    value.alertHqFlg = [];
                    value.otherHqFlg = [];
                }

                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();

            // 初期値：災害時で参集体制に関する設定項目をセット
            this.setDisasterPrevention('1');

            // 市本部等設置時利用可のデフォルトはチェックあり
            this.osakaCityFlg.set('value', 'on');

            // 管理者以外はチェックを外せないように非活性
            // var role = UserInfo.getRoleCd();
            // if (!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.XXMGR_USER)) {
            //     this.osakaCityFlg.set('disabled', true);
            // }

        },

        /**
         * 市本部等設置時利用可のtips
         */
        setAvailableTips: function() {
            // 共有先
            var message = '・チェック：所属で本部が未設置の場合でも、鳥取県で本部が設置されると利用可能になります。<br>' + 
                            '・未チェック：所属で本部が設置された場合のみ、利用可能になります。';
            var tooltip = new TooltipDialog({
                id: 'availableTipsRegister',
                style: 'width: 300px; height:100px',
                content: '<p>' + message + '</p>'
            });
            var label = this.availableTip;
            this.own(tooltip);
            on(label, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: tooltip,
                    around: label
                });
            }));
            on(label, 'mouseleave', function() {
                popup.close(tooltip);
            });
        }

    });
});

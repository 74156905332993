/**
* バイナリベクトルタイルの表示設定ダイアログ用パーツ
* @module app/map/vectorTile/vectorTileStyle
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/vectorTileStyle.html',
    'idis/view/_IdisWidgetBase',
    'idis/view/page/_PageBase',
    // 以下、変数として受けない
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/IdisModal',
    'idis/view/form/Button',
    'dijit/form/NumberTextBox',
    'dijit/form/Select',
    'idis/view/form/ColorSelector',
    '../../draw/DrawSelectIconDialog'
], function(module, array, declare, lang, template, _IdisWidgetBase, _PageBase) {
    
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString : template,
        
        _layerId: null,
        
        _title: '表示設定',
        
        _layerIdList: [],

        constructor: function() {
            this.inherited(arguments);
        },
        
        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },
        
        // DOM構築後postCreate()後に呼ばれる
        // 入れ子になったウィジェットにアクセスするにはここで設定する
        startup: function() {
            this.inherited(arguments);
        },
        
        // ウィジェットを破棄する
        destroy: function() {
            this.inherited(arguments);
        },
        
        /**
        * 設定ダイアログがオープンされた際に呼ばれる
        */
        onShow: function() {
            
        },
        
        /**
        * 設定ダイアログがクローズされた際に呼ばれる
        */
        onHide: function() {
            // 現在の選択値に戻す
        },
        
        /**
        * 決定ボタンがクリックされた際に呼ばれる。
        * @param {MouseEvent} evt クリック・イベント
        */
        onOK: function() {
            
        },
        
        /**
        * キャンセルボタンがクリックされた際に呼ばれる。
        * 未使用
        * @param {MouseEvent} evt クリック・イベント
        */
        onCancel: function(/* evt */) {
            this.dialog.hide();
        },
        
        /**
        * 指定内容をリセットする
        *
        */
        reset: function() {
            
        },

        toggleForm: function(map) {
            var idList = this.targetLayerIdList.get('value');
            idList = idList.split(',');
            this.dialog.set('title', this.title.get('value') ? '表示設定(' + this.title.get('value') + ')' : '表示設定');

            var hasSymbol = false;
            var hasLine = false;
            var hasFill = false;
            array.forEach(idList, lang.hitch(this, function(id) {
                var type = map.getLayer(id) ? map.getLayer(id).type : '';
                if (type === 'symbol') {
                    hasSymbol = true;
                }
                if (type === 'line') {
                    hasLine = true;
                }
                if (type === 'fill') {
                    hasFill = true;
                }
            }));
            this.symbolForm.style.display = hasSymbol ? '' : 'none';
            this.lineForm.style.display = hasLine ? '' : 'none';
            this.fillForm.style.display = hasFill ? '' : 'none';

            if (this.iconImage.children && this.iconImage.children[0]) {
                this.iconImage.children[0].remove();
            }
        }
    });
});
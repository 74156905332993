/**
 * 「河川・沿岸カメラ実況情報詳細」画面用モジュール。
 * @module app/riverCamera/RiverCameraObservationDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/dom-construct',
    'dojo/dom-class',
    'dojo/text!./templates/RiverCameraObservationDetailPage.html',
    'dojo/when',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/control/Locator',
    'app/util/DateFormatter',
    'idis/service/Requester',
    'idis/store/JsonFileMemory',
    'idis/util/DateUtils',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form'
], function (module, declare, lang, domStyle, domConstruct, domClass, template, when, DialogChain, _PageBase, Router,
    Locator, DateFormatter, Requester, JsonFileMemory, DateUtils) {
    /**
     * 水位状況情報詳細画面
     * @class RiverCameraObservationDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/observation/river/RiverCameraObservationDetailPage~RiverCameraObservationDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--rivercameraobs idis-Page--obsList',

        /**
         * 画像URL
         */
        // 河川カメラの画像が見つからない時のエラー画像URL
        COMMON_ERR_PNG_URL: 'images/observation/riverCamera/error.jpg',
        // 河川カメラの画像ベースURL
        RIVER_CAMERA_PNG_BASE_URL: 'data/camera-10min/',
        // 現在の河川カメラの画像(左に大きく出る画像)URL
        CURRENT_CAMERA_IMAGE_URL: null,

        // 現在の画像位置の画像インスタンス
        _currentImageInstance: null,

        // 以前まで選択されていたカルーセル画像インスタンス
        _previoudCarouselInstance: null,

        // 最初のカルーセル画像インスタンスが取得されているか
        _isPreviousInstance: false,

        constructor: function() {
            this.chain = DialogChain.get(this);
            // データを取得する
            this.store = new JsonFileMemory({
                idProperty: 'cameraId',
                target: '/data/camera/cctv.geojson'
            });
            // 画像URLを取得
            this.RIVER_CAMERA_PNG_BASE_URL += Locator.getQuery().id + '/';
            this.CURRENT_CAMERA_IMAGE_URL = this.RIVER_CAMERA_PNG_BASE_URL +
                Locator.getQuery().date + '.jpg';
        },

        buildRendering: function () {
            this.inherited(arguments);

            // カルーセル画像がクリックされた場合
            this.on('div:click', lang.hitch(this, function (e) {
                if (e.target.id.indexOf('carousel-image') === -1 ||
                    // 画像が取得できていない場合不可
                    e.target.src.indexOf(this.COMMON_ERR_PNG_URL) > 0) {
                        return;
                }
                if (this._previoudCarouselInstance!==e.target) {
                    // 上部にクリックした画像を表示
                    this._currentImageInstance.src = e.target.src;
                    // 表示中の画像の日時を表示
                    var originDate = e.target.src.substr(-20).substring(0, 16);
                    var date = originDate.substr(0, 10);
                    var initTime = originDate.substr(-5);
                    var imageDate = date + ' ' + initTime.replace(/-/g, ':');
                    this.currentCameraImgTitle.innerHTML = DateFormatter.jpDateTime(imageDate, true);
                    // 選択中のカルーセル画像枠に色付け
                    domStyle.set(e.target, 'border', 'solid 2px #00d897');
                    if (!this._isPreviousInstance) {
                        this._isPreviousInstance = true;
                    } else {
                        // 選択解除されたカルーセル画像枠の色戻す
                        domStyle.set(this._previoudCarouselInstance, 'border', 'none');
                    }
                    // 以前選択されていたものは、borderを消すため、インスタンスを保持
                    this._previoudCarouselInstance = e.target;
                } else {
                    // 同一のカルーセル画像を連続で押した場合、現在の画像に戻す
                    this._currentImageInstance.src = this.CURRENT_CAMERA_IMAGE_URL;
                    this.currentCameraImgTitle.innerHTML = '現在の画像';
                    domStyle.set(this._previoudCarouselInstance, 'border', 'none');
                    this._isPreviousInstance = false;
                    this._previoudCarouselInstance = null;
                }
            }));

            this.initPage();
        },

        /**
         * URLの状態に従い表示状態を更新する
         */
        initPage: function () {
            // カメラ情報を取得
            when(this.store.filter({id: Locator.getQuery().id}).fetch())
                .then(lang.hitch(this, function (cameraResult) {
                var targetCameraData = cameraResult[0];
                // パラメータのカメラIDが不正の場合、一覧に戻る
                if (!targetCameraData) {
                    this.chain.info('河川カメラ情報が取得できませんでした。', 'エラー', this.moveRiverCameraPage);
                }

                // 河川・沿岸カメラ詳細情報をセット
                // -観測局
                this.cameraObservatoryName.innerHTML = targetCameraData.name || '---';
                // -所轄
                this.manager.innerHTML = targetCameraData.manager || '---';
                // -市町村名
                this.municipalityName.innerHTML = targetCameraData.municipalityName || '---';
                // -住所
                this.address.innerHTML = targetCameraData.address || '---';
                // -河川名
                this.riverName.innerHTML = targetCameraData.rivername || '---';
                // -位置
                this.location.innerHTML = targetCameraData.location || '---';

                // 河川カメラ画像をセット
                // -平常時の画像
                this.setCameraImg(this.RIVER_CAMERA_PNG_BASE_URL + 'normal.jpg',
                    'is-normal-camera-img', this.normalImgNode);
                // -現在の画像
                this._currentImageInstance = this.setCameraImg(this.CURRENT_CAMERA_IMAGE_URL,
                    'is-current-camera-img', this.currentCameraImgNode);
                // -カルーセルの画像をセット
                this.setCarouselImage();
            }));

        },

        /**
        * 画像インスタンスをセット
        */
        setCameraImg: function (imageUrl, className, div) {
            var image = new Image();
            domClass.add(image, className);
            // 画像が取得できるかチェック
            Requester.get(imageUrl, {
                headers: { 'Content-Type': 'image/jpeg; charset=utf-8' },
                handleAs: null,
                preventCache: true
            }).then(lang.hitch(this, function () {
                image.src = imageUrl;
            }), lang.hitch(this, function () {
                // 画像が取得できない場合、エラー画像を表示
                image.src = this.COMMON_ERR_PNG_URL;
            })).then(function() {
                domConstruct.place(image, div);
            });
            return image;
        },

        /**
         * カルーセルの画像をセット
         */
        setCarouselImage: function () {
            // 表示対象日付データを取得
            var dates = DateUtils.getArroundDate(Locator.getQuery().date, false, 1000 * 600, 6);
            var htmlArray = [];
            dates.forEach(function (dateInfo, i) {
                Requester.get(this.RIVER_CAMERA_PNG_BASE_URL + dateInfo.pathFormatDate + '.jpg', {
                    headers: { 'Content-Type': 'image/jpeg; charset=utf-8' },
                    handleAs: null,
                    preventCache: true
                }).then(lang.hitch(this, function () {
                    return this.RIVER_CAMERA_PNG_BASE_URL + dateInfo.pathFormatDate + '.jpg';
                }), lang.hitch(this, function () {
                    // 画像取得失敗の場合、エラー画像のURLを返す
                    return this.COMMON_ERR_PNG_URL;
                })).then(lang.hitch(this, function(url){
                    var html = '';
                    html += '<div style="display: flex;flex-direction: column;';
                    var userAgent = window.navigator.userAgent.toLowerCase();
                    if (userAgent.match(/msie/) || userAgent.match(/trident/) || userAgent.match(/edge/)) {
                        html += 'justify-content: space-around;margin: 16px 36px;width:120px">';
                    } else {
                        html += 'justify-content: space-around;margin: 16px 0;width:120px">';
                    }
                    html += '<div style = "text-align: center;">';
                    html += DateFormatter.jpDateTime(dateInfo.dataFormatDate, true).replace(' ', '<br>');
                    html += '</div>';
                    html += '<img id="carousel-image" src="';
                    html += url;
                    html += '" ';
                    html += 'style="display: block;width: 120px;height: auto;cursor:pointer;">';
                    html += '</div>';
                    htmlArray.push({ index: i, html: html });
                    var sorted = htmlArray.sort(function (a, b) {
                        return a.index - b.index;
                    });

                    var filtered = sorted.map(function (item) {
                        return item.html;
                    });
                    this.cameraImageCarouselContainer.innerHTML = filtered.join('');
                }));
            }, this);
        },

        /**
         * 一覧へ戻るリンクがクリックされた際に呼ばれる
         */
        onRiverCameraLinkClick: function (evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 一覧画面へ遷移
            this.moveRiverCameraPage();
        },
        moveRiverCameraPage: function () {
            Router.moveTo('observation/rivercamera');
        }
    });
});

/**
 * 被害種別選択用モジュール
 * @module app/formDisplay/damagetype/DamageTypeSelector
 */
define([
  'module',
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/topic',
  'dojo/text!./templates/DamageTypeSelector.html',
  'app/model/DisasterInfo',
  'idis/view/dialog/InfoDialog',
  'idis/control/Locator',
  'idis/control/Router',
  'idis/store/IdisRest',
  'idis/view/_IdisWidgetBase',
  'app/damage/consts/DamageType',
  // 以下、変数として受け取らないモジュール
  'dijit/Dialog',
  './DamageTypeDialog'
], function(module, declare, lang, array, topic, template, DisasterInfo, InfoDialog,
  Locator, Router, IdisRest, WidgetBase, DamageType) {

  /**
   * 被害種別選択用モジュール
   * @class DamageTypeSelector
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), WidgetBase,
    /** @lends app/formDisplay/damagetype/DamageTypeSelector~DamageTypeSelector# */ {

    // テンプレート文字列
    templateString: template,

    // ルートに付与されるCSS
    baseClass: 'damagetype-selector',
    /**
     * データ格納用オブジェクト
     * @type {module:dstore/Store}
     */
    store: null,

    /**
     * コンストラクタ
     */
    constructor: function() {
        this.inherited(arguments);
    },

    /**
     * DOM生成
     */
    buildRendering: function() {
      this.inherited(arguments);
      this.damageType.innerText = '未選択';
    },

    /**
     * 画面生成完了
     */
    startup: function() {
      this.inherited(arguments);
      // このウィジェット消滅時にダイアログも削除
      this.own(this.dialog);
    },

    /**
     * 災害変更ダイアログを表示する
     */
    showDialog: function() {
      // ダイアログを表示
      this.dialog.show();
      // ダイアログ中のページウィジェットを取得
      var page = this.dialog.getChildren()[0];
      this.changeFlg = false;

      // ダイアログ側でOKボタンが押された際のイベント
      page.on('update', lang.hitch(this, function(evt) {
        // 親被害変更
        this.change(evt.data);
        console.log(evt);

        // 再選択で選択先を外した場合
        if (this.damageType.innerHTML === '') {
          this.damageType.innerText = '未選択';
		  // 選択されている親災害IDを解除する。
          topic.publish(module.id + '::released', this.dialog);
        }

        // ダイアログを非表示にする
        this.dialog.hide();
        InfoDialog.show('被害種別を選択しました');
      }));
      // ダイアログの画面を再表示する
      page.refresh();

    },

    /**
     * 選択されている親災害IDの選択を解除する。
     */
    release: function() {
        this.damageType.innerText = '未選択';
//        this.subDamageType.innerText = null;
        // 選択されている親災害IDを解除する。
        topic.publish(module.id + '::released', this.dialog);
    },

    /**
     * 種別を変更する
     */
    change: function(data) {
      // 変更された種別を画面へセット
//      this.damageType.innerText = DamageType[data.mainTypeId];

      var mainLabel = [];
      array.forEach(data.mainTypeIds, function(id){
          mainLabel.push(DamageType[id]);
      });

      this.damageType.innerText = mainLabel.join('、');
      this.changeFlg = true;

      // 選択した種別情報を設定する。
      topic.publish(module.id + '::selected', data);
    },

    resetDamageType: function () {
      Object.keys(this.innerDialog.grid.checkboxes)
        .forEach(lang.hitch(this, function (key) {
          var chkbox = this.innerDialog.grid.checkboxes[key];
          chkbox.set('checked', false);
        }));
    },

    /**
     * 被害種別をセットする
     */
    setDamageType: function(damageTypeList) {
        var damageType = {
                '01': '住家被害',
                '02': 'ライフライン（電気、水道等）',
                '03': '文教施設（学校等）',
                '04': '病院',
                '05': '庁舎等施設',
                '06': '土砂災害',
                '07': '火災',
                '08': '河川',
                '09': '道路',
                '10': '農地・農業用施設被害',
                '14': '林野被害',
                '15': '港湾被害',
                '11': 'その他被害',
                '12': '簡易報告',
                '13': '現地画像'
            };
        for (var i = 0; i < damageTypeList.length; i++) {
            this.innerDialog.grid.checkboxes[damageTypeList[i]].set('checked', true);
            if (this.innerDialog.grid.mainTypeIds.indexOf(damageTypeList[i]) === -1) {
                this.innerDialog.grid.mainTypeIds.push(damageTypeList[i]);
            }
            damageTypeList[i] = damageType[damageTypeList[i]];
        }
        this.damageType.innerText = damageTypeList.join('、');
    }
  });

});

/**
 * 職員招集 グループ登録・詳細画面部分の共通モジュール
 * @module app/convocation/view/group/_ConvoGroupPageBase
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dstore/Memory',
    'idis/control/Router',
    'idis/view/page/_PageBase', // 共通基底クラス
    //以下、変数で受けないモジュール
    'app/convocation/view/group/ConvoGroupRegisterGrid',
    'app/convocation/view/group/ConvoEditEmployeeDialog',
    'app/view/form/AddEmployeeSelector'
], function(module, declare, lang, Memory, Router, _PageBase) {
    /**
     * 職員招集 グループ登録・詳細画面部分の共通モジュール
     * @class _ConvoGroupPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/group/_ConvoGroupPageBase~_ConvoGroupPageBase# */
        {
            store: null,

            // 更新用招集職員番号
            employeeCdOld: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化
             */
            constructor: function() {
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoGroupRegisterGrid();
                this.initAddEmployeeSelector();
                this.initEditEmployeeDialog();
                this.own(this.addEmployeeSelector);
                this.own(this.convoEditEmployeeDialog);
            },

            /**
             * gridの初期化。
             */
            initConvoGroupRegisterGrid: function() {
                this.store = new Memory({
                    'idProperty': 'employeeCd',
                    'data': []
                });
                this.convoGroupRegisterGrid.set('collection', this.store);
                // グリッドの編集ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'edit'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.convoGroupRegisterGrid.on('editButtonClick', lang.hitch(this, function(evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.showConvoEditEmployeeDialog(evt.item);
                }));
            },

            /**
             * 職員追加ダイアログの処理初期化。
             */
            initAddEmployeeSelector: function() {
                // 職員追加ダイアログ
                var page = this.addEmployeeSelector;
                // ダイアログのregisterイベントをキャッチする
                page.on('register', lang.hitch(this, function(evt) {
                    // 入力チェックが成功したときのみ処理実施
                    if (Array.isArray(evt.value)) {
                        length = evt.value.length;
                        evt.value.forEach(lang.hitch(this, function(employee) {
                            if (this._validateEmpInfo(employee.employeeCd, true)) {
                                this.editEmployeeData(employee, true);
                            }
                        }));
                    } else {
                        if (this._validateEmpInfo(evt.value.employeeCd, true)) {
                            this.editEmployeeData(evt.value, true);
                        }
                    }
                }));
            },

            /**
             * 職員編集ダイアログの処理初期化。
             */
            initEditEmployeeDialog: function() {
                // 職員編集ダイアログ
                var dialog = this.convoEditEmployeeDialog;
                var page = dialog.getChildren()[0];
                //FIXME lang.hitchを使うとイベントのキャッチができない。原因要究明
                var self = this;
                // ダイアログのupdateイベントをキャッチする
                page.on('update', function(evt) {
                    // 入力チェックが成功したときのみ処理実施
                    if (self._validateEmpInfo(evt.value.employeeCd)) {
                        // 職員情報をセット
                        self.editEmployeeData(evt.value, false);
                        page.leave();
                        // form情報のリセット
                        page.form.reset();
                        // ダイアログを閉じる
                        dialog.hide();
                    }
                });
                // ダイアログのdeleteイベントをキャッチする
                page.on('delete', function(evt) {
                    // 職員コードに基づいてgridの行データを削除
                    self.store.remove(evt.employeeCd);
                    // 削除した後のstoreをgridに設定
                    self.convoGroupRegisterGrid.set('collection', self.store);
                    // 設定内容を反映するため、gridを更新
                    self.convoGroupRegisterGrid.refresh();
                    // ダイアログを閉じる
                    dialog.hide();
                });
            },

            /**
             * @param item 行データ
             * 職員編集ダイアログを表示する。
             */
            showConvoEditEmployeeDialog: function(item) {
                var page = this.convoEditEmployeeDialog.getChildren()[0];
                // 招集対象職員
                if (item.employeeCd) {
                    this.employeeCdOld = item.employeeCd;
                    page.employeeCd.set('value', item.employeeCd);
                }
                this.convoEditEmployeeDialog.show();
            },

            /**
             * @param data ダイアログから渡った職員情報
             * @param isAdd 追加の場合はtrue、falseなら更新
             * 職員情報をgridに追加・更新する。
             */
            editEmployeeData: function(data, isAdd) {
                if (!isAdd) {
                    // falseなら更新
                    // grid用データオブジェクトの行データを更新
                    this.store.remove(this.employeeCdOld);
                    this.store.putSync(data);
                } else {
                    // trueなら追加
                    // grid用データオブジェクトにデータを追加
                    this.store.addSync(data);
                }
                // 削除した後のstoreをgridに設定
                this.convoGroupRegisterGrid.set('collection', this.store);
                // 設定内容を反映するため、gridを更新
                this.convoGroupRegisterGrid.refresh();
            },

            // パンくずリストのボタンを押下したときの挙動
            onConvocationGroupAdminPageLinkClick : function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/group');
            },

            /**
             * パンくずリストのボタンを押下したときの挙動。
             */
            onConvoAutoMailLinkClick: function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/group');
            },

            /**
             * @param cd 招集対象職員の職員番号
             * 職員情報の入力チェック。
             */
            _validateEmpInfo: function(cd, addMode) {
                // 招集対象職員は必須
                if (!cd) {
                    this.chain.info('職員が選択されていません。', '入力エラー');
                    return false;
                }
                // gridのstore内に同じコードのグループがあれば追加しない
                if (addMode && this.store.getSync(cd)) {
                    this.chain.info('選択された職員は既に追加されています。', '入力エラー');
                    return false;
                }
                return true;
            }
        });
});

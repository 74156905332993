/**
* 体制状況一覧用モジュール。
* @module app/employgathering/EmployGatheringStatusListGrid
*/
define([
    'module',
    'dgrid/Editor',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './EmployGatheringColumn'
    // 以下、変数として受け取らないモジュール
], function(module, Editor, declare, lang, locale, IdisGrid, helper, EGColumn) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Editor], {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            EGColumn.municipalityName,
            helper.column('orgName', '組織名', {sortable: false}),
            EGColumn.gathringNum,
            EGColumn.reportTimestamp,
            EGColumn.comment
        ],
        farOffRemoval: 15000
    });
});

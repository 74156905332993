/**
 * ユーザの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/user/_UserDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, _PageBase, InfoDialog) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        // ユーザID
        _userId: null,

        constructor: function() {
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // accountType selection
            if (formData.roleCd === '') {
                InfoDialog.show('アカウント種類が選択されていません');
                return false;
            }

            // organization
            if (formData.organization === '') {
                InfoDialog.show('入力チェック', '組織が選択されていません');
                return false;
            }

            // 組織チェック
            switch (formData.roleCd) {
            case 'R01001': // システム管理
            case 'R01011': // 県・危機管理・管理
            case 'R01012': // 県・危機管理・一般
            case 'R01013': // 県・健康福祉
            case 'R01014': // 県・土木
            case 'R01015': // 県・農林
            case 'R01016': // 県・水産
            case 'R01017': // 県・その他部局
                // 県
                if (formData.organization.slice(0, 1) !== '1') {
                    InfoDialog.show('県組織を選択してください');
                    return false;
                }
                break;
            case 'R02011': // 振興局・危機管理・管理
            case 'R02012': // 振興局・危機管理・一般
            case 'R02013': // 振興局・健康福祉
            case 'R02014': // 振興局・土木
            case 'R02015': // 振興局・農林
            case 'R02016': // 振興局・水産
            case 'R02017': // 振興局・その他部局
            case 'R02018': // 振興局・現地報告
                // 振興局
                if (formData.organization.slice(0, 1) !== '2') {
                    InfoDialog.show('振興局組織を選択してください');
                    return false;
                }
                break;
            default:
                InfoDialog.show('選択されたアカウント種類は使用できません');
                return false;
            }

            // account name
            if (formData.userId === '') {
                InfoDialog.show('入力チェック', 'ユーザIDが入力されていません');
                return false;
            }
            if (formData.userName === '') {
                InfoDialog.show('入力チェック', 'ユーザ名が入力されていません');
                return false;
            }
            // password
            if (this.title.innerHTML === '職員招集管理者情報新規登録' && formData.userPassword === '') {
                InfoDialog.show('入力チェック', 'パスワードが入力されていません');
                return false;
            }
            if (formData.userPassword !== formData.passwordConfirm) {
                InfoDialog.show('入力チェック', '入力されたパスワードが一致しません');
                return false;
            }
            if (formData.userPassword !== '' && !this.isValidPassword(formData.userPassword)) {
                InfoDialog.show('入力チェック', '入力されたパスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
                return false;
            }
            return true;
        },

        /**
         * パスワードの妥当性を検証する。
         */
        isValidPassword: function(userPassword) {
            // 長さ
            if (userPassword.length < 8 || userPassword.length > 20) {
                return false;
            }

            // アルファベットが含まれているか
            if (/[^\x21-\x7e]/.test(userPassword)) {
                return false;
            }

            return true;
        }
    });
});

/**
* 要請措置状況一覧画面用モジュール。
* @module app/demand/DemandAdminPage
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dijit/registry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/_base/lang',
    'dojo/text!./templates/TimelinePage.html',
    'dojo/topic',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/util/DateUtils',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    './TimelineRiverConverter',
    'dijit/layout/BorderContainer',

    // 以下、変数として受け取らないモジュール

    'dijit/layout/ContentPane',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/OrganizationSelector',
    './TimelineDisasterSelector',
    './TimelineGrid',
    './TimelineRiverMultiSelector',
    './TimelineDetailRegisterDialog',
    './TimelineInfoChangeDialog',
    './TimelineChangeDialog',
    './TimelineActionPlanRegisterDialog'
], function (module, array, declare, registry, domStyle, json, lang, template, topic, IdisRest, DialogChain,
    _PageBase, Loader, Locator, Router, ACL, UserInfo, Requester, DateUtils,
    DisasterInfo, InfoDialog) {
    //  DisasterInfo, TimelineRiverConverter) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,




        /** @lends module:app/timeline/TimelinePage~TimelinePage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--timeline',

            /**
             * 災害ID
             */
            _disasterId: null,

            /**
             * 市町区コード
             */
            _municipalityCd: null,

            /**
             * タイムラインに紐付く対象河川コード
             */
            _targetRivers: null,

            /**
             * タイムラインに紐付く基準時刻
             */
            _zeroHour: null,

            /**
             * タイムラインに紐付く行動計画の登録ID
             */
            _registId: null,

            /**
             * タイムラインに紐付く行動計画の管理番号
             */
            _managementId: null,

            /**
             * タイムラインID
             */
            _timelineId: null,

            DISASTER_SELECT: 'app/timeline/TimelineDisasterSelector::selected',

            TIMELINE_COMPLETED: 'app/timeline/RegisterCell::updated',

            ALERT_CELL_CHANGED: 'app/timeline/AlertCell::updated',

            TIMELINE_CHANGED: 'app/timeline/TimelineRiverMultiSelector::updated',

            DETAIL_REGISTER: 'app/timeline/TimelineDetailRegisterDialog::regist',

            TIMELINE_UPDATE: 'app/timeline/TimelineInfoChangeDialog::update',

            TIMELINE_CHANGE: 'app/timeline/TimelineChangeDialog::update',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            DISASTER_TYPE_MAP: {
                '01': '風水害',
                '02': '地震・津波',
                '05': '火山',
                '04': '国民保護',
                '99': 'その他'
            },




            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
                // this.own(this.detailDialog);
                // this.own(this.formDialog);
                // 市町区コードをユーザーの市町区からセット
                this._municipalityCd = UserInfo.getMunicipalityCds()[0];

                // // グリッドを初期化する
                this.initGrid();
                // // ファイルアップロードフォームを初期化する
                // this.initFileUploadDialog();
                // // 詳細画面内のフォームがsubmitされた際の動作を設定
                // this.initDetailPage();
            },

            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    //結合文字が記号だとエラーが出るので"Join"で結合
                    idProperty: 'timelineDetailId',
                    target: '/api/timeline'
                });

                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }

                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
            },

            startup: function () {
                // 検索ペインのリセット
                this.searchForm.reset();

                this.inherited(arguments);
                this.setButtonStatus();

                // 画面自動更新用のタイマーを設定(1分毎)
                var self = this;
                this.timer = setInterval(lang.hitch(this, function () {
                    self.updateGridQuery(self.searchForm.get('value'));
                }), 1 * 60 * 1000);

                // 災害名選択ダイアログから親管理番号を受け取る。
                this.own(topic.subscribe(this.DISASTER_SELECT, lang.hitch(this, function (disaster) {
                    this.setDisasterInfo(disaster);
                    this.updateGridQuery(this.searchForm.get('value'));
                })));

                this.own(topic.subscribe(this.TIMELINE_COMPLETED, lang.hitch(this, function () {
                    this.updateGridQuery(this.searchForm.get('value'));
                })));

                this.own(topic.subscribe(this.ALERT_CELL_CHANGED, lang.hitch(this, function () {
                    this.updateGridQuery(this.searchForm.get('value'));
                })));

                this.own(topic.subscribe(this.TIMELINE_CHANGED, lang.hitch(this, function () {
                    this.updateGridQuery(this.searchForm.get('value'));
                })));

                this.own(topic.subscribe(this.DETAIL_REGISTER, lang.hitch(this, function (data) {
                    console.log(data);
                })));

                this.own(topic.subscribe(this.TIMELINE_UPDATE, lang.hitch(this, function (data) {
                    this.sendInfoChange(data);
                })));

                this.own(topic.subscribe(this.TIMELINE_CHANGE, lang.hitch(this, function (data) {
                    this.sendChangeTimeline(data);
                })));

                var promise = Requester.get('/api/timeline/' + this._disasterId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: false
                }).then(lang.hitch(this, function (data) {
                    console.log(data);
                    this.checkTimelineEnd(data);
                    //this.disasterType.innerHTML = this.DISASTER_TYPE_MAP[data.disasterType];
                    //this.timelineRivers.innerHTML = TimelineRiverConverter.convert(data.targetRivers);
                    this._managementId = data.managementId;
                    this._timelineId = data.timelineId;
                    this._registId = data.registId;
                    //this._targetRivers = data.targetRivers;
                    this._zeroHour = data.zeroHour;
                    this.headquarterStatus.innerHTML = data.headquarterStatus;
                    //this.timelineRivers._setValueAttr(data.targetRivers);
                    //this.timelineRivers._setDisastr(data);
                    //this.timelineRivers._setInitFlag();
                }));

                // 実施項目の追加用
                this.sendAddDetail();

                Loader.wait(promise);
            },

            /**
             * widgetが居なくなる時に呼ばれる
             * 定期処理を止める
             */
            destroy: function () {
                this.inherited(arguments);
                clearInterval(this.timer);
            },

            // 検索条件を表示するメソッド
            onOpenFilterClick: function () {
                domStyle.set(this.openFilterLink, 'display', 'none');
                domStyle.set(this.closeFilterLink, 'display', '');
                domStyle.set(this.searchCriteria.domNode, 'display', '');
                this.borderContainer.resize();

            },
            // 検索条件を非表示にするメソッド
            onCloseFilterClick: function () {
                domStyle.set(this.openFilterLink, 'display', '');
                domStyle.set(this.closeFilterLink, 'display', 'none');
                domStyle.set(this.searchCriteria.domNode, 'display', 'none');
                this.borderContainer.resize();

            },



            // タイムラインがないもしくは終了している際の画面制御
            checkTimelineEnd: function (data) {

                var buttonsState = true;
                var delButtonState = true;
                if (UserInfo.getRoleCd() === ACL.ADMIN_USER ||
                    UserInfo.getRoleCd() === ACL.CITY_CRI_MANAGE_USER ||
                    UserInfo.getRoleCd() === ACL.CITY_CRI_STAFF_USER) {
                    if (data !== null && data.zeroHour !== null) {
                        if (!lang.isObject(data.endTimestamp)) {
                            buttonsState = false;
                            this.timelineState.innerHTML = '終了';
                            domStyle.set(this.startTimelineButton.domNode, 'display', '');
                            domStyle.set(this.endTimelineButton.domNode, 'display', 'none');
                        } else {
                            this.timelineState.innerHTML = '稼働中';
                            domStyle.set(this.startTimelineButton.domNode, 'display', 'none');
                            domStyle.set(this.endTimelineButton.domNode, 'display', '');
                        }
                        this.timelineName.innerHTML = data.timelineName;
                        this.zeroHour.innerHTML = DateUtils.format(data.zeroHour, { time: 'HH:mm' });

                    } else {
                        buttonsState = false;
                        delButtonState = false;
                        this.timelineName.innerHTML = 'なし';
                        this.timelineState.innerHTML = 'なし';
                        this.zeroHour.innerHTML = '';
                        domStyle.set(this.startTimelineButton.domNode, 'display', 'none');
                        domStyle.set(this.endTimelineButton.domNode, 'display', 'none');
                        this.changeTimelineButton.set('disabled', false);
                        this.registerTimelineButton.set('disabled', false);
                    }
                    if (!delButtonState && !buttonsState) {
                        // タイムライン詳細が無い場合はタイムラインが無いので、関連するボタンを非活性に
                        this.deleteTimelineButton.set('disabled', true);
                        this.infoChangeButton.set('disabled', true);
                        this.addDetailButton.set('disabled', true);
                        this.startTimelineButton.set('disabled', true);
                        this.endTimelineButton.set('disabled', true);
                    }
                    if (delButtonState && !buttonsState) {
                        // タイムラインが終了している場合は、タイムライングリッドが編集できない状態となる
                        this.infoChangeButton.set('disabled', true);
                        this.addDetailButton.set('disabled', true);
                        this.changeTimelineButton.set('disabled', false);
                        this.registerTimelineButton.set('disabled', false);
                        this.startTimelineButton.set('disabled', false);
                        this.deleteTimelineButton.set('disabled', false);
                    }
                    if (delButtonState && buttonsState) {
                        // タイムラインが稼働中の場合は、終了、変更以外は可能
                        this.infoChangeButton.set('disabled', false);
                        this.addDetailButton.set('disabled', false);
                        this.changeTimelineButton.set('disabled', true);
                        this.registerTimelineButton.set('disabled', true);
                        this.endTimelineButton.set('disabled', false);
                        this.deleteTimelineButton.set('disabled', false);
                    }
                } else {
                    if (data !== null && data.zeroHour !== null) {
                        if (!lang.isObject(data.endTimestamp)) {
                            this.timelineState.innerHTML = '終了';
                        } else {
                            this.timelineState.innerHTML = '稼働中';
                        }
                        this.timelineName.innerHTML = data.timelineName;
                        this.zeroHour.innerHTML = DateUtils.format(data.zeroHour, { time: 'HH:mm' });

                    } else {
                        this.timelineName.innerHTML = 'なし';
                        this.timelineState.innerHTML = 'なし';
                        this.zeroHour.innerHTML = '';
                    }
                }
            },

            setButtonStatus: function () {
                // ユーザー権限が「閲覧ユーザ」の場合は新規登録ボタンを非活性に
                var role = UserInfo.getRoleCd();
                if (role === ACL.VIEW_USER ||
                    role === ACL.DEPT_USER ||
                    role === ACL.HQ_USER ||
                    role === ACL.WRITE) {
                    this.infoChangeButton.set('disabled', true);
                }

                if (!(role === ACL.ADMIN_USER ||
                    role === ACL.CITY_CRI_MANAGE_USER ||
                    role === ACL.CITY_CRI_STAFF_USER)) {
                    domStyle.set(this.infoChangeButton.domNode, 'display', 'none');
                    domStyle.set(this.deleteTimelineButton.domNode, 'display', 'none');
                    domStyle.set(this.addDetailButton.domNode, 'display', 'none');
                    domStyle.set(this.startTimelineButton.domNode, 'display', 'none');
                    domStyle.set(this.endTimelineButton.domNode, 'display', 'none');
                    domStyle.set(this.changeTimelineButton.domNode, 'display', 'none');
                    domStyle.set(this.registerTimelineButton.domNode, 'display', 'none');
                }
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function (value) {
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                filter = filter.eq('disasterId', this._disasterId);

                //対象期間(開始)
                if (value.startDateForSeach) {
                    filter = filter.eq('startDateForSeach', value.startDateForSeach.getTime());
                }

                //対象期間(終了)
                if (value.endDateForSeach) {
                    filter = filter.eq('endDateForSeach', value.endDateForSeach.getTime());
                }

                //表示対象河川
                if (value.timelineRiversForSearch) {
                    filter = filter.eq('timelineRiversForSearch', value.timelineRiversForSearch);
                }

                //カテゴリ
                filter = filter.eq('categoryCd', value.categoryCd);

                // 部 課 係
                if (value.hldOrganization !== '') {
                    filter = filter.eq('organization', value.hldOrganization.substr(1, 6));
                }

                //未実施のみ
                if (value.activeOnly && value.activeOnly.length > 0) {
                    filter = filter.eq('activeOnly', true);
                } else {
                    filter = filter.eq('activeOnly', false);
                }

                //アラート設定
                //filter = filter.eq('alertSettingFlg',value.alertSettingFlg);

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', collection);
            },

            /**
             * 検索ボタンが押されたときに呼び出される。
             * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
             * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
             */
            onSubmit: function () {
                try {
                    if (this.searchForm.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.searchForm.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            },

            setDisasterInfo: function (disasterInfo) {
                this.disasterType.innerHTML = disasterInfo.disasterType;
                this.headquarterStatus.innerHTML = disasterInfo.headquarterStatus;
                this.zeroHour.innerHTML = disasterInfo.zeroHour;
            },

            /**
             * 新規登録ダイアログを表示する。
             * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
             * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
             */
            showRegisterDialog: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 施設登録画面へ遷移
                Router.moveTo('demand/register');
            },

            /**
             * 詳細ダイアログを表示する。
             * @param {Object} item 参照する行のデータ
             */
            showDetailDialog: function (item) {
                // ダイアログの最初の子要素が詳細画面
                var page = this.detailDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
                page.form.reset();
                // 行データの内容をフォームに反映
                page.form.set('value', item);

                // 詳細ダイアログを表示
                this.detailDialog.show();
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.grid.on('registerButtonClick', lang.hitch(this, function (evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.showDetailDialog(evt.item);
                }));

                this.updateGridQuery(this.searchForm.get('value'));

            },

            /**
             * 詳細画面のフォームが投稿された際の動作を設定する。
             */
            initDetailPage: function () {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.detailDialog;
                var page = dialog.getChildren()[0];
                // 詳細画面のsendイベントを受け取る
                page.on('sendupdate', lang.hitch(this, function (evt) {
                    this.chain.confirmPut(function (chain) {
                        var promise = this.store.put(evt.value);
                        Loader.wait(promise).then(function () {
                            chain.infoComplete();
                            // 登録フォーム・ダイアログを閉じる
                            dialog.hide();
                        }, function (err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));

                page.on('senddelete', lang.hitch(this, function (evt) {

                    this.chain.confirmDel(function (chain) {
                        Loader.wait(this.store.remove(evt.value.rqstHistIdANDitemDetailCd)).then(function () {
                            dialog.hide();
                            chain.infoComplete();
                            // 登録フォーム・ダイアログを閉じる
                        }, function (err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));
            },

            infoChange: function () {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.infoChangeDialog;
                var page = dialog.getChildren()[0];
                // 現時点での基準時刻をセットする
                // page.timelineRiversForChange._setValueAttr(this._targetRivers);
                page.refarenceDateForChange._setValueAttr(this._zeroHour);
                this.infoChangeDialog.show();
            },

            sendInfoChange: function (data) {
                if (data.data.refarenceDateForChange.getTime() === this._zeroHour) {
                    //               data.data.timelineRiversForChange === this._targetRivers ) {
                    this.chain.info('変更がありませんでした。',
                        function () {
                            this.chain.hide();
                            this.infoChangeDialog.hide();
                        }
                    );
                } else {
                    // ダイアログを表示
                    this.chain.confirm('タイムラインを更新します。',
                        function (chain) {
                            var promise = Requester.put('/api/timeline/info/' + this._disasterId, {
                                data: {
                                    targetRivers: data.data.timelineRiversForChange,
                                    zeroHour: data.data.refarenceDateForChange.getTime()
                                }
                            }).then(function (data) {
                                chain.infoComplete(function () {
                                    // 更新結果の保持（ダイアログ再表示時に使用）
                                    //this._targetRivers = data.targetRivers.split(',');
                                    this._zeroHour = data.referenceTimestamp;
                                    // タイムライン画面への更新
                                    this.zeroHour.innerHTML = DateUtils.format(this._zeroHour, { time: 'HH:mm' });
                                    //this.timelineRivers.innerHTML = TimelineRiverConverter.convert(this._targetRivers);
                                    this.updateGridQuery(this.searchForm.get('value'));
                                    this.infoChangeDialog.hide();
                                    chain.hide();
                                });
                            }, function (error) {
                                console.log(error);
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        },
                        function () {
                            this.chain.hide();
                        }
                    );
                }
            },

            startTimeline: function () {
                // ダイアログを表示
                var message = 'タイムラインの開始を行います。開始後は情報を変更することが可能となり、予定時間以降にアラートが鳴ります。';
                this.chain.confirm(message,
                    function (chain) {
                        var promise = Requester.post('/api/timeline/start/' + this._disasterId, {
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(lang.hitch(this,
                            function () {
                                chain.infoComplete(function () {
                                    chain.hide();
                                    this.updateGridQuery(this.searchForm.get('value'));
                                    this.addDetailButton.set('disabled', false);
                                    this.startTimelineButton.set('disabled', false);
                                    this.endTimelineButton.set('disabled', false);
                                    this.changeTimelineButton.set('disabled', true);
                                    this.registerTimelineButton.set('disabled', true);
                                    this.infoChangeButton.set('disabled', false);
                                });
                                domStyle.set(this.startTimelineButton.domNode, 'display', 'none');
                                domStyle.set(this.endTimelineButton.domNode, 'display', '');
                                this.timelineState.innerHTML = '稼働中';
                            }),
                            function (error) {
                                console.log(error);
                                chain.infoError(error);
                            }
                        );
                        Loader.wait(promise);
                    },
                    function () {
                        this.chain.hide();
                    }
                );

            },

            endTimeline: function () {
                // ダイアログを表示
                var message = 'タイムラインの終了を行います。終了後は情報を変更することが不可となり、予定時間以降にアラートが鳴りません。';
                this.chain.confirm(message,
                    function (chain) {
                        var promise = Requester.post('/api/timeline/finished/' + this._disasterId, {
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(lang.hitch(this,
                            function () {
                                chain.infoComplete(function () {
                                    chain.hide();
                                    this.updateGridQuery(this.searchForm.get('value'));
                                    this.addDetailButton.set('disabled', true);
                                    this.startTimelineButton.set('disabled', false);
                                    this.endTimelineButton.set('disabled', true);
                                    this.changeTimelineButton.set('disabled', false);
                                    this.registerTimelineButton.set('disabled', false);
                                    this.infoChangeButton.set('disabled', true);
                                });
                                domStyle.set(this.startTimelineButton.domNode, 'display', '');
                                domStyle.set(this.endTimelineButton.domNode, 'display', 'none');
                                this.timelineState.innerHTML = '終了';
                            }),
                            function (error) {
                                console.log(error);
                                chain.infoError(error);
                            }
                        );
                        Loader.wait(promise);
                    },
                    function () {
                        this.chain.hide();
                    }
                );

            },

            changeTimeline: function () {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.changeDialog;
                var page = dialog.getChildren()[0];
                var managementId = this._managementId;
                // 現時点での基準時刻をセットする
                var promise = Requester.get('/api/timeline/change/' + this._disasterId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: false
                }).then(lang.hitch(this, function (data) {
                    var i;
                    for (i = 0; i < data.length; i++) {
                        if (data[i].managementId === '1') {
                            page.managementId1.innerHTML = data[i].managementId;
                            page.timelineName1.innerHTML = data[i].name;
                            page.registId1._setValueAttr(data[i].registId);
                            if (data[i].managementId !== managementId) {
                                domStyle.set(page.actionPlan1, 'display', '');
                            } else {
                                domStyle.set(page.actionPlan1, 'display', 'none');
                            }
                        } else if (data[i].managementId === '2') {
                            page.managementId2.innerHTML = data[i].managementId;
                            page.timelineName2.innerHTML = data[i].name;
                            page.registId2._setValueAttr(data[i].registId);
                            if (data[i].managementId !== managementId) {
                                domStyle.set(page.actionPlan2, 'display', '');
                            } else {
                                domStyle.set(page.actionPlan2, 'display', 'none');
                            }
                        } else if (data[i].managementId === '3') {
                            page.managementId3.innerHTML = data[i].managementId;
                            page.timelineName3.innerHTML = data[i].name;
                            page.registId3._setValueAttr(data[i].registId);
                            if (data[i].managementId !== managementId) {
                                domStyle.set(page.actionPlan3, 'display', '');
                            } else {
                                domStyle.set(page.actionPlan3, 'display', 'none');
                            }
                        } else if (data[i].managementId === '4') {
                            page.managementId4.innerHTML = data[i].managementId;
                            page.timelineName4.innerHTML = data[i].name;
                            page.registId4._setValueAttr(data[i].registId);
                            if (data[i].managementId !== managementId) {
                                domStyle.set(page.actionPlan4, 'display', '');
                            } else {
                                domStyle.set(page.actionPlan4, 'display', 'none');
                            }
                        } else if (data[i].managementId === '5') {
                            page.managementId5.innerHTML = data[i].managementId;
                            page.timelineName5.innerHTML = data[i].name;
                            page.registId5._setValueAttr(data[i].registId);
                            if (data[i].managementId !== managementId) {
                                domStyle.set(page.actionPlan5, 'display', '');
                            } else {
                                domStyle.set(page.actionPlan5, 'display', 'none');
                            }
                        }
                    }
                }));
                Loader.wait(promise);

                this.changeDialog.show();
            },

            sendChangeTimeline: function (data) {
                if (data.data.activeFlg !== null) {
                    //data.data.referenceTime.getTime() === this._zerohour 
                    //&& data.data.timelineRiversForChange === this._targetRivers ) {
                    var dialog = this.changeDialog;
                    var page = dialog.getChildren()[0];
                    var registId;
                    if (data.data.activeFlg === '1') {
                        registId = data.data.registId1;
                    } else if (data.data.activeFlg === '2') {
                        registId = data.data.registId2;
                    } else if (data.data.activeFlg === '3') {
                        registId = data.data.registId3;
                    } else if (data.data.activeFlg === '4') {
                        registId = data.data.registId4;
                    } else if (data.data.activeFlg === '5') {
                        registId = data.data.registId5;
                    }
                    this.chain.confirm('タイムラインを変更します。現在表示中のタイムラインはステータスが完了となります。',
                        function (chain) {
                            var promise = Requester.post('/api/timeline/change/' + this._disasterId, {
                                data: {
                                    registId: registId
                                    //managementId: managementId
                                }
                            }).then(function (data) {
                                chain.infoComplete(function () {
                                    // 更新結果の保持（ダイアログ再表示時に使用）
                                    console.log(data);
                                    this.checkTimelineEnd(data);
                                    this._managementId = data.managementId;
                                    this._timelineId = data.timelineId;
                                    this._registId = data.registId;
                                    //this._targetRivers = data.targetRivers;
                                    this._zeroHour = data.zeroHour;
                                    //this.headquarterStatus.innerHTML = data.headquarterStatus;
                                    //this.timelineRivers._setValueAttr(data.targetRivers);
                                    //this.timelineRivers._setDisastr(data);
                                    //this.timelineRivers._setInitFlag();
                                    this.updateGridQuery(this.searchForm.get('value'));
                                    page.changeTimelineForm.reset();
                                    this.changeDialog.hide();
                                    // ダイアログ初期化
                                    //this.changeDialog.getChildren()[0].form.reset();
                                    chain.hide();
                                });
                            }, function (error) {
                                console.log(error);
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                            //window.location.reload();
                        },
                        function () {
                            this.chain.hide();
                        }
                    );
                } else {
                    this.chain.info('タイムラインを選択してください',
                        function () {
                            this.chain.hide();
                            //this.changeDialog.hide();
                        }
                    );
                }
            },

            deleteTimeline: function () {

                // ダイアログを表示
                var message = 'タイムラインの削除を行います。完了日時を含め、情報は全て削除されます。';
                this.chain.confirm(message,
                    function (chain) {
                        var promise = Requester.del('/api/timeline/' + this._disasterId, {
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(lang.hitch(this,
                            function () {
                                chain.infoComplete(function () {
                                    // タイムライン削除後は、変更ボタンを使用不可にする
                                    chain.hide();
                                    this.updateGridQuery(this.searchForm.get('value'));
                                    this.checkTimelineEnd(null);
                                    this.startup();
                                });
                            }),
                            function (error) {
                                console.log(error);
                                chain.infoError(error);
                            }
                        );
                        Loader.wait(promise);
                    },
                    function () {
                        this.chain.hide();
                    }
                );
            },

            addDetail: function () {
                var dialog = this.addDialog;
                var page = dialog.getChildren()[0];

                // 基準時刻をセット
                page.zeroHour.innerHTML = this.zeroHour.innerHTML;
                page.estimatedTime._setValueAttr(DateUtils.format(new Date().getTime(), { time: 'HH:00' }));

                //  page.hldOrganization._setValueAttr('001');

                // 実施項目追加ダイアログを表示
                this.addDialog.show();
            },

            sendAddDetail: function () {
                // 登録ダイアログの最初の子要素が登録画面
                var dialog = this.addDialog;
                var page = dialog.getChildren()[0];
                // 登録画面のsendイベントを受け取る
                page.on('send', lang.hitch(this, function (evt) {
                    //登録確認ダイアログを表示
                    if (this.validate(evt.value)) {
                        this.chain.confirmAdd(function (chain) {
                            var jsonStr = json.stringify(evt.value);
                            var promise = Requester.post('/api/timeline/regist/' + this._disasterId, {
                                data: jsonStr,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: false
                            }).then(lang.hitch(this, function () {
                                console.debug('success register TimelineDetail');
                                this.updateGridQuery(this.searchForm.get('value'));
                                chain.infoComplete(lang.hitch(this, function () {
                                    chain.hide();
                                    dialog.hide();
                                }));
                            }), function (error) {
                                chain.infoError(error);
                            });
                            Loader.wait(promise);
                        });
                    }
                }));
            },

            //実施項目バリデーション
            validate: function (data) {
                //ファイルが空の場合は処理を中断
                if (data.estimatedTime === '') {
                    InfoDialog.show('入力チェック', '予定時刻が入力されていません');
                    return false;
                }
                if (data.organization === '') {
                    InfoDialog.show('入力チェック', '対応課が選択されていません');
                    return false;
                }
                if (data.comment === '') {
                    InfoDialog.show('入力チェック', '実施項目が入力されていません');
                    return false;
                }
                return true;
            },

            onChangeDisaster: function () {
            },

            onChangeOrg: function () {
            },

            /**
             * 施設が選択されていることを検証する
             */
            checkFacility: function (facilityId) {
                var result = false;
                this.facilityId.model.store.get(facilityId).then(
                    lang.hitch(this, function (item) {
                        if (item.type === 'MFacility') {
                            result = true;
                        } else {
                            result = false;
                        }
                    }
                    ));
                return result;
            },

            showFileUploadDialog: function () {
                this.fileUploadDialog.show();
            },

            /**
             * ファイルアプロードダイアログ初期化処理
             */
            initFileUploadDialog: function () {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.fileUploadDialog;
                var page = dialog.getChildren()[0];
                dialog._demandIdList = [];
                // 登録ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function () {
                    dialog.hide();
                    this.updateGridQuery(this.form.get('value'));
                }));
            },


            outputListExcel: function () {
                var dialog = this.formDialog.getChildren()[0];
                // 前日0時から現在日時までを日付範囲の初期値に設定
                var value = this.form.get('value');
                var now = new Date();
                var yday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
                yday.setHours(0);
                yday.setMinutes(0);
                yday.setSeconds(0);
                yday.setMilliseconds(0);
                value.orderDateStart = yday;
                value.orderDateEnd = now;
                dialog.initDialog(value);
                this.formDialog.show();
            },

            registerTimeline: function () {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.registDialog;
                var page = dialog.getChildren()[0];
                // 現時点での基準時刻をセットする
                var promise = Requester.get('/api/timeline/plan', {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: false
                }).then(lang.hitch(this, function (data) {
                    array.forEach(data, function (plan) {
                        if (plan.managementId === '1') {
                            page.managementId1.innerHTML = plan.managementId;
                            page.timelineName1.innerHTML = plan.name;
                            page.registId1._setValueAttr(plan.registId);
                            domStyle.set(page.actionPlan1, 'display', '');
                        } else if (plan.managementId === '2') {
                            page.managementId2.innerHTML = plan.managementId;
                            page.timelineName2.innerHTML = plan.name;
                            page.registId2._setValueAttr(plan.registId);
                            domStyle.set(page.actionPlan2, 'display', '');
                        } else if (plan.managementId === '3') {
                            page.managementId3.innerHTML = plan.managementId;
                            page.timelineName3.innerHTML = plan.name;
                            page.registId3._setValueAttr(plan.registId);
                            domStyle.set(page.actionPlan3, 'display', '');
                        } else if (plan.managementId === '4') {
                            page.managementId4.innerHTML = plan.managementId;
                            page.timelineName4.innerHTML = plan.name;
                            page.registId4._setValueAttr(plan.registId);
                            domStyle.set(page.actionPlan4, 'display', '');
                        } else if (plan.managementId === '5') {
                            page.managementId5.innerHTML = plan.managementId;
                            page.timelineName5.innerHTML = plan.name;
                            page.registId5._setValueAttr(plan.registId);
                            domStyle.set(page.actionPlan5, 'display', '');
                        }
                    });
                }));
                Loader.wait(promise);
                this.registDialog.show();
            }
        });
});

/**
 * 行政区域選択ダイアログ（DistrictDialog.js）と既存オブジェクトとのPub/Subのハブとなるオブジェクト
 * 作図情報一覧（DrawPanel.js）から呼ばれる
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/topic',
	'dojo/_base/lang',
	'dojo/_base/array',
	'leaflet',
	'./DistrictUtils'
], function(module, declare, topic, lang, array, L, DistrictUtils){
	
	var TOPIC = {
		REMOVE_FEATURE : '/app/draw/DrawPanel::removeDistrictFeature',
		ADD_FEATURE : '/app/draw/DrawPanel::addDistrictFeature'
	};
	
	// デフォルトのスタイル
	var DEFAULT_STYLE = {
		COLOR: '#ffa500',	// rgb(255, 165, 0)　オレンジ
		FILL_COLOR: '#ffa500', // 色はColorSelectorに設定されている必要がある
		WEIGHT: 2,	// LineSelectorに設定されていないとエラーとなるので注意
		OPACITY: 1,
		FILL_OPACITY: 0.5,
		LINE_STYLE: 'solid',	// 線種（独自のスタイル）
		DRAW_TYPE: 'district'	// 作図種別
	};
	
	var DistrictDrawController = declare(null, {
		
		constructor: function(){
			
			// 行政区域選択ダイアログでの選択状態変更トピックを取得
			topic.subscribe(TOPIC.REMOVE_FEATURE, lang.hitch(this, function(msg){
				// 作図レイヤーから削除
				this._removeLayers(msg.feature.properties.dcode, msg.featureGroup);
			}));
			topic.subscribe(TOPIC.ADD_FEATURE, lang.hitch(this, function(msg){
				// 作図レイヤーに追加
				this._addLayers(msg.feature, msg.featureGroup);
			}));
			
		},
		
		/**
		 * 作図レイヤーから削除する
		 * @param {String} 行政コード
		 */
		_removeLayers: function(code, featureGroup){
			
			// 削除対象のレイヤー
			var layers = DistrictUtils.getLayers(code, featureGroup);
			// 作図レイヤーから削除
			// 作図レイヤーが既に地図に追加されているため、作図レイヤーから削除すれば地図から削除される
			array.forEach(layers, function(layer){
				featureGroup.removeLayer(layer);
			});
		},
		
		/**
		 * 作図レイヤーに追加する
		 * @param {Object} geojsonデータ
		 */
		_addLayers: function(feature, featureGroup){
			
			// レイヤー生成
			// geojsonで追加するとlayergroupで追加されるため、他作図オブジェクトと異なり深くなってしまい、
			// 編集や削除など既存機能の利用ができない。
			// 従って、マルチポリゴンをばらしてシングルにして追加する。
			// geojsonのcoordinateは[longitude, latitude]であるが、Leafletでは[latitude, longitude]で指定する必要があるため、
			// feature.geometry.coodinatesから座標を取得してL.polygon(latlngs)で追加する場合は、
			// 緯度経度の順序を入れ替える必要がある。
			// これに対応されたL.geoJsonで一旦レイヤーを作成し、その中から個々のpolygonレイヤーを取得してセットしている
			var geojsonLayers = L.geoJson(feature)._layers;
			var layers = null;
			for (var key in geojsonLayers) {
				if (geojsonLayers.hasOwnProperty(key)) {
					// ポリゴンレイヤーの集合オブジェクトを取得
					layers = geojsonLayers[key]._layers;
				}
			}
			if (!layers) {
				layers = geojsonLayers;
			}

			// 個々のポリゴンレイヤーを個別に作図レイヤーに追加
			for (var key in layers) {
				if (layers.hasOwnProperty(key)) {
					var layer = layers[key];
					// lineStyle（線種）は独自のプロパティー
					layer.setStyle({
						color: DEFAULT_STYLE.COLOR,
						weight: DEFAULT_STYLE.WEIGHT,
						fillColor: DEFAULT_STYLE.FILL_COLOR,
						fillOpacity: DEFAULT_STYLE.FILL_OPACITY,
						lineStyle: DEFAULT_STYLE.LINE_STYLE,
						drawType: DEFAULT_STYLE.DRAW_TYPE
					});
					
					// 他の作図レイヤーに合わせてプロパティーを設定
					// tableKeyとtableValueは他作図レイヤーのデフォルト値を設定
					// これがないと編集ダイアログ表示でエラーとなる
					layer.layerInfo = {
						files: [],
						free: '',
						tableKey: [''],
						tableValue:[''],
						title: '',
						tmpFiles: [],
						// 以下、行政区域用の設定
						// 行政コード
						districtCode: feature.properties.dcode,
						// 都道府県（'prefecture'） or 市区町村（'city'）
						districtType: ((feature.properties.pname !== void 0) ? 'prefecture' : 'city'),
						// 都道府県名 or 市区町村名
						districtName: ((feature.properties.pname !== void 0) ? feature.properties.pname : feature.properties.dname)
					};
					
					// 作図レイヤーが既に地図に追加されているため、作図レイヤーに追加すれば地図に表示される
					// DrawControllerの対象レイヤーとなる
					featureGroup.addLayer(layer);
					
				}
			}
		}

	});

	return new DistrictDrawController();
	
});
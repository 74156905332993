define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    'app/evacorder/EvacOrderColumn',
    'app/model/CrisisManageRiverLevelReferenceLevel',
    'app/model/EarthquakeIntensityLevel',
    'app/model/FloodWarningLevel',
    'app/model/KikikuruRiskLevel',
    'app/model/RiverLevelReferenceLevel',
    'app/model/TsunamiWarningLevel',
], function (module, array, declare, IdisGrid, helper,
        CommonColumn, EvacOrderColumn,
        CrisisManageRiverLevelReferenceLevel, EarthquakeIntensityLevel, FloodWarningLevel, KikikuruRiskLevel,
        RiverLevelReferenceLevel, TsunamiWarningLevel) {

    var EMPTY_LABEL = '-';

    var omissionFormatter = function(value){
        return value?.length > 15 ? (value.substring(0, 15) + '...') : (value ? value : EMPTY_LABEL);
    };

    var issueFormatter = function(value){
        return value ? '発表' : EMPTY_LABEL;
    };

    var modelFormatter = function(value, model){
        var matchItem = array.filter(model.data, function(item) {
            return item.level === value;
        });
        return matchItem?.length > 0 ? matchItem[0].label : EMPTY_LABEL;
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            CommonColumn.municipalityCd,
            EvacOrderColumn.evacOrderType,
            EvacOrderColumn.issueReasonType,
            CommonColumn.optionColumnNotSortable('kisyoList', '気象注意報・警報'),
            helper.column('landslide', '土砂\n災害\n警戒', {
                formatter: issueFormatter, sortable: false
            }),
            helper.column('flood', '指定河川洪水予報', {
                children: [
                    helper.column('floodRiverStationList', '水位観測所', {
                        formatter: omissionFormatter, sortable: false
                    }),
                    helper.column('floodCriteria', '基準値', {
                        formatter: function(value) {
                            return modelFormatter(value, FloodWarningLevel);
                        }, sortable: false
                    }),
                ], sortable: false
            }),
            helper.column('heavyrain', '記録的\n短時間\n大雨', {
                formatter: issueFormatter, sortable: false
            }),
            helper.column('rainfall', '危険度情報', {
                children: [
                    helper.column('sedimentLevel', '土砂', {
                        formatter: function(value) {
                            return modelFormatter(value, KikikuruRiskLevel);
                        }, sortable: false
                    }),
                    helper.column('floodRiskInformationLevel', '洪水', {
                        formatter: function(value) {
                            return modelFormatter(value, KikikuruRiskLevel);
                        }, sortable: false
                    }),
                    helper.column('heavyRainRiskInfoLevel', '浸水害', {
                        formatter: function(value) {
                            return modelFormatter(value, KikikuruRiskLevel);
                        }, sortable: false
                    }),
                ],
                sortable: false
            }),
            helper.column('earthquake', '震度', {
                formatter: function(value) {
                    return modelFormatter(value, EarthquakeIntensityLevel);
                }, sortable: false
            }),
            helper.column('tsunami', '津波', {
                formatter: function(value) {
                    return modelFormatter(value, TsunamiWarningLevel);
                }, sortable: false
            }),
            helper.column('rainfall', '雨量', {
                children: [
                    helper.column('rainfallObsList', '観測局', {
                        formatter: omissionFormatter, sortable: false
                    }),
                    CommonColumn.thresholdDissotable('rainfallCumulative', '累加\n雨量\n[mm]', 1),
                ],
                sortable: false
            }),
            helper.column('riverLevel', '水位', {
                children: [
                    helper.column('riverLevelObsList', '観測局', {
                        formatter: omissionFormatter, sortable: false
                    }),
                    helper.column('riverLevelCriteria', '基準値', {
                        formatter: function(value) {
                            return modelFormatter(value, RiverLevelReferenceLevel);
                        }, sortable: false
                    }),
                    CommonColumn.thresholdDissotable('riverLevel', '水位\n[m]'),
                ],
                sortable: false
            }),
            helper.column('crisisManageRiverLevel', '危機管理型水位', {
                children: [
                    helper.column('crisisManageRiverLevelObsList', '水位計', {
                        formatter: omissionFormatter, sortable: false
                    }),
                    helper.column('crisisManageRiverLevel', '基準値', {
                        formatter: function(value) {
                            return modelFormatter(value, CrisisManageRiverLevelReferenceLevel);
                        }, sortable: false
                    }),
                ],
                sortable: false
            }),
            helper.column('manualMessage', '手動メッセージ', {
                formatter: omissionFormatter, sortable: false
            }),
        ],
    });
});

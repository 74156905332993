define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/on',
    'idis/view/grid/IdisGrid',
    'idis/view/form/Button',
    'idis/util/DateUtils'
], function(module, dojo, declare, on, IdisGrid, Button, DateUtils) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--fdmareport',

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && (item.reportedFlg === '0') ? 'red-row' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [

            {
                field: 'detail',
                label: '詳細',
                sortable: false,
                renderCell: function(item) {
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: '詳細',
                        onClick: function() {
                            on.emit(gridNode, 'detailButtonClick', {item: item});
                        }
                    });
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return button.domNode;
                }
            },
            {field: 'seqNum', label: '報告番号', sortable: false},
            {field: 'reportTimestamp', label: '報告日時', className: 'reportTimestamp',
                renderCell: function(item) {
                    var actions = dojo.create('div');
                    var reportTime = dojo.create('div');
                    var text = DateUtils.format(item.reportTimestamp, {date: 'yyyy/MM/dd', time: 'HH:mm'});
                    reportTime.innerHTML = text;
                    actions.appendChild(reportTime);
                    if (item.statusFlg === "1") {
                        var correctState = dojo.create('div', {
                            innerHTML: "(訂正前)",
                            style: { color: "red" }
                        });
                        actions.appendChild(correctState);
                    } else if (item.statusFlg === "2") {
                        var cancelState = dojo.create('div', {
                            innerHTML: "(取消前)",
                            style: { color: "red" }
                        });
                        actions.appendChild(cancelState);
                    }
                    return actions;
                }
            },
            {label: '人的被害',field: 'human', sortable: false,
            children: [
                {field: 'deadNum', label: '死者', sortable: true},
                {field: 'unknownNum', label: '行方不明者', sortable: true},
                {field: 'seriousNum', label: '重傷者', sortable: true},
                {field: 'mildNum', label: '軽傷者', sortable: true}
            ]
            },
            {label: '物的被害（住家被害）',field: 'property', sortable: false,
                children: [
                {field: 'fullDestlHm', label: '全壊', sortable: true},
                {field: 'halfDestlHm', label: '半壊', sortable: true},
                {field: 'partDestlHm', label: '一部損壊', sortable: true},
                {field: 'fldAbvHm', label: '床上浸水', sortable: true},
                {field: 'fldBlwHm', label: '床下浸水', sortable: true}
            ]
            },
            { field: 'note', label: '備考', sortable: false,
                formatter: function(value) { return value.replaceAll(/\r?\n/g, '<br />'); }
            }
        ]
    });
});

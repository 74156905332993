/**
 * 確認ダイアログ
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderCancelDialog.html',
    'idis/consts/CONTENT_TYPE',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/_IdisWidgetBase',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール,
    'idis/view/form/WordCountTextBox'
], function (module, declare, lang, on, json, topic, template,
    CONTENT_TYPE, ConfirmDialog, InfoDialog, _IdisWidgetBase,
    Router, Requester, Loader, DialogChain) {

    var content = declare('_PrintDialogContent', _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Dialog idis-Dialog--evacorder',

        widgetsInTemplate: true
    });

    return declare(module.id.replace(/\//g, '.'), [ConfirmDialog], {
        /** @lends module:idis/view/page/ProvideRegisterCityDialog~ProvideRegisterCityDialog# */
        /**
         * ダイアログのタイトル
         */
        title: '避難情報取消',

        // 画面からセットされる。
        requestUrl: null,

        /**
         * コンストラクター
         * mapとlayerControl必須
         */
        constructor: function (options) {
            lang.mixin(this, options);
            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
        },
        /**
         * DOM生成後の処理
         */
        postCreate: function () {
            this.inherited(arguments);
            this.set('content', this.inner);
        },

        onOK: function () {
            // 訂正確認画面ダイアログを表示する。
            var data = {
                'correctCancelReason': this.inner.reason.value
            };
            this.chain._confirmFor('避難情報を取消', lang.hitch(this, function (chain) {
                var promise = Requester.put(this.requestUrl, {
                    data: data
                });
                // 処理と共にローディング表示
                Loader.wait(promise).then(lang.hitch(this, function () {
                    // 完了ダイアログを表示
                    chain.infoComplete(function () {
                        Router.moveTo('evacorder', {
                            'municipalityCd': this._municipalityCd
                        });
                    }, function (err) {
                        // 失敗時にはエラーダイアログを表示
                        if (err.response.data && typeof (err.response.data) === 'string') {
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                }));
            }));
        }
    });
});

/**
 * 避難情報の種別（セレクター作成用）
 * @module app/evacorder/EvacOrderTypeModel
 */
define([
    {
        evacOrderTypeCd: '11',
        labelWithLevel: '【警戒レベル3】高齢者等避難',
        labelWithoutLevel: '高齢者等避難',
        available: true
    },
    {
        evacOrderTypeCd: '13',
        labelWithLevel: '【警戒レベル4】避難指示',
        labelWithoutLevel: '避難指示',
        available: true
    },
    {
        evacOrderTypeCd: '14',
        labelWithLevel: '【警戒レベル5】緊急安全確保',
        labelWithoutLevel: '緊急安全確保',
        available: true
    },
    // 「警戒区域」はシステムでお客様に提供はしないものの、XML検証試験には必要な項目であるため、
    // 画面には出さないあえて残します。後続の開発者の方も消さないようお願いします。
    //  XML検証試験では、このファイルの下記のコメントアウト部を外すだけで警戒区域を発表できるようになります。
    // {
    //     evacOrderTypeCd: '19',
    //     labelWithLevel: '警戒区域',
    //     labelWithoutLevel: '警戒区域',
    //     available: true
    // },
    {
        evacOrderTypeCd: '80',
        labelWithLevel: '解除',
        labelWithoutLevel: '解除',
        available: true
    }
]);

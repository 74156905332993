/**
* 津波情報一覧画面用モジュール。
* @module app/tsunami/view/TsunamiPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, locale, IdisGrid, helper, CommonColumn) {

	/**
   * 日時を表示形式に変換する
   * 'yyyy-MM-dd HH:mm:dd'を'年月日時分'に変換する
   * FIXME 外部化を検討。helperで対応?
   *
   * @param {string} 'yyyy-MM-dd HH:mm:dd'
   * @return {string} 'yyyy年MM月dd日 HH時mm分'
   */
	var formatDateTime = function(value) {
		var dateLabel = locale.format(new Date(value.replace(/-/g, '/')), {
			selector: 'date',
				datePattern: 'MM月dd日'
		});
		var timeLabel = locale.format(new Date(value.replace(/-/g, '/')), {
			selector: 'time',
				timePattern: 'HH時mm分'
		});
		return dateLabel + ' ' + timeLabel;
	};

	return declare(module.id.replace(/\//g, '.'), IdisGrid, {
    // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
    rowClassName: function(item) {
		return item && item.pubStatus === 1 ? 'is-public' : '';
    },
    /**
      * 各列の定義
      * @type {Object[]}
      */
		columns: [
			helper.buttonColumn('detail', '詳細'),
			helper.column('reportDatetime', '発表日時', { formatter: formatDateTime }),
			CommonColumn.text
		]
	});
});

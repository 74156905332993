/**
 * 雨量情報詳細画面用モジュール。
 * @module app/tide/RainfallObservationDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/text!./templates/RainfallObservationDetailPage.html',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/util/DateUtils',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    './chart/RainHistoryChart',
    'app/consts/APP_QUERY',
    // 以下、変数で受けないモジュール
    'app/view/form/ObservationRegionSelector',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './RainfallLegend',
    './grid/RainfallObservationDetailPageGrid'
], function(module, array, declare, lang, Deferred, domStyle, topic, template,
		Locator, Router, Requester, IdisRest, DateUtils, Loader, _PageBase,
		DateTimeSelect, DataKind, RainHistoryChart, APP_QUERY)  {
    /**
     * 雨量状況情報詳細画面
     * @class RainfallObservationDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/tide/RainfallObservationDetailPage~RainfallObservationDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--rainfallobs',

        // ストア
        store: null,

        // 変更フラグ
        changeFlg: 0,

        // 時間モード
        mode: '10',

        lastRequestDateTime: '',

        constructor: function() {
            this.store = new IdisRest({
                target: '/api/tide/detail'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 一覧画面から遷移していない場合の初期表示は、時間モード10分で最新の時間データ
            Requester.get('/api/rain/10min/latestDataTime').then(lang.hitch(this, function(data){
				// 雨量データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'rainId',
                    target: '/api/rain/10min/detail'
                });
	            // 日付セレクトボックスを生成・設置する
                var dateQuery = Number(Locator.getQuery().date);
				if(dateQuery){
					data = dateQuery;
				}
				this.dateTimeSelect = new DateTimeSelect({
					to: data
				});
				//
				this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
				this.borderContainer.resize();
			}));
        },

        startup: function() {
            this.inherited(arguments);
			this.initForm();
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
			this.inherited(arguments);
			// 日時の変更を監視
			this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, function(datetime) {
				// 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
				// 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみデータを取得するようにする
				if (this.lastRequestDateTime !== (datetime.date + ' ' + datetime.time)) {
					var timeMode = '10min';
            		// データを取得する
                    this.store = new IdisRest({
                        idProperty: 'rainId',
                        target: '/api/rain/' + timeMode +'/detail'
                    });
					var filter = new this.store.Filter();
            		// 時間モードをフィルターにセット
                    filter = filter.eq('timeMode', this.mode);
            		// 観測日時をフィルターにセット
                    var date = new Date(datetime.date + ' ' + datetime.time).getTime();
                    filter = filter.eq('date', date);
                    // [観測局]のセット
                    var value = this.form.get('value');
                    if (value.observatoryId) {
                        filter = filter.eq('observatoryId', value.observatoryId);
                    }else{
                    	// 初期化時に非同期処理によりobservatoryIdが取得できていない場合、Locatorからセット
                        filter = filter.eq('observatoryId', Locator.getQuery().observatoryId);
                    }
                    var collection = this.store.filter(filter);
					this.grid.set('collection', collection);

					collection.fetch().then(lang.hitch(this, function(data) {
						if(data.totalLength !== 0){
							this.initText(data);
							this.initChart(data);
						}
					})
					);

				}
				this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
            })));
            // 最新ボタンクリックを監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, function() {
				var timeMode = '10min';
				// 最新の観測情報を取得
				Requester.get('/api/rain/'+ timeMode + '/latestDataTime').then(lang.hitch(this, function(data){
					// 観測日時セレクトボックスを再構築する
					// セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
					this.dateTimeSelect.rebuild(data);
				}));
			})));
			this.initSelectBox();

            // グラフエリアを作る
            this.chart = new RainHistoryChart();
            this.controlContent.on('show', lang.hitch(this, function() {
                this.chart.create(this.chartNode, this.legend);
            }));
        },

        initForm: function() {
            // 地域セレクターの初期化(流域はリストに表示しない)
			this.regionSelector._setRegionOnly();
			// 一覧画面から遷移した場合、選択した地域をセット
			if(Locator.getQuery().regionCd){
				this.regionSelector.set('value', Locator.getQuery().regionCd);
			}
        },

        initGrid: function() {
        	// グリッドのカラムをセットする
			this.setGridColumn();
        },

        /**
         * 観測所の住所を初期化する。
         */
		initText: function(data) {
			if(data.totalLength !== 0){
				this.regionName.innerHTML = data[0].regionName;
                this.observatoryName.innerHTML = data[0].observatoryName;
                this.observationStation.innerHTML = data[0].observatoryName + '観測所';
				this.observatoryAddress.innerHTML = data[0].observatoryAddress;
				var startTimestamp = '---';
				if (data[0].startTimestamp) {
					startTimestamp = DateUtils.format(data[0].startTimestamp, {
						date: 'yyyy年MM月dd日',
						time: 'HH時mm分'
					});
				}
				this.startTimestamp.innerHTML = startTimestamp;
			}
		},

		/**
		 * 時間モードの初期化を行う
		 */
		setTimeModeSelect: function(){
			var obsInfos = this.observationStationsSelectBox.options;
			var observatoryId = this.observationStationsSelectBox.get('value');
			var isHourObs = false;
			array.forEach(obsInfos, function(item){
				if(item.value===observatoryId){
                    isHourObs = item.isHourObs;
				}
			}, this);
            var timeMode;
			// 時間観測局の場合、正時のみ
			if (isHourObs) {
				domStyle.set(this.timeModeSelect.domNode, 'display', 'none');
				domStyle.set(this.hourMode, 'display', '');
				timeMode = 'hourly';
			} else {
				domStyle.set(this.timeModeSelect.domNode, 'display', '');
				domStyle.set(this.hourMode, 'display', 'none');
				timeMode = this.timeModeSelect.get('value');
			}
			// 時間モードセレクタ用の時間モードを返す
			return timeMode;
		},

		// セレクトボックスの初期化を行う
		initSelectBox: function(){
			// 観測局セレクトボックスの初期化
			// デフォルトでは県全域の観測局を表示
			var promise =
				Requester.get('/api/observationStation/selectBox/?dataKind='+ DataKind.RAINFALL +'&regionCd=');
			Loader.wait(promise).then(lang.hitch(this, function(data) {
				this.observationStationsSelectBox.options = data;
				var observatoryId = Locator.getQuery().observatoryId;
				this.observationStationsSelectBox.set('value', observatoryId);
				// 時間モードの初期化
				var timeModeQuery = Locator.getQuery().timeMode;
				if(timeModeQuery === '60'){
					this.timeModeSelect.set('value',timeModeQuery);
				}
				// 時間モードをセット
				this.setTimeModeSelect();
				}));
		},

		// 観測局セレクトボックスの更新を行う
		updateSelectBox: function(value){
			var query = '';
			if (value.length > 0) {
				if (value.length === 6 || value.length === 0) {
					// 地域選択の場合
					query = 'regionCd=' + value;
				} else {
					// 流域選択の場合
					query = 'riverBasinCd=' + value;
				}
			}
			// 選択された地域内の観測局一覧を取得する
			var promise =
				Requester.get('/api/observationStation/selectBox?dataKind='+ DataKind.RAINFALL +'&' + query);
			Loader.wait(promise).then(lang.hitch(this, function(data) {
				// セット中の観測局IDを退避
				var observatoryId = this.observationStationsSelectBox.get('value') ||
									Locator.getQuery().observatoryId;
				// セレクトボックスにオプションを設定する
				this.observationStationsSelectBox.set('options', data).reset();
				// もともとセットされていた観測局が選択肢にあるならセット
				array.forEach(data, function(item){
					if (item.value===observatoryId) {
						this.observationStationsSelectBox.set('value', observatoryId);
					}
				}, this);
				// 時間モードをセット
				if (this.changeFlg) {
					this.setTimeModeSelect();
				}
			}));
		},

		/**
		 * Formの値をチェックし、表の更新を行う。
		 */
		onSubmit: function() {
			try {
				if (this.form.isValid()) {
					// 入力値が正常ならグリッドの検索条件を更新
					this.updateGridQuery();
				}
			} catch (e) {
				console.error(e);
			} finally {
				return false;
			}
		},

		/**
		 * 表の更新を行う。
		 */
		updateGridQuery: function() {
				var timeMode = '10min';
				// データを取得する
				this.store = new IdisRest({
					idProperty: 'rainId',
					target: '/api/rain/' + timeMode +'/detail'
				});
				var filter = new this.store.Filter();
				// 時間モードをフィルターにセット
				filter = filter.eq('timeMode', this.mode);
				// 観測日時をフィルターにセット
				var date = new Date(this.lastRequestDateTime).getTime();
				filter = filter.eq('date', date);
				// [観測局]のセット
				var value = this.form.get('value');
				if (value.observatoryId) {
					filter = filter.eq('observatoryId', value.observatoryId);
				}
				var collection = this.store.filter(filter);
				this.grid.set('collection', collection);
				collection.fetch().then(lang.hitch(this, function(data) {
					if(data.totalLength !== 0){
						this.initText(data);
						this.initChart(data);
					}
				}));
		},

		/**
		 * 予測雨量データを取得する。
		 */
		getFeatureRainfallData: function(data) {
			var deferred = new Deferred();
			// 観測局ID取得
			var observatoryId = data[0].observatoryId;
			// 予測雨量データ格納用オブジェクト
			var futureJson = '/data/rainfall/detail/' + 'future_' + observatoryId + '.json';
			Requester.get(futureJson).then(lang.hitch(this, function(featureRainfall) {
				// 検索中の時間
				var targetData = data[0];
				array.forEach(featureRainfall.items, function(featureRainfall){
					if (DateUtils.format(targetData.dataTimestamp, {date:'yyyy-MM-dd'}) ===
						featureRainfall.dataTimestamp){
							deferred.resolve(featureRainfall);
					}
				});
				deferred.reject('指定の観測日時に対する予測雨量が見つかりません。');
			}), function(){
				deferred.reject('指定の観測局の予測雨量が見つかりません。 : 観測局ID ＝ ' + observatoryId);
			});
            return deferred.promise;
		},

		initChart: function(data) {
			// 予測雨量データを追加
			this.getFeatureRainfallData(data).then(lang.hitch(this, function(future) {
				this.chart.create(this.chartNode, this.legend,
					{mode:this.mode, data:data, futureData: future});
			}), lang.hitch(this, function(errMsg){
				// 予測雨量の取得に失敗しても、メッセージだけだして処理を続行する。
				this.chart.create(this.chartNode, this.legend,
					{mode:this.mode, data:data, futureData: null});
			}));
		},

		/**
		 * [地域]を変更し、観測所の更新を行う。
		 */
		onRegionChange: function(evt){
			this.updateSelectBox(evt);
		},

		/**
		 * [観測局]を変更し、表の更新を行う。
		 */
		onObservationStationChange: function(){
			if(this.changeFlg !== 0){
				// 時間モードをセット&時間モードによって、グリッド更新
				this.onChangeModeSelect(this.setTimeModeSelect());
			}
			this.changeFlg++;
		},


		// 観測日時の初期時刻を表示するためのメソッド
		initDateTime: function(){
			var timeMode = '10min';
			// 最新の観測情報を取得
			Requester.get('/api/rain/'+ timeMode + '/latestDataTime').then(lang.hitch(this, function(data){
				// 観測日時セレクトボックスを再構築する
				// セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
				this.dateTimeSelect.rebuild(data);
				this.defaultDate = data;
			}));
		},

		/**
		* 時間モードが変更された際に呼ばれる
		*/
		onChangeModeSelect: function(value) {
			// 正時の場合、1時間モードとする
			this.mode = value === 'hourly' ? '60' : value;
			// 観測時間選択の表示を切り替える
			this.dateTimeSelect.changeMode(value);

			// 時間モードによってリクエスト先のAPIを切り替える
			var timeMode = '10min';
			this.store.target = '/api/rain/' + timeMode + '/detail';
			// 時間セレクタの変更
			if(value === '60'){
                timeMode = 'hourly';
            }
            this.dateTimeSelect.changeMode(timeMode);
            Locator.replaceState(APP_QUERY.MODE, timeMode);
			// サーバーにリクエストする
			var filter = this.store.Filter();
			// 時間モードをフィルターにセット
			filter = filter.eq('timeMode', this.mode);
			// 観測日時をフィルターにセット
			var date = new Date(this.lastRequestDateTime).getTime();
			filter = filter.eq('date', date);
			//観測局をフィルターにセット
			var observatoryId = this.form.get('value').observatoryId;
			filter = filter.eq('observatoryId', observatoryId);

			var collection = this.store.filter(filter);
			this.grid.set('collection', collection);
			// チャートの再生成を行う
			collection.fetch().then(lang.hitch(this, function(data) {
				if(data.totalLength !== 0){
					this.initText(data);
					this.initChart(data);
				}
			}));
		},

		// 一つ前の観測局を取得する
		onPreviousButtonClick: function() {
			var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最初の観測局の場合はなにもしない
			if(value !== options[0].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i - 1].value);
					}
				}));
			}
		},

		// 一つ後の観測局を取得する
		onNextButtonClick: function() {
            var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最後の観測局の場合はなにもしない
			if(value !== options[options.length - 1].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i + 1].value);
					}
				}));
			}
		},

		/**
		* 10分と正時でグリッドのカラム表示・非表示項目を切り替える
		*/
		setGridColumn: function() {
			if (this.mode === '60') {
				// 正時の場合、
				// 「時間雨量」カラムを表示、「10分雨量」「60分雨量」を非表示
				this.grid.styleColumn('0-2', 'display: table-cell;');
				this.grid.styleColumn('0-3', 'display: none;');
				this.grid.styleColumn('0-4', 'display: none;');
			} else {
				// 10分の場合、
				// 「時間雨量」カラムを非表示、「10分雨量」「60分雨量」を表示
				this.grid.styleColumn('0-2', 'display: none;');
				this.grid.styleColumn('0-3', 'display: table-cell;');
				this.grid.styleColumn('0-4', 'display: table-cell;');
			}
		},

		onRainfallObservationLinkClick: function(evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			Router.moveTo('observation/rainfall');
		},

        /**
         * 時間モードによって、凡例を切り替える
         */
        setLegend: function(){
            if (this.mode === '60') {
                // 正時の場合、
                // 「時間雨量」の凡例を表示
                // 「10分雨量」の凡例を非表示
                domStyle.set(this.hourlyLegend, 'display', '');
            } else {
                // 10分の場合、
                // 「10分雨量」の凡例を表示
                // 「時間雨量」の凡例を非表示
                domStyle.set(this.hourlyLegend, 'display', 'none');
            }
        }
	});
});

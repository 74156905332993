/**
 * <危機管理型水位計情報詳細>
 *
 * @module app/river/grid/CrisisManageRiverLevelObsDetailPageGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    './RiverLevelCommonColumn',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, IdisGrid, RiverLevelCommonColumn) {
    /**
     * 危機管理型水位計情報詳細画面用Grid
     *
     * @class CrisisManageRiverLevelObsDetailPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/river/CrisisManageRiverLevelObsDetailPageGrid~CrisisManageRiverLevelObsDetailPageGrid# */
		{
		columns: [
					{field : 'days',
					label : '月日',
					sortable : false
					},
					{field : 'time',
						label : '時刻',
						sortable : false
					},
					RiverLevelCommonColumn.excess,
					RiverLevelCommonColumn.riverLevel
					]
		});
});

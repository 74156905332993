/**
 * 係詳細ダイアログ用モジュール。　
 * @module app/organization/UnitDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UnitDetailDialog.html',
    './_UnitDialogBase',
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    '../config',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, domStyle, template, _UnitDialogBase,
    Loader, Requester, USER_TYPE, UserInfo, config, Region) {
    /**
     * 係詳細ダイアログ
     * @class UnitDetailDialog
     * @extends module:app/organization/_UnitDialogBase~_UnitDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _UnitDialogBase,
    /** @lends module:app/view/page/UnitRegisterDialog~UnitRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,
        // バージョン
        _version: null,

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームを初期化する。
         */
        initForm: function(object) {
            this._unitCd = object.unitCd;
            var self = this;
            var page = this.form;

            // 振興局ユーザの場合のみ、自治体ダイアログのクリアボタンを非表示にする
            if(UserInfo.getUserType() === USER_TYPE.REGION){
                domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
            }
            // 市町村ユーザの場合のみ、自治体ダイアログ用選択ボタンを非活性にする
            if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                    UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                this.municipalityCd.set('noAllButton', true);
            }

            // 自治体名に登録されている振興局、または道を表示する
            Loader.wait(Requester.get('/api/organization/units/' + this._unitCd))
            .then(function (data) {
                if(!data.municipalityCd){
                    data.municipalityCd = data.regionCd;
                } else if(data.municipalityCd && data.municipalityCd === config.municInfo.prefMunicCd){
                    data.municipalityCd = config.municInfo.prefCd;
                }
                var item = data;
                page.set('value', item);
                self.organizationSelector.set('value', 'S' + data.sectCd);
                self._version = data.version;
            });
            this.organizationSelector.restoreRefresh();
        },

        /**
         * 係を削除する。
         */
        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            value.unitCd = this._unitCd;
            value.version = this._version;
            this.emit('remove', {value: value});
        },

        /**
         * 係を更新する。
         */
        onSubmit: function() {
            try {
                console.debug('係詳細ダイアログの[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.unitCd = this._unitCd;
                value.version = this._version;

                this.emit('update', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();
            this.initForm(object);
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
            this.setButtonStatus();
            this.isRegion(municipalityCd.value).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    this.organizationSelector.setRegionCd(municipalityCd.value);
                    this.organizationSelector.setMunicipalityCd(null);
                } else if(municipalityCd.value && municipalityCd.value !== config.municInfo.prefCd){
                    this.organizationSelector.setMunicipalityCd(municipalityCd.value);
                    this.organizationSelectorForUnit.setRegionCd(null);
                } else if (municipalityCd.value && municipalityCd.value === config.municInfo.prefCd) {
                    this.organizationSelector.setMunicipalityCd(config.municInfo.prefMunicCd);
                    this.organizationSelector.setRegionCd(null);
                }
            }));
        },

        // 組織セレクタの活性・非活性判定
        setButtonStatus: function () {
            if (this.municipalityCd.value === '') {
                this.organizationSelector.set('disabled', true);
                this.organizationSelector.set('value', '');
            } else {
                this.organizationSelector.set('disabled', false);
            }
        }
    });
});

/**
 * 流域平均雨量情報の一覧ページ
 * @module app/riverBasin/RiverBasinRainfallPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/RiverBasinRainfallPage.html',
    'dojo/topic',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    //
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/riverBasin/grid/RainfallPageGrid',
    'app/view/page/StatisticsDataDialog'
], function(module, declare, lang, domClass, domStyle, template, topic,
    Router, Requester, IdisRest, _PageBase,
    DateTimeSelect, DataKind) {

    var _apiUrlPrefix = '/api/riverBasinRainfall';

    /**
     * 流域平均雨量情報一覧画面
     * @class RiverBasinRainfallPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:aapp/riverBasin/RiverBasinRainfallPage~RiverBasinRainfallPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--riverBasinRainfall',

        // グリッド用ストア
        store: null,

        /**
         * 時間モード
         * {string} '10': 10分 '60': 正時
         */
        mode: '10',

        /**
         * 前回のデータ取得日時
         * {string} 'yyyy-MM-dd HH:mm'
         */
        lastRequestDateTime: '',

        constructor: function() {
            this.inherited(arguments);
            this.store = new IdisRest({
                idProperty: 'riverBasinRainfallId',
                target: _apiUrlPrefix + '/'
            });
        },

        buildRendering: function() {
            this.inherited(arguments);

            this.setLatestDatetime();
            this.initGrid();

            // 凡例を切り替える
            this.setLegend();
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);

            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, this.onChangeDateTime)));

            // 最新ボタンのクリックが押されたら
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, this.onClickLatest)));
        },

        /**
         * gridの初期化
         *
         * 詳細ボタンクリック時の動作を規定している
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            // グリッドのカラムをセットする
            this.setGridColumn();
        },

        /**
         * 時刻が変更された時の動作
         * @param {*} datetime
         */
        onChangeDateTime: function(datetime) {
            // 1回の操作で日付と時間が同時に変更される場合があり, このとき同じ日時で2回続けて変更イベントが発行される
            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため, 前回の日時を保持し、異なる場合のみデータを取得するようにする
            if (this.lastRequestDateTime === (datetime.date + ' ' + datetime.time)) {
                return;
            }

            // IEでDate生成時にエラーとなるのを回避
            var dateStr = datetime.date.replace(/-/g, '/') + ' ' + datetime.time;
            var date = new Date(dateStr).getTime();

            // 観測日時をフィルターにセット
            var filter = new this.store.Filter();
            filter = filter.eq('date', date);
            filter = filter.eq('timeMode', this.mode);

            var collection = this.store.filter(filter);
            this.grid.set('collection',collection);

            this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
        },

        /**
         * ロード時に最新時刻を取得して時刻選択パーツにセット
         * @param {*} value
         */
        setLatestDatetime: function() {
            // 日付セレクトボックスを生成・設置する
            // 初期表示は、時間モード10分で最新の時間データ
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect = new DateTimeSelect({
                    to: date
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
                this.borderContainer.resize();

            }), lang.hitch(this, function() {
                this.dateTimeSelect = new DateTimeSelect();
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
            }));
        },

        /**
         * 最新ボタンが押された時の動作
         */
        onClickLatest: function() {
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect.rebuild(date);

            }), lang.hitch(this, function() {
                this.dateTimeSelect.rebuild(new Date());
            }));
        },

        /**
         * 時間モードが変更された際に呼ばれる
         */
        onChangeModeSelect: function(value) {
            this.mode = value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);

            var timeModeStr = null;
            if(value === '10'){
                timeModeStr = '[10分モード]';
            }
            if(value === '60'){
                timeModeStr = '[正時モード]';
            }

            // 新しい[時間モード]で表の更新を行う。
            var filter = new this.store.Filter();

            // 時間モードをフィルターにセット
            filter = filter.eq('timeMode', this.mode);

            // 観測日時をフィルターにセット
            var date = new Date(this.lastRequestDateTime).getTime();
            filter = filter.eq('date', date);

            // サーバーにリクエストする
            var collection = this.store.filter(filter);
            this.grid.set('collection',collection);
            
            // グリッドのカラムをセットする
            this.setGridColumn();
            // 凡例を切り替える
            this.setLegend();
        },

		
		/**
		* 10分と正時でグリッドのカラム表示・非表示項目を切り替える
		*/
		setGridColumn: function() {
			if (this.mode === '60') {
				// 正時の場合、
                // 「時間雨量」カラムを表示、「10分雨量」「60分雨量」を非表示
                domClass.remove(this.grid.domNode, 'is-10minMode');
                domClass.add(this.grid.domNode, 'is-60minMode');
			} else {
				// 10分の場合、
				// 「時間雨量」カラムを非表示、「10分雨量」「60分雨量」を表示
                domClass.add(this.grid.domNode, 'is-10minMode');
                domClass.remove(this.grid.domNode, 'is-60minMode');
			}
		},

        /**
         * グリッドの詳細ボタンを押した時の動作
         * @param {*} グリッド1行分の観測データ
         */
        onDetailButtonClick: function(object) {
            // 現在の表示時刻を引き継いで
            var date = new Date(this.lastRequestDateTime).getTime();

            // 詳細画面へ遷移
            Router.moveTo('observation/basin/detail', {
                riverBasinId : object.riverBasinId,
                date : date,
                timeMode : this.mode
            });
        },

        /**
         * 時間モードによって、凡例を切り替える
         */
        setLegend: function(){
            if (this.mode === '60') {
                // 正時の場合、
                // 「時間雨量」の凡例を表示
                // 「10分雨量」の凡例を非表示
                domStyle.set(this.hourlyLegend, 'display', '');
                domStyle.set(this.minLegend, 'display', 'none');
            } else {
                // 10分の場合、
                // 「10分雨量」の凡例を表示
                // 「時間雨量」の凡例を非表示
                domStyle.set(this.minLegend, 'display', '');
                domStyle.set(this.hourlyLegend, 'display', 'none');
            }
        },

        /**
         * 観測情報ダウンロードボタンを押した時の動作
         */
        onWorksheetDownloadButtonClick: function() {
            // ダイアログの最初の子要素が登録画面
            var dialog = this.statisticsDataDialog.getChildren()[0];
            dialog.setInitKind(DataKind.RIVER_BASIN);
            this.statisticsDataDialog.set({title: '統計情報'});
            this.statisticsDataDialog.show();
        }
    });
});
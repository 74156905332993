/**
 * 初回に全データを取得し、以降はキャッシュを利用するdojo/store形式のStore。
 * @module idis/store/CacheTreeRest
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    '../error/InvalidArgumentException',
    './CacheRest'
], function(module, array, declare, lang, InvalidArgumentException, CacheRest) {
    /**
     * ツリー用。最初に全要素を取得してキャッシュし、以降はその内容を返す。
     *
     * 問い合わせ先APIは識別子、親要素の識別子、表示文字列を持つオブジェクトの配列をitemプロパティーに格納したJSONを返すこと。
     * 返り値は複数の最上位要素を持つことを前提とし、それら最上位要素はローカル生成した仮想ルート要素にぶら下げる。
     * 要素が親として指定した要素が存在しない場合や、APIが返した値が仮想ルートと同一の識別子を持つ場合はエラーとなる。
     *
     * 識別子と親要素の識別子として扱うプロパティー名はオプション指定可能。
     * 表示文字列として扱うプロパティー名はdijit/tree/model実装クラス側で設定する。
     *
     * @class CacheTreeRest
     * @extends module:idis/store/CacheRest
     * @param {Object} kwArgs
     * @param {string} kwArgs.target ツリー要素を返すAPIのURL
     * @param {Object} [kwArgs.targetQuery] APIへのGETリクエストに含めるクエリー・パラメーター
     * @param {string} [kwArgs.parentProperty='parentId'] 要素の親要素の識別子として扱うプロパティー
     * @param {string} [kwArgs.rootId='$ROOT$'] 仮想ルート要素の識別子として扱うプロパティー
     * @param {string} [kwArgs.itemMapper=function] クエリ結果を下の形にマッピング
     * @example <caption>APIの返却値の例</caption>
     * {
     *     items: [
     *         {id: '001', name: '最上位要素1'},
     *         {id: '002', name: '子要素1', parentId: '001'},
     *         ...
     *     ]
     * }
     */
    return declare(CacheRest, /** @lends module:idis/store/CacheTreeRest~CacheTreeRest# */ {
        /**
         * 要素の親要素の識別子として扱うプロパティー
         */
        parentProperty: 'parentId',

        /**
         * 仮想ルート要素として扱う識別子。
         * 実際に取得した要素が同一の識別子を持っていた場合はエラーとなる。
         * @type {string}
         */
        rootId: '$ROOT$',

        /**
         * 要素の親要素の識別子を返す。
         * @param {Object} item 要素
         */
        getParentIdentity: function(item) {
            return item[this.parentProperty];
        },

        /**
         * 要素をツリー用に加工したコピーを返す。
         * 親要素を持たない要素をルート要素直下の要素として設定する。
         * @param {Object} item ツリー要素
         * @returns {Object} 加工済み要素
         */
        _alignItem: function(item) {
            // 元データを破壊しないようコピー
            var copied = lang.mixin(null, item);
            var parentId = copied[this.parentProperty];
            if (parentId === this.rootId) {
                // 親識別子が仮想ルート要素と一致
                var message = module.id + '#_alignItem: 元データに仮想ルート要素と同一の識別子を持つ要素が存在します。';
                throw new InvalidArgumentException(message, copied);
            } else if (parentId === void 0 || parentId === null) {
                // 親識別子として仮想ルートを設定
                copied[this.parentProperty] = this.rootId;
            }
            return copied;
        },

        /**
         * 受け取ったデータからキャッシュを生成する。
         */
        _createCache: function(orgData) {
            // 加工関数が指定されている場合は各要素を加工
            if (this.itemMapper) {
                orgData = array.map(orgData.items || orgData, this.itemMapper, this);
            }
            // 問い合わせ結果の各要素をツリー用に加工
            var data = array.map(orgData.items || orgData, this._alignItem, this);
            // 仮想ルート要素を生成して先頭に格納
            var root = {};
            root[this.idProperty] = this.rootId;
            data.unshift(root);
            // 加工したデータを格納したMemoryをキャッシュとして返す
            return this.inherited(arguments, [data]);
        }
    });
});


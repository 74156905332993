/**
 * 「災害対応共有サイト（各所属本部）」画面用のモジュール
 * @module app/disasterInfoShare/DisasterInfoShareDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'idis/service/Requester',
    'dojo/text!./templates/DisasterInfoShareDetailPage.html',
    'app/model/RelatedLink',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/Loader',
    'dojo/dom-style',
    'idis/consts/ACL',
    'app/config',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    './DisasterInfoShareDetailGrid',
    './DisasterInfoShareNewsGrid',
    './DisasterInfoShareEmergencyNewsGrid',
    './DisInfoShareDetailDialog',
    './DisInfoShareEditDialog',
    './DisInfoShareRegisterDialog',
    'app/model/DisInfoShareDestination'
], function(module, declare, lang, array, Requester, template, RelatedLink, IdisRest, DialogChain, UserInfo, DisasterInfo, Locator, Router, Loader, domStyle, ACL, config, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // 基本クラス
        baseClass: 'idis-Page idis-Page--disasterInfoShare',

        // テンプレート文字列
        templateString: template,

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * データ更新用オブジェクト
         * @type {module:dstore/Store}
         */
        updateStore: null,

        /**
         * データ削除用オブジェクト
         * @type {module:dstore/Store}
         */
        deleteStore: null,

        /**
         * 災害ID
         * @private
         */
        _disasterId: null,

        /**
         * 本部ID
         * @private
         */
        _deptId: null,

        /**
         * @type {Object}
         */
        timer: null,

        // 危機管理部の本部ID
        CRISIS_MANAGEMENT_DEPT_ID : config.dept.crisisManagementDeptCd,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses/register'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            // 本部IDを定義
            this._deptId = Locator.getQuery().deptId;
        },

        // DOMノードを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.initRelatedLink();

            var self = this;
            var promise = Requester.get('/api/organizations/depts/dept4DisInfoShare').then(function(data) {
                // 画面タイトルに本部名称を設定
                var titleDeptName;
                array.forEach(data.items, (lang.hitch(self, function (item) {
                    if (item.id === self._deptId) {
                        titleDeptName = item.name;
                    }
                })));
                self.titleDeptName.innerHTML = titleDeptName;
            }, function(error) {
                console.error(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            this.initNewsGrid();
            this.initEmergencyNewsGrid();
            this.initRegisterDialog();
            this.initDetailPage();
            this.setButtonStatus();
            //詳細ダイアログの右上の閉じるボタンを非表示にする
            domStyle.set(this.detailDialog.closeButtonNode, 'display', 'none');
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function() {
            this.inherited(arguments);
            clearInterval(this.timer);
        },

        // ユーザー権限が「閲覧ユーザ」の場合は新規登録ボタンを非活性に
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.registerButton.setDisabled(true);
            }
            var deptCd = UserInfo.getOrganization().deptCd;
            if (deptCd !== this._deptId) {
                this.registerButton.setDisabled(true);
            }
        },

        /**
         * 共有情報グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('editButtonClick', lang.hitch(this, function(evt) {
                this.showEditDialog(evt.item);
            }));

            // 編集ダイアログを開く
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item);
            }));

            this.grid.on('fileDownloadButtonClick', lang.hitch(this, function(evt) {
                this.onFileDownload(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * 共有情報グリットを更新する。
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            filter = filter.eq('senderOrganizationCd', this._deptId);

            var collection = this.store.filter(filter);

            this.grid.set('collection', collection);

            this.grid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateGridQuery();
            }),600*1000);
        },

        /**
         * 緊急情報グリッドを初期化する。
         */
        initEmergencyNewsGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名

            // 編集ダイアログを開く
            this.emergencyNewsGrid.on('a.title-link:click', lang.hitch(this, function(evt) {
                var messageId = evt.target.id.split('--')[1];
                this.showNewsDetailPage(messageId);
            }));

            this.updateEmergencyNewsGridQuery();
        },

        /**
         * 緊急情報グリットを更新する。
         */
        updateEmergencyNewsGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            filter = filter.eq('emergencyNewsFlg', '1');

            var collection = this.store.filter(filter);

            this.emergencyNewsGrid.set('collection', collection);

            this.emergencyNewsGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateEmergencyNewsGridQuery();
            }),600*1000);
        },

        /**
         * 新着情報グリッドを初期化する。
         */
        initNewsGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名

            // 編集ダイアログを開く
            this.newsGrid.on('a.title-link:click', lang.hitch(this, function(evt) {
                var messageId = evt.target.id.split('--')[1];
                this.showNewsDetailPage(messageId);
            }));

            this.newsGrid.on('fileDownloadButtonClick', lang.hitch(this, function(evt) {
                this.onFileDownload(evt.item);
            }));

            this.updateNewsGridQuery();
        },

        /**
         * 新着情報グリットを更新する。
         */
        updateNewsGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            filter = filter.eq('senderOrganizationCd', this._deptId);

            filter = filter.eq('newInformationFlg', '1');

            var collection = this.store.filter(filter);

            this.newsGrid.set('collection', collection);

            this.newsGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateNewsGridQuery();
            }),600*1000);
        },

        /**
         * 詳細ダイアログを初期化する
         */
        initDetailPage: function() {
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];

            // 登録ダイアログのsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;

                    // 登録処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(lang.hitch(this, function(chain) {
                    // OKが押された場合

                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'messageId',
                        target: '/api/bbses/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                }));
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(lang.hitch(this, function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.messageId)).then(function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                }));
            }));

            page.on('cancel', lang.hitch(this, function() {
                this.updateGridQuery();
                this.updateNewsGridQuery();
                this.updateEmergencyNewsGridQuery();
            }));
        },

        /**
         * 共有情報詳細ダイアログを開く
         */
        showDetailPage: function(item) {
            // ストアからデータを取得し、詳細ダイアログを表示する
            var fil = this.storeMessage = new IdisRest({
                target: '/api/bbses/message/' + item.messageId
            });

            fil.fetch().then(lang.hitch(this, function(formItem){
                this.innerDetailDialog.setContent(formItem);
            }));

            this.innerDetailDialog.updateDialog(item);
            this.detailDialog.show();

            // 情報の発信元が、自身の所属している本部と一致する場合、編集ボタンを表示する
            var deptCd = UserInfo.getOrganization().deptCd;
            if (item.senderOrganizationCd === deptCd) {
                this.innerDetailDialog.showEditButton();
            } else {
                this.innerDetailDialog.hideEditButton();
            }
        },

        /**
         * 新着情報詳細ダイアログを開く
         */
        showNewsDetailPage: function(messageId) {
            // ストアからデータを取得し、詳細ダイアログを表示する
            var fil = this.storeMessage = new IdisRest({
                target: '/api/bbses/message/' + messageId
            });

            fil.fetch().then(lang.hitch(this, function(formItem){
                this.innerDetailDialog.setContent(formItem);
            }));

            this.detailDialog.show();
            // 新着・緊急情報の詳細ダイアログの場合、編集リンクを非表示とする
            this.innerDetailDialog.hideEditButton();
        },

        /**
         * 編集ダイアログを開く
         */
        showEditDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];

            // 画面上のフォームをリセット
            page.form.reset();
            page.clearPreview();

            // 添付ファイルをプレビューに反映
            this.innerDetailDialog.displayAttachFile(item.attachFile);

            // 内容をフォームに反映
            var value = lang.mixin(null, item);
            delete value.attachFile;
            page.form.set('value', value);

            // 詳細ダイアログを表示
            this.innerDetailDialog.initDialog(item);
            this.detailDialog.show();
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];
            // 登録ダイアログのsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;
                    // 登録処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery();
                            this.updateNewsGridQuery();
                            this.updateEmergencyNewsGridQuery();
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            this.innerRegisterDialog.clearPreview();
            this.innerRegisterDialog.initDialog();
            this.registerDialog.show();

            // 危機管理部の場合のみ、緊急情報の公開チェックボックスと通知種別プルダウンを表示する
            if (this._deptId !== this.CRISIS_MANAGEMENT_DEPT_ID) {
                this.innerRegisterDialog.hideEmergencyNewsSetting();
            }
        },

        /**
         * 市災害対策本部画面へ遷移する。
         */
        onReturnLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 市災害対策本部画面へ遷移
            Router.moveTo('disasterInfoShareList');
        },

        /**
         * ファイル共有サイトへ新規タブで遷移する。
         */
        onFileShareClick: function() {
            window.open(config.fileShare.url, '_blank');
        },

        initRelatedLink: function() {
            var relatedLinkDom = [];
            for (var i=0; i < RelatedLink.length; i++) {
                relatedLinkDom.push('<li class="four-column-list"><a href="' + RelatedLink[i].url + '">' + RelatedLink[i].name + '</a></li>');
            }
            this.relatedLink.innerHTML = relatedLinkDom.join('');
        }
    });
});

/**
 * 体制・報告 詳細/更新画面用モジュール。
 * @module app/disasterprevention/view/DisasterPreventionDetailPage
 */
define([
    'module',
    'app/disaster/model/DisasterType',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionDetailPage.html',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './_DisasterPreventionDialogPageBase',
    './model/DistributionType',
    './model/HqSetFlg',
    './model/HqType',
    'app/config',
    'app/common/consts/FunctionCd',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    './DisasterPreventionDeleteDialog',
    'app/view/form/PrefRegionMunicipalitySelector'
], function(module, DisasterType, declare, lang, Deferred, domStyle, template, Locator,
    UserInfo, USER_TYPE, Requester, DialogChain, InfoDialog, Loader,
    _PageBase, DistributionType, HqSetFlg, HqType, config, FunctionCd) {
    /**
     * 災害情報登録画面。
     * @class DisasterPreventionDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionDetailPage~DisasterPreventionDetailPage */ {
        // テンプレート文字列
        templateString: template,

        // 更新・訂正どちらのボタンからsubmitされたかを判断する
        _updateFlg: null,

        // 取消時、配信対象フラグ
        _revertDistributionType: null,

        // 配信状況
        _isUnsentLalert: null,

        /**
         * 体制の機能コード
         */
        functionCd : 'F05002',

        startup: function () {
            this.inherited(arguments);
            // 体制状況にモデルをセット
            this._disasterType = this.disasterTypeSelect.value;
            this._lastDetailItem = true; // dummy値（体制状況リストに解除体制を含める）
        },

        buildRendering: function() {
            this.inherited(arguments);
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            this.own(this.deleteDialog);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            // 災害種別の変更時、体制を初期化する
            this.own(this.disasterTypeSelect.on('change', lang.hitch(this, function(value) {
                this.onChangeDisasterType(value);
            })));
            // 体制状況の変更時、本部設置情報欄の表示有無を切り替える
            this.own(this.status.on('change', lang.hitch(this, function(status) {
                if (this._prevStatusStore) {
                    this.onChangeHqStatus(this._prevStatusStore.get(status));
                }
            })));
            // 更新・訂正どちらのボタンからsubmitされたかを判断する
            this.own(this.submitButton.on('click', lang.hitch(this, function() {
                // 更新ボタン → insertされる
                this._updateFlg = 0;
                this.onSubmit();
            })));
            this.own(this.updateButton.on('click', lang.hitch(this, function() {
                // 訂正ボタン → updateされる
                this._updateFlg = 1;
                this.onSubmit();
            })));
            // 取消ボタン
            this.own(this.revertButton.on('click', lang.hitch(this, function() {
                this.revertReport();
            })));
        },

        /**
         * フォームを初期化する。
         */
        initForm: function(obj) {
            this._disasterPrevId = obj.disasterPrevId;
            this._revertDistributionType = obj.revertDistributionType;
            this._isUnsentLalert = obj.unsentLalert;

            // 更新ボタン・訂正ボタン・取消ボタンの権限を反映
            domStyle.set(this.updateButton.domNode, 'display', 'none');
            domStyle.set(this.submitButton.domNode, 'display', 'none');
            domStyle.set(this.revertButton.domNode, 'display', 'none');
            this.municipalitySelector.set('value', obj.municipalityCd);
            // 詳細画面では自治体変更不可
            this.municipalitySelector.set('noAllButton',true);
            // 権限に応じてボタンを表示切替
            // 自分の自治体 or 代理登録権限ありの場合のみ、操作ボタン表示
            if (UserInfo.hasWriteAuthz(FunctionCd.DISASTERPREVENTION) &&
                (UserInfo.getUserType() === USER_TYPE.REGION && UserInfo.getRegionCd() === obj.municipalityCd ||
                UserInfo.getMunicipalityCd() === obj.municipalityCd) ||
                UserInfo.hasProxyWriteAuthz(FunctionCd.DISASTERPREVENTION)) {
                    domStyle.set(this.updateButton.domNode, 'display', '');
                    domStyle.set(this.submitButton.domNode, 'display', '');
                    domStyle.set(this.revertButton.domNode, 'display', '');
            }
            // 過去報の場合は、権限に関わらず更新ボタンは不可
            if(obj.activeFlg === 0){
                domStyle.set(this.submitButton.domNode, 'display', 'none');
            }

            this.form.set('value', obj);
            // 災害種別
            this.disasterTypeSelect.set('value', obj.disasterType);
            // 報告日時
            this.reportTimestamp.set('value', obj.reportTimestampMS);
            // 体制状況にモデルをセット
            this.setStatus(obj.status);
            this._seqNum = obj.seqNum;
            // 体制に応じて本部設置情報の表示を切替える
            this._setHqInfNode(obj);
            if (obj.hqSetFlg) {
                switch (obj.hqSetFlg) {
                    case '0':
                        this.hqSetFlgNot.set('value', true);
                        break;
                    case '1':
                        this.hqSetFlgSet.set('value', true);
                        break;
                    case '2':
                        this.hqSetFlgAbolished.set('value', true);
                        break;
                }
                this._setHqSetInf(obj.hqSetFlg);
            }

            // 設置日時
            this.hqSetTimestamp.set('value', obj.hqSetTimestampMS);
            // 廃止日時
            this.hqAbolishedTimestamp.set('value', obj.hqAbolishedTimestampMS);
            // 組織
            this.organization.set('value', obj.orgCd);
            // 設置場所
            this.hqSetPlace.set('value', obj.hqSetPlace);

            // 報告番号
            this.seqNum.innerHTML = '第' + this._seqNum + '報';
        },

        // 更新・訂正・取消ボタンいずれかの押下時によばれる
        onSubmit: function() {
            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }
            // 登録・更新共通のバリデーションを実施
            if(!this.validate()) {
                return false;
            }
            // 更新固有のバリデーションを実施
            this._validate().then(lang.hitch(this, this.putRequest));
        },

        /**
         * 訂正・取消処理開始
         */
        putRequest: function() {
            try {
                var value = this.form.get('value');
                // 非表示項目は入力値を削除
                if(domStyle.get(this.hqInfNode, 'display')==='none'){
                    // delete value.hqSetFlg;
                    delete value.hqSetTimestamp;
                    delete value.hqAbolishedTimestamp;
                    delete value.hqSetPlace;
                }
                if (domStyle.get(this.hqSetTimestampArea, 'display')==='none') {
                    delete value.hqSetTimestamp;
                    delete value.hqAbolishedTimestamp;
                    delete value.hqSetPlace;
                }
                if (domStyle.get(this.hqAbolishedTimestampArea, 'display')==='none') {
                    delete value.hqAbolishedTimestamp;
                }
                value.disasterPrevId = this._disasterPrevId;
                value.seqNum = this._seqNum;

                // 報告者所属組織
                value.organization = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                    UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

                // 訂正ボタンフラグセット
                value.updateFlg = this._updateFlg;
                // 配信状況セット
                value.isUnsentLalert = this._isUnsentLalert;
                //体制状況から自動で入力する値をセット
                var statusCd = this.status.value;
                value.statusName = this._prevStatusStore.get(statusCd).label;
                // 本部設置種別
                // 本部廃止の場合は、本部の種別をセット
                // 本部未設置体制の解除の場合は、'99'をセット
                var statusHqType = this._prevStatusStore.get(statusCd).hqType;
                value.hqType = statusHqType !== HqType.RELEASE ? statusHqType :
                    ([HqType.RESPONSE_HQ, HqType.ALERT_HQ].indexOf(this._lastDetailItem.hqType) && this._lastDetailItem.hqSetFlg !== HqSetFlg.NOT_YET ?
                        this._lastDetailItem.hqType : statusHqType);
                // 本部名
                var hqName = this._prevStatusStore.get(statusCd).hqName;
                // 解除の場合の本部名
                if(value.hqSetFlg!==HqSetFlg.NOT_YET){
                    if(statusCd==='999999'){
                        // 体制の解除->体制名＋解除
                        hqName = this._lastDetailItem.hqName || this._lastDetailItem.statusName + ' 解除';
                    }
                } else if (!value.hqSetFlg) {
                    value.hqSetFlg = HqSetFlg.NOT_YET;
                }
                value.hqName = hqName;
                // 災害種別名
                value.disasterTypeName = DisasterType.get(this._disasterType).label;
                var processType = { 0: 'UPDATE', 1: 'CORRECT' }[this._updateFlg];
                Loader.wait(this.getDistributionType(processType, value)).then(lang.hitch(this, function(distributionType) {
                    // 配信種別をセット
                    this._distributionType = value.disPrevDistributionType =
                        // 訂正理由の表示のため、「更新・訂正」については区別できるようにする
                        // 更新ボタン押下→「更新」、訂正・取消ボタン押下→「訂正」
                        (distributionType === DistributionType.UPDATE && this._updateFlg === 1 ?
                        DistributionType.CORRECT : distributionType);
                    // 配信種別が「配信対象外」でなければ配信対象とする
                    this._provideFlg = value.provideFlg = (distributionType !== DistributionType.NO_DISTRIBUTE);

                    // 更新処理実行
                    this.emit('put-send', {value: value});
                }));


            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Lアラート配信種別を変数を取得する
         */
        getDistributionType: function(processType, value) {
            var deferred = new Deferred();
            // 最新報でない場合は配信の対象外
            if (this._lastDetailItem && this._lastDetailItem && this._lastDetailItem.activeFlg!==1) {
                return deferred.resolve(DistributionType.NO_DISTRIBUTE);
            }
            // 振興局は対象外
            var municipalityCd = this.municipalitySelector.get('value');
            if (municipalityCd.substring(0,3) === config.municInfo.prefRegCdGudge &&
                municipalityCd !== config.municInfo.prefMunicCd) {
                return deferred.resolve(DistributionType.NO_DISTRIBUTE);
            }

            // 詳細画面からの操作はJava側で判定する
            Requester.get('/api/disasterPrevention/distributionType/' + this._disasterPrevId, {
                query: {
                    processType: processType,
                    hqType: value ? value.hqType : null,
                    hqSetFlg: value ? value.hqSetFlg : null
                },
                handleAs: 'text'
            }).then(deferred.resolve, // 成功の場合
                lang.hitch(this, function(error) {
                    // 失敗の場合
                    console.error(error);
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                    deferred.reject();
            }));
            return deferred.promise;
        },

        /**
         * 取消ボタンがクリックされた際に呼ばれる
         */
        revertReport: function() {
            // 削除対象を選択できるのは、最新報かつ第2報以上のときとする
            if(this._lastDetailItem.activeFlg===1 && this._lastDetailItem.seqNum > 1){
                this.deleteDialog.show();
            }else{
                this.submitRevertReport();
            }
        },
        submitRevertReport: function(allDelete){
            var processType = allDelete ? 'ALL_CANCEL' : 'CANCEL';
            Loader.wait(this.getDistributionType(processType)).then(lang.hitch(this, function(distributionType) {
                // 配信種別をセット
                this._distributionType =
                    // 訂正理由の表示のため、「更新・訂正」については区別できるようにする
                    // 更新ボタン押下→「更新」、訂正・取消ボタン押下→「訂正」
                    (distributionType === DistributionType.UPDATE ? DistributionType.CORRECT : distributionType);
                // 配信種別が「配信対象外」でなければ配信対象とする
                this._provideFlg = distributionType !== DistributionType.NO_DISTRIBUTE;

                var value = {
                    disasterPrevId: this._disasterPrevId,
                    provideFlg: this._provideFlg,
                    disPrevDistributionType: this._distributionType,
                    allDelete: allDelete,
                    version: this.version.get('value')
                };
                this.emit('put-revert-send', {value: value});
            }));
        },

        /**
         * 詳細ダイヤログ固有のヴァリデーションチェック
         * @param {MouseEvent} evt
         */
        _validate: function() {
            var deferred = new Deferred();
            var infoDialogMsg = null;

            // 本部解除の場合
            if (this.hqSetFlgAbolished.get('value')===true&&
            domStyle.get(this.hqInfNode, 'display')!=='none') {
                // 本部解除時、設置日時入力必須（体制解除は入力不要）
                if(!this.hqSetTimestamp.validate() ||
                    !this.hqSetTimestamp._date.displayedValue ||
                    !this.hqSetTimestamp._time.displayedValue) {
                    infoDialogMsg = '「設置日時」';
                }
                // 廃止日時入力必須
                if(!this.hqAbolishedTimestamp.validate() ||
                    !this.hqAbolishedTimestamp._date.displayedValue ||
                    !this.hqAbolishedTimestamp._time.displayedValue) {
                        infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                        infoDialogMsg = '「廃止日時」';
                }
            }

            // 両日時入力チェックOKの場合、設置日時＜廃止日時チェック
            if (infoDialogMsg) {
                infoDialogMsg += 'が入力されていません。';
            // 設置日時＜廃止日時チェックは、両日時とも表示されている場合のみ実施
            } else if(domStyle.get(this.hqSetTimestampArea, 'display')!=='none' &&
                    domStyle.get(this.hqAbolishedTimestampArea, 'display')!=='none'){
                if (this.hqAbolishedTimestamp.validate() &&
                    this.hqAbolishedTimestamp._date.displayedValue &&
                    this.hqAbolishedTimestamp._time.displayedValue &&
                    (this.hqSetTimestamp._date.displayedValue>this.hqAbolishedTimestamp._date.displayedValue||
                    (this.hqSetTimestamp._date.displayedValue===this.hqAbolishedTimestamp._date.displayedValue&&
                    this.hqSetTimestamp._time.displayedValue>=this.hqAbolishedTimestamp._time.displayedValue))) {
                        infoDialogMsg = '本部廃止日時は、本部設置日時以降の日時を入力してください。';
                }
            }

            // 更新の場合、且つ報告日時が前報より過去の場合エラー
            if (!infoDialogMsg && // メッセージが登録されていない
                this._updateFlg === 0 && // 更新ボタン選択
                (this.reportTimestamp.validate() && // 報告日時が設定されている
                this.reportTimestamp._date.displayedValue &&
                this.reportTimestamp._time.displayedValue) &&
                (this._lastDetailItem.reportTimestampMS >= this.reportTimestamp.get('value')) // 日時が同じ又は以前
            ) {
                infoDialogMsg = '更新時の報告日時は、前報の報告日時以降の日時を入力してください。';
            }

            // エラーメッセージ表示
            if(infoDialogMsg){
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return deferred.reject();
            }

            // 前後の本部設置状況と種別を比較して判定
            var lastHqSetFlg = this._lastDetailItem.hqSetFlg;
            var nowHqSetFlg = this.form.get('value').hqSetFlg;
            if (lastHqSetFlg === nowHqSetFlg) {
                return deferred.resolve();
            }
            var nowItem = {
                hqSetFlg: nowHqSetFlg,
                hqType: this._prevStatusStore.get(this.status.get('value')).hqType
            };
            // 更新
            if (this._updateFlg === 0) {
                infoDialogMsg = this._validateCompareHqSetStatus(this._lastDetailItem, nowItem);

                // エラーメッセージ表示
                if(infoDialogMsg){
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : infoDialogMsg
                    });
                    this.infoDialog.show();
                    return deferred.reject();
                }
                return deferred.resolve();
            }

            // 訂正
            // 前報を取得π
            Loader.wait(Requester.get('/api/disasterPrevention/revert/' + this._disasterPrevId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            })).then(lang.hitch(this, function(item) {
                infoDialogMsg = this._validateCompareHqSetStatus({
                    hqSetFlg: item ? item.hqSetFlg : HqSetFlg.NOT_YET,
                    hqType: item ? item.hqType : HqType.NOT_YET
                }, nowItem);
                // エラーメッセージ表示
                if(infoDialogMsg){
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : infoDialogMsg
                    });
                    this.infoDialog.show();
                    deferred.reject();
                } else {
                    deferred.resolve();
                }
            }), lang.hitch(this, function(error) {
                console.log(error);
                this.chain.info('情報の取得に失敗しました。', 'エラー');
                deferred.reject();
            }));
            return deferred.promise;
        },

        _validateCompareHqSetStatus: function(last, now) {
            var lastHqType = last.hqType;
            var lastHqSetFlg = last.hqSetFlg;
            var nowHqSetFlg = now.hqSetFlg;
            var nowHqType = now.hqType;
            switch (nowHqSetFlg) {
                case HqSetFlg.NOT_YET: // 設置状況：未設置
                    // 設置 → 未設置は不可
                    if (lastHqSetFlg === HqSetFlg.ESTABLISH) {
                        return '本部設置状況は「設置」から「未設置」への更新はできません。<br>' +
                            '設置している本部を「廃止」に更新後、「未設置」に更新ください。<br>' +
                            '前報が誤りの場合は訂正又は取消にて変更してください。';
                    }
                    break;
                case HqSetFlg.RELEASE: // 設置状況：廃止
                    var msg = '本部設置状況は「未設置」から「廃止」への更新、又は<BR>' +
                        '異なる体制状況から「廃止」への更新はできません。<BR>' +
                        '「未設置」から「設置」、「設置」から「廃止」へ順次更新するか<BR>' +
                        '前報が誤りの場合は訂正又は取消にて変更してください。';

                    // 体制状況「解除」かつ更新の場合は、種別不一致であっても可（訂正は不可）
                    if (nowHqType === HqType.RELEASE){
                        return this._updateFlg === 1 ? msg : null;
                    }
                    // 本部設置/廃止への更新の場合、種別が一致していない場合は不可
                    if (([HqType.RESPONSE_HQ, HqType.ALERT_HQ].indexOf(nowHqType) > -1 &&
                        (nowHqType !== lastHqType || lastHqSetFlg === HqSetFlg.NOT_YET)) ||
                        // 本部設置/解除 → 本部未設置解除 は不可
                        ([HqType.RESPONSE_HQ, HqType.ALERT_HQ].indexOf(lastHqType) > -1 &&
                            nowHqType === HqType.RELEASE)) {
                            return msg;
                    }
                    break;
                case HqSetFlg.ESTABLISH:
                default:
                    break;
            }
            return null;
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(parent, obj) {

            this.form.reset();
            this.reportTimestamp._date.displayedValue = null;
            this.reportTimestamp._time.displayedValue = null;
            this.hqSetTimestamp._date.displayedValue = null;
            this.hqSetTimestamp._time.displayedValue = null;
            this.hqAbolishedTimestamp._date.displayedValue = null;
            this.hqAbolishedTimestamp._time.displayedValue = null;

            this._lastDetailItem = obj;
            this._disasterType = obj.disasterType;
            this._parent = parent;
            this.initDeleteDialog();
            this.initForm(obj);
        },

        /**
         * 削除対象選択ダイアログを初期化する。
         */
        initDeleteDialog: function(){
            // 取消ボタン押下イベントを受け取る
            this.innerDeleteDialog.on('send-delete-target', lang.hitch(this, function(value) {
                this.deleteDialog.hide();
                this.submitRevertReport(value.allDelete);
            }));
        },

        /**
         * 本部設置状況変更イベント
         */
        onChangeHqFlg: function () {
            // 詳細画面の場合、直前の状態と比較して成否を判定する。
            var hqSetFlg = this.form.get('value').hqSetFlg;

            //  廃止が選択されている＆直前報が未設置の場合は、体制状況を「解除」として本部設置情報ペインを閉じる
            if(hqSetFlg === '2' &&
                this._lastDetailItem.hqSetFlg === HqSetFlg.NOT_YET) {
                domStyle.set(this.hqInfNode, 'display', 'none');
                this.form.set('value', {status:'999999'});
                return;
            }

            if (this.form.get('value').status === '999999' &&
                    hqSetFlg !== '2' &&
                    domStyle.get(this.hqInfNode, 'display')!=='none') {
                // 体制解除が選択されている場合、「廃止」のみが選択可能
                // エラーメッセージを出力し、体制状況を元に戻す。
                var infoDialogMsg = '体制状況に「解除」を選択した場合、<BR>';
                infoDialogMsg += '本部設置状況は「廃止」のみが選択可能です。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                this.hqSetFlgAbolished.set('value', true);
                return;
            }
            this._setHqSetInf(hqSetFlg);
        },

        /**
         * 体制状況変更イベント
         */
        onChangeHqStatus: function (value) {
            // 詳細画面の場合、直前の状況と比較して成否を判定する。
            // 直前報の本部設置状況が「設置」の場合、変更は不可
            if (this._lastDetailItem.status === value.code) {
                // 変更前後でコードが同じ場合は変更不要
                this._setHqInfNode(value);
                return;
            }
            this._setHqInfNode(value);
        },

        /**
         * 災害種別変更イベント
         */
        onChangeDisasterType: function (value) {
            // 詳細画面の場合、直前の状況と比較して成否を判定する。
            if (this._disasterType === value) {
                // 変更前後でコードが同じ場合は変更不要
                return;
            }
            // 直前報の本部設置状況が「設置」の場合、変更は不可
            if (this._lastDetailItem.hqSetFlg === '1') {
                // 直前報の体制設置状況フラグが「設置」の場合、
                // エラーメッセージを出力し、体制状況を元に戻す。
                var infoDialogMsg = '本部が設置された状態で災害種別を変更することができません。<BR>';
                infoDialogMsg += '本部が切り替わった場合は、旧本部を廃止の上で新本部を設置してください。<BR>';
                infoDialogMsg += '前報が誤りの場合は訂正又は取消を行ってください。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                this.disasterTypeSelect.set('value', this._lastDetailItem.disasterType);
                return;
            }
            this._disasterType = this.disasterTypeSelect.value;
            this.setStatus();
            this.status.set('value', this._lastDetailItem.status);
        }
    });
});

/**
 * クロノロジの新規登録・詳細・続報登録画面用の基底モジュール。
 * @module app/chronology/_ChronologyPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/ChronologyRegisterPage.html',
    'dojo/dom-style',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/consts/STORAGE_KEY',
    'idis/view/page/_PageBase',
    'idis/map/IdisMap',
    'idis/consts/USER_TYPE',
    'idis/consts/ORGANIZATION_GROUP',
    'idis/model/UserInfo',
    'leaflet',
    '../config',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/util/storage/LocalStorage',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'exif-js/exif',
    'app/map/baselayer/BaseLayerPane',
    'app/model/Municipality',
    'app/model/RegionMunicipality',
    'dojo/on',
    'dojox/lang/functional/array',
    '../draw/DrawPanel',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dojox/form/Uploader',
    'dijit/form/SimpleTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector'
], function(module, declare, lang, domClass, domConstruct, json, topic, template, domStyle,
    Menu, MenuItem, popup, TooltipDialog, STORAGE_KEY, _PageBase, IdisMap, USER_TYPE, ORGANIZATION_GROUP,
	UserInfo, leaflet, config, GeoService, Requester, FilesUtils, LocalStorage,
    DialogChain, IdisDialog, InfoDialog, Loader, exif, BaseLayerPane, Municipality,
    RegionMunicipality, on, df, DrawPanel, Region) {
    // GeoServiceを初期化
    var _geoService = null;

    /**
     * クロノロジ新規登録・詳細・続報登録画面画面。
     * @class _ChronologyPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/chronology/_ChronologyPageBase~_ChronologyPageBase# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 市町村コード（県）
         */
        MUNICIPALITY_CD_KUMAMOTO_PREF: config.municInfo.prefMunicCd,

        /**
         * 市町村コード（市）
         */
        MUNICIPALITY_CD_KUMAMOTO_CITY: config.municInfo.cityMunicCd,

        //作図ダイアログ
        drawPanel : null,

        DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
        JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
        JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
        DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
        //イベントは破棄しない、全作図モードをOFFにする
        DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
        //mapに取り付けた全てのイベントを破棄する。
        DRAW_EVT_OFF: '/app/draw/DrawPanel::removeDrawAllEvent',

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * 市町村コードのリスト
         */
        _municipalityCds: null,

        /**
         * 発生元住所 緯度
         */
        _sourceAddressLat: null,


        /**
         * 発生元住所 経度
         */
        _sourceAddressLng: null,

        /**
         * 災害シミュレーションフラグ
         */
        _simulateFlg: false,
        _scenarioId: null,
        _eventType: null,
        _content: null,
        _timing: null,

        /**
         * 要請フラグ
         */
        _isDisasterHld: false,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 災害シミュレーションの場合添付ファイルを不可視にする
            if(this._simulateFlg){
                domStyle.set(this.attachFileItem, 'display', 'none');
            }
        },

        /**
         * postCreate
         */
        postCreate: function() {
            this.inherited(arguments);
        },

        /**
         * リンク挿入ボタンが押された際に呼ばれる。
         */
        onLinkInsertButtonClick: function() {
            // 内容のカーソル位置を記憶する
            var point = this.content.domNode.selectionStart;
            if (!point && point !== 0) {
                // 取得出来なかった場合は末尾に設定
                point = this.content.get('value').length;
            }
            this._insertPoint = point;
            this.linkInsertForm.reset();
            this.linkInsertDialog.show();
        },

        /**
         * リンク挿入内容確定時に呼ばれる。ベント
         */
        onLinkInsertOK: function() {
            try {
                if (this.linkInsertForm.validate()) {
                    // リンク文字列を生成
                    var value = this.linkInsertForm.get('value');
                    var linkText = '[' + value.title + '](' + value.url + ')';
                    // 内容のカーソル位置か末尾へリンク文字列を挿入
                    var content = this.content.get('value');
                    var newContent = content.slice(0, this._insertPoint) + linkText + content.slice(this._insertPoint);
                    this.content.set('value', newContent);
                    // ダイアログを閉じる
                    this.linkInsertDialog.hide();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        setShareTips: function(){
            var shareOrganContent = '宛先となる自治体を指定してください。<br>' +
                                    '自治体を選択すると自治体内の全組織が宛先となり、<br>' +
                                    '組織を選択すると選択した組織が宛先となります。<br>' +
                                    '登録内容は、宛先の組織のユーザにのみ閲覧可能です。';

            var shareOrganTips = new TooltipDialog({
                id: 'shareTips',
                style: 'width: 375px; height:100px',
                content: '<p>' + shareOrganContent + '</p>'
            });
            var shareOrganLabel = this.shareOrganLabel;
            this.own(shareOrganTips);
            on(shareOrganLabel, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: shareOrganTips,
                    around: shareOrganLabel
                });
            }));
            on(shareOrganLabel, 'mouseleave', function() {
                popup.close(shareOrganTips);
            });
        },

        /**
         * マップを初期化する。
         */
        initMap: function(latitude, longitude, zoom) {

            //中心アイコンを非表示にする
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');

            // マップの生成
            var latlng = null;
            if(latitude && longitude) {
                latlng = [latitude, longitude];
            } else {
                latlng = [config.map.latitude, config.map.longitude];
            }
            if(!zoom){
                zoom = 14;
            }
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, //　コメント時に+/-が使用できないため
                touchExtend : false,
                minZoom: 7,
                drawControlTooltips:false}
            ).setView(latlng, zoom);
            // destroy時にmapを破棄するよう設定
            this.own(this.map);

            this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                // 作図ダイアログが閉じられているとき住所取得モードとする
                if(this.drawPanel._ActiveMode === null) {
                    this.pointLat = e.latlng.lat;
                    this.pointLng = e.latlng.lng;
                    this.addMark(this.pointLat, this.pointLng, this);
                }
            })));

            // 作図パネルを生成
            this.createDrawPanel();
            topic.publish(this.DRAW_INIT_ID, this.map);
            topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function(args){
                this.drawJson = args;
            }));
        },

        /**
         * 作図ペインを生成する。
         */
        createDrawPanel: function() {
            if(this.drawPanel === null){
                this.own(
                    this.drawPanel = new DrawPanel({
                        map     : this.map,
                        'class'  : 'drawPanel-NonModal',
                        dispType : 'damage'
                    }));
            }
        },

        /**
         * 作図ダイアログを表示する。
         */
        showDrawPanelDialog: function(){
            this.drawPanel.show();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
         * 地図上でポイントされている位置の住所を設定します。
         */
        mapToAddress: function() {
            if(this.pointLat === '' || this.pointLng === '') {
                console.debug('not pointed map');
                this.chain.info('地図が選択されていません。', 'エラー');
                return;
            }
            console.debug('start reverse geocoding');
            if (_geoService === null) {
                _geoService = new GeoService({ url: config.geocode && config.geocode.url });
            }
            _geoService.reverseGeocode(leaflet.latLng({
                lat: this.pointLat,
                lng: this.pointLng
            })).then(lang.hitch(this, function(res) {
                this.addMark(this.pointLat, this.pointLng);
                this.sourceAddress01.set('value', res.address.Address);
                this._sourceAddressLat = this.pointLat;
                this._sourceAddressLng = this.pointLng;
            }), lang.hitch(this, function() {
                this.chain.info('住所を取得できませんでした。', 'エラー');
            }));

            console.debug('end reverse geocoding (address: ' +
                    this.sourceAddress01.value + ')');
        },

        /**
         * 住所の位置を地図上にポイントします。
         */
        addressToMap: function() {
            if(!this.sourceAddress01.value) {
                console.debug('not input address');
                this.chain.info('住所を入力してください。', 'エラー');
                return;
            }
            var address = this.sourceAddress01.value;

            if (_geoService === null) {
                _geoService = new GeoService({ url: config.geocode && config.geocode.url });
            }
            _geoService.geocode(address).then(lang.hitch(this, function(results) {
                if(results.length > 0) {
                    var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                    this.pointLat = results[0].latlng.lat;
                    this.pointLng = results[0].latlng.lng;
                    this.addMark(this.pointLat, this.pointLng);
                    var zoom = 11;
                    if(results[0].text === config.municInfo.prefName){
                        zoom = 7;
                    }
                    if(results[0].text.indexOf('振興局') !== -1){
                        zoom = 9;
                    }
                    this.map.setView(latlng, zoom);
                    this._sourceAddressLat = results[0].latlng.lat;
                    this._sourceAddressLng = results[0].latlng.lng;
                } else {
                    this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                }
            }));
        },

        /**
         * マーカーを追加する。
         */
        addMark: function(lat, lng) {
            this.removeMark();
            this.marker = leaflet.marker([lat, lng]).addTo(this.map);
        },

        /**
         * マーカーを削除する。
         */
        removeMark: function() {
            if(this.marker){
                this.map.removeLayer(this.marker);
            }
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            FilesUtils.uploadFile(this, '/api/chronologies/uploadFile');

        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {
            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.chronologyAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1) {
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self, false);

            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self, false);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self, false);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                    console.log('GPS data is unavailable.');
                }else{
                    console.log('GPS data is available.');
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 14);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * クロノロジ対応履歴を登録する。
         */
        registerChronologyAction: function() {
            var sendData = {
                chronologyId : this.chronologyId,
                content : this.action.value,
                attachFileIds: ''
            };

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            // 添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            var jsonStr = json.stringify(sendData);
            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/chronologies/action', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function() {
                    console.debug('success register Chronology');
                    chain.infoComplete(function() {
                        chain.hide();
                    });
                }, function(error) {
                    console.log('error register Chronology');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * 市町村情報を取得する
         * @param {*} cd
         */
        getMunicipalityInfo: function(cd){
            return Municipality.store.get(cd).then(lang.hitch(this, function(municipality){
                return municipality ? municipality : null;
			}));
        },

        /**
         * 自治体コードから自治体情報を取得する
         * @param {*} cd
         */
        getRegionMunicipalityInfo: function(cd){
            return RegionMunicipality.store.get(cd).then(lang.hitch(this, function(item){
                return item ? item : null;
			}));
        },

        /**
         * ツリーオブジェクトから組織オブジェクトを成形する
         * @param {*} treeObj
         */
        setOrganizationFromTreeObject: function(treeObj, parent){
            parent = null; // コンパイルエラー回避のためのダミー処理
            var organization = {};
            // NOTE: 長崎県では係まで管理対象とする
            // また、ツリーオブジェクトには自治体オブジェクトが入りうる
            if(treeObj.id.substr(0,1) === 'U'){
                organization.unitCd = treeObj.id;
                organization.sectCd = treeObj.parentId;
                organization.deptCd = treeObj.grandParentId;
            } else if (treeObj.id.substr(0,1) === 'S'){
                organization.unitCd = null;
                organization.sectCd = treeObj.id;
                organization.deptCd = treeObj.parentId;
            } else
            if (treeObj.id.substr(0,1) === 'D'){
                organization.unitCd = null;
                organization.sectCd = null;
                organization.deptCd = treeObj.id;
            }
            organization.name = treeObj.name;
            return organization;
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self, pic, isAdminPage, isAction) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            if (pic){
                var previewUri = self.getPreviewUri(uri);
                domConstruct.place('<img src="'+ previewUri + '" width="110">', contentNode);
            }

            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1) {
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    if(isAdminPage){
                        if(isAction){
                            // 概況画面から対応履歴の添付を削除
                            for(var i=0; i<self.actionAttachFileList.length; i++) {
                                if(self.actionAttachFileList[i].chronologyAtcFileId === id) {
                                    self.actionAttachFileList.splice(i,1); //id:3の要素を削除
                                }
                            }
                        }else{
                            // 概況画面からクロノロジの添付を削除
                            for(var j=0; j<self.attachFileList.length; j++) {
                                if(self.attachFileList[j].chronologyAtcFileId === id) {
                                    self.attachFileList.splice(j,1); //id:3の要素を削除
                                }
                            }
                        }
                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }else{
                        self.chain.confirmDel(function(chain) {
                            var promise = Requester.del('/api/chronologies/uploadFile/' + id)
                            .then(function() {
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    // 該当ファイルを削除
                                    for(var i=0; i<self.attachFileList.length; i++) {
                                        if(self.attachFileList[i].chronologyAtcFileId === id) {
                                            self.attachFileList.splice(i,1); //id:3の要素を削除
                                        }
                                    }
                                    // サムネイルとメニューを削除
                                    domConstruct.destroy(newNode);
                                    popup.close(tooltip);
                                });
                            }, function(error) {
                                console.log(error);
                                chain.infoError(error);
                            });

                            //ローダーの表示
                            Loader.wait(promise);

                        });
                    }
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['above-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * プレビューをクリアする
         **/
        clearPreview: function() {

            var length = this.preview.childNodes.length;
            for(var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }

            if(this._attachFileList) {
                this._attachFileList.splice(0, this._attachFileList.length);
            }
        },

        /**
         * プレビュー用の画像ファイルのURIを返す
         **/
        getPreviewUri : function(uri) {
            var previewUri = uri;
            // 画像ファイルの場合
            if(uri.indexOf('.jpg') !== -1 ||uri.indexOf('.jpeg') !== -1 ||
            uri.indexOf('.png') !== -1 || uri.indexOf('.JPG') !== -1 ||
            uri.indexOf('.JPEG') !== -1 || uri.indexOf('.PNG') !== -1 ||
            uri.indexOf('.gif') !== -1) {
                previewUri = uri;
            } else if (uri.indexOf('.xls') !== -1 || uri.indexOf('.xlsx') !== -1) {
                previewUri = 'images/excelicon.png';
            } else if (uri.indexOf('.pdf') !== -1) {
                previewUri = 'images/pdficon.png';
            } else if (uri.indexOf('.doc') !== -1 || uri.indexOf('.docx') !== -1) {
                previewUri = 'images/docicon.png';
            } else {
                previewUri = 'images/othericon.png';
            }
            return previewUri;
        },

        /**
         * 自治体・組織コードが設定された場合の処理
         */
        onMunicipalityChange: function(){

            // NOTE: 長崎県では自治体と組織を一つのセレクタで制御するため
            // 要請の場合、宛先に組織が指定されているかチェックする
            if(this._isDisasterHld) {
                // セレクタからフルチェックされた自治体コードを取得する
                var shareMap = this.shareMunicipalityCd.tree._checkMap;
                var tree = this.shareMunicipalityCd.tree;
                var isMunicipality = false;
                var isDummy = false; // 自治体指定か否か
                var isCrossOrg = false; // 組織またぎか否か

                // チェックされている項目に部以外のものがないかを判定する
                df.forIn(shareMap, function(item) {
                    var cd = tree.model.store.getIdentity(item);
                    // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                    // idが4桁未満のものをアイテムをまとめるダミーとして判定
                    if (cd.substring(0,1) !== 'D') {
                        // 市町村または振興局または県
                        isMunicipality = true;
                    }
                    if (cd.length < 4){
                        isDummy = true;
                    }
                    // 組織またぎか否か
                    if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                        // 配信元が県・振興局ユーザの場合、宛先が市町自治体か否か
                        isCrossOrg = /^42[234]*/.test(item.id);
                    } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                        // 配信元が市町ユーザの場合、宛先に「長崎県」の有無
                        isCrossOrg = (item.id === config.municInfo.prefMunicCd);
                    } else if( UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                        // 配信元がその他ユーザの場合、要請の宛先は県のみとなるため、組織またぎ確定
                        isCrossOrg = true;
                    }
                }, this);

                if (isDummy && !isCrossOrg) {
                    // 自治体が選択されていた場合、エラーメッセージを出力する
                    // var infoDialogMsg = '宛先は自治体全体でなく要請を行う組織を選択してください。';
                    var infoDialogMsg = '宛先には要請を行う個別の自治体または組織を選択してください。';
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : infoDialogMsg
                    });
                    this.infoDialog.show();
                    return false;
                }
            }

            // 最下層組織が取得されるため市町村コードへの変換が必要
            var selectedMunicItem = this.shareMunicipalityCd.getMunicipalityCdsIncludesHalf()[0];
            // 未選択の場合は処理せず終了
            if(!selectedMunicItem){
                // this.chronologyShares.set('value', []);
                return false;
            }
            var selectedMunicCd = selectedMunicItem.id;
            //地図の位置を変更
            if(this.map){
                var api = '/api/municipalities/';
                this.isRegion(selectedMunicCd).then(lang.hitch(this, function(isRegion){
                    if(isRegion){
                        api = '/api/regions/';
                    }
                    Requester.get(api + selectedMunicCd, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(municipality) {
                        // マップを初期化
                        if(municipality.latitude && municipality.longitude){
                            this.map.setView([municipality.latitude,municipality.longitude]);
                        }
                    }), lang.hitch(this, function() {
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    }));
                }));
            }

            // NOTE: 長崎県案件では自治体と組織はひとつのセレクタとするため不要
            // //共有先の入力情報を初期化の上、ツリーを再構築
            // this.chronologyShares.set('value', []);
            // this.chronologyShares.onMunicipalityChange(selectedMunicCd);
            //共有先セレクタを表示
            // domStyle.set(this.chronologySharesAlert, 'display', 'none');
            // domStyle.set(this.chronologyShares.domNode, 'display', '');

        },

        /**
         * 宛先一括選択ボタンをクリックした際に呼ばれる
         */
        // 「各市町の防災分野を一括選択」ボタン
        onCollective1ButtonClick: function() {
            // 一括選択したい部・課・局のコードを入力
            var items = ORGANIZATION_GROUP.CHRONOLOGY_DP;
            // 宛先セレクターにセットする
            this.shareMunicipalityCd.set('value', items);
        },
        // 「県本庁の主幹課を一括選択」ボタン
        onCollective2ButtonClick: function() {
            // 一括選択したい部・課・局のコードを入力
            var items = ORGANIZATION_GROUP.CHRONOLOGY_MAIN;
            // 宛先セレクターにセットする
            this.shareMunicipalityCd.set('value', items);
        },

        /**
         * ユーザが長崎県危機管理部危機管理課ユーザかどうかを判定する
         * NOTE: 独自要件のため組織コードはハードコードする
         * @param {*} id
         */
        // isManagementOffice: function() {
        //     var roleCd = UserInfo.getRoleCd();
        //     if (roleCd === 'R01011' || roleCd === 'R01012') {
        //         return true;
        //     }
        //     return false;
        // },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        }
    });
});

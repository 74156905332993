/**
 * 市民への情報配信履歴 詳細ダイアログ用モジュール。
 * @module app/citizenInfoHistory/CitizenInfoHistoryDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/text!./templates/CitizenInfoHistoryDetailDialog.html',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/DateTextBox',
    'dijit/form/Form',
    'dijit/form/NumberTextBox',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'dijit/TitlePane'
], function(module, declare, domStyle, template, DialogChain, _PageBase, DisasterInfo) {
    /**
     * 市民への情報配信履歴 詳細画面
     * @class CitizenInfoHistoryDetailPage
     * @extends module:app/citizenInfoHistory/_CitizenInfoHistoryPageBase~_CitizenInfoHistoryPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/citizenInfoHistory/CitizenInfoHistoryDetailDialog~CitizenInfoHistoryDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {

            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
            
            //災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
            }
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
        },

        // 防災行政無線の値が変更された時の処理
        onChangeEmergencyRadioRegistFlg: function (evt) {
            if(evt.target.value === '0') {
                domStyle.set(this.municipalityCds, 'display', 'none');
            }else {
                domStyle.set(this.municipalityCds, 'display', '');
            }
        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function(object) {
            this._citizenInfoId = object.citizenInfoId;

            // ここで区のリストをセットする
            if(object.municipalityCdList){
                this.municipalityCd.set('value', object.municipalityCdList);
            }

            // 配信区の初期表示
            if(object.emergencyRadioFlg === '0') {
                domStyle.set(this.municipalityCds, 'display', 'none');
            }else {
                domStyle.set(this.municipalityCds, 'display', '');
            }
        },

        /**
         * 更新ボタンクリックイベント
         */
        updateCitizenInfoHistoryButtonClick: function() {
            try {

                var value = this.form.get('value');
                value.disasterId = this._disasterId;

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを実施（独自チェック）
                if (!value.transmitOrganizationCd || value.transmitOrganizationCd === '') {
                    this.chain.info('配信組織を選択してください。', '入力エラー');
                    return false;
                }
                if (value.emergencyRadioFlg === '1' && 
                    (!value.municipalityCd || value.municipalityCd.length === 0 )) {
                    this.chain.info('配信区を選択してください。', '入力エラー');
                    return false;
                }
                
                // 配信区が設定された場合の処理
                if(value.municipalityCd.length > 0 && value.emergencyRadioFlg === '1') {
                    var citizenInfoHistoryAreas = [];
                    for(var i=0; i < value.municipalityCd.length; i++) {
                        citizenInfoHistoryAreas.push(value.municipalityCd[i]);
                    }
                    value.citizenInfoHistoryAreas = citizenInfoHistoryAreas.join(',');
                } else {
                    value.citizenInfoHistoryAreas = '';
                }
                delete value.municipalityCd;
                
                // 市民への情報配信履歴IDをセット
                value.citizenInfoId = this._citizenInfoId;

                this.emit('update', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 削除ボタンクリックイベント
         */
        deleteCitizenInfoHistoryButtonClick: function() {
            try {
                var value = this.form.get('value');
                value.citizenInfoId = this._citizenInfoId;

                this.emit('remove', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * キャンセルボタン押下時処理
         */
        cancel: function() {
            // ダイアログを閉じる
            this.leave();
        }
    });
});

/**
 * 被災対応状況の追加ダイアログ。
 * @module app/bbs/view/BbsReplyForm
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/BbsReplyForm.html',
    'idis/service/Requester',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    './_BbsPageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Select',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput'
], function(module, declare, domStyle, json, topic, template,
    Requester, Locator, Loader, DialogChain, UserInfo, _BbsPageBase) {
    /**
     * 掲示板返信コメント入力フォーム
     * @class BbsReplyDetailPage
     * @extends module:app/bbs/_BbsPageBase~_BbsPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BbsPageBase,
        /** @lends module:idis/view/page/BbsReplyDetailPage~BbsReplyDetailPage# */
        {
            // テンプレート文字列
            templateString: template,
            attachFileList: [],
            bbsReplyId: null,
            selectedReply: null,

            constructor: function() {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
                this._bbsId = Locator.getQuery().bbsId;
            },

            // DOMを構築する
            buildRendering: function() {
                this.inherited(arguments);
                this.organizationNameNode.innerHTML = UserInfo.getOrganizationName();
            },

            /**
             * 掲示板返信コメントを登録する。
             */
            registerReply: function() {
                try {
                    if (this.replyForm.validate()) {
                        // 入力チェックが成功したらaddイベントを発行
                        // 実際の登録処理はサンプル画面側でaddイベントを監視しているメソッドが実施
                        var sendData = {
                            bbsId : this._bbsId,
                            comment : this.comment.get('value'),
                            sendUserName : this.sendUserName.get('value'),
                            attachFileIds: '',
                        };
        
                        // フォームのバリデーションを行う（共通部品）
                        if(!this.replyForm.validate()) {
                            return false;
                        }
                        if(!sendData.comment  || sendData.comment === ''){
                            this.chain.info('コメントが入力されていません。', 'エラー');
                            return false;
                        }
        
                        // 添付ファイルIDをセット
                        sendData.attachFileIds = this.setFileIds();
                        var jsonStr = json.stringify(sendData);
                        //登録確認ダイアログを表示
                        this.chain.confirmAdd(function(chain) {
                            var promise = Requester.post('/api/bbses/reply/register', {
                                data: jsonStr,
                                headers: {'Content-Type': 'application/json; charset=utf-8'},
                                handleAs: 'json',
                                preventCache : true
                            }).then(function() {
                                console.debug('success register Bbs');
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    // DetailPageに入力内容を送る
                                    topic.publish(module.id + '::initReply');
                                    this.preview.innerHTML = '';
                                    this.attachFileList = [];
                                });
                            }, function(error) {
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        });
                        console.debug('[登録]ボタンがクリックされました。');
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 掲示板返信コメントを更新する。
             */
            updateReply: function() {
                try {
                    if (this.replyForm.validate()) {
                        var sendData = this.replyForm.get('value');
                        delete sendData.attachFile;
                        if(!sendData.comment  || sendData.comment === ''){
                            this.chain.info('コメントが入力されていません。', 'エラー');
                            return false;
                        }

                        // 添付ファイルIDをセット
                        sendData.attachFileIds = this.setFileIds();
                        var jsonStr = json.stringify(sendData);
                        //更新確認ダイアログを表示
                        this.chain.confirmPut(function(chain) {
                            var promise = Requester.put('/api/bbses/reply/' + this.bbsReplyId, {
                                data: jsonStr,
                                headers: {'Content-Type': 'application/json; charset=utf-8'},
                                handleAs: 'json',
                                preventCache : true
                            }).then(function() {
                                console.debug('success update Bbs');
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    // DetailPageに入力内容を送る
                                    topic.publish(module.id + '::initReply');
                                });
                            }, function(error) {
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        });
                        console.debug('[更新]ボタンがクリックされました。');
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            /**
             * 掲示板返信コメントを削除する。
             */
            deleteReply: function() {
                try {
                    if (this.replyForm.validate()) {
                        //削除確認ダイアログの表示
                        this.chain.confirmDel(function(chain) {
                            var promise = Requester.del('/api/bbses/reply/' + this.bbsReplyId)
                            .then(function() {
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    this.emit('delete', {
                                        // テンプレートHTML内でformに対し
                                        // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                                        value: this.replyForm.get('value')
                                    });
                                    topic.publish(module.id + '::initReply');
                                });
                            }, function(error) {
                                chain.infoError(error);
                            });

                            //ローダーの表示
                            Loader.wait(promise);
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 掲示板返信フォームを初期化する
             */
            clearReplyForm: function(){
                //[編集中]メッセージを消す
                if(this.selectedReply && this.selectedReply.editingMsg){
                    domStyle.set(this.selectedReply.editingMsg, 'display', 'none');
                }
                this.replyForm.reset();
                this.formTitle.innerHTML = '返信';
                this.preview.innerHTML = '';
                domStyle.set(this.insertReplyButtons.domNode, 'display', '');
                domStyle.set(this.updateReplyButtons.domNode, 'display', 'none');
            },

            /**
             * 添付ファイルIDをセットする
             */
            setFileIds: function() {
                // 添付ファイルIDをセット
                if(this.attachFileList.length>0) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].bbsAtcFileId);
                    }
                    return attachFileIds.join(',');
                } else {
                    return '';
                }
            },

            /**
             * 掲示板返信編集フォームを表示する
             */
            showUpdateReplyForm: function(item){
                this.replyForm.reset();
                this.replyForm.set('value', item);
                this.bbsReplyId = item.bbsReplyId;
                this.attachFileList = [];
                this.preview.innerHTML = '';

                // 添付ファイルを設定
                if(item.attachFiles) {
                    for(var i=0; i<item.attachFiles.length; i++) {
                        this.attachFileList.push(item.attachFiles[i]);
                        this.showPreview(item.attachFiles[i]);
                    }
                }
                this.formTitle.innerHTML = '返信編集';
                domStyle.set(this.insertReplyButtons.domNode, 'display', 'none');
                domStyle.set(this.updateReplyButtons.domNode, 'display', '');
            }
        });
});

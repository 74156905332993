/**
 * 警報・注意報のカラム定義一覧。
 * @module app/timeline/RegisterCell
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/topic',
    // 'dojo/_base/array',
    'idis/util/DateUtils',
    'idis/view/grid/helper',
    'dojo/text!./templates/RegisterCell.html',
    'idis/view/_IdisWidgetBase',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/ValidationTextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'dojox/lang/functional/fold'
], function(module, declare, domStyle, topic, DateUtils, helper, template, WidgetBase, Loader, InfoDialog) {

    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/timeline/RegisterCell~RegisterCell# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'timeline-registerCell',

        item: null,

        store: null,

        buildRendering: function(){
            this.inherited(arguments);
            if(this.item.completeTimestamp){
                domStyle.set(this.uncompleted, 'display', 'none');
                this.reportTimestampLabel.innerText = DateUtils.format(this.item.completeTimestamp, {time: 'HH:mm'});
            } else {
                domStyle.set(this.completed, 'display', 'none');
            }

            if (this.item.disabled) {
                this.registerUpdateButton.set('disabled',true);
                this.registerClearButton.set('disabled',true);
                this.registerCompleteButton.set('disabled',true);
                this.reportTimestampInput._nowButton.set('disabled', true);
            }
        },

        register: function(){
            var data = {
                timelineDetailId: this.item.timelineDetailId,
                //reporter: this.reporterNameInput.get('value'),
                completeTimestamp: this.reportTimestampInput.get('value'),
                alertFlg: this.item.alertFlg,
                noActionFlg: this.item.noActionFlg
            };
            return this.updateRecord(data);
        },

        updateRecord: function(data,clear){
            if (!data.completeTimestamp && !clear){
                InfoDialog.show('入力チェック', '完了日時がセットされていません。');
            } else {
                var promise = this.store.put(data);
                Loader.wait(promise).then(function(){
                    topic.publish(module.id + '::updated');
                }, function(err) {
                    console.error('エラーが発生しました', err);
                    topic.publish(module.id + '::updated');
                });
            }
        },

        update: function(){
            domStyle.set(this.uncompleted, 'display', '');
            domStyle.set(this.completed, 'display', 'none');
            this.reportTimestampInput.set('value', this.item.completeTimestamp);
        },

        clear: function(){
            var data = {
                timelineDetailId: this.item.timelineDetailId,
                completeTimestamp: null,
                alertFlg: this.item.alertFlg,
                noActionFlg: this.item.noActionFlg
            };
            return this.updateRecord(data,'clear');
        },

        registerColumn: function(){
            return helper.column('content', '実施項目');
        }

    });
});

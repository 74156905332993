/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/WeatherInfoTimeLineFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/KisyoAreaSelector',
    'dijit/form/Form'
], function (module, declare, lang, Deferred, JSON, template, _PageBase, DisasterInfo, InfoDialog,
    DialogChain, Loader) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
            // テンプレート文字列
            templateString: template,
            /**
             * 災害ID
             */
            _disasterId: null,

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                }
            },

            setInitValue: function(areaCd, reportDatetimeFrom, reportDatetimeTo) {
                this.areaCd.set('value',areaCd);
                this.reptimestampFrom.set('value',reportDatetimeFrom);
                this.reptimestampTo.set('value',reportDatetimeTo);
            },

            /**
             * 帳票を出力する
             */
            outputListExcel: function () {

                // 出力条件
                var areaCd = this.form.get('value').areaCd;
                var repdateFrom = this.reptimestampFrom._date.displayedValue;
                var reptimeFrom = this.reptimestampFrom._time.displayedValue;
                var repdateTo = this.reptimestampTo._date.displayedValue;
                var reptimeTo = this.reptimestampTo._time.displayedValue;

                if (!this._disasterId) {
                    this.chain.info('災害名が正しく選択されていません。', 'エラー');
                    return false;
                }
                //repdateをYYYYMMDD形式に変換
                repdateFrom = repdateFrom.replace(/\//g, '');
                repdateTo = repdateTo.replace(/\//g, '');
                //reptimeをhhss形式に変換
                reptimeFrom = reptimeFrom.replace(':', '');
                reptimeTo = reptimeTo.replace(':', '');

                var reqid = 'WEATHER_INFO_LIST_EX';
                var fileName = 'WeatherInfoList.xlsx';
                var paramList = [];
                if (areaCd) {
                    paramList.push({ key: 'areaCd', value: areaCd });
                }
                if (repdateFrom) {
                    paramList.push({ key: 'repdateFrom', value: repdateFrom });
                }
                if (reptimeFrom) {
                    paramList.push({ key: 'reptimeFrom', value: reptimeFrom });
                }
                if (repdateTo) {
                    paramList.push({ key: 'repdateTo', value: repdateTo });
                }
                if (reptimeTo) {
                    paramList.push({ key: 'reptimeTo', value: reptimeTo });
                }

                var data4xoblosDownload = {
                    fileName: fileName,
                    reqid: reqid,
                    paramList: paramList
                };


                var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function () {
                    this.infoDialog = new InfoDialog({
                        title: 'ダウンロード完了',
                        content: 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function (error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title: 'エラー',
                        content: 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
                }));

                Loader.wait(promise);
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },

            // 帳票のダウンロード
            download: function (data) {
                var deferred = new Deferred();

                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {

                    // エラー時は処理を止める
                    if (xhr.status !== 200) {
                        deferred.reject();
                        return;
                    }

                    // バイナリデータを取得
                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                    var baseFileName = '気象情報一覧（二次細分区域）.xlsx';
                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放
                        window.URL.revokeObjectURL(url);
                        link.remove();
                    }

                    deferred.resolve();

                };
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            },

            /**
             * キャンセルボタン押下
             */
            onCancel: function () {
                this.getParent().hide();
            }
        });
});

/**
 * <潮位実況情報情報一覧画面（10分)>
 *
 * @module app/tide/grid/TideLevelObservationPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './TideLevelObservationCommonColumns'
    // 以下、変数で受けないモジュール
], function(module, declare, IdisGrid, helper, CommonColumn, TideCommonColumns) {


    /**
     * 潮位実況情報情報一覧画面（10分)
     *
     * @class TideLevelObservationPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/tide/TideLevelObservationPageGrid~TideLevelObservationPageGrid# */ {
        minRowsPerPage: -1,
        columns: [
			helper.column('regionName', '地域'),
            CommonColumn.municipalityCd,
            CommonColumn.observatoryName,
            helper.buttonColumn('detail', '詳細'),
            TideCommonColumns.tideLevel,
            TideCommonColumns.tideLevelTp,
            TideCommonColumns.tideLevelAstronomical,
            helper.column('limitLevel', '基準値', {
                children : [
                    CommonColumn.threshold('tideLevelAlert', '警戒潮位[m]', 2),
                    CommonColumn.threshold('tideLevelReport', '通報潮位[m]', 2)
                ]
            }),
            CommonColumn.managerCd
        ]
    });
});

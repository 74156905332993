/**
 * お知らせ情報グリッド
 * @module app/convocation/view/condition/ConvoAutoMaiRegisterGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },
        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('convoGroup', 'グループ名'),
            helper.column('convoStaffNum', '対象職員数'),
            helper.buttonColumn('delete', '削除')
        ]
});
});

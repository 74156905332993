/**
 * 自動招集条件グリッド
 * @module app/convocation/view/condition/ConvoAutoMailListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('settingName', '配信設定名', {sortable: false})
        ]
    });
});

/**
 * 災害情報詳細/更新画面用モジュール。
 * @module app/timeline/TimelineActionPlanRegisterDialog
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/text!./templates/TimelineActionPlanRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/view/Loader',
    'dstore/RequestMemory',
    'dojo/request/iframe',
    'idis/view/dialog/DialogChain',
    'app/model/DisasterInfo',
    'dojo/string',
    // 以下、変数から参照されないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    'app/view/page/ErrorMessageDialogForXoblos',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function (module, json, declare, lang, array, topic, template, InfoDialog, _PageBase, Requester, Loader,
    RequestMemory, iframe, DialogChain, DisasterInfo, dStr) {
    /**
     * 災害情報登録画面。
     * @class TimelineActionPlanRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/timeline/TimelineActionPlanRegisterDialog~TimelineActionPlanRegisterDialog# */ {
            // テンプレート文字列
            templateString: template,

            _fileId: null,

            infoDialog: null,

            _disasterId: null,

            file: [],

            ACTION_PLAN_REQID: 'ACTION_PLAN_IN',

            TIMELINE_TEMPLATE_DOWNLOAD_PATH: '/data/timeline/タイムラインテンプレート.xlsx',

            constructor: function () {
                this.inherited(arguments);
                // 入力帳票の各種パスを取得する。
                this.storepath = new RequestMemory({
                    idProperty: 'reqid',
                    target: '/data/report/xoblos-config.json'
                });
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
            },

            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             *報告書読み込みで呼ばれる
             **/
            loadFile: function () {
                //ファイルが空の場合は処理を中断
                if (this.attachFile._files.length === 0) {
                    return;
                }

                console.debug('report change');

                // エクセルファイル以外の場合はエラー
                var file = this.attachFile._files[0];
                if (file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
                    file.name.indexOf('.xlsm') === -1) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: 'エクセルファイルを選択してください。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    this.attachFile.reset();
                    return;
                }

                var form = new FormData();
                form.append('report', this.attachFile._files[0]);

                // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをコールするときにも使う。
                var now = new Date();
                this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                    this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                    this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/upload/uploadFile?fileId=' +
                    this._fileId, {
                    form: this.actionPlanRegisterForm.id,
                    handleAs: 'json'
                });
                //ローダーの表示
                Loader.wait(promise);
            },


            /**
             * ファイル読み込み実行
             */
            onSubmit: function () {
                if (!this.validate()) {
                    return false;
                }
                this.isnputListExcel();
            },

            /**
             * ファイル入力実行
             */
            isnputListExcel: function () {
                var reqid = this.ACTION_PLAN_REQID;
                var reqParams = {};
                this.storepath.fetch().then(lang.hitch(this, function (data) {
                    data.some(function (data) {
                        if (reqid === data.reqid) {
                            reqParams = data;
                            return true; //break;
                        }
                    });
                })).then(lang.hitch(this, function () {
                    // ファイルをサーバーにアップロードするAPIのURL
                    var url = '/api/xoblos/uploadReport' +
                        '?reqid=' + reqid +
                        '&disid=' + this._disasterId +
                        '&path=' + reqParams.pathweb;

                    iframe.post(url, {
                        form: this.actionPlanRegisterForm.id,
                        handleAs: 'json'
                    }).then(lang.hitch(this, function (ret) {
                        // アップロードしたファイルパスとエラーメッセージjson、
                        // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                        var promise = Requester.post('/api/xoblos/upload', {
                            data: {
                                reqid: reqid,
                                disid: this._disasterId,
                                filename: ret.fileName
                            }
                        }).then(lang.hitch(this, function (data) {
                            // ResultCode=0以外（xoBlosエラー）の場合にはエラーをスローする。
                            // エラー処理の中で、xoBlosエラーダイアログを表示する。
                            if (data.ResultCode !== 0) {
                                var err = new Error('xoblos failed');
                                err.data = data;
                                throw err;
                            } else {
                                var regapi = reqParams.regapi;
                                console.log(data);
                                return Requester.post(regapi, {
                                    data: {
                                        // ResultCodeが0の場合は、正常。
                                        items: data.data,
                                        managementId: this.actionPlanRegisterForm.value.activeFlg
                                    }
                                });
                            }
                        })).then(lang.hitch(this, function (data) {
                            // 成功した場合は完了ダイアログを表示する。
                            if (data.errorActionPlan.length === 0) {
                                this.chain.infoComplete(function () {
                                    // 確認ダイアログを閉じる
                                    this.chain.hide();
                                    // 親のダイアログを閉じる。
                                    this.getParent().hide();
                                    this.emit('upload');
                                });
                            } else {
                                var message = '';
                                message += 'ファイル読み込みを完了しました。<br>';
                                message += 'ただし、入力異常が存在しているため、登録できませんでした。<br><br>';
                                message += '<table style="margin-left: auto;margin-right: auto">';
                                message += '<tr><th style="text-align:center"><b>帳票情報</b></th><tr>';
                                array.forEach(data.errorActionPlan, function (errorActionPlan) {
                                    message += '<tr><td>' + errorActionPlan;
                                    message += '</td></tr>';
                                });
                                message += '</table>';

                                var title = '完了';
                                // ダイアログにメッセージを表示
                                this.chain.info(message, title, function () {
                                    // 確認ダイアログを閉じる
                                    this.chain.hide();
                                    // 親のダイアログを閉じる。
                                    this.getParent().hide();
                                    this.emit('upload');
                                });
                            }
                        }), lang.hitch(this, function (error) {
                            this.uploadError(ret, error, reqParams);
                        }));
                        // ローダーの表示
                        Loader.wait(promise);
                    }));
                }));
            },

            uploadError: function (ret, error, reqParams) {
                // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                console.log(ret);
                console.log(error);
                console.log(reqParams);
                if (error.response.data) {
                    // エラーダイアログに結果コードを渡す
                    var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                    errPage.initErrorMessageDialogForXoblos(
                        error.response.data.code,
                        reqParams.errpathweb + this.ACTION_PLAN_REQID + '/' +
                        this._disasterId + '/' + ret.fileName,
                        ret.fileName);
                    this.errorMessageDialogForXoblos.show();
                    this.attachFile.reset();
                } else {
                    // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                    this.chain.infoError(function () {
                        this.chain.hide();
                        // 親のダイアログを閉じる。
                        this.getParent().hide();
                    });
                }
            },

            // 日付をゼロパディング
            padZero: function (num) {
                var result;
                if (num < 10) {
                    result = '0' + num;
                } else {
                    result = '' + num;
                }
                return result;
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },

            /**
             * Date形式のtimestampをxoBlosインターフェイス仕様のtimestamp文字列に置き換える。
             */
            _getTimestampStr: function (ts) {
                if (!ts) {
                    return '';
                }
                var year = dStr.pad(ts.getFullYear(), 4);
                var month = dStr.pad(ts.getMonth(), 2);
                var date = dStr.pad(ts.getDate(), 2);
                var hour = (ts.getHours()) ? dStr.pad(ts.getHours(), 2) : '00';
                var min = (ts.getMinutes()) ? dStr.pad(ts.getMinutes(), 2) : '00';
                var sec = (ts.getSeconds()) ? dStr.pad(ts.getSeconds(), 2) : '00';

                return year + month + date + hour + min + sec;
            },

            /**
             * キャンセルボタン押下
             */
            onCancel: function () {
                this.getParent().hide();
            },

            /**
             * 入力チェック
             */
            validate: function () {
                // ファイルが空の場合は処理を中断
                if (this.attachFile.getFileList().length === 0) {
                    InfoDialog.show('入力チェックエラー', 'タイムライン入力フォーマットを追加してください。');
                    return false;
                }
                // タイムラインが選択されていなかった場合はエラー
                if (this.actionPlanRegisterForm.value.activeFlg === '' ||
                    this.actionPlanRegisterForm.value.activeFlg === null) {
                    InfoDialog.show('入力チェックエラー', 'タイムラインを選択してください。');
                    return false;
                }
                return true;
            },

            /**
             * タイムラインテンプレートボタン押下
             */
            onDownloadTemplate: function () {
                window.location.href = this.TIMELINE_TEMPLATE_DOWNLOAD_PATH;
            }
        });
});

/**
 * <雨量ランキング画面>
 *
 * @module app/observation/rain/RainfallObservationRankingPageGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, IdisGrid, helper, CommonColumn) {
    /**
     * 雨量ランキング用Grid
     *
     * @class RainfallObservationRankingPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/observation/rain/RainfallObservationRankingPageGrid~RainfallObservationRankingPageGrid# */
        {
		columns: [		          
			helper.column('rankingNum', '順位'),
			CommonColumn.observatoryName,
			helper.column('municipalityName', '市町'),
			{field : 'rainfall60Min',
				label : '60分雨量[mm]',
				formatter: lang.hitch(this,function(item) {
					if (!item) {
						return '-';
					} else {
						return item.toFixed(1);
					}
				})
			},
			{field : 'rainfall24Hour',
				label : '24時間雨量[mm]',
				formatter: lang.hitch(this,function(item) {
					if (!item) {
						return '-';
					} else {
						return item.toFixed(1);
					}
				})
			},
			{field : 'rainfallCumulative',
				label : '累計雨量[mm]',
				formatter: lang.hitch(this,function(item) {
					if (!item) {
						return '-';
					} else {
						return item.toFixed(1);
					}
				})
			},
			helper.column('dataTimestamp', '観測日時'),
			helper.buttonColumn('detail', '詳細')
		]
	});
});
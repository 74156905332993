/**
 * 気象情報画面用モジュール。
 * @module app/weatherinfo/view/WeatherInfoPageRegion
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/control/Router',
    'dojo/text!./templates/WeatherInfoPageRegion.html',
    './_WeatherInfoPageBase',
    'idis/store/IdisRest',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    './WeatherInfoGridRegion'
], function(module, declare, Router, template, _PageBase, IdisRest) {
    /**
     * 気象情報画面
     * @class WeatherInfoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/weatherinfo/view/WeatherInfoPage~WeatherInfoPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--weatherinfo',

        // ストア
        store: null,

        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'areaCode',
                target: '/api/WeatherInformations/region'
            });
        },

        /**
        //  * 細分区域を切り替える
        //  */
        switchDivision: function(evt) {
            evt.preventDefault();
            // 気象警報・注意報（市町村）画面へ遷移
            Router.moveTo('weatherinfo');

        },

        onWeatherInfoTimeLineRegionButtonClick: function() {
            // 避難状況登録登録画面へ遷移
            Router.moveTo('weatherinfo/timeline/region');
        }
    });
});
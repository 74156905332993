/**
 * 最新の観測時刻取得用モジュール
 * @module app/observation/LatestData
 */
define([
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/service/Requester'
], function(declare, lang, Requester) {
    /**
     * 最新の観測情報を管理するクラス
     *
     * @class LatestData
     * @param {Object} kwArgs
     * @param {string} [kwArgs.url='.../data.json'] 観測情報を保持するJSONファイルのパス
     * @example <caption>ファイルの例</caption>
     * data.json
     * @example <caption>JSONファイルのフォーマット</caption>
     * {
     *     latestDateTimestamp: 2017-10-10 10:00:00 , // 最新の観測時刻
     * }
     */
    return declare(null, {
        /**
         * 最新の観測情報に対するリクエスト結果
         * @type {Promise<Object>}
         */
        _promise: null,

        /**
         * 最新の観測情報を保持しているjsonファイルのURL
         * @type {String}
         */
        url: null,

        /**
         * 最新の観測情報
         * @type {Object}
         */
        _latestData: {},

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
        },

        /**
         * 最新の観測情報をサーバーから取得し、成否をPromiseとして返す。
         * @returns {Promise}
         */
        load: function() {
            return Requester.get(this.url).then(lang.hitch(this, function(latestData){

                // TODO: IE対応
                this._latestData = latestData;
            }));
        },

        /**
         * 最新の観測情報を再度サーバーから取得する
         * @returns {Promise}
         */
        refresh: function() {
            // 取得情報を初期化する
            this._promise = null;
            this._latestData = null;
            return this.load();
        },

        /**
         * 最新の観測時刻を返す。
         * @returns {string} 観測時刻 yyyy-MM-dd hh:mm:ss
         */
        getLatestDateTimestamp: function() {
            return this._latestData.latestDateTimestamp;
        },

        getLatestDate: function() {
            return new Date(this._latestData.latestDateTimestamp.replace(/-/g, '/'));  
        },
        
        /**
         * 最新の観測時刻に対するJSONファイル名を返す。
         * @returns {string} yyyy-MM-dd-HH-mm.json
         */
        getLatestJsonFileName: function() {
            var date = this._latestData.latestDateTimestamp.slice(0, 16).replace(/ /g, '-').replace(/:/g, '-');
            return date + '.json';
        },

        /**
         * 最新の正時に対するJSONファイル名を返す。
         * @returns {string} yyyy-MM-dd-HH-00.json
         */
        getLatestHourJsonFileName: function() {
            var date = this._latestData.latestDateTimestamp.slice(0, 13).replace(/ /g, '-');
            return date + '-00.json';
        }

    });
});

/**
 * 避難所情報パネル
 * @module app/monitor/ShelterPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/ShelterPanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function (module, array, declare, lang, locale, domConstruct, domStyle, JSON, template, topic,
    dojo, Router, UserInfo, USER_TYPE, Requester, DialogChain, Loader, _PageBase, DisasterInfo, config) {
    /**
     * 避難所情報パネル
     * @class ShelterPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/ShelterPanel~ShelterPanel# */ {
            // テンプレート文字列
            templateString: template,

            /**
             * 最大表示件数
             */
            MAX_COUNT: 5,

            /**
             * 市町村コード
             */
            _municipalityCd: null,

            /**
             * constructor
             */
            constructor: function () {
                this.chain = DialogChain.get(this);
                this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
                console.debug('避難所情報表示対象の市町村コード：' + this._municipalityCd);
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);
                this.initTable();

                // 市町村切替時に避難所情報を更新
                this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                    lang.hitch(this, function (payload) {
                        this._municipalityCd = payload.municipalityCd;
                        this.initTable();
                    })));

                // 災害切替時に避難所情報を更新
                this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                    lang.hitch(this, function () {
                        this.initTable();
                    })));

            },

            /**
             * テーブルを初期化します
             */
            initTable: function () {
                // 避難所情報概況を設定
                this.setShelterOverviewInfo();

                // 避難所情報一覧を設定
                this.setShelterListInfo();
            },

            /**
             * 避難所情報概況を設定します
             */
            setShelterOverviewInfo: function () {
                var self = this;

                var disasterId = DisasterInfo.getDisasterId();

                var promise = Requester.get('/data/shelter/overview/' + disasterId + '/' + this._municipalityCd + '.json', {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    console.debug('避難所情報（' + disasterId + ', ' + this._municipalityCd + '）：' +
                        JSON.stringify(item));

                    // 避難所情報を設定
                    self.setShelterOverview(self, item);

                }, function (error) {
                    console.log('避難所情報概況が見つかりません。初期値をセットします。' + (error.message ? ('(' + error.message + ')') : ''));
                    if (error.response.status === 404) {
                        var item = {
                            'openingShelterNum': 0,
                            'totalEvaqueeNum': 0,
                            'totalEvacHouseholdNum': 0
                        };
                        self.setShelterOverview(self, item);
                    } else {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 避難所情報概況を設定します
             */
            setShelterOverview: function (self, item) {
                self.openingShelterNum.innerHTML = item.openingShelterNum;
                self.totalEvaqueeNum.innerHTML = item.totalEvaqueeNum;
                self.totalEvacHouseholdNum.innerHTML = item.totalEvacHouseholdNum;
            },

            /**
             * 避難所情報一覧を設定します
             */
            setShelterListInfo: function () {
                var self = this;

                var disasterId = DisasterInfo.getDisasterId();

                var promise = Requester.get('/data/shelter/list/' + disasterId + '/' + this._municipalityCd + '.json', {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    console.debug('避難所情報（' + disasterId + ', ' + this._municipalityCd + '）：' +
                        JSON.stringify(item));

                    // 避難所情報を設定
                    self.setShelterList(self, item);

                }, function (error) {
                    console.log('避難所情報一覧が見つかりません。初期値をセットします。' + (error.message ? ('(' + error.message + ')') : ''));
                    if (error.response.status === 404) {
                        var item = { 'items': [] };
                        self.setShelterList(self, item);
                    } else {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 避難所情報を設定します
             */
            setShelterList: function (self, data) {
                var shelterPanelTbody = dojo.byId('shelterPanelTbody');
                domConstruct.empty(shelterPanelTbody);

                // 先頭5件のみ表示
                if (data.items.length > this.MAX_COUNT) {
                    data.items.length = this.MAX_COUNT;
                }

                array.forEach(data.items, function (item) {
                    var datetimeString = self.formatDateTime(item.registerTimestamp);
                    var tr = domConstruct.create('tr');
                    domConstruct.place(domConstruct.create('td', {
                        innerHTML: datetimeString, style: 'padding-left:10px; width:30%;'
                    }), tr);
                    domConstruct.place(domConstruct.create('td', {
                        innerHTML: item.content, style: 'width:55%;'
                    }), tr);
                    var detailTd = domConstruct.create('td', {
                        style: 'text-align: right; padding-right:10px; width:15%; color: blue;'
                    });
                    var u = domConstruct.create('u');
                    var a = domConstruct.create('a', {
                        innerHTML: '詳細&gt;',
                        href: '#',
                        onclick: function () {
                            Router.moveToByUrl(item.sourceUrl);
                        }
                    });
                    domConstruct.place(a, u);
                    domConstruct.place(u, detailTd);
                    domConstruct.place(detailTd, tr);
                    domConstruct.place(tr, shelterPanelTbody);
                });

            },

            /**
             * 'yyyy-MM-dd HH:mm' 形式に変換
             */
            formatDateTime: function (val) {
                if (!val) {
                    return '';
                }
                var timestamp = new Date(val);
                var dateLabel = locale.format(timestamp, {
                    selector: 'date',
                    datePattern: 'yyyy/MM/dd'
                });
                var timeLabel = locale.format(timestamp, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                return dateLabel + '&nbsp;' + timeLabel;
            },

            /**
             * 避難所情報概況ページに遷移
             */
            onShelterAdminLinkClick: function (evt) {
                evt.preventDefault();

                if (this._municipalityCd && this._municipalityCd !== config.municInfo.prefMunicCd) {
                    Router.moveTo('shelter', {
                        municipalityCd: this._municipalityCd
                    });
                } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                    Router.moveTo('shelter', {
                        municipalityCd: UserInfo.getMunicipalityCd()
                    });
                } else {
                    Router.moveTo('shelter/admin');
                }
            }

        });
});

/**
* メール配信一覧画面用モジュール。
* @module app/broadnotify/BroadnotifyTemplateSelectGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, lang, array, locale, df, IdisGrid, IdisSelector, helper, DateUtils) {

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector], {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [

            helper.buttonColumn('detail', '選択'),

            helper.column('notifyTypeName', '通知種別'),

            helper.column('subject', 'タイトル'),

            helper.column('body', '本文'),

            helper.column('remarks', '備考'),

            helper.column('admin', '管理者'),

            {field: 'updTimestamp', label: '更新日時', sortable: true,
            formatter: function(updTimestamp){
                return updTimestamp ? DateUtils.format(updTimestamp) : '-';
            }
        }

        ]
    });
});

/**
* Spectee管理画面、詳細画面共通モジュール。
* @module app/spectee/_SpecteeAdminPageBase
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/SpecteeAdminPage.html',
    'dojo/on',
    'dstore/Memory',
    'idis/view/page/_PageBase',
    'idis/consts/STORAGE_KEY',
    'idis/util/storage/LocalStorage',
    'idis/map/IdisMap',
    'app/config',
    'idis/service/GeoService',
    'idis/service/Requester',
    'leaflet',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/IdisDialog',
    'app/map/baselayer/BaseLayerPane',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    '../view/form/DisasterSelector',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './SpecteeLegendDialog',
    './SpecteeAdminGrid',
    './NoneAdressIconPanel'
], function (module, array, declare, lang, domClass, domStyle, template, on, Memory, _PageBase,
    STORAGE_KEY, LocalStorage, IdisMap, config, GeoService, Requester, leaflet, InfoDialog, IdisDialog, BaseLayerPane) {

    // GeoServiceを初期化
    var _geoService = new GeoService({ url: config.geocode && config.geocode.url });

    /**
    * Spectee情報画面。
    * @class _SpecteeAdminPageBase
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/spectee/_SpecteeAdminPageBase~_SpecteeAdminPageBase# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--spectee',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            store: null,
            /**
             * 災害ID
             */
            _disasterId: null,
            /**
             * 市町コード
             */
            _municipalityCd: null,
            /**
             * 使用するgrid名
             * @type {String}
             */
            GRID_NAME: 'grid',
            /**
             * お気に入り情報
             * @type {String}
             */
            IS_FAVORITE: '1',
            /**
             * page種別(管理画面)
             * @type {String}
             */
            PAGE_TYPE_ADMIN: 'ADMIN_PAGE',
            /**
             * page種別(詳細画面)
             * @type {String}
             */
            PAGE_TYPE_DETAIL: 'DETAIL_PAGE',
            /**
             * SNS種別(Twitter)
             * @type {String}
             */
            SNSTYPE_TWITTER: 1,
            /**
             * SNS種別(Instagram)
             * @type {String}
             */
            SNSTYPE_INSTAGRAM: 2,
            /**
             * SNS種別(Facebook)
             * @type {String}
             */
            SNSTYPE_FACEBOOK: 3,
            /**
             * SNS種別(youtube)
             * @type {String}
             */
            SNSTYPE_YOUTUBE: 4,
            /**
             * SNS種別(Tiktok)
             * @type {String}
             */
            SNSTYPE_TIKTOK: 5,

            /**
             * 位置情報確定レベル(緯度経度・住所なし)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_NONE: '0',
            /**
             * 位置情報確定レベル(都道府県)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_PREF: '1',
            /**
             * 位置情報確定レベル(市町村)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_CITY: '2',
            /**
             * 位置情報確定レベル(区町丁目)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_TOWN: '3',
            /**
             * 位置情報確定レベル(位置情報確定)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_COMFIRMED: '4',
            /**
             * gridの選択行id
             * @type {String}
             */
            gridSelectedId: null,
            /**
             * マウスオーバーイベントで使用する「詳細」「お気に入り登録・解除」ボタン列のマウスオーバー状態
             * @type {boolean}
             */
            mouseOvered: false,
            /**
             * 「詳細」「お気に入り登録・解除」ボタンをクリックした時点の
             * 「詳細」「お気に入り登録・解除」ボタン列のマウスオーバー状態
             * @type {boolean}
             */
            wasMouseOvered: false,
            /**
             * 選択行の情報
             * @type {Object}
             */
            selectedRow: {},
            //地図上の描画
            drawnNewsIds: [],
            divIconMarkers: [],


            constructor: function () {
                //地図アイコンに関する情報を初期化
                this.drawnNewsIds = [];
                this.divIconMarkers = [];

                this._events = [];
            },
            /**
             * ページ遷移でSpectee情報取得の定期実行イベントとDomを破棄する。
             */
            destroy: function () {
                this.inherited(arguments);
                if (this.specteeTimer) {
                    clearInterval(this.specteeTimer);
                }
            },

            /**
            * ログインユーザによって管理者用gridと市区町村ユーザ用gridのいずれを使用するかが決定されるため
            * 使用するgridの非表示を解除する(両gridとも、初期は非表示)
            */
            setGridStyle: function () {
                this[this.GRID_NAME + 'Area'].domNode.style.display = '';
            },

            /**
            * グリッドを初期化する。
            * 管理者用gridと市区町村ユーザ用gridを共通で処理できるよう、GRID_NAMEで処理を使い分ける
            */
            initGrid: function () {
                // お気に入り登録ボタンのクリックイベント処理
                this[this.GRID_NAME].on('openFavoriteDialog', lang.hitch(this, function (evt) {
                    this.openFavoriteDialog(evt.item);
                }));

                // お気に入り登録解除ボタンのクリックイベント処理
                this[this.GRID_NAME].on('favoriteRemoveButtonClick', lang.hitch(this, function (item) {
                    this.removeFavorite(item);
                }));

                this[this.GRID_NAME].on('moveToDetailPage', lang.hitch(this, function(evt) {
                    evt.preventDefault();
                    this.moveToDetailPage(evt);
                }));

                // グリッドの行選択イベント
                this[this.GRID_NAME].on('dgrid-select', lang.hitch(this, function (evt) {
                    // クリックイベント発火時のマウスオーバー状態を保持する
                    this.wasMouseOvered = new Object({ 0: this.mouseOvered });
                    // 選択された行データを退避
                    var item = lang.mixin(null, evt.rows[0].data);

                    // 選択行とidを保持する
                    // SNSダイアログ表示後の、select状態を解除する処理に使用する
                    this.gridSelectedId = evt.rows[0].id;
                    this.selectedRow = evt.rows[0];

                    // 地図上にピンを打つため緯度経度情報を取得する
                    var prefecture = null;
                    var city = null;
                    var town = null;
                    var lat = null;
                    var lng = null;
                    var locationInfo = {};

                    // SpecteeAPIと、お気に入り情報取得APIではレスポンスのJSONが異なるため、判定を入れる
                    if (item.locationInfo) {
                        if (item.locationInfo[0]) {
                            locationInfo = item.locationInfo[0];
                            if (locationInfo.geocode) {
                                lat = locationInfo.geocode.latitude;
                                lng = locationInfo.geocode.longitude;
                            }
                        }
                    }

                    // 取得した住所情報を各変数にセットする
                    if (locationInfo) {
                        prefecture = locationInfo.prefecture;
                        city = locationInfo.city;
                        town = locationInfo.town;
                    }

                    // 地図上にピンを打つ
                    if (lat && lng) {
                        // 緯度経度情報がSpecteeから渡ってきた場合は、その値を使用する
                        this.addMark(lat, lng, item);
                        this.map.setView([lat, lng], 14);
                    } else {
                        // 緯度経度情報がSpecteeから渡ってこなかった場合は、ジオコーディングして地図上にポイントを打つ
                        // 都道府県、市町村、区町丁目情報を引数として渡す
                        this.addressToMap(prefecture, city, town, item);
                    }
                }));
            },

            /**
             * grid情報を最新化する。
             */
            updateGrid: function (data) {
                var gridData = new Memory({
                    data: data,
                    idProperty: 'newsId'
                });

                this._count = 0;
                // 表示されているアイコンを削除する
                this.removeMarkers();
                // グリッドを空にする
                this[this.GRID_NAME].set('collection', null);
                // gridに情報を反映する
                this[this.GRID_NAME].set('collection', gridData.filter());
                if (gridData.data.length >= 100) {
                    InfoDialog.show('', '検索結果が100件以上あるため、最新100件を表示しています。');
                }
            },

            /**
             * マップを初期化する。
             */
            initMap: function (lat, lng, zoom) {
                // 中心アイコンを非表示にする
                LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');

                // マップの生成
                var latlng = [lat, lng];

                this.map = new IdisMap(this.map, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }).setView(latlng, zoom);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function (e) {
                    this.pointLat = e.latlng.lat;
                    this.pointLng = e.latlng.lng;
                })));
            },

            /**
             * grid描画時、spectee情報の位置情報をもとに地図上にアイコンを生成する
             */
            addMarker: function (arg) {
                // すでに描画されていた場合は戻る。
                if (this.drawnNewsIds.indexOf(arg.newsId) !== -1) {
                    return false;
                }

                // 位置情報がない場合のアイコン表示
                if(!arg.locationInfo || arg.locationInfo.length === 0 || !arg.locationInfo[0].city || arg.locationInfo[0].city === '""'){
                    this._count ++;
                    this.noneAdressIconPanel.noneAdressIcon(arg, this._count);
                    this.noneAdressIconPanel.noneAdressCount(this._count);
                    return false;
                }

                var marker = this.makeSpecteeMarker(arg);
                if (marker) {
                    var self = this;
                    marker.addTo(this.map).on('click', function () {
                        self.clickMarker(arg);
                    });
                    this.divIconMarkers.push(marker);
                    this.drawnNewsIds.push(arg.newsId);
                }
            },

            /**
             * 地図上のアイコンを削除する
             */
            removeMarkers: function () {
                var map = this.map;
                array.forEach(this.divIconMarkers, function (divIcon) {
                    map.removeLayer(divIcon);
                });

                this.drawnNewsIds.length = 0;
            },

            //地図上のマーカー押下時の挙動
            clickMarker: function (arg) {
                this.grid.clearSelection();
                this.grid.select(arg.newsId);
            },

            _toSendValue: function (form, _disasterId, _municipalityCd, _regionCd) {
                var value = lang.mixin(null, form.item);
                value.disasterId = _disasterId;
                value.regionCd = _regionCd;
                value.municipalityCd = _municipalityCd;
                value.memo = form.value.memo;

                // 不要な要素を除去する
                delete value.newsId;
                delete value.categoryIds;
                delete value.targetIds;
                delete value.date;
                delete value.eventIds;
                delete value.note;
                delete value.reportNo;
                delete value.title;
                delete value.updDate;
                delete value.locationInfo;
                delete value.snsInfo;
                delete value.postDate;
                delete value.favoriteStatus;
                delete value.newFlg;
                delete value.deleteFlg;
                delete value.favoriteFlg;
                return value;
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function (prefecture, city, town, item) {
                if (!prefecture) {
                    if (this.marker) {
                        this.removeMark();
                    }
                    // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                    this.setPopup(item, true, false);
                    return;
                }
                var address = prefecture + city + town;
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, item);
                        if (this.map) {
                            this.map.setView(latlng, this.map.getZoom());
                        }
                    } else {
                        // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                        this.setPopup(item, true, true);
                        if (this.marker) {
                            this.removeMark();
                        }
                    }
                })).otherwise(lang.hitch(this, function () {
                    // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                    this.setPopup(item, true, true);
                    if (this.marker) {
                        this.removeMark();
                    }
                }));
            },

            /**
             * マーカーを追加する。
             */
            addMark: function (lat, lng, item) {
                if (this.marker) {
                    this.removeMark();
                }
                if (this.map) {
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                }
                // 第二引数はダイアログ表示のケースか否かを表し、第三引数はarcgisサービス側でのエラー発生の有無を表している
                this.setPopup(item, false, false);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.map) {
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * ポップアップの中身を作る。
             * isDialog: ダイアログ表示のケースか否か
             * isError: arcgisサービス側でのエラー発生の有無
             */
            setPopup: function (item, isDialog, isError) {
                // クリック時のポップアップを作成
                var loc = null;
                var sns = null;
                // 詳細画面も本処理を使用するので、どちらのページから呼び出されたかを判定する
                // 詳細ボタンクリックとgrid行選択のイベントが同時に走るため、formの有無で判断する
                if (this.form && this.form.get('value').favorite &&
                    (this.form.get('value').favorite[0] === this.IS_FAVORITE)) {
                    loc = item.locationInfo;
                    sns = item.snsInfo;
                } else {
                    if (item.locationInfo[0]) {
                        loc = item.locationInfo[0];
                    }
                    if (item.snsInfo[0]) {
                        sns = item.snsInfo[0];
                    }
                }

                // 日付をyyyy-MM-dd HH:mm形式にする
                if (item && item.updDate && item.updDate.length > 17) {
                    item.updDate = item.updDate.substr(0, 16);
                }
                var pref = loc && loc.prefecture ? loc.prefecture : '';
                var city = loc && loc.city ? loc.city : '';
                var town = loc && loc.town ? loc.town : '';
                var address = pref + city + town;

                var snsType = null;
                if (sns) {
                    if (this.form && this.form.get('value').favorite &&
                        this.form.get('value').favorite[0] === this.IS_FAVORITE) {
                        snsType = 'snsType';
                        snsType = sns[snsType];
                    } else {
                        snsType = 'sns_type';
                        snsType = sns[snsType];
                    }
                }

                // ポップアップ内のHTML要素を組み立てる
                var popupMinWidth = 0;
                var contentMaxWidth = 0;
                var faceBookTiktokContentMaxWidth = 0;
                if (snsType === this.SNSTYPE_FACEBOOK) {
                    faceBookTiktokContentMaxWidth = this.getMaxWidth(sns.embed);
                    if (!faceBookTiktokContentMaxWidth) {
                        faceBookTiktokContentMaxWidth = 200;
                    }
                    popupMinWidth = faceBookTiktokContentMaxWidth + 40;
                    contentMaxWidth = faceBookTiktokContentMaxWidth + 20;
                } else {
                    popupMinWidth = 240;
                    contentMaxWidth = 220;
                    faceBookTiktokContentMaxWidth = 200;
                }

                var content = '<table style="border-collapse: collapse; border: solid 1px black;">';
                content += '<tr style="border: solid 1px black; background-color: #f0f8ff"><td>' +
                    '<div style="font-weight:bold; max-width:' + contentMaxWidth + 'px; ' +
                    'text-align:left; font-size:0.9em; margin: 10px">' +
                    '<span style="font-weight:normal; font-size:0.7em;">' + item.updDate + '</span><br>';
                content += address ? '<span>' + address + '</span><br>' : '';
                content += '<span style="color:#941f57">' + '[第' + item.reportNo + '報] ' + '</span>' +
                    '<span style="color:#191970">' + item.title + '</span><br>';
                content += '</div></td></tr>';

                var type = null;
                var url = null;
                var detailPageURL = null;
                var mediaUrl = 'media_url';
                var mediaType = 'media_type';
                // コンテンツの有無と、コンテンツが画像、動画のいずれであるかを判定する
                // 詳細画面も本処理を使用するので、どちらのページから呼び出されたかを判定する
                // 詳細ボタンクリックとgrid行選択のイベントが同時に走るため、formの有無で判断する
                if (sns) {
                    if (this.form && this.form.get('value').favorite &&
                        this.form.get('value').favorite[0] === this.IS_FAVORITE) {
                        url = sns.mediaUrl;
                        if (sns.mediaType === 'photo') {
                            type = 'photo';
                        } else if (sns.mediaType === 'video') {
                            type = 'video';
                        }
                    } else {
                        if (sns.media && sns.media[0]) {
                            url = sns.media[0][mediaUrl];
                            if (sns.media[0][mediaType] === 'photo') {
                                type = 'photo';
                            } else if (sns.media && sns.media[0] && sns.media[0][mediaType] === 'video') {
                                type = 'video';
                            }
                        }
                    }

                    // 画像、動画エリア Twitterのみ 他のSNSはembedに埋め込まれている
                    if (snsType === this.SNSTYPE_TWITTER) {
                        content = this.buildPopupElementForTwitter(content, url, type);
                    }

                    // Spectee側で組み立てられた埋め込み部分のうち不要な部分を削る
                    sns.embed = this.trimEmbed(sns);

                    if (snsType === this.SNSTYPE_YOUTUBE) {
                        // Youtubeの場合の処理
                        sns.embed = this.buildPopupElementForYoutube(sns, contentMaxWidth);
                    } else if (snsType === this.SNSTYPE_FACEBOOK || snsType === this.SNSTYPE_TIKTOK) {
                        // FacebookかTiktokの場合の処理
                        sns.embed = this.buildPopupElementForFacebookTiktok(sns, snsType, faceBookTiktokContentMaxWidth);
                    }

                    content += '<tr><td style="max-width:' + contentMaxWidth + 'px;">';
                    content += sns && sns.embed ? '<div style="font-size:0.9em; margin: 10px; max-width:' +
                        contentMaxWidth + 'px;">' + sns.embed + '</div>' : '';
                    content += '</td>';
                } else {
                    content += '<tr><td style="max-width:' + contentMaxWidth + 'px;">';
                    content += item.note ? '<span style="word-wrap: break-word; font-weight:normal; ' +
                    'max-width:' + contentMaxWidth + 'px; font-size:0.8em;">' + item.note + '</span>' : '';
                    content += '</td>';
                }
                detailPageURL = ['?caseId=',item.caseId,'&favoriteFlg=',item.favoriteFlg,'&p=spectee%2Fdetail'].join('');
                content += '</tr><tr><td align="left"><a href="'+ detailPageURL +'">&nbsp&nbsp詳細画面へ</a></td></tr></table><br>';

                // 地図エリアの高さを取得し、ポップアップがエリア内に収まるよう調整する
                var popupMaxHeight = null;
                if (this.mapPane && this.mapPane.h) {
                    popupMaxHeight = Math.floor(this.mapPane.h * 0.85);
                }
                var customOptions =
                {
                    'minWidth': popupMinWidth,
                    'maxHeight': popupMaxHeight
                };
                if (isDialog) {
                    // gridの行選択を解除する
                    delete this.grid.selection[this.gridSelectedId];
                    // 選択中クラスが付与されてしまうので、強制的にリムーブする
                    domClass.remove(this.selectedRow.element, 'dgrid-selected');

                    if (!this.wasMouseOvered[0]) {
                        var messageTag = null;
                        if (isError) {
                            var prefName = config.municInfo.prefName;
                            if (pref && pref !== prefName) {
                                messageTag = '<p>' + prefName + '外の住所情報から<br>緯度経度の特定はできません。</p>';
                            } else {
                                messageTag = '<p>正常に緯度経度が取得できませんでした。</p>';
                            }
                        } else {
                            messageTag = '<p>住所情報がありませんでした。</p>';
                        }
                        content = messageTag + content;
                        // ダイアログを表示する
                        this.chain.info('<div style="text-align: center;">' + content + '</div>', 'Spectee情報');
                    }
                } else if (this.marker) {
                    this.marker.bindPopup('<div style="text-align: center;">' + content + '</div>', customOptions);
                }
            },

            /**
             * Spectee側で組み立てられた埋め込み部分のうち不要な箇所を削る。
             */
            trimEmbed: function (sns) {
                // blockquoteタグを削る
                if (sns && /^(<blockquote)/.test(sns.embed)) {
                    var target = sns.embed.indexOf('>');
                    sns.embed = sns.embed.substring(target + 1);

                    var lastTarget = sns.embed.lastIndexOf('<');
                    sns.embed = sns.embed.substring(lastTarget, 0);
                }
                // 不要な改行を削る
                if (sns && /^(<br><br>)/.test(sns.embed)) {
                    sns.embed = sns.embed.replace(/<br><br>/g, '<br>');
                }
                return sns.embed;
            },

            /**
             * Twitter用のコンテンツを組み立てる。
             */
            buildPopupElementForTwitter: function (content, url, type) {
                // Twitterの場合は画像、動画を抜き出し横幅を固定
                // 画像、動画を読み込んだ後、ポップアップをリサイズする
                content += '<tr><td style="width:100%; max-width:220px;">';
                if (url) {
                    if (type === 'photo') {
                        content += '<div style="max-width: 160px; margin-top: 10px; ' +
                            'margin-left: 30px; margin-right: 30px;">';
                        // 画像の場合
                        var image = new Image();
                        image.src = url;
                        // 画像サイズを取得し終えた時点でポップアップを更新する
                        image.onload = lang.hitch(this, function () {
                            if (this.marker) {
                                this.marker._popup.update();
                            }
                        });
                        content += '<img src="' + url + '" style="width:100%; height:auto;"></img></div>' +
                            '<a href="' + url + '" target="_blank" style="font-size:0.8em;">画像へのリンク</a>';
                    } else if (type === 'video') {

                        content += '<div style="max-width: 160px; margin-top: 10px; ' +
                            'margin-left: 30px; margin-right: 30px;">';
                        // 動画の場合
                        var element = document.createElement('video');
                        var videoOriginWidth = 0;
                        var videoOriginHeight = 0;
                        var videoHeight = 0;
                        element.src = url;
                        // 動画サイズを取得し終えた時点でポップアップ内の動画サイズを書き換えポップアップを更新する
                        element.onloadedmetadata = lang.hitch(this, function () {
                            // 元の動画サイズを取得する
                            videoOriginWidth = element.videoWidth;
                            videoOriginHeight = element.videoHeight;
                            // 元の動画サイズと、200pxとの比率を算出し、正しい高さを取得する
                            var magni = parseInt(videoOriginWidth, 10) / 200;
                            videoHeight = Math.floor(videoOriginHeight / magni);
                            // ポップアップの生成処理時にheight:auto;としていた箇所を正しい高さに書き換える
                            if (this.marker) {
                                this.marker._popup._content =
                                    this.marker._popup._content.replace('height:auto;', 'height:' + videoHeight + 'px;');
                                this.marker._popup.update();
                            }
                        });

                        content += '<video controls preload="metadata" style="width:100%; height:auto;"><source src="' +
                            url + '"></video></div>';
                        content += '<a href="' + url + '" target="_blank" style="font-size:0.8em;">動画へのリンク</a>';
                    }
                }
                content += '</td></tr>';
                return content;
            },

            /**
             * Youtube用のコンテンツを組み立てる。
             */
            buildPopupElementForYoutube: function (sns) {
                // Youtubeの場合の処理 動画の縦横サイズを固定する
                // 元々縦横が指定されているため、サイズの記述部分を切り出して200×150に書き換える
                var wigthTarget = sns.embed.lastIndexOf('width="');
                sns.embed = sns.embed.substring(wigthTarget + 7);
                var wigthTargetEnd = sns.embed.indexOf('"');
                sns.embed = sns.embed.substring(wigthTargetEnd + 1);

                var heightTarget = sns.embed.lastIndexOf('height');
                sns.embed = sns.embed.substring(heightTarget + 8);
                var heightTargetEnd = sns.embed.indexOf('"');
                sns.embed = sns.embed.substring(heightTargetEnd + 1);

                sns.embed = '<iframe width="200px" height="150px"' + sns.embed;
                return sns.embed;
            },

            /**
             * Facebook/Tiktok用のコンテンツを組み立てる。
             */
            buildPopupElementForFacebookTiktok: function (sns, snsType, contentMaxWidth) {
                // FacebookかTiktokの場合は横幅を固定後、元と同じ比率になるよう縦幅を調整する
                var forward = sns.embed.substring(0, sns.embed.lastIndexOf(' width="') + 7);
                var backward = sns.embed.substring(sns.embed.lastIndexOf(' width="') + 8);
                backward = backward.substring(backward.indexOf('"') + 1);
                var originWidth = this.trimStrings(sns.embed, forward, backward);

                sns.embed = forward + '"' + contentMaxWidth + '"' + backward;

                if (snsType === this.SNSTYPE_TIKTOK) {
                    var magni = parseInt(originWidth, 10) / contentMaxWidth;

                    var forwardHeight = sns.embed.substring(0, sns.embed.lastIndexOf(' height="') + 8);
                    var backwardHeight = sns.embed.substring(sns.embed.lastIndexOf(' height="') + 9);
                    backwardHeight = backwardHeight.substring(backwardHeight.indexOf('"') + 1);

                    var originHeight = this.trimStrings(sns.embed, forwardHeight, backwardHeight);
                    var height = Math.floor(parseInt(originHeight, 10) / magni);

                    sns.embed = forwardHeight + '"' + height.toString() + 'px"' + backwardHeight;
                }
                return sns.embed;
            },

            /**
             * Facebook/Tiktokコンテンツの縦横比調整時に不要な文字列を削る。
             */
            trimStrings: function (embed, strForReplace1, strForReplace2) {
                var str = embed.replace(strForReplace1, ' ');
                str = str.replace(strForReplace2, ' ');
                str = str.replace(/"/g, '');
                str = str.replace(/ /g, '');
                str = str.replace('px', '');
                return str;
            },

            getMaxWidth: function (embed) {
                var returnValue = 240;
                if (embed !== '') {
                    // クエリストリング毎に分割
                    var params = embed.slice(1).split(' ');
                    // クエリストリング確認用
                    for (var i = 0; i < params.length; i++) {
                        var param = params[i].split('=');
                        var key = param[0];
                        var value = param[1];

                        // 該当するクエリストリングは無視
                        if (key === 'width') {
                            returnValue = this.trimStrings(value, ' ', ' ');
                            returnValue = parseInt(returnValue, 10);
                        }
                    }
                }
                return returnValue;
            },

            /**
             * 「周辺被害」ボタンによって、地図中のアイコンの表示有無を切り替える
             */
            showDamageReportMarkers: function(){
                // 市町コードが指定されていない場合は、ダイアログを表示して処理終了
                if (!this._municipalityCd) {
                    this.chain.info('市町を選んで検索してください。', 'エラー');
                    return false;
                }
                // フラグが立っていない場合は操作不可
                if (!this.enabledAroundDamageButton) {
                    return false;
                }
                // 地図が初期化されていない場合は操作せず返す
                if (!this.map) {
                    return false;
                }

                if (this.showMarkersFlg) {
                    this.map.removeLayer(this.layerGroup);
                    domStyle.set(this.showMarkersButton,'background-color','rgba(0, 0, 0, 0.7)');
                    this.showMarkersFlg = false;
                } else {
                    // マップの描画が追いつかない場合があるため、タイミングを調整
                    setTimeout(lang.hitch(this, function() {
                        this.map.addLayer(this.layerGroup);
                    }), 1000);
                    domStyle.set(this.showMarkersButton,'background-color','rgba(100, 100, 100, 0.7)');
                    this.showMarkersFlg = true;
                }
            },

            updateDamageReportMarkers: function() {
                if (this.showMarkersFlg && this.layerGroup) {
                    this.map.addLayer(this.layerGroup);
                }
            },

            /**
             * 周辺被害情報を取得する
             */
            fetchDamageReports: function() {
                var param = '?disasterId=' + this._disasterId;
                param += '&municipalityCd=' + this._municipalityCd;
                param += '&activeFlg=1&hasNoParentFlg=0';
                Requester.get('/api/damageReports' + param, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    this.enabledAroundDamageButton = true;
                    var items = data.items;
                    this.setDamageReportMarkers(items);
                    this.updateDamageReportMarkers();
                }), lang.hitch(this, function(error) {
                    this.chain.infoError(error);
                }));
            },

            /**
             * 周辺被害情報を地図に描画する
             */
            setDamageReportMarkers: function(items) {
                this.layerGroup = new leaflet.featureGroup();
                array.forEach(items, lang.hitch(this, function(item) {
                    if (item.latitude && item.longitude) {
                        var marker = this.makeDamageMarker(item);
                        this.layerGroup.addLayer(marker);
                    }
                }));
            },

            /**
             * 周辺被害情報のマーカーを生成する
             */
            makeDamageMarker: function(item){
                var lat = item.latitude;
                var lng = item.longitude;
                var lifeType = {
                    'elec1': item.elecMax,
                    'elec2': item.elecCurr,
                    'water1': item.watRedMax,
                    'water2': item.watRedCurr,
                    'water3': item.watFailMax,
                    'water4': item.watFailCurr,
                    'gas1': item.gusMax,
                    'gas2': item.gusCurr,
                    'phone1': item.telMax,
                    'phone2': item.telCurr
                };
                var lifeTypeNum = {};
                var type;
                var beforeKey = null;
                if(!lat || !lng){
                    return false;
                }

                //レコードの値から、imgファイル名上の値に変換する
                var urgencyType = '1';
                if(item.urgencyType !== null && item.urgencyType !== ''){
                    urgencyType = item.urgencyType;
                }
                var hldStatus = '01';
                if(item.hldStatus !== null && item.hldStatus !== ''){
                    if(item.hldStatus === '1' || item.hldStatus === '2'){
                        hldStatus = '02';
                    }else if(item.hldStatus === '3'){
                        hldStatus = '03';
                    }
                }
                var humanDamageFlg = item.humanDamageFlg;

                //メインアイコン
                var mainIcon = '/data/images/draw/icon/080.png';
                var html = [];
                var leftPosition = 0;
                if (item.damageType && (item.urgencyType || item.urgencyType==='0') && item.damageTypeList) {
                    for(var key in lifeType){
                        if(key){
                            //lifeType[key] に1以上の数字が入っている場合
                            if(!this.isEmpty(lifeType[key])){
                                //最初のkeyの場合
                                if(key === 'elec1'){
                                    lifeTypeNum[key] = lifeType[key];
                                }
                                // 2つ目以降のkeyの場合
                                // beforeKeyとkeyが同じ && 1つ前のlifeType[beforeKey]に値が入っていない場合
                                else if(key.slice(0, -1) === beforeKey.slice(0, -1) &&
                                    this.isEmpty(lifeType[beforeKey])){
                                    lifeTypeNum[key] = lifeType[key];
                                }
                                //beforeKeyとkeyが異なる場合
                                else if(key.slice(0, -1) !== beforeKey.slice(0, -1)){
                                    lifeTypeNum[key] = lifeType[key];
                                }
                            }
                            beforeKey = key;
                        }
                    }
                    array.forEach(item.damageTypeList.split(','), function(damageType, i){
                        //被害種別がライフラインの場合、被害箇所のiconを設定する
                        if(damageType === '02' && Object.keys(lifeTypeNum).length === 1){
                            for(var key2 in lifeTypeNum){
                                if(key2){
                                    type = key2.slice(0, -1);
                                    mainIcon = '/images/damage/type_' + damageType + '_'+
                                                type + '_urg_' + item.urgencyType + '.png';
                                }
                            }
                        }
                        else{
                            mainIcon = '/images/damage/type_' + damageType + '_urg_' + item.urgencyType + '.png';
                        }
                        leftPosition = 20 * i;
                        html += '<img src="' + mainIcon + '" height="35px" width="35px" style="position:absolute;top:' +
                            0 + 'px;left:' + leftPosition + 'px;">';
                    });

                }

                //人的被害アイコン
                var humanDamageIcon = '/images/damage/hmDmg_0.png';
                var leftFlgPosition = leftPosition + 18;
                var leftStatusPosition = leftPosition + 20;
                var iconSize = 20;
                if (humanDamageFlg !== null) {
                    humanDamageIcon = '/images/damage/hmDmg_' + humanDamageFlg + '.png';
                    iconSize = (humanDamageFlg === '0') ? 20 : 12;
                }
                html += '<img src="' + humanDamageIcon + '" height="' + iconSize + 'px" width="' + iconSize + 'px"';
                html += ' style="position:absolute;top:15px;left:' + leftFlgPosition + 'px;margin:2px">';

                //対応状況アイコン
                var hldStatusIcon = '/images/damage/status_' + hldStatus + '.png';
                html += '<img src="' + hldStatusIcon + '" height="12px" width="12px"';
                html += ' style="position:absolute;top:-4px;left:' + leftStatusPosition + 'px;">';

                var divIcon = leaflet.divIcon({
                    html: html,
                    className: 'damage-point',
                    iconSize: [0, 0],
                    iconAnchor: [3, 3]
                });

                var marker = leaflet.marker([lat, lng],{
                    icon: divIcon
                });

                return marker;
            },

            isEmpty: function(str) {
                if (str === void 0) {
                    return true;
                }
                if (str === null) {
                    return true;
                }
                if(str === ''){
                    return true;
                }
                if(str === '0'){
                    return true;
                }
                return false;
            },

            /**
             * 背景地図ダイアログを表示する。
             */
            showBaseLayerDialog: function() {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({map: this.map})
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            },

            /**
             * 凡例ダイアログを表示する。
             */
            showLegendDialog: function() {
                this.legendDialog.show();
            }
        }
    );
});
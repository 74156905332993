/**
 * 発令内容コードと色のマッピング
 * @module app/evacorder/consts/EvacorderTypeColorMap
 */
define({
    '01': '#FF2800',
    '02': '#FC002E',
    '03': '#AA00AA',
    '04': '#0C000C',
    '09': '#FFFF00',
    '90': '#808080',
    '11': '#FF2800',
    '13': '#AA00AA',
    '14': '#0C000C',
    '19': '#FFFF00',
    '80': '#808080'
});
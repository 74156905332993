/**
 * レイヤー情報を単に出力する詳細画面パーツ。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-geometry',
    'dojo/text!./templates/PhotoUploadDetail.html',
    'dojox/lang/functional/object',
    'idis/view/_IdisWidgetBase',
    'idis/store/IdisRest',
    // 以下、変数として受け取らないモジュール
    './PhotoUploadDetailGrid'
], function (module, declare, domGeom, template, df, _IdisWidgetBase, IdisRest) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        constructor: function () {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'photoId',
                target: '/api/photo/photo4Map'
            });
        },

        /**
         * DOMノードを生成する
         */
        buildRendering: function () {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            // グリッドの検索条件を指定された値で更新する
            this.updateGridQuery();
        },

        // DOMノード生成後に呼ばれる
        postCreate: function () {
            this.inherited(arguments);
            this.resize();
        },

        /**
         * グリッドを初期化する
         */
        initGrid: function () {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());
        },

        /**
         * リサイズ時にBorderContainerに追随する
         */
        resize: function (changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        /**
         * グリッドの検索条件を指定された値で更新する
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function () {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // レイヤーID
            if (this.item.id) {
                filter = filter.eq('layerId', this.item.id);
            }
            // 情報種別コード
            if (this.item.infoCategoryCd) {
                filter = filter.eq('infoCategoryCd', this.item.infoCategoryCd);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        }
    });
});

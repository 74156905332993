/**
 * 配信情報登録画面用モジュール。
 * @module app/sending/SendingRegisterPage
 */
define([
    'module',
    'app/model/DisasterInfo',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/SendingRegisterPage.html',
    'dojo/topic',
    'dojo/when',
    'dojo/Deferred',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './_SendingPageBase',
    'app/model/Organization',
    'app/provide/ProvideUtil',
    'idis/consts/USER_TYPE',
    'app/config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/OrganizationSelector',
    'app/sending/SendingConfirmDialog',
    'app/sending/SendingSelectorDialog',
    'app/view/form/CustomizableMunicipalitySelector'
    ], function(module, DisasterInfo, dojo, declare, lang, array, domStyle, json, template,
        topic, when, Deferred, Locator, Router, UserInfo, Requester, InfoDialog, Loader,
        _SendingPageBase, Organization, ProvideUtil, USER_TYPE, config) {
    /**
     * 配信情報新規登録画面。
     * @class SendingRegisterPage
     * @extends module:app/sending/_SendingPageBase~_SendingPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _SendingPageBase,
            /** @lends module:app/sending/SendingRegisterPage~SendingRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        _isEmergency: null, // true or false
        _deptCd: null,
        _sectCd: null,
        _unitCd: null,
        // ツイッター文字数カウント用
        _tweetNum: null,
        _lalertDetailType: null,

        TWITTER_MAX_LENGTH: 140,

        // 配信先市町の設定情報
        _sendingSetting: null,

        _municipalityCd: null,

        constructor: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);

            this._isEmergency = Locator.getQuery().emergency === '0' ? false : true;
            this._deptCd = UserInfo.getOrganization().deptCd;
            this._sectCd = UserInfo.getOrganization().sectCd;
            this._unitCd = UserInfo.getOrganization().unitCd;

            // 配信課
            // 報告部署にログインユーザの組織情報を設定
            var orgCd = (this._unitCd ? 'U' + this._unitCd :
                            this._sectCd ? 'S' + this._sectCd :
                            this._deptCd ? 'D' + this._deptCd : '');
            Organization.store.get(orgCd).then(
                lang.hitch(this, function(item){
                var _sendOrganizationName = item ? item.name : '取得失敗';

                this.sendOrganizationName.set('value',_sendOrganizationName);
            }));

            // タイトル切り替え
            if (this._isEmergency) {
                domStyle.set(this.sendingTitle, 'display', 'none');
                domStyle.set(this.urgentMailTitle, 'display', '');
            } else {
                domStyle.set(this.sendingTitle, 'display', '');
                domStyle.set(this.urgentMailTitle, 'display', 'none');
            }

            // テンプレート選択ダイアログに渡す市町コード
            this._municipalityCd = (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) ?
                UserInfo.getMunicipalityCd() || UserInfo.getMunicipalityCds()[0] : 
                (UserInfo.getUserType() === USER_TYPE.REGION) ? UserInfo.getRegionCd() : null;

            // 県/振興局の危機管理・一般ユーザは県のみ選択可能
            var role = UserInfo.getRoleCd();
            if (role === 'R01012' || role === 'R02012'){
                this.prefWholeArea.set('checked', true);
                this.municSelection.set('checked', false);
                this.prefWholeArea.set('disabled', true);
                this.municSelection.set('disabled', true);
            }
            //市町ユーザの場合、お知らせのみ選択可能、かつ自身の市町のみ選択可能
            else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                this.prefWholeArea.set('checked', false);
                this.municSelection.set('checked', true);
                this.prefWholeArea.set('disabled', true);
                this.municSelection.set('disabled', true);

                // 所属自治体を初期値とし、変更不可
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                this.municipalityCd.set('noAllButton', true);
            }

            this.changeDeliveryTarget();

            this._setMunicipalityChangedEvent();
            this._changeCheckBoxesVisibleIfEmergency();
            this.initTemplateDialog();
        },

        /**
         * サーバから配信先市町の設定を取得する
         * コールバック関数の引数には成功時は設定の値、失敗時はnullが入る
         * メンバ変数_sendingSettingに設定を代入する
         *
         * 呼ぶときは when(this._setSendingSetting(municipalityCd), function())
         */
        _setSendingSetting: function(municipalityCd) {
            var deferred = new Deferred();
            this._sendingSetting = null;
            if (!municipalityCd) {
                return deferred.promise;
            }
            municipalityCd = this._convertPrefectureCd(municipalityCd);
            var promise = Requester.get('/api/municipalities/sendingsetting/', {
                query: {
                    municipalityCds: municipalityCd
                }
            });
            Loader.wait(promise).then(lang.hitch(this, function(sendingSettings) {
                if (sendingSettings.length === 0) {
                    deferred.resolve(null);
                }
                this._sendingSetting = sendingSettings[0];
                deferred.resolve(this._sendingSetting);
            }), function() {
                // error
                deferred.resolve(null);
            });
            return deferred.promise;
        },

        /**
         * 市町に応じてチャネルのチェックボックスの表示・非表示を変更する。
         */
        _changeCheckBoxesVisible: function(municipalityCd) {
            if (!municipalityCd) {
                return;
            }
            when(this._setSendingSetting(municipalityCd),
                lang.hitch(this, function(sendingSetting) {
                    if (!sendingSetting) {
                        // 設定の取得に失敗した場合は非表示
                        this.twitter.checked = false;
                        this.yahoo.checked = false;
                        domStyle.set(this.dispTwitterCheckBox, 'display', 'none');
                        domStyle.set(this.dispYahooCheckBox, 'display', 'none');
                        return;
                    }

                    if (sendingSetting.twitterFlg){
                        domStyle.set(this.dispTwitterCheckBox, 'display', 'inline');
                    } else {
                        this.twitter.checked = false;
                        domStyle.set(this.dispTwitterCheckBox, 'display', 'none');
                    }

                    if (sendingSetting.yahooFlg){
                        domStyle.set(this.dispYahooCheckBox, 'display', 'inline');
                    } else {
                        this.yahoo.checked = false;
                        domStyle.set(this.dispYahooCheckBox, 'display', 'none');
                    }
                    
                })
            );
        },

        /*
         * 配信先市町が変更されたときのイベントを登録
         */
        _setMunicipalityChangedEvent: function() {
            this.municipalityCd.on('change', lang.hitch(this, function(evt) {
                this._changeCheckBoxesVisible(evt.value);
            }));
        },

        /*
         * 緊急速報メールの初期表示をセット
         */
        _changeCheckBoxesVisibleIfEmergency: function() {
            if (this._isEmergency) {
                domStyle.set(this.dispEmergencyMailCheckBox, 'display', 'inline');
                this.changeDeliveryTarget();
            }else{
                this.emergencyMail.set('checked', false);
                domStyle.set(this.dispEmergencyMailCheckBox, 'display', 'none');
            }
        },

        /*
         * チャネル別詳細設定を表示
         */
        showChannelDetails: function() {
            domStyle.set(this.inputGuide, 'display', 'none');
            var isChecked = false;
            // 緊急速報メール
            if (this._isEmergency && this.emergencyMail.checked) {
                isChecked = true;
                domStyle.set(this.inputEmergencyMail, 'display', 'block');
                this.emergencyMailSubject.set('value', this.subject.displayedValue);
                this.emergencyMailBody.set('value', this.bodyText.displayedValue);
            } else {
                domStyle.set(this.inputEmergencyMail, 'display', 'none');
            }

            // Lアラート
            if (this.lalert.checked) {
                isChecked = true;
                domStyle.set(this.inputLalert, 'display', 'block');
                this.onChangeLalertCategorySelect();
            } else {
                domStyle.set(this.inputLalert, 'display', 'none');
            }

            // Twitter
            if (this.twitter.checked) {
                isChecked = true;
                domStyle.set(this.inputTwitter, 'display', 'block');
                var twitterBodyText = '【' + this.subject.displayedValue + '】\n'+ this.bodyText.displayedValue;

                // tweetを全て削除
                var element = dojo.byId('twitterOuterFrame');
                while (element.firstChild) {
                    element.removeChild(element.firstChild);
                }

                // tweet必要枠の計算
                this._tweetNum = Math.ceil(this.getLen(twitterBodyText) / this.TWITTER_MAX_LENGTH);
                var connectWordLengthFirst = 4;
                var connectWordLengthLast = 3;
                var connectAllLength = connectWordLengthFirst + connectWordLengthLast;
                if (this._tweetNum > 1) {
                    // 「（続く）」分の文字数を考慮して枠を追加
                    this._tweetNum = Math.ceil(((this._tweetNum-1) * connectAllLength +
                                                    this.getLen(twitterBodyText)) / this.TWITTER_MAX_LENGTH);
                }
                var words = 0;
                for (var i = 0; i < this._tweetNum; i++) {
                    var oneTweetContentLength = this.TWITTER_MAX_LENGTH - connectWordLengthLast - 1;
                    if (i !== 0) {
                        oneTweetContentLength -= connectWordLengthFirst;
                    }
                    var oneTweetText = twitterBodyText.substr(words, oneTweetContentLength);
                    this.addTweet(oneTweetText, i + 1);
                    words += oneTweetContentLength;
                }
            } else {
                domStyle.set(this.inputTwitter, 'display', 'none');
            }

             // Yahoo
            if (this.yahoo.checked) {
                isChecked = true;
                domStyle.set(this.inputYahoo, 'display', 'block');
                var yahooBodyText = '【' + this.subject.displayedValue + '】\n'+ this.bodyText.displayedValue;
                this.yahooBody.set('value', yahooBodyText);
                if (yahooBodyText.length > 750){
                    InfoDialog.show('', 'Yahooに連携可能な文字数を超えています');
                }
            } else {
                domStyle.set(this.inputYahoo, 'display', 'none');
            }

            // チェックが付いていなければガイドを表示
            if (!isChecked){
                domStyle.set(this.channelSelectWarning, 'display', 'block');
            }else{
                domStyle.set(this.channelSelectWarning, 'display', 'none');
            }
        },

        addTweet: function(oneTweetText, i) {
            // +ボタンで追加した場合
            if (oneTweetText.type === 'click') {
                oneTweetText = '';
                this._tweetNum += 1;
            } else {
                // 最終ツイート以外に「続く」を入れる
                if (i !== this._tweetNum) {
                    oneTweetText = oneTweetText + '(続く)';
                }
            }
            // 最初ツイート以外に「続き」を入れる
            if (i !== 1) {
                oneTweetText = '(続き)' + oneTweetText;
            }
            this.createTweetElements(oneTweetText, i);
        },

        /**
         * 配信情報一覧画面へ遷移する。
         */
        onSendingPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('sending');
        },

        toggleMunicSelector: function(){
            if(this.prefWholeArea.get('checked')){ // 「県」が選ばれていた場合
                domStyle.set(this.municCdSelector, 'display', 'none');
                this._changeCheckBoxesVisible(config.municInfo.prefMunicCd);
            } else {
                domStyle.set(this.municCdSelector, 'display', '');
                this._changeCheckBoxesVisible(this.municipalityCd.get('value'));
            }
        },

        /**
         * 配信対象を切り替え時、対象市町の表示切り替えをする。
         * 対象市町は、「お知らせ」と「市町別」が選択されている時のみ表示。
         */
        changeDeliveryTarget: function(){
            // IEだと、ラジオボタンのCHANGEイベントを拾おうとすると、
            // ラジオボタンチェックを「変更する前」のチェック状態を見てしまうので、正しい動きができない。
            // よって、timeoutでずらしている。
            setTimeout(lang.hitch(this, this.toggleMunicSelector), 100);
        },

        /**
         * テンプレートダイアログを初期化する
         */
        initTemplateDialog: function() {
            // ダイアログ中のページウィジェットを取得
            var page = this.dialog.getChildren()[0];
            page.initContent(this._isEmergency, this._municipalityCd);

            // ダイアログ側でOKボタンが押された際のイベント
            page.on('update', lang.hitch(this, function(evt) {
                // テンプレート変更
                this._changeTemplate(evt.data);
                // ダイアログを非表示にする
                this.dialog.hide();
            }));
        },
        /**
         * テンプレートダイアログを表示する
         */
        showTemplateDialog: function() {
            // ダイアログを表示
            this.dialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.dialog.getChildren()[0];
            // ダイアログの画面を再表示する
            page.refresh();
        },

        /**
         * 県のコードを変換する。
         *
         * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
         * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
         * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
         * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町コードでなければ登録できない。
         */
        _convertPrefectureCd: function(municipalityCd) {
            if (municipalityCd === config.municInfo.prefCd) {
                 return config.municInfo.prefMunicCd;
            }
            return municipalityCd;
        },

        /**
         * 配信確認ダイアログを表示する
         */
        showConfirmDialog: function() {

            //市町がセットされているかチェック
            if (this.form.value.deliveryTarget === '2') {
                if (this.municipalityCd.value.length === 0) {
                    InfoDialog.show('', '対象市町が選択されていません');
                    return;
                }
            }

            // 配信先を選択しているかチェック
            if (!(this.emergencyMail.checked ||
                  this.lalert.checked ||
                  this.twitter.checked ||
                  this.yahoo.checked)) {
                InfoDialog.show('', '配信先チャンネルを選択してください。');
                return;
            }

            // チェックボックスとチャネル詳細が合致しているかチェック
            if (this._invalidChannelDetailDisplay()) {
                InfoDialog.show('', '「チャンネル別詳細設定ボタン」を<br>押して詳細を確認してください。');
                return;
            }

            // 緊急速報メールの内容チェック
            if (this._isEmergency && this.emergencyMail.checked) {
                if (!ProvideUtil.urgentMailValidate(this.emergencyMailSubject.value)) {
                    InfoDialog.show('', '緊急速報メールの件名に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }
                if (!ProvideUtil.urgentMailValidate(this.emergencyMailBody.value)) {
                    InfoDialog.show('', '緊急速報メールの本文に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }
                if (this.emergencyMailBody.value === '') {
                    InfoDialog.show('入力チェック', '緊急速報メールの本文が入力されていません');
                    return;
                }
                if (this.emergencyMailSubject.value === '') {
                    InfoDialog.show('入力チェック', '緊急速報メールの件名が入力されていません');
                    return;
                }
                // 未来かどうかを判定
                if (new Date() < new Date(this.form.value.sendTimestamp)) {
                    InfoDialog.show('入力チェック', '緊急速報メールでは配信日時に未来の時間は設定できません');
                    return;
                }
            }

            //Lアラート
            if (this.lalert.checked){
                if (!this.subject.value.trim()) {
                    InfoDialog.show('入力チェック', '件名が入力されていません');
                    return;
                }
                if (!this.bodyText.value.trim()) {
                    InfoDialog.show('入力チェック', '本文が入力されていません');
                    return;
                }
            }

            //twitter
            if (this.twitter.checked){
                // テキストエリアの残り文字数計算
                var element = dojo.byId('twitterOuterFrame');
                var elements = element.getElementsByTagName('textarea');
                for (var i = 0; i < elements.length; i++) {
                    var text = elements[i].value;
                    // 配信内容が空の時にエラーメッセージの表示
                    if (this.getLen(text) === 0){
                        InfoDialog.show('','Twitterの配信内容が空です。配信内容を記載してください。');
                        return;
                    }
                    // 文字数制限を超えている時にエラーメッセージの表示
                    var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
                    if (words < 0){
                        InfoDialog.show('','Twitterの文字数制限を超えています。各記入枠の文字数を制限内に抑えてください。');
                        return;
                    }
                }
            }

            // yahoo
            if (this.yahoo.checked) {
                if (this.yahooBody.value === '') {
                    InfoDialog.show('入力チェック', '防災アプリ(Yahoo防災速報)の配信内容が入力されていません');
                    return;
                }
            }

            // フォームの入力チェック
            if (!this.form.validate()) {
                return;
            }

            // ダイアログを表示
            this.confirmDialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.confirmDialog.getChildren()[0];
            // ダイアログ側で「一括配信」ボタンが押された際のイベント
            page.on('register', lang.hitch(this, function(data) {
                // 登録
                return this.register(data.sendTimestamp);
            }));
            page.refresh(this);
        },

        /**
         * チェックボックスとチャネル詳細が合致していないことをチェックする
         * 合致していない場合trueを返す
         */
        _invalidChannelDetailDisplay: function() {
            var objs = [
                {display: this.inputEmergencyMail.style.display,
                 checked: this.emergencyMail.checked},
                {display: this.inputLalert.style.display,
                 checked: this.lalert.checked},
                {display: this.inputTwitter.style.display,
                 checked: this.twitter.checked},
                {display: this.inputYahoo.style.display,
                 checked: this.yahoo.checked}
            ];
            for (var i = 0; i < objs.length; i++) {
                var dispState = objs[i].display !== 'block' ? false : true;
                if (dispState !== objs[i].checked) {
                    return true;
                }
            }
            return false;
        },

        /**
         * 配信情報を登録する。
         */
        register: function(sendTimestamp) {
            var sendData = {};
            sendData.municipalityCdList=[];

            /* 共通部分 */
            sendData.disasterId = DisasterInfo.getDisasterId();
            sendData.infoType = '10'; // '10'「お知らせ」決め打ち
            sendData.deptCd = this._deptCd;
            sendData.sectCd = this._sectCd;
            sendData.unitCd = this._unitCd;
            sendData.sendOrganizationName = this.sendOrganizationName.get('value');
            sendData.sendTimestamp = sendTimestamp;
            sendData.subject = this.subject.get('value');
            sendData.bodyText = this.bodyText.get('value');
            sendData.senderName = this.senderName.get('value');

            if(this.prefWholeArea.get('checked')){ // 県全体の場合
                sendData.municipalityCdList.push(config.municInfo.prefMunicCd);
                sendData.municipalityCd = sendData.municipalityCdList[0];
            } else {
                sendData.municipalityCdList.push(this.municipalityCd.get('value'));
                sendData.municipalityCd = sendData.municipalityCdList[0];
            }

            /* チャネル別詳細設定 */
            // 緊急速報メール
            if (this._isEmergency) {
                sendData.urgentMailFlg = '1';
                var emergencyMailMunicipalityCd = config.municInfo.prefMunicCd;
                sendData.emergencyMailMunicipalityCd = this._convertPrefectureCd(emergencyMailMunicipalityCd);
                sendData.emergencyMailSubject = this.emergencyMailSubject.get('value');
                sendData.emergencyMailBody = this.emergencyMailBody.get('value');
            } else {
                sendData.urgentMailFlg = '0';
            }

            // Lアラート
            if (this.lalert.checked) {
                sendData.lalertFlg = '1';
                sendData.publisher = this.publisher.get('value');
                sendData.sendCategory ='04'; // '04'「お知らせ」決め打ち
                sendData.lalertUrgency = this.lalertUrgency.get('value');
                sendData.lalertCategory = this.lalertCategory.get('value');
                sendData.sendingDetailType = this.sendingDetailType.get('value');
                sendData.lalertChangeType = null;
                sendData.lalertReason = null;
                sendData.lalertSendHistId = null;
                sendData.lalertSubject = null;
                sendData.lalertBodyText = null;
            } else {
                sendData.lalertFlg = '0';
            }

            // Twitter
            if (this.twitter.checked) {
                sendData.twitterFlg = '1';
                var tweetData = this.twitterOuterFrame.getElementsByTagName('textarea');
                var tweetList = [];
                array.forEach (tweetData, function(data) {
                    tweetList.push(data.value);
                });
                sendData.tweetList = tweetList;
            } else {
                sendData.twitterFlg = '0';
            }

            // Yahoo
            if (this.yahoo.checked) {
                sendData.yahooFlg = '1';
                sendData.yahooBody = this.yahooBody.get('value');
            } else {
                sendData.yahooFlg = '0';
            }

            // 最終確認を行う際のメッセージ文字列
            var lastConfirmMsg = '';
            lastConfirmMsg += this._isEmergency ? '・緊急速報メール' : '';
            lastConfirmMsg += this.lalert.checked ? '・Lアラート' : '';
            lastConfirmMsg += this.twitter.checked ? '・Twitter' : '';
            lastConfirmMsg += this.yahoo.checked ? '・防災アプリ(Yahoo)' : '';
            lastConfirmMsg += 'に連携します。<br>よろしいですか？';
            lastConfirmMsg = lastConfirmMsg.slice(1);
            lastConfirmMsg = '<div style="text-align:center;">' + lastConfirmMsg + '</div>';

            // 登録確認ダイアログを表示
            return this.chain.confirm(lastConfirmMsg, function(chain) {
                var promise = Requester.post('/api/sending', {
                    data: json.stringify(sendData),
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    chain.infoComplete(function() {
                        this.confirmDialog.hide();
                        // 一覧画面に移動
                        Router.moveTo('sending');
                    });
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    chain.infoError(error);
                    this.confirmDialog.hide();
                }));
                // ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * テンプレートを変更する
         */
        _changeTemplate: function(data) {
            // 選択されたテンプレートを画面へセット
            this.subject.set('value', data.subject);
            this.bodyText.set('value', data.bodyText);
            this.lalertCategory.set('value',Object.keys(data.lalertCategory)[0][0]);
            this.sendingDetailType.set('value',Object.keys(data.lalertCategory)[0]);
            this.lalertUrgency.set('value',Object.keys(data.lalertUrgency));
            this._lalertDetailType = Object.keys(data.lalertCategory)[0];

            // 選択しているテンプレートIDを設定する。
            topic.publish(module.id + '::selected', data.admNum);
        },

        /**
         * メンバ変数_sendingSettingからtwitterIdとして表示する文字列を返す
         */
        getTwitterIdStr: function() {
            return this._sendingSetting.municipalityName + ' @' + this._sendingSetting.twitterId;
        },

        /**
         * 選択可能な全てのチャネルを選択する
         */
        checkAllChannels: function() {
            if (this._isEmergency) {
                this.emergencyMail.set('checked', true);
            }
            this.lalert.set('checked', domStyle.get(this.dispLalertCheckBox).display !== 'none');
            this.twitter.set('checked', domStyle.get(this.dispTwitterCheckBox).display !== 'none');
            this.yahoo.set('checked', domStyle.get(this.dispYahooCheckBox).display !== 'none');
        },

        /**
         * 全てのチャネルを未選択にする
         */
        uncheckAllChannels: function() {
            if (!this._isEmergency){
                this.emergencyMail.set('checked',false);
            }
            this.lalert.set('checked',false);
            this.twitter.set('checked',false);
            this.yahoo.set('checked',false);
        }
    });
});

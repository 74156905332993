/**
 * Lアラート避難情報詳細ダイアログ。
 * @module app/provide/ProvideEvacOrderDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/ProvideEvacOrderDetailDialog.html',
    'app/provide/_ProvideDialogBase',
    'dstore/Memory',
    'idis/util/DateUtils',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/DisasterSelector',
    'app/provide/ProvideEvacOrderGrid'
], function(module, declare, lang, array, template, _ProvideDialogBase, Memory, DateUtils) {
    /**
     * Lアラート避難情報登録ダイアログ画面。
     * @class ProvideEvacOrderRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), [_ProvideDialogBase],
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ダイアログを開く際に設定する。
        contentType: null,

        // 避難対象地区グリッド用のストア
        gridStore: null,

        // ダイアログで保持する市町村コード
        _municipalityCd: null,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.inherited(arguments);
            this.gridStore = new Memory();
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },
        
        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this.municipalityCd._setValueAttr(obj.municipalityCd);
            // 配信日時をセットする。
            var sendTimestamp = new Date(obj.sendTimestamp);
            this.evacTimestampNode.innerHTML = DateUtils.format(sendTimestamp);
            // 補足情報
            this.complementaryInfo._setValueAttr(obj.complementaryInfo);

            // 災害名をセットする。
            this.disasterNameNode.set('value',obj.disasterId);
            // 避難情報発令日時をセットする。
            var evacTimestamp = new Date(obj.evacOrderTimestamp);
            this.evacTimestampNode.innerHTML = DateUtils.format(evacTimestamp);
            // 発令理由をセットする。
            this.issueReasonTypeNode.innerHTML = obj.evacuateReason;
            // 対象世帯数をセットする。
            this.evacHouseholdNumNode.innerHTML = obj.evacHouseholdNum + '(世帯)';
            // 対象世帯数をセットする。
            this.evaqueeNumNode.innerHTML = obj.evaqueeNum + '(人)';
            // 避難行動指針
            this.evacuateGuidelineNode.innerHTML = obj.evacuateGuideline;
            // 対象地区に関する情報はグリッドで表示する。
            this.initGrid(obj);
        },

        // 避難対象地区グリッドを初期表示する。
        initGrid: function(obj) {
            this.evacOrderGrid.set('collection', new Memory({
                // グリッド格納時にIDが付与されてしまうので、元データを変更させないようコピーを格納する
                data: array.map(obj.items, function(item, i) {
                    return lang.mixin({id:i}, item);
                })
            }));
        }
    });
});

/**
 * 掲示板登録画面用モジュール。
 * @module app/bbs/BbsRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/promise/all',
    'dojo/dom-style',
    'dojo/text!./templates/BbsRegisterPage.html',
    './_BbsPageBase',
    '../config',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'dojox/lang/functional/array',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/TitlePane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/WordCountTextarea',
    'app/view/form/ShareOrgsMultiSelector4Chronology'
], function(module, declare, lang, array, all, domStyle, template, _BbsPageBase, config,
    Router, DialogChain, UserInfo, IdisRest, Loader, df, DisasterInfo) {

    /**
     * 掲示板登録画面
     * @class BbsRegisterPage
     * @extends module:app/bbs/_BbsPageBase~_BbsPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BbsPageBase,
    /** @lends module:app/bbs/BbsRegisterPage~BbsRegisterPage# */ {
        // テンプレート文字列
        templateString: template,
        
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--bbs',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        constructor: function() {
            // データ登録用オブジェクト
            this.store = new IdisRest({
                idProperty: 'bbsId',
                target: '/api/bbses/register'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 宛先セレクターに「全体」をセットする
            this.shareMunicipalityCd.set('value', [0]);
        },

        // DOMノード生成後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            console.debug('BbsRegisterPage#startup()');
            this.inherited(arguments);
            this.attachFileList = [];
            // 未読通知掲載期限を非表示
            domStyle.set(this.pubEndTimestampArea, 'display', 'none');
            // 新規登録・詳細画面で利用するTipsをセットする
            this.setTipsPopups();
        },

        onSubmit: function() {

            var sendData = this.form.get('value');

            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }

            // フォームのバリデーションを実施（独自チェック）
            // 入力チェック（未読通知掲載期限）
            if (sendData.notifyFlg === '02' && sendData.pubEndTimestamp === null) {
                this.chain.info('未読通知掲載期限を入力してください。', '入力エラー');
            return false;
            }
            // 入力チェック（宛先）
            if(sendData.shareMunicipalityCd.length < 1) {
                this.chain.info('宛先を選択してください。', '入力エラー');
                return false;
            }

            // 災害IDをセット
            sendData.disasterId = this._disasterId;

            var bbsShareOrgs = [];

            // セレクタからフルチェックされた自治体コードを取得する
            var shareMap = this.shareMunicipalityCd.tree._checkMap;
            var excludeMap = {};
            var tree = this.shareMunicipalityCd.tree;
            var isMunicipality = false;
            df.forIn(shareMap, function(item) {
                if(tree.isEveryChildChecked(item)){
                    // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                    // idが4桁未満のものはアイテムをまとめるダミーなので除外し、
                    // 4桁以上のもののみ処理を行う
                    if (tree.model.store.getIdentity(item).length >= 4) {
                        // セレクタにて子要素が全てチェックされている場合、子要素を除外リストに追加
                        tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                            if(!items){
                                return false;
                            }
                            array.forEach(items, function(item){
                                excludeMap[tree.model.store.getIdentity(item)] = true;
                            }, this);
                        }));
                    }
                }
            }, this);
            // 最下層のチェックを取得する
            // 合わせて要請時の宛先に組織が設定されているかチェック
            df.forIn(shareMap, function(item) {
                var cd = tree.model.store.getIdentity(item);
                // 除外リストに設定されていなければ登録
                // 親もこのタイミングで登録される
                // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                // idが4桁未満のものはアイテムをまとめるダミーなので除外し、
                // 4桁以上のもののみ処理を行う
                if(cd.length >= 4 && !excludeMap[cd] && cd !== '$ROOT$'){
                    bbsShareOrgs.push(item);
                    if (cd.substring(0, 1) !== 'D') {
                        // 部でない情報が登録されている
                        isMunicipality = true;
                    }
                }
            }, this);

            // 共有先情報を設定する。
            var bbsShares = [];

            // 項目ごとに処理
            all(
                array.map(bbsShareOrgs, function(org){
                    // 各アイテムの自治体要素を取得する
                    return this.getRegionMunicipalityInfo(this.shareMunicipalityCd.getRegionMunicipalityInfo(org).id);
                }, this)
            ).then(lang.hitch(this, function(municInfos){
                // 共有先組織の数だけループする
                array.forEach(municInfos, function(infos, index){
                    // 仮想ルートと全体を宛先から除く
                    if(infos && infos.id !== '$ROOT$' && infos.id !== '0'){
                        var share = {};
                        var shareOrg = {};
                        share.senderFlg = '0';
                        share.destinationFlg = '1';
                        // 組織情報をセット
                        shareOrg = this.setOrganizationFromTreeObject(bbsShareOrgs[index]);
                        // 組織が設定されていれば市町 + 組織名、市町のみであれば市町名
                        shareOrg.name = shareOrg.deptCd && shareOrg.deptCd !== null ?
                            infos.name + shareOrg.name : shareOrg.name;
                        // 部・課・係コード・最下層組織コードを設定する
                        share.deptCd = shareOrg.deptCd;
                        share.sectCd = shareOrg.sectCd;
                        share.unitCd = shareOrg.unitCd;
                        share.organizationCd = shareOrg.unitCd ? shareOrg.unitCd :
                            shareOrg.sectCd ? shareOrg.sectCd : shareOrg.deptCd;

                        // 市町コード(振興局はnull)
                        share.municipalityCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                            infos.id !== config.municInfo.prefMunicCd ? null : infos.id;
                        // 振興局コード(振興局以外はnull)
                        share.regionCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                            infos.id !== config.municInfo.prefMunicCd ? infos.id : null;
                        // 自治体名 + 組織名
                        share.shareName = shareOrg.name;
                        bbsShares.push(share);
                    }
                }, this);

                //  共有先情報をセットする
                sendData.bbsShares = bbsShares;

                //添付ファイルIDをセット
                if(this.attachFileList) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].bbsAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }

                delete sendData.attachFile;
                delete sendData.shareMunicipalityCd;

                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(sendData)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('bbs');
                        });
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        // 未読通知掲載期限を表示・非表示
        onNotifyFlgChange: function(){
            var notifyFlg = this.form.get('value').notifyFlg;
            // '01'=通知しない、'02'=通知する
            if(typeof notifyFlg === 'undefined' || notifyFlg === null) {
                domStyle.set(this.pubEndTimestampArea, 'display', 'none');
            } else if(notifyFlg === '01') {
                domStyle.set(this.pubEndTimestampArea, 'display', 'none');
            } else if(notifyFlg === '02'){
                domStyle.set(this.pubEndTimestampArea, 'display', '');
            } else {
                domStyle.set(this.pubEndTimestampArea, 'display', 'none');
            }
        },

        onBbsLinkClick:function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('bbs');
        }

    });
});
/**
* 体制情報画面用モジュール。
* @module app/view/page/DisasterPreventionStatusListGrid
*/
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/_base/array',
	'dojo/date/locale',
	'dojox/lang/functional/object',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
	// 以下、変数として受け取らないモジュール
	'dojox/lang/functional/array'
], function (module, declare, lang, array, locale, df, IdisGrid, helper) {
	return declare(module.id.replace(/\//g, '.'), IdisGrid, {
		/**
		 * 各列の定義
		 * @type {Object[]}
		 */
		columns: [
			helper.buttonColumn('detail', '詳細'),
			{
				field: '_item', label: '災害種別', sortable: false,
				formatter: lang.hitch(this, function (item) {
					var type = null;
					switch (item.disasterType) {
						case '01':
							type = '風水害';
							break;
						case '02':
							type = '地震・津波';
							break;
						case '05':
							type = '火山';
							break;
						case '04':
							type = '国民保護';
							break;
						case '99':
							type = 'その他';
							break;
					}
					return type;
				})
			},
			{
				field: '_item', label: '本部種別', sortable: false,
				formatter: lang.hitch(this, function (item) {
					var type = null;
					switch (item.hqType) {
						case '01':
							type = '対策本部';
							break;
						case '02':
							type = '警戒本部';
							break;
						case '03':
							type = 'その他本部';
							break;
						case '98':
							type = '未設置';
							break;
						case '99':
							type = '体制解除';
							break;
					}
					return type;
				})
			},
			helper.column('level', 'レベル', { sortable: false }),
			helper.column('label', '体制状況', { sortable: false }),
			{
				field: '_item', label: '本部設置', sortable: false,
				formatter: lang.hitch(this, function (item) {
					var type = null;
					switch (item.hqType) {
						case '01':
							type = 'あり(災害対策本部)';
							break;
						case '02':
							type = 'あり(災害警戒本部)';
							break;
						case '03':
							type = 'あり';
							break;
						case '98':
						case '99':
							type = 'なし';
							break;
					}
					return type;
				})
			},
			{
				field: '_item', label: '本部名称', sortable: false,
				formatter: lang.hitch(this, function (item) {
					if (item.hqName) {
						return item.hqName;
					} else {
						return '-';
					}
				})
			}
		]
	});
});

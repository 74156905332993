/**
 * 流域平均雨量詳細情報のグラフ
 * @module app/riverBasin/chart/RiverBasinRainfallHistoryChart
 */
define([
    'module',
    'app/view/chart/ChartCommon',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/Chart',
    'dojox/charting/plot2d/ClusteredColumns',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/widget/Legend',
    //
    'dojox/charting/plot2d/Grid',
    'dojox/charting/axis2d/Default'
], function(
    module, ChartCommon, array, declare, lang,
    Tooltip, Chart, ClusteredColumns, Theme, Legend
) {

    /**
     * 流域平均雨量情報一覧画面
     * @class RiverBasinRainfallHistoryChart
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:app/riverBasin/graph/RiverBasinRainfallHistoryChart~RiverBasinRainfallHistoryChart# */
    {
        title: '流域平均雨量',
        legend: {},

        /**
         * Y軸最大値
         */
        maxRainfallYAxis: 30, // 10分or時間雨量のY軸最大値
        maxCumulativeYAxix: 300, // 累加雨量のY軸最大値

        /**
         * グラフのノードを生成する
         * @param {*} node グラフを入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} legendNode 凡例を入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} options.mode 検索条件の時間モード
         * @param {*} options.data 詳細GridのCollectionのデータ
         */
        create: function(node, legendNode, options) {
            var modeLabel = '';

            // 雨量データを初期化
            var rainfallChartData = [0,0];

            if(options){
                // 引数を変数にセット
                lang.mixin(this, options);

                // 再作成の場合、表を破棄
                ChartCommon.destroyChart(this.chart);

                // タイトルをセット
                modeLabel = {
                    '10' : '10分',
                    '30' : '30分',
                    '60' : '時間'
                }[this.mode];

                // 雨量データを取得
                rainfallChartData = this.getRainfallChartData();

                // Y軸の最大値をセット
                this.setMaxYAxis(rainfallChartData);
            }

            this.chart = new Chart(node, {
                title: modeLabel + this.title,
                titlePos: 'top',
                titleGap: 10
            });

            // グラフのデザインテーマ, 他のグラフ群と合わせる
            this.chart.setTheme(Theme);

            // 縦軸と横軸の最大値、横軸のラベル名等を定義
            ChartCommon.addDataTimestampXAxis(this.chart, this.data);

            this.chart.addAxis('y', {
                min: 0,
                max: this.maxRainfallYAxis,
                vertical: true,
                fixLower: 'minor',
                fixUpper: 'minor'
            });

            // 10分雨量の線グラフを追加
            this.chart.addPlot('rainfall10Min', {
                type: ClusteredColumns,
                markers: true,
				gap: 5,
                vAxis: 'y'
            });
            this.chart.addSeries('流域平均雨量',
                rainfallChartData,
                {
                    plot: 'rainfall10Min'
            });
            this.rainfall10MinTooltip = new Tooltip(this.chart, 'rainfall10Min');

			ChartCommon.addGridPlot(this.chart);

            this.chart.render();

            // プロットの凡例を表示
            // 初回起動時のみ生成
            if (!this.legend.id) {
                this.legend = new Legend({chart: this.chart, horizontal: true }, legendNode);
            }
            this.legend.set('chart', this.chart);
            this.legend.refresh();
            ChartCommon.setAxisTitle(this.chart, 'y', '流域平均雨量[mm/h]');
        },

        /**
         * 雨量のグラフデータを返す
         */
        getRainfallChartData: function() {
            if(!this.data){
                return [0,0];
            }
            var chatData = [];
            array.forEach(this.data, function(item, i){
                chatData.push({
                    x: i + 1,
                    y: isFinite(item.rainfall60Min) ?
                            item.rainfall60Min : '0'
                });
            });
            return chatData;
        },

        /**
         * 累加雨量のグラフデータを返す
         */
        getRainfallCumulativeChartData: function() {
            if(!this.data){
                return [0,0];
            }
            var chatData = [];
            array.forEach(this.data, function(item, i){
                chatData.push({
                    x: i + 1,
                    y: isFinite(item.rainfallCumulative) ?
                            item.rainfallCumulative : '0'
                });
            });
            return chatData;
        },

        /**
         * Y軸最大値をセット
         */
        setMaxYAxis: function(rainfallChartData){
            // 10or60雨量最大値を取得
            array.forEach(rainfallChartData, lang.hitch(this, function(value, i){
                var rainfall = isFinite(value.y)&&value.y!==null ? value.y : '0';
                if (i===0){
                    this.maxRainfallYAxis = rainfall;
                }else{
                    this.maxRainfallYAxis = this.maxRainfallYAxis>=rainfall ?
                        this.maxRainfallYAxis : rainfall;
                }
            }));
            this.maxRainfallYAxis = this.maxRainfallYAxis>0 ?
                this.maxRainfallYAxis : 30;
            // 累加雨量最大値を取得
            array.forEach(this.data, lang.hitch(this, function(item, i){
                var rainfallCumulative = isFinite(item.rainfallCumulative)&&item.rainfallCumulative!==null ?
                    item.rainfallCumulative : '0';
                if (i===0){
                    this.maxCumulativeYAxix = rainfallCumulative;
                }else{
                    this.maxCumulativeYAxix = this.maxCumulativeYAxix>=rainfallCumulative ?
                        this.maxCumulativeYAxix : rainfallCumulative;
                }
            }));
            this.maxCumulativeYAxix = this.maxCumulativeYAxix>0 ?
                this.maxCumulativeYAxix : 300;
        }
    });
});

/**
 * 防災体制・報告の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/disasterprevention/view/_DisasterPreventionDialogPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'exif-js/exif',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './model/DisasterType',
    './model/HqSetFlg',
    './model/HqType',
    '../../config',
    'dojo/store/Memory',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector',
    'app/disasterprevention/view/DisasterPreventionConfirmDialog'
], function(module, declare, lang, domClass, domConstruct, domStyle, on, iframe,
    Menu, MenuItem, popup, TooltipDialog, exif, Requester, IdisRest, UserInfo, DisasterInfo,
    _PageBase, InfoDialog, Loader, DisasterType, HqSetFlg, HqType, config, Memory) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 災害種別
         */
        _disasterType: null,

        /**
         * 呼び出し元
         */
        _parent: null,

        /**
         * 配信対象フラグ
         */
        _provideFlg: false,

        /**
         * 選択のための体制一覧
         */
        _prevStatusStore: null,

        /**
         * constructor
         */
        constructor: function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
            }

            //災害情報を取得する
            Requester.get('/api/disasters/' + this._disasterId)
            .then(lang.hitch(this, function(res) {
                this._disasterType = res.disasterType;
            }));
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var infoDialogMsg = null;
            // 報告日時は必須
            if(!this.reportTimestamp.validate() || !this.reportTimestamp._date.displayedValue ||
                    !this.reportTimestamp._time.displayedValue) {
                infoDialogMsg = '「報告日時」';
            }
            // 本部設置・解除の場合、設置日時は必須
            if(domStyle.get(this.hqSetTimestampArea, 'display')!=='none' &&
                    (!this.hqSetTimestamp.validate() ||
                    !this.hqSetTimestamp._date.displayedValue ||
                    !this.hqSetTimestamp._time.displayedValue)) {
                infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                infoDialogMsg += '「設置日時」';
            }
            // 本部解除の場合、解除日時は必須
            if(this.hqAbolishedTimestampArea &&
                !(this._lastDetailItem.seqNum === 1 && this._updateFlg) &&
                domStyle.get(this.hqAbolishedTimestampArea, 'display')!=='none' &&   // 第一報の訂正ではないこと
                    (!this.hqAbolishedTimestamp.validate() ||
                    !this.hqAbolishedTimestamp._date.displayedValue ||
                    !this.hqAbolishedTimestamp._time.displayedValue)) {
                infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                infoDialogMsg += '「廃止日時」';
            }
            // 第一報の訂正の場合、解散不可
            if (this.form.get('value').hqSetFlg === HqSetFlg.RELEASE &&
                this._lastDetailItem.seqNum === 1 && this._updateFlg) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : '体制が解散された場合は、続報登録してください。<br>体制の設置が誤りだった場合は取消を実施してください。'
                });
                this.infoDialog.show();
                return false;
            }
            if(infoDialogMsg!==null){
                infoDialogMsg += 'が入力されていません';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }else if(!this.form.isValid()) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                return false;
            }

            return true;
        },

        /**
         * 本部設置情報入力欄表示切替え、諸々の変数を初期化する
         * 切替え要否の判定は当ファンクション呼び出し元にて実施すること
         * @param {*} item 変更後の体制情報オブジェクト
         */
        _setHqInfNode: function(item) {
            // 変数の初期化
            this.hqSetTimestamp.set('value', null);
            if (this.hqAbolishedTimestamp) {
                this.hqAbolishedTimestamp.set('value', null);
            }
            this.hqSetPlace.set('value', null);
            this.hqSetFlgNot.set('value', true);
            // 切替え対象のノードを非表示に初期化
            domStyle.set(this.hqInfNode, 'display', 'none');
            domStyle.set(this.hqSetTimestampArea, 'display', 'none');
            if (this.hqAbolishedTimestampArea) {
                domStyle.set(this.hqAbolishedTimestampArea, 'display', 'none');
            }
            domStyle.set(this.hqSetPlaceArea, 'display', 'none');
            // 非表示中は必須チェックの対象外
            domClass.remove(this.hqSetTimestamp, 'is-required');  // 本部設置日時
            if (this.hqAbolishedTimestampArea) {
                domClass.remove(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
            }
            this.hqSetFlgNot.set('disabled', false);
            this.hqSetFlgSet.set('disabled', false);
            if (this.hqSetFlgAbolished) {
                this.hqSetFlgAbolished.set('disabled', false);
            }
            switch(item.hqType) {
                case HqType.RESPONSE_HQ:
                    // 災害対策本部設置対象の体制の場合
                    // 下に続く
                case HqType.ALERT_HQ:
                    // 災害警戒本部設置対象の体制の場合
                    // 下に続く
                case HqType.OTHER_HQ:
                    // その他本部設置対象の場合
                    this.hqName.innerHTML = item.hqName;
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetFlg &&
                        this._lastDetailItem.status === item.code) {
                        // 直前報で体制状況が設定されていて、
                        // 直前報と変更後の体制状況が同一の場合は直前報の値をセットする
                        switch (this._lastDetailItem.hqSetFlg) {
                            case '0':
                                this.hqSetFlgNot.set('value', true);
                                break;
                            case '1':
                                this.hqSetFlgSet.set('value', true);
                                break;
                            case '2':
                                this.hqSetFlgAbolished.set('value', true);
                                break;
                        }
                        this._setHqSetInf(this._lastDetailItem.hqSetFlg);
                    }
                    domStyle.set(this.hqInfNode, 'display', '');
                    break;
                case HqType.NOT_YET:
                    break;
                case HqType.RELEASE:
                    if (this._lastDetailItem &&
                    (this._lastDetailItem.hqSetFlg === '1' ||
                    this._lastDetailItem.hqSetFlg === '2')) {
                        // 解除選択時に直前報の本部設置フラグが「設置」又は「廃止」の場合
                        // DOMは表示状態且つ「廃止」選択状態とし、
                        // 編集を不可とする。
                        // 細かく制御するため、_setHqSetInf関数は呼ばず、
                        // 当関数内で完結させる
                        this.hqSetFlgAbolished.set('value', true);
                        domClass.add(this.hqSetTimestamp, 'is-required');  // 本部設置日時
                        domClass.add(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
                        domStyle.set(this.hqSetTimestampArea, 'display', '');
                        domStyle.set(this.hqAbolishedTimestampArea, 'display', '');
                        domStyle.set(this.hqSetPlaceArea, 'display', '');
                        domStyle.set(this.hqInfNode, 'display', '');
                        if (this._lastDetailItem &&
                            this._lastDetailItem.hqSetTimestamp) {
                            // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                            this.hqSetTimestamp.set('value', this._lastDetailItem.hqSetTimestamp);
                        }
                        if (this._lastDetailItem &&
                            this._lastDetailItem.hqAbolishedTimestamp) {
                            // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                            this.hqAbolishedTimestamp.set('value', this._lastDetailItem.hqAbolishedTimestamp);
                        }
                        if (this._lastDetailItem &&
                            this._lastDetailItem.hqSetPlace) {
                            // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                            this.hqSetPlace.set('value', this._lastDetailItem.hqSetPlace);
                        }
                    }
                    break;
            }
        },

        /**
         * 本部設置状況変更に伴い、設置日時と設置場所の表示有無を切替え、初期化する
         * 切替え要否の判定は当ファンクション呼び出し元にて実施すること
         * @param {*} value 変更後の本部設置状況：0:未設置、1:設置、2:解除、null
         */
        _setHqSetInf: function(value) {
            // 切替え対象のノードを非表示に初期化
            domStyle.set(this.hqSetTimestampArea, 'display', 'none');
            if (this.hqAbolishedTimestampArea) {
                domStyle.set(this.hqAbolishedTimestampArea, 'display', 'none');
            }
            domStyle.set(this.hqSetPlaceArea, 'display', 'none');
            if (!value) {
                // nullの場合
                return;
            }
            switch (value) {
                case '0':
                    break;
                case '1':
                    domClass.add(this.hqSetTimestamp, 'is-required');  // 本部設置日時
                    domStyle.set(this.hqSetTimestampArea, 'display', '');
                    domStyle.set(this.hqSetPlaceArea, 'display', '');
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetTimestamp &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetTimestamp.set('value', this._lastDetailItem.hqSetTimestamp);
                    }
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetPlace &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetPlace.set('value', this._lastDetailItem.hqSetPlace);
                    }
                    break;
                case '2':
                    domClass.add(this.hqSetTimestamp, 'is-required');  // 本部設置日時
                    domClass.add(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
                    domStyle.set(this.hqSetTimestampArea, 'display', '');
                    domStyle.set(this.hqAbolishedTimestampArea, 'display', '');
                    domStyle.set(this.hqSetPlaceArea, 'display', '');
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetTimestamp &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetTimestamp.set('value', this._lastDetailItem.hqSetTimestamp);
                    }
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqSetPlace &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqSetPlace.set('value', this._lastDetailItem.hqSetPlace);
                    }
                    if (this._lastDetailItem &&
                        this._lastDetailItem.hqAbolishedTimestamp &&
                        this._lastDetailItem.status === this.status.get('value')) {
                        // 直前報で値が設定され、且つ直前報と体制状況が同一の場合、直前報の値をセット
                        this.hqAbolishedTimestamp.set('value', this._lastDetailItem.hqAbolishedTimestamp);
                    }
                    break;
                
            }
        },

        /**
         * 体制セット
         */
        setStatus: function(status){
            // 新規登録時は解除を表示しない
            var postFlg = this._lastDetailItem ? false:true;
            // 自治体コード
            var areaSelVal = this.municipalitySelector.get('value')!==config.municInfo.prefMunicCd?
                this.municipalitySelector.get('value') : config.municInfo.prefCd;
            var disasterType = this._disasterType;
            var self = this;
            if (disasterType) {
                Requester.get('/api/disasterPrevention/statusForSelect?' +
                    'areaCd=' + areaSelVal + '&disasterType=' + disasterType +
                    '&includeRelease=' + !postFlg, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (data) {
                    self._prevStatusStore = new Memory({
                        idProperty: 'code',
                        data: data.items
                    });
                    self.status.set('sortByLabel', false);
                    self.status.set('store', self._prevStatusStore);
                    self.status.set('value', status);
                    // 本部設置状況を初期化
                    self._setHqInfNode(self._prevStatusStore.get(self.status.value));
                }, function (err) {
                    console.log(err);
                    InfoDialog.show('エラー', '体制状況の取得に失敗しました。');
                });
            }
        }
    });
});

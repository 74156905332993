/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterBulkOperatingGrid.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/array',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/query',
    'dijit/form/Button',
    'dojo/topic',
    'dojox/lang/functional/array',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    'idis/view/grid/helper',
    'idis/model/UserInfo',
    'app/config',
    './consts/CrowdedStatus',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/consts/ACL',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'dijit/layout/BorderContainer'
], function (module, array, declare, lang, domClass, query, Button,
    topic, df, DateUtils, IdisGrid, IdisSelector, helper, UserInfo, config, CrowdedStatus) {

    // 日時編集イベント
    var _TIME_EDIT_EVENT = module.id + '::' + 'TimeEdit';
    // 数値編集イベント
    var _NUMBER_EDIT_EVENT = module.id + '::' + 'NumberEdit';
    // プルダウンリスト選択編集イベント
    var _SELECT_EDIT_EVENT = module.id + '::' + 'SelectEdit';
    // 混雑状況自動セットイベント
    var _AUTO_SET_CROWDED_STATUS = module.id + '::' + 'autoSetCrowdedStatus';

    /**
     * 指定された'yyyy-MM-dd HH:mm:ss'形式の日付文字列をDateインスタンス化して返す。
     * @function _parseDateStr
     * @param {string} dateStr 日付文字列
     * @returns {Date} 日付文字列に対応するDateインスタンス
     * @private
     */
    function _parseDateStr(dateStr) {
        var matched = dateStr.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
        var year = parseInt(matched[1], 10);
        // 数値上の月は0始まり
        var month = parseInt(matched[2], 10) - 1;
        var day = parseInt(matched[3], 10);
        var hour = parseInt(matched[4], 10);
        var minute = parseInt(matched[5], 10);
        var second = parseInt(matched[6], 10);
        return new Date(year, month, day, hour, minute, second);
    }

    /**
     * 編集可能な日時グリッド列定義を生成して返す。
     * @function _editableTsColumn
     * @param {string} field フィールド名
     * @param {string} label 列名
     * @private
     */
    function _editableTsColumn(field, label) {
        return {
            field: field,
            label: label,
            sortable: false,
            // イベント種別判別用の独自プロパティー
            _editEvent: _TIME_EDIT_EVENT,
            // 列のヘッダー部分に一括操作ボタンを追加
            renderHeaderCell: function () {
                var containerNode = document.createElement('div');
                // ラベル要素を作成してcontainerNodeのブロックに挿入する。
                var textNode = document.createElement('div');
                textNode.innerHTML = label;
                containerNode.appendChild(textNode);
                // ボタン要素を作成してcontainerNodeのブロックに挿入する。
                var grid = this.grid;
                var column = this;
                var button = new Button({
                    label: '一括入力',
                    disabled: true,
                    onClick: function () {
                        topic.publish(column._editEvent, {
                            // 対象グリッド
                            grid: grid,
                            // 列定義
                            column: column,
                            // 選択行のID一覧
                            idList: grid.getSelectedIdList()
                        });
                    }
                });
                containerNode.appendChild(button.domNode);
                button.startup();
                // グリッドのフィールドとしてボタンを参照出来るようにしておく
                this.grid[field + 'BulkEditButton'] = button;
                // 追加が完了したcontainerNodeを返す。
                return containerNode;
            },
            renderCell: function (item) {
                var node = document.createElement('div');
                node.innerHTML = [
                    '<span class="shelter-ShelterBulkOperatingGrid-Label">',
                    this.grid.formatField(item, field),
                    '</span><div class="shelter-ShelterBulkOperatingGrid-Edit"></div>'
                ].join('');
                return node;
            }
        };
    }

    /**
     * 編集可能な日時グリッド列定義を生成して返す。
     * @function _editableTsColumn
     * @param {string} field フィールド名
     * @param {string} label 列名
     * @private
     */
    function _editableNumColumn(field, label) {
        return {
            field: field,
            label: label,
            sortable: false,
            // イベント種別判別用の独自プロパティー
            _editEvent: _NUMBER_EDIT_EVENT,
            renderCell: function (item) {
                var node = document.createElement('div');
                node.innerHTML = [
                    '<span class="shelter-ShelterBulkOperatingGrid-Label">',
                    this.grid.formatField(item, field),
                    '</span><div class="shelter-ShelterBulkOperatingGrid-Edit"></div>'
                ].join('');
                return node;
            }
        };
    }

    /**
     * 編集可能なプルダウンリスト選択グリッド列定義を生成して返す。
     * @function _editableSelectColumn
     * @param {string} field フィールド名
     * @param {string} label 列名
     * @param {list}   SelectorOptions 選択肢
     * @private
     */
    function _editableSelectColumn(field, label, SelectorOptions) {
        // オプションのラベルで表示する
        var formatter = function (grid, item, field) {
            var value = grid.fetchItemValue(item, field);
            return (!value || value === ' ') ?
                grid.formatField(item, field) :
                array.filter(SelectorOptions, function (opt) {
                    return opt.value === value;
                }, this)[0].label;
        };
        return {
            field: field,
            label: label,
            sortable: false,
            // イベント種別判別用の独自プロパティー
            _editEvent: _SELECT_EDIT_EVENT,
            _editEventPayload: { options: SelectorOptions },
            formatter: formatter,
            renderCell: function (item) {
                var node = document.createElement('div');
                node.innerHTML = [
                    '<span class="shelter-ShelterBulkOperatingGrid-Label">',
                    formatter(this.grid, item, field),
                    '</span><div class="shelter-ShelterBulkOperatingGrid-Edit"></div>'
                ].join('');
                return node;
            }
        };
    }

    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    var ShelterBulkOperatingGrid = declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector],
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {
            allowTextSelection: false,

            nullableFlg: false, // nullセット可能かどうか

            /**
             * グリッド行IDと報告日時の対応
             * @type {Object}
             */
            reportTimeMap: null,

            /**
             * グリッド行IDと開設日時の対応
             * @type {Object}
             */
            shelterStartTimeMap: null,

            /**
             * グリッド行IDと閉鎖日時の対応
             * @type {Object}
             */
            shelterEndTimeMap: null,

            evacHouseholdNumMap: null,

            evaqueeNumMap: null,

            crowdedStatusMap: null,

            shelterMap: {},

            /**
             * 日付列判別用。
             * 列名をキー、日付列かどうかを値として格納する。
             * @type {Object}
             */
            editTypeMap: null,

            // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
            rowClassName: function (item) {
                return item && (item.availableFlg === '0') ? 'gray-row' : '';
            },

            // ユーザーの市町村内の避難所のみを登録可能としている
            renderRow: function (item) {
                var userMunicipalityCds = UserInfo.getMunicipalityCds();
                var div = this.inherited(arguments);
                // デフォルトではチェックボックスを非活性に
                var inputtags = div.getElementsByTagName('input');
                for (var j = 0; j < inputtags.length; j++) {
                    inputtags[j].disabled = true;
                }
                // ユーザーの市町内の避難所かつ、避難所の利用可否フラグが１である場合、チェックボックスを活性に
                userMunicipalityCds.forEach(function (value) {
                    if (((item.municipalityCd === value) ||
                        ((config.municInfo.cityDistrictMunicCds.indexOf(value) !== -1) &&
                            (item.municipalityCd === config.municInfo.cityMunicCd))) &&
                        item.availableFlg === '1') {
                        for (var i = 0; i < inputtags.length; i++) {
                            inputtags[i].disabled = false;
                        }
                    }
                });

                // rowClassNameが指定されている場合
                if (this.rowClassName) {
                    var className = this.rowClassName;
                    // 関数の場合は行データを与えて実行する
                    if (lang.isFunction(className)) {
                        className = className(item);
                    }
                    // 偽値でなければCSSクラスとして追加
                    if (className) {
                        domClass.add(div, className);
                    }
                }

                this.shelterMap[item.facilityId] = item;

                return div;
            },

            resetMap: function () {
                this.shelterMap = {};
            },

            columns: [
                // チェックボックス
                {
                    field: 'checkbox',
                    label: 'checkbox',
                    selector: 'checkbox'
                },
                helper.column('districtName', '地区', { sortable: false }),
                helper.column('facilityName', '避難所名', { sortable: false }),
                helper.column('address', '住所', { sortable: false }),
                {
                    field: '_item',
                    label: '避難所区分',
                    sortable: false,
                    formatter: lang.hitch(this, function (item) {
                        var shelterCategory = '';
                        if (item.edesignatedEvacShFlg === '1') {
                            shelterCategory += '指定緊急 ';
                        }
                        if (item.designatedEvacShFlg === '1') {
                            shelterCategory += '指定 ';
                        }
                        if (item.welfareEvacShFlg === '1') {
                            shelterCategory += '福祉 ';
                        }
                        if (item.temporaryEvacShFlg === '1') {
                            shelterCategory += '臨時';
                        }
                        if (item.civilProtectionEvacShFlg === '1') {
                            shelterCategory += '国民保護';
                        }
                        if (item.otherEvacShFlg === '1') {
                            shelterCategory += 'その他';
                        }
                        return shelterCategory;
                    })
                },
                {
                    field: '_item',
                    label: '対象災害',
                    sortable: false,
                    formatter: lang.hitch(this, function (item) {
                        var shelterType = '';
                        if (item.floodShFlg === '1') {
                            shelterType += '洪水&nbsp';
                        }
                        if (item.tsunamiShFlg === '1') {
                            shelterType += '津波&nbsp';
                        }
                        if (item.earthquakeShFlg === '1') {
                            shelterType += '地震&nbsp';
                        }
                        if (item.stormSurgeShFlg === '1') {
                            shelterType += '高潮&nbsp';
                        }
                        if (item.sedimentDisasterShFlg === '1') {
                            shelterType += '土砂&nbsp';
                        }
                        if (item.fireShFlg === '1') {
                            shelterType += '火災&nbsp';
                        }
                        if (item.landsideWaterShFlg === '1') {
                            shelterType += '内水&nbsp';
                        }
                        if (item.volcanoShFlg === '1') {
                            shelterType += '火山&nbsp';
                        }
                        return shelterType;
                    })
                },
                _editableTsColumn('reportTime', '報告日時'),
                _editableTsColumn('shelterStartTime', '開設日時'),
                _editableTsColumn('shelterEndTime', '閉鎖日時'),
                _editableNumColumn('evacHouseholdNum', '避難世帯数'),
                _editableNumColumn('evaqueeNum', '避難者数'),
                _editableSelectColumn('crowdedStatus', '混雑状況', CrowdedStatus.options),
                {
                    field: 'capacity',
                    label: '最大収容人数',
                    sortable: false,
                    formatter: lang.hitch(this, function (item) {
                        if (item === null) {
                            return '-';
                        }
                        return item;
                    })
                }
            ],
            constructor: function () {
                // 変数を初期化
                this.reportTimeMap = {};
                this.shelterStartTimeMap = {};
                this.shelterEndTimeMap = {};
                this.evacHouseholdNumMap = {};
                this.evaqueeNumMap = {};
                this.crowdedStatusMap = {};
                this.editTypeMap = {};
            },

            // プロパティー設定後に呼ばれる
            postMixInProperties: function () {
                this.inherited(arguments);
                // フィールドごとのイベント名を設定
                df.forEach(this.columns, function (column) {
                    this.editTypeMap[column.field] = column._editEvent;
                }, this);
            },

            // DOM構築後に呼ばれる
            postCreate: function () {
                this.inherited(arguments);
                // 選択行の編集ボタンに対するクリックを監視
                this.on('.dgrid-selected .shelter-ShelterBulkOperatingGrid-Edit:click', lang.hitch(this, function (evt) {
                    var cell = this.cell(evt);
                    var row = cell.row;
                    var column = cell.column;
                    // 編集ダイアログを開くためのイベントを発行
                    topic.publish(cell.column._editEvent, {
                        // 対象グリッド
                        grid: this,
                        // 列定義
                        column: column,
                        // 行はIDで指定（一括操作と揃えるため配列化）
                        idList: [row.id],
                        // 初期値として既存設定値を渡す
                        value: this.fetchItemValue(row.data, column.field),
                        // 初期値として既存設定値を渡す
                        items: this.shelterMap[row.data.facilityId]
                    });
                }));
                // 1件以上選択されている場合のみ一括入力ボタンをクリック可能にする
                this.on('dgrid-refresh-complete, dgrid-error, dgrid-select, dgrid-deselect', lang.hitch(this, function () {
                    var disabled = !this.getSelectedIdList().length;
                    this.reportTimeBulkEditButton.set('disabled', disabled);
                    this.shelterStartTimeBulkEditButton.set('disabled', disabled);
                    this.shelterEndTimeBulkEditButton.set('disabled', disabled);
                }));
                // 行選択時はサーバー側の値を既存値として設定する
                this.on('dgrid-select', lang.hitch(this, function (evt) {
                    array.map(evt.rows, function (row) {
                        df.forEach(this.columns, function (column) {
                            // 編集対象列以外は何もしない
                            if (!column._editEvent) {
                                return;
                            }
                            // 以下、編集対象列の場合
                            var isDate = this.isDateField(column.field);
                            // サーバー側の設定値が存在するなら初期値として設定
                            var value = row.data && row.data[column.field];
                            if (value) {
                                this.setEditValue(row.id, column.field, isDate ? _parseDateStr(value) : value);
                            }
                            // DOMを更新
                            this.updateLabel(row, column.field);
                        }, this);
                    }, this);
                }));
                // 行選択解除時は入力値をクリアする
                this.on('dgrid-deselect', lang.hitch(this, function (evt) {
                    array.map(evt.rows, function (row) {
                        df.forEach(this.columns, function (column) {
                            // 編集対象列以外は何もしない
                            if (!column._editEvent) {
                                return;
                            }
                            // 以下、編集対象列の場合
                            // 入力値をクリア
                            this.deleteEditValue(row.id, column.field);
                            // DOMを更新
                            this.updateLabel(row, column.field);
                        }, this);
                    }, this);
                }));
            },

            /**
             * 指定された列が日付列かどうかを返す。
             * @returns {boolean} 指定された列が日付列かどうか
             */
            isDateField: function (field) {
                return this.editTypeMap[field] === _TIME_EDIT_EVENT;
            },

            /**
             * グリッド要素またはそのIDを受け取り、グリッド要素のIDを返す。
             * @param {Object|string} itemOrId グリッド要素またはそのID
             * @returns {string} グリッド要素のID
             * @private
             */
            _toItemId: function (itemOrId) {
                if (lang.isObject(itemOrId)) {
                    return this.get('collection').getIdentity(itemOrId);
                } else {
                    return itemOrId;
                }
            },

            /**
             * 指定された要素の指定されたフィールドの編集値を返す。
             * 編集中の値が無ければnullを返す。
             * @param {Object|string} itemOrId グリッド要素またはそのID
             * @param {string} field 列名
             * @returns {Date|string} フィールドの編集値またはnull
             */
            getEditValue: function (itemOrId, field) {
                return itemOrId && this[field + 'Map'][this._toItemId(itemOrId)] || null;
            },

            /**
             * 指定された要素の指定されたフィールドの編集値を設定する。
             * @param {Object|string} itemOrId グリッド要素またはそのID
             * @param {string} field 列名
             * @param {Date|string} value フィールドの設定値
             */
            setEditValue: function (itemOrId, field, value) {
                var itemId = this._toItemId(itemOrId);
                if (itemId) {
                    this[field + 'Map'][itemId] = value;
                } else {
                    console.error('不明な設定先要素');
                }
            },

            /**
             * 指定された要素の指定されたフィールドの編集値を削除する。
             * @param {Object|string} itemOrId グリッド要素またはそのID
             * @param {string} field 列名
             */
            deleteEditValue: function (itemOrId, field) {
                if (itemOrId) {
                    delete this[field + 'Map'][this._toItemId(itemOrId)];
                }
            },

            /**
             * 指定した要素の指定したフィールド値を取得する。
             * グリッドの編集値が存在する場合はそれを優先し、無ければ元の設定値を取得する。
             * @param {Object} item グリッド要素
             * @param {string} field フィールド名
             * @returns {Date|string} 日付フィールドなら日付、それ以外は文字列形式で値を返す
             */
            fetchItemValue: function (item, field) {
                if (!item) {
                    return null;
                }

                // nullableFlg = trueの場合、グリッドの編集値が存在しない場合(null)でもそのまま取得する
                // （再開設時に閉鎖日時にnullを設定するため）
                // nullableFlg = falseの場合、グリッドの編集値が存在しない場合は元の設定値を取得する
                // （こちらが通常ケース）
                var value = this.nullableFlg ?
                    this.getEditValue(item, field) : this.getEditValue(item, field) || item[field];

                if (this.isDateField(field) && value && lang.isString(value)) {
                    return _parseDateStr(value);
                } else {
                    return value;
                }
            },

            /**
             * 指定した要素の指定したフィールドの表示値を返す。
             * グリッドの編集値が存在する場合はそれを優先し、無ければ元の設定値を取得する。
             * @param {Object} item グリッド要素
             * @param {string} field フィールド名
             * @returns {string} フィールドの表示文字列
             */
            formatField: function (item, field) {
                var value = this.fetchItemValue(item, field);
                if (!value) {
                    return '未設定';
                } else if (this.isDateField(field)) {
                    return DateUtils.format(value).slice(0, -3);
                } else {
                    return value;
                }
            },

            /**
            * 指定された行・列の現在の設定値をDOMに反映する。
            * 本来はputメソッドで元データを更新し、HTMLは列定義を元に自動更新されるようにすべきであるが、
            * グリッドのstoreがRESTベースでありputはサーバーへのPUTとして解釈されてしまうため、
            * putメソッドは使わずにこのメソッド内で直接HTMLを書き換える。
            * @param {Object} row dgrid行情報
            * @param {string} row.id 行ID
            * @param {string} row.data 行データ
            * @param {string} row.element 行のDOM要素
            * @param {string} field 列名
             */
            updateLabel: function (row, field) {
                var labelNode = query('.field-' + field + ' .shelter-ShelterBulkOperatingGrid-Label', row.element)[0];
                if (labelNode) {
                    labelNode.innerHTML = this.formatField(row.data, field);
                }
                // formatterを設定している場合、formatterを使って変換
                var column = df.filter(Object.values(this.columns), function (column) {
                    return column.field === field;
                }, this)[0];
                if (column.formatter) {
                    labelNode.innerHTML = column.formatter(this, row.data, field);
                }
            },

            /**
             * グリッドの編集結果を更新する。
             * @param {Object} kwArgs キーワード引数
             * @param {string} kwArgs.field フィールド名
             * @param {Date|string} kwArgs.value 編集結果の値
             * @param {string[]} kwArgs.idList 行ID一覧
             */
            updateEditValues: function (kwArgs) {
                // 日時の変更の場合、nullセット可能とする（再開設するため）
                var datetimeFieldName = new Array('reportTime', 'shelterStartTime', 'shelterEndTime');
                if (datetimeFieldName.indexOf(kwArgs.field) !== -1) {
                    this.nullableFlg = true;
                }
                array.forEach(kwArgs.idList, function (id) {
                    // POST用データを保持
                    this.setEditValue(id, kwArgs.field, kwArgs.value);
                    // DOMを更新
                    this.updateLabel(this.row(id), kwArgs.field);
                    // 避難者数の変更の場合、混雑状況を自動セットする
                    if (kwArgs.field === 'evaqueeNum') {
                        this.setCrowdedStatus(id, kwArgs.value, true);
                    }
                }, this);
                this.nullableFlg = false; // nullセット不可に戻す
            },

            setCrowdedStatus: function (id, evaqueeNum, confirmFlg) {
                var items = this.row(id).data;
                var newValue = CrowdedStatus.calcCrowdedStatus(evaqueeNum, items.capacity);
                // グリッド内容を書き換える
                this.updateEditValues({
                    field: 'crowdedStatus',
                    value: newValue,
                    idList: [id]
                });
                // 変更した場合、ダイアログを表示
                if (confirmFlg && items.crowdedStatus !== newValue) {
                    topic.publish(_AUTO_SET_CROWDED_STATUS);
                }
            }
        });

    // イベント名を外から参照出来るようにする
    ShelterBulkOperatingGrid.TIME_EDIT_EVENT = _TIME_EDIT_EVENT;
    ShelterBulkOperatingGrid.NUMBER_EDIT_EVENT = _NUMBER_EDIT_EVENT;
    ShelterBulkOperatingGrid.SELECT_EDIT_EVENT = _SELECT_EDIT_EVENT;
    ShelterBulkOperatingGrid.AUTO_SET_CROWDED_STATUS = _AUTO_SET_CROWDED_STATUS;

    return ShelterBulkOperatingGrid;
});

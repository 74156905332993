define([
    'dojo/json',
    'dojo/dom-style',
    'dojo/dom-geometry',
    'dojo/_base/lang',
    'leaflet',
    './measure/Area',
    './measure/Distance',
    './measure/measureObj',
    './CustomDivIcon',
    './CustomIcon',
    './_CustomMarkerUtilsMixin',
    './_CustomMarkerInitialize',
    './_OptionConverterMixin',
    './_PointToTanJSONMixin',
    './_SetStyleMixin',
    './_TanJSONToLayerMixin',
    // 以下、変数で受けないモジュール
    './Arrow',
    './FreeHandPolygon',
    './FreeHandPolyline',
    './TanJSON',
    './measure/MeasureToolbar',
    './TouchExtend'
], function(json, domStyle, domGeo, lang, leaflet, Area, Distance, measureObj,
    CustomDivIcon, CustomIcon, _CustomMarkerUtilsMixin, _CustomMarkerInitialize,
    _OptionConverterMixin, _PointToTanJSONMixin, _SetStyleMixin, _TanJSONToLayerMixin) {
    return  (function (window, document, undefined) {
        leaflet.Draw.CustomMarker = leaflet.Draw.Marker.extend({
            options: {
                icon:  new CustomIcon()
            }
        });

        leaflet.Draw.Note = leaflet.Draw.Marker.extend({
            options: {
                icon:  new CustomDivIcon()
            },
            initialize: function (map, options) {
                leaflet.Draw.Feature.prototype.initialize.call(this, map, options);
                this.type = 'DivIcon';
            }
        });

        leaflet.Marker.include(_SetStyleMixin);
        leaflet.Marker.include(_CustomMarkerInitialize);

        leaflet.Icon.include(_CustomMarkerUtilsMixin);
        leaflet.DivIcon.include(_CustomMarkerUtilsMixin);
        leaflet.Marker.include(_CustomMarkerUtilsMixin);

        var LayerTOTanJSON = {
            toTanJSON: function(){
                var tanJsons  = this.toGeoJSON();
                tanJsons.features = [];
                this.eachLayer(function(layer){
                    tanJsons.features.push(layer.toTanJSONFromOne(layer.options.drawType));
                });
                return {
                    type: tanJsons.type,
                    features: tanJsons.features
                };
            }
        };

        leaflet.Icon.include(_OptionConverterMixin);
        leaflet.DivIcon.include(_OptionConverterMixin);
        leaflet.Marker.include(_OptionConverterMixin);
        leaflet.Circle.include(_OptionConverterMixin);
        leaflet.Polygon.include(_OptionConverterMixin);
        leaflet.Polyline.include(_OptionConverterMixin);
        leaflet.Icon.include(_PointToTanJSONMixin);
        leaflet.DivIcon.include(_PointToTanJSONMixin);
        leaflet.Marker.include(_PointToTanJSONMixin);
        leaflet.Circle.include(_PointToTanJSONMixin);
        leaflet.Polygon.include(_PointToTanJSONMixin);
        leaflet.Polyline.include(_PointToTanJSONMixin);

        leaflet.FeatureGroup.include(LayerTOTanJSON);
        leaflet.GeoJSON.include(_TanJSONToLayerMixin);

    }(window, document));
});

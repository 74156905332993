/**
 * 行政区域描画用のユーティリティー群
 * 
 */
define([], function(){
	return {
		
		/**
		 * 作図レイヤーから指定行政コードを持つレイヤーを取得する
		 * @return {Array}
		 */
		getLayers: function(code, featureGroup){
			
			var layers = [];
			
			var _layers = featureGroup._layers;
			
			for (var key in _layers) {
				if (_layers.hasOwnProperty(key)) {
					if (_layers[key].layerInfo !== void 0 && _layers[key].layerInfo.districtCode === code) {
						layers.push(_layers[key]);
					}
				}
			}
			
			return layers;
		}
		
	};
});
/**
 * 共有情報一覧用モジュール
 * @module app/disasterInfoShare/DisasterInfoShareDetailGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/util/DateFormatter'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, IdisGrid, helper, DateFormatter) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: '',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // 詳細
            helper.buttonColumn('detail', '詳細', '詳細'),
            // 情報
            {field: 'emergencyNewsFlg', label: '情報',
                formatter: lang.hitch(this, function(emergencyNewsFlg, item){
                    if (emergencyNewsFlg === 1) {
                        if (item.notifyType === '本部長（市長）指示') {
                            return '<span style="color:red;">緊急(本部長)<span>';
                        } else if (item.notifyType === '市本部からのお知らせ') {
                            return '<span style="color:red;">緊急(市本部)<span>';
                        }
                    } else if (item.newInformationFlg === 1) {
                        return '新着';
                    } else {
                        return '共有';
                    }
                }),
                sortable: false
            },
            // レベル
            helper.column('messageType', 'レベル', {
                formatMap: {
                    '001': '一般（お知らせ）',
                    '002': '通知（お知らせ）',
                    '003': '注意（お知らせ）',
                    '004': '重要（お知らせ）',
                    '005': '緊急'
                },
                sortable: false
            }),
            // 発表日時
            {field: 'pubStartTimestamp', label: '発表日時',
                formatter: lang.hitch(this, function(item){
                    var res = DateFormatter.jpDateTime(new Date(item), true);
                    var now = DateFormatter.jpDateTime(new Date(), true);
                    if (res > now) {
                        return res + ' (発表前)';
                    } else {
                        return res;
                    }
                })
            },
            // タイトル
            {field: 'title', label: 'タイトル', sortable: false}
        ]
    });
});

/**
 * 職員招集・メール配信一覧画面用モジュール。
 *
 * @module app/convocation/view/mail/ConvocationMailAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/dom-style',
        'dojo/text!./templates/ConvocationMailAdminPage.html',
        'idis/store/IdisRest',
        'idis/view/Loader',
        'idis/service/Requester',
        'idis/control/Router',
        'idis/view/dialog/DialogChain',
        'idis/view/page/_PageBase',
        'app/model/ConvoMail',
        'idis/model/UserInfo',
        'idis/consts/ACL',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/CheckBox',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/view/form/ConvoGroupSelector',
        'app/convocation/view/mail/ConvoSendMailDialog',
        'app/convocation/view/mail/ConvoSendAutoMailDialog',
        'app/convocation/view/mail/ConvoMailListGrid'
],function(module, declare, lang, domStyle, template, IdisRest, Loader, Requester,
    Router, DialogChain, _PageBase, ConvoMail, UserInfo, ACL) {
    /**
     * メール配信一覧画面
     *
     * @class ConvocationMailAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,{
    /** @lends module:app/convocation/view/mail/ConvocationMailAdminPage~ConvocationMailAdminPage# */
        // テンプレート文字列
        templateString: template,

        store: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        /**
         * 変数の初期化。
         */
        constructor: function() {
            //メール配信一覧画面用データ格納オブジェクト
            this.store = new IdisRest({
                idProperty: 'distributionId',
                target: '/api/convoMails/mails'
            });
            // ダイアログの連鎖関係を取得
            this.chain = DialogChain.get(this);
            // 招集抑止ステータスの設定
            this.isSuppressed = false;
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.mailDialog);
            this.own(this.autoMailDialog);
            this.initMailDialog();
            this.initAutoMailDialog();
            this.initConvoMailListGrid();
            this.initSuppressItems();
        },

        /**
         * 招集抑止ステータスを画面上部のフィールドに反映する。
         */
        initSuppressItems :function() {
            ConvoMail.getSuppressInfo().then(lang.hitch(this, function(info) {
                this.suppressInfo = info;
                // // 警報・注意報
                // this.setSuppressStatus({
                //     label: this.warningStatus,
                //     button: null,
                //     status: info[ConvoMail.WARNING]
                // });
                // 津波
                this.setSuppressStatus({
                    label: this.tsunamiStatus,
                    button: this.tsunamiSuppressButton,
                    status: info[ConvoMail.TSUNAMI]
                });
                // 地震
                this.setSuppressStatus({
                    label: this.earthquakeStatus,
                    button: this.earthquakeSuppressButton,
                    status: info[ConvoMail.EARTHQUAKE]
                });
                // // 火山
                // this.setSuppressStatus({
                //     label: this.volcanoStatus,
                //     button: this.volcanoSuppressButton,
                //     status: info[ConvoMail.VOLCANO]
                // });
                // 国民保護
                // this.setSuppressStatus({
                //     label: this.civilProtectionStatus,
                //     button: this.civilProtectionSuppressButton,
                //     status: info[ConvoMail.CIVIL_PROTECTION]
                // });
                // // その他
                // this.setSuppressStatus({
                //     label: this.otherStatus,
                //     button: null,
                //     status: info[ConvoMail.OTHER]
                // });
            }), function(error) {
                console.error(error);
            });

            var role = UserInfo.getRoleCd();
            if (role !== ACL.ADMIN_USER) {
                this.tsunamiSuppressButton.setDisabled(true);
                this.earthquakeSuppressButton.setDisabled(true);
                // this.volcanoSuppressButton.setDisabled(true);
                // this.civilProtectionSuppressButton.setDisabled(true);
            }
        },

        /**
         * 抑止・抑止解除のステータスによって画面表示を切り替える
         */
        setSuppressStatus: function(item) {
            // 招集抑止ステータスが'1'の場合は抑止中とみなす
            if (item.status) {
                item.label.innerHTML = '抑止中';
                domStyle.set(item.label, {
                    'background-color': '#FFFF00'
                });
                if (item.button) {
                    item.button.setLabel('解除');
                }
            } else {
                // 招集抑止ステータスが'1'でなければ抑止なしとみなし、フィールドを非表示
                item.label.innerHTML = '';
                domStyle.set(item.label, {
                    'background-color': '#fff'
                });
                if (item.button) {
                    item.button.setLabel('招集抑止');
                }
            }
        },

        /**
         * 抑止ボタンが押下された際の挙動。
         */
        earthquakeSuppress: function() {
            // 抑止・抑止解除を実行
            this.suppress({
                type: ConvoMail.EARTHQUAKE,
                flag: this.suppressInfo[ConvoMail.EARTHQUAKE],
                title: '地震'
            });
        },

        /**
         * 抑止ボタンが押下された際の挙動。
         */
        tsunamiSuppress: function() {
            // 抑止・抑止解除を実行
            this.suppress({
                type: ConvoMail.TSUNAMI,
                flag: this.suppressInfo[ConvoMail.TSUNAMI],
                title: '津波'
            });
        },

        // /**
        //  * 抑止ボタンが押下された際の挙動。
        //  */
        // volcanoSuppress: function() {
        //     // 抑止・抑止解除を実行
        //     this.suppress({
        //         type: ConvoMail.VOLCANO,
        //         flag: this.suppressInfo[ConvoMail.VOLCANO],
        //         title: '火山'
        //     });
        // },

        /**
         * 抑止ボタンが押下された際の挙動。
         */
        // civilProtectionSuppress: function() {
        //     // 抑止・抑止解除を実行
        //     this.suppress({
        //         type: ConvoMail.CIVIL_PROTECTION,
        //         flag: this.suppressInfo[ConvoMail.CIVIL_PROTECTION],
        //         title: '国民保護'
        //     });
        // },

        /**
         * 抑止・抑止解除を実行し、ステータスを再表示
         */
        suppress: function(option) {
            var suppress = false;
            // 確認ダイアログに出力するメッセージ
            var message = option.title + 'による招集メールの自動配信抑止を解除します。<br>' + 'よろしいですか？';
            // 抑止されていない場合はメッセージを変更
            if (!option.flag) {
                message = option.title + 'による招集メールの自動配信を抑止します。<br>' + 'よろしいですか？';
                suppress = true;
            }
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                // 招集抑止ステータスの変更
                ConvoMail.suppress(option.type, suppress).then(lang.hitch(this, function() {
                    // 画面上部のフィールドへ変更内容を反映
                    this.initSuppressItems();
                    // 確認ダイアログを閉じる
                    chain.infoComplete();
                }), function(error) {
                    chain.infoError(error);
                });
            }));

        },

        /**
         * メール配信一覧用グリッドの初期化。
         */
        initConvoMailListGrid: function() {
            // 初期表示時の検索条件を設定する
            var date = new Date();
            var filter = new this.store.Filter();

            // 送信日To：の初期値として現在日時を設定
            this.sendTimestampTo.set('value', date);
            filter = filter.eq('convoMailTo', date.getTime());
            // 送信日From：の初期値として現在日時の1年前を設定
            date.setFullYear(date.getFullYear() - 1);
            this.sendTimestampFrom.set('value', date);
            filter = filter.eq('convoMailFrom', date.getTime());

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // メール配信一覧をgridにセット
            this.convoMailListGrid.set('collection', collection);
                //グリッドの詳細ボタンクリック時の動作を設定する
                //helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.convoMailListGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                //helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.moveConvoMailDetailPage(evt.item);
            }));
        },

        /**
         * 新規メール配信ダイアログの初期化。
         */
        initMailDialog: function() {
            // ダイアログの子要素の一番最初がダイアログの画面項目
            var dialog = this.mailDialog;
            var page = dialog.getChildren()[0];

            // 配信ダイアログから送信イベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                // 確認ダイアログ用メッセージ
                var confMessage = 'メールの配信を行います。<br>' + 'よろしいですか？';
                // 確認ダイアログの表示
                this.chain.confirm(confMessage, lang.hitch(this, function(chain) {
                    // OKが押下されたらstoreの機能でPOSTメソッドを発行する
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        Loader.wait(this.onSubmit()).then(function() {
                            // 成功時
                            // 配信ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログを表示
                            chain.infoComplete();
                        }, function(err) {
                            // 失敗時
                            // エラー内容をダイアログに表示する
                            chain.infoError(err);
                        });
                    }), function(err) {
                        // 失敗時
                        // エラー内容をダイアログに表示する
                        chain.infoError(err);
                    });
                }));
            }));
        },

        /**
         * 新規メール配信ダイアログの初期化。
         */
        initAutoMailDialog: function() {
            // ダイアログの子要素の一番最初がダイアログの画面項目
            var dialog = this.autoMailDialog;
            var page = dialog.getChildren()[0];

            // 配信ダイアログから送信イベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                console.debug(evt);
                // 送信確認ダイアログの表示
                this.chain.confirm('招集メールの配信を行います。<br>' + 'よろしいですか？', function(chain) {
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    var promise = Requester.post('/api/convoMails/mails', {
                        data : evt.value
                    }).then(function(){
                        // 完了ダイアログを表示
                        chain.infoComplete(function() {
                            location.reload();
                        });
                    }, function(err) {
                        // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                        console.error(err);
                        chain.infoError(err);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            }));
        },

        /**
         * 検索ボタンが押下された際の挙動。
         */
        onSubmit: function() {
            this.updateGridQuery();
            return false;
        },

        /**
         * 画面から入力された検索条件に合致する招集メール一覧を表示する。
         */
        updateGridQuery: function() {
            var value = this.form.value;
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 招集メール送信日(from)
            if (value.fromDate) {
                filter = filter.eq('convoMailFrom', value.fromDate.getTime());
            }
            // 招集メール送信日(to)
            if (value.toDate) {
                value.toDate.setHours(23);
                value.toDate.setMinutes(59);
                value.toDate.setSeconds(59);
                filter = filter.eq('convoMailTo', value.toDate.getTime());
            }
            // 送信先（グループ）
            if (value.groupId && value.groupId.length > 0) {
                filter = filter.eq('distGroupId', value.groupId);
            }
            // 配信種別
            if (value.occurEvent && value.occurEvent.length > 0) {
                filter = filter.eq('occuredCase', value.occurEvent);
            }
            // メール種別
            if (value.convoMailType && value.convoMailType.length > 0) {
                filter = filter.eq('convoMailType', value.convoMailType);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // 取得したcollectionをgridに設定
            this.convoMailListGrid.set('collection', collection);
        },

        /**
         * 新規メール配信ダイアログを表示する。
         */
        showSendMailDialog: function() {
            // ダイアログの子要素の一番最初がダイアログの画面項目
            var dialog = this.mailDialog;
            var page = dialog.getChildren()[0];
            page.initPage();
            this.mailDialog.show();
        },

        /**
         * 招集メール配信ダイアログを表示する。
         */
        showSendAutoMailDialog: function() {
            var dialog = this.autoMailDialog;
            var page = dialog.getChildren()[0];
            page.initPage();
            this.autoMailDialog.show();
        },

        /**
         * 配信設定ボタンが押下された際の挙動。
         */
        moveAutoMailAdminPage: function() {
            // テンプレート管理画面へ遷移
            Router.moveTo('convocation/automail');
        },

        /**
         * テンプレート管理ボタンが押下された際の挙動。
         */
        moveMailTempPage: function() {
            console.log('[テンプレート管理]ボタンがクリックされました。');
            // テンプレート管理画面へ遷移
            Router.moveTo('convocation/mailTemp');
        },

        /**
         * @param item 招集状況一覧グリッドの行データ
         * 招集状況管理の詳細ボタンが押下された際の挙動。
         */
        moveConvoMailDetailPage: function(item) {
            console.log('[詳細]ボタンがクリックされました。');
            // メール種別によって遷移先を変える
            if (item.convoMailType === '1') {
                // 招集メールの場合
                Router.moveTo('convocation/statusDetail', {
                    id: item.distributionId
                });
            } else {
                // 通知メールの場合
                Router.moveTo('convocation/notificationDetail', {
                    id: item.distributionId
                });
            }
        },

        /**
         * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
         * (startup同様、画面描画時に呼ばれる)
         * @param changeSize
         * @param resultSize
         */
        resize: function(changeSize, resultSize) {
            // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
            this.borderContainer.resize(changeSize, resultSize);
        }
    });
});

/**
 * 部新規登録ダイアログ用モジュール。　
 * @module app/organization/DeptRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DeptRegisterDialog.html',
    './_DeptDialogBase',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/config',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'dijit/form/ValidationTextBox'
], function(module, declare, lang, domStyle, template, _DeptDialogBase, UserInfo, USER_TYPE, config, Region) {
    /**
     * 部新規登録ダイアログ
     * @class DeptRegisterDialog
     * @extends module:app/organization/_DeptDialogBase~_DeptDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _DeptDialogBase,
    /** @lends module:app/view/page/DeptRegisterDialog~DeptRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 部を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('部新規登録ダイアログの[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.newDeptCd = value.deptCd;
                delete value.deptCd;
                // TODO 以下のログ書き出しを消去し、コメントアウトを外す

                // 振興局コード/市町村コード取得用の変数
                var municipalityCd = null;
                var regionCd = null;

                // 道・振興局・市町村のいずれかのコードを判定・取得する
                this.isRegion(value.municipalityCd).then(lang.hitch(this, function(isRegion){
                    if(isRegion){
                        regionCd = value.municipalityCd;
                    } else if(value.municipalityCd &&
                            value.municipalityCd !== config.municInfo.prefCd){
                        municipalityCd = value.municipalityCd;
                    } else if (value.municipalityCd &&
                            value.municipalityCd === config.municInfo.prefCd) {
                        municipalityCd = config.municInfo.prefMunicCd;
                    }
                }));

                // json形式で、Resourceに渡すための変数を作成する
                var param = {
                    'deptName': value.deptName,
                    'municipalityCd': municipalityCd,
                    'regionCd': regionCd
                };
                this.emit('register', {value: param});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();

            // ユーザ種別を判定する
            switch (UserInfo.getUserType()) {
            case USER_TYPE.PREFECTURE:
                this.municipalityCd.set('value', config.municInfo.prefCd);
                break;
            case USER_TYPE.REGION:
                this.municipalityCd.set('value', UserInfo.getRegionCd());
                domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
                break;
            case USER_TYPE.MUNICIPALITY:
                if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    // 市町村ユーザの場合のみ、選択ボタンを非表示にする
                    this.municipalityCd.set('noAllButton', true);
                }
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                break;
            case  USER_TYPE.OTHER_ORGAN:
                this.municipalityCd.set('value', config.municInfo.prefCd);
                break;
            }

        }
    });
});

/**
 * 気象情報発令履歴画面用モジュール。
 * @module app/weatherinfo/view/WeatherInfoHistoryPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/WeatherInfoHistoryPage.html',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/store/IdisRest',
    '../../config',
    // 以下、変数で受けないモジュール
    'app/common/view/StatementDialog',
    'app/view/form/KisyoAreaSelector',
    'dijit/form/Form',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    './WeatherInfoHistoryGrid'
], function(module, declare, lang, Router, template, Locator, _PageBase, Requester, IdisRest, config) {
    /**
     * 気象情報画面
     * @class WeatherInfoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/weatherinfo/view/WeatherInfoPage~WeatherInfoPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--weatherinfo',

        // ストア
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'kisyoHeaderId',
                target: '/api/WeatherInformations/history'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // フォームを初期化する
            this.initForm();
            // グリッドを初期化する
            this.initGrid();
        },

        /**
         * フォームを初期化する。
         */
        initForm: function() {
            this.inherited(arguments);
            // 遷移元のエリアをセット（デフォルト県全域）
            this.areaSelector.set('value', Locator.getQuery().areaCode===null ?
                config.municInfo.prefCd : Locator.getQuery().areaCode);
            // 検索時刻を初期化する
            this.initTime();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            this.updateGridQuery(this.form.get('value'));
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.historyGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // 各情報の対象エリアを取得する
                Requester.get('/api/WeatherInformations/areas?kisyoHeaderId=' + evt.item.kisyoHeaderId)
                    .then(lang.hitch(this, function(data){
                        this.showDetailDialog(data);
                }));
            }));
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // 情報文ページを表示する
            var url = '/data/adess-xml-xslt/' + item.kisyoHeaderId + '.xml';
            window.open(url, '_blank');

        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            if (value.areaCd) {
                filter = filter.eq('areaCode', value.areaCd);
            }

            if (value.reportDatetimeFrom) {
                var reportDatetimeFrom = new Date(value.reportDatetimeFrom).getTime();
                filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
            }

            if (value.reportDatetimeTo) {
                var reportDatetimeTo = new Date(value.reportDatetimeTo).getTime();
                filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.historyGrid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        // 検索時刻の初期値をセット
        initTime: function(){
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(startDate.getDate()-7);
            this.reportDatetimeTo._setValueAttr(endDate);
            this.reportDatetimeFrom._setValueAttr(startDate);
        },

        onReturnLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo');
        }
    });
});

/**
 * 河川水位基準値
 * @module app/model/RiverLevelReferenceLevel
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        idProperty: 'level',
        data: [
            {
                level: "4",
                label: "氾濫危険水位"
            }, {
                level: "3",
                label: "避難判断水位"
            }, {
                level: "2",
                label: "氾濫注意水位"
            }, {
                level: "1",
                label: "水防団待機水位"
            }
        ]
    });
});

/**
* 消防庁被害報告詳細画面用モジュール。
* @module app/fdmareport/FDMAReportDetailPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/FDMAReportDetailPage.html',
    'app/damage/_DamageReportPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/util/DateUtils',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/WordCountTextarea',
    'app/fdmareport/FDMAReportDetailDialog',
], function(module, declare, lang, json, domStyle, template,
    _DamageReportPageBase,Locator, Router, Requester, UserInfo, DisasterInfo, Loader, DialogChain, DateUtils) {

    /**
    * 消防庁被害報告詳細画面。
    * @class DamageReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/fdmareport/FDMAReportDetailPage~FDMAReportDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--fdmareport',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 報告ID
         */
        fdmaReportId: null,

        /**
          * 管理番号
          */
        admNum: null,

        /**
         * 続報番号
         */
        seqNum: null,

        /**
         * 報告確認フラグ
         */
        confirmFlg: false,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.fdmaReportDetailDialog);
        },

        constructor: function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
            }

            this.fdmaReportId = Locator.getQuery().fdmaReportId;
            this.chain = DialogChain.get(this);
        },

        startup: function() {
            this.inherited(arguments);
            this.initDetailPage();
        },

        initDetailPage: function() {
            var url = '/api/fdmaReports/';
            if (this.fdmaReportId) {
                url += this.fdmaReportId;
            } else {
                url += 'latest?disasterId=' + this._disasterId;
            }

            Requester.get(url)
            .then(lang.hitch(this, function(data) {
                var item = data;
                this.form.set('value', item);

                // 消防庁報告ID
                this.fdmaReportId = item.fdmaReportId;

                // 管理番号
                this.admNum = item.admNum;
                // 報告番号
                this.seqNum = item.seqNum;
                this.preSeqNum.innerHTML = item.seqNum;
                // 報告日時
                this.preReportTimestamp.innerHTML = DateUtils.format(item.reportTimestamp, {date: 'yyyy/MM/dd', time: 'HH:mm'});

                // 人的被害 死者
                this.preDeadNum.innerHTML = item.deadNum;
                // 人的被害 行方不明者
                this.preUnknownNum.innerHTML = item.unknownNum;
                // 人的被害 重傷者
                this.preSeriousNum.innerHTML = item.seriousNum;
                // 人的被害 軽傷者
                this.preMildNum.innerHTML = item.mildNum;

                // 住家被害 全壊
                this.preFullDestlHm.innerHTML = item.fullDestlHm;
                // 住家被害 半壊
                this.preHalfDestlHm.innerHTML = item.halfDestlHm;
                // 住家被害 一部破損
                this.prePartDestlHm.innerHTML = item.partDestlHm;
                // 住家被害 床上浸水
                this.preFldAbvHm.innerHTML = item.fldAbvHm;
                // 住家被害 床下浸水
                this.preFldBlwHm.innerHTML = item.fldBlwHm;

                // 備考
                this.preNote.innerHTML = item.note.replace(/\r?\n/g, '<br />');

                // バージョン(hidden)
                this.version.set('value', item.version);

                // 続報報告ボタン、更新ボタンの表示
                if(UserInfo.hasWriteAuthz('F05019')){
                    domStyle.set(this.detailDialog, 'display','');
                    if (item.statusFlg === "0" && item.latestSeqNumFlg) {
                        // 報告番号が最新かつ、訂正・取消されていない場合、ボタンを表示する。
                        domStyle.set(this.registerButton.domNode, 'display','');
                        domStyle.set(this.correctButton.domNode, 'display','');
                    } else {
                        domStyle.set(this.registerButton.domNode, 'display','none');
                        domStyle.set(this.correctButton.domNode, 'display','none');
                    }
                } else {
                    domStyle.set(this.detailDialog, 'display','none');
                    domStyle.set(this.registerButton.domNode, 'display','none');
                    domStyle.set(this.correctButton.domNode, 'display','none');
                }

                //定時報告から引き継いだ値を入力項目にセット
                if (Locator.getQuery().deadNum) {
                    this.deadNum.set('value',
                        Locator.getQuery().deadNum === 'NaN' ? '' : Locator.getQuery().deadNum);
                }
                if (Locator.getQuery().unknownNum) {
                    this.unknownNum.set('value',
                        Locator.getQuery().unknownNum === 'NaN' ? '' : Locator.getQuery().unknownNum);
                }
                if (Locator.getQuery().seriousNum) {
                    this.seriousNum.set('value',
                        Locator.getQuery().seriousNum === 'NaN' ? '' : Locator.getQuery().seriousNum);
                }
                if (Locator.getQuery().mildNum) {
                    this.mildNum.set('value',
                        Locator.getQuery().mildNum === 'NaN' ? '' : Locator.getQuery().mildNum);
                }
                if (Locator.getQuery().fullDestlHm) {
                    this.fullDestlHm.set('value',
                        Locator.getQuery().fullDestlHm === 'NaN' ? '' : Locator.getQuery().fullDestlHm);
                }
                if (Locator.getQuery().halfDestlHm) {
                    this.halfDestlHm.set('value',
                        Locator.getQuery().halfDestlHm === 'NaN' ? '' : Locator.getQuery().halfDestlHm);
                }
                if (Locator.getQuery().partDestlHm) {
                    this.partDestlHm.set('value',
                        Locator.getQuery().partDestlHm === 'NaN' ? '' : Locator.getQuery().partDestlHm);
                }
                if (Locator.getQuery().fldAbvHm) {
                    this.fldAbvHm.set('value',
                        Locator.getQuery().fldAbvHm === 'NaN' ? '' : Locator.getQuery().fldAbvHm);
                }
                if (Locator.getQuery().fldBlwHm) {
                    this.fldBlwHm.set('value',
                        Locator.getQuery().fldBlwHm === 'NaN' ? '' : Locator.getQuery().fldBlwHm);
                }
            }));

        },



        /**
         * 消防庁被害報告詳細ダイアログを表示する。
         */
        showFDMAReportDetailDialog: function() {
            this.fdmaReportDetailDialog.show();
        },

        registerFDMAReport: function() {
            this.updateDamageReport(true);
        },

        correctFDMAreport: function() {
            this.updateDamageReport(false);
        },

        updateDamageReport: function(newReportFlg){
            var sendData = this.form.get('value');
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // フォームのバリデーションを行う（独自チェック）
            if(!this.validateForm(sendData, newReportFlg)) {
                return false;
            }

            sendData.disasterId = this._disasterId;
            sendData.admNum = this.admNum;
            sendData.seqNum = this.seqNum;

            if (newReportFlg) {
                delete sendData.version;
            }

            var jsonStr = json.stringify(sendData);

            if(newReportFlg) {
                // 続報報告の場合、登録確認ダイアログを表示
                if (this.confirmFlg) {
                    // 続報報告時に訂正理由が入力されている場合、確認メッセージを表示する。
                    this.chain.confirm('OKボタンを押すと、消防庁に続報として情報連携します。<br>' +
                            '訂正する場合には、キャンセルしてから、訂正ボタンを押してください。',
                            lang.hitch(this, function(chain) {
                        var promise = Requester.post('/api/fdmaReports', {
                            data: jsonStr
                        }).then(function() {
                            chain.infoComplete(function(){
                                Router.moveTo('fdmaReport');
                            });
                        }, function(error) {
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    }));
                } else {
                    this.chain.confirmAdd(lang.hitch(this, function(chain) {
                        var promise = Requester.post('/api/fdmaReports', {
                            data: jsonStr
                        }).then(function() {
                            chain.infoComplete(function(){
                                Router.moveTo('fdmaReport');
                            });
                        }, function(error) {
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    }));
                }
            } else {
                // 訂正の場合、更新確認ダイアログを表示
                this.chain.confirmPut(lang.hitch(this, function(chain) {
                    var promise = Requester.put('/api/fdmaReports/' + this.fdmaReportId, {
                        data: jsonStr
                    }).then(function() {
                        chain.infoComplete(function(){
                            Router.moveTo('fdmaReport');
                        });
                    }, function(error) {
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                }));
            }
        },

        /**
         * 入力値の妥当性をチェックする。
         */
        validateForm: function(data, newReportFlg) {
            if (!data.reportTimestamp || data.reportTimestamp === '') {
                this.chain.info('報告日時を入力してください。', '入力エラー');
                return false;
            }
            // 訂正時は訂正理由の入力を求める
            if (!newReportFlg) {
                if (!data.correctCancelReason || data.correctCancelReason === '') {
                    this.chain.info('訂正時は訂正理由を入力してください。', '入力エラー');
                    return false;
                }
            }
            if (newReportFlg) {
                if (data.correctCancelReason || data.correctCancelReason !== '') {
                    // 続報報告時に訂正理由が入力されている場合、確認メッセージを表示する。
                    this.confirmFlg = true;
                } else {
                    this.confirmFlg = false;
                }
            }
            return true;
        }
    });
});

/**
 * 代理回答ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoDeputyAnswerDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/dom-style',
    'dojo/text!./templates/ConvoDeputyAnswerDialog.html', // テンプレート文字列
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase', // 共通基底クラス
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button'
], function(module, declare, domStyle, template, Requester, Loader, InfoDialog, DialogChain, _PageBase) {
    /**
     * 代理回答ダイアログ。
     * @class 代理回答ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoDeputyAnswerDialog~ConvoDeputyAnswerDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // 配信履歴詳細ID
            detailId: 0,
            // 職員コード
            employeeCd: 0,

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // 問２があるか否かのフラグ
                this.isQ2 = false;
                // 問３があるか否かのフラグ
                this.isQ3 = false;
                // 問４があるか否かのフラグ
                this.isQ4 = false;
                // 問５があるか否かのフラグ
                this.isQ5 = false;
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
            },

            /**
             * ダイアログの初期化
             */
            initDialog: function(item) {
                var self = this;
                this.detailId = item.distributionHistDetailId;
                this.employeeCd = item.employeeCd;

                // 質問のリストを取得する
                // （回答オブジェクトは各質問オブジェクトの中に格納されている）
                Requester.get('/api/convoMails/mails/questions/deputy/' +
                    item.distributionHistDetailId, {}
                ).then(function(data) {
                    // 質問リストから質問を取得
                    data.forEach(function(question) {
                        // リスト内の番号によってどのラベルに設定するかを判断する
                        switch (question.no) {
                        case 5010 : self.a1Label.innerHTML = question.distributionQContents +':';
                                    self.setSelectOptions(question.answers, self.a1);
                                    break;
                        case 5020 : self.a2Label.innerHTML = question.distributionQContents +':';
                                    self.setSelectOptions(question.answers, self.a2);
                                    self.isQ2 = true;
                                    break;
                        case 5030 : self.a3Label.innerHTML = question.distributionQContents +':';
                                    self.setSelectOptions(question.answers, self.a3);
                                    self.isQ3 = true;
                                    break;
                        case 5040 : self.a4Label.innerHTML = question.distributionQContents +':';
                                    self.setSelectOptions(question.answers, self.a4);
                                    self.isQ4 = true;
                                    break;
                        case 5050 : self.a5Label.innerHTML = question.distributionQContents +':';
                                    self.setSelectOptions(question.answers, self.a5);
                                    self.isQ5 = true;
                                    break;
                        default : break;
                        }
                    });
                    // 問２がない場合は、2つ目のセレクトボックスを非表示にする
                    if (!self.isQ2) {
                        domStyle.set(self.a2Division, 'display', 'none');
                    }
                    // 問３がない場合は、3つ目のセレクトボックスを非表示にする
                    if (!self.isQ3) {
                        domStyle.set(self.a3Division, 'display', 'none');
                    }
                    // 問４がない場合は、4つ目のセレクトボックスを非表示にする
                    if (!self.isQ4) {
                        domStyle.set(self.a4Division, 'display', 'none');
                    }
                    // 問５がない場合は、5つ目のセレクトボックスを非表示にする
                    if (!self.isQ5) {
                        domStyle.set(self.a5Division, 'display', 'none');
                    }
                }, function(error) {
                    console.log(error);
                });
            },

            /**
             * @param answers 回答リスト
             * @param selectbox optionを設定する対象となるセレクトボックス
             * 回答をもとにセレクトボックスを設定する。
             */
            setSelectOptions: function(answers, selectbox) {
                // オプションの配列
                var optionList = [];
                // 回答を配列にセット
                // labelは「(回答番号):(回答内容)」の形で表示される
                answers.forEach(function(answer) {
                    optionList.push({
                        label: answer.distributionAContents,
                        value: answer.no
                    });
                });
                // 作成した配列をセレクトボックスのoptionとして設定
                selectbox.set('options', optionList);
            },

            /**
             * 登録ボタンが押下された際に呼ばれるメソッド。
             */
            onSubmit :function() {
                if (this.validate()) {
                    // formに渡された登録内容
                    var formData = this.form.get('value');
                    var arr = [formData.a1, formData.a2, formData.a3, formData.a4, formData.a5];
                    var value = {
                        answers: arr,
                        note: formData.note
                    };
                    //更新確認ダイアログを表示
                    this.chain.confirm('参集情報を代理回答します。よろしいですか？', function(chain) {
                        var promise = Requester.post('/api/convoMails/mails/answer/deputy/' +
                            this.detailId + '/' + this.employeeCd, {
                            data: value
                        }).then(function(result) {
                            if (result) {
                                // 成功時は完了ダイアログを表示
                                chain.infoComplete(function() {
                                    location.reload();
                                });
                            } else {
                                chain.info('対象の職員招集要請は既に削除されています。', 'エラー');
                            }
                        }, function(error) {
                            // 失敗時はエラーログ出力、エラーダイアログを表示
                            console.log(error);
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                }
            },

            /**
             * 入力値の妥当性チェック
             */
            validate: function() {
                var formData = this.form.get('value');
                // 問１の必須チェック
                if ((!formData.a1 || formData.a1.length === 0)) {
                    InfoDialog.show('入力エラー', '問１の回答が選択されていません。');
                    return false;
                }
                // 問２の必須チェック
                if (this.isQ2 && (!formData.a2 || formData.a2.length === 0)) {
                    InfoDialog.show('入力エラー', '問２の回答が選択されていません。');
                    return false;
                }
                // 問３の必須チェック
                if (this.isQ3 && (!formData.a3 || formData.a3.length === 0)) {
                    InfoDialog.show('入力エラー', '問３の回答が選択されていません。');
                    return false;
                }
                // 問４の必須チェック
                if (this.isQ4 && (!formData.a4 || formData.a4.length === 0)) {
                    InfoDialog.show('入力エラー', '問４の回答が選択されていません。');
                    return false;
                }
                // 問５の必須チェック
                if (this.isQ5 && (!formData.a5 || formData.a5.length === 0)) {
                    InfoDialog.show('入力エラー', '問５の回答が選択されていません。');
                    return false;
                }
                return true;
            }
        });
});

/**
* 気象情報発令履歴画面用モジュール。
* @module app/weatherinfo/view/WeatherInfoHistoryPage
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, IdisGrid, helper, CommonColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            CommonColumn.formatDateTime('reportDatetime', '発表日時'),
            CommonColumn.text
        ]
    });
});

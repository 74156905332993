/**
 * 防災ポータル情報配信テンプレート登録用ダイアログモジュール。
 * @module app/emergency/EmergencyNewsTemplateRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/EmergencyNewsTemplateRegisterDialog.html',
    './_EmergencyNewsTemplateDialogBase'
    // 以下、変数で受けないモジュール
    // 変数で受けないモジュールなし
], function(module, declare, template, _EmergencyNewsTemplateDialogBase) {
    /**
     * 防災ポータル情報配信テンプレート登録用ダイアログ
     * @class EmergencyNewsTemplateDialog
     * @extends module:./_EmergencyNewsTemplateDialogBase~_EmergencyNewsTemplateDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _EmergencyNewsTemplateDialogBase, {
        // テンプレート文字列
        templateString: template,

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ダイアログの初期化
         */
        initDialog: function() {
            this.form.reset();
        },

        /**
         * 新規登録
         */
        onSubmit: function(){
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

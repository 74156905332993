/**
 * 職員管理 ファイル読み込みダイアログ用モジュール。
 * @module app/convocation/view/employee/ConvoEmployeeFileUploadDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoEmployeeFileUploadDialog.html',
    'dojo/request/iframe',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/service/Requester',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button'
], function(module, array, declare, lang, template, iframe, InfoDialog, _PageBase, DialogChain, Loader, Requester, DisasterInfo) {
    /**
     * 職員管理ファイルアップロードダイアログ
     * @class ConvoEmployeeFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        _fileId: null,

        infoDialog : null,

        file: [],

        /**
         * constructor
         */
        constructor: function() {
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
            if (!this.validate()) {
                return false;
            }
            this.chain.confirmAdd(function(chain) {
                var reqid = 'DP_EMPLOYEE_LIST_IN';

                // パラメータの定数化
                var reqParams = {
                    reqid : reqid,
                    pathweb : 'out/xoblos/DP_EMPLOYEE_LIST_IN/',
                    errpathweb : 'out/xoblos/err/',
                    regapi : '/api/convoMails/employees/InportFile'
                };

                var url = '/api/xoblos/uploadReport' +
                        '?reqid=' + reqid +
                        '&disid=' + DisasterInfo.getDisasterId() +
                        '&path=' + reqParams.pathweb;

                var fileName = null;
                var promise = iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(ret) {
                    fileName = ret.fileName;
                    // アップロードしたファイルパスとエラーメッセージjson、
                    // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                    return Requester.post('/api/xoblos/upload',{
                        data: {
                            reqid: reqid,
                            disid: DisasterInfo.getDisasterId(),
                            filename: ret.fileName
                        }
                    }).then(lang.hitch(this, function(data) {
                        // ResultCode=0以外（xoBlosエラー）の場合にはエラーダイアログを表示する。
                        if (data.ResultCode !== 0){
                            // xoblosの内部エラー(必須項目のみ入力など)
                            var err = new Error('xoblos failed');
                            this.chain.infoError(err, function() {
                                this.chain.hide();
                            });
                        } else {
                            // xoblosエラーが出なかった場合は、返ってきたJSONデータをJavaに転送し、登録処理を行う
                            var regapi = reqParams.regapi;
                            return Requester.post(regapi, {
                                data: data.data
                            }).then(lang.hitch(this, function(data) {
                                console.log(data);
                                if (data.nonExistentDivisionNames.length === 0 && data.nonExistentEmployees.length === 0) {
                                    // 成功した場合は完了ダイアログを表示する。
                                    chain.infoComplete(function() {
                                        // 確認ダイアログを閉じる
                                        chain.hide();
                                        // 親のダイアログを閉じる。
                                        this.attachFile.reset();
                                        this.getParent().hide();
                                        this.emit('upload');
                                    });
                                } else {
                                    var message = '';
                                    message += 'ファイル読み込みを完了しました。<br>';
                                    message += 'ただし下記は、入力情報に誤りがあるため、登録・更新を行いませんでした。<br><br>';
                                    if (data.nonExistentDivisionNames.length > 0) {
                                        message += '<table style="margin-left: auto;margin-right: auto">';
                                        message += '<tr><th style="text-align:center"><b>課</b></th><tr>';
                                        array.forEach(data.nonExistentDivisionNames, function(divisionName){
                                            message += '<tr><td>' + divisionName;
                                            message += '</td></tr>';
                                        });
                                        message += '</table>';
                                    }
                                    if (data.nonExistentEmployees.length > 0) {
                                        message += '<table style="margin-left: auto;margin-right: auto">';
                                        message += '<tr><th style="text-align:center"><b>管理番号</b></th><tr>';
                                        array.forEach(data.nonExistentEmployees, function(employeeCd){
                                            message += '<tr><td>' + employeeCd;
                                            message += '</td></tr>';
                                        });
                                        message += '</table>';
                                    }

                                    var title = '完了';
                                    // ダイアログにメッセージを表示
                                    this.chain.info(message, title, function(){
                                        // 確認ダイアログを閉じる
                                        this.chain.hide();
                                        // 親のダイアログを閉じる。
                                        this.getParent().hide();
                                        this.attachFile.reset();
                                        this.emit('upload');
                                    });
                                }
                            }), lang.hitch(this, function(error) {
                                // 職員情報登録・更新失敗エラー(Excelファイルデータの整合性(部局、課の組み合わせが正しいか)など)
                                this.chain.infoError(error, function() {
                                    this.chain.hide();
                                });
                            }));
                        }
                    }), lang.hitch(this, function(error) {
                        // xoblosのエラー(呼び出し失敗など)
                        this.chain.infoError(error, function() {
                            this.chain.hide();
                        });
                    }));
                }));
                // ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * 入力チェック
         */
        validate: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile.getFileList().length === 0) {
                InfoDialog.show('入力チェックエラー', 'ファイルが選択されていません');
                return false;
            }
            return true;
        },

        /**
         * 報告書読み込みで呼ばれる
         */
        loadFile: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile._files.length === 0) {
                return;
            }

            // エクセルファイル以外の場合はエラー
            var file = this.attachFile._files[0];
            if(file.name.indexOf('.xlsx') === -1) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エクセルファイルファイルを選択してください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                this.attachFile.reset();
                return;
            }
        }
    });
});

/**
* 定時報告詳細画面用モジュール。
* @module app/scheduledReport/ScheduledReportDetailPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo',
    'dojo/Deferred',
    'dojo/_base/array',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom-style',
    'dojo/text!./templates/ScheduledReportDetailPage.html',
    'dojo/json',
    'dojo/date/locale',
    './_ScheduledReportPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'app/common/consts/FunctionCd',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    './consts/ReportType',
    // 以下、変数として受け取らないモジュール
    './ScheduledReportMuniAutoAggregateDialog',
    './ScheduledReportRegistDialog'
], function (module, declare, lang, dojo, Deferred, array, ItemFileWriteStore, domStyle, template,
    json, locale, _ScheduledReportPageBase, Locator, Router, Requester,
    USER_TYPE, UserInfo, FunctionCd, Loader, DialogChain, ReportType) {

    /**
    * 定時報告詳細画面。
    * @class DamageReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _ScheduledReportPageBase,
        /** @lends module:app/scheduledReport/DamageReportPage~DamageReportPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--scheduledreport',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            // 管理番号セレクトボックス初期化フラグ
            initAdmNumSelectFlg: true,

            /**
             * 報告ID
             */
            scheduledReportId: null,

            /**
             * 報告種別
             */
            reportType: null,

            /**
             * 市町村
             */
            _municipalityCd: null,

            /**
             * 集計時点
             */
            _aggrTimestamp: null,

            /**
             * 管理番号
             */
            _admNum: null,

            /**
             * 報告済みフラグ
             */
            _reportedFlg: null,

            /**
             * 組織（報告者）
             */
            _organization: null,


            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.scheduledReportRegistDialog);
                //this.municipalityCd.set('value',Locator.getQuery().municipalityCd);
            },

            constructor: function () {
                this.inherited(arguments);
                this.scheduledReportId = Locator.getQuery().scheduledReportId;
                this.chain = DialogChain.get(this);

            },

            startup: function () {
                this.inherited(arguments);
                this.initDetailPage();
            },

            initDetailPage: function () {
                if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                    this.autoAggrigateInMunicButton.set('label', '県報告（外部公開可能）<br>のみ集計');
                    domStyle.set(this.scheduledReportPrefLabel, 'display', 'none');
                } else if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                    domStyle.set(this.scheduledReportPrefLabel, 'display', 'none');
                }
                Requester.get('/api/scheduledReports/' + this.scheduledReportId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (data) {
                    var item = data;
                    this.form.set('value', item);
                    //変数
                    this._admNum = item.admNum;
                    this._reportedFlg = item.reportedFlg;

                    this._aggrTimestamp = item.aggrTimestamp;
                    this.aggrTimestampForDept.innerHTML = locale.format(new Date(item.aggrTimestamp)) || '&nbsp;';
                    this.aggrTimestampForMuni.innerHTML = locale.format(new Date(item.aggrTimestamp)) || '&nbsp;';
                    //self.note.value = item.note;

                    //ボタンの出し分け
                    if (item.reportedFlg === '1') {
                        //代理登録権限が無い場合は削除、訂正ボタンを非表示とする
                        if (!UserInfo.hasProxyWriteAuthz(FunctionCd.DAMAGE_SCHE_ADM)) {
                            //削除ボタン
                            domStyle.set(this.deleteButton.domNode, 'display', 'none');
                            //市町・危機管理・管理または市町・危機管理・一般(市町村ユーザかつ登録権限以上を持っている)に該当しない場合に一時保存、登録ボタンを非表示とする
                            if (!(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY && UserInfo.hasWriteAuthz(FunctionCd.DAMAGE_SCHE_ADM))) {
                                //訂正ボタン
                                domStyle.set(this.correctButton.domNode, 'display', 'none');
                            }
                        }

                        //登録ボタン
                        domStyle.set(this.registerButton.domNode, 'display', 'none');
                        //一時保存ボタン
                        domStyle.set(this.temporarilySavedButton.domNode, 'display', 'none');
                    } else {
                        //代理登録権限が無い場合は削除、一時保存、登録ボタンを非表示とする
                        if (!UserInfo.hasProxyWriteAuthz(FunctionCd.DAMAGE_SCHE_ADM)) {
                            //削除ボタン
                            domStyle.set(this.deleteButton.domNode, 'display', 'none');
                            
                            //市町・危機管理・管理または市町・危機管理・一般(市町村ユーザかつ登録権限以上を持っている)に該当しない場合に一時保存、登録ボタンを非表示とする
                            if (!(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY && UserInfo.hasWriteAuthz(FunctionCd.DAMAGE_SCHE_ADM))) {
                                //一時保存ボタン
                                domStyle.set(this.temporarilySavedButton.domNode, 'display', 'none');
                                //登録ボタン
                                domStyle.set(this.registerButton.domNode, 'display', 'none');
                            }
                        }
                        
                        //更新ボタン
                        domStyle.set(this.correctButton.domNode, 'display', 'none');
                    }

                    //[前報の値を入力]ボタン
                    this._preReportId = null;
                    array.forEach(item.reportInfos, lang.hitch(this, function (reportInfo) {
                        // 表示している報数より過去の、最新報IDを保管しておく
                        if (reportInfo.admNum < this._admNum && reportInfo.reportedFlg === '1') {
                            this._preReportId = reportInfo.scheduledReportId;
                        }
                    }));
                    // コピー可能な過去報がある場合のみ、ボタンを表示する
                    if (this._preReportId) {
                        domStyle.set(this.copyPreReportButton.domNode, 'display', '');
                    } else {
                        domStyle.set(this.copyPreReportButton.domNode, 'display', 'none');
                    }

                    if (item.reportedFlg !== '1') {
                        // 登録時、「前回」「今回」の項目見出しを表示する
                        dojo.query('.preReportDispHeader').forEach(function (node) {
                            node.style.display = '';
                        });
                        if (this._preReportId) {
                            //過去報がある場合の処理
                            dojo.query('.preReportDisp').forEach(function (node) {
                                // 「前回」項目表示
                                node.style.display = '';
                            });
                            // 「前回」項目に前報の値を表示
                            this.setPreReportDisp();
                            if (item.version === 1) {
                                // 一時保存もしていない、初回の登録時の場合「今回」各項目に過去報の値を初期値としてセットする
                                this.copyPreReport();
                            }
                        }
                    }

                    //管理番号によらず共通の情報や、ヘッダー内の情報は初回のみ処理
                    if (this.initAdmNumSelectFlg) {
                        this._reportType = item.reportType;
                        this._municipalityCd = item.municipalityCd;
                        var reportType = item.reportType;

                        //報告ヘッダー項目の処理
                        if (reportType === '01') {
                            //市町報告の場合
                            domStyle.set(this.municipalityForm, 'display', '');
                            this.admNumSelect = this.admNumSelectforMuni;
                            this.municipalityCd.set('value', item.municipalityCd);
                            this.municipalityCd.set('noAllButton', true);
                            domStyle.set(this.autoAggrigateInMunicButton.domNode, 'display', '');
                            domStyle.set(this.autoAggrigateAllButton.domNode, 'display', '');
                        } else {
                            //部局報告の場合
                            this.admNumSelect = this.admNumSelectforDept;
                            domStyle.set(this.deptForm, 'display', '');
                            this.deptName.innerHTML = ReportType[reportType].deptName;
                            //農林水産・土木・その他のみ自動集計ボタンを表示 → 一律で表示しない方針に変更
                            // if(reportType === '05' || reportType === '06' || reportType === '09'){
                            //     domStyle.set(self.autoAggrigateButton.domNode, 'display','');
                            // }

                        }
                        //ログインユーザの所属情報を取得
                        this._organization = data.userOrganization;
                        this.organizationName.innerHTML = data.userOrganizationName;
                        //報告種別に対応するフォームのみ表示
                        domStyle.set(dojo.byId(ReportType[reportType].divId), 'display', '');

                        var selectData = [];
                        array.forEach(item.reportInfos, function (reportInfo) {
                            selectData.push({
                                scheduledReportId: reportInfo.scheduledReportId,
                                admNum: String(reportInfo.admNum),
                                admNumStr: '第' + String(reportInfo.admNum) + '報'
                            });
                        });

                        var selectContent = {
                            identifier: 'scheduledReportId',
                            label: 'admNumStr',
                            items: selectData
                        };

                        var scheduledReportItemFileWriteStore = new ItemFileWriteStore({
                            data: selectContent
                        });
                        this.admNumStore = scheduledReportItemFileWriteStore;
                        this.admNumSelect.set('sortByLabel', false);
                        this.admNumSelect.set('store', scheduledReportItemFileWriteStore);
                        this.admNumSelect.set('value', this.scheduledReportId);
                        this.admNumSelect.on('change', lang.hitch(this, this.onAdmNumChange));
                        this.initAdmNumSelectFlg = false;
                    }

                    // 変更履歴(詳細時のみ表示)
                    if (item.reportedFlg === '1') {
                        dojo.byId('reportHistorySelectForm').style.display = '';
                        var reportHistoryInfoArray = [];
                        array.forEach(item.reportInfos, lang.hitch(this, function (reportInfo) {
                            // 変更履歴用に、過去報情報を配列に格納する
                            if (reportInfo.admNum <= this._admNum && reportInfo.reportedFlg === '1') {
                                var datetime = new Date(reportInfo.reportTimestamp);
                                var date = datetime.getFullYear() + '/' + (datetime.getMonth() + 1) +
                                    '/' + datetime.getDate();
                                var time = datetime.getHours() + ':' + ('0' + datetime.getMinutes()).slice(-2);
                                reportInfo.convertReportTimeStamp = date + ' ' + time;

                                reportHistoryInfoArray.push(reportInfo);
                            }
                        }));
                        // 変更履歴の情報をセレクトボックスにセットする
                        var reportHistorySelectData = [];
                        array.forEach(reportHistoryInfoArray, function (reportHistoryInfo) {
                            reportHistorySelectData.push({
                                scheduledReportId: reportHistoryInfo.scheduledReportId,
                                historyInfo: reportHistoryInfo.convertReportTimeStamp +
                                    ' - ' + reportHistoryInfo.reportAuthorName
                            });
                        });

                        var reportHistorySelectContent = {
                            identifier: 'scheduledReportId',
                            label: 'historyInfo',
                            items: reportHistorySelectData
                        };

                        var reportHistoryItemFileWriteStore = new ItemFileWriteStore({
                            data: reportHistorySelectContent
                        });
                        this.historyStore = reportHistoryItemFileWriteStore;
                        this.reportHistorySelect.set('sortByLabel', false);
                        this.reportHistorySelect.set('store', reportHistoryItemFileWriteStore);
                        this.reportHistorySelect.set('value', this.scheduledReportId);
                        this.reportHistorySelect.on('change', lang.hitch(this, function (historyId) {
                            this.onReportHistoryChange(historyId);
                        }));
                    } else {
                        dojo.byId('reportHistorySelectForm').style.display = 'none';
                    }
                }));

            },

            /**
             * 検索
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            registerScheduledReport: function () {
                this.updateDamageReport(true, true);
            },

            temporarilySavedScheduledReport: function () {
                this.updateDamageReport(true, false);
            },

            correctScheduledReport: function () {
                this.updateDamageReport(false, true);
            },

            deleteScheduledReport: function () {
                var version = this.form.get('value').version;
                //削除確認ダイアログの表示
                this.chain.confirmDel(function (chain) {
                    var promise = Requester.del('/api/scheduledReports/' + this.scheduledReportId, {
                        query: {
                            version: version
                        }
                    })
                        .then(function () {
                            chain.infoComplete(function () {
                                // 一覧画面に移動
                                Router.moveTo('report/sche');
                            });
                        }, function (error) {
                            console.log(error);
                            chain.infoError(error);
                        });
                    //ローダーの表示
                    Loader.wait(promise);
                });

            },

            updateDamageReport: function (newReportFlg, isActual) {
                var sendData = this.form.get('value');
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm(sendData)) {
                    return false;
                }

                sendData.disasterId = this._disasterId;
                sendData.organization = this._organization;
                sendData.reportedFlg = '1';
                delete sendData.admNum;
                delete sendData.municipalityCd;

                //登録確認ダイアログのメッセージ
                var message = null;

                //isActual true : 登録
                if (isActual) {
                    message = '入力内容を登録します。';
                }
                //isActual false : 一時保存
                else {
                    message = '入力内容を一時保存します。<br>一覧上では未報告状態として表示されます。';

                    // 一時保存の場合：reportedFlgを0にセット(未報告のまま、一時保存)
                    sendData.reportedFlg = '0';
                }

                var jsonStr = json.stringify(sendData);

                if (newReportFlg) {
                    //登録確認ダイアログを表示
                    this.chain.confirm(message, lang.hitch(this, function (chain) {
                        var promise = Requester.put('/api/scheduledReports/' + this.scheduledReportId, {
                            data: jsonStr,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(lang.hitch(this, function () {
                            this.chain.infoComplete(function () {
                                Router.moveTo('report/sche');
                            });
                        }), function (error) {
                            console.log(error);
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    }));

                } else {
                    //更新確認ダイアログを表示
                    this.chain.confirmPut(lang.hitch(this, function (chain) {
                        var promise = Requester.put('/api/scheduledReports/' + this.scheduledReportId, {
                            data: jsonStr,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            chain.infoComplete(function () {
                                Router.moveTo('report/sche');
                            });

                        }, function (error) {
                            console.log(error);
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);

                    }));
                }

            },

            /**
             *  登録確認ダイアログ表示
             */
            showScheduledReportRegistDialog: function (sendData) {
                var registDialog = this.scheduledReportRegistDialog;
                // ダイアログのgetDetailDataメソッドを呼び、入力値を設定する
                var page = registDialog.getChildren()[0];
                page.setDetailData(sendData);
                this.scheduledReportRegistDialog.show();
            },

            /**
             * 入力値の妥当性をチェックする。
             */
            validateForm: function (data) {
                if (!data.reportAuthorName || data.reportAuthorName === '') {
                    this.chain.info('報告者名を選択してください。', '入力エラー');
                    return false;
                }
                if (!data.reportTimestamp || data.reportTimestamp === '') {
                    this.chain.info('報告日時を登録してください。', '入力エラー');
                    return false;
                }
                return true;
            },

            onAdmNumChange: function (reportId) {
                //管理番号を変更した場合の処理
                if (reportId === this.scheduledReportId) {
                    return false;
                }
                this.scheduledReportId = reportId;
                this.initDetailPage();
            },

            /**
             * 変更履歴を変更した場合の処理
             */
            onReportHistoryChange: function (historyId) {
                var self = this;
                Requester.get('/api/scheduledReports/' + historyId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    delete item.reportAuthorName;
                    delete item.reportTimestamp;
                    delete item.version;
                    delete item.municipalityCd;

                    self.form.set('value', item);

                }, function (error) {
                    self.chain.infoError(error);
                });
            },

            //被害情報自動集計(都報告（マスコミ公開可）)
            autoAggrigateInMunic: function () {
                this.autoAggrigate(['41']);
            },

            //被害情報自動集計(全量)
            autoAggrigateAll: function () {
                this.autoAggrigate(['11', '31', '41']);
            },

            //被害情報自動集計
            autoAggrigate: function (reportStatusList) {
                //種別、市町コード、日時、報告状況の検索条件
                var param = '?reportType=' + this._reportType;
                param += '&disasterId=' + this._disasterId;
                if (this._municipalityCd) {
                    param += '&municipalityCd=' + this._municipalityCd;
                }
                param += '&aggrDateTime=' + this._aggrTimestamp;
                if (reportStatusList && reportStatusList.length > 0) {
                    var reportStatus = reportStatusList.join(',');
                    param += '&reportStatus=' + reportStatus;
                }

                var self = this;
                Requester.get('/api/scheduledReports/autoAggregate' + param, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    delete item.reportAuthorName;
                    delete item.reportTimestamp;
                    delete item.version;
                    delete item.municipalityCd;

                    self.form.set('value', item);
                    console.log(item);

                }, function (error) {
                    self.chain.infoError(error);
                });
            },

            /**
             * 区市町村連携被害数値情報自動集計ボタン押下時の処理
             */
            muniAutoAggregateButtonClick: function () {
                // ダイアログの画面を初期化する
                var dialog = this.muniAutoAggregateDialog.getChildren()[0];
                dialog.initDialog(this._municipalityCd, this._disasterId, this);
                // ダイアログを表示する
                this.muniAutoAggregateDialog.show();
            },

            /**
             * 「前回」項目に前報の値を表示するよう設定
             */
            setPreReportDisp: function () {
                Requester.get('/api/scheduledReports/' + this._preReportId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (item) {
                    this.deadNumDisp.innerHTML = item.deadNum || '0';
                    this.unknownNumDisp.innerHTML = item.unknownNum || '0';
                    this.seriousNumDisp.innerHTML = item.seriousNum || '0';
                    this.mildNumDisp.innerHTML = item.mildNum || '0';
                    this.unknownSympNumDisp.innerHTML = item.unknownSympNum || '0';
                    this.fullDestlHmDisp.innerHTML = item.fullDestlHm || '0';
                    this.fullDestlHhDisp.innerHTML = item.fullDestlHh || '0';
                    this.fullDestlHrDisp.innerHTML = item.fullDestlHr || '0';
                    this.halfDestlHmDisp.innerHTML = item.halfDestlHm || '0';
                    this.halfDestlHhDisp.innerHTML = item.halfDestlHh || '0';
                    this.halfDestlHrDisp.innerHTML = item.halfDestlHr || '0';
                    this.partDestlHmDisp.innerHTML = item.partDestlHm || '0';
                    this.partDestlHhDisp.innerHTML = item.partDestlHh || '0';
                    this.partDestlHrDisp.innerHTML = item.partDestlHr || '0';
                    this.fldAbvHmDisp.innerHTML = item.fldAbvHm || '0';
                    this.fldAbvHhDisp.innerHTML = item.fldAbvHh || '0';
                    this.fldAbvHrDisp.innerHTML = item.fldAbvHr || '0';
                    this.fldBlwHmDisp.innerHTML = item.fldBlwHm || '0';
                    this.fldBlwHhDisp.innerHTML = item.fldBlwHh || '0';
                    this.fldBlwHrDisp.innerHTML = item.fldBlwHr || '0';
                    this.unstlDestlHmDisp.innerHTML = item.unstlDestlHm || '0';
                    this.unstlDestlHhDisp.innerHTML = item.unstlDestlHh || '0';
                    this.unstlDestlHrDisp.innerHTML = item.unstlDestlHr || '0';
                }), lang.hitch(this, function (error) {
                    this.chain.infoError(error);
                }));
            },

            //全報の値を入力
            copyPreReport: function () {
                var self = this;
                Requester.get('/api/scheduledReports/' + this._preReportId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    delete item.reportAuthorName;
                    delete item.reportTimestamp;
                    delete item.version;
                    delete item.municipalityCd;

                    self.form.set('value', item);

                }, function (error) {
                    self.chain.infoError(error);
                });
            },

            calcWelfSum: function () {
                var item = this.form.get('value');
                this.sumWelfNum.set('value',
                    (item.oldWelfNum || 0) + (item.childWelfNum || 0) + (item.handyWelfNum || 0) + (item.otrWelfNum || 0));
                this.sumWelfMny.set('value',
                    (item.oldWelfMny || 0) + (item.childWelfMny || 0) + (item.handyWelfMny || 0) + (item.otrWelfMny || 0));
            },
            calcHygSum: function () {
                var item = this.form.get('value');
                this.sumHygNum.set('value', (item.medicNum || 0) + (item.otrHygNum || 0));
                this.sumHygMny.set('value', (item.medicMny || 0) + (item.otrHygMny || 0));
            },
            calcEnvSum: function () {
                var item = this.form.get('value');
                this.sumEnvNum.set('value',
                    (item.wtrSplyNum || 0) + (item.garbageNum || 0) + (item.humanWstNum || 0) +
                    (item.indsWstNum || 0) + (item.drainNum || 0) + (item.otrEnvNum || 0));
                this.sumEnvMny.set('value',
                    (item.wtrSplyMny || 0) + (item.garbageMny || 0) + (item.humanWstMny || 0) +
                    (item.indsWstMny || 0) + (item.drainMny || 0) + (item.otrEnvMny || 0));
            },
            calcBizSum: function () {
                var item = this.form.get('value');
                this.sumBizNum.set('value',
                    (item.commercialNum || 0) + (item.industrialNum || 0) + (item.miningNum || 0) +
                    (item.tourismNum || 0) + (item.shipNum || 0) + (item.otrBizNum || 0));
                this.sumBizMny.set('value',
                    (item.commercialMny || 0) + (item.industrialMny || 0) + (item.miningMny || 0) +
                    (item.tourismMny || 0) + (item.shipMny || 0) + (item.otrBizMny || 0));
            },
            calcCropSum: function () {
                var item = this.form.get('value');
                this.sumCropNum.set('value',
                    (item.cropArea ? 1 : 0) + (item.treeArea ? 1 : 0) + (item.lvStkNum || 0) +
                    (item.agriStkNum || 0) + (item.otrCropNum || 0));
                this.sumCropMny.set('value',
                    (item.cropMny || 0) + (item.treeMny || 0) + (item.lvStkMny || 0) +
                    (item.agriStkMny || 0) + (item.otrCropMny || 0));
            },
            calcAgriFacSum: function () {
                var item = this.form.get('value');
                this.sumAgriFacNum.set('value',
                    (item.agriGrdnNum || 0) + (item.agriBarnNum || 0) + (item.agriFacOtrNum || 0));
                this.sumAgriFacMny.set('value',
                    (item.agriGrdnMny || 0) + (item.agriBarnMny || 0) + (item.agriFacOtrMny || 0));
            },
            calcAgriFieldSum: function () {
                var item = this.form.get('value');
                this.sumAgriFieldNum.set('value', (item.riceFieldArea || 0) + (item.fieldArea || 0));
                this.sumAgriFieldMny.set('value', (item.riceFieldMny || 0) + (item.fieldMny || 0));
            },
            calcAgriFieldFacSum: function () {
                var item = this.form.get('value');
                this.sumAgriFieldFacNum.set('value', (item.agriFacNum || 0) + (item.cstlFacNum || 0));
                this.sumAgriFieldFacMny.set('value', (item.agriFacMny || 0) + (item.cstlFacMny || 0));
            },
            calcFrstSum: function () {
                var item = this.form.get('value');
                this.sumFrstMny.set('value',
                    (item.frstFacMny || 0) + (item.mntnClpsMny || 0) + (item.frstPrvtMny || 0) +
                    (item.workRoadFacMny || 0) + (item.frstPrdctMny || 0) + (item.afrstMny || 0) +
                    (item.frstPrdctFacMny || 0) + (item.otrFrstMny || 0));
            },
            calcFishSum: function () {
                var item = this.form.get('value');
                this.sumFishNum.set('value',
                    (item.coastNum || 0) + (item.fistPortNum || 0) + (item.fishShrdNum || 0) +
                    (item.seafoodNum || 0) + (item.fishBoatNum || 0) + (item.aquaCltrNum || 0) +
                    (item.fishGrndNum || 0) + (item.fishStkNum || 0) + (item.otrFishNum || 0));
                this.sumFishMny.set('value',
                    (item.coastMny || 0) + (item.fistPortMny || 0) + (item.fishShrdMny || 0) +
                    (item.seafoodMny || 0) + (item.fishBoatMny || 0) + (item.aquaCltrMny || 0) +
                    (item.fishGrndMny || 0) + (item.fishStkMny || 0) + (item.otrFishMny || 0));
            },
            calcPubCvlSum: function () {
                var item = this.form.get('value');
                this.sumPubCvlNum.set('value',
                    (item.riverNum || 0) + (item.cstrCoatNum || 0) + (item.hrbrCoatNum || 0) +
                    (item.saboNum || 0) + (item.slidePrvtNum || 0) + (item.clpsPrvtNum || 0) +
                    (item.roadNum || 0) + (item.bridgeNum || 0) + (item.harborNum || 0) +
                    (item.sewerNum || 0) + (item.otrPubCvlNum || 0));
                this.sumPubCvlMny.set('value',
                    (item.riverMny || 0) + (item.cstrCoatMny || 0) + (item.hrbrCoatMny || 0) +
                    (item.saboMny || 0) + (item.slidePrvtMny || 0) + (item.clpsPrvtMny || 0) +
                    (item.roadMny || 0) + (item.bridgeMny || 0) + (item.harborMny || 0) +
                    (item.sewerMny || 0) + (item.otrPubCvlMny || 0));
            },
            calcCityCvlSum: function () {
                var item = this.form.get('value');
                this.sumCityCvlNum.set('value', (item.parkNum || 0) + (item.otrCityCvlNum || 0));
                this.sumCityCvlMny.set('value', (item.parkMny || 0) + (item.otrCityCvlMny || 0));
            },
            calcCompSum: function () {
                var item = this.form.get('value');
                this.sumCompNum.set('value', (item.pubCompNum || 0) + (item.otrCompNum || 0));
                this.sumCompMny.set('value', (item.pubCompMny || 0) + (item.otrCompMny || 0));
            },
            calcEducSum: function () {
                var item = this.form.get('value');
                this.sumEducNum.set('value',
                    (item.highSclNum || 0) + (item.cmplSclNum || 0) + (item.elemSclNum || 0) +
                    (item.specSclNum || 0) + (item.otrSclNum || 0) + (item.socialSclNum || 0) +
                    (item.culturalNum || 0) + (item.otrEducNum || 0));
                this.sumEducMny.set('value',
                    (item.highSclMny || 0) + (item.cmplSclMny || 0) + (item.elemSclMny || 0) +
                    (item.specSclMny || 0) + (item.otrSclMny || 0) + (item.socialSclMny || 0) +
                    (item.culturalMny || 0) + (item.otrEducMny || 0));
            },
            publicFacilitySum: function () {
                var item = this.form.get('value');
                this.sumPublicFacility.set('value',
                    (item.financialDamagePe || 0) + (item.financialDamageAf || 0) +
                    (item.financialDamagePc || 0) + (item.financialDamagePo || 0));
                this.sumPublicFacilityAndOther.set('value',
                    (item.financialDamagePe || 0) + (item.financialDamageAf || 0) +
                    (item.financialDamagePc || 0) + (item.financialDamagePo || 0) +
                    (item.financialDamageAd || 0) + (item.financialDamageFd || 0) +
                    (item.financialDamageLd || 0) + (item.financialDamageMd || 0) +
                    (item.financialDamageBd || 0) + (item.financialDamageOd || 0));
            }
        });
});

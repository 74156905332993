define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/on',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    'idis/view/grid/helper',
    // 以下、変数で受けないモジュール
    './ChronologyLinks',
    'dijit/form/CheckBox',
    'dijit/form/Button',
    './consts/ChronologyType',
    'dojox/form/Uploader',
    'dijit/Destroyable'

], function(module, dojo, declare, lang, array, locale, domClass, domStyle, topic, on, df, IdisGrid, IdisSelector,
    helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        /**
         * 各列の定義
         * @type {Object[]}
         */

        columns: [
            // ボタン
            helper.column('organizationName', '対応組織', {
                formatter: function(value, item){
                    var org = '';
                    if(item.unitName){
                        org += '<font size="-2" color="#c6c6c6">' +
                            item.deptName + ' / ' + item.sectName + '</font></br>';
                        org += item.unitName;
                        return org;
                    }
                    if(item.sectName){
                        org += '<font size="-2" color="#c6c6c6">' +
                            item.deptName + '</font></br>';
                        org += item.sectName;
                        return org;
                    }
                    if(item.deptName){
                        return item.deptName;
                    }
                    return org;
                },
                sortable: false
            }),
            helper.column('onDemandNum', '要請数', {sortable: false}),
            helper.column('notYetNum', '未対応', {sortable: false}),
            helper.column('onGoingNum', '対応中', {sortable: false}),
            helper.column('completedNum', '対応済', {sortable: false})
        ]
    });
});

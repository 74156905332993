/**
 * 地区選択ツリー用モデル
 * @module app/model/DistrictStoreModel
 */
define([
    'dojo/_base/declare',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(declare, CacheStoreModel, CacheTreeRest) {
    /**
     * 避難状況の地区選択用ツリー
     * @param {string} municipalityCd 市町村コード
     * @class DistrictStoreModel
     * @extends module:idis/store/CacheStoreModel~CacheStoreModel
     */
    return declare(CacheStoreModel, /** @lends module:app/model/DistrictStoreModel~DistrictStoreModel# */ {
        constructor: function(municipalityCd) {
            // 地区一覧用storeを生成
            this.store = new CacheTreeRest({
                target: '/api/district/district4Tree/' + municipalityCd
            });
        }
    });
});

/**
 * 職員招集メール一覧グリッド
 * @module app/broadnotify/BroadnotifyDetailGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,

        columns: [
            helper.column('terminalName', 'ユーザ名'),
            {field: 'sendStatus', label: '配信結果', sortable: true,
                formatter: lang.hitch(this, function(item){
                var type = null;
                    switch (item) {
                        case '0':
                            type = '未通知';
                            break;
                        case '1':
                            type = '通知中';
                            break;
                        case '2':
                            type = '通知完了';
                            break;
                        case '3':
                            type = '通知失敗';
                            break;
                        case '4':
                            type = '通知キャンセル';
                            break;
                    }
                return type;
            })},
            {field: 'responseStatus', label: '受信結果', sortable: true,
                formatter: lang.hitch(this, function(item){
                var type = null;
                switch (item) {
                    case '0':
                        type = '未確認';
                        break;
                    case '1':
                        type = '確認済';
                        break;
                    case '-':
                        type = '-';
                        break;
                }
                return type;
            })}
        ]
    });
});


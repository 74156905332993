/**
* 施設詳細画面用モジュール。
* @module app/facility/FacilityDetailPage
*/
define([
    'module',
    'app/config',
    'app/facility/FacilityDetailConfig',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/text!./templates/FacilityDetailPage.html',
    'dojo/on',
    'dojo/dom-class',
    'dojo/dom-style',
    './_FacilityPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/IdisDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/config',
    'app/map/baselayer/BaseLayerPane',
    'leaflet',
    'dojo/json',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/view/form/CustomizableDistrictSelector',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/CustomizableFacilitiesSelector'
], function(module, appconfig, detailConfig, declare, lang, Deferred, template, on, domClass, domStyle,
    _FacilityPageBase, Locator, Router, DialogChain, InfoDialog, IdisDialog, IdisMap, UserInfo, IdisRest,
    GeoService, Requester, Loader, config, BaseLayerPane, leaflet, json) {
        // GeoServiceを初期化
        var _geoService = new GeoService({
            url: config.geocode && config.geocode.url
        });

        /**
        * 施設詳細画面
        * @class FacilityDetailPage
        * @extends module:app/facility/_FacilityPageBase~_FacilityPageBase
        */
        return declare(module.id.replace(/\//g, '.'), _FacilityPageBase,
            /** @lends module:app/facility/FacilityDetailPage~FacilityDetailPage# */ {
                // テンプレート文字列
                templateString: template,

                confirmDialog: null,

                // ストア
                facilityStore: null,

                /**
                * 施設ID
                */
                _facilityId: null,

                /**
                * 市町区コード（県）
                */
                MUNICIPALITY_CD_KUMAMOTO_PREF: config.municInfo.prefMunicCd,

                /**
                * 住所 緯度
                */
                _addressLat: null,

                /**
                * 住所 経度
                */
                _addressLng: null,

                /**
                * バージョン
                */
                _version: null,

                detailFlg: null,

                attachFileList: [],

                /**
                 * 住所、緯度経度の変更をチェックするフラグ
                 */
                addressCheckFlg: false, // 住所変更による緯度経度の反映が必要
                latLngCheckFlg: false, // 緯度経度変更による住所の反映が必要

                /**
                 * 詳細画面初期化中フラグ
                 */
                _isInitDetailSet: true,

                constructor: function() {
                    // データ格納用オブジェクト
                    this.facilityStore = new IdisRest({
                        idProperty: 'facilityId',
                        target: '/api/facility'
                    });
                    // ダイアログ連鎖を登録
                    // 引数に与えたウィジェットのthis.ownを呼び出し、
                    // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                    this.chain = DialogChain.get(this);

                    // 市町村コードを取得
                    var municipalityCds = UserInfo.getMunicipalityCds();
                    if(municipalityCds && municipalityCds.length > 0) {
                        this._municipalityCd = municipalityCds[0];
                    } else {
                        this._municipalityCd = config.municInfo.prefMunicCd;
                    }

                    // 施設IDを取得
                    this._facilityId = Locator.getQuery().facilityId;
                    //詳細タブの初期表示を設定する。
                    this.detailFlg = '1';
                },

                /**
                * 施設情報を更新するための準備をする。送るデータの体裁を整える。
                */
                onSubmit: function() {
                    var sendData = this.form.get('value');
                    var districtCd = sendData.districtCd;
                    sendData.version = this._version;

                    //避難所以外では、詳細タブの項目が消えてしまうため、こちらで定義する。
                    if(sendData.facilityTypeId !== '01'){
                        sendData.heliportFlg = this.heliportFlg.value;
                        sendData.elevatorFlg = this.elevatorFlg.value;
                        sendData.roofFlg = this.roofFlg.value;
                        sendData.structure = this.structure.value;
                        sendData.petFlg = this.petFlg.value;

                        sendData.electricityFlg = this.electricityFlg.value;
                        sendData.gasFlg = this.gasFlg.value;
                        sendData.waterFlg = this.waterFlg.value;
                        sendData.phoneFlg = this.phoneFlg.value;
                        sendData.airConditionerFlg = this.airConditionerFlg.value;
                        sendData.pcFlg = this.pcFlg.value;
                        sendData.showerFlg = this.showerFlg.value;
                        sendData.bathFlg = this.bathFlg.value;
                        sendData.cookingFlg = this.cookingFlg.value;
                        sendData.laundryFlg = this.laundryFlg.value;
                        sendData.powerFlg = this.powerFlg.value;
                        sendData.toiletFlg = this.toiletFlg.value;
                        sendData.menToiletNum = this.menToiletNum.value;
                        sendData.womenToiletNum = this.womenToiletNum.value;
                        sendData.multitoiletFlg = this.multitoiletFlg.value;
                        if(sendData.facilityTypeId !== '02' && sendData.facilityTypeId !== '03'){
                            sendData.vehicleAccessibleFlg = this.vehicleAccessibleFlg.value;
                            sendData.accessibleVehicleCd = this.accessibleVehicleCd.value;
                        }
                    }

                    //添付ファイルIDをセット
                    if(this.attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i=0; i<this.attachFileList.length; i++) {
                            attachFileIds.push(this.attachFileList[i].facilityAtcFileId);
                        }
                        sendData.attachFileIds = attachFileIds.join(',');
                    } else {
                        sendData.attachFileIds = '';
                    }
                    delete sendData.attachFile;

                    if(this.pointLat === '' || this.pointLat === null ||
                    this.pointLng === '' || this.pointLng === null){ //地図にピンがなかった場合
                        this._addressLat = null;
                        this._addressLng = null;

                        this.addressToLatLng().then(lang.hitch(this, function(){
                            sendData.latitude = this._addressLat;
                            sendData.longitude = this._addressLng;
                        }), lang.hitch(this, function() {
                            // エラーの場合
                            sendData.latitude = this._addressLat = null;
                            sendData.longitude = this._addressLng = null;
                        })).then(lang.hitch(this, function() {
                            this.validation(sendData, districtCd);
                        }));

                    } else { //地図にピンがあった場合
                        sendData.latitude = this.pointLat;
                        sendData.longitude = this.pointLng;
                        this.validation(sendData, districtCd);
                    }

                },

                /**
                * 入力内容のチェックを行う。
                */
                validation: function(sendData, districtCd){
                    // フォームのバリデーションを行う（共通部品）
                    if(!this.form.validate()) {
                        return false;
                    }
                    // フォームのバリデーションを行う（独自チェック）
                    if(!this.validateForm(sendData, districtCd)) {
                        return false;
                    }

                    // 避難所、物資拠点以外は地区をセットしない（画面入力なし）
                    if(['01', '02', '03'].indexOf(sendData.facilityTypeId) < 0){
                        delete sendData.districtCd;
                    }

                    //住所が有効かどうかチェック
                    if(this._addressLat === null || this._addressLng === null){//住所が無効である場合
                        this.chain.confirm('住所から緯度・経度が取得できませんでした。<br>' +
                        '緯度・経度の情報が必要な場合は、キャンセルして地図上で施設の位置をクリックしてください。<br>' +
                        '緯度・経度の情報が不要な場合は、OKボタンを押して登録を完了してください。',
                        function(chain) {
                            Loader.wait(this.facilityStore.put(sendData)).then(function() {
                                // 成功時（PUT結果はグリッドが自動的に反映）
                                chain.infoComplete(function() {
                                    // 施設一覧ページに遷移
                                    Router.moveTo('facility');
                                });
                            }, function(err) {
                                // 失敗時
                                chain.infoError(err);
                            });
                        });

                    } else { //住所が有効である場合
                        this.updateFacility(sendData);
                    }

                },

                /**
                * 施設情報を更新する。
                */
                updateFacility: function(sendData){
                    this.chain.confirmPut(function(chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        Loader.wait(this.facilityStore.put(sendData)).then(function() {
                            // 成功時（PUT結果はグリッドが自動的に反映）
                            chain.infoComplete(function() {
                                // 施設一覧ページに遷移
                                Router.moveTo('facility');
                            });
                        }, function(err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                },

                // 詳細ページ表示時、オンのフラグにチェックを入れるメソッド
                facilityCheck: function(item, page){
                    if(item.publicFlg === '0' && item.municipalityAuthorizeFlg === 'true'){
                        page.municipalityAuthorizeFlg.set('value', 'on');
                    }
                    if(item.floodShFlg === '1'){
                        page.floodShFlg.set('value', 'on');
                    }
                    if(item.sedimentDisasterShFlg === '1'){
                        page.sedimentDisasterShFlg.set('value', 'on');
                    }
                    if(item.stormSurgeShFlg === '1'){
                        page.stormSurgeShFlg.set('value', 'on');
                    }
                    if(item.earthquakeShFlg === '1'){
                        page.earthquakeShFlg.set('value', 'on');
                    }
                    if(item.tsunamiShFlg === '1'){
                        page.tsunamiShFlg.set('value', 'on');
                    }
                    if(item.fireShFlg === '1'){
                        page.fireShFlg.set('value', 'on');
                    }
                    if(item.landsideWaterShFlg === '1'){
                        page.landsideWaterShFlg.set('value', 'on');
                    }
                    if(item.volcanoShFlg === '1'){
                        page.volcanoShFlg.set('value', 'on');
                    }
                    //その他の災害が記載されていたらチェックボックスをonにしておく。
                    if(item.otherShFlg === '1' || item.shNote){
                        page.otherShFlg.set('value', 'on');
                    }
                    //onの場合はその他の記入boxを編集可能状態にする。
                    this.onOtherDisasterButtonClick();
                    //通行不可の場合は車種の初期表示をdisabledにしておく。
                    this.onVehicleButtonClick();
                    if(item.edesignatedEvacShFlg === '1'){
                        page.eDesignatedEvacShFlg.set('value', 'on');
                    }
                    if(item.designatedEvacShFlg === '1'){
                        page.designatedEvacShFlg.set('value', 'on');
                    }
                    if(item.welfareEvacShFlg === '1'){
                        page.welfareEvacShFlg.set('value', 'on');
                    }
                    if(item.temporaryEvacShFlg === '1'){
                        page.temporaryEvacShFlg.set('value', 'on');
                    }
                    if(item.civilProtectionEvacShFlg === '1'){
                        page.civilProtectionEvacShFlg.set('value', 'on');
                    }
                    if(item.otherEvacShFlg === '1'){
                        page.otherEvacShFlg.set('value', 'on');
                    }
                    this._version = item.version;
                },

                /**
                * 施設情報を削除する。
                */
                deleteFacilityButtonClick: function() {
                    this.chain.confirmDel(function(chain) {
                        // 施設の論理削除 追加処理と共にローディング表示
                        Loader.wait(this.facilityStore.remove(Locator.getQuery().facilityId+
                            '?version=' + this._version)).then(function() {
                            // 成功時（DELETE結果はグリッドが自動的に反映）
                            chain.infoComplete(function() {
                                // 施設一覧ページに遷移
                                Router.moveTo('facility');
                            });
                        }, function(err) {
                            // 失敗時
                            if (err.response.data) {
                                var jsonData = json.parse(err.response.data);
                                if (jsonData.messages.length > 0) {
                                    chain.info(jsonData.messages.join('<br>'), 'エラー');
                                    return;
                                }
                            }
                            chain.infoError(err);
                        });
                    });
                },

                /**
                * マップを初期化する。
                */
                initMap: function(item) {
                    // マップの生成
                    this.map = new IdisMap(this.mapNode, {
                        config: config.map,
                        keyboard: false, // コメント時に+/-が使用できないため
                        touchExtend : false,
                        minZoom: 9,
                        drawControlTooltips: false}
                    );
                    // destroy時にmapを破棄するよう設定
                    this.own(this.map);
                    this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                        // 住所が変更されておらず、緯度経度のみ変更された場合は
                        // 緯度経度変更による反映チェックが必要
                        if (!this.addressCheckFlg) {
                            this.latLngCheckFlg = true;
                        } else {
                            this.latLngCheckFlg = false;
                        }
                        // 住所変更による反映がされたものとする
                        this.addressCheckFlg = false;
                    })));

                    // DB登録内容から緯度・経度を取得
                    this.getLatLng(item).then(lang.hitch(this, function(latlng) {
                        // 成功の場合、マーカーをセット
                        this.pointLat = this._addressLat = latlng[0];
                        this.pointLng = this._addressLng = latlng[1];
                        this.map.setView(latlng, 14);
                        this.addMark(this.pointLat, this.pointLng, this);
                    }), lang.hitch(this, function(errMsg) {
                        // 失敗の場合、地図の中心地初期化のため市町村から緯度経度は取得するがマーカーは追加しない
                        this.getLatLngFromMunicipalityCd(item.districtDto.municipalityCd).then(lang.hitch(this, function(latlng) {
                            this.map.setView(latlng, 12);
                        })).then(lang.hitch(this, function() {
                            // 中心地初期化が成功でも失敗でも、緯度経度取得失敗のエラーだけは表示する
                            this.chain.info(errMsg, 'エラー');
                        }));
                    }));
                },

                /**
                * DB登録内容から緯度・経度を返す
                *   緯度経度の登録がなければ、住所から割り出す
                */
                getLatLng: function(item) {
                    var deferred = new Deferred();
                    // 緯度経度のDB登録がある場合は、そのまま地図反映へ進む
                    if (item.latitude && item.longitude) {
                        return deferred.resolve([item.latitude, item.longitude]);
                    }
                    // 住所の登録もない場合はエラーとする
                    if(!item.address) {
                        return deferred.reject('住所を入力してください。');
                    }
                    // 緯度・経度の登録がない場合は、住所から取得
                    var address = appconfig.municInfo.prefName + item.address;
                    Loader.wait(_geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0) {
                            deferred.resolve([results[0].latlng.lat, results[0].latlng.lng]);
                        } else {
                            deferred.reject('住所から位置情報を取得できませんでした。');
                        }
                    }), lang.hitch(this, function() {
                        deferred.reject('住所から位置情報を取得できませんでした。');
                    })));
                    return deferred.promise;
                },

                /**
                * 市町村情報から緯度・経度を返す
                *   失敗した場合はconfigのデフォルト値を返す
                */
                getLatLngFromMunicipalityCd: function(municipalityCd) {
                    var deferred = new Deferred();
                    var promise = Requester.get('/api/municipalities/' + municipalityCd, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(data) {
                        if(!this.address.get('value') && municipalityCd !== config.municInfo.prefMunicCd) {
                            this.address.set('value', data.municipalityName);
                        }
                        deferred.resolve([data.latitude, data.longitude]);
                    }), lang.hitch(this, function() {
                        // 失敗の場合、デフォルトの緯度経度を返す
                        deferred.resolve([config.map.latitude, config.map.longitude]);
                    }));
                    //ローダーの表示
                    Loader.wait(promise);
                    return deferred.promise;
                },

                /**
                 * 背景地図ダイアログを表示する。
                 */
                showBaseLayerDialog: function() {
                    if (!this._baseLayerDialog) {
                        // 初回呼び出し時にインスタンス生成
                        this._baseLayerDialog = new IdisDialog({
                            noUnderlay: true,
                            title: '背景地図',
                            content: new BaseLayerPane({map: this.map})
                        });
                        // 画面が破棄された際に連れて行く
                        this.own(this._baseLayerDialog);
                    }
                    this._baseLayerDialog.show();
                },

                /**
                * 地図上でポイントされている位置の住所を設定します。
                */
                mapToAddress: function() {
                    if(this.pointLat === '' || this.pointLng === '') {
                        this.chain.info('地図が選択されていません。', 'エラー');
                        return;
                    }

                    _geoService.reverseGeocode(leaflet.latLng({
                        lat: this.pointLat,
                        lng: this.pointLng
                    })).then(lang.hitch(this, function(res) {
                        this._isInitDetailSet = true; // 初期表示時と同様に住所変更でフラグが更新されないようにする
                        this.addMark(this.pointLat, this.pointLng);
                        this.address.set('value', res.address.Address);
                        this._addressLat = this.pointLat;
                        this._addressLng = this.pointLng;
                    }), lang.hitch(this, function() {
                        this.chain.info('住所を取得できませんでした。', 'エラー');
                    })).then(lang.hitch(this, function() {
                        // 住所、緯度経度変更による反映がされたものとする
                        this.latLngCheckFlg = false;
                        this.addressCheckFlg = false;
                    }));
                },

                /**
                * 住所の位置を地図上にポイントします。
                */
                addressToMap: function() {
                    if(!this.address.value) {
                        this.chain.info('住所を入力してください。', 'エラー');
                        return;
                    }
                    //先頭に「県名」の記載を追加して検索する。
                    var address = this.address.value;
                    if(this.address.value.indexOf(appconfig.municInfo.prefName) !== 0){
                        address = appconfig.municInfo.prefName + address;
                    }
                    _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0) {
                            var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                            this.pointLat = results[0].latlng.lat;
                            this.pointLng = results[0].latlng.lng;
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;
                            this.map.setView(latlng, 14);
                            this.addMark(this.pointLat, this.pointLng);
                        } else {
                            this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                        }
                    }), lang.hitch(this, function() {
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    })).then(lang.hitch(this, function() {
                        // 住所、緯度経度変更による反映がされたものとする
                        this.latLngCheckFlg = false;
                        this.addressCheckFlg = false;
                    }));
                },

                /**
                * フォームに入力されている住所から、緯度・経度を割り出す
                */
                addressToLatLng: function() {
                    var address = appconfig.municInfo.prefName + this.address.value;
                    return _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0){ //住所が有効である場合
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;
                        } else { //住所が無効の場合（現実に存在しない住所が入力された場合）
                            this._addressLat = null;
                            this._addressLng = null;
                        }

                    }));
                },

                /**
                * マーカーを追加する。
                */
                addMark: function(lat, lng) {
                    this.removeMark();
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                },

                /**
                * マーカーを削除する。
                */
                removeMark: function() {
                    if (this.marker) {
                        this.map.removeLayer(this.marker);
                    }
                },


                /**
                * 入力値の妥当性をチェックする。
                */
                validateForm: function(sendData, districtCd){
                    var designatedEvacShFlg = sendData.designatedEvacShFlg.length;
                    var eDesignatedEvacShFlg = sendData.eDesignatedEvacShFlg.length;
                    var welfareEvacShFlg = sendData.welfareEvacShFlg.length;
                    var temporaryEvacShFlg = sendData.temporaryEvacShFlg.length;
                    var civilProtectionEvacShFlg = sendData.civilProtectionEvacShFlg.length;
                    var otherEvacShFlg = sendData.otherEvacShFlg.length;

                    if(sendData.facilityTypeId === '01' || sendData.facilityTypeId === '02' || sendData.facilityTypeId === '03'){
                        if(districtCd === '' || !this.checkDistrict(districtCd)) {
                            return this.showErrDialog('振興局が選択されているか、<br>地区が正しく選択されていません');
                        }
                    }
                    // 物資拠点・備蓄倉庫を選択した上で、管理主体がnullだとエラー
                    if((sendData.facilityTypeId === '02' || sendData.facilityTypeId === '03') &&
                        !sendData.managementEntity) {
                        return this.showErrDialog('管理主体が正しく選択されていません');
                    }

                    if((sendData.facilityTypeId === '01') && designatedEvacShFlg === 0 && eDesignatedEvacShFlg === 0 &&
                        welfareEvacShFlg === 0 && temporaryEvacShFlg === 0 && civilProtectionEvacShFlg === 0 && otherEvacShFlg === 0) {
                        return this.showErrDialog('避難所区分が正しく選択されていません');
                    }

                    // 住所を変更したが地図上でポイントされている位置に反映されていない
                    if (this.addressCheckFlg) {
                        return this.showErrDialog('変更した住所を地図に反映してください');
                    }

                    // 地図上でポイントされている位置を変更したが住所に反映されていない
                    if (this.latLngCheckFlg) {
                        return this.showErrDialog('地図でポイントした位置を住所に反映してください');
                    }
                    return true;
                },

                showErrDialog: function(msg) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : msg
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                },

                // 種別詳細セレクトボックスの初期化を行う
                initFacilityTypeDetailSelectBox: function(){
                    var GOVERNMENT_AGENCY = '05'; // 行政機関
                    var PUBLIC_INSTITUTION = '09'; // 公共機関
                    var MEDICAL_INSTITUTION = '10'; // 医療機関
                    var PRESCHOOL = '11'; // 幼稚園・保育園
                    var SCHOOL = '12'; // 学校
                    var NURSINGHOME = '13'; // 老人ホーム
                    var LIFE_LINE = '14'; // ライフライン
                    var HELIPORT = '15'; // ヘリポート
                    var facilityTypeId = this.facilityTypeSelectBox.value;

                    // セレクトボックスに設定するデータの配列を作成
                    var newOptions = [{
                        label: '未選択',
                        value: ''
                    }];
                    switch(facilityTypeId) {
                    case GOVERNMENT_AGENCY:
                        detailConfig.governmentAgency.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case PUBLIC_INSTITUTION:
                        detailConfig.publicInstitution.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case MEDICAL_INSTITUTION:
                        detailConfig.medicalInstitution.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case PRESCHOOL:
                        detailConfig.preschool.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case SCHOOL:
                        detailConfig.school.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case NURSINGHOME:
                        detailConfig.nursingHome.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case LIFE_LINE:
                        detailConfig.lifeLine.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case HELIPORT:
                        detailConfig.heliport.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    default:
                        break;
                    }
                },

                // 対象災害で「その他」が押されたときに入力フォームを表示する。
                onOtherDisasterButtonClick: function() {
                    if(this.form.get('value').otherShFlg[0] === 'on'){
                        this.shNote.set('disabled',false);
                    }else{
                        this.shNote.set('disabled',true);
                    }
                },

                // 詳細情報のラジオボタン(トイレ)で「あり」が選択されたときに入力フォームを表示する。
                selectToilet: function() {
                    if(this.form.get('value').toiletFlg === '2'){
                        this.menToiletNum.set('disabled', false);
                        this.womenToiletNum.set('disabled', false);
                        domStyle.set(this.multitoilet, 'display', '');
                    }else{
                        this.menToiletNum.set('disabled', true);
                        this.womenToiletNum.set('disabled', true);
                        domStyle.set(this.multitoilet, 'display', 'none');
                    }
                },

                // 車両の通行可否で「否」が選択された時は最大車種を非表示にする。
                onVehicleButtonClick: function() {
                    if(this.form.get('value').vehicleAccessibleFlg === '1'){
                        this.accessibleVehicleCd.set('disabled',true);
                    }else{
                        this.accessibleVehicleCd.set('disabled',false);
                    }
                },

                // HTML上にウィジェットが設置されてから呼ばれる
                startup: function() {
                    this.inherited(arguments);

                    // 画面を初期化
                    this.initPage();
                    if(UserInfo.getAdminFlg()){
                        this.publicFlgYes.set('readOnly', false);
                        this.publicFlgNo.set('readOnly', false);
                    }
                },

                // DOMノード生成後に呼ばれる
                postCreate: function() {
                    this.inherited(arguments);
                },

                /**
                * 画面を初期化する。
                */
                initPage: function() {
                    //初期化
                    this.pointLat = '';
                    this.pointLng = '';
                    this.marker = '';
                },

                onFacilityLinkClick:function(evt) {
                    // ブラウザーの遷移処理をキャンセル
                    evt.preventDefault();
                    Router.moveTo('facility');
                },

                // DOMノードを生成するためのメソッド
                buildRendering: function() {
                    this.inherited(arguments);
                    var promise = this.initTree();
                    Loader.wait(promise).then(lang.hitch(this, function(){
                        this.initForm();
                    }));
                },

                // 施設種別セレクトボックスの初期化を行う
                initSelectBox: function(){
                    var promise =
                    Requester.get('/api/facility/category');
                    return Loader.wait(promise).then(lang.hitch(this, function(data) {
                        // セレクトボックスに設定するデータの配列を作成
                        var newOptions = [{
                            label: '&nbsp;',
                            value: null
                        }];
                        // サーバーレスポンスの各要素をselectへ追加
                        data.items.forEach(function(object){
                            newOptions.push({
                                label: object.facilityTypeName,
                                value: object.facilityTypeId
                            });
                        });
                        this.facilityTypeSelectBox.set('options',newOptions);
                    }));
                },


                // リストボックスで避難所が選択された場合は避難所関連の入力フォームを表示する
                showShelterAdmIdInputForm: function(id){
                    // '01'=避難所、'02'=備蓄倉庫、'03'=物資拠点'
                    if(id === '01') {
                        //「利用可否」「開設可否」の記載
                        domStyle.set(this.stocklabel, 'display', '');
                        domStyle.set(this.publicLabel, 'display', '');
                        // 避難所・物資拠点・備蓄倉庫の共通項目を表示
                        domStyle.set(this.folkliftNumInput, 'display', '');
                        domStyle.set(this.vehicleInfo, 'display', '');
                        // 避難所の管理項目
                        domStyle.set(this.shelterCategory, 'display', '');
                        domStyle.set(this.capacityInput, 'display', '');
                        domStyle.set(this.capacityCalcStandardInput, 'display', '');
                        domStyle.set(this.shelterType, 'display', '');
                        // 備蓄倉庫・それ以外の施設の項目
                        domStyle.set(this.municipalitySelector, 'display', 'none');
                        // 地区・種別詳細の項目
                        domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                        domStyle.set(this.districtCd, 'display', '');
                        // 詳細タブを表示する。
                        this.showDetailTab(this.detailFlg);
                        // トイレ関係
                        this.selectToilet();
                    } else if(id === '02' || id === '03'){
                        //「利用可否」「開設可否」の記載
                        domStyle.set(this.stocklabel, 'display', '');
                        domStyle.set(this.publicLabel, 'display', 'none');
                        // 避難所・物資拠点・備蓄倉庫の共通項目を表示
                        domStyle.set(this.folkliftNumInput, 'display', '');
                        domStyle.set(this.vehicleInfo, 'display', '');
                        // 避難所の管理項目
                        domStyle.set(this.shelterCategory, 'display', 'none');
                        domStyle.set(this.capacityInput, 'display', 'none');
                        domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                        domStyle.set(this.shelterType, 'display', 'none');
                        // 備蓄倉庫・それ以外の施設の項目
                        domClass.add(this.municipalitySelector, 'is-required');
                        domStyle.set(this.municipalitySelector, 'display', '');
                        // 地区・種別詳細の項目
                        domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                        domStyle.set(this.districtCd, 'display', '');
                        //詳細タブを非表示にする。
                        this.closeDetailTab(this.detailFlg);
                    } else {
                        //「利用可否」「開設可否」の記載
                        domStyle.set(this.stocklabel, 'display', '');
                        domStyle.set(this.publicLabel, 'display', 'none');
                        // 避難所・物資拠点・備蓄倉庫の共通項目を非表示
                        domStyle.set(this.folkliftNumInput, 'display', 'none');
                        domStyle.set(this.vehicleInfo, 'display', 'none');
                        // 避難所の管理項目を非表示
                        domStyle.set(this.shelterCategory, 'display', 'none');
                        domStyle.set(this.capacityInput, 'display', 'none');
                        domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                        domStyle.set(this.shelterType, 'display', 'none');
                        // 備蓄倉庫・物資拠点の項目を非表示
                        domClass.remove(this.municipalitySelector, 'is-required');
                        domStyle.set(this.municipalitySelector, 'display', '');
                        // 地区・種別詳細の項目
                        if(id >= '30'){
                            domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                        } else{
                            domStyle.set(this.facilityTypeDetailSelectBox, 'display', '');
                        }
                        domStyle.set(this.districtSelector, 'display', 'none');
                        // 詳細タブを非表示にする。
                        this.closeDetailTab(this.detailFlg);
                    }
                },

                // 詳細タブを表示にする
                showDetailTab: function(detailFlg){
                    if(detailFlg === '0'){
                        this.tabContainer.addChild(this.mainFacilityInfo);
                        this.detailFlg = '1';
                        this.tabContainer.selectChild(this.mainFacilityInfo);
                    }
                },

                // 詳細タブを非表示にする
                closeDetailTab: function(detailFlg){
                    if(detailFlg === '1'){
                        this.tabContainer.removeChild(this.mainFacilityInfo);
                        this.detailFlg = '0';
                    }
                },

                /**
                * フォームを初期化する。
                */
                initForm: function() {
                    var page = this.form;
                    try{
                        this.initSelectBox().then(lang.hitch(this,function(){
                            return Requester.get('/api/facility/' + this._facilityId);
                        })).then(lang.hitch(this,function(data) {
                            var item = data;
                            page.set('value', item);
                            this.facilityTypeSelectBox.set('value',item.facilityTypeId);
                            this.initFacilityTypeDetailSelectBox();
                            this.facilityTypeDetail.set('value',item.facilityTypeDetail);
                            this.managementEntity.set('value',item.managementEntity);
                            this.districtCd.set('value',item.districtDto.districtCd);
                            this.onChangePublicFlg();
                            // チェックボックスのうち、フラグがオンのものはcheckedにして表示する
                            this.facilityCheck(item,this);

                            // 地図の初期化＆DB登録情報を地図上に反映
                            this.initMap(item);

                            // 添付ファイルを設定
                            this.attachFileList = [];
                            if(item.facilityAtcFiles) {
                                for(var i=0; i<item.facilityAtcFiles.length; i++) {
                                    this.attachFileList.push(item.facilityAtcFiles[i]);
                                    this.showPreview(item.facilityAtcFiles[i], false);
                                }
                            }

                            // 避難所用管理項目の表示非表示切り替え
                            this.showShelterAdmIdInputForm(data.facilityTypeId);
                        }));
                    } catch (e) {
                        console.error(e);
                    }
                },

                initTree: function() {
                    return this.districtCd._initModel();
                },

                /**
                * 地区が選択されていることを検証する
                */
                checkDistrict :function(districtCd) {
                    var result = false;
                    this.districtCd.model.store.get(districtCd).then(
                        lang.hitch(this, function(item){
                            // item.typeが'MDistrict'の場合はツリーの子要素であるため地区として選択可能にする
                            if(item.type === 'MDistrict') {
                                result = true;
                            } else {
                                result = false;
                            }
                        }
                    ));
                    return result;
                },

                onChangeAddress: function() {
                    // 詳細画面の初期表示の時はフラグの更新を行わない
                    if (this._isInitDetailSet) {
                        this._isInitDetailSet = false;
                        return;
                    }

                    // 緯度経度が変更されておらず、住所のみ変更された場合は
                    // 住所変更による反映チェックが必要
                    if (!this.latLngCheckFlg) {
                        this.addressCheckFlg = true;
                        this._addressLat = null;
                        this._addressLng = null;
                    }
                    // 緯度経度変更による反映がされたものとする
                    this.latLngCheckFlg = false;
                },
        
                /**
                 * 外部公開ラジオボタン切り替えイベント
                 */
                onChangePublicFlg: function() {
                    // 外部公開可の場合、自市区町村のみ閲覧制限不可
                    if (this.form.get('value').publicFlg === '1') {
                        this.municipalityAuthorizeFlg.reset();
                        this.municipalityAuthorizeFlg.set('disabled', true);
                    // 外部公開不可の場合、自市区町村のみ閲覧制限可
                    } else {
                        this.municipalityAuthorizeFlg.set('disabled', false);
                    }
                }

            }
        );
    }
);

/**
 * 危機管理型水位計情報詳細画面用モジュール。
 * @module app/view/page/CrisisManageRiverLevelObsDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/CrisisManageRiverLevelObsDetailPage.html',
    'dojox/charting/Chart',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/plot2d/Lines',
    'dojox/charting/widget/SelectableLegend',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/action2d/Magnify',
    'dojox/gfx/matrix',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/control/Locator',
    'idis/view/Loader',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    // 以下、変数で受けないモジュール
    'app/view/form/ObservationRegionSelector',
    'dojox/charting/plot2d/Markers',
    'dojox/charting/axis2d/Default',
    'dojox/charting/plot2d/Grid',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    // './RiverLevelCorrectDialog',
    './grid/CrisisManageRiverLevelObsDetailPageGrid',
    './CrisisManagerRiverLegend'
], function(module, array, declare, lang, topic, template,
        Chart, Theme, LinesPlot, SelectableLegend, Tooltip, Magnify, Matrix,
        _PageBase, IdisRest, Router,
		Requester, Locator, Loader, DateTimeSelect, DataKind)  {
    /**
     * 水位状況情報詳細画面
     * @class CrisisManageRiverLevelObsDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:./CrisisManageRiverLevelObsDetailPage~CrisisManageRiverLevelObsDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--riverlevelobs',

        // ストア
        store: null,

        // 時間モード
        mode: Locator.getQuery().timeMode || 10,

        // チャート
        chart: null,
        riverLevelTooltip: null,
        riverLevelMagnify: null,
        waitRiverLevelTooltip: null,
        waitRiverLevelMagnify: null,
        cautionRiverLevelTooltip: null,
        cautionRiverLevelMagnify: null,
        judgmentRiverLevelTooltip: null,
        judgmentRiverLevelMagnify: null,
        crisisRiverLevelTooltip: null,
        crisisRiverLevelMagnify: null,
        markerShape: 'm-3,0 c0,-4 6,-4 6,0 m-6,0 c0,4 6,4 6,0',
        minorLineColor: 'silver',
        majorLineColor: 'gray',

        // 変更フラグ
        changeFlg: 0,

        // データ種別
        dataKind: Locator.getQuery().dataKind || DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL,

        // 観測局ID
        observatoryId : Locator.getQuery().observatoryId,

        constructor: function() {
            // 雨量データ格納用オブジェクト
            // TODO:
            this.store = new IdisRest({
                idProperty: 'riverId',
                target: '/api/river/crisis/detail'
            });
            // データ種別
            this.dataKind = Locator.getQuery().dataKind || DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL;
            // 観測局ID
            this.observatoryId = Locator.getQuery().observatoryId;
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 初期表示は、時間モード10分で最新の時間データ
			// 最新の観測情報を取得する
            Requester.get('/api/river/crisis/latestDataTime?dataKind=' +
            DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL + '&timeMode=10').then(lang.hitch(this, function(data){


	            // 日付セレクトボックスを生成・設置する
                // Queryに日付がある場合はそれをセットする
                var dateQuery = Number(Locator.getQuery().date);
                if(dateQuery){
                    data = dateQuery;
                }
                this.dateTimeSelect = new DateTimeSelect({
                    to: data
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
			}));
        },

        startup: function() {
            this.inherited(arguments);
            this.initForm();
        },

        initForm: function(){
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, function(datetime) {
                // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
                // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみデータを取得するようにする
                if (this.lastResuestDateTime !== (datetime.date + ' ' + datetime.time)) {
                    this.lastResuestDateTime = datetime.date + ' ' + datetime.time;
                    this.updateGridQuery();
                }
            })));
            // 最新ボタンクリックを監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, function() {
                // 最新の観測情報を取得
                Requester.get('/api/river/crisis/latestDataTime?dataKind=' +
                DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL + '&timeMode=' + this.timeMode).then(
                        lang.hitch(this, function(data){
                    // 観測日時セレクトボックスを再構築する
                    // セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
                    this.dateTimeSelect.rebuild(data);
                }));
            })));

            this.initSelectBox();
        },



        // セレクトボックスの初期化を行う
        initSelectBox: function(){
            var timeModeQuery = Locator.getQuery().timeMode;
            // 観測局セレクトボックスの初期化
            // デフォルトでは県全域の観測局を表示
            // TODO:
            var value = Locator.getQuery().regionCd;
            var promise =
                Requester.get('/api/observationStation/selectBox/?dataKind='+ DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL +
                    (value !== undefined ? '&regionCd=' + value : ''));
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                this.crisisObsSelectBox.options = data;
                this.crisisObsSelectBox.set('value', this.observatoryId);
                // 時間モードの初期化
                if(timeModeQuery === '60'){
                    this.timeModeSelect.set('value',timeModeQuery);
                }
            }));
        },

        // 観測局セレクトボックスの更新を行う
        updateSelectBox: function(value){

            // 選択された地域内の観測局一覧を取得する
            var promise =
                Requester.get('/api/observationStation/selectBox/?dataKind=' +
                DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL +
                (value !== undefined ? '&regionCd=' + value : ''));
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                // セレクトボックスにオプションを設定する
                this.crisisObsSelectBox.set('options', data).reset();
            }));
        },

        /**
         * 観測日時を10分モードの最新時刻で初期化する。
         */
        initDateTime: function(){
            var date = this.date;
            Requester.get('/api/river/crisis/latestDataTime?timeMode=10').then(function(data) {
                date._setValueAttr(data);
            });
        },

        // 一つ前の観測局を取得する
        onPreviousButtonClick: function() {
            var options = this.crisisObsSelectBox.options;
            var value = this.crisisObsSelectBox.value;
            var newValue = null;
			// 最初の観測局の場合はなにもしない
			if(value !== options[0].value){
				array.forEach(options, lang.hitch(this, function(option, i){
					if(option.value===value){
                        newValue = options[i - 1].value;
						this.crisisObsSelectBox.set('value', newValue);
					}
				}));
            }
        },
        // 一つ後の観測局を取得する
        onNextButtonClick: function() {
            var options = this.crisisObsSelectBox.options;
            var value = this.crisisObsSelectBox.value;
            var newValue = null;
			// 最後の観測局の場合はなにもしない
			if(value !== options[options.length - 1].value){
				array.forEach(options, lang.hitch(this, function(option, i){
					if(option.value===value){
                        newValue = options[i + 1].value;
						this.crisisObsSelectBox.set('value', newValue);
					}
				}));
            }
        },



        /**
         * 観測所の情報を初期化する。
         */
        initText: function(data) {
            if(data.totalLength !== 0){
                // 観測所の基準値情報の初期化
                this.floodStartRiverLevel.innerHTML = this._formatRiverLevel(data[0].floodStartRiverLevel);
                this.dangerousRiverLevel.innerHTML = this._formatRiverLevel(data[0].dangerousRiverLevel);
                this.obsStartRiverLevel.innerHTML = this._formatRiverLevel(data[0].obsStartRiverLevel);
                // 観測所の住所情報の初期化
                this.regionName.innerHTML = data[0].regionName || '-';
                this.riverSysName.innerHTML = data[0].riverSysName || '-';
                this.riverName.innerHTML = data[0].riverName || '-';
                this.observatoryAddress.innerHTML = data[0].observatoryAddress || '-';
            }
        },

        _formatRiverLevel: function(data){
            return data||data===0 ? data.toFixed(2) : '-';
        },

        /**
        * Formの値をチェックし、表の更新を行う。
        */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
        * 表の更新を行う。
        */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            // 観測日時をフィルターにセット
            var date = new Date(this.lastResuestDateTime).getTime();
            filter = filter.eq('date', date);
            filter = filter.eq('dataKind', this.dataKind);
            // [観測局]のセット
            filter = filter.eq('observatoryId', this.observatoryId);
            // [時間モード]のセット
            filter = filter.eq('timeMode', this.mode);
            var collection = this.store.filter(filter);
            this.grid.set('collection', collection);
            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    this.initText(data);
                    this.initChart(this.mode,data);
                }
            }));
        },
        destroyChart: function(){
            // 初回起動時にchartTitleを参照してエラーとならないように入れ子構造にしている
            if(this.chart){
                if(this.chart.chartTitle.innerHTML !== ''){
                this.chart.destroy();
                }
            }
            return '';
        },

        initChart: function(timeMode, data) {
            // Gridとは逆順に並べる必要がある
            data.reverse();
            this.destroyChart();
            // データが取得できていなかったら終了
            if(data.totalLength === 0){
                return false;
            }

            var chartTitle = '';
            if(this.dataKind===DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL){
                chartTitle = '危機管理型';
            }
            if(timeMode === 60 || timeMode === '60') {
                chartTitle += '時間水位';
            } else{
                chartTitle += '10分水位';
            }

            // チャートを生成
            this.chart = new Chart(this.chartNode, {
                    title: chartTitle,
                    // タイトルの場所
                    titlePos: 'top',
                    // タイトルの高さ
                    titleGap: 10
                });

            // グラフ全体のテーマを決定。Themeはdefineで定義。
            this.chart.setTheme(Theme);

            // グラフの背景に描画される線の種類を決定。
            this.chart.addPlot('grid', {type: 'Grid',
                hMajorLines: true,
                hMinorLines: false,
                vMajorLines: true,
                vMinorLines: false,
                majorHLine: { color: 'silver', width: 0.5 },
                majorVLine: { color: 'silver', width: 0.5 } });

            var array = data;
            var nowRiverLevel = 0;
            var maxRiverLevel = this.dataKind===DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL ?
            data[0].floodStartRiverLevel : data[0].dangerousRiverLevel;
            var minRiverLevel = data[0].obsStartRiverLevel < -1 ?
            data[0].obsStartRiverLevel : -1;

            // 時間雨量の縦軸の最大値を取得
            for (var i = 0, j = array.length; i < j; i++) {
                nowRiverLevel = array[i].riverLevel;
                if (nowRiverLevel !== null && !isNaN(nowRiverLevel)) {
                    if (maxRiverLevel <= nowRiverLevel){
                        maxRiverLevel = nowRiverLevel;
                    }
                    if (nowRiverLevel <= minRiverLevel){
                        minRiverLevel = nowRiverLevel;
                    }
                }
            }
            // 縦軸と横軸の最大値、横軸のラベル名等を定義
            this.chart.addAxis('x',
                    {majorTickStep: 1, natural: true, labels: this.getLabelData(data)});
            this.chart.addAxis('y',
                    { min: minRiverLevel, max: maxRiverLevel, vertical: true, fixLower: 'minor', fixUpper: 'minor'});

            // 水位の線グラフを追加
            this.chart.addPlot('riverLevel', {
                type: LinesPlot,
                markers: true,
                fill: 'rgb(239, 68, 111)'
            });
            // 線グラフとプロットされる点を紐付け
            this.chart.addSeries('水位[m]', this.getRiverLevelChartData(data), {
                plot: 'riverLevel',
                stroke: {color: 'rgb(239, 68, 111)'},
                marker: this.markerShape});
            //チェックによる非表示、Gridとの対応
            this.riverLevelTooltip = new Tooltip(this.chart, 'riverLevel');
            this.riverLevelMagnify = new Magnify(this.chart, 'riverLevel');

            if(data[0]){

                if (data[0].riverLevelDanger) {
                    // 氾濫危険水位の線グラフを追加
                    this.chart.addPlot('crisisRiverLevel', {
                        type: LinesPlot
                    });
                    this.chart.addSeries('氾濫危険水位[m]',
                    this.getStdRiverLevelChartData(data, data[0].riverLevelDanger), {
                        plot: 'crisisRiverLevel',
                        stroke: {color: '#fc002e'}});
                    this.crisisRiverLevelTooltip = new Tooltip(this.chart, 'crisisRiverLevel');
                    this.crisisRiverLevelMagnify = new Magnify(this.chart, 'crisisRiverLevel');
                }

                if (data[0].riverLevelEvacuate) {
                    // 避難判断水位の線グラフを追加
                    this.chart.addPlot('judgmentRiverLevel', {
                        type: LinesPlot
                    });

                    this.chart.addSeries('避難判断水位[m]',
                    this.getStdRiverLevelChartData(data, data[0].riverLevelEvacuate), {
                        plot: 'judgmentRiverLevel',
                        stroke: {color: '#f89303'}});
                    this.judgmentRiverLevelTooltip = new Tooltip(this.chart, 'judgmentRiverLevel');
                    this.judgmentRiverLevelMagnify = new Magnify(this.chart, 'judgmentRiverLevel');
                }

                if (data[0].riverLevelCaution) {
                // 氾濫注意
                    this.chart.addPlot('cautionRiverLevel', {
                        type: LinesPlot
                    });

                    this.chart.addSeries('氾濫注意水位[m]',
                    this.getStdRiverLevelChartData(data, data[0].riverLevelCaution), {
                        plot: 'cautionRiverLevel',
                        stroke: {color: '#fae100'}});
                    this.cautionRiverLevelTooltip = new Tooltip(this.chart, 'cautionRiverLevel');
                    this.cautionRiverLevelMagnify = new Magnify(this.chart, 'cautionRiverLevel');
                }

                if (data[0].riverLevelStandby) {
                    // 水防団待機
                    this.chart.addPlot('waitRiverLevel', {
                        type: LinesPlot
                    });
                    this.chart.addSeries('水防団待機水位[m]',
                        this.getStdRiverLevelChartData(data, data[0].riverLevelStandby), {
                            plot: 'waitRiverLevel',
                            stroke: {color: '#9bff9b'}});
                    this.waitRiverLevelTooltip = new Tooltip(this.chart, 'waitRiverLevel');
                    this.waitRiverLevelMagnify = new Magnify(this.chart, 'waitRiverLevel');
                }

                if (data[0].floodStartRiverLevel || data[0].floodStartRiverLevel === 0) {
                    // 氾濫開始水位
                    this.chart.addPlot('floodStartRiverLevel', {
                        type: LinesPlot
                    });
                    this.chart.addSeries('氾濫開始水位[m]',
                        this.getStdRiverLevelChartData(data, data[0].floodStartRiverLevel), {
                            plot: 'floodStartRiverLevel',
                            stroke: {color: '#0C000C'}});
                    this.floodStartRiverLevelTooltip = new Tooltip(this.chart, 'floodStartRiverLevel');
                    this.floodStartRiverLevelMagnify = new Magnify(this.chart, 'floodStartRiverLevel');
                }

                if (data[0].dangerousRiverLevel) {
                    // 危険水位
                    this.chart.addPlot('dangerousRiverLevel', {
                        type: LinesPlot
                    });
                    this.chart.addSeries('危険水位[m]',
                        this.getStdRiverLevelChartData(data, data[0].dangerousRiverLevel), {
                            plot: 'dangerousRiverLevel',
                            stroke: {color: '#cc2800'}});
                    this.dangerousRiverLevelTooltip = new Tooltip(this.chart, 'dangerousRiverLevel');
                    this.dangerousRiverLevelMagnify = new Magnify(this.chart, 'dangerousRiverLevel');
                }

                if (data[0].obsStartRiverLevel) {
                    // 観測開始水位
                    this.chart.addPlot('obsStartRiverLevel', {
                        type: LinesPlot
                    });
                    this.chart.addSeries('観測開始水位[m]',
                        this.getStdRiverLevelChartData(data, data[0].obsStartRiverLevel), {
                            plot: 'obsStartRiverLevel',
                            stroke: {color: '#e17b00'}});
                    this.obsStartRiverLevelTooltip = new Tooltip(this.chart, 'obsStartRiverLevel');
                    this.obsStartRiverLevelMagnify = new Magnify(this.chart, 'obsStartRiverLevel');
                }
            }
            // チャートを表示
            this.chart.render();

            // プロットの凡例を表示
            // 初回起動時のみ生成
            if(!this.legend.id){
            this.legend = new SelectableLegend({ chart: this.chart, horizontal: true }, this.legend);
            }
            this.legend.set('chart', this.chart);
            this.legend.refresh();
            // 縦軸のタイトルを表示
            this.setLeftAxisTitle(this.chart, 'y', '水位[m]');

        },

        /**
        * 時間モードが変更された際に呼ばれる
        */
        onChangeModeSelect: function(value) {
            this.mode = value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value === '60' ? 'hourly' : value);
            if(value === '10'){
                this.grid.columns['0-5'].label = '10分水位\n[m]';
                this.grid.renderHeader();
            }
            if(value === '60'){
                this.grid.columns['0-5'].label = '時間水位\n[m]';
                this.grid.renderHeader();
            }
            this.store.target = '/api/river/crisis/detail';
            // グリッドを更新
            this.updateGridQuery();
        },

        /**
        * [観測局]を変更し、表の更新を行う。
        */
        onCrisisObsChange: function(){
            if(this.changeFlg !== 0){
                this.observatoryId = this.crisisObsSelectBox.get('value');
                this.onSubmit();
                // データ種別を取得
            }
            this.changeFlg++;
        },

        getRiverLevelChartData: function(data) {
            var chatData = [];

            for(var i = 0; i < data.length; i++){
                var riverLevel = isFinite(data[i].riverLevel) ? data[i].riverLevel : '0';
                var record = {
                        x: i + 1,
                        y: riverLevel
                };
                chatData.push(record);
            }

            return chatData;
        },

        getStdRiverLevelChartData: function(data, stdRiverLavel) {
            var chatData = [];

            for(var i = 0; i < data.length; i++){
                var record = {
                        x: i + 1,
                        y: stdRiverLavel
                };
                chatData.push(record);
            }

            return chatData;
        },

        setLeftAxisTitle: function(chart, axisname, title) {
            var axis = chart.axes[axisname];
            var x = 50;
            var y = 30;
            var label = title;
            var rotate = 0;
            var m = Matrix;
            var elem = axis.group.createText({x:x, y:y, text:label, align: 'middle'});
            elem.setFill('black');
            elem.setTransform(m.rotategAt(rotate, x,y));
        },

        getLabelData: function(data) {
            var labelData = [];
            for(var i = 0; i < data.length; i++){
                var time = data[i].time;
                time = time.replace('時', ':').replace('分', '');

                var label = {
                        value: i + 1,
                        text: time
                };

                labelData.push(label);
            }

            return labelData;
        },

        onCrisisRiverLevelObsLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // Todo:
            Router.moveTo('observation/crisisManage');
        }
    });
});

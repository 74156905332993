/**
 * <避難所状況一覧画面>
 *
 * @module app/shelter/ShelterListPage.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/ShelterListPage.html', // テンプレート文字列
    'dojo/topic',
    'idis/consts/USER_TYPE',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/config',
    'app/model/DisasterInfo',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/provide/ProvideShelterRegisterDialog',
    '../view/form/DistrictSelector',
    './ShelterFormDialog',
    './ShelterListGrid',
    './ShelterFileUploadDialog'
], function(module, declare, lang, domStyle, json, template, topic,
        USER_TYPE, Locator, Router, UserInfo, Requester, IdisRest, DialogChain, InfoDialog, Loader, _PageBase,
        config, DisasterInfo, Region) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var filterStore = {};

    /**
     * 避難所状況一覧画面
     *
     * @class ShelterListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/shelter/ShelterListPage~ShelterListPage# */
    {
        // テンプレート文字列
        templateString: template,
        shelterGrid: null,
        store: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 市町村CD
         */
        _municipalityCd: null,

        /**
         * 地域CD
         */
        _districtCd: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--shelter',

        // イベント格納
        _events: [],

        constructor: function() {
            this.store = new IdisRest({
                target: '/api/shelters'
            });
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'shelterId',
                target: '/api/shelters',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 概況画面で選択された市町村がある場合
            if(Locator.getQuery().municipalityCd){
                //それが管理対象の市町である場合、municipalityCdとしてセット
                var getQueryMuniCd = Locator.getQuery().municipalityCd;
                this._municipalityCd = getQueryMuniCd;
                // 振興局/市町村セレクタを初期化
                this.municipalityCd.set('value', getQueryMuniCd);
            }else{// クエリパラメータ自体がない場合は、管理対象市町の一つをmunicipalityCdとしてセット。
                this._municipalityCd = UserInfo.getMunicipalityCds()[0];
            	// 振興局/市町村セレクタを初期化
                if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                    if (config.municInfo.cityDistrictMunicCds.indexOf(UserInfo.getMunicipalityCd()) === -1) {
                        this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                    } else {
                        this.municipalityCd.set('value', config.municInfo.cityMunicCd);
                    }
                } else if (UserInfo.getUserType() === USER_TYPE.REGION) {
                    this.municipalityCd.set('value', UserInfo.getRegionCd());
                }
            }

            // 市町ユーザは変更不可
            if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                this.municipalityCd.set('noAllButton', true);
            }   

            //this.initDistrictSelector();
            this.own(this.formDialog);
            this.own(this.fileUploadDialog);
            this.initFileUploadDialog();
        },

        startup: function() {
            this.inherited(arguments);
            this.initShelterGrid();
            this.setButtonStatus();
            this.borderContainer.resize();

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                domStyle.set(this.overviewLabel, 'display', 'none');
            }

        },
        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');
            // 市町村
            if (value.municipalityCd) {
                filterStore.municipalityCd = value.municipalityCd;
            }
            // 開設日時From
            if (value.shelterStartTimeFrom) {
                filterStore.shelterStartTimeFrom = value.shelterStartTimeFrom;
            }
            // 開設日時To
            if (value.shelterStartTimeTo) {
                filterStore.shelterStartTimeTo = value.shelterStartTimeTo;
            }
            // 閉鎖日時From
            if (value.shelterEndTimeFrom) {
                filterStore.shelterEndTimeFrom = value.shelterEndTimeFrom;
            }
            // 閉鎖日時To
            if (value.shelterEndTimeTo) {
                filterStore.shelterEndTimeTo = value.shelterEndTimeTo;
            }
            // 過去報表示
            filterStore.activeFlg = this.activeFlg.checked ? '1' : '0';
            // 避難所区分
            filterStore.eDesignatedEvacShFlg = this.eDesignatedEvacShFlg.checked ? '1' : '0';
            filterStore.designatedEvacShFlg = this.designatedEvacShFlg.checked ? '1' : '0';
            filterStore.welfareEvacShFlg = this.welfareEvacShFlg.checked ? '1' : '0';
            filterStore.temporaryEvacShFlg = this.temporaryEvacShFlg.checked ? '1' : '0';
            filterStore.civilProtectionEvacShFlg = this.civilProtectionEvacShFlg.checked ? '1' : '0';
            filterStore.otherEvacShFlg = this.otherEvacShFlg.checked ? '1' : '0';
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
            //保管されていれば値をセット
            // 市町・地区
            if(filterStore.municipalityCd) {
                this.municipalityCd.set('value', filterStore.municipalityCd);
            }
            // 開設日時From
            if(filterStore.shelterStartTimeFrom) {
                this.shelterStartTimeFrom.set('value', filterStore.shelterStartTimeFrom);
            }
            // 開設日時To
            if(filterStore.shelterStartTimeTo) {
                this.shelterStartTimeTo.set('value', filterStore.shelterStartTimeTo);
            }
            // 閉鎖日時From
            if(filterStore.shelterEndTimeFrom) {
                this.shelterEndTimeFrom.set('value', filterStore.shelterEndTimeFrom);
            }
            // 閉鎖日時To
            if(filterStore.shelterEndTimeTo) {
                this.shelterEndTimeTo.set('value', filterStore.shelterEndTimeTo);
            }
            // 過去報表示
            if(filterStore.activeFlg) {
                this.activeFlg.set('value', filterStore.activeFlg === '1' ? true : false);
            }
            if(filterStore.eDesignatedEvacShFlg) {
                this.eDesignatedEvacShFlg.set('value',
                        filterStore.eDesignatedEvacShFlg === '1' ? true : false);
            }
            if(filterStore.designatedEvacShFlg) {
                this.designatedEvacShFlg.set('value',
                        filterStore.designatedEvacShFlg === '1' ? true : false);
            }
            if(filterStore.welfareEvacShFlg) {
                this.welfareEvacShFlg.set('value',
                        filterStore.welfareEvacShFlg === '1' ? true : false);
            }
            if(filterStore.temporaryEvacShFlg) {
                this.temporaryEvacShFlg.set('value',
                        filterStore.temporaryEvacShFlg === '1' ? true : false);
            }
            if(filterStore.civilProtectionEvacShFlg) {
                this.civilProtectionEvacShFlg.set('value',
                        filterStore.civilProtectionEvacShFlg === '1' ? true : false);
            }
            if(filterStore.otherEvacShFlg) {
                this.otherEvacShFlg.set('value',
                        filterStore.otherEvacShFlg === '1' ? true : false);
            }
        },
        /**
        * ファイルアプロードダイアログ初期化処理
        */
        initFileUploadDialog: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.fileUploadDialog;
            var page = dialog.getChildren()[0];
            // page.initOptions(options);
            // 登録ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function() {
                dialog.hide();
                this.updateGridQuery(this.form.get('value'));
            }));
        },

        //ユーザー権限に応じてボタンの表示を制御
        setButtonStatus: function(){
            // 避難所管理の登録権限を持たない場合、「新規開設」「ファイル読み込み」「一括操作」ボタンを非表示にする。
            // 施設追加ボタンについて開設時にマスタ登録が必要になったとき用のボタンを想定、開設権限がない場合に非表示とする
            if (!UserInfo.hasWriteAuthz('F05006')){
                domStyle.set(this.RegisterButton.domNode, 'display', 'none');
                domStyle.set(this.BulkOperatingButton.domNode, 'display', 'none');
                domStyle.set(this.FileUploadButton.domNode, 'display', 'none');
                domStyle.set(this.RegisterFacilityButton.domNode, 'display', 'none');
            }
            // 長崎では配信のみを行うという操作が発生しないため、Lアラート配信ボタンを常に非表示
            domStyle.set(this.sendButton.domNode, 'display', 'none');
            // 施設管理の登録権限を持たない場合、施設追加ボタンを非表示にする。
            if (!UserInfo.hasWriteAuthz('F10001')){
                domStyle.set(this.RegisterFacilityButton.domNode, 'display', 'none');
            }
        },

        //帳票出力ボタンを押した時の挙動
        showFormDialog: function() {
            this.formDialog.show();
        },
        //ファイル読み込みボタンを押した時の挙動
        showFileUploadDialog: function() {
            this.fileUploadDialog.show();
        },

        /**
        * 地区選択セレクターを初期化する。
        */
        initDistrictSelector: function() {
            var municipalityCd = this._municipalityCd;
            if (!municipalityCd) {
                return;
            }
            // 市町の地区コードは市町コードの市町部分(3~5桁目)+000
            if (this._municipalityCd !== config.municInfo.prefMunicCd) {
                var districtCd = municipalityCd.substr(2, 3) + '000';
                if (districtCd !== '000000') {
                    this._districtCd.set('value', districtCd);
                }
            }

        },

        initShelterGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.shelterGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));

            // 前回と同一市町の検索の場合、保管した検索条件をセットする
            if (this._municipalityCd === filterStore.municipalityCd) {
                this.setFilterData();
            }
            // 再検索
            this.updateGridQuery(this.form.get('value'));
        },

        /**
        * グリッドの検索条件を指定された値で更新する。
        * @param {Object} value name属性と値のマッピング
        */
        updateGridQuery: function() {
            var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            if (value.districtCd) {
                filter = filter.eq('districtCd', value.districtCd);
            }
            if (value.facilityName) {
                filter = filter.eq('mfacility.facilityName', value.facilityName);
            }
            if (value.shelterStartTimeFrom) {
                var shelterStartTimeFrom = new Date(value.shelterStartTimeFrom).getTime();
                filter = filter.eq('shelterStartTimeFrom', shelterStartTimeFrom);
            }
            if (value.shelterStartTimeTo) {
                var shelterStartTimeTo = new Date(value.shelterStartTimeTo).getTime();
                filter = filter.eq('shelterStartTimeTo', shelterStartTimeTo);
            }
            if (value.shelterEndTimeFrom) {
                var shelterEndTimeFrom = new Date(value.shelterEndTimeFrom).getTime();
                filter = filter.eq('shelterEndTimeFrom', shelterEndTimeFrom);
            }
            if (value.shelterEndTimeTo) {
                var shelterEndTimeTo = new Date(value.shelterEndTimeTo).getTime();
                filter = filter.eq('shelterEndTimeTo', shelterEndTimeTo);
            }
            // 過去報表示が選択されていない場合、アクティブラグが立っているものだけを検索
            if (!this.activeFlg.get('checked')) {
                filter = filter.eq('activeFlg', '1');
            }

            // 避難所区分
            var shelterCategory = '';
            if(this.eDesignatedEvacShFlg.checked){
                shelterCategory += '01,';
            }
            if(this.designatedEvacShFlg.checked){
                shelterCategory += '02,';
            }
            if(this.welfareEvacShFlg.checked){
                shelterCategory += '03,';
            }
            if(this.temporaryEvacShFlg.checked){
                shelterCategory += '04,';
            }
            if(this.civilProtectionEvacShFlg.checked){
                shelterCategory += '06,';
            }
            if(this.otherEvacShFlg.checked){
                shelterCategory += '05,';
            }
            filter = filter.eq('shelterCategory', shelterCategory);

            this.isRegion(value.municipalityCd).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    //振興局として設定
                    filter = filter.eq('regionCd', value.municipalityCd);
                } else {
                    //市町村として設定
                    filter = filter.eq('municipalityCd', value.municipalityCd);
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.shelterGrid.set('collection', collection);

                //検索条件の保管
                this.setFilterStore();
            }));
        },

        /**
        * 市町村セレクタで振興局を選択したかどうかを判定する。
        * true:振興局、false:市町村
        */
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
        * 検索ボタンが押されたときに呼び出される。
        * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
        * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
        */
        onSubmit: function() {
            try {
                if (this.municipalityCd.get('value') === '') {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'いずれかの市町村を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                }
                else if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                }
                this._events.push(this.endTimeEvent);
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
        * 配信情報(開設避難所情報)ダイアログを表示する。
        */
        showShelterDialog: function() {
            var args = {};
            args.shelterArgs = {
                sendCategory : '02',
                subject: ' ',
                evacTimestamp: new Date(),
                evaqueeData: [],
                municipalityCd: this.municipalityCd.get('value'),
                // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                distributionType: '01',
                isPrefMailDefault: true
            };

            args.evacOrderArgs = null;

            // ダイアログの最初の子要素が登録画面
            var dialog = this.shelterRegisterDialog.getChildren()[0];

            // ダイアログの画面を初期化する。
            Loader.wait(dialog.initDialog(args, false, false, false))
            .then(lang.hitch(this, function(){
                // TODO 一括配信時のメール文面実装
                // メール文面用ダイアログを初期化する。
                //dialog.initMailColumn();
                // ダイアログのsendイベントを受け取れるようにする
                dialog.on('send', lang.hitch(this, function(evt) {
                    return this.sendLalert(evt.value);
                }));
                // ダイアログを破棄できないように設定する。
                this.shelterRegisterDialog.set('closable', false);
                // ダイアログを表示する。
                this.shelterRegisterDialog.show();
            }));
        },

        sendLalert: function(shelterInfo){

            var message = 'Lアラート等への配信を行います。\nよろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {

                var jsonStr = json.stringify(shelterInfo.lalertForm);
                var promise =  Requester.post('/api/lalert/send', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    // 成功時
                    chain.infoComplete(lang.hitch(this, function() {
                        // ヘッダーに反映
                        topic.publish(module.id + '::delivered');
                        chain.hide();
                        this.shelterRegisterDialog.getChildren()[0].closeDialog();
                        this.onSubmit();
                    }));
                }), function(err) {
                    // 失敗時
                    if(err.response.data && typeof(err.response.data) === 'string'){
                        err.response.data = json.parse(err.response.data);
                    }
                    chain.infoError(err);
                });
                Loader.wait(promise);
            }));
        },

        /**
        * ページ遷移時に格納していたイベントを削除する。
        */
        _removeEvents: function(){
            this._events.forEach(function(event){
                event.remove();
            });
        },

        //新規登録ボタンを押下したときの挙動
        onRegisterButtonClick: function() {
            // 避難所情報新規登録画面へ遷移
            Router.moveTo('shelter/register', {
                municipalityCd : this._municipalityCd
            });
        },
        //施設追加ボタンを押下したときの挙動
        onRegisterFacilityButtonClick: function() {
            // 施設新規登録画面へ遷移
            Router.moveTo('facility/register', {
                type : 'shelter'
            });
        },
        //一括操作ボタンを押下したときの挙動
        onOpeningButtonClick: function() {
            if (this.municipalityCd.get('value') !== config.municInfo.prefMunicCd) {
                // 市町の地区コードは市町コードの市町部分(3~5桁目)+000
                var districtCd = this._municipalityCd.substr(2, 3) + '000';
                if (districtCd !== '000000') {
                    this._districtCd = districtCd;
                }
            }
            // 避難所情報一括操作画面へ遷移
            Router.moveTo('shelter/operating', {
                municipalityCd : this.municipalityCd.get('value'),
                districtCd : this._districtCd
            });
        },

        //詳細ボタンを押下したときの挙動
        onDetailButtonClick: function(object) {
            // 避難所情報詳細画面へ遷移
            Router.moveTo('shelter/detail', {
                municipalityCd : this._municipalityCd,
                shelterId : object.shelterId
            });
        },
        //パンくずリストのボタンを押下したときの挙動
        onShelterAdminPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin');
        },
        //詳細検索を表示ボタンをクリックした時の挙動
        onOpenFilterClick: function() {
            domStyle.set(this.shelterEndTime, 'display', '');
            domStyle.set(this.searchActiveFlg, 'display', '');
            domStyle.set(this.shelterCategory, 'display', '');
            domStyle.set(this.detailSearchOpen, 'display', 'none');
            domStyle.set(this.detailSearchClose, 'display', '');
            this.borderContainer.resize();
        },
        //詳細検索を閉じるボタンをクリックした時の挙動
        onCloseFilterClick: function() {
            domStyle.set(this.shelterEndTime, 'display', 'none');
            domStyle.set(this.searchActiveFlg, 'display', 'none');
            domStyle.set(this.shelterCategory, 'display', 'none');
            domStyle.set(this.detailSearchOpen, 'display', '');
            domStyle.set(this.detailSearchClose, 'display', 'none');
            this.borderContainer.resize();
        }
        }
    );
});

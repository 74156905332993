define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--emergency',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
          // ボタン
          helper.buttonColumn('detail', '詳細'),
          {field: 'tmplSubject', label: '件名', sortable: false},
          {field: 'tmplBody', label: '本文', sortable: false}
        ]
    });
});

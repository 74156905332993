/**
 * 観測地域モデル
 * @module app/model/ObservationRegion
 */
define([
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'value',
        data: [
            {
                'value': '310000',
                'label': '鳥取県全域',
                'selected': true,
                'regionFlg': ''
            },
            {
                'value': '',
                'label': '------地域選択------',
                'disabled': 'true',
                'regionFlg': '1'
            },
            {
                'value': '310001',
                'label': '東部振興監',
                'regionFlg': '1'
            },
            {
                'value': '310002',
                'label': '中部総合事務所地域振興局',
                'regionFlg': '1'
            },
            {
                'value': '310003',
                'label': '西部総合事務所地域振興局',
                'regionFlg': '1'
            },
            {
                'value': '310004',
                'label': '日野振興センター日野振興局',
                'regionFlg': '1'
            }
        ]
    });
});

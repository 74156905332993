/**
 * 避難状況登録画面用モジュール。
 * @module app/view/page/EvacOrderRegisterFromRecommendPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderRegisterPageBase',
    'app/model/DisasterInfo',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'app/shelter/ShelterOpeningGrid',
    'app/provide/ProvideEvacOrderRegisterDialog',
    './EvacOrderPopulationGrid'
], function (module, array, declare, lang, domStyle, all, topic, template,
    json, _EvacOrderRegisterPageBase, DisasterInfo, Loader, Locator, Router,
    UserInfo, Requester) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderRegisterPageBase~_EvacOrderRegisterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderRegisterPageBase,
    /** @lends module:app/evacorder/EvacOrderRegisterFromRecommendPage~EvacOrderRegisterFromRecommendPage# */ {
            // テンプレート文字列
            templateString: template,

            _evacRecommendIds: null,

            _districtCdList: [],

            constructor: function () {
                // URLから発令判断支援IDを取得
                this._evacRecommendIds = Locator.getQuery().evacRecommendIds;
            },

            startup: function () {
                this.inherited(arguments);


                // 戻るボタン（発令判断支援用）を表示
                domStyle.set(this.cancelButtonArea, 'display', '');
                // ファイル読み込みを非表示
                domStyle.set(this.fileImportBtn.domNode, 'display', 'none');

                // 操作されないようにローディング中にしておく
                Loader.show()
                    .then(lang.hitch(this, function () {
                        //発令判断支援の情報を取得してくる
                        return this.initRegisterPage();
                    }))
                    .then(lang.hitch(this, function(){
                        // 地図の初期位置を設定する。
                        return this.initLatlng();
                    }))
                    // 市町村コードがモジュールにセットされてから処理される。
                    .then(lang.hitch(this, function () {
                        // 発令内容セレクターを変更。
                        this.setEvacOrderTypeSelector(this._issueReasonType, this._evacOrderType);
                        // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
                        this.initTreeAndLayers().then(Loader.hide);
                    }));
            },

            /**
             * 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
             * @returns promise
             */
            initTreeAndLayers: function () {
                return this.initTree().then(lang.hitch(this, 'initDistrictLayer'))
                    .then(lang.hitch(this, function () {
                        // 準備が整ったら選択中の各地区をチェック状態にする
                        this._selectDistricts(this._districtCdList);
                        // 現況状況レイヤーの初期値をONにする。
                        this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                        // 避難所一覧グリッドを検索する
                        this.queryShelterSimply();
                    }));
            },

            initRegisterPage: function () {
                return Requester.get('/api/evacrecommend/' + this._evacRecommendIds)
                .then(lang.hitch(this, function (data) {
                    // 市町村
                    this.municipalitySelector.set('value', data.municipalityCd);
                    this._municipalityCd = data.municipalityCd;

                    // 発令理由
                    this.issueReasonType.set('value', data.issueReasonType);
                    this.issueReasonType.setDisabled(true);
                    this._issueReasonType = data.issueReasonType;

                    // 発令内容
                    this.evacOrderType.set('value', data.evacOrderType);
                    this._evacOrderType = data.evacOrderType;

                    // 地区
                    this._districtCdList = data.districtList;

                }), lang.hitch(this, function (error) {
                    if (error.response.data && typeof (error.response.data) === 'string') {
                        error.response.data = json.parse(error.response.data);
                    }
                    this.chain.infoError(error);
                }));

            }
        });
});

/**
 * 配信情報Lアラート内容確認ダイアログ（避難所）
 * @module app/sendingHistory/ShelterDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ShelterDialog.html',
    'idis/view/dialog/DialogChain',
    'dojo/date/locale',
    'dojo/dom-style',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/provide/form/DistributionTypeInput',
    'app/sending/ShelterGrid'
], function (module, array, declare, lang, template, DialogChain, locale, domStyle, Requester, _PageBase) {
    /**
     * 配信情報Lアラート内容確認ダイアログ（避難所）
     * @class ShelterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sendingHistory/Dialog/ShelterDialog~ShelterDialog# */ {
            // テンプレート文字列
            templateString: template,

            baseClass: 'idis-Page idis-Page--sending',

            // グリッドに描画したデータ
            _lastRowList: null,

            constructor: function () {
                this.chain = DialogChain.get(this);
            },

            initDialog: function (obj) {
                // 配信情報種別
                this.distributionType.set('value', obj.shelter.distributionType);

                // 発行元
                this.municipalityName.innerHTML = obj.municipalityName;

                // 補足情報
                this.complementaryInfo.set('value', obj.shelter.complementaryInfo);

                // 災害名
                Requester.get('/api/disasters/' + obj.disasterId, {
                }).then(lang.hitch(this, function (disasterObj) {
                    this.disasterName.innerHTML = disasterObj.disasterName;
                }), lang.hitch(this, function () {
                    // error
                    this.disasterName.innerHTML = '';
                }));

                // 発令日時
                this.sendTimestamp.innerHTML = locale.format(new Date(obj.sendTimestamp));

                // 避難世帯数
                this.abstEvacHouseholdNum.innerHTML = obj.shelter.abstEvacHouseholdNum;

                // 避難者数
                this.abstEvaqueeNum.innerHTML = obj.shelter.abstEvaqueeNum;

                // 前回グリッドに描画したデータを消去する
                if (this._lastRowList) {
                    array.forEach(this._lastRowList, function (rowNode) {
                        this.shelterGrid.removeRow(rowNode, false);
                    }, this);
                }

                // グリッドにデータを描画し、そのデータを保管しておく
                this._lastRowList = this.shelterGrid.renderArray(obj.shelter.items);

                this.container.resize();
            },

            onOKButton: function () {
                this.getParent().hide();
            }
        });
});

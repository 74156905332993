/**
 * 指定河川洪水情報警戒レベル
 * @module app/model/FloodWarningLevel
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        idProperty: 'level',
        data: [
            {
                level: "5",
                label: "氾濫発生情報"
            }, {
                level: "4",
                label: "氾濫危険情報"
            }, {
                level: "3",
                label: "氾濫警戒情報"
            }, {
                level: "2",
                label: "氾濫注意情報"
            }
        ]
    });
});

/**
 * （指定河川洪水予報）水位観測所マスタ選択用入力パーツ
 * @module app/view/form/FloodStationSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, TreeChecker, CacheStoreModel, CacheTreeRest) {
    /**
     * 水位観測所選択用パーツ。
     * @class WeatherWarnSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/form/FloodStationSelector~FloodStationSelector# */ {
        // 選択ダイアログのタイトル
        title: '水位観測所選択',

        constructor : function() {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/floodForecasts/floodStations',
                    itemMapper: function(item) {
                        return {
                            id: item.waterLevelStationCode,
                            name: item.waterLevelStationName,
                            parentId: null
                        };
                    }
                })
            });
        }
    });
});

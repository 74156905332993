/**
 * Lアラートお知らせ配信ダイアログ。
 * @module app/provide/ProvideShelterRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/text!./templates/ProvideShelterRegisterDialog.html',
    'app/provide/_ProvideDialogBase',
    'app/model/DisasterInfo',
    'dstore/Memory',
    'idis/store/IdisRest',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'app/shelter/consts/CrowdedStatus',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/SimpleTextarea',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/DisasterSelector',
    './form/DistributionTypeInput',
    './ProvideShelterGrid'
], function(module, declare, lang, array, domStyle, template, _ProvideDialogBase,
    DisasterInfo, Memory, IdisRest, Router, Requester, InfoDialog, CrowdedStatus) {
    /**
     * Lアラートお知らせ登録ダイアログ画面。
     * @class ProvideShelterDialogRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ダイアログを開く際に設定する。
        contentType: null,

        // 登録用ストア
        registerStore: null,

        // 災害ID
        _disasterId: null,

        // 施設ID
        _facilityId: null,

        // 引数
        _args: null,

        // 配信日時
        sendTimestamp: null,

        // 避難所グリッド用のストア
        gridStore: null,

        // Lアラート配信時に発信元として設定する市町コード
        _municipalityCd: null,

        // 緊急速報メール利用有無判別フラグ
        _urgentMailFlg: false,

        // 緊急速報メール配信の判断に用いる。
        // 避難情報が自主避難より格上であり、格上げまたは範囲拡大の場合trueとする。
        _isUrgentMailTarget: false,

        // 前回の配信履歴
        _lalertSendHistId: null,

        _isCorrectOrCancel: false,

        _isShelterOnly: false,

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.shelterStore = new Memory();
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });

            this._disasterId =  DisasterInfo.getDisasterId();
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        // isCorrectOrCancelは、避難所の訂正/取消が実施された場合にtrueが連携される
        initDialog: function(args, isCorrect, isCancel, isRemoving){
            this._args = args;
            this._isCorrectOrCancel = (isCorrect || isCancel);

            var obj = args.shelterArgs;
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this._municipalityCd = obj.municipalityCd;
            this.municipalityCd._setValueAttr(this._municipalityCd);

            this._facilityId = args.facilityId;

            // 災害名をセットする。
            this.disasterNameNode.set('value',this._disasterId);
            // 補足情報
            this.complementaryInfo._setValueAttr(this._complementaryInfo ? this._complementaryInfo : '');

            if (args.evacOrderArgs !== null) {
                // 避難所＋避難情報で出す場合
                this._isShelterOnly = false;

                domStyle.set(this.showShelterButton.domNode, 'display', '');
                domStyle.set(this.sendButton.domNode, 'display', '');
                domStyle.set(this.sendShelterOnlyButton.domNode, 'display', 'none');

                domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
                this.borderContainer.resize();

                // 緊急速報メール対象市町フラグをセットする。
                return this.setLalertContent(obj, true, isCancel, false);
            } else {
                // 避難所だけで出す場合
                this._isShelterOnly = true;

                if(this._isCorrectOrCancel){
                    domStyle.set(this.errataDescriptionItemNode, 'display', '');
                    this.borderContainer.resize();
                } else {
                    domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
                    this.borderContainer.resize();
                }

                domStyle.set(this.showShelterButton.domNode, 'display', 'none');
                domStyle.set(this.sendButton.domNode, 'display', 'none');
                domStyle.set(this.sendShelterOnlyButton.domNode, 'display', '');
                this.borderContainer.resize();

                // 配信設定情報を取得する。
                return this.setLalertContent(obj, false, isCancel, isRemoving);
            }
        },

        // 画面から渡された値で県民向けメールの表示・非表示判定を行う
        initMailColumn: function(lastStatus){
            // 避難情報登録での避難所一括操作 または 避難所一括操作画面
            if(this._sheltersData.length > 1 || (lastStatus &&
                lastStatus[this._sheltersData[0].facilityId])){
                var displayFlg = false;
                array.some(this._sheltersData, lang.hitch(this, function(data){
                    // 一括操作
                    if(lastStatus){
                        var startTime = lastStatus[data.facilityId].shelterStartTime;
                        var endTime = lastStatus[data.facilityId].shelterEndTime;
                        if(!startTime && !endTime && data.evacShelterSort === '開設'){
                            // 準備中→開設済
                            displayFlg = true;
                            return true;
                        } else if(!startTime && !endTime && data.evacShelterSort === '閉鎖'){
                            // 準備中→閉鎖
                            displayFlg = true;
                            return true;
                        } else if(startTime && !endTime &&  data.evacShelterSort === '閉鎖'){
                            // 開設済→閉鎖
                            displayFlg = true;
                            return true;
                        }
                    }
                }));
                if(displayFlg){
                    // 県民メールを表示する
                    this._lastStatus = lastStatus;
                    domStyle.set(this.prefMailColumn.domNode, 'display', '');
                    this.onCloseMsgClick();
                } else {
                    // 県民メールを表示しない
                    domStyle.set(this.prefMailColumn.domNode, 'display', 'none');
                }
            } else if(!lastStatus){
                // 避難所新規登録：開設済みかつ公開可能
                if(this._sheltersData.length > 0 &&
                    (this._sheltersData[0].statusName === '1' || this._sheltersData[0].statusName === '9')){
                    domStyle.set(this.prefMailColumn.domNode, 'display', '');
                    this.onCloseMsgClick();
                } else {
                    domStyle.set(this.prefMailColumn.domNode, 'display', 'none');
                }
            } else if(!this._isCancel && lastStatus[0]){
                // 避難所詳細：続報の登録または訂正かつ公開可能
                if(lastStatus[0] === '0'){
                    // 開設準備中→開設済み
                    domStyle.set(this.prefMailColumn.domNode, 'display', '');
                    this.onCloseMsgClick();
                } else if(lastStatus[0] === '9' && this._sheltersData[0].evacShelterSort === '開設'){
                    // 閉鎖→開設済み
                    domStyle.set(this.prefMailColumn.domNode, 'display', '');
                    this.onCloseMsgClick();
                } else if(lastStatus[0] === '1' && this._sheltersData[0].statusName === '9'){
                    // 開設済み→閉鎖
                    domStyle.set(this.prefMailColumn.domNode, 'display', '');
                    this.onCloseMsgClick();
                } else {
                    domStyle.set(this.prefMailColumn.domNode, 'display', 'none');
                }
            } else {// 避難所詳細 取消：表示なし
                // メール文面を非表示
                domStyle.set(this.prefMailColumn.domNode, 'display', 'none');
            }
        },

        setLalertContent: function(obj, isFromEvacOrderPage, isCancel, isRemoving){

            // Lアラート用避難所情報を取得
            return Requester.get('/api/shelters/lalert/', {
                query: {
                    disasterId: this._disasterId,
                    municipalityCd: this._municipalityCd
                }
            }).then(lang.hitch(this, function(result) {

                // 前回の配信履歴がある場合は保持
                this._lalertSendHistId = result.lalertSendHistId;
                // 更新種別の設定
                var distType = obj.distributionType;
                // 更新種別が新規かつ前回の配信履歴があり、その配信履歴が「取消し」でない場合は更新に切り替える
                if (distType === '01' && (this._lalertSendHistId && result.distributionType !== '03' )) {
                    distType = '02';
                }

                // 「避難所取消」の場合は、取消した避難所がその市町村のラスト1件であれば、「取消(=03)」をセットする。。
                // それ以外であれば、「更新・訂正(=02)」の訂正。
                if(isCancel && isRemoving && result.shelters.length === 1 &&
                    result.shelters[0].facilityId === obj.evaqueeData[0].facilityId){
                    // isRemoving()かつ、result.sheltersの長さが1であり、
                    // facilityIdが一致していれば、消した避難所がその市町村のラスト1件であると言える。

                    distType = '03';
                    // 「取り消したい最後の情報」がobj.evaqueeData[0]に入っているはずなので、
                    // それをgrid上に表示する
                    result.shelters.push(obj.evaqueeData[0]);
                }


                this.distributionTypeInput.set('value', distType);
                // 空で初期化
                this.errataDescriptionInput.set('value', '');

                var shelters = [];
                var facilityIdList = [];
                var totalEvaqueeNum = 0;
                var totalEvaqueeActNum = 0;
                var totalEvacHouseholdNum = 0;
                var totalEvacHouseholdActNum = 0;

                var shelterOpenFlg = false;

                array.forEach(obj.evaqueeData, function(facility){
                    // 緯度経度は、そのままだと桁が大きいので、小数点4桁まで丸める
                    facility.evacShelterLatitude = Math.round(facility.evacShelterLatitude * 10000) / 10000;
                    facility.evacShelterLongitude = Math.round(facility.evacShelterLongitude * 10000) / 10000;

                    // このあとの処理の為、facilityIdをキーにしてオブジェクトを取得できるマップを作っておく。
                    facilityIdList.push(facility.facilityId);
                    if(!isRemoving || distType === '03'){
                        // isRemoving=true(唯一の報の取消である)場合は、Lアラートとして連携しない。
                        // isRemoving=falseの場合のみpushし、Lアラートに送れるようにする。
                        // ただし、isRemoving=trueでも、その市町最後の避難所情報（＝Lアラート取消報：distType=03）
                        // だった場合は、取消内容を表示しなければならないので、グリッドに表示する。

                        shelters.push(facility);

                        // ついでに避難者情報も足し算する
                        if(facility.evacShelterSort === '開設'){
                            totalEvaqueeNum += facility.evaqueeNum;
                            totalEvaqueeActNum += facility.evaqueeActNum;
                            totalEvacHouseholdNum += facility.evacHouseholdNum;
                            totalEvacHouseholdActNum += facility.evacHouseholdActNum;
                        }

                        // これから操作する避難所が登録済みの避難所に含まれているか
                        var shelter = result.shelters.filter(function(v) {
                            return(v.facilityId === facility.facilityId);
                        });
                        // 開設されたかを判定
                        if (shelter.length === 0) {
                            // 新規開設の場合
                            shelterOpenFlg = true;
                        } else if (shelter[0].evacShelterSort === '閉鎖' && facility.evacShelterSort === '開設') {
                            // 閉鎖から開設に変更された場合
                            shelterOpenFlg = true;
                        }
                    }
                });

                array.forEach(result.shelters, function(shelter){
                    // 緯度経度は、そのままだと桁が大きいので、小数点4桁まで丸める
                    shelter.evacShelterLatitude = Math.round(shelter.evacShelterLatitude * 10000) / 10000;
                    shelter.evacShelterLongitude = Math.round(shelter.evacShelterLongitude * 10000) / 10000;

                    if(facilityIdList.indexOf(shelter.facilityId) === -1){
                        // 対象災害をセット
                        var evacShelterTypeOfDisaster = '';
                        var evacShelterTypeOfDisasterList = [];
                        if (shelter.floodShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('洪水');
                        }
                        if (shelter.sedimentDisasterShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('崖崩れ、土石流及び地滑り');
                        }
                        if (shelter.stormSurgeShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('高潮');
                        }
                        if (shelter.earthquakeShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('地震');
                        }
                        if (shelter.tsunamiShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('津波');
                        }
                        if (shelter.fireShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('大規模な火事');
                        }
                        if (shelter.landsideWaterShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('内水氾濫');
                        }
                        if (shelter.volcanoShFlg === '1') {
                            evacShelterTypeOfDisasterList.push('火山現象');
                        }
                        if (evacShelterTypeOfDisasterList && evacShelterTypeOfDisasterList.length !== 0) {
                            evacShelterTypeOfDisaster = evacShelterTypeOfDisasterList.join(',');
                        }
                        shelter.evacShelterTypeOfDisaster = evacShelterTypeOfDisaster;

                        delete shelter.floodShFlg;
                        delete shelter.sedimentDisasterShFlg;
                        delete shelter.stormSurgeShFlg;
                        delete shelter.earthquakeShFlg;
                        delete shelter.tsunamiShFlg;
                        delete shelter.fireShFlg;
                        delete shelter.landsideWaterShFlg;
                        delete shelter.volcanoShFlg;

                        // 混雑状況は必須なので、セットがなければ自動算出する
                        if (!shelter.crowdedStatus) {
                            shelter.crowdedStatus = CrowdedStatus.calcCrowdedStatus(shelter.evaqueeNum, shelter.capacity);
                        }
                        shelter.spaceStatus = CrowdedStatus.getLabel(shelter.crowdedStatus);

                        // 避難所指定区分をセット
                        var evacSuitableShelter = '指定なし';
                        if(shelter.edesignatedPlaceEvacShFlg === '1' && shelter.designatedEvacShFlg === '1' ){
                            evacSuitableShelter = '指定緊急避難場所兼避難所';
                        } else if(shelter.edesignatedPlaceEvacShFlg === '1'){
                            evacSuitableShelter = '指定緊急避難場所';
                        } else if (shelter.designatedEvacShFlg === '1' ){
                            evacSuitableShelter = '指定避難所';
                        } else {
                            evacSuitableShelter = '指定なし';
                        }
                        shelter.evacSuitableShelter = evacSuitableShelter;
                        // 指定避難所フラグ・指定緊急避難場所フラグは削除する
                        delete shelter.designatedEvacShFlg;
                        delete shelter.edesignatedPlaceEvacShFlg;
                        // これから操作する避難所でない場合、そのままリストに追加する。
                        // (これから操作する避難所の場合は、すでにsheltersに追加されているのでスキップする)
                        shelters.push(shelter);

                        // ついでに避難者情報も足し算する
                        if(shelter.evacShelterSort === '開設'){
                            totalEvaqueeNum += shelter.evaqueeNum;
                            totalEvaqueeActNum += shelter.evaqueeActNum;
                            totalEvacHouseholdNum += shelter.evacHouseholdNum;
                            totalEvacHouseholdActNum += shelter.evacHouseholdActNum;
                        }
                    }
                });

                this.totalEvacNum = {
                    totalEvaqueeNum: totalEvaqueeNum,
                    totalEvaqueeActNum: totalEvaqueeActNum,
                    totalEvacHouseholdNum: totalEvacHouseholdNum,
                    totalEvacHouseholdActNum: totalEvacHouseholdActNum
                };

                this.initGrid(shelters, totalEvaqueeNum, totalEvaqueeActNum,
                                    totalEvacHouseholdNum, totalEvacHouseholdActNum);

                return this.getSendingSetting(this._municipalityCd).then(lang.hitch(this, function(){
                    // 外部連携対象があるか
                    var hazExtDistTarget = false;
                    // 開設の時だけ送信選択可能にする
                    if (shelterOpenFlg) {
                        if (this._twitterFlg){
                            hazExtDistTarget = true;
                            domStyle.set(this.snsArea, 'display', '');
                            this.twitterFlg.set('checked', true);
                        }
                        if (this._yahooFlg){
                            hazExtDistTarget = true;
                            domStyle.set(this.yahooArea, 'display', '');
                            this.yahooFlg.set('checked', true);
                        }
                    }
                    // 外部連携ありの場合、公開先欄を表示して、外部連携なしメッセージを非表示
                    if (hazExtDistTarget) {
                        domStyle.set(this.publishArea, 'display', '');
                        domStyle.set(this.publishAreaHideMessage, 'display', 'none');
                    } else {
                        domStyle.set(this.publishArea, 'display', 'none');
                        domStyle.set(this.publishAreaHideMessage, 'display', '');
                    }
                    this.borderContainer.resize();
                }), lang.hitch(this, function(error){
                    console.error(error);
                    this.chain.info('配信設定情報の取得に失敗しました。外部配信を行いません。', 'エラー', function(){
                        this.chain.hide();
                        Router.moveTo('shelter', {
                            municipalityCd: this._municipalityCd
                        });
                    });
                }));

            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
                throw new Error('Lアラート情報の取得に失敗しました。');
            }));
        },

        onCancelButtonClick: function(){
            if(!this._isShelterOnly){
                this.emit('cancel');
            }
            // ダイアログを閉じる。
            this._closeDialog();
        },

        // 避難所グリッドを初期表示する。
        initGrid: function(data, totalEvaqueeNum, totalEvaqueeActNum, totalEvacHouseholdNum, totalEvacHouseholdActNum) {
            // storeに避難所データをセットする。
            this.shelterStore = new Memory({
                data: array.map(data, function(item, i) {
                    return lang.mixin({id:i}, item);
                })
            });

            this.shelterGrid.set('collection', this.shelterStore);

            // 避難者人数
            this.evaqueeNumNode.innerHTML = totalEvaqueeNum ? totalEvaqueeNum + ' 人' : '0 人';

            // 総避難世帯数
            this.evacHouseholdNumNode.innerHTML = totalEvacHouseholdNum ?
                totalEvacHouseholdNum + ' 世帯' : '0 世帯';

            // 自主避難者人数
            this.evaqueeActNumNode.innerHTML = totalEvaqueeActNum ? totalEvaqueeActNum + ' 人' : '0 人';

            // 自主避難世帯数
            this.evacHouseholdActNumNode.innerHTML = totalEvacHouseholdActNum ?
                totalEvacHouseholdActNum + ' 世帯' : '0 世帯';

        },

        setShelterInfo: function(){
            // 避難所情報を格納する配列
            var items = [];
            // 避難所情報をメモリーストアから取得して配列に格納
            this.shelterStore.fetch().forEach(function(object) {
                // 不要なプロパティーを削除
                delete object.id;
                // 配列に格納
                items.push(object);
            });
            // 新規の場合、訂正・取消理由欄は無効
            // formタグを使うとダイアログ上でグリッドのカラム名が表示されないため使用しない
            var distType = this.distributionTypeInput.get('value');
            this._complementaryInfo = this.complementaryInfo.get('value');
            return {
                disasterId        : this._disasterId,
                bodyText          : ' ',
                municipalityCd    : this.municipalityCd.get('value'),
                complementaryInfo : this.complementaryInfo.get('value'),
                sendCategory      : this.SHELTER_CONTENT_TYPE,
                sendTimestamp     : new Date(),
                distributionType  : distType,
                errataDescription : (this._isCorrectOrCancel && distType !== '01') ?
                    this.errataDescriptionInput.get('value') : null,
                abstEvaqueeNum       : this.totalEvacNum.totalEvaqueeNum,
                abstEvacHouseholdNum : this.totalEvacNum.totalEvacHouseholdNum,
                subject           : '開設避難所発信',
                lalertSendHistId  : this._lalertSendHistId,
                tLalertShelterSendHistList : items
            };
        },

        sendAndRegister: function() {
            // 訂正・取消の場合は「訂正・取消理由」が必須なので、チェックする。
            var distType = this.distributionTypeInput.get('value');
            if((this._isCorrectOrCancel && distType !== '01') &&
                (this.errataDescriptionInput.get('value') === null ||
                this.errataDescriptionInput.get('value').trim() === '')){

                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '訂正・取消理由が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            var value = {
                lalertForm: this.setShelterInfo(),
                twitterFlg: (this.twitterFlg.getValue() === 'on') ? true : false,
                yahooFlg: (this.yahooFlg.getValue() === 'on') ? true : false
            };
            this.emit('send', {
                value: value
            });
            return false;
        },

        closeDialog: function(){
            this._closeDialog();
        },

        checkEvacOrder: function(){
            var value = {
                lalertForm: this.setShelterInfo(),
                twitterFlg: (this.twitterFlg.getValue() === 'on') ? true : false,
                yahooFlg: (this.yahooFlg.getValue() === 'on') ? true : false
            };
            this.emit('hideshelter', {value: value});
            this._closeDialog();
        }
    });
});

/**
* 通知グループの基底モジュール。
* @module app/broadnotify/_BroadnotifyGroupPageBase
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'app/model/BroadnotifyTerminal',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup'
], function(module, declare, lang, Router, _PageBase, InfoDialog, BroadnotifyTerminal) {
    
    /**
     * 通知グループ新規登録・詳細画面。
     * @class _BroadnotifyGroupPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/broadnotify/_BroadnotifyGroupPageBase~_BroadnotifyGroupPageBase# */ {

        // ルート要素に付与されるCSS
        baseClass : 'idis-Page idis-Page--broadnotify-group',

        /**
         * データ格納用オブジェクト
         *
         * @type {module:dstore/Store}
         */
        store : null,

        /**
         * 入力されたデータの妥当性をチェックする。
         */
        validate: function() {
            var formData = this.form.get('value');
            // 条件名が入力されていない場合
            if (formData.groupName === '') {
                InfoDialog.show('入力エラー', 'グループ名が入力されていません。');
                return false;
            }

            // ユーザが選択されていない場合
            if (!this.store.data[0]) {
                InfoDialog.show('入力エラー', 'ユーザが選択されていません。');
                return false;
            }
            return true;
        },

        // パンくずリストのボタンを押下したときの挙動
        onBroadnotifyGroupListPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('broadnotify/group');
        },

        /**
         * 対象ユーザ選択ダイアログの初期化。
         */
        initSelectGroupDialog: function() {
            // thisは各画面を指す
            var page = this.selectGroupDialog.getChildren()[0];
            // ダイアログのregisterイベントをキャッチする
            page.on('register', lang.hitch(this, function(evt) {
                // gridのstore内に同じコードのユーザがあれば追加しない
                var sameFlg = true;
                var codes = [];
                if(evt.terminalId.length > 0) {
                    for(var i = 0; i < evt.terminalId.length; i++){
                        var terminalId = evt.terminalId[i];
                        if (!this.store.getSync(terminalId)) {
                            sameFlg = false;
                            codes.push(terminalId);
                        }
                    }
                    if(sameFlg){
                        this.chain.info('選択したユーザは既に追加されています。', 'エラー');
                        return;
                    }
                } else {
                    this.chain.info('ユーザが選択されていません。', 'エラー');
                    return;
                }
                // ツリーのデータを取得
                for (i = 0; i < codes.length; i++) {
                    BroadnotifyTerminal.get(codes[i]).then(lang.hitch(this, function(item) {
                        this.addDistGroupInfo({
                            id: '' + item.id,
                            name: item.name
                        });
                    }));
                }
                
                page.leave();
                // form情報のリセット
                page.form.reset();
            }));
        },

        /**
         * ユーザ追加ボタンが押下された際にユーザ選択ダイアログを表示する。
         */
        addGroupButtonClick: function() {
            this.selectGroupDialog.show();
        },

        /**
         * 対象ユーザgridの初期化。
         */
        initConvoAutoMailRegisterGrid: function() {
            // gridに設定する
            this.broadnotifyGroupTerminalsGrid.set('collection', this.store);
            // ボタンが押された際の処理を定義
            this.broadnotifyGroupTerminalsGrid.on('deleteButtonClick', lang.hitch(this, function(evt) {
                var cd = evt.item.terminalId;
                // 指定したコードのユーザをgridから削除する
                this.deleteDistGroupInfo(cd);
            }));
        },

        getGroupCdList: function() {
            var list = [];
            this.broadnotifyGroupTerminalsGrid.collection.data.forEach(lang.hitch(this, function(row) {
                list.push(row.terminalId);
            }));
            return list;
        },

        /**
         * @param item 追加対象のユーザの情報
         * ユーザをGridに追加する。
         */
        addDistGroupInfo: function(item) {
            // gridに追加
            this.store.addSync({
                terminalId: item.id,
                terminalName: item.name
            });
            // 削除した後のstoreをgridに設定
            this.broadnotifyGroupTerminalsGrid.set('collection', this.store);
            // 設定内容を反映するため、gridを更新
            this.broadnotifyGroupTerminalsGrid.refresh();
        },

        /**
         * @param cd ユーザコード
         * コードに合致したユーザをグリッド上から削除する。
         */
        deleteDistGroupInfo: function(cd) {
            var messege = 'ユーザを削除します。よろしいですか？<br>' + '※この処理ではデータベース上の情報は削除されません。';
            this.chain.confirm(messege, lang.hitch(this, function(chain) {
                // storeからユーザを削除
                this.store.remove(cd);
                // 削除した後のstoreをgridに設定
                this.broadnotifyGroupTerminalsGrid.set('collection', this.store);
                // 設定内容を反映するため、gridを更新
                this.broadnotifyGroupTerminalsGrid.refresh();
                // 確認ダイアログを閉じる
                chain.hide();
            }));
        }
    });
});

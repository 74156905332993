/**
 * 招集メール一覧グリッド。
 * @module app/convocation/view/mail/ConvoMailListGrid
 */
define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/on',
    'dojo/dom-construct',
    'idis/view/form/Button',
    'idis/view/grid/helper'
], function(module, dojo, declare, IdisGrid, on, domCon, Button, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
            { field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item) {
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                var content = null;

                // メール種別が「招集メール」または「通知メール」の場合
                if (item.convoMailType === '1' || item.convoMailType === '2') {
                    content = button.domNode;
                } else {
                    var comment = domCon.create('div');
                    comment.textContent = '-';
                    content = comment;
                }
                return content;
            }},
            helper.column('distributeTimeStamp', '送信日時'),
            helper.column('occuredCase', '配信種別', {
                formatMap: {
                    '01': '地震',
                    '02': '津波',
                    '03': '火山',
                    '04': '国民保護',
                    '06': '気象注警報',
                    '99': '手動配信'
                }
            }),
            helper.column('convoMailType', 'メール種別', {
                formatMap: {
                    '0': '一般メール',
                    '1': '招集メール',
                    '2': '通知メール'
                }
            }),
            helper.column('distributionTitle', '件名', {sortable: false}),
            helper.column('distributionBody', '本文', {sortable: false,
                formatter: function(value) {
                    var maxlength = 150;
                    return value.length >= maxlength ? value.slice(0, maxlength) + '...' : value;
                }
            }),
            {field: 'groupName', label: '送信先（グループ）', sortable: false,
                renderCell: function(item){
                    var span= dojo.create('span');
                    span.innerHTML = item.groupName;
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return span;
                }
            }
        ]
    });
});

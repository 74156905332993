/**
* 定時報告の基底モジュール。
* @module app/scheduledReport/_ScheduledReportPageBase
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/NumberTextBox',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/CustomizableMunicipalitySelector',
    './ScheduledReportDeptGrid',
    './ScheduledReportFormDialog',
    './ScheduledReportMuniGrid',
    './ScheduledReportRequestDialog'
], function (module, array, declare, lang, Router, UserInfo, DisasterInfo, DialogChain,
    _PageBase) {

    /**
     * 定時報告共通
     * @class _ScheduledReportPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/scheduledReport/_ScheduledReportPageBase~_ScheduledReportPageBase# */ {
            /**
             * 災害ID
             * @private
             */
            _disasterId: null,

            /**
             * constructor
             */
            constructor: function () {
                this.chain = DialogChain.get(this);

                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            /**
             * 定時報告一覧画面へ遷移する。
             */
            onScheduledReportPageLinkClick: function (evt) {
                evt.preventDefault();
                Router.moveTo('report/sche');
            },

            /**
             * 定時報告集計画面へ遷移する。
             */
            onScheduledReportPrefPageLinkClick: function (evt) {
                evt.preventDefault();
                Router.moveTo('report/sche/pref');
            }
        });
});
define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dojo/topic',
    'idis/map/IdisMap',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, topic, IdisMap, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        // 規制解除日時が入力されている場合
        rowClassName: function(item) {
            return item && item.regEndTimestamp ? 'is-regEnd' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('regionCd', '振興局', {
                formatter: function(value, item) {
                    return item.region.regionName + '';
                }
            }),
            helper.column('regReasonCd', '規制要因', {
                formatter: function(value) {
                    var REG_REASON = {
                        '01': '災害',
                        '02': '工事',
                        '03': '事前',
                        '99': 'その他'
                    };
                    return REG_REASON[value] || '';
                }
            }),
            helper.column('roadName', '路線名', {
                formatter: function(value, item) {
                    if (item.roadName) {
                        return item.roadName;
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('regStartPointName', '規制区間', {
                formatter: function(value, item) {
                    return (item.regStartPointName || '') + '<br>〜<br>' + (item.regEndPointName || '');
                }
            }),
            helper.column('regLength', '延長', {
                formatter: function(value, item) {
                    if (item.regLength && item.regLength !== '') {
                        return item.regLength + ' km';
                    } else {
                        return '';
                    }
                }
            }),
            helper.column('regContentCd', '規制内容', {
                formatter: function(value, item) {
                    switch (item.regContentCd) {
                        case '01':
                            return '全面通行止め';
                        case '02':
                            return '車両通行止め';
                        case '03':
                            return '片側交互通行止め';
                        case '04':
                            return 'チェーン規制';
                        case '99':
                            return 'その他';
                        default:
                            return '';
                    }
                }
            }),
            helper.column('regStartTimestamp', '規制日時', {
                formatter: function(value, item) {
                    var startDateStr = '';
                    if (item.regStartTimestamp) {
                        var startDate = new Date(item.regStartTimestamp);
                        switch (item.regStartTimestampCd) {
                            case '1': // 確定
                                startDateStr = locale.format(startDate) + '<br>（確定）';
                                break;
                            case '2': // 予定
                                startDateStr = locale.format(startDate) + '<br>（予定）';
                                break;
                            default:
                                startDateStr = locale.format(startDate);
                                break;
                        }
                    }
                    var planedEndDateStr = '';
                    switch (item.regPlanedEndCd) {
                        case '1': // 日時指定
                            if (item.regPlanedEndTimestamp) {
                                var plandEndDate = new Date(item.regPlanedEndTimestamp);
                                switch (item.regPlanedEndTimestampCd) {
                                    case '1': // 確定
                                        planedEndDateStr = locale.format(plandEndDate) + '<br>（確定）';
                                        break;
                                    case '2': // 予定
                                        planedEndDateStr = locale.format(plandEndDate) + '<br>（予定）';
                                        break;
                                    default:
                                        planedEndDateStr = locale.format(plandEndDate);
                                        break;
                                }
                            }
                            break;
                        case '2':
                            planedEndDateStr = '未定';
                            break;
                        case '3':
                            planedEndDateStr = '当面の間';
                            break;
                        case '9': // その他
                            planedEndDateStr = item.regPlanedEndOther;
                            break;
                        default:
                            planedEndDateStr = '';
                            break;
                    }
                    var endDateStr = '';
                    if (item.regEndTimestamp) {
                        var endDate = new Date(item.regEndTimestamp);
                        endDateStr = locale.format(endDate) + '<br>（規制解除済み）';
                    }
                    if (endDateStr) {
                        return startDateStr + '<br>〜<br>' + endDateStr;
                    } else {
                        return startDateStr + '<br>〜<br>' + planedEndDateStr;
                    }
                }
            }),
            helper.column('bypassFlg', '迂回路', {
                formatter: function(value, item) {
                    switch (item.bypassFlg) {
                        case '0':
                            return 'なし';
                        case '1':
                            return item.bypassComment;
                        case '2':
                            return '確認中';
                        default:
                            return '';
                    }
                }
            }),
            helper.column('regContentComment', '規制理由')

        ],

        // グリッド行がクリックされた場合に呼ばれる
        onRowClick: function(item) {
            // 規制解除されている場合は何もしない
            if (item.regEndTimestamp) {
                return;
            }
            // 緯度
            var lat;
            if (item.regStartPointLat) {
                if (item.regEndPointLat) {
                    // 起点も終点もある場合はその中心
                    lat = (item.regStartPointLat + item.regEndPointLat) / 2;
                } else {
                    // 起点しかない場合は起点
                    lat = item.regStartPointLat;
                }
            } else if (item.regEndPointLat) {
                // 終点しかない場合は終点
                lat = item.regEndPointLat;
            }
            // 経度
            var lng;
            if (item.regStartPointLng) {
                if (item.regEndPointLng) {
                    // 起点も終点もある場合はその中心
                    lng = (item.regStartPointLng + item.regEndPointLng) / 2;
                } else {
                    // 起点しかない場合は起点
                    lng = item.regStartPointLng;
                }
            } else if (item.regEndPointLng) {
                // 終点しかない場合は終点
                lng = item.regEndPointLng;
            }
            // 緯度も経度もある場合は地図を要素の指定する座標へ移動
            if (lat && lng) {
                topic.publish(IdisMap.TOPIC.MOVE, {
                    lat: lat,
                    lng: lng
                });
            }
            // ポップアップを表示
            topic.publish(IdisMap.TOPIC.OPEN_POPUP, {id: item.layerId});
        }
    });
});

/**
 * 観測局一覧画面用モジュール。
 * @module app/observationstation/ObservationStasionsPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ObservationStationsPage.html',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/store/IdisRest',
    './model/DataKind',
    // 以下、変数で受けないモジュール
    'app/view/form/RegionMuni4ObservatorySelector',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    './ObservationStationsPageGrid'
], function(module, declare, lang, template, _PageBase, Router, IdisRest, DataKind) {
    /**
     * 観測局一覧画面
     * @class stationsPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/observationstation/stationsPage~stationsPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--observationStation',

        // ストア
        store: null,

        //コンストラクタ
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'observatoryId',
                target: '/api/observationStation'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
        },

        /**
         * グリッドを初期化する。
         * @function module:app/view/page/stationsPage~stationsPage#~startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initGrid();
        },

        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
        	// 検索条件を持たない状態で初期値を取得
            this.onSubmit();
        },

        //詳細ボタンを押下したときの挙動
        onDetailButtonClick: function(object) {
            // 種別に応じた観測画面へ遷移
            switch (object.dataKind) {
                case DataKind.RAINFALL:
                    // 雨量情報詳細画面へ遷移
                    Router.moveTo('observation/rainfall/detail', {
                        observatoryId : object.observatoryId
                    });
                    break;
                case DataKind.RIVER_LEVEL:
                case DataKind.CRISIS_MANAGEMENT_RIVER_LEVEL:
                    // 水位情報状況詳細画面へ遷移
                    Router.moveTo('observation/river/detail', {
                        observatoryId : object.observatoryId
                    });
                    break;
                case DataKind.TIDE_LEVEL:
                    // 潮位情報状況詳細画面へ遷移
                    Router.moveTo('observation/tide/detail', {
                        observatoryId : object.observatoryId
                    });
                    break;
                case DataKind.RIVER_BASIN:
                case DataKind.DAM:
                    // ダム諸量情報詳細画面へ遷移
                    Router.moveTo('observation/dam/detail', {
                        observatoryId : object.observatoryId
                    });
                    break;
                case DataKind.LOCAL:
                    // 市内気象情報詳細画面へ遷移
                    Router.moveTo('observation/local/detail', {
                        observatoryId : object.observatoryId
                    });
                    break;
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // データ種別
            if (value.dataKind) {
                filter = filter.eq('dataKind', value.dataKind);
            }
            // 地域/市町村セレクタ
            switch (this.areaSelector.getAreaType()) {
                case 'REGION':          //地域を選択
                    filter = filter.eq('regionCd', value.areaCd);
                    break;
                case 'MUNICIPALITY':    // 市町村を選択
                    filter = filter.eq('municipalityCd', value.areaCd);
                    break;
            }
            // 管理区分
            if (value.managerCd) {
                filter = filter.eq('managerCd', value.managerCd);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        }
    });
});

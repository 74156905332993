/**
 * 利用者登録用ダイアログモジュール。
 * @module app/user/UserRegisterDialog
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/dom-style',
	'dojo/text!./templates/UserRegisterDialog.html',
	'idis/view/Loader',
	'idis/service/Requester',
	'app/config',
	'app/common/consts/FunctionCd',
	'idis/consts/ACL',
	'./_UserDialogBase',
	'idis/model/UserInfo',
	// 以下、変数で受けないモジュール
	'app/view/form/CustomizableMunicipalitySelector',
	'app/view/form/RegionSelector',
	'app/view/form/MunicOrganizationSelector',
	'idis/view/form/WordCountTextarea'
], function (module, declare, lang, domStyle, template, Loader,
	Requester, config, FunctionCd, ACL, _UserDialogBase, UserInfo) {

	return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
		{
			// テンプレート文字列
			templateString: template,

			/**
			 * buildRendering
			 */
			buildRendering: function () {
				this.inherited(arguments);
				this.initSelectBox();
				// 市町セレクタを「県なし」にセット
				this.municipalityCd.noPrefecture();
			},

			/**
			 * 利用者を新規登録する。
			 */
			onSubmit: function () {
				try {

					// フォームのバリデーションを実施
					if (!this.form.validate()) {
						return false;
					}

					// 固有のバリデーションを実施
					if (!this.validate()) {
						return false;
					}

					var value = this.form.get('value');
					value.newUserId = value.userId;
					delete value.userId;
					delete value.passwordConfirm;


					this.emit('register', { value: value });
				} catch (e) {
					console.error(e);
				}
				return false;
			},

			/**
			 * ダイアログを初期化する。（新規登録時）
			 */
			initDialog: function () {
				this.reset();
				this.organizationSelector._setReadOnlyAttr(true);
				this.initSelectBox();
				this.organizationSelector.restoreRefresh();

				// 物資調達の機能権限がない場合は物資ユーザ・パスワードの領域を非表示
				if (!UserInfo.hasAuthz(FunctionCd.BUSSHI)) {
					domStyle.set(this.supplyUserArea, 'display', 'none');
				}
			},

			// 役割セレクトボックスの初期化を行う
			initSelectBox: function () {
				var promise = Requester.get('/api/roles');
				Loader.wait(promise).then(lang.hitch(this, function (data) {
					// セレクトボックスに設定するデータの配列を作成
					var newOptions = [];
					// サーバーレスポンスの各要素をselectへ追加
					if (UserInfo.getRoleCd() === ACL.ADMIN_USER){
						newOptions = [{
							label: '--選択してください--',
							value: ''
						}];
						data.items.forEach(function (object) {
							newOptions.push({
								label: object.roleName,
								value: object.roleCd
							});
						});
					} else {
						// システム管理者以外の場合は、操作ユーザと同じuser_typeを選択肢にセット
						data.items.forEach(function (object) {
							if(object.userType === UserInfo.getUserType() &&
								object.roleCd !== ACL.ADMIN_USER){
								newOptions.push({
									label: object.roleName,
									value: object.roleCd
								});
							}
						});
					}
					this.roleCdSelect.set('options', newOptions);
					if(UserInfo.getRoleCd() !== ACL.ADMIN_USER){
						this.roleCdSelect.set('value', UserInfo.getRoleCd());
						this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
						this.regionCd.set('value', UserInfo.getRegionCd());
						this.municipalityCd._setReadOnlyAttr(true);
						this.regionCd._setReadOnlyAttr(true);
						this.organizationSelector._setReadOnlyAttr(false);
					}
				}));
			},

			// 役割のセレクトボックスの値が変更された場合の処理
			onRoleCdSelectChanged: function (roleCd) {
				this.organizationSelector.restoreRefresh();
				this.municipalityCd.set('value', '');
				this.regionCd.set('value', '');
				this.organizationSelector.set('value', '');
				this._roleCd = roleCd;
				if (roleCd.match(/^R01[0-9]*/)) {
					this.municipalityCd.set('value', config.municInfo.prefMunicCd);
					domStyle.set(this.municipalityCdBlock, 'display', 'none');
					domStyle.set(this.regionCdBlock, 'display', 'none');
					this.organizationSelector._setReadOnlyAttr(false);
				}
				else if (roleCd.match(/^R02[0-9]*/)) {
					domStyle.set(this.municipalityCdBlock, 'display', 'none');
					domStyle.set(this.regionCdBlock, 'display', '');
					this.organizationSelector._setReadOnlyAttr(true);
				}
				else if (roleCd.match(/^R03[0-9]*/)) {
					domStyle.set(this.municipalityCdBlock, 'display', '');
					domStyle.set(this.regionCdBlock, 'display', 'none');
					this.organizationSelector._setReadOnlyAttr(true);
				}
				else if (roleCd.match(/^R04[0-9]*/)) {
					// （仮）関係機関のmunicipalityCdを設定
					this.municipalityCd.set('value', config.municInfo.prefMunicCd);
					domStyle.set(this.municipalityCdBlock, 'display', 'none');
					domStyle.set(this.regionCdBlock, 'display', 'none');
					this.organizationSelector._setReadOnlyAttr(false);
				}
				if(UserInfo.getRoleCd() !== ACL.ADMIN_USER){
					this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
					this.regionCd.set('value', UserInfo.getRegionCd());
					this.municipalityCd._setReadOnlyAttr(true);
					this.regionCd._setReadOnlyAttr(true);
					this.organizationSelector._setReadOnlyAttr(false);
				}
			},

			// 市町村セレクタの値が変更するときの動作
			onMunicipalityCdChanged: function (municipalityCd){
				this.organizationSelector.set('value', '');
				this.organizationSelector._setReadOnlyAttr(false);
				this.organizationSelector.setMunicipalityCd(municipalityCd.value);
			},
			// 振興局セレクタの値が変更するときの動作
			onRegionCdChanged: function(regionCd) {
				this.organizationSelector.set('value', '');
				this.organizationSelector._setReadOnlyAttr(false);
				this.organizationSelector.setRegionCd(regionCd.value);
			}
		});
});

/**
 * SIP4D-CKAN情報新規登録モジュール
 * @module app/sip4dCkan/Sip4dCkanRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/Sip4dCkanRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/TitlePane',
    '../view/form/LayerDirectorySelector'
], function(module, declare, template, InfoDialog, _PageBase) {
    /**
     * SIP4D-CKAN情報新規登録
     * @class Sip4dCkanRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sip4dCkan/Sip4dCkanRegisterDialog~Sip4dCkanRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        startup: function() {
        },

        /**
         * データ格納領域を初期化し、データを設定する
         */
        setPage: function(evt){
            this.name.set('value', evt.name);
            this.url.set('value', evt.url);
        },

        /**
         * 登録を実行する
         */
        regist: function() {
            // formに定義されている情報を設定する
            var sendData = this.form.get('value');
            console.debug(sendData);
            try {
                if (this.form.validate() && this.isGeofileRegisterValid(sendData)) {
                    this.emit('send', {
                        value: sendData
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 入力値をチェックする
         */
        isGeofileRegisterValid: function(data) {
            if (!data.parentLayerId || data.parentLayerId === '') {
                InfoDialog.show('入力エラー', '格納先を選択してください。');
                return false;
            }
            return true;
        }
    });
});

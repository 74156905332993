/**
 * 招集グループ新規登録画面用モジュール。
 * @module app/convocation/view/group/ConvoGroupRegisterPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoGroupRegisterPage.html', // テンプレート文字列
    'app/convocation/view/group/_ConvoGroupPageBase', // 共通基底クラス
    'idis/view/dialog/DialogChain',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'app/view/form/EmployeeDivisionSelector',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, template, _PageBase, DialogChain, Router, Requester, Loader) {
    /**
     * 招集グループ新規登録画面。
     * @class ConvoGroupRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoGroupRegisterPage~ConvoGroupRegisterPage# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
            },

            /**
             * 登録ボタンが押下された際の挙動。
             */
            onSubmit:function(){
                var formData = this.form.value;
                // formの入力チェック
                if (!this.form.validate()) {
                    return false;
                }
                // 招集対象職員の職員番号の配列
                var employees = [];
                // gridの行数分処理する
                this.store.fetch().forEach(function(item) {
                    // 職員番号のみサーバー側に渡す
                    employees.push(item.employeeCd);
                });
                // requestに追加
                formData.employeeCds = employees;

                //登録確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    var promise = Requester.post('/api/convoMails/groups', {data: formData})
                    .then(function() {
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('convocation/group');
                        });
                    }, function(error) {
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
                return false;
            }
        });
});

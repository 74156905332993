/**
 * 緊急情報パネル
 * @module app/emergency/EmergencyPanel
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/EmergencyPanel.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, declare, lang, domStyle, JSON, template, Router, UserInfo, Requester,
        DialogChain, Loader, _PageBase) {
    /**
     * 緊急情報情報パネル
     * @class EmergencyPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/emergency/EmergencyPanel~EmergencyPanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 緊急ニュース管理画面を初期化
            this.initPanel();
        },

        /**
         * 緊急ニュース情報パネルを初期化します
         */
        initPanel: function() {
            var self = this;

            // 一覧画面の表示権限がない場合はリンクを非表示
            if(!UserInfo.hasAuthz('F06002')) {
                domStyle.set(this.emergencyListLink, 'display', 'none');
            }

            var promise = Requester.get('/api/emergencyNews/latest/', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                console.debug('緊急ニュース情報：' +
                    JSON.stringify(data));

                var message = '';
                if(data) {
                    message = data.subject;
                } else {
                    message = '現在、緊急ニュースはありません';
                }

                if(self.emergencyNews) {
                    // 画面遷移してない場合のみ注入
                    self.emergencyNews.innerHTML = message;
                }
            }, function(error) {
                console.log(error);
                self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        onEmergencyAlertLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // ポータル公開情報一覧へ遷移
            Router.moveTo('emergency');
        }
    });
});

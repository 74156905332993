/**
 * 避難状況・報告概況画面用モジュール。
 * @module app/view/page/EvacOrderAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'dojo/text!./templates/SimulationEventListPage.html',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'dojo/topic',
    'idis/view/dialog/DialogChain',
    // 'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/simulation/SimulationEventListGrid',
    'app/simulation/SimulationEventRegisterDialog',
    'app/simulation/SimulationEventDetailDialog',
    'app/view/form/DisasterChangerDialog',
    'idis/view/form/WordCountTextarea'
], function (module, declare, lang, domStyle, json, Router, Locator, IdisRest, Requester,
    InfoDialog, template, _PageBase, Loader, topic, DialogChain) {
    /**
     * 災害シミュレーションイベント一覧画面
     * @class SimulationEventListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/SimulationEventListPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--simulation',

            store: null,

            scenarioStore: null,

            _disasterId: null,

            _scenarioId: null,

            _simulationId: null,

            _disasterName: null,

            _comment: null,

            constructor: function () {
                this.chain = DialogChain.get(this);
                // URLからシナリオIDを取得
                this._scenarioId = Locator.getQuery().scenarioId;
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.initEventGrid();
                this.initEventDetailDialog();
            },

            startup: function () {
                this.inherited(arguments);
                this.initListPage();
                // 災害名選択
                this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                    lang.hitch(this, function () {
                        this.disasterName.set('value', '');
                    })));
            },

            initListPage: function () {
                Requester.get('/api/simulations/' + this._scenarioId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (data) {
                    this.form.set('value', data);
                    // 再生中のシミュレーションがある場合、削除ボタンを非活性にする
                    if (data.status === '1') {
                        this.playButton.set('disabled', true);
                    }
                }));
            },

            initEventGrid: function () {
                this.updGrid();
                // グリッドの詳細ボタンクリック時の動作を設定する
                this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.showDetailDialog(evt.item);
                }));
                // グリッドの削除ボタンクリック時の動作を設定する
                this.grid.on('deleteButtonClick', lang.hitch(this, function (evt) {
                    this.deleteEvent(evt.item);
                }));
            },

            updGrid: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'scenarioId',
                    target: '/api/simulations/events/' + this._scenarioId
                });
                this.grid.set('collection', this.store.filter());
            },

            initEventDetailDialog: function () {
                var dialog = this.eventDetailDialog;
                var page = dialog.getChildren()[0];
                var self = this;
                // イベント詳細ダイアログからのupdateイベントを取得
                page.on('update', lang.hitch(this, function (env) {
                    self.updEventTiming(env);
                }));
            },

            /**
             * シナリオ再生
             */
            onPlayButtonClick: function () {
                if (this.grid._rows.length < 1) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: 'イベントが登録されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                if (this.disasterName.innerHTML === '未選択' ||
                    this.disasterName.innerHTML === '') {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '災害が設定されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                if (!this.startTimestamp || this.startTimestamp === '') {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '発生日時が設定されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                var currentTime = new Date();
                var startDate = this.startTimestamp._date.displayedValue.split('/');
                var startTime = this.startTimestamp._time.displayedValue.split(':');
                var startTimestamp = new Date(startDate[0], startDate[1] - 1, startDate[2], startTime[0], startTime[1]);
                if (startTimestamp <= currentTime) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '現在より後の日時を指定してください'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }

                // シミュレーション再生履歴の登録
                var jsonStr = json.stringify({
                    scenarioId: this._scenarioId,
                    startTimestamp: this.playForm.get('value').startTimestamp,
                    disasterId: this._disasterId
                });
                var message = '再生します。<br>' +
                    '以後、シナリオへのイベントの追加・削除を行なっても、<br>' +
                    '再生中のシミュレーション内容には反映されません。<br>再生しますか？';
                //確認ダイアログを表示
                return this.chain.confirm(message, function (chain) {
                    var promise = Requester.post('/api/simulations/start', {
                        data: jsonStr,
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function (data) {
                        this._simulationId = data[0].simulationId;
                        chain.infoComplete(function () {
                            this.chain.hide();
                            // 一覧画面に移動
                            Router.moveTo('simulation/history', {
                                simulationId: this._simulationId
                            });
                        });
                    }), lang.hitch(this, function (error) {
                        chain.infoError(error);
                        this.confirmDialog.hide();
                    }));
                    // ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * シナリオ更新
             */
            onScenarioUpdButtonClick: function () {
                var sendData = this.form.get('value');

                //更新確認ダイアログを表示
                this.chain.confirmPut(function (chain) {
                    var jsonStr = json.stringify(sendData);
                    var promise = Requester.put('/api/simulations/' + this._scenarioId, {
                        data: jsonStr,
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function () {
                        chain.infoComplete(lang.hitch(this, function () {
                            this.chain.hide();
                            console.debug('更新が完了しました。');
                        }));
                    }, function (error) {
                        chain.infoError(error);
                    }));
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * シナリオ削除
             */
            onScenarioDelButtonClick: function () {
                var uri = '/api/simulations/' + this._scenarioId;

                //削除確認ダイアログの表示
                this.chain.confirmDel(lang.hitch(this, function (chain) {
                    var promise = Requester.del(uri)
                        .then(lang.hitch(this, function () {
                            chain.infoComplete(lang.hitch(this, function () {
                                this.chain.hide();
                                console.debug('削除が完了しました。');
                                Router.moveTo('simulation');
                            }));
                        }), lang.hitch(this, function (error) {
                            console.log(error);
                            chain.infoError(error);
                        }));
                    //ローダーの表示
                    Loader.wait(promise);
                    this.updGrid();
                }));
                console.debug('[削除]ボタンがクリックされました。');
            },

            /**
             * イベント情報を更新する。
             */
            updEventTiming: function (env) {
                //登録確認ダイアログを表示
                this.chain.confirmPut(function (chain) {
                    var jsonStr = json.stringify({
                        timing: env.timing,
                        content: env.content
                    });
                    var promise = Requester.put('/api/simulations/event/' + env.eventId, {
                        data: jsonStr,
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function () {
                        chain.infoComplete(function () {
                            chain.hide();
                            this.updateGridQuery();
                            console.debug('更新が完了しました。');
                        });
                    }, function (error) {
                        chain.infoError(error);
                    }));
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * イベントを削除する。
             */
            deleteEvent: function (deleteTarget) {
                var uri = '/api/simulations/event/' + deleteTarget.eventId;

                //削除確認ダイアログの表示
                this.chain.confirmDel(lang.hitch(this, function (chain) {
                    var promise = Requester.del(uri)
                        .then(lang.hitch(this, function () {
                            chain.infoComplete(lang.hitch(this, function () {
                                this.chain.hide();
                                this.updateGridQuery();
                                console.debug('削除が完了しました。');
                            }));
                        }), lang.hitch(this, function (error) {
                            console.log(error);
                            chain.infoError(error);
                        }));
                    //ローダーの表示
                    Loader.wait(promise);
                }));
                console.debug('[削除]ボタンがクリックされました。');
            },

            showEventRegisterDialog: function () {
                var page = this.eventRegisterDialog.getChildren()[0];
                page.initPage(this._scenarioId);
                this.eventRegisterDialog.show();
            },

            showDetailDialog: function (item) {
                var page = this.eventDetailDialog.getChildren()[0];
                page.initDetailPage(item);
                this.eventDetailDialog.show();
            },

            /**
             * 災害変更ダイアログを表示する
             */
            showDisasterDialog: function () {
                // ダイアログを表示
                this.dialog.show();
                // ダイアログ中のページウィジェットを取得
                var page = this.dialog.getChildren()[0];

                // ダイアログ側でOKボタンが押された際のイベント
                page.on('update', lang.hitch(this, function (evt) {
                    this.disasterName.innerHTML = evt.data.disasterName;
                    this._disasterId = evt.data.disasterId;
                    // ダイアログを非表示にする
                    this.dialog.hide();
                }));
                // ダイアログの画面を再表示する
                page.refresh();
            },

            /**
             * イベント一覧画面へ遷移する。
             */
            onScenarioPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('simulation');
            },

            /**
             * 検索パラメーターの設定
             */
            updateGridQuery: function () {
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', collection);

            }
        });
});

/**
 * 招集メール配信ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoAutoTempInsertDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoAutoTempInsertDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button',
    'app/convocation/view/mail/ConvoSendAutoMailGrid'
], function(module, declare, template, _PageBase) {
    /**
     * 招集メール配信ダイアログ。
     * @class 招集メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,{
        /** @lends module:idis/view/page/ConvoSendAutoMailDialog~ConvoSendAutoMailDialog# */
        // テンプレート文字列
        templateString: template
    });
});

/**
 * 避難発令判断支ルール一覧画面
 * @module app/evacrecommendRule/EvacRecommendRulePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/EvacRecommendRulePage.html',
    'idis/control/Router',
    './_EvacRecommendRulePageBase',
    // 以下、変数で受けないモジュール
    './EvacRecommendRuleGrid'
], function (module, declare, lang, template, Router, _EvacRecommendRulePageBase) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     * @private
     */
    var _filterData = {};

    /**
     * 避難発令判断支ルール一覧画面
     * @class evacrecommendRule
     * @extends module:app/evacrecommendRule/_EvacRecommendRulePageBase~_EvacRecommendRulePageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacRecommendRulePageBase,
    /** @lends module:app/evacrecommendRule/EvacRecommendRulePage~EvacRecommendRulePage# */ {
            // テンプレート文字列
            templateString: template,

            startup: function () {
                this.inherited(arguments);
                // セレクタ初期化
                this.initIssueReasonTypeSelector(false);
                this.initEvacOrderTypeSelector(false);
                // グリッドを初期表示する。
                this.initGrid();
            },

            /**
             * グリッドを初期化する
             */
            initGrid: function () {
                // 検索結果が保存されている場合は、検索結果をFormにセットする。
                this.applyFilterData();

                // 詳細ボタンをクリックした際のイベントをセットする
                this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                // グリッドを更新する。
                this.updateGridQuery();
            },

            /**
             * グリッドの検索条件を更新する。
             */
            updateGridQuery: function () {
                var value = this.form.get('value');
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                if (value.municipalityCd && !this.municipalityCd.isPrefectureSelected()) {
                    filter = filter.eq(this.municipalityCd.isRegionSelected() ? 'regionCd' : 'municipalityCd',
                        value.municipalityCd);
                }
                if (value.evacOrderType) {
                    filter = filter.eq('evacOrderType', value.evacOrderType);
                }
                if (value.issueReasonType) {
                    filter = filter.eq('issueReasonType', value.issueReasonType);
                }

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', this.store.filter(filter));
            },

            /**
             * 検索条件を保管する。
             */
            saveFilterData: function () {
                _filterData = this.form.get('value') || {};
            },

            /**
             * 保管されている検索条件をフォームにセットする。
             */
            applyFilterData: function () {
                this.form.set('value', _filterData);
            },

            /**
             * 詳細画面に遷移
             */
            onDetailButtonClick: function (object) {
                // 検索条件を保管する。
                this.saveFilterData();

                // 詳細画面へ遷移
                Router.moveTo('evacrecommend/rule/detail', {
                    ruleSetId: object.ruleSetId
                });
            },

            /**
             * 登録画面に遷移
             */
            onRegisterButtonClick: function () {
                // 検索条件を保管する。
                this.saveFilterData();

                // 登録画面へ遷移
                Router.moveTo('evacrecommend/rule/register', {
                    municipalityCd: this.municipalityCd.get('value')
                });
            }
        });
});

/**
 * 利用者管理画面用モジュール。
 * @module app/broadnotify/TerminalListPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/TerminalListPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/view/grid/IdisGrid',
    'idis/view/form/AclButton',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'app/view/form/UserOrganizationSelector',
    './TerminalListGrid',
    './TerminalRegisterDialog',
    './TerminalDetailDialog'
], function(module, declare, lang, json, template, _PageBase, dom, registry,
    Dialog, Memory, Rest, ACL, IdisGrid, AclButton, IdisRest, Loader, DialogChain) {
    /**
     * 利用者管理画面
     * @class TerminalListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/broadnotify/TerminalListPage~TerminalListPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotify',

        /**
         * ストア
         */
        store: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'terminalId',
                target: '/api/broadnotify/terminal',
                sortParam: 'sort'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerTerminalRegisterDialog);
            this.own(this.innerTerminalDetailDialog);
            this.organizationSelector.restoreRefresh();
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this.initPage();
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.terminalListGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showUpdateDialog(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * 利用者管理画面を初期化する。
         */
        initPage: function() {

            // 新規登録ダイアログを初期化
            this.initRegisterDialog();

            // 詳細ダイアログを初期化
            this.initDetailDialog();

        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            var dialog = this.terminalRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        if(err.response.data) {
                            var jsonData = json.parse(err.response.data);
                            // ユーザIDが重複していた場合はDBエラー（E2001）
                            if(jsonData.code && jsonData.code === 'E2001') {
                                chain.info('すでに登録されているユーザを登録しようとしています。', 'エラー');
                                return;
                            }
                        }

                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailDialog: function() {
            var dialog = this.terminalDetailDialog;
            var page = dialog.getChildren()[0];

            // 詳細画面のremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                // 削除確認ダイアログの表示
                var messege = '削除します。よろしいですか？<br>' + '対象ユーザが0件となった配信テンプレートは合わせて削除されます。';
                this.chain.confirm(messege, function(chain) {
                    var promise = this.store.remove(evt.value.terminalId);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細画面のupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    // 追加処理と共にローディング表示
                    var promise = this.store.put(form);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        if(err.response.data) {
                            var jsonData = json.parse(err.response.data);
                            // ユーザIDが重複していた場合はDBエラー（E2001）
                            if(jsonData.code && jsonData.code === 'E2001') {
                                chain.info('すでに登録されているユーザに更新しようとしています。', 'エラー');
                                return;
                            }
                        }
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 利用者を検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 利用者新規登録ダイアログを表示する。
         */
        showRegisterDialog: function() {
            console.debug('[新規登録]ボタンがクリックされました。');
            this.innerTerminalRegisterDialog.initDialog();
            this.terminalRegisterDialog.show();
        },

        /**
         * 利用者詳細ダイアログを表示する。
         */
        showUpdateDialog: function(object) {
            console.debug('[詳細]ボタンがクリックされました。');
            this.innerTerminalDetailDialog.initDialog(object);
            this.terminalDetailDialog.show();
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 組織による検索
            if (value.organization && value.organization !== '') {
                filter = filter.eq('organization', value.organization);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.terminalListGrid.set('collection', collection);
        }
    });
});

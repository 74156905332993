/**
 * 配信ポータル画面用モジュール。
 * @module app/sending/SendingPage
 */
define([
    'module',
    '../config',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/SendingPage.html',
    'dstore/Memory',
    'dstore/Rest',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/consts/ACL',
    'idis/view/dialog/InfoDialog',
    'idis/view/form/AclButton',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'app/sending/_SendingPageBase',
    'app/model/Region',
    // 以下、変数として受け取らないモジュール
    'dijit/form/CheckBox',
    'idis/view/form/DateTimeInput',
    'app/sending/SendingGrid',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, config, declare, lang, domClass, domStyle, template, Memory, Rest, registry, IdisGrid,
    Locator, Router, Requester, IdisRest, UserInfo, USER_TYPE, ACL, InfoDialog, AclButton, Loader,
    DialogChain, _SendingPageBase, Region) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var SendingFilterStore = null;

    /**
     * 配信情報画面。
     * @class SendingPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _SendingPageBase,
        /** @lends module:app/sending/SendingPage~SendingPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        // メール管理システムへのリンクを表示する権限（他個所で使わないためハードコード）
        MAIL_SYSTEM_AUTH: ['R01001', 'R01011'] ,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        // [template内のcheckBox, サーバで受ける検索条件の変数名, サーバでのtrue時の値, サーバでのfalse時の値]
        _searchCriteriaCheckBox: [],

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'sendingId',
                target: '/api/sending',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);

            // [template内のcheckBox, サーバで受ける検索条件の変数名, サーバでのtrue時の値, サーバでのfalse時の値]
            this._searchCriteriaCheckBox = [
                {obj:this.emergencyMail,  param:'emergencyMail',  t:'true', f:'false'}, // 緊急速報メール
                {obj:this.lalert,         param:'lalert',         t:'true', f:'false'}, // Lアラート
                {obj:this.prefectureMail, param:'prefectureMail', t:'true', f:'false'}, // 県防災速報メール
                {obj:this.twitter,        param:'twitter',        t:'true', f:'false'}, // Twitter
                {obj:this.yahoo,          param:'yahoo',          t:'true', f:'false'}, // Yahoo防災速報
                {obj:this.notice,         param:'notice',         t:'10',   f:null},    // お知らせ
                {obj:this.evac,           param:'evac',           t:'20',   f:null},    // 避難情報
                {obj:this.shelter,        param:'shelter',        t:'21',   f:null},    // 避難所情報
                {obj:this.headquarter,    param:'headquarter',    t:'2',   f:null},    // 本部設置情報
                {obj:this.success,        param:'success',        t:'true', f:'false'}, // 配信完了
                {obj:this.wait,           param:'pending',        t:'true', f:'false'}, // 配信待ち
                {obj:this.fail,           param:'fail',           t:'true', f:'false'}  // 失敗・一部失敗
            ];

            // 県ユーザの市町初期表示を未選択にする
            if (UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                this.municipalityCd.defaultUnselected = true;
            }

        },

        startup: function() {
            this.inherited(arguments);
            this._loadFilterStore();
            this._initGrid();
            this._setButtonStatus();
        },

        /**
         * グリッドを初期化する。
         */
        _initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.sendingGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            this._updateGridQuery();
        },

        /**
         * 新規登録ボタンの活性/非活性を初期化する
         */
        _setButtonStatus: function() {
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.RegisterButton.set('disabled', true);
            }
        },

        /**
         * 検索
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this._updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 検索パラメーターの設定
         */
        _updateGridQuery: function() {
            var filter = new this.store.Filter();

            // 入力チェック　一ヶ月以上の期間を指定して検索できない
            if(this.sendTimestampFrom || this.sendTimestampTo){
                // 検索期間Fromについて、入力がなければをリリース日を指定する。
                var durationFrom = this.sendTimestampFrom.get('value') ?
                    this.sendTimestampFrom.get('value') : new Date(2019, 10, 28) ;
                // 検索期間Toについて、入力がなければ現在日時を指定する。
                var durationTo = this.sendTimestampTo.get('value') ?
                    this.sendTimestampTo.get('value') : new Date();

                if(durationTo.getTime() - durationFrom.getTime() > 31 * 24 * 60 * 60 * 1000) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '一ヶ月以上の期間を指定した検索はできません。一ヶ月以内の期間を検索してください。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog=null;
                    return false;
                }
            }


            // 検索条件をセット
            if (this._isSearch()) {
                for (var i = 0; i < this._searchCriteriaCheckBox.length; i++) {
                    if (this._searchCriteriaCheckBox[i].obj.checked) {
                        filter = filter.eq(this._searchCriteriaCheckBox[i].param, this._searchCriteriaCheckBox[i].t);
                    } else {
                        if (this._searchCriteriaCheckBox[i].f !== null) {
                            filter = filter.eq(this._searchCriteriaCheckBox[i].param,
                                    this._searchCriteriaCheckBox[i].f);
                        }
                    }
                }

                // もしも検索に緊急速報メールが含まれる場合、urgentMail=trueをつける
                if (this.emergencyMail.checked) {
                    filter = filter.eq('urgentMail', true);
                } else {
                    filter = filter.eq('urgentMail', false);
                }

                var value = this.form.get('value');
                var searchCriteriaTimestamp = [['sendingTimestampFrom', value.sendTimestampFrom],
                                              ['sendingTimestampTo', value.sendTimestampTo]];
                for (i = 0; i < searchCriteriaTimestamp.length; i++) {
                    if (searchCriteriaTimestamp[i][1]) {
                        var timestamp = new Date(searchCriteriaTimestamp[i][1]).getTime();
                        filter = filter.eq(searchCriteriaTimestamp[i][0], timestamp);
                    } else {
                        filter = filter.eq(searchCriteriaTimestamp[i][0], 0);
                    }
                }

                var munic = value.municipalityCd;
                this.isRegion(munic).then(lang.hitch(this, function(isRegion){
                    if(isRegion){
                        filter = filter.eq('regionCd', munic);
                    } else if(munic && munic !== config.municInfo.prefCd){
                        filter = filter.eq('municipalityCd', munic);
                    }
                    var collection = this.store.filter(filter);
                    this.sendingGrid.set('collection', collection);
                }));
            }
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
			}));
        },


        /**
         * 検索条件が一つでも設定されていればtrue
         */
        _isSearch: function() {
            for (var i = 0; i < this._searchCriteriaCheckBox.length; i++) {
                if (this._searchCriteriaCheckBox[i].obj.checked) {
                    return true;
                }
            }

            var value = this.form.get('value');
            var timestamp = [value.sendTimestampFrom,
                             value.sendTimestampTo];
            for (i = 0; i < timestamp.length; i++) {
                if (timestamp[i]) {
                    return true;
                }
            }
            return false;
        },

        /**
         * 検索条件を保管する
         */
        saveFilterStore: function() {
            var value = this.form.get('value');
            SendingFilterStore = {
                emergencyMail:value.emergencyMail,
                lalert:value.lalert,
                prefectureMail:value.prefectureMail,
                twitter:value.twitter,
                yahoo:value.yahoo,
                notice:value.notice,
                evac:value.evac,
                shelter:value.shelter,
                headquarter:value.headquarter,
                success:value.success,
                wait:value.wait,
                fail:value.fail,
                sendTimestampFrom:value.sendTimestampFrom,
                municipalityCd:value.municipalityCd,
                regionCd:value.regionCd
            };
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        _loadFilterStore: function() {
            //保管されていれば値をセット
            if (SendingFilterStore) {
                this.emergencyMail.set('value', SendingFilterStore.emergencyMail);
                this.lalert.set('value', SendingFilterStore.lalert);
                this.prefectureMail.set('value', SendingFilterStore.prefectureMail);
                this.twitter.set('value', SendingFilterStore.twitter);
                this.yahoo.set('value', SendingFilterStore.yahoo);
                this.notice.set('value', SendingFilterStore.notice);
                this.evac.set('value', SendingFilterStore.evac);
                this.shelter.set('value', SendingFilterStore.shelter);
                this.headquarter.set('value', SendingFilterStore.headquarter);
                this.success.set('value', SendingFilterStore.success);
                this.wait.set('value', SendingFilterStore.wait);
                this.fail.set('value', SendingFilterStore.fail);
                this.sendTimestampFrom.set('value', SendingFilterStore.sendTimestampFrom);
                this.municipalityCd.set('value', SendingFilterStore.municipalityCd);
                this.regionCd.set('value', SendingFilterStore.regionCd);

                this.form.set('value', SendingFilterStore);
            }
            // 検索条件として保管されていなければ、配信日時Fromを初期化
            if (!(SendingFilterStore && SendingFilterStore.sendTimestampFrom)) {
                // 昨日の0時以降を検索対象とする
                var date = new Date();
                date.setDate(date.getDate());
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                this.sendTimestampFrom.set('value', date);
            }
            // 検索条件として保管されていなければ、配信日時Toを初期化
            if (!(SendingFilterStore && SendingFilterStore.sendTimestampTo)) {
                // 現在時刻以前を検索対象とする
                var currentDate = new Date();
                this.sendTimestampTo.set('value', currentDate);
            }
            SendingFilterStore = null;
        },

        /**
         * 新規登録画面を表示
         */
        showRegisterPage: function() {
            //検索条件の保管
            this.saveFilterStore();
            //新規画面に移動
            Router.moveTo('sending/register',{emergency:'0'});
        },

        /**
         * 緊急速報メール新規登録画面を表示
         */
        showEmergencyPage: function() {
            //検索条件の保管
            this.saveFilterStore();
            //新規画面に移動
            Router.moveTo('sending/register',{emergency:'1'});
        },

        /**
         * テンプレート管理画面を表示
         */
        showTemplatePage: function() {
            //検索条件の保管
            this.saveFilterStore();
            //テンプレート管理画面に移動
            Router.moveTo('sending/template');
        },

        /**
         * 詳細画面を表示
         */
        onDetailButtonClick: function(object) {
            //検索条件の保管
            this.saveFilterStore();
            // 配信情報詳細画面へ遷移
            Router.moveTo('sending/detail', {sendingId: object.sendingId});
        },

        /*
         * 全てのチャネルを選択する
         */
        checkAllChannels: function() {
            this.emergencyMail.set('checked', true);
            this.lalert.set('checked', true);
            this.prefectureMail.set('checked', true);
            this.twitter.set('checked', true);
            this.yahoo.set('checked', true);
        },

        /*
         * 全てのチャネルを未選択にする
         */
        uncheckAllChannels: function() {
            this.emergencyMail.set('checked', false);
            this.lalert.set('checked', false);
            this.prefectureMail.set('checked', false);
            this.twitter.set('checked', false);
            this.yahoo.set('checked', false);
        }
    });
});

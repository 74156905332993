/**
 * 係の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/organization/_UnitDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/NumberTextBox',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    '../view/form/SectSelector'
], function(module, declare,  _PageBase, InfoDialog) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/organization/_UnitDialogBase~_UnitDialogBase# */
    {
        /**
         * 係コード
         */
        _unitCd: null,

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // 自治体
            if (!formData.municipalityCd || formData.municipalityCd === '') {
                InfoDialog.show('入力チェック', '自治体が選択されていません');
                return false;
            }

            // 課コード
            if (!formData.sectCd || formData.sectCd === '' ||
                !this.checkSect(formData.sectCd)) {
                InfoDialog.show('入力チェック', '課が選択されていません');
                return false;
            }

            // 係名
            if (!formData.unitName || formData.unitName === '') {
                InfoDialog.show('入力チェック', '係名が入力されていません');
                return false;
            }

            return true;
        },


        /**
         * 課が選択されていることを検証する
         */
        checkSect :function(sectCd) {
            var result = false;

            // sectCdが'S'から始まっていれば課が選択されている
            if (sectCd.slice(0, 1) === 'S') {
                result = true;
            } else {
                result = false;
            }

            return result;
        }
    });
});

/**
 * 避難状況配信処理ベースモジュール。
 * @module app/evacorder/_EvacOrderProvidePageBase
 */
define([
    'module',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/promise/all',
    'dojo/topic',
    'app/model/Municipality',
    'app/provide/consts/DeliveredStatus',
    'app/shelter/consts/CrowdedStatus',
    './DistributionType',
    './EvacOrderType',
    './EvacOrderUtil'
], function (module, Router, Requester, DialogChain, InfoDialog, Loader, _PageBase,
    array, declare, lang, json, all, topic,
    Municipality, DeliveredStatus, CrowdedStatus,
    DistributionType, EvacOrderType, EvacOrderUtil) {
/**
 * 避難状況配信処理
 * @class _EvacOrderProvidePageBase
 * @extends module:idis/view/page/_PageBase~_PageBase
 */
return declare(module.id.replace(/\//g, '.'), _PageBase,
/** @lends module:app/evacorder/_EvacOrderProvidePageBase~_EvacOrderProvidePageBase# */ {

    constructor: function () {
        // ダイアログ連鎖を登録
        this.chain = DialogChain.get(this);
    },

    /**
     * [一括配信] Lアラート配信ダイアログを表示する
     * @type {{municipalityCd: string, disasterId: string}} query
     */
    showProvideDialog4bulkProvide: function(query) {
        all({
            // 情報配信画面に渡す避難状況情報を取得する
            evacOrderArgs: this._getLalertEvacOrderWholeCheck(query, true),
            municipalityName: Municipality.store.get(this._municipalityCd).then(function (item) { return item.name; })
        }).then(lang.hitch(this, function (lalertArgs) {
            lalertArgs.urgentMailArgs = { municipalityName: lalertArgs.municipalityName };
            lalertArgs.isBulk = true;
            this.showProvideDialog(lang.hitch(this, this.sendLalert), lalertArgs);
        }), function (error) {
            console.error(error);
            if (error.response && error.response.data && typeof (error.response.data) === 'string') {
                error.response.data = json.parse(error.response.data);
            }
            this.chain.infoError(error);
        });
    },

    /**
     * [一括配信] 外部配信のみ実施（一覧画面から一括配信用）
     * @param {*} info 
     */
    sendLalert: function (info) {
        var message = 'Lアラート等への配信を行います。\nよろしいですか？';
        this.chain.confirm(message, lang.hitch(this, function (chain) {
    
            var form = {};
            // 市町村をセットする。
            form.municipalityCd = this._municipalityCd;
            // 災害IDをセットする。
            form.disasterId = this._disasterId;
            // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
            form.note = info.evacOrderInfo.lalertForm.complementaryInfo;
            form.twitterFlg = info.evacOrderInfo.twitterFlg;
            form.prefMailFlg = info.evacOrderInfo.prefMailFlg;
            form.twitterBodyText = info.evacOrderInfo.twitterMessage; //Twitter用本文
            form.prefMailBodyText = info.evacOrderInfo.prefMailBodyText; //県防災情報メール用本文
            form.subject = info.evacOrderInfo.prefMailSubject; //県防災情報メール用件名
    
            form.sendPostForms = [];
            form.sendPostForms.push(info.evacOrderInfo.lalertForm);
            if (info.urgentMailInfo) {
                form.sendPostForms.push(info.urgentMailInfo);
            }

            var jsonStr = json.stringify(form);
            var promise = Requester.post('/api/evacorders/send', {
                data: jsonStr,
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(lang.hitch(this, function () {
                // 成功時
                chain.infoComplete(lang.hitch(this, function () {
                    // ヘッダーに反映
                    topic.publish('app/evacorder/EvacOrderPage::delivered');
                    chain.hide();
                    this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                    // 一覧画面再検索
                    this.onSubmit();
                }));
            }), function (err) {
                // 失敗時
                if (err.response.data && typeof (err.response.data) === 'string') {
                    err.response.data = json.parse(err.response.data);
                }
                chain.infoError(err);
            });
            Loader.wait(promise);
        }));
    },

    /**
     * [登録] Lアラート配信(避難情報)登録ダイアログを表示する。
     */
    showEvacOrderDialog4Register: function (form) {

        all({
            // 情報配信画面に渡す避難所開設情報を構築する
            shelterArgs: this._getLalertShelterArgs(form.facilityIds),
            // 情報配信画面に渡す避難状況情報を取得する
            evacOrderArgs: this._getLalertEvacOrderWholeCheck(form),
            // 緊急メール画面に渡す避難状況情報を取得する
            urgentMailArgs: this._getUrgentMailArgs()
        }).then(lang.hitch(this, function (lalertArgs) {
            // 避難所引数内の施設IDと避難所引数を外に出す
            if (lalertArgs.shelterArgs) {
                lalertArgs.facilityId = lalertArgs.shelterArgs.facilityId;
                lalertArgs.shelterArgs = lalertArgs.shelterArgs.shelterArgs;
                // 新規登録避難所一覧にLアラート配信情報から混雑状況を取得して追加
                array.forEach(this._evacOrderForm.data.items, function(shelter) {
                    var lalsterInfo = array.filter(lalertArgs.shelterArgs.evaqueeData, function(arg) {
                        return arg.facilityId === shelter.facilityId;
                    })[0];
                    if (!!lalsterInfo) {
                        shelter.crowdedStatus = lalsterInfo.crowdedStatus;
                    }
                });
            }
            // Lアラート用の情報を付与する
            // 緊急速報メールなどの文面用にリストを作成する
            lalertArgs.districtList = form.districtList;

            // 配信ダイアログ表示
            this.showProvideDialog(lang.hitch(this, this.sendAndRegister), lalertArgs);

        }), lang.hitch(this, function (error) {
            console.error(error);
            if (error.response.data && typeof (error.response.data) === 'string') {
                error.response.data = json.parse(error.response.data);
            }
            this.chain.infoError(error);
        }));
    },

    /**
     * [登録] 配信情報をパラメータにセットして、登録＆配信処理を行う。
     * @param {*} info 配信ダイヤログから渡された配信情報入りの避難情報
     */
    sendAndRegister: function (info) {

        var sendPostForms = [];

        // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
        this._evacOrderForm.note = info.evacOrderInfo.lalertForm.complementaryInfo;
        this._evacOrderForm.twitterFlg = info.evacOrderInfo.twitterFlg;
        this._evacOrderForm.prefMailFlg = info.evacOrderInfo.prefMailFlg;
        this._evacOrderForm.twitterBodyText = info.evacOrderInfo.twitterMessage; //Twitter用本文
        this._evacOrderForm.prefMailBodyText = info.evacOrderInfo.prefMailBodyText; //県防災情報メール用本文
        this._evacOrderForm.subject = info.evacOrderInfo.prefMailSubject; //県防災情報メール用件名
        delete this._evacOrderForm.evacOrderLalertAreaMap;
        delete this._evacOrderForm.correctCancelReason;

        sendPostForms.push(info.evacOrderInfo.lalertForm);
        if (info.shelterInfo && info.shelterInfo.lalertForm) {
            this._evacOrderForm.data.twitterFlg = info.shelterInfo.twitterFlg;
            this._evacOrderForm.data.yahooFlg = info.shelterInfo.yahooFlg;
            this._evacOrderForm.data.prefMailFlg = info.shelterInfo.prefMailFlg;
            this._evacOrderForm.data.sendPostForm = info.shelterInfo.lalertForm;
        }
        if (info.urgentMailInfo) {
            sendPostForms.push(info.urgentMailInfo);
        }
        this._evacOrderForm.sendPostForms = sendPostForms;

        // 登録する
        this.register();
    },

    /**
     * [訂正] Lアラート配信(避難情報)訂正ダイアログを表示する。
     * @param {Object} form
     * @param {boolean} isCancelling false: 避難情報訂正, true: 避難情報取消（取消後も発令中の情報が残っている場合）
     */
    showEvacOrderDialog4CorrectCancel: function (form, isCancelling) {

        this._getLalertEvacOrderWholeCheck(form).then(lang.hitch(this, function (evacOrderArgs) {
            // lalertArgs.evacOrderArgs._isUrgentMailTarget = lalertArgs.checkUpgradeStatus;
            var lalertArgs = {};
            lalertArgs.evacOrderArgs = evacOrderArgs;

            // Lアラート用の情報を付与する
            // 訂正・取消理由を渡す
            lalertArgs.evacOrderArgs.errataDescription = form.correctCancelReason;

            // Lアラート種別を渡す
            lalertArgs.evacOrderArgs.distributionType = DistributionType.CORRECT;

            // 配信ダイアログ表示
            this.showProvideDialog(lang.hitch(this,
                !isCancelling ? this.sendAndRegisterCorrect : this.sendAndRegisterCancel
            ), lalertArgs);

        }), lang.hitch(this, function (error) {
            console.error(error);
            if (error.response.data && typeof (error.response.data) === 'string') {
                error.response.data = json.parse(error.response.data);
            }
            this.chain.infoError(error);
        }));
    },

    /**
     * [訂正] 避難情報の訂正＆配信処理を実行する
     * @param {Object} form
     */
    sendAndRegisterCorrect: function (info) {
        var sendPostForms = [];

        // 避難所開設情報が設定されているか確認する。
        // 設定されていない場合はダイアログを警告メッセージに変更する。
        var message = '避難情報を訂正します。<br>よろしいですか。';
        var promise = null;

        // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
        this._evacOrderForm.note = info.evacOrderInfo.lalertForm.complementaryInfo;
        this._evacOrderForm.correctCancelReason = info.evacOrderInfo.lalertForm.errataDescription;
        // this._evacOrderForm.twitterFlg = info.evacOrderInfo.twitterFlg;
        this._evacOrderForm.prefMailFlg = info.evacOrderInfo.prefMailFlg;
        this._evacOrderForm.bodyText = info.evacOrderInfo.urgentMailMessage;
        delete this._evacOrderForm.evacOrderLalertAreaMap;

        sendPostForms.push(info.evacOrderInfo.lalertForm);
        this._evacOrderForm.sendPostForms = sendPostForms;
        this.chain.confirm(message, lang.hitch(this, function (chain) {
            // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
            promise = this.formStore.add(this._evacOrderForm).then(lang.hitch(this, function () {

                chain.info('登録・配信が完了しました。', '完了', lang.hitch(this, function () {
                    // ヘッダーに反映
                    topic.publish(module.id + '::updated');
                    this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                    Router.moveTo('evacorder', {
                        municipalityCd: this._municipalityCd
                    });
                }));

            }), lang.hitch(this, function (error) {
                console.error(error);
                if (error.response.data && typeof (error.response.data) === 'string') {
                    error.response.data = json.parse(error.response.data);
                }
                chain.infoError(error);
            }));
            Loader.wait(promise);
        }));
    },

    /**
     * [取消] 避難情報の取消＆配信処理を実行する
     * @param {Object} info
     */

    sendAndRegisterCancel: function (info) {
        // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
        var correctCancelReason = info.evacOrderInfo.lalertForm.errataDescription;

        // 避難所開設情報が設定されているか確認する。
        // 設定されていない場合はダイアログを警告メッセージに変更する。
        var message = '避難情報を取り消します。<br>よろしいですか。';
        this.chain.confirm(message, lang.hitch(this, function (chain) {

            var sendPostForms = [];
            sendPostForms.push(info.evacOrderInfo.lalertForm);

            // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
            var promise = Requester.put('/api/evacorders/cancel', {
                data: {
                    evacOrderId: this._evacOrderId,
                    disasterId: this._disasterId,
                    issueReasonType: this._cancelIssueReasonType,
                    municipalityCd: this._municipalityCd,
                    correctCancelReason: correctCancelReason,
                    sendPostForms: sendPostForms
                }
            }).then(lang.hitch(this, function () {

                chain.info('取消情報の登録・配信が完了しました。', '完了', lang.hitch(this, function () {
                    // ヘッダーに反映
                    topic.publish(module.id + '::updated');
                    this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                    Router.moveTo('evacorder', {
                        municipalityCd: this._municipalityCd
                    });
                }));

            }), lang.hitch(this, function (error) {
                console.error(error);
                if (error.response.data && typeof (error.response.data) === 'string') {
                    error.response.data = json.parse(error.response.data);
                }
                chain.infoError(error);
            }));
            Loader.wait(promise);
        }));
    },

    /**
     * [取消] Lアラート配信(避難情報)取消ダイアログを表示する。（取消後、他の避難情報が残らない場合の取消）
     * @param {Object} data 取消対象の情報
     * @param {Object} form formからセットする情報
     */
    showEvacOrderCancelDialog: function () {

        // 取消対象のLアラート連携用データを取得しておく。
        return Loader.wait(Requester.get('/api/evacorders/lalert/cancel', {
            query: {
                disasterId: this._disasterId,
                municipalityCd: this._municipalityCd,
                issueReasonType: this._initialIssueReasonType
            }
            // 取消処理を実行して取消後の返り値を保持する。
        })).then(lang.hitch(this, function (beforeLalert) {
            // 下記の場合にはLアラートに連携しない。
            // ・前報がない場合（自主避難の歴史しか存在しない場合） lalertSendHistIdがint型のため値がない場合は0が返却される
            // ・前報が取消し情報の場合
            if (beforeLalert.lalertSendHistId === 0 || beforeLalert.distributionType === DistributionType.CANCEL) {
                this.chain.hide();
                Router.moveTo('evacorder', {
                    municipalityCd: this._municipalityCd
                });
                return;
            }

            // 訂正取消し理由・補足情報は画面の値を使う
            var correctCancelReason = this.correctCancelReason.get('value');
            var note = this.note.get('value');


            // 市町村内の全避難世帯数・避難者数を計算する
            var evacHouseholdNum = 0;
            var evaqueeNum = 0;
            array.forEach(beforeLalert.evacOrderAreaList, function (area) {
                evacHouseholdNum += area.evacHouseholdNum;
                evaqueeNum += area.evaqueeNum;
            });

            // 配信ダイアログ表示
            this.showProvideDialog(lang.hitch(this, this.sendAndRegisterCancel), {
                evacOrderArgs: {
                    sendCategory: '01', // 避難勧告・指示
                    municipalityCd: this._municipalityCd,
                    evacTimestamp: beforeLalert.sendTimestamp,
                    issueReasonType: beforeLalert.issueReasonType,
                    evacOrderType: this.evacOrderType.get('value'),
                    evacuateReason: beforeLalert.evacuateReason,
                    evacHouseholdNum: evacHouseholdNum,
                    evaqueeNum: evaqueeNum,
                    evacGuidunce: beforeLalert.evacuateGuideline,
                    note: note,
                    evaqOrderData: beforeLalert.evacOrderAreaList,
                    lalertSendHistId: beforeLalert.lalertSendHistId,
                    isFix: this.isFixPage, // 詳細ページからの場合はerrata欄を表示
                    correctCancelReason: correctCancelReason,
                    distributionType: DistributionType.CANCEL //取消
                }
            });

        }));
    },

    /**
     * （共通）配信ダイアログ表示
     * @param {function} callbackFunc  DB登録＆配信処理関数(配信ボタン押下時コール)
     * @param {{*}} evacOrderArgs   避難・避難所配信情報
     */
    showProvideDialog: function(callbackFunc, lalertArgs) {
        // ダイアログの最初の子要素が登録画面
        var dialog = this.evacOrderRegisterDialog.getChildren()[0];
        // ダイアログのsendAndRegisterイベントを受け取れるようにする
        dialog.on('sendandregister', lang.hitch(this, function (info) {
            // 避難情報・避難所情報の一括登録 ＋ 外部連携配信を一気に行う。
            try {
                return callbackFunc(info);
            } catch (error) {
                this.chain.info(['エラーが発生しました', error].join('<br>'), 'エラー');
            }
        }));
        var isIssue = !EvacOrderUtil.isReleased(lalertArgs.evacOrderArgs.evacOrderType);
        if (!isIssue) {
            // 解除の場合、解除対象の発令内容をセット
            array.forEach(EvacOrderType.data, function(item) {
                if (item.id === lalertArgs.evacOrderArgs.evacOrderType) {
                    lalertArgs.evacOrderArgs.evacOrderType = item.issueId;
                    return;
                }
            });
        }
        // ダイアログの画面を初期化する。
        Loader.wait(dialog.initDialog(lang.mixin(lalertArgs, { isIssue: isIssue })))
        .then(lang.hitch(this, function () {
            // ダイアログを破棄できないように設定する。
            this.evacOrderRegisterDialog.set('closable', false);
            // ダイアログを表示する。
            this.evacOrderRegisterDialog.show();
        }));
    },

    /**
     * 指定された施設IDを元にLアラート・ダイアログ用の避難所情報を生成して返す。
     * @param {string} facilityIds 避難所のID一覧
     * @returns {Promise<Object>} Lアラート・ダイアログに出す避難所情報
     */
    _getLalertShelterArgs: function (facilityIds) {
        if (!facilityIds) {
            return null;
        }
        facilityIds = facilityIds || '[]';
        return Requester.get('/api/facility/list/' + facilityIds)
            .then(lang.hitch(this, function (facilityItems) {
                if (!facilityItems) {
                    return null;
                }
                var sendFacilityItems = array.filter(facilityItems, function (item) {
                    return (item.publicFlg && item.publicFlg === '1');
                });
                if (!sendFacilityItems || sendFacilityItems.length === 0) {
                    return null;
                }
                var evacShelterDateTime = this._formatDateFromObj(new Date());
                return {
                    facilityId: facilityItems[0].facilityId,
                    shelterArgs: {
                        sendCategory: '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        distributionType: DistributionType.NEW,
                        municipalityCd: this._municipalityCd,
                        evaqueeData: array.map(sendFacilityItems, function (item) {
                            return {
                                facilityId: item.facilityId,
                                address: item.address,
                                evacShelterName: item.facilityName,
                                evacShelterNameKana: item.facilityNameKana,
                                evacShelterType: item.facilityTypeName,
                                evacShelterSort: '開設',
                                evacShelterLatitude: item.latitude,
                                evacShelterLongitude: item.longitude,
                                evaqueeNum: 0,
                                evaqueeActNum: 0,
                                evacHouseholdNum: 0,
                                evacHouseholdActNum: 0,
                                evacShelterDatetime: evacShelterDateTime,
                                evacTopical: ' ',
                                evacShelterTypeOfDisaster: this._generateEvacShelterTypeOfDisaster(item),
                                crowdedStatus: CrowdedStatus.calcCrowdedStatus(0, item.capacity),
                                spaceStatus: CrowdedStatus.calcCrowdedStatusLabel(0, item.capacity),
                                eDesignatedEvacShFlg: item.eDesignatedEvacShFlg,
                                // designatedEvacShFlg: item.designatedEvacShFlg,
                                edesignatedEvacShFlg: item.edesignatedEvacShFlg,
                                welfareEvacShFlg: item.welfareEvacShFlg,
                                // secondaryEvacShFlg: item.secondaryEvacShFlg,
                                // plannedNotSiteEvacShFlg: item.plannedNotSiteEvacShFlg,
                                // wardsEvacShFlg: item.wardsEvacShFlg,
                                compmentPrelinEvacShFlg: item.compmentPrelinEvacShFlg
                                // edesignatedPlaceEvacShFlg: item.edesignatedPlaceEvacShFlg
                            };
                        }, this)
                    }
                };
            }), function (error) {
                console.error(error);
                throw new Error('避難所用Lアラート情報取得に失敗');
            });
    },

    /**
     * Lアラート連携用対象災害リストを返す
     * @param {Object} facility
     *                  避難所施設情報
     */
    _generateEvacShelterTypeOfDisaster: function(facility) {
        var evacShelterTypeOfDisaster = [];
        if (facility.floodShFlg === '1') {
            evacShelterTypeOfDisaster.push('洪水');
        }
        if (facility.sedimentDisasterShFlg === '1') {
            evacShelterTypeOfDisaster.push('崖崩れ、土石流及び地滑り');
        }
        if (facility.stormSurgeShFlg === '1') {
            evacShelterTypeOfDisaster.push('高潮');
        }
        if (facility.earthquakeShFlg === '1') {
            evacShelterTypeOfDisaster.push('地震');
        }
        if (facility.tsunamiShFlg === '1') {
            evacShelterTypeOfDisaster.push('津波');
        }
        if (facility.fireShFlg === '1') {
            evacShelterTypeOfDisaster.push('大規模な火事');
        }
        if (facility.landsideWaterShFlg === '1') {
            evacShelterTypeOfDisaster.push('内水氾濫');
        }
        if (facility.volcanoShFlg === '1') {
            evacShelterTypeOfDisaster.push('火山現象');
        }
        return evacShelterTypeOfDisaster.join(',');
    },

    // これから避難情報を発令予定の地区について、すでに発令済みの情報と合わせて全域判定を行う。
    _getLalertEvacOrderWholeCheck: function (form, isBulkProv) {

        // 新規登録時
        var wholeAreaCheckForm = {
            disasterId: form.disasterId,
            municipalityCd: form.municipalityCd,
            // issueReasonType: form.issueReasonType,
            evacorderId: this._evacOrderId,
            evacOrderLalertAreaMap: form.evacOrderLalertAreaMap
        };
        var jsonStr = json.stringify(wholeAreaCheckForm);
        return Loader.wait(Requester.post('/api/evacorders/lalert/wholeAreaCheck', {
            data: jsonStr,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            handleAs: 'json',
            preventCache: true
        })).then(lang.hitch(this, function (data) {
            // 市町村内の全避難世帯数・避難者数を計算
            var evacNum = this._aggregatedEvacOrderData(data);

            // 一括配信の場合、未配信かつ発令日時が最新の避難情報を取得
            if (isBulkProv) {
                array.forEach(array.filter(data.evacOrderAreaList, function (evacOrderArea) {
                    return evacOrderArea.deliveredStatus === DeliveredStatus.UN_DELIVERED;
                }), function (evacOrderArea, i) {
                    if (i === 0 || form.evacOrderTimestamp < evacOrderArea.evacOrderTimestamp) {
                        form = lang.clone(evacOrderArea);
                        var isReleased = form.evacuateIssue !== '解除';
                        form.evacOrderType = array.filter(EvacOrderType.data, function(item) {
                            return form.evacuateSort ===
                                (isReleased ? item.simpleLabel : item.issue); })[0].id;
                        form.issueReason = evacOrderArea.reason;
                        form.evacOrderTimestamp = new Date(form.evacOrderTimestamp);
                    }
                });
            }

            return {
                sendCategory: '01', // 避難勧告・指示
                municipalityCd: wholeAreaCheckForm.municipalityCd,
                subject: form.evacOrderName,
                evacTimestamp: form.evacOrderTimestamp,
                issueReasonType: form.issueReasonType,
                evacuateReason: form.issueReason,
                evacOrderType: form.evacOrderType, // 緊急メール配信用
                evacHouseholdNum: evacNum.evacHouseholdNum,
                evaqueeNum: evacNum.evaqueeNum,
                evacGuidunce: form.evacGuidance,
                lastDistributionType: data.distributionType,
                note: form.note,
                evaqOrderData: data.evacOrderAreaList,
                lalertSendHistId: data.lalertSendHistId,
                isFix: !!this.isFixPage, // 詳細ページからの場合はerrata欄を表示
                correctCancelReason: form.correctCancelReason
            };
        }), function (error) {
            console.error(error);
            throw new Error('全域判定APIの呼び出しに失敗。', error);
        });
    },

    /**
     * 緊急速報メール用のパラメータを返す
     * @param {string} municipalityCd 
     * @returns 
     */
    _getUrgentMailArgs: function () {
        return all({
            municipalityName: Municipality.store.get(this._municipalityCd).then(function (item) { return item.name; }),
            // Lアラート配信ダイアログを表示した際に取得した地区一覧をテンプレートに利用する。
            districtList: Requester.post('/api/evacorders/district/hierarchy', {
                data: {
                    disasterId: this._disasterId,
                    municipalityCd: this._municipalityCd,
                    issueReasonType: this.issueReasonType.get('value'),
                    evacOrderType: this.evacOrderType.get('value'),
                    districtList: this.convertDistrictList(this._districtArray)
                }
            })
        });
    },

    /**
     * 日付フォーマッター
     */
    _formatDateFromObj: function (date) {
        return date.getFullYear() + '-' + this._zeroPadding(date.getMonth() + 1) + '-' +
            this._zeroPadding(date.getDate()) + 'T' + this._zeroPadding(date.getHours()) + ':' +
            this._zeroPadding(date.getMinutes()) + ':' + '00.000+09';
    },

    /**
     * 月や日付を2桁にゼロpaddingする
     */
    _zeroPadding: function (month) {
        return ('00' + month).slice(-2);
    },

    /**
      * 市町村内の全避難世帯数・避難者数を計算する。
      * @param {string} data 避難情報
      * @returns {Promise<Object>} 集計結果
      */
    _aggregatedEvacOrderData: function (data) {
        // 市町村内の全避難世帯数・避難者数を計算する。
        // 同じ地区が、相異なる発令理由・発令内容で発令対象となっていた場合、
        // 地区同士を比較して、より新しく発令した方を集計用の人数・世帯数として集計する。
        // 全域判定が挟まると重複してしまうが、その重複ぶんは無視をする。
        var result = {
            evacHouseholdNum: 0,
            evaqueeNum: 0
        };
        var isIssued = false; // 発令中の避難情報があるかどうか
        var distEvaqNumMap = {}; // 地区コードと地区情報オブジェクトのマップ
        array.forEach(data.evacOrderAreaList, function (area) {
            if (area.evacuateIssue !== '解除') {
                isIssued = true;
                //地区がマップ内にあるかどうか判定する
                if (Object.keys(distEvaqNumMap).indexOf(area.districtCd) !== -1 &&
                    distEvaqNumMap[area.districtCd].evacOrderTimestamp < area.evacOrderTimestamp) {
                    // 地区がマップ内に登録済みで、かつ登録済みの地区情報よりも現在の地区の方が新しい場合
                    distEvaqNumMap[area.districtCd] = area;
                } else if (Object.keys(distEvaqNumMap).indexOf(area.districtCd) === -1) {
                    // 含まれていなかった場合は、今のareaをそのまま登録。
                    distEvaqNumMap[area.districtCd] = area;
                }
            }
        });

        // マップ内には、各地区の最新情報１件のみが入っている。
        array.forEach(Object.keys(distEvaqNumMap), function (distCd) {
            var dist = distEvaqNumMap[distCd];
            result.evacHouseholdNum += (dist.evacHouseholdNum) ? dist.evacHouseholdNum : 0;
            result.evaqueeNum += (dist.evaqueeNum) ? dist.evaqueeNum : 0;
        }, this);

        if (isIssued) {
            // もし一つでも「発令」があって、かつ対象世帯数・人数がこの時点で0だということは、
            // 対象世帯数・人数が不明だということなので、nullを仕込む。（Lアラート連携時には空欄になる）
            result.evacHouseholdNum = (result.evacHouseholdNum === 0) ? null : result.evacHouseholdNum;
            result.evaqueeNum = (result.evaqueeNum === 0) ? null : result.evaqueeNum;
        }
        return result;
    }
});
});

/**
* 被害種別選択用grid
* @module app/formDisplay/damagetype/DamageTypeGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dijit/form/RadioButton',
    'dijit/form/CheckBox',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, array, RadioButton, CheckBox,
    IdisGrid, helper) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage-list',

        // radiobutton/checkbox制御用
//        radioButtons: {},
        checkboxes: {},

        // 親子のID
//        mainTypeId: null,
        mainTypeIds: [],

        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [
//                {
//                    field: 'main', label: '主', sortable: false,
//
//                    renderCell: lang.hitch(this, function(item) {
//                        this.radioButtons[item.damageTypeId] = new RadioButton({
//                            onClick: lang.hitch(this, function() {
//                                this._onRadioButton(item);
//                            })
//                        });
//                        // HTMLとしてウィジェットに紐付くDOMノードを返す
//                        return this.radioButtons[item.damageTypeId].domNode;
//                    })
//                },
                {
                    field: 'main', label: '選択', sortable: false,

                    renderCell: lang.hitch(this, function(item) {
                        this.checkboxes[item.damageTypeId] = new CheckBox({
                            onClick: lang.hitch(this, function() {
                                this._onCheckBox(item);
                            })
                        });
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.checkboxes[item.damageTypeId].domNode;
                    })
                },
                helper.column('damageType', '被害種別')
            ];

            this.inherited(arguments);
        },

        reset: function() {
            this.inherited(arguments);

//            this.radioButtons = {};
            this.checkboxes = {};
//            this.mainTypeId = null;
            this.mainTypeIds = [];
        },

        /**
         *  ラジオボタンを押した時
         *  押されたものが親, それ以外を全て子供にチェックを入れるようにしている
         * @param {*} item 押された行の内容
         */
//        _onRadioButton: function(item) {
//            this.mainTypeId = item.damageTypeId;
//            console.log('main: ' + this.mainTypeId);
//
//            // 全部のcheckboxをenableにする。
//            for (var key in this.checkboxes) {
//                if (this.checkboxes.hasOwnProperty(key)) {
//                    this.checkboxes[key].set('disabled', false);
//                }
//            }
//
//            // 今回親にした行のチェックボックスをdisableに
//            this.checkboxes[item.damageTypeId].set('disabled', 'disabled');
//            this.checkboxes[item.damageTypeId].set('checked', false);
//            this._onCheckBox(item);
//
//        },

        _onCheckBox: function(item) {
            if (this.checkboxes[item.damageTypeId].get('checked')) {
                // チェック状態である => 今回追加されたのでmainTypeIdsに入れる
                this.mainTypeIds.push(item.damageTypeId);
            } else {
                // チェック状態でない => mainTypeIdsにあったら削除
                var index = array.indexOf(this.mainTypeIds, item.damageTypeId);
                if (index !== -1) {
                    this.mainTypeIds.splice(index, 1);
                }
            }

            console.log(this.mainTypeIds);
        }
    });
});

define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    './ChronologyHistory',
    'idis/util/DateUtils'
  ], function(module, declare, IdisGrid, ChronologyHistory, DateUtils) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

      // ルート要素に付与されるCSS
      baseClass: 'idis-Page idis-Page--chronology',

      showHeader: false,

      showFooter: false,

      /**
       * 各列の定義
       * @type {Object[]}
       */

      items: null,

      columns: [
        // ボタン
        {
          field: 'chronologyHistory',
          label: '',
          sortable: false,
          className: 'historyGridRow',

          renderCell: function(item) {
            var history = new ChronologyHistory({
                chronologyHistId: item.chronologyHistId,
                content: item.content,
                registerTimestamp: DateUtils.format(item.registerTimestamp),
                seqNum: item.seqNum,
                shareName: item.shareName
            });
            return history.domNode;
          }
        }
      ]
    });
  });
/**
 * 配信情報詳細画面用モジュール。
 * @module app/sending/SendingHistoryDertailPage
 */
define([
    'module',
    'dojo',
    'dojo/date/locale',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/json',
    'dojo/when',
    'dojo/Deferred',
    'dojo/on',
    'dojo/text!./templates/SendingHistoryDetailPage.html',
    'dijit/registry',
    'dijit/TooltipDialog',
    'dijit/popup',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'app/provide/ProvideUtil',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/ContentPane',
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector',
    'app/sendingHistory/dialog/InfoDialog',
    'app/sendingHistory/dialog/DisasterPreventionDialog',
    'app/sendingHistory/dialog/ShelterDialog',
    'app/sendingHistory/dialog/ChronologyDialog',
    'app/sendingHistory/dialog/DamageDialog',
    'app/sendingHistory/dialog/DamageDetailDialog',
    'app/sendingHistory/dialog/EvacOrderDialog'
], function (
    module,
    dojo,
    locale,
    declare,
    lang,
    array,
    dom,
    domStyle,
    json,
    when,
    Deferred,
    on,
    template,
    registry,
    TooltipDialog,
    popup,
    Locator,
    Router,
    UserInfo,
    Requester,
    DialogChain,
    InfoDialog,
    Loader,
    ProvideUtil,
    _PageBase
) {
    /**
     * 配信情報詳細画面。
     * @class SendingDetailPage
     * @extends module:app/sending/_SendingPageBase~_SendingPageBase
     */
    return declare(
        module.id.replace(/\//g, '.'),
        _PageBase,
        /** @lends module:app/sending/SendingHistoryDetailPage~SendingHistoryDetailPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--sending',

            _sendingId: null,
            _twitterId: null,

            // サーバからもらう配信情報を持つ
            _sendingItem: null,

            // Lアラートの詳細カテゴリーの値を持つ
            // セレクタにセットするための苦肉の策
            _lalertDetailType: null,

            _itemTypeNameList: {
                '1': '災害登録情報',
                '2': '本部設置状況',
                '3': '避難所情報',
                '4': '避難情報',
                '5': '被害情報',
                '6': '被害情報（詳細報）',
                '7': 'クロノロジー',
                '8': '府民向けお知らせ情報',
                '9': 'Lアラートお知らせ情報',
                '10': 'Lアラート送信結果',
                '21': 'テスト',
                '22': 'テスト'
            },
            constructor: function () {
                this.inherited(arguments);
                this.chain = DialogChain.get(this);
            },

            buildRendering: function () {
                this.inherited(arguments);
                // URLから配信IDを取得
                this._sendingId = Locator.getQuery().sendingId;
                this._twitterId = Locator.getQuery().twitterId;
            },

            startup: function () {
                this.inherited(arguments);
                when(this._setMemberVariables(), lang.hitch(this, this._initPage));
            },

            /**
             * 配信情報と、配信先の設定をサーバから取得する
             * メンバ変数_sendingItemに値が代入される
             *
             * 呼ぶときは when(this._setMemberVariables(), function())
             */
            _setMemberVariables: function () {
                var deferred = new Deferred();
                Requester.get('/api/sendingHisotry/item?sendingId=' + this._sendingId + '&twitterId=' + this._twitterId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(
                    lang.hitch(this, function (sendingObj) {
                        if (!sendingObj) {
                            deferred.resolve(null);
                        }
                        this._sendingItem = sendingObj;
                        deferred.resolve({
                            _sendingItem: this._sendingItem,
                        });
                    })
                );
                return deferred.promise;
            },

            /**
             * 詳細画面を初期化する。
             */
            _initPage: function (result) {
                var item = result._sendingItem;

                // 1. 共通項目の値をセットする
                this.sendOrganizationName.set('value', item.sendOrganizationName);
                this.sendTimestamp.set('value', item.sendTimestamp);
                this.municipalityName.set('value', item.municipalityName);
                this.subject.set('value', item.subject);
                this.bodyText.set('value', item.bodyText);


                switch (this._sendingItem.infoType) {
                    case '9': // Lアラートお知らせ情報
                        if (item.linkLalertType === '1') {
                            this.infoRow.style.display = '';

                            if (item.lalertInfo.sendStatus === '01') {
                                this.infoLalertStatus.innerHTML = '配信済み';
                                this.infoLalertSTimestamp.innerHTML = item.sendTimestamp;
                            }
                            if (item.lalertInfo.sendStatus === '99') {
                                this.infoLalertStatus.innerHTML = '失敗';
                                this.infoLalertSTimestamp.innerHTML = item.sendTimestamp;
                            }
                            if (item.lalertInfo.sendStatus === '00') {
                                this.infoLalertStatus.innerHTML = '未配信';
                            }
                        }
                        if (item.linkTwitterType === '1') {
                            this.twitterRow.style.display = '';
                            var isFail = false;
                            var isPend = false;
                            item.tweetList.forEach(lang.hitch(this, function (t) {
                                if (!this.isPend && t.statusCode === '00') {
                                    this.isPend = true;
                                }
                                if (t.statusCode === '99') {
                                    this.isFail = true;
                                    return;
                                }
                            }));

                            if (this.isFail) {
                                this.twitterStatus.innerHTML = '失敗';
                                this.twitterSTimestamp.innerHTML = item.sendTimestamp;
                                return;
                            }
                            if (this.isPend) {
                                this.twitterStatus.innerHTML = '未配信';
                            } else {
                                this.twitterStatus.innerHTML = '配信済み';
                                this.twitterSTimestamp.innerHTML = item.sendTimestamp;
                            }
                        }
                        if (item.linkPortalType === '1') {
                            this.prefRow.style.display = '';
                            if (item.prefInfo.sendStatus === '01') {
                                this.prefLalertStatus.innerHTML = '配信済み';
                                this.prefLalertSTimestamp.innerHTML = item.sendTimestamp;
                            }
                            if (item.prefInfo.sendStatus === '99') {
                                this.prefLalertStatus.innerHTML = '失敗';
                                this.prefLalertSTimestamp.innerHTML = item.sendTimestamp;
                            }
                            if (item.prefInfo.sendStatus === '00') {
                                this.prefLalertStatus.innerHTML = '未配信';
                            }
                        }
                        break;
                    case '7':
                        this.lalertRow.style.display = '';
                        if (item.chronology.sendStatus === '00') {
                            this.lalertStatus.innerHTML = '未配信';
                        }
                        if (item.chronology.sendStatus === '01') {
                            this.lalertStatus.innerHTML = '配信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.chronology.sendStatus === '02') {
                            this.lalertStatus.innerHTML = '受信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.chronology.sendStatus === '99') {
                            this.lalertStatus.innerHTML = '失敗';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        break;
                    case '6':
                        this.lalertRow.style.display = '';
                        if (item.damageDetail.sendStatus === '00') {
                            this.lalertStatus.innerHTML = '未配信';
                        }
                        if (item.damageDetail.sendStatus === '01') {
                            this.lalertStatus.innerHTML = '配信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.damageDetail.sendStatus === '02') {
                            this.lalertStatus.innerHTML = '受信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.damageDetail.sendStatus === '99') {
                            this.lalertStatus.innerHTML = '失敗';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        break;

                    case '5':
                        this.lalertRow.style.display = '';
                        if (item.damage.sendStatus === '00') {
                            this.lalertStatus.innerHTML = '未配信';
                        }
                        if (item.damage.sendStatus === '01') {
                            this.lalertStatus.innerHTML = '配信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.damage.sendStatus === '02') {
                            this.lalertStatus.innerHTML = '受信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.damage.sendStatus === '99') {
                            this.lalertStatus.innerHTML = '失敗';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        break;

                    case '4':
                        this.lalertRow.style.display = '';
                        if (item.evacute.sendStatus === '00') {
                            this.lalertStatus.innerHTML = '未配信';
                        }
                        if (item.evacute.sendStatus === '01') {
                            this.lalertStatus.innerHTML = '配信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.evacute.sendStatus === '02') {
                            this.lalertStatus.innerHTML = '受信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.evacute.sendStatus === '99') {
                            this.lalertStatus.innerHTML = '失敗';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        break;
                    case '3':
                        this.lalertRow.style.display = '';
                        if (item.shelter.sendStatus === '00') {
                            this.lalertStatus.innerHTML = '未配信';
                        }
                        if (item.shelter.sendStatus === '01') {
                            this.lalertStatus.innerHTML = '配信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.shelter.sendStatus === '99') {
                            this.lalertStatus.innerHTML = '失敗';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        break;

                    case '2':
                        this.lalertRow.style.display = '';
                        if (item.disaster.sendStatus === '00') {
                            this.lalertStatus.innerHTML = '未配信';
                        }
                        if (item.disaster.sendStatus === '01') {
                            this.lalertStatus.innerHTML = '配信済み';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        if (item.disaster.sendStatus === '99') {
                            this.lalertStatus.innerHTML = '失敗';
                            this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                        }
                        break;
                }

                this.senderName.set('value', item.senderName);
                var idx = Object.keys(this._itemTypeNameList).filter(function (key) {
                    return key === item.infoType;
                })[0];
                this.infoTypeName.innerHTML = this._itemTypeNameList[idx];
                // if (item.deliveredFlg === '1') {
                //     this.lalertStatus.innerHTML = '配信済み';
                //     this.lalertSTimestamp.innerHTML = locale.format(new Date(item.sendTimestamp));
                // } else {
                //     this.lalertStatus.innerHTML = '未配信';
                // }
            },

            /**
             * 配信情報一覧画面へ遷移する。
             */
            onSendingPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('sendingHistory');
            },

            /**
             * Lアラート配信内容確認（お知らせ以外）
             */
            showLalertDetail: function () {
                var page = null;
                switch (this._sendingItem.infoType) {
                    case '9': // Lアラートお知らせ情報
                        this.infoDialog.show();
                        page = this.infoDialog.getChildren()[0];
                        page.initDialog(this._sendingItem);
                        break;
                    case '7': // クロノロジー
                        this.chronologyDialog.show();
                        page = this.chronologyDialog.getChildren()[0];
                        page.initDialog(this._sendingItem);
                        break;
                    case '6': // 被害詳細情報
                        this.damageDetailDialog.show();
                        page = this.damageDetailDialog.getChildren()[0];
                        page.initDialog(this._sendingItem);
                        break;
                    case '5': // 被害情報
                        this.damageDialog.show();
                        page = this.damageDialog.getChildren()[0];
                        page.initDialog(this._sendingItem.damage);
                        break;
                    case '4': // 避難情報
                        this.evacOrderDialog.show();
                        page = this.evacOrderDialog.getChildren()[0];
                        page.initDialog(this._sendingItem);
                        break;
                    case '3': // 避難所情報
                        this.shelterDialog.show();
                        page = this.shelterDialog.getChildren()[0];
                        page.initDialog(this._sendingItem);
                        break;
                    case '2': // 対策本部設置情報
                        this.disasterPreventionDialog.show();
                        page = this.disasterPreventionDialog.getChildren()[0];
                        page.initDialog(this._sendingItem.disaster);
                        break;
                }
            }
        }
    );
});

/**
 * 避難発令テンプレート管理一覧画面用モジュール。
 * @module app/evacorder/EvacOrderTemplatePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/EvacOrderTemplatePage.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    './_EvacOrderPageBase',
    'idis/consts/USER_TYPE',
    'app/model/Region',
    '../config',
    'idis/view/Loader',
    'idis/control/Locator',
    // 以下、変数として受け取らないモジュール
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    './EvacOrderTemplateGrid',
    './EvacOrderTemplateRegisterDialog',
    './EvacOrderTemplateDetailDialog',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, declare, lang, template, Router, UserInfo, IdisRest, DialogChain,
    _EvacOrderPageBase, USER_TYPE, Region, config, Loader, Locator) {
        /**
         * 避難発令テンプレート管理一覧画面用モジュール。
         * @class EvacOrderTemplatePage
         * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
         */
        return declare(module.id.replace(/\//g, '.'), _EvacOrderPageBase,
        /** @lends module:app/evacorder/EvacOrderTemplatePage~EvacOrderTemplatePage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--evacorder',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        _userRoleCd: '',

        _isPrefUser: false,

        _municipalityCd: null,

        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'evacOrderTemplateId',
                target: '/api/evacorders/template'
            });
            this._userRoleCd = UserInfo.getRoleCd();
            this._isPrefUser = UserInfo.getUserType() === USER_TYPE.PREFECTURE;
            this._municipalityCd = this._isPrefUser ? null :
                UserInfo._userInfo.municipalityCd || UserInfo._userInfo.municipalityCds[0];

            // 避難発令テンプレートIDを取得
            this.evacOrderTemplateId = Locator.getQuery().evacOrderTemplateId;
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // ダイアログを表示
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.EvacOrderTemplateGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailDialog(evt.item);
            }));

            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
            }
            this.updateGridQuery();
        },

        /**
          * 検索パラメーターの設定
          */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 市町
            var munic = value.municipalityCd;

            if(munic && munic !== config.municInfo.prefCd){
                filter = filter.eq('municipalityCd', munic);
            }

            // キーワード
            if (value.keyword) {
                filter = filter.eq('keyword', value.keyword);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.EvacOrderTemplateGrid.set('collection', collection);
        },

        /**
        * テンプレートを検索する。
        */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            var filterStore = {};
            var value = this.form.get('value');

            // キーワード
            if (value.municipalityCd) {
                filterStore.municipalityCd = value.municipalityCd;
            }

            // キーワード
            if (value.keyword) {
                filterStore.keyword = value.keyword;
            }
        },

        /**
         * 避難発令一覧画面へ遷移する。
         */
        onEvacOrderPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('evacorder');
        },

        /**
        * 新規登録ダイアログを表示する。
        */
        showRegisterDialog: function() {
            this.registerDialog.show();
            var page = this.registerDialog.getChildren()[0];
            page.on('update', lang.hitch(this,function() {
                page.leave();
                page.form.reset();
                this.updateGridQuery();
            }));
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // 最後に開いたデータの項目を保持
            // 取消ダイアログでオリジナルの情報を表示するために利用
            this._lastDetailItem = item;
            this.detailDialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.detailDialog.getChildren()[0];

            // ダイアログの画面を再表示する
            page.form.reset();
            page.initContent(item);

            page.on('complete', lang.hitch(this,function() {
                page.leave();
                page.form.reset();
                this.updateGridQuery();
            }));
        }
    });
});
/**
 * Lアラートお知らせ配信ダイアログ。
 * @module app/provide/ProvideGeneralDetailDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ProvideGeneralDetailDialog.html',
    'app/provide/_ProvideDialogBase',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/util/DateUtils',
    'idis/store/IdisRest',
    './model/GeneralInfoCategoryGroup',
    './model/GeneralInfoCategory',
    './model/ProvideUrgencyType',
    'app/config',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/SimpleTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector'
], function(module, array, declare, lang, template,
    _ProvideDialogBase, DisasterInfo, UserInfo, ACL, DateUtils, IdisRest,
    GeneralInfoCategoryGroup, GeneralInfoCategory, ProvideUrgencyType, config) {
    /**
     * Lアラートお知らせ登録ダイアログ画面。
     * @class ProvideGeneralDetailDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), [_ProvideDialogBase],
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,
        // 登録用ストア
        registerStore: null,
        // 配信元の市町村コード
        _municipalityCd: null,
        // 配信種別
        GENERAL_CONTENT_TYPE : '04',
        // 運用種別
        _distributionType: null,

        // formがdisabled状態かどうか
        _isFormDisabled: false,

        buildRendering: function() {
            this.inherited(arguments);
            // 緊急度にモデルをセット
            this.urgencyType.set('store', ProvideUrgencyType);
            // 配信カテゴリーにモデルをセット
            this.generalInfoCategoryGroup.set('store', GeneralInfoCategoryGroup);
            this.generalInfoCategory.set('store', GeneralInfoCategory);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // カテゴリー・グループ選択の際、そのグループに紐付いたカテゴリーのみ選択可能にする
            this.own(this.generalInfoCategoryGroup.on('change', lang.hitch(this, function(value) {
                this.generalInfoCategory.set('query', function(item) {
                    // code先頭がグループのcodeと一致する場合のみ選択肢に表示
                    return item && item.code.indexOf(value) === 0;
                });
            })));
            // 訂正取消理由欄の入力状況により訂正・更新ボタンの表示を切り替える
            this.own(this.errataDescription.on('input', lang.hitch(this, function() {
                // 反応が早すぎると新しい入力値が反映出来ないため、1ミリ秒待ってから反映
                setTimeout(lang.hitch(this, '_updateCorrectButtonLabel'), 1);
            })));
        },

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.inherited(arguments);
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });
        },

        /**
         * 訂正取消理由欄の入力状況によって更新・訂正ボタンの表示を切り替える。
         */
        _updateCorrectButtonLabel: function() {
            // 訂正取消理由が入力されている場合は『訂正』、それ以外の場合は『更新』と表示
            this.correctButton.set('label', this.errataDescription.get('value') ? '訂正' : '更新');
        },

        // 訂正・取消ボタンの権限設定
        setButtonStatus: function(item) {
            // ユーザーの種別をセット
            var role = UserInfo.getRoleCd();
            // ユーザーの市町村情報をセット
            var municCdList = UserInfo.getMunicipalityCds();
            // ユーザーの種別が部局ユーザーでもその他ユーザーでもなく、
            // かつ市町村情報と参照中の発信元の市町村が一致する場合は更新・削除を可能に
            // 以下のいずれかの条件を満たす場合、訂正・取消ボタンを無効とする
            // - ユーザーが『部局ユーザー』または『その他ユーザー』である
            // - ユーザーの所属に『対象の配信元市町村』および『県』が含まれない
            // - 参照中の配信の更新種別が『訂正』または後続の改訂版が存在する
            var disabled = role === ACL.DEPT_USER || role === ACL.OTHER_USER ||
                array.indexOf(municCdList, this._municipalityCd) === -1 &&
                array.indexOf(municCdList, config.municInfo.prefMunicCd) === -1 ||
                item.distributionType === '03' || item.revised;
            this._isFormDisabled = disabled;
            this.correctButton.set('disabled', disabled);
            this.cancelButton.set('disabled', disabled);
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj) {
            // 前回の送信履歴ID
            this.lalertSendHistId.set('value', obj.lalertSendHistId);
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this._municipalityCd = obj.municipalityCd;
            this.municipalityCd._setValueAttr(this._municipalityCd);
            // 配信日時をセットする。
            var timestamp = new Date(obj.sendTimestamp);
            this.sendTimestampNode.innerHTML = DateUtils.format(timestamp);
            // タイトルを設定する
            this.subjectInput.set('value', obj.subject);
            // 緊急度を設定する。
            this.urgencyType.set('value', obj.urgencyType);
            // 一般情報カテゴリーを設定する（無ければ広報）
            var cat = obj.generalInfoCategory || '41';
            this.generalInfoCategoryGroup.set('value', cat[0]);
            // サブ・カテゴリーは選択肢が更新されてから設定
            // （イベント・リスナーの実行を待つため非同期化）
            setTimeout(lang.hitch(this.generalInfoCategory, 'set', 'value', cat), 10);
            // 内容を設定する。
            this.bodyText.setValue(obj.bodyText);
            // 訂正取消理由を設定する。
            this.errataDescription.setValue(obj.errataDescription);
            // 訂正取消理由の有無により更新・訂正ボタンの表示を切り替える
            this._updateCorrectButtonLabel();
            // 権限の設定
            this.setButtonStatus(obj);
        },
        // 訂正ボタンクリック時
        onCorrectButtonClick: function(){
            this._distributionType = '02';
            this.onSubmit();
        },
        // 取消ボタンクリック時
        onCancelButtonClick: function(){
            this._distributionType = '03';
            this.onSubmit();
        },
        onSubmit: function() {
            // formがdisabled状態の場合は何もしない
            if (this._isFormDisabled) {
                return false;
            }
            // municipalityCd・sendTimestamp・subject・urgencyType・bodyText・errataDescription
            //はformから取得
            var form = this.regForm.get('value');

            // 災害ID
            form.disasterId = DisasterInfo.getDisasterId();
            // 配信情報種別
            form.sendCategory = this.GENERAL_CONTENT_TYPE;
            // 運用種別
            form.distributionType = this._distributionType;

            // 現在の時刻を配信日時としてformにセットする。
            form.sendTimestamp = new Date();

            try{
                if (this.regForm.validate()){
                    // baseクラスでリクエストを投げる
                    this._postRequest(this.registerStore, form);
                }
            } catch(e) {
                console.error(e);
            }
            // デフォルトのonSubmitイベントをキャンセルする。
            return false;
        }
    });
});

/**
 * お知らせ情報画面用モジュール。
 * @module app/notice/view/NoticePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/NoticePage.html',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './form/NoticeTypeSelect',
    './NoticeDetailPage',
    './NoticeGrid',
    './NoticeTypePage',
    './NoticeRegisterPage'
], function(module, declare, lang, template, IdisRest, DialogChain, _PageBase, Loader) {
    /**
     * グリッド画面のサンプル実装。
     * @class NoticePage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/NoticePage~NoticePage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--notice',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'noticeId',
                target: '/api/notices'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // お知らせ種別
            if (value.noticeTypeId) {
                filter = filter.eq('noticeTypeId', value.noticeTypeId);
            }
            // 公開状況
            if (value.pubFlg) {
                filter = filter.eq('pubFlg', value.pubFlg);
            }
            // お知らせ日時
            if (value.fromDate) {
                filter = filter.eq('noticeFrom', value.fromDate.getTime());
            }
            if (value.toDate) {
                filter = filter.eq('noticeTo', value.toDate.getTime());
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var page = this.registerDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // 登録ダイアログを表示
            this.registerDialog.show();
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
            page.form.reset();
            // 行データの内容をフォームに反映
            // 詳細フォーム上、pubFlgはCheckGroupなので配列メンバーの有無で判定される
            item = lang.mixin(null, item, {
                pubFlg: (item.pubFlg ? ['0'] : [])
            });
            page.form.set('value', item);
            // 詳細ダイアログを表示
            this.detailDialog.show();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];
            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細画面のフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                // 更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    // OKが押された場合
                    // 更新処理と共にローディング表示
                    Loader.wait(this.store.put(evt.value)).then(function() {
                        // 成功時（PUT結果はグリッドが自動的に反映）
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
            // 詳細画面のdeleteイベントを受け取る
            page.on('delete', lang.hitch(this, function(evt) {
                // 削除確認ダイアログを表示
                this.chain.confirmDel(function(chain) {
                    // OKが押された場合
                    // 削除処理と共にローディング表示
                    Loader.wait(this.store.remove(evt.noticeId)).then(function() {
                        // 成功時（DELETE結果はグリッドが自動的に反映）
                        // 詳細ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.registerDialog);
            this.own(this.detailDialog);
            // グリッドを初期化する
            this.initGrid();
            // 登録画面内のフォームがsubmitされた際の動作を設定
            this.initRegisterPage();
            // 詳細画面内のフォームがsubmitされた際の動作を設定
            this.initDetailPage();
        }
    });
});


/**
 * 再配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingReConfirmDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/SpecteeMobileKeywordDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, declare, template, WidgetBase) {

    /**
     * 配信確認ダイアログ
     * @class SendingConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        /**
         * フォームに値をセットする。
         */
        setValue: function (arg) {
            this.keyword.set('value', arg);
        },

        /**
         * 「設定する」ボタンが押された時の挙動。
         */
        onSubmit: function () {
            try {
                // 送信パラメータを設定
                var value = this.form.get('value');
                this.emit('keyword', { keyword: value.keyword });
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

/**
 * 監視画面用モジュール
 * @module app/view/page/MonitoringPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/debounce',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/text!./templates/MonitoringPage.html',
    'dojo/on',
    'dojo/json',
    'dojo/topic',
    'dojo/when',
    'idis/consts/STORAGE_KEY',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/util/storage/LocalStorage',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/draw/measure/MeasurePane',
    'idis/view/page/_PageBase',
    'idis/view/draw/_DrawUtil',
    'idis/view/form/MunicipalitySelectForm',
    'idis/view/maptoimage/MapToImage',
    '../../config',
    'app/map/AutoUpdatePane',
    'app/map/LayerPane',
    'app/map/detail/DetailMap',
    'app/map/baselayer/BaseLayerPane',
    'app/map/legend/LegendPane',
    'app/map/legend/GridLegendPane',
    'app/map/notice/NoticePanel',
    'app/map/print/PrintDialog',
    'app/model/LayerStore',
    'app/monitor/consts/mapViewSelectCfg',
    'app/draw/DrawPanel',
    'app/damage/integrate/DamageReportIntegrator',
    'app/model/DisasterInfo',
    "dijit/registry",
    'app/map/vectorTile/vectorTileDialog',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/TabContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/StackContainer',
    'app/map/AddressPanel',
    'app/map/islandPanel/IslandPanel',
    'app/monitor/WeatherPanel',
    'app/monitor/EvacRecommendPanel',
    'app/monitor/ObservationPanel',
    'app/monitor/EmergencyPanel',
    'app/monitor/EarthquakePanel',
    'app/monitor/TsunamiPanel',
    'app/monitor/VolcanoPanel',
    'app/monitor/EvacorderPanel',
    'app/monitor/ShelterPanel',
    'app/monitor/DamagePanel',
    'app/monitor/TrafficPanel',
    'app/monitor/MapViewSelectPanel',
    'app/monitor/DangerPanel',
    'app/monitor/LifelinePanel',
    'app/monitor/UnreadInfoPanel',
    'app/map/TimestampsPanel',
], function (module, array, declare, lang,
    aspect, debounce, domClass, domStyle, all, template, on, json, topic,
    when, STORAGE_KEY, QUERY, Locator, IdisMap, UserInfo, Requester, LocalStorage,
    ConfirmDialog, IdisDialog, InfoDialog, MeasurePane, _PageBase, DrawUtil, MunicipalitySelectForm, MapToImage,
    config, AutoUpdatePane, LayerPane, DetailMap, BaseLayerPane, LegendPane, GridLegendPane,
    NoticePanel, PrintDialog, LayerStore, mapViewSelectCfg, DrawPanel, DamageReportIntegrator, DisasterInfo, registry, vectorTileDialog) {
    /**
     * 左側メニュー内の各画面のキー名とコンストラクター関数の対応
     * @type {Object<string,function>}
     */
    var stackClassMap = {
        layer: LayerPane
    };

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // 基本クラス
        baseClass: 'idis-Page idis-Page--map',

        // テンプレート文字列
        templateString: template,

        /**
         * 作図ダイアログに対する参照
         * @type {module:app/draw/DrawPanel~DrawPanel}
         * @private
         */
        _drawPanel: null,

        /**
         * 距離計測ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _measureDialog: null,

        /**
         * 被害統合機能
         */
        _damageReportIntegrator: null,

        /**
         * 背景地図ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _baseLayerDialog: null,


        /**
         * 市町村選択ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _municipalitySelectDialog: null,

        _vectorTileDialog: null,

        /**
         * 全画面モードフラグ
         */
        _fullScreenModeFlg: false,

        /**
         * popupイベントを保持する
         * @private
         */
        _downloadEvts: [],

        /**
         * 被害統合モードの状態を保持する
         */
        _isIntegrateMode: false,

        /**
         * モニター情報のタブを保持する
         */
        _monitorTab: 'weatherTab',

        /**
         * メッセージ表示用ダイアログ
         */
        infoDialog : null,

        // 各種Dialogの表示をコントロールするためのPub/Sub
        // すべての作図モードをOffにして、Dialogを閉じる
        DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
        DISABLE_MEASURE: '/app/view/page/MapPage::disableMeasure',
        DISABLE_PRINT: '/app/view/page/MapPage::disablePrint',
        DISABLE_DAMAGE_INTEGRATE: '/app/damage/integrate/DamageReportIntegrator::disable',
        // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
        DOWNLOAD_4IE: '/app/view/page/MapPage::download4IE',

        buildRendering: function () {
            this.inherited(arguments);
            this._downloadEvts = [];

            // 長崎県ではすべてのユーザで利用可能
            // システム管理者以外は「作図・レイヤー管理」「被害報告統合」を非表示
            //if (UserInfo.getRoleCd() !== 'R01001') {
            //    domStyle.set(this.drawPanelButton, 'display', 'none');
            //    domStyle.set(this.damageIntegrateButton, 'display', 'none');
            //}

            this.municipalitySelectForm = new MunicipalitySelectForm({}, this.municipalitySelectForm);
            this.municipalitySelectForm.startup();
        },

        /**
         * 左側メニュー内の各画面のキー名とインスタンスの対応
         * @type {Object<string,ContentPane>}
         */
        _stackMap: null,

        /**
         * URL更新前のクエリーの内容
         * @type {Object}
         */
        _lastQuery: null,

        /**
         * 自動更新clearTimeout用のID
         * @type {number}
         */
        _autoUpdateTimeoutId: null,

        /**
         * 位置情報
         * @type {Object}
         */
        _latlng: null,

        /**
         * 地図のベースになるレイヤー
         * @type {String}
         */
        MUNICIPALITY_LAYER_URL: '/data/master/municipalities.geojson',

        constructor: function () {
            this._stackMap = {};
            this._lastQuery = {};
        },

        startup: function () {
            this.inherited(arguments);

            // 被害統合モードをオフに設定する
            this._isIntegrateMode = false;
            // 地図と隣り合う領域の境界(splitter）を非表示にしておく
            domStyle.set(this.detailPane._splitterWidget.domNode, 'display', 'none');
            this.wrapper.layout();

            // マップを初期化
            this.initMap();

            // 検索ボタン
            this.own(topic.subscribe('idis/view/form/GeocodingForm::geocoded', lang.hitch(this, function (payload) {
                this._latlng = payload.latlng;
                this.map.setView(this._latlng, 11);
            })));
            // 市町選択ボタン
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function (payload) {
                    // 自動更新時は反応させない
                    if (payload.latlng) {
                        this._latlng = payload.latlng;
                        if (this.map) {
                            this.map.setView(this._latlng, payload.zoom);
                        }
                    }
                    this.updateLayer();
                })));
            // 島しょ部移動
            this.own(topic.subscribe('app/map/islandPanel/IslandPanel::moveToIsland',
                lang.hitch(this, function (payload) {
                    this.map.setView(payload.latlng, payload.zoom);
                })));
            // 災害切替
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function () {
                    this.updateLayer();
                })));
            // 被害統合のonSubmitまたはdeactivateがpubされて、被害統合機能がオフになったときに、ボタンの色を変更する。
            topic.subscribe('app/damage/integrate/DamageReportIntegrator::deactivated',
                lang.hitch(this, function () {
                    this.deactivateIntegrateDamageButton();
                }
                ));
            // テロップ設定の反映
            this.own(topic.subscribe(NoticePanel.TOPIC.CHANGE_SETTING, lang.hitch(this, function (payload) {
                domClass.toggle(this.noticeControlNode, 'is-bottom', payload.region === '1');
            })));
            // 詳細表示領域の状態をURLに従って更新
            this.updateDetailPane();
            // URLの変更を監視
            this.own(Locator.on('change', lang.hitch(this, this.onLocationChanged)));
            // 中心位置表示用ボタンの状態をトグル
            if (!LocalStorage.hasKey || !LocalStorage.hasKey(STORAGE_KEY.CENTER_MARK)) {
                LocalStorage.set(STORAGE_KEY.CENTER_MARK, '1');
            }

            var centerMarkValue = !!LocalStorage.get(STORAGE_KEY.CENTER_MARK);
            domClass.toggle(this.centerMarkToggleButton, 'is-checked', centerMarkValue);
            // LocalStorageの変更を監視
            this.own(LocalStorage.watch(STORAGE_KEY.CENTER_MARK, function (value) {
                domClass.toggle(this.centerMarkToggleButton, 'is-checked', !!value);
            }, this));
            // 自動更新設定を反映
            if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                this.reserveAutoUpdate();
            }
            // Web Storageの内容が変わった場合は反映
            this.own(LocalStorage.watch(STORAGE_KEY.AUTO_UPDATE_INTERVAL, this.reserveAutoUpdate, this));
            // 凡例
            this.own(topic.subscribe('app/monitor/ObservationPanel::showGridLegendLayer',
                lang.hitch(this, function () {
                    this.showGridLegendDialog();
                })));

            // モニター状況のタブ変更
            var self = this;
            var updateFlg = false;
            this.monitorTab.watch('selectedChildWidget', function (name, oval, nval) {
                self._monitorTab = nval.id;
                // 初心者モードから遷移した時の対応
                // 避難・避難所タブへ切り替え後、通常のレイヤーを表示しない
                // また、ログインユーザーの指定緊急避難場所・指定緊急避難所レイヤーのみを表示
                if (Locator.getQuery().layerType === 'allShelter' && self._monitorTab === 'evacShelterTab'){
                    var payload = {};
                    var id = [];
                    var layerQuery = Locator.getLayerQuery();
                    var municipalityCd = UserInfo.getSelectedMunicipalityCd();
                    if (municipalityCd === config.municInfo.otherRelationOrg) {
                        municipalityCd = config.municInfo.prefMunicCd;
                    }
                    let edesignatedEvacShId = Number(municipalityCd.substr(1,4));
                    let designatedEvacShId = edesignatedEvacShId + 1000;
                    id = [edesignatedEvacShId, designatedEvacShId];
                    payload = {layerQuery: layerQuery, layerId: id};
                    topic.publish(module.id + '::selected', payload);
                    return;
                }
                // 連続でタブ切替した時の対応
                // 一定時間待ってから最後に選択されたタブの処理を行う
                updateFlg = true;
                setTimeout(lang.hitch(this, function () {
                    if (updateFlg) {
                        updateFlg = false;
                        self.updateLayer();
                    }
                }), 500);

            });
            // 未読情報
            this.own(topic.subscribe('app/monitor/UnreadInfoPanel::showHideUnreadInfoPanel',
                lang.hitch(this, function (isOpen) {
                    if (isOpen) {
                        domStyle.set(this.unreadInfoPane.domNode, 'display', '');
                    } else {
                        domStyle.set(this.unreadInfoPane.domNode, 'display', 'none');
                    }
                    this.monitorInfoPane.resize();
                })));

            // 全画面表示通知
            // ヘッダー側の処理が終わってから呼び出される
            this.own(topic.subscribe('app/view/Header::fullscreen',
                lang.hitch(this, function (fullScreenModeFlg) {
                    this.toggleFullScreenMap(fullScreenModeFlg);
            })));
            this.own(topic.subscribe('app/view/TrainingHeader::fullscreen',
                lang.hitch(this, function (fullScreenModeFlg) {
                    this.toggleFullScreenMap(fullScreenModeFlg);
            })));
            this.own(topic.subscribe('app/view/BeginnerHeader::fullscreen',
                lang.hitch(this, function (fullScreenModeFlg) {
                    this.toggleFullScreenMap(fullScreenModeFlg);
            })));
            this.own(topic.subscribe('app/view/TrainingBeginnerHeader::fullscreen',
                lang.hitch(this, function (fullScreenModeFlg) {
                    this.toggleFullScreenMap(fullScreenModeFlg);
            })));

            // 未読情報初期表示
            topic.publish(module.id + '::initUnreadInfoPanel');

            // 表示情報に対応するインスタンスを生成
            var key = 'layer';
            var pane = this._stackMap[key] = new stackClassMap[key]();
            this.stackPane.addChild(pane);

            // IE対応
            // イベントを管理する人は各Mapに必要。
            // TODO pub/subの方がよいか？
            if (DrawUtil._isIE()) { DrawUtil._setPopupEvtForMap(this); }

             // 初心者モードからのアクセスでタブを切り替える場合
            if(Locator.getQuery().monitorTab){
                this._monitorTab = Locator.getQuery().monitorTab;
                var tabs = registry.byId("monitorTab");
                tabs.selectChild(this._monitorTab);
            }

            // 初心者モードからのアクセスで表示情報を切り替える場合
            if(Locator.getQuery().layerType){
                var payload = {};
                var id = [];
                var layerQuery = Locator.getLayerQuery();

                switch(Locator.getQuery().layerType){

                    case 'hazard':
                        id = [300];
                        payload = {layerQuery: layerQuery, layerId: id};
                        topic.publish(module.id + '::selected', payload);
                        break;

                    case 'allShelter':
                        var tabs = registry.byId("monitorTab");
                            tabs.selectChild("evacShelterTab");
                        break;
                }
            }

            // 背景地図に応じてベクトルタイル表示設定ボタンの表示を切り替える
            this.toggleVectorStyleButton();
        },

        // ウィジェットを破棄する
        destroy: function () {
            // 自動更新を解除
            clearTimeout(this._autoUpdateTimeoutId);
            this.inherited(arguments);
        },

        /**
         * マップ・オブジェクトにサイズ変更を通知する。
         */
        notifyResizeToMap: function () {
            // マップにサイズ変更を通知
            this.map.invalidateSize({ pan: false });
        },

        /**
         * URLが変更された際に反応する。
         */
        onLocationChanged: function () {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();
            // 詳細ペインの状態が変更された場合
            if (query[QUERY.DETAIL_LAYER_ID] !== this._lastQuery[QUERY.DETAIL_LAYER_ID]) {
                this.updateDetailPane();
            }
            // 詳細ペインの状態が変更された場合
            if (query[QUERY.BASE_LAYER_ID] !== this._lastQuery[QUERY.BASE_LAYER_ID]) {
                this.toggleVectorStyleButton();
            }
            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        /**
         * URLの状態に従い詳細領域を更新する。
         */
        updateDetailPane: function () {
            // URLから詳細表示用レイヤーIDを取得
            var layerId = Locator.getQuery()[QUERY.DETAIL_LAYER_ID];
            when(layerId && LayerStore.get(layerId), lang.hitch(this, function (item) {
                // 古いウィジェットを破棄
                array.forEach(this.detailContent.getChildren(), function (child) {
                    child.destroyRecursive();
                });
                // 表示対象が指定されているなら設置
                if (item) {
                    // 情報カテゴリー・コードに応じたウィジェットを取得
                    var widgetClass = DetailMap[item.infoCategoryCd];
                    // 詳細表示領域へ設置
                    this.detailTitle.innerHTML = item.name;
                    var widget = new widgetClass({ item: item });
                    this.detailContent.addChild(widget);
                }
                // 詳細表示領域の表示を調整
                this.detailPane.resize({ h: (item ? 250 : 0) });
                domStyle.set(this.detailPane._splitterWidget.domNode, 'display', item ? '' : 'none');
                // 再レイアウト
                this.wrapper.layout();
            }));
        },

        /**
         * 詳細領域を隠す。
         */
        hideDetailPane: function () {
            // URLから詳細レイヤーIDを削除する
            Locator.replaceState(QUERY.DETAIL_LAYER_ID, '');
        },

        /**
         * コントロール領域を隠す。
         */
        hideControlPane: function () {
            domStyle.set(this.monitorPane.domNode, 'display', '');
            domStyle.set(this.controlPane.domNode, 'display', 'none');
            // 全画面地図の時は領域自体も隠す
            if (this._fullScreenModeFlg) {
                domStyle.set(this.monitorControlPane.domNode, 'display', 'none');
            }
            // 再レイアウト
            this.wrapper.layout();
        },

        /**
         * 指定されたキーワードに対応するペインを表示する。
         * @param {string} key 画面のキー名
         * @param {function} classDef 画面を初期化する場合に使用するクラス
         */
        showControlPane: function (key) {
            // キーに対応するインスタンスを確認
            var pane = this._stackMap[key];
            // 既に存在する場合は選択する
            if (pane) {
                this.stackPane.selectChild(pane);
            } else {
                // キーに対応するクラスを引数無しで生成
                pane = this._stackMap[key] = new stackClassMap[key]();
                // selecChildはaddChildの内部で呼ばれる
                this.stackPane.addChild(pane);
            }
            // コントロール領域を表示
            domStyle.set(this.monitorControlPane.domNode, 'display', '');
            domStyle.set(this.monitorPane.domNode, 'display', 'none');
            domStyle.set(this.controlPane.domNode, 'display', '');
            // 再レイアウト
            this.wrapper.layout();
        },

        /**
         * 指定されたキーワードに対応するペインを左側で表示切替する。
         */
        toggleControlPane: function (key) {
            // コントロールを表示
            this.showControlPane(key);
        },

        /**
         * 表示情報ボタンクリック時に呼ばれる。
         * 表示情報レイヤーの表示状態を切り替える。
         */
        toggleLayerPane: function () {
            this.toggleControlPane('layer');
        },

        /**
         * 作図ダイアログを表示する
         */
        showDrawPanelDialog: function () {

            // 「広域印刷」「距離計測」を無効化
            this.switchMainMapDialogs('draw');

            if (!this._drawPanel) {
                this._drawPanel = new DrawPanel({
                    map: this.map,
                    'class': 'drawPanel-NonModal',
                    dispType: 'main'
                });
                // 画面が破棄された際に連れて行く
                this.own(this._drawPanel);
            }
            this._drawPanel.show();
        },

        /**
         * 凡例ダイアログを表示する。
         */
        toggleLegendDialog: function () {
            if (!this._legendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._legendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new LegendPane()
                });
                // 画面が破棄された際に連れて行く
                this.own(this._legendDialog);
            }
            if (this._legendDialog.open) {
                this._legendDialog.hide();
            } else {
                this._legendDialog.show();
            }
        },

        showGridLegendDialog: function () {
            if (!this._gridLegendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._gridLegendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new GridLegendPane()
                });
                // 画面が破棄された際に連れて行く
                this.own(this._gridLegendDialog);
            }
            if (this._gridLegendDialog.open) {
                this._gridLegendDialog.hide();
            } else {
                this._gridLegendDialog.show();
            }
        },

        /**
         * 距離計測ダイアログを表示する。
         */
        showMeasureDialog: function () {

            // 「作図」「広域印刷」を無効化
            this.switchMainMapDialogs('measure');

            // 初回にウィジェットを生成
            if (!this._measureDialog) {
                this._measureDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '距離計測',
                    content: new MeasurePane({ map: this.map })
                });
            }
            // 画面が破棄された際に連れて行く
            this.own(this._measureDialog);
            this._measureDialog.show();
        },

        /**
         * 印刷ダイアログを表示する
         */
        showPrintDialog: function () {

            // 「作図」「距離計測」を無効化
            this.switchMainMapDialogs('print');

            // 初回にウィジェットを生成
            if (!this._printDialog) {
                this._printDialog = new PrintDialog({
                    noUnderlay: true,
                    map: this.map,
                    layerControl: this.map.layerControl
                });
            }
            // 画面が破棄された際に連れて行く
            this.own(this._printDialog);
            this._printDialog.show();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function () {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({ map: this.map })
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
         * 中心表示の状態を更新する。
         */
        toggleCenterMark: function () {
            // 設定値をトグル
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, LocalStorage.get(STORAGE_KEY.CENTER_MARK) ? '' : '1');
        },

        /**
         * 経緯度グリッドの表示を切り替える。
         */
        toggleLatLngLayer: function () {
            var isActive = this.map.toggleLatLngLayer();
            domClass.toggle(this.latLngGridButton, 'is-checked', isActive);
        },

        /**
         * UTMグリッドの表示を切り替える。
         */
        toggleUtmLayers: function () {
            var isActive = this.map.toggleUtmLayers();
            domClass.toggle(this.utmGridButton, 'is-checked', isActive);
        },

        /**
         * 現在の設定に従って自動更新を予約する。
         */
        reserveAutoUpdate: function () {
            // 既存の更新予約を解除
            if (this._autoUpdateTimeoutId) {
                clearTimeout(this._autoUpdateTimeoutId);
            }
            // Web Storageの値を取得
            var interval = LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL);
            // ボタンのチェック状態を更新
            domClass.toggle(this.autoUpdateButton, 'is-checked', !!interval);
            if (!interval) {
                // 更新間隔が設定されていない場合は終了
                return;
            }
            // 更新間隔が設定されている場合は予約
            console.debug(module.id + '#reserveAutoUpdate: auto update in ' + interval + ' minutes');
            this._autoUpdateTimeoutId = setTimeout(lang.hitch(this, function () {
                // 表示情報ツリーを最新化
                console.debug(module.id + '#reserveAutoUpdate: update start');

                all([
                    // 表示情報ツリーを最新化
                    LayerStore.refreshAll(),
                    // 表示中のレイヤーを最新化
                    this.refreshAllLayers()
                ]).always(lang.hitch(this, function () {
                    // 成否に関わらず次の更新処理を予約
                    console.debug(module.id + '#reserveAutoUpdate: update end');

                    // 行政界を表示
                    this.putFirstLayer();
                    this.reserveAutoUpdate();
                }));
            }), parseFloat(interval) * 60 * 1000);
        },
        /**
         * 現在地図上に表示されている全てのレイヤーを更新する。
         */
        refreshAllLayers: function () {
            // 表示中のレイヤーを表示順の昇順（下にあるものが先）にくるよう並べたリストを取得
            // (表示順を遵守したいので、Locator.getLayerQuery()のキー取得とは別でlayerControlへのアクセスが必要)
            var layerIdList = this.map.layerControl.getLayersByDisplayOrder();
            console.debug(layerIdList);
            this._layerQuery = Locator.getLayerQuery();

            var layerPromiseList = array.map(layerIdList, function (layerId) {
                // レイヤーを一つずつ消去（非同期処理）
                this.map.layerControl.removeLayerById(layerId);
                // jsonファイルからレイヤー情報を新規作成する
                return Requester.get('/data/layer/tree/' + layerId + '.json')
                    .then(lang.hitch(this, function (layerInfo) {
                        var opacity = IdisMap.valueToOpacity(this._layerQuery[layerId]);
                        // レイヤーを一つずつ追加（同期処理）
                        return this.map.layerControl.addGeneralLayer(layerInfo, opacity);
                    })).otherwise(function (err) {
                        // 失敗した場合はエラー出力してnullを返す
                        console.error(err);
                        return null;
                    });
            }, this);

            return all(layerPromiseList);
        },

        /**
         * 表示情報の自動更新設定ダイアログを表示する。
         */
        onAutoUpdateButtonClick: function () {
            if (!this._autoUpdateDialog) {
                // 初回呼び出し時にインスタンス生成
                var autoUpdatePane = new AutoUpdatePane();
                var dialog = this._autoUpdateDialog = new ConfirmDialog({
                    title: '自動更新設定',
                    content: autoUpdatePane,
                    onOK: lang.hitch(this, function () {
                        // ダイアログのOKボタンをクリックした際の動作
                        if (autoUpdatePane.form.validate()) {
                            // Web Storageの値を更新
                            var interval = autoUpdatePane.form.get('value').interval;
                            LocalStorage.set(STORAGE_KEY.AUTO_UPDATE_INTERVAL, interval);
                            dialog.hide();
                        }
                    })
                });
                // 自動更新の値がWeb Storageに記録済みの場合はフォームへ反映
                if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                    autoUpdatePane.form.set('value', { interval: LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL) });
                }
                // 画面が破棄された際に連れて行く
                this.own(this._autoUpdateDialog);
            }
            if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                // 設定済みの場合は自動更新を解除
                LocalStorage.remove(STORAGE_KEY.AUTO_UPDATE_INTERVAL);
            } else {
                // 自動更新未設定の場合はダイアログを開く
                this._autoUpdateDialog.show();
            }
        },

        /**
         * 「作図」「広域印刷」「距離計測」機能を制御する
         * 各ボタンクリック時に発行し、選択された機能以外はダイアログを閉じ、機能をオフにする
         * @param {String} 'draw'（作図）, 'measure'（距離計測）, 'print'（広域印刷）
         */
        switchMainMapDialogs: function (id) {
            var dialogSet = {
                //key     : [closeTargetDialog, PubUrl]
                'draw': [this._drawPanel, this.DISABLE_DRAW],
                'measure': [this._measureDialog, this.DISABLE_MEASURE],
                'print': [this._printDialog, this.DISABLE_PRINT],
                'damage-integrate': [this._damageReportIntegrator, this.DISABLE_DAMAGE_INTEGRATE]
            };
            // インスタンスがあれば、すべて閉じる。
            Object.keys(dialogSet).forEach(function (key) {
                if (key === id) { return; }
                if (!!dialogSet[key][0]) {
                    topic.publish(dialogSet[key][1]);
                    if (key === 'measure') {
                        dialogSet[key][0].hide();
                    }
                }
            });
        },

        /**
         * 地図の全画面表示切替
         */
        toggleFullScreenMap: function (fullScreenModeFlg) {

            if (fullScreenModeFlg) {
                // 全画面表示
                this._fullScreenModeFlg = true;
                // 地図と隣り合う領域の境界(splitter）を非表示
                domStyle.set(this.monitorControlPane._splitterWidget.domNode, 'display', 'none');
                // モニタ状況領域を非表示
                domStyle.set(this.monitorControlPane.domNode, 'display', 'none');
                // ボタン表示切替
                domStyle.set(this.showFullScreenMapButton, 'display', 'none');
                domStyle.set(this.showMapforMonitorButton, 'display', '');
            } else {
                // 全画面表示解除
                this._fullScreenModeFlg = false;
                // 地図と隣り合う領域の境界(splitter）を表示
                domStyle.set(this.monitorControlPane._splitterWidget.domNode, 'display', '');
                // モニタ状況領域を表示
                domStyle.set(this.monitorControlPane.domNode, 'display', '');
                // ボタン表示切替
                domStyle.set(this.showFullScreenMapButton, 'display', '');
                domStyle.set(this.showMapforMonitorButton, 'display', 'none');
            }

            this.wrapper.resize();

            // マップにサイズ変更を通知
            debounce(this.notifyResizeToMap(), 10);
        },

        /**
         * 地図を全画面で表示する
         */
        showFullScreenMap: function () {
            // ヘッダーのリサイズのため処理通知
            topic.publish(module.id + '::fullscreen', true);
        },

        /**
         * 地図を通常画面で表示する
         */
        showMapforMonitor: function () {
            // ヘッダーのリサイズのため処理通知
            topic.publish(module.id + '::fullscreen', false);
        },

        /**
         * 地図に表示されている作図データをすべてTanJSONとして保存する。
         */
        downloadAllFeatures: function () {
            var geojson = { 'type': 'FeatureCollection' };
            var features = [];
            this.map.eachLayer(function (featureGroup) {
                if (featureGroup._layers && featureGroup.getLayers().length) {
                    featureGroup.toTanJSON().features.forEach(function (layer) {
                        features.push(layer);
                    });
                }
            });
            geojson.features = features;
            DrawUtil._doDownload(json.stringify(geojson));
        },

        showSubMenu: function () {
            domClass.add(this.container, 'is-subMenuShown');
        },

        hideSubMenu: function () {
            domClass.remove(this.container, 'is-subMenuShown');
        },

        initMap: function () {
            var municipalityCd = UserInfo.getSelectedMunicipalityCd();

            if (municipalityCd) {
                var self = this;
                Requester.get('/api/municipalities/' + municipalityCd, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {

                    self._latlng = {
                        latitude: item.latitude,
                        longitude: item.longitude
                    };

                    // マップの初期化
                    self.map = new IdisMap(self.id + '-map', {
                        config: self._latlng,
                        keyboard: false, // コメント時に+/-が使用できないため
                        touchExtend: false, // IE対応
                        minZoom: 5,
                        maxZoom: 18,
                        drawControlTooltips: false
                    });
                    // mapのdestroyはremoveでDojoと互換なのでownで消せる
                    self.own(self.map);

                    // 最後のリサイズより10ミリ秒待ってからマップへ通知
                    var notifier = debounce(lang.hitch(self, 'notifyResizeToMap'), 10);
                    self.own(aspect.after(self.detailPane, 'resize', notifier));

                    // 行政界を表示
                    self.putFirstLayer();
                }, function (error) {
                    console.log(error);
                });
            } else {
                // デフォルトの位置情報を設定
                this._latlng = config.map;

                // マップの初期化
                this.map = new IdisMap(this.id + '-map', {
                    config: this._latlng,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false, // IE対応
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                });
                // mapのdestroyはremoveでDojoと互換なのでownで消せる
                this.own(this.map);

                // 最後のリサイズより10ミリ秒待ってからマップへ通知
                var notifier = debounce(lang.hitch(this, 'notifyResizeToMap'), 10);
                this.own(aspect.after(this.detailPane, 'resize', notifier));
            }
        },

        /**
         * 地図レイヤーの上に行政界レイヤーを敷く
         * 県の境が見にくいため、自治体ごとのpolygonを置いている.
         * layeridの'999'は仮
         * TODO: 将来的にURLで制御させるか検討要
         */
        putFirstLayer: function () {
            return this.map.layerControl.addGeneralLayer({
                'id': 999,
                'name': '行政界',
                'parentId': 0,
                'disasterId': 21,
                'dispSeqNo': 2,
                'infoCategoryCd': 'D108',
                'pubStatus': '9',
                'deptCd': 'D01001',
                'sectCd': 'S01003',
                'minZoom': 8,
                'tileFlg': false,
                'jsonType': '1',
                'listType': '0',
                'layerUrl': this.MUNICIPALITY_LAYER_URL,
                'styleUrl': 'style.js',
                'hideFlg': false,
                'userId': 'U01001'
            }, 1);
        },

        activateIntegrateDamageButton: function () {
            domClass.add(this.damageIntegrateButton, 'is-Activated');
            this._isIntegrateMode = true;
        },

        deactivateIntegrateDamageButton: function () {
            domClass.remove(this.damageIntegrateButton, 'is-Activated');
            this._isIntegrateMode = false;
        },

        /**
         * 被害統合機能をactiveにする
         */
        activateIntegrateDamage: function () {
            // 他のダイアログを消す
            this.switchMainMapDialogs('damage-integrate');

            if (!this._damageReportIntegrator) {
                this._damageReportIntegrator = new DamageReportIntegrator(this.map);
                this.own(this._damageReportIntegrator);
            }
            this._damageReportIntegrator.activate();

            // ボタンの色を変える
            this.activateIntegrateDamageButton();
        },

        /**
         * 被害統合機能のオン・オフを制御する
         */
        toggleIntegrateDamage: function () {
            if (this._isIntegrateMode) {
                // 被害統合機能をdeactivateする
                topic.publish(this.DISABLE_DAMAGE_INTEGRATE);
                this._isIntegrateMode = false;
            } else {
                this.activateIntegrateDamage();
                this._isIntegrateMode = true;
            }
        },

        /**
         * 表示されている地図範囲を画像として保存
         */
        saveMapAsImage: function () {
            console.log('saveMapAsImage');

            // 背景が保存できない地図の場合はメッセージを表示して中断する
            var baselayerId = ''; // 現在表示している背景地図のID
            var blQuery = Locator.getQuery().bl;
            if(blQuery) {
                baselayerId = blQuery;
            } else {
                // URLにない場合は定義されている一覧の先頭になる
                var baseLayerConfig = config.map.userBaseLayers ? config.map.userBaseLayers[UserInfo.getRunningMode()] : undefined;
                baselayerId = baseLayerConfig[0];
            }
            // 地図保存が可能な背景地図のID
            var saveImageBaseLayersConfig = config.map.saveImageBaseLayers ? config.map.saveImageBaseLayers[UserInfo.getRunningMode()] : undefined;
            if (saveImageBaseLayersConfig.indexOf(baselayerId) === -1) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '背景地図に淡色、標準、写真のいずれかを選択してください。<br>'
                                    + 'それ以外の背景地図で保存する場合は、画面のキャプチャをご利用ください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            // TODO: 表示されている範囲ではなく範囲を指定する場合は、選択されている範囲のlatLngBoundsを渡す（未実装）
            this._mapToImage = new MapToImage(this.map, {
                latLngBounds: null
            });

            // canvas投影が完了したら呼ばれる
            // this.ownは不要
            on(this._mapToImage, 'finish', lang.hitch(this, function (e) {
                console.log('finish');
                // ダウンロードを実行する
                this._mapToImage.download(e.canvas);
            }));

            // 画像生成（canvas投影）開始
            this._mapToImage.start();
        },

        /**
         * レイヤー表示の更新
         */
        updateLayer: function () {
            var payload = {};
            var layerQuery = Locator.getLayerQuery();
            var id = [];
            var disasterId = DisasterInfo.getDisasterId();
            // var regionCd = UserInfo.getSelectedRegionCd();
            var municipalityCd = UserInfo.getSelectedMunicipalityCd();
            if (municipalityCd === config.municInfo.otherRelationOrg) {
                municipalityCd = config.municInfo.prefMunicCd;
            }

            switch (this._monitorTab) {
                // 気象観測
                case 'weatherTab':
                    id = mapViewSelectCfg.WEATHER_LAYER_ID;
                    payload = { layerQuery: layerQuery, layerId: id };
                    topic.publish(module.id + '::selected', payload);
                    break;
                // 地震・火山
                case 'disasterTab':
                    id = [61, 59, 60];
                    payload = { layerQuery: layerQuery, layerId: id };
                    console.log('地震・火山のpublish時のpayload');
                    console.log(payload);
                    topic.publish(module.id + '::selected', payload);
                    break;
                // 避難・避難所
                case 'evacShelterTab':
                    // サーバーから避難・避難所情報レイヤーIDを取得する
                    Requester.get('/api/monitor/evacshelter?municipalityCd=' + municipalityCd +
                        '&disasterId=' + disasterId, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function (item) {
                        payload = { layerQuery: layerQuery, layerId: item };
                        topic.publish(module.id + '::selected', payload);
                    }), function (error) {
                        console.debug(error);
                    });
                    break;
                // 被害情報
                case 'damageTab':
                    // サーバーから被害情報レイヤーIDを取得する
                    Requester.get('/api/monitor/damage?municipalityCd=' + municipalityCd +
                        '&disasterId=' + disasterId, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function (item) {
                        payload = { layerQuery: layerQuery, layerId: item };
                        topic.publish(module.id + '::selected', payload);
                    }), function (error) {
                        console.debug(error);
                    });
                    break;
                // 道路情報
                // case 'trafficTab':
                //     // サーバーから道路情報レイヤーIDを取得する
                //     Requester.get('/api/monitor/traffic?regionCd=' + regionCd + '&disasterId=' + disasterId, {
                //         headers: { 'Content-Type': 'application/json; charset=utf-8' },
                //         handleAs: 'json',
                //         preventCache: true
                //     }).then(lang.hitch(this, function (item) {
                //         payload = { layerQuery: layerQuery, layerId: item };
                //         topic.publish(module.id + '::selected', payload);
                //     }), function (error) {
                //         console.debug(error);
                //     });
                //     break;
            }
        },

        
        /**
         * 処理名：ベクトルタイルの表示設定ダイアログの表示・非表示の制御。
         * 処理概要：ベクトルタイルの表示設定ダイアログの表示・非表示を制御する。
         *
         * @param なし
         * @return なし
         */
        dispVectorStyleDialog: function() {
            if (!this._vectorTileDialog) {
                this._vectorTileDialog = new vectorTileDialog({
                    title:'ベクトルタイル設定',
                    _mapObj: this.map
                });
                // 画面が破棄された際に連れて行く
                this.own(this._vectorTileDialog);
            }
            if (this._vectorTileDialog.open) {
                this._vectorTileDialog.hide();
            } else {
                this._vectorTileDialog.show();
                this._vectorTileDialog.style.display = '';
            }
        },

        toggleVectorStyleButton: function() {
            var baseLayer = Locator.getQuery()[QUERY.BASE_LAYER_ID];
            if (baseLayer === 'GSI_VECTOR') {
                this.showVectorStyleButton.style.display = '';
            } else {
                this.showVectorStyleButton.style.display = 'none';
                if (this._vectorTileDialog && this._vectorTileDialog.open) {
                    this._vectorTileDialog.hide();

                    var styleDialog = this._vectorTileDialog.inner.styleDialog && this._vectorTileDialog.inner.styleDialog.getChildren()[0] ? 
                                        this._vectorTileDialog.inner.styleDialog.getChildren()[0] : null;
                    if (styleDialog && styleDialog.dialog && styleDialog.dialog.open) {
                        styleDialog.dialog.hide();
                    }
                }
            }
        }
    });
});

/**
 * 災害情報詳細/更新画面用モジュール。
 * @module app/timeline/TimelineChangeDialog
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/text!./templates/TimelineChangeDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/view/Loader',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput'
], function (module, json, declare, lang, array, topic, template, InfoDialog, _PageBase) {
    /**
     * 災害情報登録画面。
     * @class TimelineChangeDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/timeline/TimelineChange~TimelineChange# */ {
            // テンプレート文字列
            templateString: template,

            item: null,

            store: null,

            constructor: function () {
                this.inherited(arguments);
            },

            buildRendering: function () {
                this.inherited(arguments);
            },

            onSubmit: function () {
                topic.publish(module.id + '::update', {
                    data: this.changeTimelineForm.get('value')
                });
            }
        });
});

/**
 * お知らせ種別を選択するためのSelect要素
 * @module app/notice/view/form/NoticeTypeSelect
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dijit/form/Select',
    '../../NoticeTypeModel'
], function(module, array, declare, lang, Select, NoticeTypeModel) {
    /**
     * お知らせ登録ダイアログ内の画面。
     * @class NoticeTypeSelect
     * @extends module:dijit/form/Select~Select
     */
    return declare(module.id.replace(/\//g, '.'), Select,
        /** @lends module:app/notice/view/form/NoticeTypeSelect~NoticeTypeSelect# */ {
        // DOM構築後に呼ばれる
        buildRendering: function() {
            this.inherited(arguments);
            // widthの設定有無によりdijit/form/SelectでCSSクラスを切り替えているため、
            // CSSクラス付与でなく直接widthプロパティーを設定する必要がある
            this.set('style', {width: '11em'});
            // オプションの読み込み（初回は最新キャッシュから読む）
            NoticeTypeModel.load().then(lang.hitch(this, function(res) {
                // 表示に反映
                this.applyResponse(res);
                // 今後内容が変わった場合は設定し直す
                this.own(NoticeTypeModel.on('load', lang.hitch(this, 'applyResponse')));
            }));
        },

        /**
         * サーバーからのレスポンス結果を選択肢として反映する。
         * @param {Object} res
         * @param {Object[]} res.items
         */
        applyResponse: function(res) {
            // 古いオプションを削除
            this.removeOption(this.options);
            // デフォルトの空白オプションを先頭に追加
            this.addOption({label: '&nbsp;', value: ''});
            array.forEach(res.items, function(item) {
                this.addOption({
                    label: item.noticeTypeName,
                    value: item.noticeTypeId
                });
            }, this);
        }
    });
});

/**
 * 観測日時選択UI用モジュール。
 * @module app/observation/view/form/DateTimeSelect
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/DateTimeSelect.html',
    'dojo/topic',
    'idis/view/_IdisWidgetBase',
    './LatestData',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'dijit/form/Select'
], function(module, array, declare, lang, date, locale,
    domStyle, template, topic, _IdisWidgetBase, LatestData) {
    /**
     * トピック一覧
     */
    var TOPIC = {
        CHANGE_DATE_TIME: module.id + '::changeDateTime',
        // 最新観測日時を必要とする処理に利用
        LOADED: module.id + '::loaded',
        RELOADED: module.id + '::reloaded'
    };

    var LATEST_URL = {
        RAIN: '/data/rainfall',
        RIVER: '/data/river',
        TIDE: '/data/tide',
        LOCALWEATHER: '/data/localWeather'
    };

    /**
     * 観測日時選択セレクトボックスと時刻変更ボタン
     * 最新日時から10日前までの日付セレクトボックスと10分単位の時間セレクトボックス
     *
     * @class DateTimeSelect
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {string} [kwArgs.to='2017-10-10 00:00:00'] 最新日時
     * @param {string} [kwArgs.type='(rain|river|tide)'] 観測情報種別
     * @param {string} defaultValue='2017-10-10 00:00:00' デフォルト日時
     *                  指定しない場合は最新日時
     * @param {string} [kwArgs.mode='(10|60)'] 時間モード
     */
    var dateTimeSelect = declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'observation-DateTimeSelect',

        /**
         * 最新日時
         * デフォルトは現在日時の直近10分刻み時刻
         * @type {Date}
         */
        latestDateTime: null,

        /**
         * 最過去日時
         * 表示できる最も古い日時
         * @type {Date}
         */
        oldestDateTime: null,

        /**
         * 時間モード
         * @type {string} 10: 10分 60: 正時
         */
        mode: '10',

        /**
         * 前回変更通知した日時
         * @param {Object} [o.date='YYYY-MM-DD']
         * @param {Object} [o.time='HH:mm']
         */
        submittedDateTime: null,

        loaded: false,

        constructor: function(kwArgs) {
            this.submittedDateTime = {};

            kwArgs = kwArgs || {};

            this.type = kwArgs.type;

            // 最新の観測日時オブジェクト
            var url;
            switch (this.type) {
            case 'rain':
                url = LATEST_URL.RAIN + '/data.json';
                break;
            case 'river':
                url = LATEST_URL.RIVER + '/data.json';
                break;
            case 'tide':
                url = LATEST_URL.TIDE + '/data.json';
                break;
            case 'localWeather':
                url = LATEST_URL.LOCALWEATHER + '/data.json';
                break;
            default:
                // FIXME エラーにする
                break;
            }
            this.latestData = new LatestData({url: url});

            // デフォルト値の設定がある場合はセット
            if (!!kwArgs.defaultValue) {
                // 直近の10分に丸める
                this.defaultDateTime = this.roundTime(new Date(kwArgs.defaultValue.replace(/-/g, '/')));
            }
            // 時間モード
            if (!!kwArgs.mode && kwArgs.mode === '60') {
                this.mode = '60';
            }
        },

        buildRendering: function() {
            this.inherited(arguments);

            // セレクトボックスのスタイル設定
            this.dateSelect.set('style', {width: '10em'});
            this.timeSelect.set('style', {width: '8em'});
            // 時間モードに応じて3時間、10分前後進ボタンを非表示にする
            if (this.mode === '10') {
                // 10分モード
                // 3時間前進・後進のボタンを非表示にする
                domStyle.set(this.threeHoursForwardButton.domNode, 'display', 'none');
                domStyle.set(this.threeHoursBackButton.domNode, 'display', 'none');
            } else {
                // 正時モード
                // 10分前進・後進のボタンを表示にする
                domStyle.set(this.tenMinutesForwardButton.domNode, 'display', 'inline-block');
                domStyle.set(this.tenMinutesBackButton.domNode, 'display', 'inline-block');
            }

            this.build().then(lang.hitch(this,function(){
                this.loaded = true;
                // ロード完了を通知
                topic.publish(TOPIC.LOADED, {});
            }));
        },

        /**
         * 最新日時を取得してセレクトボックスを構築する
         * @param {boolean} true: 最新ボタンクリックによる再生成
         */
        build: function(rebuild) {
            // 最新日時を取得
            return this.latestData.load().then(lang.hitch(this, function(){
                // 最新日時
                this.latestDateTime = this.roundTime(this.latestData.getLatestDate());
                // 最過去日時（10日前の00時）
                // 時刻を00時に設定する
                // setHoursはエポックミリ秒で返るので変換
                this.oldestDateTime = new Date(date.add(this.latestDateTime, 'day', -10).setHours(0,0,0,0));

                // 日付のセレクトボックスを生成する
                this.buildDateSelect();
                // 時間のセレクトボックスを生成する
                this.buildTimeSelect();

                // 日時の変更を通知
                // 前回サブミット値に関係なく変更を通知
                topic.publish(TOPIC.CHANGE_DATE_TIME, {
                    date: this.dateSelect.get('value'),
                    time: this.timeSelect.get('value'),
                    latestClicked: (rebuild) ? rebuild : false
                });
                this.submittedDateTime = {
                    date: this.dateSelect.get('value'),
                    time: this.timeSelect.get('value')
                };
            }));
        },

        /**
         * 最新ボタンククリック時に日時のセレクトボックスを再作成する
         */
        rebuild: function() {
            // デフォルト値の設定を解除
            delete this.defaultDateTime;
            this.build(true);
        },

        /**
         * 直近の10分刻み時刻に丸める
         * 例えば、10時03分の場合は10時00分、10時13分の場合は10時10分に丸められる
         * @param {Date}
         * @returns {Date} 丸めた日時
         */
        roundTime: function(dt) {
            // 分を取得し、10で割り切れなければ、そのあまりでminuteをマイナスする
            var minutes = dt.getMinutes();
            var mod = minutes % 10;
            if (mod !== 0) {
                return date.add(dt, 'minute', -mod);
            }
            return dt;
        },

        /**
         * 時刻を丸めた値を返す
         * @param {Date}
         * @param {string} '10'：10分モード '60':正時モード
         * @return {string} 'HH:mm'
         */
        getRoundTimeValue: function(dt, mode) {
            if (mode === '60') {
                var targetDate = date.add(dt, 'minute', -dt.getMinutes());
                var timeValue = locale.format(targetDate, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                return timeValue;
            } else {
                return '';
            }
        },

        /**
         * 日付のセレクトボックスを生成する
         */
        buildDateSelect: function() {
            // 最新日時から10日前までの日付を算出し、セレクトボックスを生成
            var currentDate = this.latestDateTime;
            var counter = 0;
            this._dateOptions = [];

            var defaultValue;
            if (!!this.defaultDateTime) {
                // デフォルト値の指定がある場合
                defaultValue = locale.format(this.defaultDateTime, {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd'
                });
            } else {
                // デフォルト値の設定（デフォルトは最新日付）
                defaultValue = locale.format(currentDate, {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd'
                });
            }

            // 10日前まで繰り返す
            while (counter < 11) {
                // セレクトボックスの値
                var dateValue = locale.format(currentDate, {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd'
                });
                // セレクトボックスのラベル 「yyyy年MM月dd日」
                var dateLabel = locale.format(currentDate, {
                    selector: 'date',
                    formatLength: 'long',
                    locale: 'ja'
                });
                // デフォルト選択値の設定
                if (defaultValue === dateValue) {
                    this._dateOptions.push({ value: dateValue, label: dateLabel, selected: true });
                } else {
                    this._dateOptions.push({ value: dateValue, label: dateLabel });
                }

                // 1日前に更新
                currentDate = date.add(currentDate, 'day', -1);
                // カウンターを増やす
                counter++;
            }
            // セレクトボックスを設定
            this.dateSelect.set('options', this._dateOptions);
            this.dateSelect.startup();
        },

        /**
         * 時間のセレクトボックスを生成する
         */
        buildTimeSelect: function() {
            // ループの開始時刻（使いたいのは時刻なので日付は何でも良い。0時0分）
            var currentDate = new Date(1970, 1, 1, 0, 0);
            var counter = 0;
            this._timeOptions = [];
            // 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
            // 指定がない場合に利用
            var defaultTime = null;
            // 00時00分から23時50分まで生成するので、ループは144回
            while (counter < 144) {
                // セレクトボックスの値
                var timeValue = locale.format(currentDate, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                // セレクトボックスのラベル
                var timeLabel = locale.format(currentDate, {
                    selector: 'time',
                    // 24時間指定
                    timePattern: 'HH時mm分'
                });

                // 選択されている日付が最新観測日か否かで時間のセレクトボックスの項目を切り替え
                var selectedDate = new Date(this.dateSelect.get('value').replace(/-/g, '/'));
                // TODO 選択されている時間も取得して、日付切り替え時の処理と共通化する
                if (date.compare(selectedDate, this.latestDateTime, 'date') !== 0) {
                    // 最新観測日ではない場合
                    if (this.mode === '60') {
                        // 正時モードの場合、正時以外は選択不可
                        if (currentDate.getMinutes() !== 0) {
                            this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
                        } else {
                            this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
                            defaultTime = timeValue;
                        }
                    } else {
                        // 10分モードの場合
                        this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
                        defaultTime = timeValue;
                    }
                } else {
                    // 最新観測日の場合は、時刻を越えている場合は選択不可
                    if (date.compare(this.latestDateTime, currentDate, 'time') !== -1) {
                        // さらに正時モードの場合は、正時以外は選択不可
                        if (this.mode === '60') {
                            if (currentDate.getMinutes() !== 0) {
                                this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
                            } else {
                                this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
                                defaultTime = timeValue;
                            }
                        } else {
                            this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
                            defaultTime = timeValue;
                        }
                    } else {
                        this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
                    }
                }

                // 10分後に更新
                currentDate = date.add(currentDate, 'minute', 10);
                // カウンターを増やす
                counter++;
            }

            // デフォルト値の設定
            var defaultTimeValue;
            if (!!this.defaultDateTime) {
                // デフォルト値の指定がある場合
                defaultTimeValue = locale.format(this.defaultDateTime, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
            } else {
                defaultTimeValue = defaultTime;
            }
            array.some(this._timeOptions, function(option){
                if (option.value === defaultTimeValue) {
                    option.selected = true;
                    return false;
                }
            });

            // セレクトボックスを設定
            this.timeSelect.set('options', this._timeOptions);
            this.timeSelect.startup();
        },

        /**
         * 日付の選択が切り替わった際に呼ばれる
         * 最新観測日か否かによって、時間のセレクトボックスの表示を切り替える
         * @param {string} 'yyyy-MM-dd'
         */
        onChangeDateSelect: function(value) {
            // 選択日時
            var selectedDate = new Date(value.replace(/-/g, '/'));
            // 現在の時刻選択値（時間の判定だけに使うので日付は何でも良い）
            var selectedTime = new Date('1970/01/01 ' + this.timeSelect.get('value'));

            if (this.mode === '10') {
                // 10分モードの場合
                if (date.compare(selectedDate, this.latestDateTime, 'date') === 0) {
                    // 最新観測日の場合
                    // 最新観測時刻までのセレクトボックスを設定
                    array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
                        // 時刻判定だけなので日付は何でも良い
                        var targetDate = new Date('1970/01/01 ' + item.value);
                        if (date.compare(this.latestDateTime, targetDate, 'time') !== -1) {
                            this.timeSelect.options[index].disabled = false;
                        } else {
                            this.timeSelect.options[index].disabled = true;
                        }
                    }));
                    // 時刻の選択値
                    if ( date.compare(this.latestDateTime, selectedTime, 'time') === -1) {
                        // 現在の選択時間が最新の観測時間外であれば、最新の観測時間を設定
                        var timeValue = locale.format(this.latestDateTime, {
                            selector: 'time',
                            timePattern: 'HH:mm'
                        });
                        this.timeSelect.set('value', timeValue);
                    }
                    // 現在の選択時間が最新の観測時間以内であれば現在の選択値をそのまま利用
                } else {
                    // 最新観測日以外の場合は、全時刻をアクティブにする
                    // 時刻の選択値は変えない
                    array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
                        this.timeSelect.options[index].disabled = false;
                    }));
                }
            } else {
                // 正時モードの場合
                if (date.compare(selectedDate, this.latestDateTime, 'date') === 0) {
                    // 最新観測日の場合
                    // 最新観測時刻までのセレクトボックスを設定
                    array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
                        // 時刻判定だけなので日付は何でも良い
                        var targetDate = new Date('1970/01/01 ' + item.value);
                        if (date.compare(this.latestDateTime, targetDate, 'time') !== -1) {
                            // この中でさらに正時のみアクティブ
                            if ((index % 6) === 0) {
                                this.timeSelect.options[index].disabled = false;
                            } else {
                                this.timeSelect.options[index].disabled = true;
                            }
                        } else {
                            this.timeSelect.options[index].disabled = true;
                        }
                    }));
                    // 時刻の選択値を更新
                    var targetTime = null;
                    if ( date.compare(this.latestDateTime, selectedTime, 'time') === -1) {
                        // 現在の選択時間が最新の観測時間外であれば、最新の観測時間を取得
                        targetTime = this.latestDateTime;
                    } else {
                        targetTime = selectedTime;
                    }
                    // さらに正時に丸めてセレクトボックスの選択値を更新
                    this.timeSelect.set('value', this.getRoundTimeValue(targetTime, this.mode));

                } else {
                    // 最新観測日以外の場合は、全正時刻をアクティブにする
                    array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
                        if ((index % 6) === 0) {
                            this.timeSelect.options[index].disabled = false;
                        } else {
                            this.timeSelect.options[index].disabled = true;
                        }
                    }));
                    // 時刻が正時以外の場合は、直前の正時を設定
                    if (selectedTime.getMinutes() !== 0) {
                        // セレクトボックスの選択値を更新
                        this.timeSelect.set('value', this.getRoundTimeValue(selectedTime, this.mode));
                    }
                }
            }

            // 描画
            this.timeSelect.startup();

            // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみ変更イベントを発火する
            if (this.submittedDateTime.date !== this.dateSelect.get('value') ||
                this.submittedDateTime.time !== this.timeSelect.get('value')) {
                // 変更を通知
                topic.publish(TOPIC.CHANGE_DATE_TIME, {
                    date: this.dateSelect.get('value'),
                    time: this.timeSelect.get('value')
                });
                this.submittedDateTime.date = this.dateSelect.get('value');
                this.submittedDateTime.time = this.timeSelect.get('value');
            }
        },

        /**
         * 時間の選択が切り替わった際に呼ばれる
         * @param {string} 選択値 'HH:mm'
         */
        onChangeTimeSelect: function(/* value */) {
            // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみ変更イベントを発火する
            if (this.submittedDateTime.date !== this.dateSelect.get('value') ||
                this.submittedDateTime.time !== this.timeSelect.get('value')) {
                // 変更を通知
                topic.publish(TOPIC.CHANGE_DATE_TIME, {
                    date: this.dateSelect.get('value'),
                    time: this.timeSelect.get('value')
                });
                this.submittedDateTime.date = this.dateSelect.get('value');
                this.submittedDateTime.time = this.timeSelect.get('value');
            }
        },

        /**
         * 現在の観測日時から、指定されたミニッツ分戻す
         * @param {Number}
         * @private
         */
        _backTimestamp: function(minutes) {
            // 現在の選択日時を取得
            var nowDate =
                new Date(this.dateSelect.get('value').replace(/-/g, '/') + ' ' + this.timeSelect.get('value'));
            // 戻した後の日時
            var backDate = date.add(nowDate, 'minute', -minutes);

            // 最過去観測時刻を越えない場合は、前に戻る
            if (date.compare(backDate, this.oldestDateTime) !== -1) {
                var dateValue = locale.format(backDate, {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd'
                });
                var timeValue = locale.format(backDate, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                // 現在の選択値と異なる場合のみ選択値を更新
                // さもなくば、setした時点で変更イベントが走る
                if (this.dateSelect.get('value') !== dateValue) {
                    this.dateSelect.set('value', dateValue);
                }
                if (this.timeSelect.get('value') !== timeValue) {
                    this.timeSelect.set('value', timeValue);
                }
            }
        },

        /**
         * 現在の観測日時から、指定されたミニッツ分進める
         * @param {Number}
         * @private
         */
        _forwardTimestamp: function(minutes) {
            // 現在の選択日時を取得
            var nowDate =
                new Date(this.dateSelect.get('value').replace(/-/g, '/') + ' ' + this.timeSelect.get('value'));
            // 進めた後の日時
            var nextDate = date.add(nowDate, 'minute', minutes);

            // 最新観測時刻を超えない場合は、次に進む
            if (date.compare(nextDate, this.latestDateTime) !== 1) {
                var dateValue = locale.format(nextDate, {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd'
                });
                var timeValue = locale.format(nextDate, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                // 現在の選択値と異なる場合のみ選択値を更新
                // さもなくば、setした時点で変更イベントが走る
                if (this.dateSelect.get('value') !== dateValue) {
                    this.dateSelect.set('value', dateValue);
                }
                if (this.timeSelect.get('value') !== timeValue) {
                    this.timeSelect.set('value', timeValue);
                }
            }
        },

        /**
         * 「10分後」ボタンがクリックされた際に呼ばれる
         */
        onTenMinutesForwardButtonClick: function() {
            this._forwardTimestamp(10);
        },

        /**
         * 「10分前」ボタンがクリックされた際に呼ばれる
         */
        onTenMinutesBackButtonClick: function() {
            this._backTimestamp(10);
        },

        /**
         * 「1時間後」ボタンがクリックされた際に呼ばれる
         */
        onHourForwardButtonClick: function() {
            this._forwardTimestamp(60);
        },

        /**
         * 「1時間前」 ボタンがクリックされた際に呼ばれる
         */
        onHourBackButtonClick: function() {
            this._backTimestamp(60);
        },

        /**
         * 「3時間前」ボタンがクリックされた際に呼ばれる
         */
        onThreeHoursBackButtonClick: function() {
            this._backTimestamp(180);
        },

        /**
         * 「3時間後」ボタンがクリックされた際に呼ばれる
         */
        onThreeHoursForwardButtonClick: function() {
            this._forwardTimestamp(180);
        },

        /**
         * 時間モード切り替え
         */
        changeMode: function(mode) {
            this.mode = mode;

            if (this.mode === '60') {
                // 正時モードの場合

                // 時間のセレクトボックスで正時以外のものをdisabledにする
                array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
                    if ((index % 6) !== 0) {
                        this.timeSelect.options[index].disabled = true;
                    }
                }));
                // 描画
                this.timeSelect.startup();

                // 現在の選択時間が正時ではない場合、選択時間から一番近い前の正時を設定する
                // 時刻判定だけなので日付は何でも良い
                var selectedDate = new Date('1970/01/01 ' + this.timeSelect.get('value'));
                if (selectedDate.getMinutes() !== 0) {
                    // セレクトボックスの選択値を更新
                    this.timeSelect.set('value', this.getRoundTimeValue(selectedDate, this.mode));
                }

                // 10分前進・後進のボタンを非表示にする
                domStyle.set(this.tenMinutesForwardButton.domNode, 'display', 'none');
                domStyle.set(this.tenMinutesBackButton.domNode, 'display', 'none');
                // 3時間前進・後進のボタンを表示にする
                domStyle.set(this.threeHoursForwardButton.domNode, 'display', 'inline-block');
                domStyle.set(this.threeHoursBackButton.domNode, 'display', 'inline-block');

            } else {
                // 10分モードの場合

                // 時間のセレクトボックスを設定
                array.forEach(this.timeSelect.options, lang.hitch(this, function(item, index){
                    if (date.compare(
                        new Date(this.dateSelect.get('value').replace(/-/g, '/')),
                        this.latestDateTime, 'date') === 0) {
                        // 観測日付が選択されている場合
                        if (date.compare(
                            this.latestDateTime, new Date('1970/01/01 ' + item.value), 'time') !== -1) {
                            this.timeSelect.options[index].disabled = false;
                        } else {
                            this.timeSelect.options[index].disabled = true;
                        }
                    } else {
                        // 観測日付以外は全てアクティブ
                        this.timeSelect.options[index].disabled = false;
                    }

                }));
                // 描画
                this.timeSelect.startup();

                // 10分前進・後進のボタンを表示にする
                domStyle.set(this.tenMinutesForwardButton.domNode, 'display', 'inline-block');
                domStyle.set(this.tenMinutesBackButton.domNode, 'display', 'inline-block');
                // 3時間前進・後進のボタンを非表示にする
                domStyle.set(this.threeHoursForwardButton.domNode, 'display', 'none');
                domStyle.set(this.threeHoursBackButton.domNode, 'display', 'none');
            }
        },

        /**
         * 最新ボタンがクリックされた際に呼ばれる
         */
        onLatestButtonClick: function() {
            // セレクトボックスを再構築する
            this.rebuild();
        },

        /**
         * 選択されている日時を返す
         * @returns {string} 'yyyy-MM-dd HH:mm'
         */
        getSelectedDateTime: function() {
            return this.dateSelect.get('value') + ' ' + this.timeSelect.get('value');
        },

        setSelectedDateTime: function(dateValue, timeValue) {
            if (!!dateValue) {
                this.dateSelect.set('value', dateValue);
            }
            if (!!timeValue) {
                this.timeSelect.set('value', timeValue);
            }
        }
    });

    // トピックを公開
    dateTimeSelect.TOPIC = TOPIC;

    return dateTimeSelect;
});


/**
 * 発令判断支援ルールセット詳細画面用モジュール。
 * @module app/evacrecommendRule/EvacRecommendRuleDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/control/Locator',
    'idis/service/Requester',
    './_EvacRecommendRuleRegisterPageBase',
], function (module, array, declare, lang, domStyle,
    Locator, Requester,
    _EvacRecommendRuleRegisterPageBase) {
    /**
     * 発令判断支援ルールセット詳細画面
     * @class EvacRecommendRuleDetailPage
     * @extends module:app/evacrecommendRule/_EvacRecommendRuleRegisterPageBase~_EvacRecommendRuleRegisterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacRecommendRuleRegisterPageBase,
    /** @lends module:app/evacrecommendRule/EvacRecommendRuleDetailPage~EvacRecommendRuleDetailPage# */ {
        // ルールセットID
        _ruleSetId: null,

        constructor: function () {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this._ruleSetId = Locator.getQuery().ruleSetId;
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function () {
            this.inherited(arguments);
            // タイトル
            this.title.innerHTML = "詳細";
            // ボタン表示制御
            this.initButtonArea();
            // 避難情報詳細ページを初期化する。
            this.initDetailPage();
        },

        /**
         * 避難情報詳細ページを初期表示する。
         */
        initDetailPage: function () {
             // 詳細情報を取得
            Requester.get('/api/evacrecommend/ruleset/' + this._ruleSetId).then(lang.hitch(this, function(data) {
                // 地図の初期位置を設定する。
                this.initLatlng(data.municipalityCd);
                // 気象エリアを設定する。
                this.setKisyoAreaSelector(data.municipalityCd);
                // チェックを入れる
                data.selectedKisyo = data.kisyoWarnings[0].length > 0 ? 'on' : '';
                data.selectedFlood = data.floodRiverList[0].length > 0 ? 'on' : '';
                data.selectedSediment = data.sedimentLevel?.length > 0 ? 'on' : '';
                data.selectedFloodRiskInformationLevel = data.floodRiskInformationLevel?.length > 0 ? 'on' : '';
                data.selectedHeavyRainRiskInfoLevel = data.heavyRainRiskInfoLevel?.length > 0 ? 'on' : '';
                data.selectedEarthquake = data.earthquake?.length > 0 ? 'on' : '';
                data.selectedTsunami = data.tsunami?.length > 0 ? 'on' : '';
                data.selectedRainfall = data.rainfallObsList[0].length > 0 ? 'on' : '';
                data.selectedRiverLevel = data.riverLevelObsList[0].length > 0 ? 'on' : '';
                data.selectedCriticalManageRiverLevel = data.crisisManageRiverLevelObsList[0].length > 0 ? 'on' : '';
                data.landslide = data.landslide === "1" ? 'on' : '';
                data.heavyrain = data.heavyrain === "1" ? 'on' : '';
                // 入力フォームにセット
                this.form.set('value', data);
                // チェックボックス状況に応じて入力可否切り替え
                this.setInputDisability();

                // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
                this.initTree()
                    .then(lang.hitch(this, 'showSelectDistrictLayer'))
                    .then(lang.hitch(this, function () {
                        // 準備が整ったら選択中の各地区をチェック状態にする
                        this._selectDistricts(data.districtCdList);
                    }));
            }), function(err) {
                // 失敗時
                this.chain.infoError(err);
            });
        },

        /**
         * 指定された地区コード一覧をチェック状態にする。
         * @param {string[]} districtCdList 地区コード一覧
         */
        _selectDistricts: function (districtCdList) {
            array.forEach(districtCdList, function (distCd) {
                this.tree.model.store.get(distCd).then(lang.hitch(this, function (item) {
                    if (item) {
                        this.tree.setChecked(item, true);
                    }
                }));
            }, this);
        },

        /**
         * 詳細ボタン表示切り替え
         */
        initButtonArea: function () {
            domStyle.set(this.registerButtonArea, 'display', 'none');
            domStyle.set(this.updButtonArea, 'display', '');
        },
    });
});

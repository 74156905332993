/**
 * 職員招集メール一覧グリッド
 * @module app/convocation/view/mail/NotificationListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dgrid/Selector',
    'dijit/form/CheckBox'
], function(module, declare, IdisGrid, helper, Selector) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,

        columns: [
            helper.column('groupName', 'グループ', {sortable: false}),
            helper.column('distributionHistDetailId', '防災配信履歴詳細ID', {sortable: false}),
            helper.column('employeeCd', '職員番号', {sortable: false}),
            helper.column('name', '氏名', {sortable: false}),
            helper.column('phoneNum', '電話番号', {sortable: false}),
            helper.column('distEmailAddress', 'メールアドレス', {sortable: false})
        ]
    });
});

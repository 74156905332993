/**
 * 各エリアコード
 * @module app/model/KisyoAreaCode
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        idProperty: 'areaCd',
        data: [
            {
                "areaCd": "4220100",
                "name": "長崎市",
                "municipalityCd": "422011"
            }, {
                "areaCd": "4220201",
                "name": "佐世保市(宇久地域を除く)",
                "municipalityCd": "422029"
            }, {
                "areaCd": "4220202",
                "name": "佐世保市(宇久地域)",
                "municipalityCd": "422029"
            }, {
                "areaCd": "4220300",
                "name": "島原市",
                "municipalityCd": "422037"
            }, {
                "areaCd": "4220400",
                "name": "諫早市",
                "municipalityCd": "422045"
            }, {
                "areaCd": "4220500",
                "name": "大村市",
                "municipalityCd": "422053"
            }, {
                "areaCd": "4220700",
                "name": "平戸市",
                "municipalityCd": "422070"
            }, {
                "areaCd": "4220800",
                "name": "松浦市",
                "municipalityCd": "422088"
            }, {
                "areaCd": "4220902",
                "name": "対馬市(上対馬)",
                "municipalityCd": "422096"
            }, {
                "areaCd": "4220901",
                "name": "対馬市(下対馬)",
                "municipalityCd": "422096"
            }, {
                "areaCd": "4221000",
                "name": "壱岐市",
                "municipalityCd": "422100"
            }, {
                "areaCd": "4221100",
                "name": "五島市",
                "municipalityCd": "422118"
            }, {
                "areaCd": "4221201",
                "name": "西海市(江島・平島を除く)",
                "municipalityCd": "422126"
            }, {
                "areaCd": "4221202",
                "name": "西海市(江島・平島)",
                "municipalityCd": "422126"
            }, {
                "areaCd": "4221300",
                "name": "雲仙市",
                "municipalityCd": "422134"
            }, {
                "areaCd": "4221400",
                "name": "南島原市",
                "municipalityCd": "422142"
            }, {
                "areaCd": "4230700",
                "name": "長与町",
                "municipalityCd": "423076"
            }, {
                "areaCd": "4230800",
                "name": "時津町",
                "municipalityCd": "423084"
            }, {
                "areaCd": "4232100",
                "name": "東彼杵町",
                "municipalityCd": "423211"
            }, {
                "areaCd": "4232200",
                "name": "川棚町",
                "municipalityCd": "423220"
            }, {
                "areaCd": "4232300",
                "name": "波佐見町",
                "municipalityCd": "423238"
            }, {
                "areaCd": "4238300",
                "name": "小値賀町",
                "municipalityCd": "423831"
            }, {
                "areaCd": "4239100",
                "name": "佐々町",
                "municipalityCd": "423912"
            }, {
                "areaCd": "4241100",
                "name": "新上五島町",
                "municipalityCd": "424111"
            }
        ]
    });
});

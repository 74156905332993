/**
 * 配信ダイアログ用ベース。
 * @module app/provide/_ProvideRegisterBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ProvideGeneralRegisterDialog.html',
    'dojo/topic',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, declare, lang, template, topic, _PageBase,
    Requester, Loader, DialogChain, UserInfo) {
    /**
     * お知らせ登録ダイアログ内の画面。
     * @class _ProvideRegisterBase
     * @extends module:app/provide/_ProvideRegisterBase~_ProvideRegisterBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/ProvideRegisterPrefDialog~ProvideRegisterPrefDialog# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--provide idis-page--provide--dialog',

        // 情報種別の定数値 情報種別毎にダイアログを用意する。
        EVAC_ORDER_CONTENT_TYPE : '01',
        SHELTER_CONTENT_TYPE : '02',
        PREVENTION_CONTENT_TYPE : '03',
        GENERAL_CONTENT_TYPE : '04',
        URGENT_MAIL_CONTENT_TYPE : '05',
        TMP_FACILITY_CONTENT_TYPE : '06',

        // 親ページを格納する
        _parent: null,

        // フォーム要素がセットされるストア
        store: null,

        // ダイアログチェイン
        chain: null,

        // ダイアログを開く際に設定する。
        _contentType: null,

        // ダイアログで保持する市町村コード
        _municipalityCd: null,

        _twitterFlg: false,
        _urgentMailFlg: false,
        _yahooFlg: false,


        constructor: function() {
            // ユーザ情報から市町村コードを設定する。
            this._municipalityCd = UserInfo.getMunicipalityCds;
            // 情報配信ダイアログページのダイアログチェインを取得する。
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        // 各画面に応じてオーバーライドして使う
        // ダイアログを呼び出す前に、ダイアログに設定したい項目{}でを渡す。
        initDialog: function(){
        },

        // 各画面に応じてオーバーライドして使う。onSubmitイベントに紐づけられている。
        // 値を取得してformにセットする。
        onSubmit: function(){
            var form = [];
            try{
                if (this.regForm.validate()){
                    // baseクラスでリクエストを投げる
                    this._postRequest(form);
                }
            } catch(e) {
                console.error(e);
            }
        },

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        _postRequest: function(store, form) {
            // 訂正確認画面ダイアログを表示する。
            this.chain._confirmFor('配信', lang.hitch(this,function(chain) {
                // 処理と共にローディング表示
                Loader.wait(store.add(form)).then(lang.hitch(this,function() {
                    // 完了ダイアログを表示
                    chain.infoComplete(lang.hitch(this, function() {
                        this._closeDialog();
                        this.regForm.reset();
                        topic.publish(module.id + '::added', '');
                        return '';
                    }));
                }, function(err) {
                        // 失敗時にはエラーダイアログを表示
                        chain.infoError(err, function(){
                            // ダイアログを閉じる。
                            this._closeDialog();
                        });
                        console.debug(err);
                }));
            }));
            // デフォルトのonSubmitイベントをキャンセルする。
            return false;
        },

        // ダイアログからサブミットが完了した後に呼ばれる。
        // ダイアログ及びchainされているダイアログを閉じて、ダイアログに設定されていた値をリセットする。
        _closeDialog: function(){
            // 確認ダイアログを閉じる
            this.chain.hide();
            // 親のダイアログを閉じる。
            this.getParent().hide();
        },

        // コード値から種別名を返す
        // 種別名
        getCategoryName: function(cd){
            var categoryName = {
                '01' : '避難情報',
                '02' : '開設避難所',
                '03' : '災害対策本部設置状況',
                '04' : 'お知らせ情報',
                '05' : '緊急速報メール情報',
                '06' : '開設一時滞在施設'
            };
            return categoryName[cd];
        },
        // 避難情報 - 発令解除区分
        getEvacuateIssueName: function(cd){
            var evacuateIssueName = {
                '01': '発令',
                '02': '発令',
                '03': '発令',
                '04': '発令',
                '05': '解除'
            };
            return evacuateIssueName[cd];
        },
        /**
         * 自分の市町の配信設定に関する情報を取得する
         */
        getSendingSetting: function(municipalityCd) {
            return Requester.get('/api/municipalities/sendingsetting?municipalityCds=' + municipalityCd)
            .then(lang.hitch(this, function(infoList) {
                if(infoList.length !== 0) {
                    // 一つの市町についてしかクエリしていないので、リストの長さは１であり、
                    // １つ目の結果が自分の市町に関する情報である
                    var setting = infoList[0];
                    this._urgentMailFlg = setting.urgentMailFlg;
                    this._twitterFlg = setting.twitterFlg;
                    this._yahooFlg = setting.yahooFlg;
                }
            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
                throw new Error('市町配信設定の情報取得に失敗しました');
            }));
        }
    });
});

/**
 * お知らせ情報グリッドの種別列定義。
 * @module app/notice/view/NoticeTypeColumn
 */
define([
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/view/LazyNode',
    '../NoticeTypeModel'
], function(declare, lang, domStyle, LazyNode, NoticeTypeModel) {
    /**
     * お知らせ表示を行うためのセル
     */
    var NoticeTypeCell = declare(LazyNode, {
        /**
         * 表示対象種別の識別子
         * @type {identifier}
         */
        noticeTypeId: null,

        /**
         * レスポンス結果を反映する。
         * @param {Object} res レスポンス結果
         * @param {Object[]} res.items 種別一覧
         */
        _parseResult: function(res) {
            if (this._destroyed || !this.domNode) {
                return;
            }
            var items = res.items;
            // 種別一覧に識別子の一致するものがあれば名前を反映
            for (var i = 0; i < items.length; i++) {
                if (items[i].noticeTypeId === this.noticeTypeId) {
                    domStyle.set(this.domNode, 'color', items[i].color || '');
                    this.domNode.innerHTML = items[i].noticeTypeName;
                    return ;
                }
            }
            // 見つからなかった場合
            domStyle.set(this.domNode, 'color', '');
            this.domNode.innerHTML = '?';
        },

        // promise解決時に呼ばれる
        onResolve: function(res) {
            // 結果をセルに反映
            this._parseResult(res);
            // 一覧が更新された場合は反映する
            this.own(NoticeTypeModel.on('load', lang.hitch(this, '_parseResult')));
        }
    });

    return {
        field: 'noticeTypeId',
        label: '種別',
        renderCell: function(item) {
            var lazyNode = new NoticeTypeCell({
                noticeTypeId: item.noticeTypeId,
                promise: NoticeTypeModel.load()
            });
            return lazyNode.domNode;
        }
    };
});

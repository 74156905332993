/**
 * 潮位詳細情報のグラフ
 * @module app/tide/chart/TideWindChart
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/action2d/Magnify',
    'dojox/charting/Chart',
    'dojox/charting/plot2d/Lines',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/widget/SelectableLegend',
    'dojox/gfx/matrix',
    'idis/util/DateUtils',
    'app/view/chart/ChartConfig',
    'app/view/chart/ChartCommon',
    //
    'dojox/charting/axis2d/Default'
], function(
    module, array, declare, lang,
    Tooltip, Magnify, Chart, Lines, Theme, SelectableLegend, Matrix,
    DateUtils, ChartConfig, ChartCommon
) {

    /**
     * 潮位詳細画面
     * @class TideWindChart
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:app/dam/chart/TideWindChart~TideWindChart# */
    {
        title: '潮位',

        /**
         * Y軸最大値・最小限
         */
        maxTideLevelAxis: 0, // 潮位最大値
        maxWindSpeedAxis: 0, // 風速最大値
        minTideLevelAxis: 0, // 潮位最小値

        legend: {},

        /**
         * グラフのノードを生成する
         * @param {*} node グラフを入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} legendNode 凡例を入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} options.mode 検索条件の時間モード
         * @param {*} options.data 詳細GridのCollectionのデータ
         */
        create: function(node, legendNode, options) {
            var modeLabel = '';
            if(options){
                // 引数を変数にセット
                lang.mixin(this, options);
                // Gridとは逆順に並べる必要がある
                this.data.reverse();

                // 再作成の場合、表を破棄
                ChartCommon.destroyChart(this.chart);

                // タイトルをセット
                modeLabel = this.isTpMode ? 'TP' : 'CDL';
                modeLabel += {
                    60 : '時間',
                    30 : '30分',
                    10 : '10分'
                }[this.mode];

                // Y軸の最大最小値をセット
                this.setMaxMinYAxis();
            }

            this.chart = new Chart(node, {
                title: modeLabel + this.title,
                titlePos: 'top',
                titleGap: 10
            });

            // グラフのデザインテーマ, 他のグラフ群と合わせる
            this.chart.setTheme(Theme);

            // グラフの背景に描画される線の種類を決定。
            ChartCommon.addGridPlot(this.chart);

            this.chart.addAxis('y', {
                min: this.minTideLevelAxis - 0.5,
                max: this.maxTideLevelAxis + 0.5,
                vertical: true,
                fixLower: 'major',
                fixUpper: 'minor'
            });

            // 時間軸
            ChartCommon.addDataTimestampXAxis(this.chart, this.data);

            // 潮位
            this.chart.addPlot('tide', {
                type: Lines,
                markers: true,
                labels: true,
                vAxis: 'y'
            });

            // TP 潮位
            this.chart.addSeries('潮位[m]',
            ChartCommon.getChartData(this.data,
                        this.isTpMode ? 'tideLevelTp' : 'tideLevel'), {
                plot: 'tide',
                stroke: {
                    color: 'green'
                },
                fill: 'green',
                marker: ChartConfig.MARKER_SHAPE
            });
            this.tideLevelTpTooltip = new Tooltip(this.chart, 'tide');
            this.tideLevelTpMagnify = new Magnify(this.chart, 'tide');

            this.chart.addSeries('天文潮位[m]',
                ChartCommon.getChartData(this.data,
                        this.isTpMode ?'tideLevelAstronomicalTp' : 'tideLevelAstronomical'), {
                plot: 'tide',
                stroke: {
                    color: 'lime'
                },
                fill: 'lime',
                marker: ChartConfig.MARKER_SHAPE
            });
            this.tideLevelAstronomicalTooltip = new Tooltip(this.chart, 'tide');
            this.tideLevelAstronomicalMagnify = new Magnify(this.chart, 'tide');

            if(!this.data || this.data[0].tideLevelReportDl){
                // 基準値
                this.chart.addPlot('excess', {
                    type: Lines,
                    markers: false,
                    labels: true,
                    vAxis: 'y'
                });

                this.chart.addSeries('通報潮位[m]',
                    ChartCommon.getChartData(this.data, 'tideLevelReportDl'), {
                        plot: 'excess',
                        stroke: {
                            color: '#FFFF00'
                        }
                });
                this.tideLevelReportTooltip = new Tooltip(this.chart, 'tide');

                if(!this.data || this.data[0].tideLevelAlertDl){
                    this.chart.addSeries('警戒潮位[m]',
                        ChartCommon.getChartData(this.data, 'tideLevelAlertDl'), {
                            plot: 'excess',
                            stroke: {
                                color: '#ff00ff'
                            }
                    });
                    this.tideLevelAlertTooltip = new Tooltip(this.chart, 'tide');
                }
            }

            this.chart.render();

            // プロットの凡例を表示
            // 初回起動時のみ生成
            if (!this.legend.id) {
                this.legend = new SelectableLegend({chart: this.chart}, legendNode);
            }
            this.legend.set('chart', this.chart);
            this.legend.refresh();
            ChartCommon.setAxisTitle(this.chart, 'y', '潮位[m]');
        },

        /**
         * Y軸最大最小値をセット
         */
        setMaxMinYAxis: function(){
            // 潮位・潮位TP・天文潮位のMaxをセット
            this.maxTideLevelAxis = ChartCommon.getMaxData(this.data, 'tideLevel', this.maxTideLevelAxis);
            this.maxTideLevelAxis = ChartCommon.getMaxData(this.data, 'tideLevelTp', this.maxTideLevelAxis);
            this.maxTideLevelAxis = ChartCommon.getMaxData(this.data, 'tideLevelAstronomical', this.maxTideLevelAxis);

            // 基準値とも比較
            if(this.data[0].tideLevelAlertDl) {
                this.maxTideLevelAxis = this.data[0].tideLevelAlertDl > this.maxTideLevelAxis ?
                    this.data[0].tideLevelAlertDl : this.maxTideLevelAxis;
            }else if(this.data[0].tideLevelReportDl) {
                this.maxTideLevelAxis = this.data[0].tideLevelReportDl > this.maxTideLevelAxis ?
                    this.data[0].tideLevelReportDl : this.maxTideLevelAxis;
            }
            this.maxWindSpeedAxis = ChartCommon.getMaxData(this.data, 'windSpeed', this.maxWindSpeedAxis);

            // 潮位最小値をセット
            this.minTideLevelAxis = ChartCommon.getMinData(this.data, 'tideLevel', this.minTideLevelAxis);
            this.minTideLevelAxis = ChartCommon.getMinData(this.data, 'tideLevelTp', this.minTideLevelAxis);
            this.minTideLevelAxis = ChartCommon.getMinData(this.data, 'tideLevelAstronomical', this.minTideLevelAxis);
        }
    });
});

/**
* 避難所の添付ファイル登録用の基底モジュール。
* @module app/shelter/_ShelterPageBase
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/promise/all',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'leaflet',
    'exif-js/exif',
    './consts/CrowdedStatus',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup'
], function (module, LayerStore, declare, lang, locale, all, domClass, domConstruct, domGeometry, domStyle,
    json, on, iframe, topic, winUtils, Rest, Menu, MenuItem, popup, TooltipDialog, registry,
    Locator, Router, IdisMap, UserInfo, DisasterInfo, GeoService, Requester, FilesUtils,
    InfoDialog, Loader, _PageBase, leaflet, exif, CrowdedStatus) {


    /**
     * 被害状況新規登録・詳細・続報登録画面画面。
     * @class _DamageReportPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/damage/_DamageReportPageBase~_DamageReportPageBase# */ {

            /**
            * popupイベントを保持する
            * @private
            */
            _downloadEvts: [],

            // 災害シミュレーション用
            _scenarioId: null,
            _simulateFlg: false,
            _eventId: null,
            _eventType: null,
            _content: null,
            _timing: null,
            _statusName: null,
            _facilityName: null,

            buildRendering: function () {
                this.inherited(arguments);
                this._municipalityCd = UserInfo.getMunicipalityCds()[0];
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);

                // 災害シミュレーションの場合添付ファイルを不可視にする
                if (this._simulateFlg) {
                    domStyle.set(this.shelterAtcFileItem, 'display', 'none');
                }
            },

            sendLalert: function (form) {
                if (!form) {
                    return;
                }
                var jsonStr = json.stringify(form);
                return Requester.post('/api/lalert/send', {
                    data: jsonStr,
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (result) {
                    console.info('Lアラート配信に成功しました：');
                    console.info(result);
                }), lang.hitch(this, function (error) {
                    this.chain.infoError(error);
                    console.error(error);
                    throw new Error('Lアラート配信失敗  data:' + form);
                }));
            },

            // long型のtimestampを、Lアラート連携時に使って要る形式に変える
            formatDate: function (longTime) {
                var date = new Date(longTime);
                return this.formatDateFromObj(date);
            },

            formatDateFromObj: function (date) {
                return date.getFullYear() + '-' + this._zeroPadding(date.getMonth() + 1) + '-' +
                    this._zeroPadding(date.getDate()) + 'T' + this._zeroPadding(date.getHours()) + ':' +
                    this._zeroPadding(date.getMinutes()) + ':' + '00.000+09';
            },

            onChangeStatus: function () {
                var status = this.form.get('value').status;
                this.displayTimeAreasByStatus(status);
                // 「開設準備中」の時は混雑状況はセットしない
                var isPreparing = status === '0';
                domStyle.set(this.crowdedStatusArea, 'display', isPreparing ? 'none' : '');
                this.crowdedStatus.set('value', isPreparing ? '' :
                    this.crowdedStatus.get('value') || CrowdedStatus.UNDER_CAPACITY);
            },

            displayTimeAreasByStatus: function (status) {
                if (status === '0') {
                    domStyle.set(this.shelterStartTimeArea, 'display', 'none');
                } else {
                    domStyle.set(this.shelterStartTimeArea, 'display', '');
                }

                if (status === '9') {
                    domStyle.set(this.shelterEndTimeArea, 'display', '');
                } else {
                    domStyle.set(this.shelterEndTimeArea, 'display', 'none');
                }
            },

            // 避難者数が変更された場合に実行する処理
            onChangeEvaqueeNum() {
                // 混雑状況の設定
                this.setCrowdedStatusByRate();
            },

            // 収容率から混雑状況を自動算出して項目に設定する。
            setCrowdedStatusByRate: function () {
                if (this._isInitDetailSet) {
                    // 詳細画面の初期表示の時は混雑状況の自動算出を行わない
                    // これ以降は自動算出した混雑状況をセットするようフラグを更新
                    this._isInitDetailSet = false;
                    return;
                }
                // 避難所未選択なら処理中断
                if (!Number(this._facilityId)) { return; }
                // 開設済みでないならセットしない
                if (this.form.get('value').status !== '1') {
                    this.crowdedStatus.set('value', '');
                    return;
                }
                var crowdedStatusBefore = this.crowdedStatus.value;
                var crowdedStatus = CrowdedStatus.calcCrowdedStatus(this.evaqueeNum.value, this._capacity);
                this.crowdedStatus.set('value', crowdedStatus);
                if (crowdedStatusBefore !== this.crowdedStatus.value) {
                    this.chain.info(CrowdedStatus.changeMessage, '情報');
                }
            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function () {
                FilesUtils.uploadFile(this, '/api/shelters/uploadFile');
            },

            /**
             * 添付ファイルのプレビューを表示する。
             */
            showPreview: function (data, exifFlg) {

                var dataUri = data.attachFilePath.replace('out/', 'data/');
                var fileName = data.attachFileName;
                var fileId = data.shelterAtcFileId;

                // 画像ファイルの場合
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    var image = new Image();

                    //JPEGファイルの場合、EXIFデータの取得を実行する
                    if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                        var img = null;
                        this.own(on(image, 'load', lang.hitch(this, function (e) {
                            console.log(e);
                            img = e.target;

                            // 位置情報は利用しない
                            // if(exifFlg) {
                            //     this.getExifData(img, this);
                            // }
                            console.log(exifFlg);
                        })));
                    }
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview');
                    domConstruct.place(image, this.preview);
                    //メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, this);

                } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview');
                    domConstruct.place(excel, this.preview);
                    //メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, this);
                } else if (fileName.indexOf('.pdf') !== -1) {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview');
                    domConstruct.place(pdf, this.preview);
                    //メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, this);
                } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview');
                    domConstruct.place(word, this.preview);
                    //メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, this);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview');
                    domConstruct.place(other, this.preview);
                    //メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, this);
                }
            },

            /**
             * JPEGファイルの位置情報を取得する
             */
            getExifData: function (img, self) {
                exif.getData(img, function () {

                    var latitude = exif.getTag(this, 'GPSLatitude');
                    var longitude = exif.getTag(this, 'GPSLongitude');

                    if (!(typeof latitude === 'undefined' || typeof longitude === 'undefined')) {
                        var f = function (number) {
                            return number[0].numerator + number[1].numerator /
                                (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                        };
                        self.chain.confirm('この画像の位置情報を使用しますか？', function (chain) {
                            // 位置情報を設定する
                            self.pointLat = f(latitude);
                            self.pointLng = f(longitude);
                            // 地図にマークして中心に移動する
                            self.addMark(self.pointLat, self.pointLng, self);
                            self.map.setView([self.pointLat, self.pointLng], 11);
                            //ダイアログを閉じる
                            chain.hide();
                        });
                    }
                });
            },

            /**
             * 周辺の避難所情報を地図に描画する
             */
            setShelterMarkers: function (items, shelterItems, selfFacilityId) {
                this.layerGroup = new leaflet.featureGroup();
                this.layerGroupForSubMap = new leaflet.featureGroup();

                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    var lat = item.latitude;
                    var lng = item.longitude;

                    //緯度経度があるもののみ表示
                    if (!lat || !lng) {
                        continue;
                    }
                    //自分自身は表示させない
                    if (selfFacilityId && item.facilityId === selfFacilityId) {
                        continue;
                    }

                    //ポップアップ
                    var content = '';
                    content = '<tr><td colspan="2" style="color:#F00;"><b>避難所開設情報</b></td></tr>';
                    content += '<tr><th style="padding-right:.3em;">避難所名: </th><td colspan="2" style="color:#00F;">';
                    content += item.facilityName + '</td></tr>';

                    // 最大収容人数
                    if (item.capacity !== null) {
                        content += '<tr><th>収容人数: </th><td style="color:#00F;">';
                        content += item.capacity + '人</td></tr>';
                    } else {
                        content += '<tr><th>収容人数: </th><td style="color:#00F;">';
                        content += '---</td></tr>';
                    }

                    content += '<tr><th>住所: </th><td style="color:#00F;">';
                    content += item.address + '</td></tr>';

                    var imgUrl = 'closed';
                    for (var j = 0; j < shelterItems.length; j++) {
                        var shelter = shelterItems[j];
                        if (item.facilityId === shelter.facilityId) {
                            var status = shelter.status;
                            var crowdedStatus = shelter.crowdedStatus;
                            if (status !== '0') {
                                if (shelter.shelterStartTime !== null) {
                                    content += '<tr><th>開設日時: </th><td style="color:#00F;">';
                                    content += shelter.shelterStartTime + '</td></tr>';
                                } else {
                                    content += '<tr><th>開設日時: </th><td style="color:#00F;">';
                                    content += '---</td></tr>';
                                }
                                if (shelter.shelterEndTime !== null) {
                                    content += '<tr><th>閉鎖日時: </th><td style="color:#00F;">';
                                    content += shelter.shelterEndTime + '</td></tr>';
                                } else {
                                    content += '<tr><th>閉鎖日時: </th><td style="color:#00F;">';
                                    content += '---</td></tr>';
                                }
                            }
                            // ステータスで表示するアイコンを仕分け
                            switch (status) {
                                case '0':
                                    imgUrl = 'preparing';               // 開設準備中
                                    break;
                                case '1':
                                    imgUrl = 'open_';                   // 開設中
                                    switch (crowdedStatus) {
                                        case '1':
                                            imgUrl += 'under_capacity'; // 空き
                                            break;
                                        case '2':
                                            imgUrl += 'crowded';        // 混雑
                                            break;
                                        case '3':
                                            imgUrl += 'over_capacity';  // 定員一杯
                                            break;
                                        case '9':
                                            imgUrl += 'unknown';        // 不明
                                    }
                                    break;
                                case '9':
                                    imgUrl = 'closed';                  // 閉鎖
                                    break;
                                default:
                            }
                            break;
                        }
                    }

                    content += '<tr><td></td></tr>';

                    var marker = this.makeDamageMarker(item, imgUrl);
                    //var subMarker = this.makeDamageMarker(item);

                    //メイン地図にのみポップアップを設定
                    if (content) {
                        marker.bindPopup('<table>' + content + '</table>', { maxWidth: 1000, closeButton: false });
                    }

                    this.layerGroup.addLayer(marker);
                }

            },

            makeDamageMarker: function (item, imgUrl) {
                var lat = item.latitude;
                var lng = item.longitude;
                if (!lat || !lng) {
                    return false;
                }

                //メインアイコン
                var mainIcon = '/images/shelter/shelter_' + imgUrl + '.png';
                var html = '<img src="' + mainIcon + '" height="20px" width="20px" style="position:absolute;">';

                var divIcon = leaflet.divIcon({
                    html: html,
                    className: 'shelter-point',
                    iconSize: [0, 0],
                    iconAnchor: [3, 3]
                });

                var marker = leaflet.marker([lat, lng], {
                    icon: divIcon
                });

                return marker;
            },

            /**
             * 「周辺避難所表示」ボタンによって、地図中のアイコンの表示有無を切り替える
             */
            showShelterMarkers: function () {
                // フラグが立っていない場合は操作不可
                if (!this.enabledAroundShelterButton) {
                    return false;
                }
                // 地図が初期化されていない場合は操作せず返す
                if (!this.map) {
                    return false;
                }

                if (this.showMarkersFlg) {
                    this.map.removeLayer(this.layerGroup);
                    domStyle.set(this.showMarkersButton, 'background-color', 'rgba(0, 0, 0, 0.7)');
                    this.showMarkersFlg = false;

                } else {
                    this.map.addLayer(this.layerGroup);
                    domStyle.set(this.showMarkersButton, 'background-color', 'rgba(100, 100, 100, 0.7)');
                    this.showMarkersFlg = true;
                }
            },

            /**
             * 添付ファイルのサムネイル上にメニューを作る
             */
            createMenu: function (newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

                //ダウンロード操作用
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', { href: '#' });
                    //クリックでファイル取得処理に入る
                    download.onclick = function () {
                        self.downloadFile(url, fileName);
                    };
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                }

                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                //メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function () {
                    popup.close(tooltip);
                };

                // 画像ファイルの場合のみ'開く'をメニューに追加する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    menu.addChild(new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function () {
                            window.open(uri);
                        }
                    }));
                }

                menu.addChild(new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function (e) {
                        console.log(e);
                        //IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                }));

                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function () {
                        // 該当ファイルを削除
                        for (var i = 0; i < self.attachFileList.length; i++) {
                            if (self.attachFileList[i].shelterAtcFileId === id) {
                                self.attachFileList.splice(i, 1); //id:3の要素を削除
                            }
                        }

                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }
                }));

                menu.startup();
                //メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function () {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['below-centered']
                    });
                })));
                //画面破棄時に一緒に破棄する
                this.own(tooltip);
            },

            /**
             * 添付ファイルをダウンロードする。
             */
            downloadFile: function (url, name) {
                // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {

                    var arrayBuffer = this.response;

                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    }

                };
                xhr.send();

                return false;

            },

            /**
             * プレビューをクリアする
             **/
            clearPreview: function () {

                var length = this.preview.childNodes.length;
                for (var i = 0; i < length; i++) {
                    domConstruct.destroy(this.preview.childNodes[0]);
                }

                if (this._attachFileList) {
                    this._attachFileList.splice(0, this._attachFileList.length);
                }
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },

            /**
             * 要請内容の表示／非表示を切り替える
             */
            showHideRequestContent: function () {
                var requestFlg = this.form.get('value').requestFlg;
                if (requestFlg === '1') {
                    domStyle.set(this.requestContentArea, 'display', '');
                    domClass.add(this.requestContentArea, 'is-required');
                } else {
                    domStyle.set(this.requestContentArea, 'display', 'none');
                    domClass.remove(this.requestContentArea, 'is-required');
                    this.requestContent.set('value', '');
                }
            }
        });
});

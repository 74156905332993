/**
 * 掲示板の新規登録・詳細・続報登録画面用の基底モジュール。
 * @module app/bbs/_BbsPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/text!./templates/BbsRegisterPage.html',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/view/page/_PageBase',
    'idis/consts/ORGANIZATION_GROUP',
    'idis/model/UserInfo',
    'app/model/RegionMunicipality',
    'idis/util/FilesUtils',
    'idis/service/iframe',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'dojo/on',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/SimpleTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/ShareOrgsMultiSelector4Chronology'
], function(module, declare, lang, dom, domClass, domConstruct, template, Menu, MenuItem, popup,
    TooltipDialog, _PageBase, ORGANIZATION_GROUP, UserInfo, RegionMunicipality, FilesUtils, iframe, DialogChain, Loader, on) {

    /**
     * 掲示板新規登録・詳細・返信画面。
     *  _BbsPageBase
     *  module:app/bbs/_BbsPageBase~_BbsPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/bbs/_BbsPageBase~_BbsPageBase# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);

            this._senderName = UserInfo.getName();

        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * postCreate
         */
        postCreate: function() {
            this.inherited(arguments);
        },

        /**
         * TipsPopupをセットする。
         */
        setTipsPopups: function () {
            // 宛先ユーザの通知
            this.setNotifyTips();
            // 未読通知掲載期限
            this.setPubEndTimestampTips();
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            FilesUtils.uploadFile(this, '/api/bbses/uploadFile');
        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {

            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.facilityAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                fileName.indexOf('.gif') !== -1) {
                var image = new Image();

                // JPEGファイルの場合、EXIFデータの取得を実行する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        img = e.target;

                        if (exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                // メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self);
            // Excelファイルの場合
            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                // メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self);
            // PDFファイルの場合
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                // メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self);
            // Wordファイルの場合
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                // メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self);
            // その他ファイルの場合
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                // メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self);
            }
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {
                'border': 'none',
                'box-shadow': 'none'
            });

            // ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {
                    href: '#'
                });
                // クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            } else {
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    // href: uri,
                    href: location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            // メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                fileName.indexOf('.gif') !== -1) {
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    // IE対策
                    if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    // 該当ファイルを削除
                    for (var i = 0; i < self.attachFileList.length; i++) {
                        if (self.attachFileList[i].facilityAtcFileId === id) {
                            self.attachFileList.splice(i, 1); // id:3の要素を削除
                        }
                    }
                    // サムネイルとメニューを削除
                    domConstruct.destroy(newNode);
                    popup.close(tooltip);
                }
            }));
            menu.startup();
            // メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['below-centered']
                });
            })));
            // 画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream'
                });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }
            };
            xhr.send();
            return false;
        },

        /**
         * 自治体コードから自治体情報を取得する
         * @param {*} cd 
         */
        getRegionMunicipalityInfo: function(cd){
            return RegionMunicipality.store.get(cd).then(lang.hitch(this, function(item){
                return item ? item : null;
			}));
        },

        /**
         * ツリーオブジェクトから組織オブジェクトを成形する
         * @param {*} treeObj 
         */
        setOrganizationFromTreeObject: function(treeObj, parent){
            parent = null; // コンパイルエラー回避のためのダミー処理
            var organization = {};
            // NOTE: 長崎県では係まで管理対象とする
            // また、ツリーオブジェクトには自治体オブジェクトが入りうる
            if(treeObj.id.substr(0,1) === 'U'){
                organization.unitCd = treeObj.id;
                organization.sectCd = treeObj.parentId;
                organization.deptCd = treeObj.grandParentId;         
            } else if (treeObj.id.substr(0,1) === 'S'){
                organization.unitCd = null;
                organization.sectCd = treeObj.id;
                organization.deptCd = treeObj.parentId;
            } else if (treeObj.id.substr(0,1) === 'D'){
                organization.unitCd = null;
                organization.sectCd = null;
                organization.deptCd = treeObj.id;
            }
            organization.name = treeObj.name;
            return organization;
        },

        /**
         * プレビューをクリアする
         */
        clearPreview: function() {
            var length = this.preview.childNodes.length;
            for (var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }
            this.attachFileList.length = 0;
        },
        /**
         * 市町一括選択ボタンをクリックした際に呼ばれる
         */
        onCollectiveButtonClick: function() {
            // 一括選択したい部・課・局のコードを入力
            var items = ORGANIZATION_GROUP.BBS_DP;
            // 宛先セレクターにセットする
            this.shareMunicipalityCd.set('value', items);
        },

        /**
         * "宛先ユーザの通知"のtips
         */
        setNotifyTips: function () {
            // 宛先ユーザの通知
            var notifyTips = new TooltipDialog({
                id: 'notifyTips',
                style: 'width: 300px; height:100px',
                content: '<p>「通知あり」を選択すると、宛先のユーザに未読情報として通知されます。<br>' +
                    '「通知なし」を選択すると、宛先のユーザには未読情報として通知されませんが、掲示板画面に表示されます。</p>'
            });
            this.own(notifyTips);
            on(dom.byId('notifyLabel'), 'mouseover', function () {
                popup.open({
                    popup: notifyTips,
                    around: dom.byId('notifyLabel')
                });
            });
            on(dom.byId('notifyLabel'), 'mouseleave', function () {
                popup.close(notifyTips);
            });
        },

        /**
         * "宛先ユーザの通知"のtips
         */
        setPubEndTimestampTips: function () {
            // 宛先ユーザの通知
            var pubEndTimestampTips = new TooltipDialog({
                id: 'pubEndTimestampTips',
                style: 'width: 300px; height:100px',
                content: '<p>利用者がログインしたときに、自分が宛先に指定された情報のうち「通知あり」かつ掲載期限を超過していない未読の情報があった場合、「未読あり」としてトップページに表示されます。<br>' +
                    '掲載期限をオーバーした情報も、掲示板一覧画面には表示されます。</p>'
            });
            this.own(pubEndTimestampTips);
            on(dom.byId('pubEndTimestampLabel'), 'mouseover', function () {
                popup.open({
                    popup: pubEndTimestampTips,
                    around: dom.byId('pubEndTimestampLabel')
                });
            });
            on(dom.byId('pubEndTimestampLabel'), 'mouseleave', function () {
                popup.close(pubEndTimestampTips);
            });
        }
    });
});

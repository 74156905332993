/**
 * 避難発令判断支ルール登録/詳細画面ベースモジュール。
 * @module app/evacrecommendRule/_EvacRecommendRuleRegisterPageBase
 */
define([
    'module',
    'dijit/form/Select',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-construct',
    'dojo/dom',
    'dojo/on',
    'dojo/text!./templates/EvacRecommendRuleRegisterPage.html',
    'dojo/topic',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'app/config',
    'app/evacorder/consts/EvacorderTypeColorMap',
    'app/evacorder/DistrictLayerFactory',
    'app/evacorder/EvacOrderCheckTree',
    'app/model/CrisisManageRiverLevelReferenceLevel',
    'app/model/DistrictStoreModel',
    'app/model/EarthquakeIntensityLevel',
    'app/model/FloodWarningLevel',
    'app/model/KikikuruRiskLevel',
    'app/model/KisyoAreaCode',
    'app/model/RiverLevelReferenceLevel',
    'app/model/TsunamiWarningLevel',
    './_EvacRecommendRulePageBase',
    // 以下、変数で受けないモジュール
    'app/view/form/CrisisManageObservationSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/FloodStationSelector',
    'app/view/form/RainObservationSelector',
    'app/view/form/RiverObservationSelector',
    'app/view/form/WeatherWarnSelector',
    'dijit/form/CheckBox',
    'dijit/form/NumberTextBox',
    'dijit/layout/TabContainer',
    'idis/view/form/WordCountTextarea',
], function (module, Select, popup, TooltipDialog,
    array, declare, lang, domCon, dom, on, template, topic,
    Router, IdisMap, Requester, InfoDialog, Loader,
    config, EvacorderTypeColorMap, DistrictLayerFactory, DistrictCheckTree,
    CrisisManageRiverLevelReferenceLevel, DistrictStoreModel, EarthquakeIntensityLevel, FloodWarningLevel,
    KikikuruRiskLevel, KisyoAreaCode, RiverLevelReferenceLevel, TsunamiWarningLevel,
    _EvacRecommendRulePageBase) {

    /**
     * 避難発令判断支ルール登録/詳細画面ベースモジュール。
     * @class _EvacRecommendRuleRegisterPageBase
     * @extends module:app/evacrecommendRule/_EvacRecommendRulePageBase~_EvacRecommendRulePageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacRecommendRulePageBase,
    /** @lends module:app/evacrecommendRule/_EvacRecommendRuleRegisterPageBase~_EvacRecommendRuleRegisterPageBase# */ {
        // テンプレート文字列
        templateString: template,

        // ツリー
        tree: null,

        // レイヤーコントロール
        _layerControl: null,

        // 地図情報filter用の地区ツリーのキャッシュデータ
        _districtTreeCache: null,

        // 県の緯度経度
        INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },

        // 発令情報を選択（SELECT）するレイヤーに設定するidを暫定的に指定する。
        // このidをみて、それぞれのレイヤーを判定する。
        SELECT_LAYER_NO: 100000,

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function () {
            this.inherited(arguments);
            // セレクタ初期化
            this.initIssueReasonTypeSelector(true);
            this.initEvacOrderTypeSelector(true);
            this.setKisyoAreaSelector(this.municipalityCd.get('value'));
            this.initFloodWarningLevelSelector();
            this.initSedimentSelector();
            this.initFloodRiskInformationLevelSelector();
            this.initHeavyRainRiskInfoLevelSelector();
            this.initEarthquakeSelector();
            this.initTsunamiSelector();
            this.initRiverLevelSelector();
            this.initCrisisManageRiverLevelSelector();

            // TIPS初期化
            this.setTipsPopups();

            // 地図をレイヤーに追加する。
            this.initMap();

            // 市町村変更時の処理
            this.own(topic.subscribe('app/view/form/CustomizableMunicipalitySelector::selected',
                lang.hitch(this, function (municipalityCd) {
                    if (this._isValidMunicipalityCd(municipalityCd)) {
                        // 地区選択ツリーを再生成して、ツリーの情報に基づいて避難情報選択レイヤー再生成する。
                        this.reCreateTree(municipalityCd).then(lang.hitch(this, this.reCreateLayers, municipalityCd));
                        // 地図の初期位置を設定する。
                        this.initLatlng(municipalityCd);
                        this.setKisyoAreaSelector(municipalityCd);
                    }
                })
            ));

            // チェックボックス>地図選択時の処理
            this.own(topic.subscribe('app/evacorder/EvacOrderCheckTree::selectDistrict',
                lang.hitch(this, function (payload) {
                    this.selectDistrictLayerFromCheckTree(payload);
                })
            ));

            // 地図選択＞チェックボックス選択時の処理
            this.own(topic.subscribe('app/evacorder/DistrictLayerFactory::selectDistrict',
                lang.hitch(this, function (payload) {
                    this.selectCheckTreeFromDistrictLayer(payload.id);
                })
            ));

            // 発令内容変更時の処理
            this.own(topic.subscribe('evacOrderTypeSelector::change',
                lang.hitch(this, this.onEvacTypeChange)));
        },

        /***********************************************************************************
         * 登録・更新ボタン操作時実行
         ************************************************************************************/
        /**
         * 登録ボタンを押下
         */
        onRegisterButtonClick: function () {
            // フォームのバリデーションを行う（共通部品）
            if (!this.form.validate()) { return false; }
            // 特有のバリデーションを行う
            if (!this.validate()) { return false; }

            // 登録確認ダイアログ表示
            this.chain.confirmAdd(lang.hitch(this, function(chain) {
                // OKが押された場合
                // 追加処理と共にローディング表示
                Loader.wait(this.store.add(this.generateSendData())).then(lang.hitch(this, function() {
                    // 成功時一覧画面に移動
                    chain.infoComplete(this.moveToListPage);
                }), function(err) {
                    // 失敗時
                    chain.infoError(err);
                });
            }));
        },

        /**
         * 更新ボタンを押下
         */
        onUpdateButtonClick: function() {
            // フォームのバリデーションを行う（共通部品）
            if (!this.form.validate()) { return false; }
            // 特有のバリデーションを行う
            if (!this.validate()) { return false; }

            // 更新確認ダイアログ表示
            this.chain.confirmPut(lang.hitch(this, function(chain) {
                // OKが押された場合
                // 追加処理と共にローディング表示
                Loader.wait(this.store.add(
                    lang.mixin(this.generateSendData(), { ruleSetId : this._ruleSetId })
                )).then(lang.hitch(this, function() {
                    // 成功時一覧画面に移動
                    chain.infoComplete(this.moveToListPage);
                }), function(err) {
                    // 失敗時
                    chain.infoError(err);
                });
            }));
        },

        /**
         * 削除ボタンを押下
         */
        onDeleteButtonClick: function() {
            // 削除確認ダイアログ表示
            this.chain.confirmDel(lang.hitch(this, function(chain) {
                // OKが押された場合
                Loader.wait(Requester.del('/api/evacrecommend/ruleset/' + this._ruleSetId)
                    .then(lang.hitch(this, function() {
                    // 成功時一覧画面に移動
                    chain.infoComplete(this.moveToListPage);
                }), function(err) {
                    // 失敗時
                    chain.infoError(err);
                }));
            }));
        },

        /**
         * リクエストフォーム作成
         */
        generateSendData: function() {
            var sendData = this.form.get('value');
            sendData.districtCdList = array.map(this.tree.getCheckedLeafs(), function(item) {
                return item.id;
            });
            // 選択していない項目の入力値を消す
            // 気象エリア
            if (!this.selectedKisyo && !this.selectedFlood) {
                delete sendData.kisyoAreaCode;
            }
            // 気象警報・注意報
            if (!this.selectedKisyo) {
                sendData.kisyoWarnings = [];
            }
            // 土砂災害警戒情報
            sendData.landslide = sendData.landslide.length;
            // 指定河川洪水予報
            if (!this.selectedFlood) {
                sendData.floodRiverList = [];
                delete sendData.floodCriteria;
            }
            // 記録的短時間大雨情報
            sendData.heavyrain = sendData.heavyrain.length;
            // 土砂災害危険度
            if (!this.selectedSediment) {
                delete sendData.sedimentLevel;
            }
            // 洪水警報危険度
            if (!this.selectedFloodRiskInformationLevel) {
                delete sendData.floodRiskInformationLevel;
            }
            // 浸水害危険度
            if (!this.selectedHeavyRainRiskInfoLevel) {
                delete sendData.heavyRainRiskInfoLevel;
            }
            // 震度
            if (!this.selectedEarthquake) {
                delete sendData.earthquake;
            }
            // 津波警報・注意報
            if (!this.selectedTsunami) {
                delete sendData.tsunami;
            }
            // 雨量
            if (!this.selectedRainfall) {
                sendData.rainfallObsList = [];
                delete sendData.rainfallCumulative;
            }
            // 河川水位
            if (!this.selectedRiverLevel) {
                sendData.riverLevelObsList = [];
                delete sendData.riverLevelCriteria;
                delete sendData.riverLevel;
            }
            // 危機管理型水位
            if (!this.selectedCriticalManageRiverLevel) {
                sendData.crisisManageRiverLevelObsList = [];
                delete sendData.crisisManageRiverLevel;
            }
            // 不要なチェック削除
            delete sendData.selectedKisyo;
            delete sendData.selectedFlood;
            delete sendData.selectedSediment;
            delete sendData.selectedFloodRiskInformationLevel;
            delete sendData.selectedHeavyRainRiskInfoLevel;
            delete sendData.selectedEarthquake;
            delete sendData.selectedTsunami;
            delete sendData.selectedRainfall;
            delete sendData.selectedRiverLevel;
            delete sendData.selectedCriticalManageRiverLevel;
            return sendData;
        },

        /**
         * 入力内容のバリデーションチェック
         */
        validate: function() {
            var val = this.form.get('value');
            var messages = [];
            // 気象警報・注意報
            if (this.selectedKisyo && !val.kisyoWarnings?.length) {
                messages.push('気象警報・注意報が未選択です。');
            }
            // 指定河川洪水予報
            if (this.selectedFlood && !val.floodRiverList?.length) {
                messages.push('指定河川洪水予報の水位観測所が未選択です。');
            }
            // 雨量
            if (this.selectedRainfall) {
                if (!val.rainfallObsList?.length) {
                    messages.push('雨量観測局が未選択です。');
                }
                if (!val.rainfallCumulative) {
                    messages.push('累加雨量が未入力です。');
                }
            }
            // 河川水位
            if (this.selectedRiverLevel) {
                if (!val.riverLevelObsList?.length) {
                    messages.push('水位観測局が未選択です。');
                }
                if (!val.riverLevelCriteria && !val.riverLevel) {
                    messages.push('河川水位を選択する場合、基準値か水位(絶対値)のいずれかの入力が必須です。');
                }
            }
            // 危機管理型水位
            if (this.selectedCriticalManageRiverLevel && !val.crisisManageRiverLevelObsList?.length) {
                messages.push('危機管理型水位計が未選択です。');
            }
            // 条件設定チェック
            if (!this.selectedKisyo && !this.selectedFlood &&
                !this.selectedFloodRiskInformationLevel && !this.selectedSediment &&
                !this.selectedHeavyRainRiskInfoLevel && !this.selectedEarthquake &&
                !this.selectedTsunami && !this.selectedRainfall &&
                !this.selectedRiverLevel && !this.selectedCriticalManageRiverLevel &&
                val.landslide.length === 0 && val.heavyrain.length === 0) {
                messages.push('気象・観測情報のいずれかのルールを設定してください。');
            }

            if (messages.length > 0) {
                return this.showErrDialog(messages.join('<br>'));
            }

            // 地区チェック
            return this.validateDistrictInfo();
        },

        /**
         * 地区の入力内容のバリデーションチェック
         * @returns チェック結果（true: OK, false: NG）
         */
        validateDistrictInfo: function () {
            // 地区リストをセットする。
            var districtArray = this.tree.getCheckedLeafs();
            // 地区必須の項目にチェックがある場合、入力チェック
            if ((this.selectedSediment ||
                this.selectedFloodRiskInformationLevel ||
                this.selectedHeavyRainRiskInfoLevel) &&
                districtArray.length === 0) {
                return this.showErrDialog('危険度情報をルールとする場合、対象地区を選択してください。');
            }

            // 地区のチェック状態を確認する。（ブラウザの処理の都合により、中の子地区が全部チェックされない可能性がある）
            var checkNotCompletedDistList = [];
            array.forEach(districtArray, function (district) {
                if (!this.tree.isCheckCompleted(district.districtCd)) {
                    checkNotCompletedDistList.push(district.districtName);
                }
            }, this);

            // 子地区の処理が終わっていない親地区がある場合、後続処理を実施しないでエラーダイアログを表示する
            if (checkNotCompletedDistList && checkNotCompletedDistList.length > 0) {
                return this.showErrDialog('以下の地区について、子地区の設定がされていません。<br>' +
                    '「地区」欄から再度チェックし、全ての子地区がチェックされることを<br>' +
                    '確認してから、登録ボタンを押してください。<br><br>' +
                    '地区：' + checkNotCompletedDistList.join('、'));
            }
            return true;
        },

        /***********************************************************************************
         * 地区ツリー関連
         ************************************************************************************/
        /**
         * ツリーを初期化する。
         * @param   municipalityCd
         *      セレクタモジュールの初期セットが間に合わない場合、パラメータで渡す
         */
        initTree: function (municipalityCd) {
            // ツリーを生成する。
            if (!this.tree) {
                this.tree = new DistrictCheckTree({
                    model: new DistrictStoreModel(municipalityCd || this.municipalityCd.get('value'))
                }, this.treeNode);
                this.tree.startup();
            }
            this.own(this.tree);
            // ツリーの作成が終了したら、地図のfilter用に地区ツリーのキャッシュを取得しておく
            this.tree.onLoadDeferred.then(lang.hitch(this, function () {
                this.tree.model.store._getCache().then(lang.hitch(this, function (items) {
                    // ツリー情報から地区マスタ情報に変換して保持
                    this._districtTreeCache = array.map(
                        // ツリーのルートを弾く
                        array.filter(items.data, function(item) { return Number(item.id); }),
                        function(item) {
                            return {
                                districtCd: item.id,
                                districtName: item.name,
                                parentCd: Number(item.parentId) ? item.parentId : null,
                                evacOrderDistFlg: item.type.indexOf('Parent') > -1 ? '0' : '1'
                            };
                    });
                }));
            }));
            return this.tree.onLoadDeferred;
        },

        /**
         * 地区ツリーを再作成する。
         * 市町変更時にツリーを破棄して再作成する。
         */
        reCreateTree: function (municipalityCd) {
            // ツリーをdata-dojo-attach-pointで紐付けるノードを保持する。
            this._treeNode = this.treeNode;

            //  ツリーを一度破棄する
            this.tree.destroyRecursive();
            delete this.tree;

            // ツリーの親ノードの子供として、保持しておいたノードを追加する。
            this.treeParentNode.appendChild(this._treeNode);

            // 避難情報ツリーを追加する。
            return this.initTree(municipalityCd);

        },

        /**
         * チェックツリーを選択する。
         * 地図レイヤー選択時に呼ばれる。
         */
        selectCheckTreeFromDistrictLayer: function (id) {
            // 対応するチェックボックスの選択状態を切り替える
            this.tree.model.store.get(id).then(lang.hitch(this, function (item) {
                this.tree.setChecked(item, !this.tree.isChecked(item));
            }));
        },

        /***********************************************************************************
         * 地図表示関連
         ************************************************************************************/
        /**
         * 地図を初期生成する。
         */
        initMap: function () {
            // 地図を生成する。
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false,
                minZoom: 9,
                drawControlTooltips: false
            // 初期値は県を設定する。
            }).setView(this.INIT_LATLNG, 11);

            // destroy時にmapを破棄するよう設定
            this.own(this.map);
            // 生成したmapのlayerControlを画面にセットする。
            this._layerControl = this.map.layerControl;
        },

        /**
         * 避難情報発令地区「選択」レイヤーを地図上に表示する。
         * 選択レイヤーの初期値は、詳細情報のstyle.urlから取得する。
         *「選択」レイヤーはgeojsonからDistrictSelectLayerFactoryによって生成する。
            * @returns {Promise} 追加完了後に解決するPromise
            */
        showSelectDistrictLayer: function (municipalityCd) {
            municipalityCd = Number(municipalityCd) ? municipalityCd : this.municipalityCd.get('value');
            return Requester.get('/data/layer/data/evacorder/area/' + municipalityCd + '.geojson')
                .then(lang.hitch(this, function (selectLayer) {
                    this._layerControl.addLayer(
                        // 地区レイヤーファクトリクラスにツリーの地区情報も渡して、geojsonに登録されている地区をfilterする。
                        DistrictLayerFactory.getGeoJsonLayers(selectLayer, this._districtTreeCache),
                        this.SELECT_LAYER_NO);
                })).then(lang.hitch(this, function () {
                    // レイヤーが追加されてから、避難区分変更時のメソッドを呼んで避難区分に応じた色を設定する。
                    // 市町村を切り替えた際に、レイヤーを追加した後に色を反映したいため、レイヤー追加後に呼んでおく。
                    this.onEvacTypeChange();
                }));
        },

        /**
         * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
         */
        initLatlng: function (municipalityCd) {
            municipalityCd = Number(municipalityCd) ? municipalityCd : this.municipalityCd.get('value');
            // 「県」以外の場合は地図を追加した後で対象の市町の緯度経度を取得して、
            // 地図の中心位置に移動させる。
            // 「県」の場合は移動しない。
            if (municipalityCd === config.municInfo.prefMunicCd) {
                return;
            }
            // 市町村の緯度経度情報の一覧を取得する。
            Requester.get('/data/municipality/municipalityList.json', { preventCache: false })
                .then(lang.hitch(this, function (obj) {

                    var municipalities = obj.municipalities;
                    var latlng = this.INIT_LATLNG;

                    // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                    municipalities.some(lang.hitch(this, function (item) {
                        if (item.municipalityCd === municipalityCd) {
                            latlng = item.latlng;
                            return true; //break
                        }
                    }));
                    this.map.setView(latlng, 12);
                }));
        },

        /**
         * 地図レイヤーを再作成する。
         * 市町変更時にレイヤーを削除して再作成する。
         */
        reCreateLayers: function (municipalityCd) {
            // 地図上のレイヤーを全て削除する。
            var layerControl = this._layerControl;
            Object.keys(layerControl.layers).forEach(lang.hitch(this, function (key) {
                layerControl.removeLayerById(key);
            }));
            // 地区レイヤーを追加する。
            this.showSelectDistrictLayer(municipalityCd);
        },

        /**
         * 避難地区を選択状態にする。
         * チェックボックス選択時に呼ばれる。
         */
        selectDistrictLayerFromCheckTree: function (id) {
            // 対応する避難地区情報をもつレイヤーを選択する
            var layerList = this._layerControl.layers[this.SELECT_LAYER_NO].getLayers();
            array.some(layerList, function (layer) {
                // 背景地図レイヤーはfeatureを持っていないため事前に確認する
                // D_CDは地区レイヤーに設定する地区ID。geojson、レイヤーのプロパティjson、treejsonに設定されている。
                var layerId = layer.feature && layer.feature.properties.D_CD;
                if (layerId === id) {
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    options.selectDistrictLayer(layer);
                    return true; // break
                }
            });
        },

        /***********************************************************************************
         * 入力フォームのセット
        /***********************************************************************************
        /**
         * 気象・観測情報チェックボックスをクリックすると呼ばれる
         */
        setInputDisability: function() {
            var val = this.form.get('value');
            // 気象・観測情報チェックボックスがチェックされているかどうかを取得
            this.selectedKisyo = val.selectedKisyo.length > 0;
            this.selectedFlood = val.selectedFlood.length > 0;
            this.selectedSediment = val.selectedSediment.length > 0;
            this.selectedFloodRiskInformationLevel = val.selectedFloodRiskInformationLevel.length > 0;
            this.selectedHeavyRainRiskInfoLevel = val.selectedHeavyRainRiskInfoLevel.length > 0;
            this.selectedEarthquake = val.selectedEarthquake.length > 0;
            this.selectedTsunami = val.selectedTsunami.length > 0;
            this.selectedRainfall = val.selectedRainfall.length > 0;
            this.selectedRiverLevel = val.selectedRiverLevel.length > 0;
            this.selectedCriticalManageRiverLevel = val.selectedCriticalManageRiverLevel.length > 0;
            // 気象・観測情報チェックボックスのチェック状態に応じて、入力フォームの入力可否をセットする
            this.weatherWarnSelector.set('disabled', !this.selectedKisyo);
            this.floodStationSelector.set('disabled', !this.selectedFlood);
            this.floodCriteria.set('disabled', !this.selectedFlood);
            this.sedimentLevel.set('disabled', !this.selectedSediment);
            this.floodRiskInformationLevel.set('disabled', !this.selectedFloodRiskInformationLevel);
            this.heavyRainRiskInfoLevel.set('disabled', !this.selectedHeavyRainRiskInfoLevel);
            this.earthquake.set('disabled', !this.selectedEarthquake);
            this.tsunami.set('disabled', !this.selectedTsunami);
            this.rainfallObsSelector.set('disabled', !this.selectedRainfall);
            this.rainfall.set('disabled', !this.selectedRainfall);
            this.riverLevelObsSelector.set('disabled', !this.selectedRiverLevel);
            this.riverLevelCriteria.set('disabled', !this.selectedRiverLevel);
            this.riverLevel.set('disabled', !this.selectedRiverLevel);
            this.criticalManageRiverLevelSelector.set('disabled', !this.selectedCriticalManageRiverLevel);
            this.crisisManageRiverLevel.set('disabled', !this.selectedCriticalManageRiverLevel);
        },

        /**
         * 市町村に応じた気象エリアをセレクタにセット
         */
        setKisyoAreaSelector: function(municipalityCd) {
            var options = [];
            array.forEach(KisyoAreaCode.data, function (item) {
                // 市町村の気象エリアを選択
                if (item.municipalityCd === municipalityCd) {
                    options.push({ label: item.name, value: item.areaCd });
                }
            });
            this._placeSelectDom('kisyoAreaCode', options, options.length > 1);
        },

        /**
         * 指定河川洪水情報警戒レベル セレクタ初期化
         */
        initFloodWarningLevelSelector: function() {
            this._placeSelectDom('floodCriteria',
                this._selectOptionsAddWarningLevelMapper(FloodWarningLevel));
        },

        /**
         * 土砂災害危険度情報警戒レベル セレクタ初期化
         */
        initSedimentSelector: function() {
            this._placeSelectDom('sedimentLevel',
                this._selectOptionsAddWarningLevelMapper(KikikuruRiskLevel));
        },

        /**
         * 洪水警報危険度情報警戒レベル セレクタ初期化
         */
        initFloodRiskInformationLevelSelector: function() {
            this._placeSelectDom('floodRiskInformationLevel',
                this._selectOptionsAddWarningLevelMapper(KikikuruRiskLevel));
        },

        /**
         * 浸水害危険度情報警戒レベル セレクタ初期化
         */
        initHeavyRainRiskInfoLevelSelector: function() {
            var options = array.map(KikikuruRiskLevel.data, function (item) {
                // 浸水害危険度情報警戒レベルはレベル５のみ
                var level = (Number(item.level) === 5 ? '【警戒レベル' + item.level + '相当】' : '');
                return {
                    label: level + item.label,
                    value: item.level
                };
            });
            this._placeSelectDom('heavyRainRiskInfoLevel', options);
        },

        /**
         * 震度情報警戒レベル セレクタ初期化
         */
        initEarthquakeSelector: function() {
            this._placeSelectDom('earthquake',
                this._selectOptionsMapper(EarthquakeIntensityLevel));
        },

        /**
         * 津波警報・注意報情報警戒レベル セレクタ初期化
         */
        initTsunamiSelector: function() {
            this._placeSelectDom('tsunami',
                this._selectOptionsMapper(TsunamiWarningLevel));
        },

        /**
         * 水位基準値 セレクタ初期化
         */
        initRiverLevelSelector: function() {
            this._placeSelectDom('riverLevelCriteria',
                [{ label: '&nbsp;', value: '' }]
                .concat(this._selectOptionsMapper(RiverLevelReferenceLevel)));
        },

        /**
         * 危機管理型水位基準値 セレクタ初期化
         */
        initCrisisManageRiverLevelSelector: function() {
            this._placeSelectDom('crisisManageRiverLevel',
                this._selectOptionsMapper(CrisisManageRiverLevelReferenceLevel));
        },

        /**
         * セレクタDOMを追加
         * @param {String} name                 セレクタ名
         * @param {object.label} options.label  セレクタラベル
         * @param {object.value} options.value  セレクタ値
         * @param {String} enabled              true: セレクタ入力可
         */
        _placeSelectDom: function(name, options, enabled) {
            this[name] = new Select({
                name: name,
                style: 'width: 20em;',
                options: options,
                disabled: !enabled
            });
            var wrapper = this[name + 'Wrapper'];
            wrapper.innerHTML = '';
            domCon.place(this[name].domNode, wrapper);
            // 観測情報以外は以上をつける（観測情報は位置の調整が不要なのでHTMLに直書き）
            if (['riverLevelCriteria', 'crisisManageRiverLevel'].indexOf(name) < 0) {
                domCon.place('<span style="width:100%; text-align:left;">&emsp;以上</span>', wrapper);
            }
        },

        // セレクタOption用のオブジェクトに変換する
        _selectOptionsMapper: function (store) {
            return array.map(store.data, function(item) {
                return { label: item.label, value: item.level };
            });
        },
        // セレクタOption用のオブジェクトに変換する（ラベルに警戒レベルつき）
        _selectOptionsAddWarningLevelMapper: function (store) {
            return array.map(store.data, function(item) {
                return {
                    label: '【警戒レベル' + item.level + '相当】' + item.label,
                    value: item.level
                };
            });
        },

        /***********************************************************************************
         * その他 画面イベント
         ************************************************************************************/
        /**
         * 避難情報種別が変更された際に呼ばれる。
         * onChange時に呼ばれるようにhtmlにattachされている。
         */
        onEvacTypeChange: function () {
            // 初期化時に避難区分がフォームにセットされた段階で当該メソッドが呼ばれてしまう。
            // 地図生成時にモジュールにセットするlayerControlが未生成の可能性があるため
            // 事前にlayerControlの有無をチェックする。
            if (!this._layerControl || Object.keys(this._layerControl.layers).length === 0) {
                return;
            }
            var layerGroup = this._layerControl.layers[this.SELECT_LAYER_NO];
            var color = EvacorderTypeColorMap[this.evacOrderType.get('value')];
            // レイヤーのfeatureをそれぞれ確認して、選択されているものは色を変更する。
            layerGroup.eachLayer(function (layer) {
                // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
                var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                options._selectedColor = color;
                if (options._isSelected) {
                    layer.setStyle({ fillColor: color });
                }
            });
        },

        /**
         * 一覧画面に遷移
         */
        moveToListPage: function (evt) {
            if (evt?.preventDefault) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
            }
            Router.moveTo('evacrecommend/rule', {
                'municipalityCd': this.municipalityCd.get('value')
            });
        },

        /**
         * TipsPopupをセットする。
         */
        setTipsPopups: function () {
            // 手動メッセージ
            var issueReasonTips = new TooltipDialog({
                id: 'messageTips',
                style: 'width: 300px;',
                content: '<p>基準値超過により避難基準超過一覧に表示された際、<br>手動メッセージは「基準値超過情報」として黒字で表示されます。</p>'
            });
            this.own(issueReasonTips);
            var manualMessageLabel = dom.byId('manualMessageLabel');
            on(manualMessageLabel, 'mouseover', function () {
                popup.open({
                    popup: issueReasonTips,
                    around: manualMessageLabel
                });
            });
            on(manualMessageLabel, 'mouseleave', function () {
                popup.close(issueReasonTips);
            });
        },

        /**
         * エラーダイアログを表示する
         * @param {string} content エラーメッセージ
         * @returns false
         */
        showErrDialog: function(content) {
            if (!this.infoDialog) {
                this.infoDialog = new InfoDialog({
                    title: 'エラー',
                    content: content
                });
            }
            this.infoDialog.show();
            this.infoDialog = null;
            return false;
        }
    });
});

/**
 * 新規メール配信ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoSendMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoSendMailDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'app/view/form/ConvoGroupSelector',
    'app/convocation/view/mail/ConvoTempInsertDialog'
], function(module, declare, lang, template, IdisRest, _PageBase, InfoDialog) {
    /**
     * 新規メール配信ダイアログ。
     * @class 新規メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoSendMailDialog~ConvoSendMailDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // テンプレートコード
            templateCd: '',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'distributionTmplCd',
                    target: '/api/convoMails/temps'
                });
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.templateInsertDialog);
                this.initTempInsertGrid();
            },

            /**
             * テンプレート選択用グリッドの初期化
             */
            initTempInsertGrid: function() {
                var page = this.templateInsertDialog.getChildren()[0];
                var filter = new this.store.Filter();
                filter = filter.eq('count', -1);
                page.tempInsertGrid.set('collection', this.store.filter(filter));
                // グリッドの挿入ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'insert'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
                    // grid内の件名と本文をformに設定する
                    this.form.set('value', evt.item);
                    this.templateCd = evt.item.distributionTmplCd;
                    this.templateInsertDialog.hide();
                }));
            },

            /**
             * ページの初期化
             */
            initPage: function() {
                this.form.reset();
                this.templateCd = '';
            },

            /**
             * 送信ボタンが押下された際の処理。
             */
            onSubmit: function() {
                try {
                    // 入力チェックを通過した場合にのみ処理を実施
                    if (this.validate()) {
                        var val = this.form.get('value');
                        var formData = {
                            'groupId' : val.groupId,
                            'templateCd' : this.templateCd,
                            'title': val.distributionTitle,
                            'body' : val.distributionBody,
                            'mailType' : '0', // FIXME 項目名の修正
                            'convoMailType' : '0',
                        };
                        // 送信イベントを発行する
                        this.emit('send', {value: formData});
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 入力値の妥当性チェック
             */
            validate: function() {
                var formData = this.form.get('value');
                // 送信先（グループ）の必須チェック
                if (!formData.groupId || formData.groupId.length === 0) {
                    InfoDialog.show('入力エラー', '送信先（グループ）が選択されていません。');
                    return false;
                }
                // 件名のnullチェック
                if (formData.distributionTitle === '') {
                    InfoDialog.show('入力エラー', '件名が入力されていません。');
                    return false;
                }
                // 本文nullチェック
                if (formData.distributionBody === ''){
                    InfoDialog.show('入力エラー', '本文が入力されていません。');
                    return false;
                }
                return true;
            },

            /**
             * テンプレートから挿入ボタンが押下された際の処理。
             */
            onSelectTempButton: function() {
                // テンプレート選択ダイアログの表示
                this.templateInsertDialog.show();
            }
        });
});

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-construct',
    'dojo/topic',
    'dojo/on',
    'idis/map/IdisMap',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/grid/IdisSelector'
], function(module, declare, lang, array, domConstruct, topic, on, IdisMap, DateUtils, IdisGrid, helper, IdisSelector) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector], {

        // ルート要素に付与されるCSS
        baseClass: 'map-DetailGrid',

        selectionMode: 'none',
        allowSelectAll: false,

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [

            { 
                field: 'attachFiles',
                id: 'attachFiles',
                label: '写真',
                sortable: false,
                renderCell: function(items) {
                    var attachNode = domConstruct.create('span');
                    if (items.attachFiles && items.attachFiles.length !== 0) {
                        var attachFile = null;
                        array.some(items.attachFiles, function(item){
                            var fileName = item.attachFileName;
                            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                                fileName.indexOf('.gif') !== -1) {
                                attachFile = item;
                                return true;
                            }
                            return false;
                        });
                        if (attachFile){
                            var path = attachFile.attachFilePath.replace('out', 'data');
                            var node = domConstruct.create('img', {
                                src: path,
                                width: '100%',
                                style: {maxWidth: '10em'}
                            });
                            domConstruct.place(node, attachNode);
                            domConstruct.place('<br>', attachNode);
                        }
                        return attachNode;
                    }
                }
            },
            // helper.column('reportName', '報告名'),
            {
                field: 'reportCrtTimestamp', label: '報告日時',
                formatter: lang.hitch(this, function (item) {
                    if (!item) {
                        return '-';
                    } else {
                        return DateUtils.format(item);
                    }
                })
            },
            helper.column('damageAddress', '発生場所'),
            helper.column('seqNum', '報告連番', {formatter: function(value) { return '第' + value + '報'; }}),
            helper.column('organizationName', '報告者組織'),
            helper.column('urgencyType', '緊急度', {
                formatMap: {
                    '0': '-',
                    '1': '低',
                    '2': '中',
                    '3': '高'
                },
                classMap: {
                    '0': '',
                    '1': 'is-low',
                    '2': 'is-middle',
                    '3': 'is-high'
                }
            }),
            // helper.column('reportStatus', '報告状況', {
            //     formatMap: {
            //         '11': '庁内共有（県へ報告しない）',
            //         '31': '県報告（外部公開不可）',
            //         '41': '県報告（外部公開可能）'
            //     }
            // }),
            helper.column('hldStatus', '対応状況', {
                formatMap: {
                    '0': '確認中',
                    '1': '対応待ち',
                    '2': '対応中',
                    '3': '対応済'
                }
            }),
            helper.column('humanDamageFlg', '人的被害', {
                formatMap: {
                    '0': 'あり',
                    '1': 'なし'
                },
                classMap: {
                    '0': 'dmg',
                    '1': 'no-dmg'
                }
            }),
            helper.column('comment', '備考'),
            helper.buttonColumn('register', '被害情報登録')
        ],

        // グリッド行がクリックされた場合に呼ばれる
        onRowClick: function(item) {
            // 地図を要素の指定する座標へ移動
            topic.publish(IdisMap.TOPIC.MOVE, {
                lat: item.damageLatitude,
                lng: item.damageLongitude
            });
            // ポップアップを表示
            topic.publish(IdisMap.TOPIC.OPEN_POPUP, {id: item.layerId});
        }
    });
});

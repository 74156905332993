/**
 * 通行規制情報の新規登録・詳細・コピー新規作成・続報登録画面用の基底モジュール。
 * @module app/traffic/view/TrafficRegulationDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/has',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/text!./templates/TrafficRegulationDetailPage.html',
    'dojo/window',
    'dojo/query',
    'dojo/topic',
    'dojo/Deferred',
    'dojo/date/locale',
    'dijit/registry',
    'dijit/form/Select',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dstore/Memory',
    'dstore/Trackable',
    'app/draw/DrawPanel',
    'app/model/LayerStore',
    'app/traffic/consts/parentLayerIdCfg',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    'idis/view/draw/_DrawUtil',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/Loader',
    'idis/map/IdisMap',
    'leaflet',
    'leaflet-geometryutil',
    'esri-leaflet-geocoder',
    '../config/road',
    '../../config',
    'app/map/baselayer/BaseLayerPane',
    // 以下、変数として受け取らないモジュール
    'dojox/form/Uploader',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Textarea',
    'idis/view/form/WordCountTextarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/RadioGroup',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    '../../view/form/DisasterSelector',
    '../../view/form/OrganizationSelector',
    '../../view/form/LayerDirectorySelector',
    './TrafficActionDetailPage',
    './TrafficActionGrid',
    './RegSectionPage',
    './RegSectionGrid'
], function (module, array, declare, lang, json, dom, domStyle, domClass, domConstruct, domGeom, has, on,
    iframe, template, winUtils, query, topic, Deferred, locale, registry, Select, Menu, MenuItem, popup, TooltipDialog,
    Memory, Trackable, DrawPanel, LayerStore, parentLayerIdCfg, Locator, Router, UserInfo, IdisRest, Requester,
    _PageBase, DrawUtil, DialogChain, IdisDialog, Loader, IdisMap, leaflet, geometryutil, geocoder, road,
    config, BaseLayerPane) {
    /**
     * 通行規制情報の新規登録・詳細・コピー新規作成・続報登録画面の基底クラス。
     *
     * @class _TrafficRegulationDetailPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/traffic/view/_TrafficRegulationDetailPageBase~_TrafficRegulationDetailPageBase# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--traffic',

            /**
             * 被災対応状況を格納するストア
             * @type {module:dstore/Trackable}
             */
            trafficActionStore: null,

            /**
             * 事前定義済み規制区間データを格納するストア
             * @type {module:dstore/Store}
             */
            regSectionStore: null,

            /**
             * 添付ファイルのリスト
             */
            trafficAttachments: [],

            /**
             * 背景地図ダイアログに対する参照
             * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
             * @private
             */
            _baseLayerDialog: null,

            // 道路選択、及び道路の範囲選択に使うもの
            roadLayerGroup: null,
            beginRegulation: null,
            endRegulation: null,
            selectedLayer: null,
            regulatedLayers: [],

            damagedLayer: null,
            damaged: null,
            addDamagedLayers: [],
            addDamaged: [],

            constructedLayer: null,
            beginConstructed: null,
            endConstructed: null,

            selectedMarkers: null,
            dfdJsonize: null,

            selectedStyle: {
                color: '#13e900',
                opacity: 1,
                weight: 7
            },
            extractedStyle: {
                color: '#ff0000',
                opacity: 1,
                weight: 10
            },
            damagedStyle: {
                color: '#800080',
                opacity: 1,
                weight: 10
            },
            constructedStyle: {
                color: '#800080',
                opacity: 1,
                weight: 10
            },
            hiddenStyle: {
                color: '#8c8c8c',
                opacity: 0.8,
                weight: 7
            },

            // DrawPanelとやり取りするPubSubのtopic
            DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
            DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
            ADD_LAYER: '/app/draw/DrawPanel::addLayer',
            REMOVE_LAYER: '/app/draw/DrawPanel::removeLayer',
            JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
            JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
            ROAD_REG_REQ: '/app/draw/DrawPanel::roadRegisterProcessStart',
            ROAD_REG_RES: '/app/draw/DrawPanel::roadRegisterProcessComplete',
            // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
            DOWNLOAD_4IE: '/app/view/page/MapPage::download4IE',

            /**
             * popupイベントを保持する
             * @private
             */
            _downloadEvts: [],

            // 初期ロード時にイベントを動かすかどうかを制御するフラグ
            isInStartup: false,

            // 事前規制区間セット時にイベントを動かすかどうかを制御するフラグ
            isSettingRegSection: false,

            // 事前定義済み規制区間モードかどうかを制御するフラグ
            isRegSectionMode: false,
            // 路線全選択モードかどうかを制御するフラグ
            isSelectAllRoadMode: false,
            // 切り出しモードかどうかを制御するフラグ
            isCutMode: false,

            constructor: function () {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);

                // 規制区間を初期化
                this.regulatedLayers = [];

                // 作図のポップアップイベントの保持
                this._downloadEvts = [];

                // 被災対応状況データ格納用オブジェクト
                this.trafficActionStore = Trackable.create(new Memory());

                // 事前定義済み規制区間データ格納用オブジェクト
                this.regSectionStore = new IdisRest({
                    idProperty: 'regSectionId',
                    target: '/api/road/regSections'
                });
            },

            /**
             * マップを初期化する。
             */
            initMap: function () {
                // mapの生成
                var latlng = [config.map.latitude, config.map.longitude];
                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, //　コメント時に+/-が使用できないため
                    touchExtend: false,
                    drawControlTooltips: false
                }).setView(latlng, 12);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
            },

            /**
             * 添付ファイル選択時に呼ばれる。
             **/
            loadAttachFile: function () {
                // ファイルが空の場合は何もしない
                if (this.attachFile._files.length === 0) {
                    return;
                }
                var url = '/api/traffic/attachments';
                // ファイルをサーバーにPOSTする
                var promise = iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                });
                // ローダーの表示
                Loader.wait(promise).then(lang.hitch(this, function (data) {
                    // 添付ファイルの配列に追加
                    this.trafficAttachments.push(data);
                    // プレビューを表示する
                    this.showPreview(data);
                    // ファイルアップローダー部品をリセットする
                    this.attachFile.reset();
                }), function (error) {
                    console.debug(error);
                });

            },

            /**
             * 左部領域の実際の大きさに合わせて再レイアウトする。
             */
            resizeLeftPane: function () {
                // 下部領域をその大きさに従ってサイズ調整する
                // ContentPaneのpadding（bottomの8pxはCSSでカット済）とborderの分を追加
                //var newHeight = domGeom.position(this.leftPaneRootNode).h + 8 + 2;
                //this.borderContainer._layoutChildren(this.leftPane.id, newHeight);
                this.leftPane.resize();
            },

            /**
             * 添付ファイル読み込み後に実行される。
             */
            showPreview: function (data) {
                var dataUri = data.filepath.replace('out/', 'data/');
                var fileName = data.filename;
                // 拡張子名（ドットを除く）
                var extName = fileName.split('.').pop().toLowerCase();
                var fileId = data.trafficAttachmentId;
                var self = this;
                // 画像ファイルの場合
                if (array.indexOf(['jpg', 'jpeg', 'png', 'gif'], extName) !== -1) {
                    var image = new Image();
                    // 画像のロード完了後、上部領域のレイアウトを調整する
                    this.own(on(image, 'load', lang.hitch(this, function () {
                        if (has('ie') || has('trident')) {
                            // IEの場合はウェイトを入れる
                            setTimeout(lang.hitch(this, 'resizeLeftPane'), 50);
                        } else {
                            this.resizeLeftPane();
                        }
                    })));
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview');
                    domConstruct.place(image, this.preview);
                    // メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, self);
                } else if (array.indexOf(['xls', 'xlsx'], extName) !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview');
                    domConstruct.place(excel, this.preview);
                    // メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, self);
                } else if (extName === 'pdf') {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview');
                    domConstruct.place(pdf, this.preview);
                    // メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, self);
                } else if (array.indexOf(['doc', 'docx'], extName) !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview');
                    domConstruct.place(word, this.preview);
                    // メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, self);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview');
                    domConstruct.place(other, this.preview);
                    // メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, self);
                }
            },

            /**
             * 添付ファイルのサムネイル上にメニューを作る。
             */
            createMenu: function (newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', {
                    'border': 'none',
                    'box-shadow': 'none'
                });
                // ダウンロード操作用
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', {
                        href: '#'
                    });
                    // クリックでファイル取得処理に入る
                    download.onclick = function () {
                        self.downloadFile(url, fileName);
                    };
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                }
                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                // メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function () {
                    popup.close(tooltip);
                };
                // 画像ファイルの場合のみ'開く'をメニューに追加する
                var extName = fileName.split('.').pop().toLowerCase();
                if (array.indexOf(['jpg', 'jpeg', 'png', 'gif'], extName) !== -1) {
                    menu.addChild(new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function () {
                            window.open(uri);
                        }
                    }));
                }
                menu.addChild(new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function (e) {
                        console.debug(e);
                        // IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                }));
                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: lang.hitch(this, function () {
                        // 該当ファイルを削除
                        for (var i = 0; i < self.trafficAttachments.length; i++) {
                            if (self.trafficAttachments[i].trafficAttachmentId === id) {
                                self.trafficAttachments.splice(i, 1); //id:3の要素を削除
                            }
                        }
                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        this.resizeLeftPane();
                        popup.close(tooltip);
                    })
                }));
                menu.startup();
                // メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function () {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['below-centered']
                    });
                })));
                // 画面破棄時に一緒に破棄する
                this.own(tooltip);
            },

            /**
             * IE対策 ダウンロード用ファイルを取得する。
             */
            downloadFile: function (url, name) {
                // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {
                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], {
                        type: 'application/octet-stream'
                    });
                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    }
                };
                xhr.send();
                return false;
            },

            /**
             * 対応状況グリッドを初期化する。
             */
            initTrafficActionGrid: function () {
                // 削除フラグがたっているものはグリッドに表示しない
                var filter = new this.trafficActionStore.Filter();
                var delFlgFilter = filter.ne('delFlg', '1');
                this.trafficActionGrid.set('collection', this.trafficActionStore.filter(delFlgFilter));

                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.trafficActionGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.showUpdateTrafficActionDialog(evt.item);
                }));
            },

            /**
             * 対応状況追加ダイアログを表示する。
             * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
             * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
             */
            showRegisterTrafficActionDialog: function () {
                // ダイアログの最初の子要素が登録画面
                var page = this.trafficActionDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
                page.form.reset();
                // 更新・削除ボタンを非表示にする
                page.updateTrafficActionButton.set('style', {
                    display: 'none'
                });
                page.deleteTrafficActionButton.set('style', {
                    display: 'none'
                });
                // 登録ボタンを表示する
                page.registerTrafficActionButton.set('style', {
                    display: ''
                });
                // ダイアログを表示
                this.trafficActionDialog.show();
            },

            /**
             * 対応状況編集ダイアログを表示する。
             * @param {Object} item 参照する行のデータ
             */
            showUpdateTrafficActionDialog: function (item) {
                // ダイアログの最初の子要素が詳細画面
                var page = this.trafficActionDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
                page.form.reset();
                // 行データの内容をフォームに反映
                page.form.set('value', item);
                // 登録ボタンを非表示にする
                page.registerTrafficActionButton.set('style', {
                    display: 'none'
                });
                // 更新・削除ボタンを表示する
                page.updateTrafficActionButton.set('style', {
                    display: ''
                });
                page.deleteTrafficActionButton.set('style', {
                    display: ''
                });
                // 詳細ダイアログを表示
                this.trafficActionDialog.show();
            },

            /**
             * 対応状況追加ダイアログのフォームが投稿された際の動作を設定する。
             */
            initTrafficActionDialog: function () {
                // 登録ダイアログの最初の子要素が登録画面
                var dialog = this.trafficActionDialog;
                var page = dialog.getChildren()[0];
                // 登録画面のregisterイベントを受け取る
                page.on('register', lang.hitch(this, function (evt) {
                    // idが空文字の場合はフォームから削除する
                    if (evt.value.id === '') {
                        delete evt.value.id;
                    }
                    // trafficActionIdが空文字の場合はフォームから削除する
                    if (evt.value.trafficActionId === '') {
                        delete evt.value.trafficActionId;
                    }
                    // ストアにオブジェクトを追加
                    this.trafficActionStore.add(evt.value).then(function () {
                        dialog.hide();
                    });
                }));
                // 登録画面のupdateイベントを受け取る
                page.on('update', lang.hitch(this, function (evt) {
                    // trafficActionIdが空文字の場合はフォームから削除する
                    if (evt.value.trafficActionId === '') {
                        delete evt.value.trafficActionId;
                    }
                    // ストアのオブジェクトを更新
                    this.trafficActionStore.put(evt.value).then(function () {
                        dialog.hide();
                    });
                }));
                // 登録画面のdeleteイベントを受け取る
                page.on('delete', lang.hitch(this, function (evt) {
                    // trafficActionIdが空文字ではないとき（サーバーに登録されている対応状況の削除）
                    if (evt.value.trafficActionId !== '') {
                        // 削除フラグをたてる
                        evt.value.delFlg = '1';
                        // ストアのオブジェクトを更新
                        this.trafficActionStore.put(evt.value).then(function () {
                            dialog.hide();
                        });
                        // trafficActionIdが空文字なとき（まだサーバーに登録していない対応状況の削除）
                    } else {
                        // ストアのオブジェクトを削除
                        this.trafficActionStore.remove(evt.value.id).then(function () {
                            dialog.hide();
                        });
                    }
                }));
            },

            /**
             * 作図パネルの作成
             */
            createDrawPanel: function () {
                if (!this.drawPanel) {
                    this.own(
                        this.drawPanel = new DrawPanel({
                            map: this.map,
                            'class': 'drawPanel-NonModal',
                            dispType: 'traffic'
                        })
                    );
                }
            },

            /**
             * 作図パネルの表示
             */
            showDrawPanelDialog: function () {
                this.drawPanel.show();
            },

            /**
             * 作図パネルの非表示
             */
            hideDrawPanelDialog: function () {
                //作図ダイアログを閉じる
                this.drawPanel.hide();
                // 全ての作図モードをキャンセルする
                topic.publish(this.DISABLE_DRAW);
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);

                // 地図を初期化
                this.initMap();

                // 地図レイヤーの追加
                leaflet.tileLayer(config.map.url[UserInfo.getRunningMode()], {
                    maxZoom: 18
                }).addTo(this.map);

                // 対応状況グリッドを初期化
                this.initTrafficActionGrid();

                // 対応状況追加ダイアログのフォームがsubmitされた際の動作を設定
                this.initTrafficActionDialog();

                // 事前定義済み規制区間グリッドを初期化する
                this.initRegSectionGrid();

                // 作図
                this.createDrawPanel();
                topic.publish(this.DRAW_INIT_ID, this.map);
                topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function (json) {
                    this.dfdJsonize = new Deferred();
                    this.dfdJsonize.resolve(json);
                }));
                topic.subscribe(this.ROAD_REG_RES, lang.hitch(this, function (json) {
                    this.dfdJsonize = new Deferred();
                    this.dfdJsonize.resolve(json);
                }));

                // 選択マーカー用LayerGroupの生成
                this.selectedMarkers = new leaflet.LayerGroup();
                this.selectedMarkers.addTo(this.map);

                // IE対応
                // イベントを管理する人は各Mapに必要。
                // TODO pub/subの方がよいか？
                if (DrawUtil._isIE()) {
                    DrawUtil._setPopupEvtForMap(this);
                }
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
                this.own(this.trafficActionDialog);
                this.own(this.regSectionDialog);
            },

            /**
             * フォームの入力項目のバリデーションを実施する。
             */
            validateForm: function () {
                var formData = this.form.get('value');

                if (!formData.regionCd) {
                    this.chain.info('振興局が選択されていません。', '入力エラー');
                    return false;
                }
                if (!formData.roadTypeCd) {
                    this.chain.info('道路種別が選択されていません。', '入力エラー');
                    return false;
                }
                if (!formData.roadName && !formData.roadNameOther) {
                    this.chain.info('路線名が選択されていません。', '入力エラー');
                    return false;
                }

                if (!formData.regStartPointName) {
                    this.chain.info('規制区間起点の地名が入力されていません。', '入力エラー');
                    return false;
                }
                if (!formData.regStartPointLat) {
                    this.chain.info('規制区間起点の緯度が入力されていません。', '入力エラー');
                    return false;
                }
                if (!formData.regStartPointLng) {
                    this.chain.info('規制区間起点の経度が入力されていません。', '入力エラー');
                    return false;
                }
                if (!formData.regEndPointName) {
                    this.chain.info('規制区間終点の地名が入力されていません。', '入力エラー');
                    return false;
                }
                if (!formData.regEndPointLat) {
                    this.chain.info('規制区間終点の緯度が入力されていません。', '入力エラー');
                    return false;
                }
                if (!formData.regEndPointLng) {
                    this.chain.info('規制区間終点の経度が入力されていません。', '入力エラー');
                    return false;
                }

                // 災害の場合
                if (formData.regReasonCd === '01') {
                    if (!formData.dmgPointName) {
                        this.chain.info('被災箇所の地名が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.dmgPointLat) {
                        this.chain.info('被災箇所の緯度が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.dmgPointLng) {
                        this.chain.info('被災箇所の経度が入力されていません。', '入力エラー');
                        return false;
                    }
                }

                // 工事の場合
                if (formData.regReasonCd === '02') {
                    if (!formData.constStartPointName) {
                        this.chain.info('工事区間起点の地名が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.constStartPointLat) {
                        this.chain.info('工事区間起点の緯度が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.constStartPointLng) {
                        this.chain.info('工事区間起点の経度が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.constEndPointName) {
                        this.chain.info('工事区間終点の地名が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.constEndPointLat) {
                        this.chain.info('工事区間終点の緯度が入力されていません。', '入力エラー');
                        return false;
                    }
                    if (!formData.constEndPointLng) {
                        this.chain.info('工事区間終点の経度が入力されていません。', '入力エラー');
                        return false;
                    }
                }

                // 規制区間起点の緯度が入力されている場合
                if (formData.regStartPointLat) {
                    // 数値であるかをチェック
                    if (isNaN(formData.regStartPointLat)) {
                        this.chain.info('規制区間起点の緯度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 規制区間起点の経度が入力されている場合
                if (formData.regStartPointLng) {
                    // 数値であるかをチェック
                    if (isNaN(formData.regStartPointLng)) {
                        this.chain.info('規制区間起点の経度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 規制区間終点の緯度が入力されている場合
                if (formData.regEndPointLat) {
                    // 数値であるかをチェック
                    if (isNaN(formData.regEndPointLat)) {
                        this.chain.info('規制区間終点の緯度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 規制区間起点の経度が入力されている場合
                if (formData.regEndPointLng) {
                    // 数値であるかをチェック
                    if (isNaN(formData.regEndPointLng)) {
                        this.chain.info('規制区間終点の経度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 被災箇所の緯度が入力されている場合
                if (formData.dmgPointLat) {
                    // 数値であるかをチェック
                    if (isNaN(formData.dmgPointLat)) {
                        this.chain.info('被災箇所の緯度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 被災箇所の経度が入力されている場合
                if (formData.dmgPointLng) {
                    // 数値であるかをチェック
                    if (isNaN(formData.dmgPointLng)) {
                        this.chain.info('被災箇所の経度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 工事区間起点の緯度が入力されている場合
                if (formData.constStartPointLat) {
                    // 数値であるかをチェック
                    if (isNaN(formData.constStartPointLat)) {
                        this.chain.info('工事区間起点の緯度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 工事区間起点の経度が入力されている場合
                if (formData.constStartPointLng) {
                    // 数値であるかをチェック
                    if (isNaN(formData.constStartPointLng)) {
                        this.chain.info('工事区間起点の経度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 工事区間終点の緯度が入力されている場合
                if (formData.constEndPointLat) {
                    // 数値であるかをチェック
                    if (isNaN(formData.constEndPointLat)) {
                        this.chain.info('工事区間終点の緯度の値が不正です。', '入力エラー');
                        return false;
                    }
                }
                // 工事区間起点の経度が入力されている場合
                if (formData.constEndPointLng) {
                    // 数値であるかをチェック
                    if (isNaN(formData.constEndPointLng)) {
                        this.chain.info('工事区間終点の経度の値が不正です。', '入力エラー');
                        return false;
                    }
                }

                // 規制内容が「解除」の場合、規制解除日時の入力があるかチェック
                if (formData.regContentCd === '90' && !formData.regEndTimestamp) {
                    this.chain.info('規制解除日時が入力されていません。', '入力エラー');
                    return false;
                }

                // 規制解除日時の入力がある場合、規制内容が「解除」かチェック
                if (formData.regContentCd !== '90' && formData.regEndTimestamp) {
                    this.chain.info('規制解除日時を入力をする場合、規制内容を「解除」にしてください。', '入力エラー');
                    return false;
                }

                // 規制日時チェック
                if (formData.regStartTimestamp &&
                    formData.regEndTimestamp && formData.regStartTimestamp > formData.regEndTimestamp) {
                    this.chain.info('規制解除日時を入力をする場合、規制開始日時以降の日時にしてください。', '入力エラー');
                    return false;
                }
                if (formData.regStartTimestamp && !formData.regEndTimestamp && formData.regPlanedEndCd === '1' &&
                    formData.regPlanedEndTimestamp && formData.regStartTimestamp > formData.regPlanedEndTimestamp) {
                    this.chain.info('規制解除予定日時を入力をする場合、規制開始日時以降の日時にしてください。', '入力エラー');
                    return false;
                }
                return true;
            },

            /**
             * レイヤーの規制区間、工事区間のバリデーションを実施する。
             */
            validateLayer: function () {

                var errFlg = false;
                this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                    if (layer._latlngs.length < 2) {
                        errFlg = true;
                    }
                }));
                if (errFlg) {
                    this.chain.info('地図上の規制区間設定が不正です。', '入力エラー');
                    return false;
                }

                if (this.constructedLayer && this.constructedLayer._latlngs.length < 2) {
                    errFlg = true;
                }
                if (errFlg) {
                    this.chain.info('地図上の工事区間設定が不正です。', '入力エラー');
                    return false;
                }

                return true;
            },

            /**
             * 通行規制情報を登録する。
             * 新規登録と複製（コピー新規作成）で使用する。
             */
            registerTrafficRegulation: function () {
                this.inherited(arguments);

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm()) {
                    return false;
                }
                // レイヤーのバリデーションを行う（独自チェック）
                if (!this.validateLayer()) {
                    return false;
                }

                // URLからコピー新規作成フラグ取得
                var copyCreateFlg = Locator.getQuery().copyCreate;

                // フォームデータを取得
                var formData = this.form.get('value');

                // 広島以降のパッケージではpubStatusの概念がないため、常に4(全体公開)をセットする。
                formData.pubStatus = '4';

                // 報告番号はdisabledのため値がセットされていないので直接セットする
                formData.seqNum = this.seqNum.value;

                // 整備局番号
                formData.mainteNum = this.mainteNum.value;

                // 災害でない場合の親レイヤーの規定値
                if (!formData.parentLayerId) {
                    formData.parentLayerId = parentLayerIdCfg.DEFALUT_PARENT_LAYER_ID;
                }
                // ユーザの組織コードを取得する
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                        UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
                formData.organization = orgCd;

                // 道路種別がその他の場合はその他入力欄から路線名を取得
                // その他以外の場合はセレクトボックスのvalue（valueもdisplayedValueも路線名）が路線名になる
                if (this.roadTypeCd.get('value') === '99') {
                    formData.roadName = this.roadNameOther.get('value');
                }

                // 路線名その他入力欄は送信しない（使う場合は路線名として送信）
                delete formData.roadNameOther;
                // 帳票出力フラグは送信しない（サーバ側でセット）
                delete formData.exportFlg;

                // 規制要因によって区間入力内容をクリア
                this.clearSectionInput(formData);
                // 規制解除予定によって規制解除予定日時をクリア
                this.clearRegPlanedEndTimestampInput(formData);
                // 孤立集落によって戸数をクリア
                this.clearIsolatedHouseCountInput(formData);
                // 人的被害によって被害人数をクリア
                this.clearHumanDmgCountInput(formData);

                // 対応状況を格納する配列
                var trafficActions = [];
                // 対応状況をメモリーストアから取得して配列に格納
                this.trafficActionStore.fetch().forEach(function (object) {
                    // 不要なプロパティーを削除
                    delete object.id;
                    // 配列に格納
                    trafficActions.push(object);
                });
                // 対応状況をフォームデータにセット
                formData.trafficActions = trafficActions;

                // 添付ファイルは送信しない
                delete formData.attachFile;

                // 添付ファイルのIDのリストを送る
                var trafficAttachmentIds = [];
                for (var i = 0; i < this.trafficAttachments.length; i++) {
                    trafficAttachmentIds.push(this.trafficAttachments[i].trafficAttachmentId);
                }
                formData.trafficAttachmentIds = trafficAttachmentIds.join(',');

                // 作図、選択道路データ
                this.getGeoJSON().then(lang.hitch(this, function (geojson) {

                    // 作図データに追加するfeatureの配列
                    var addgeojson = [];
                    // 規制区間を足す
                    this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                        addgeojson.push(this.regulatedLayerToGeoJSON(layer));
                    }));
                    // 被災箇所を足す
                    addgeojson.push(this.damagedLayerToGeoJSON(this.damagedLayer));
                    this.addDamagedLayers.forEach(lang.hitch(this, function (layer) {
                        addgeojson.push(this.addDamagedLayerToGeoJSON(layer));
                    }));
                    // 工事区間を足す
                    addgeojson.push(this.constructedLayerToGeoJSON(this.constructedLayer));

                    // 規制区間・被災箇所・工事区間が空でなかったら要素を足す
                    geojson.features = addgeojson.filter(function (o) {
                        return o;
                    }).concat(geojson.features);

                    // 規制解除日時が入力されている場合は、各featureに規制解除済みを示すプロパティーをセットする
                    // 入力されていない場合は解除済みを示すプロパティーを削除する
                    if (this.regEndTimestamp.get('value')) {
                        geojson.features.forEach(lang.hitch(this, function (feature) {
                            feature.properties.isRegEnd = true;
                        }));
                    } else {
                        geojson.features.forEach(lang.hitch(this, function (feature) {
                            delete feature.properties.isRegEnd;
                        }));
                    }

                    formData.drawJson = json.stringify(geojson);

                    // 登録確認ダイアログを表示
                    this.chain.confirmAdd(function (chain) {
                        var jsonStr = json.stringify(formData);
                        console.debug(jsonStr);

                        var promise = Requester.post('/api/traffic/regulations', {
                            data: formData
                        });
                        // ローダーの表示
                        Loader.wait(promise).then(function (data) {
                            console.debug(data);
                            // レイヤーをリフレッシュする
                            // LayerStore.refresh(data.parentLayerId);
                            // 続報登録では複数のレイヤーが更新されるの可能性があるので全てリフレッシュする
                            LayerStore.refreshAll();
                            chain.infoComplete(function () {
                                // 一覧画面に移動
                                Router.moveTo('traffic');
                            });
                        }, function (error) {
                            chain.infoError(error, function () {
                                // 一覧画面に移動
                                Router.moveTo('traffic');
                            });
                        });
                    });
                }));
            },

            /**
             * 通行規制情報を更新する。
             */
            updateTrafficRegulation: function () {
                // 詳細画面用の子クラスで実装
            },

            /**
             * 通行規制情報を削除する。
             */
            deleteTrafficRegulation: function () {
                // 詳細画面用の子クラスで実装
            },

            /**
             * 通行規制情報を復元する。
             */
            restoreTrafficRegulation: function () {
                // 詳細画面用の子クラスで実装
            },

            /**
             * 続報登録画面に遷移する。
             */
            createFollowUpTrafficRegulation: function () {
                // 詳細画面用の子クラスで実装
            },

            /**
             * コピー新規登録画面に遷移する。
             */
            copyCreateTrafficRegulation: function () {
                var trafficRegulationId = Locator.getQuery().id;
                Router.moveTo('traffic/register', {
                    id: trafficRegulationId,
                    copyCreate: true
                });
            },

            /**
             * Excel帳票（個票）を出力する。
             */
            outputDetailExcel: function () {
                // 詳細画面用の子クラスで実装
            },

            /**
             * キャンセルボタンを押したときに呼ばれる。
             */
            leave: function () {
                this.inherited(arguments);
                //作図イベントを破棄
                topic.publish(this.DRAW_EVT_OFF);
            },

            /**
             * 規制要因CDが変更されたときに呼ばれる。
             */
            regReasonCdChanged: function (regReasonCd) {
                // 規制要因によって入力項目を変更
                this.updateSectionInput();

                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();

                // 災害名を設定する（災害名が変更可能である場合）
                if (!this.disasterId.get('noAllButton')) {
                    this.updateDisasterInput();
                }

            },

            /**
             * 災害名を設定する
             */
            updateDisasterInput: function () {
                // 災害
                if (this.regReasonCd.value === '01') {
                    // 災害IDが未設定の場合
                    if (!this.disasterId.value) {
                        // サーバーから最新の災害IDを取得する
                        var url = '/api/traffic/latestDisasterId';
                        Requester.get(url).then(lang.hitch(this, function (item) {
                            // 災害情報を取得し画面へ表示
                            this.disasterId.set('value', item);
                            // サーバーからデフォルトの格納先を取得する
                            this.getDefaultParent(item);
                        }), function (error) {
                            console.debug(error);
                        });
                    } else {
                        // サーバーからデフォルトの格納先を取得する
                        this.getDefaultParent(this.disasterId.value);
                    }
                } else {
                    this.disasterId.set('value', '');
                    this.parentLayerId.set('value', '');
                }

            },

            /**
             * 災害名を設定する
             */
            updateDisasterInputDetail: function () {
                if (this.disasterId.value) {
                    // サーバーからデフォルトの格納先を取得する
                    this.getDefaultParent(this.disasterId.value);
                } else {
                    this.parentLayerId.set('value', '');
                }

            },

            /**
             * 区間入力（規制区間、被災箇所、工事区間）の表示状態を更新する
             */
            updateSectionInput: function () {
                // 規制要因によって入力項目を変更
                switch (this.regReasonCd.value) {
                    // 災害
                    case '01':
                        domStyle.set(this.dmgPointSection, 'display', '');
                        domStyle.set(this.constPointSection, 'display', 'none');
                        break;
                    // 工事
                    case '02':
                        domStyle.set(this.dmgPointSection, 'display', 'none');
                        domStyle.set(this.constPointSection, 'display', '');
                        break;
                    // 事前
                    case '03':
                    // その他
                    case '99':
                        domStyle.set(this.dmgPointSection, 'display', 'none');
                        domStyle.set(this.constPointSection, 'display', 'none');
                        break;
                    default:
                        domStyle.set(this.dmgPointSection, 'display', 'none');
                        domStyle.set(this.constPointSection, 'display', 'none');
                        break;
                }

            },

            /**
             * 区間入力（被災箇所、工事区間）の入力内容をクリアする
             */
            clearSectionInput: function (formData) {
                // 規制要因によって入力項目をクリア
                switch (this.regReasonCd.value) {
                    // 災害
                    case '01':
                        // 工事区間のレイヤーを削除する
                        this.removeConstructedLayer();
                        // 工事区間をクリア
                        this.clearConstructedSectionInput(formData);
                        break;
                    // 工事
                    case '02':
                        // 被災箇所のレイヤーを削除する
                        this.removeDamagedLayer();
                        // 被災箇所をクリア
                        this.clearDamagedSectionInput(formData);
                        break;
                    // 事前
                    case '03':
                    // その他
                    case '99':
                        // 被災箇所のレイヤーを削除する
                        this.removeDamagedLayer();
                        // 被災箇所をクリア
                        this.clearDamagedSectionInput(formData);
                        // 工事区間のレイヤーを削除する
                        this.removeConstructedLayer();
                        // 工事区間をクリア
                        this.clearConstructedSectionInput(formData);
                        break;
                }
            },

            /**
             * 被災箇所の入力内容をクリアする
             */
            clearDamagedSectionInput: function (formData) {
                // 被災箇所をクリア
                formData.dmgPointName = '';
                formData.dmgPointNameKana = '';
                formData.dmgPointLat = '';
                formData.dmgPointLng = '';
            },

            /**
             * 工事区間の入力内容をクリアする
             */
            clearConstructedSectionInput: function (formData) {
                // 工事区間をクリア
                formData.constStartPointName = '';
                formData.constStartPointNameKana = '';
                formData.constStartPointLat = '';
                formData.constStartPointLng = '';
                formData.constEndPointName = '';
                formData.constEndPointNameKana = '';
                formData.constEndPointLat = '';
                formData.constEndPointLng = '';
            },

            /**
             * 道路種別が変更されたときに呼ばれる。
             */
            roadTypeCdChanged: function (roadTypeCd) {
                // 初期ロード時はここでは何もしない
                if (this.isInStartup) {
                    return;
                }
                // 事前規制区間設定時はここでは何もしない
                if (this.isSettingRegSection) {
                    return;
                }
                // その他の場合
                if (roadTypeCd === '99') {
                    // 路線名セレクトボックスを非表示
                    this.roadName.set('style', {
                        display: 'none'
                    });
                    // 路線名その他入力欄を表示
                    this.roadNameOther.set('style', {
                        display: ''
                    });
                    // 路線名のセレクトボックスをクリアする
                    this.clearRoadNameList();
                    // その他以外の場合
                } else {
                    // 路線名セレクトボックスを表示
                    this.roadName.set('style', {
                        display: ''
                    });
                    // 路線名その他入力欄は非表示
                    this.roadNameOther.set('style', {
                        display: 'none'
                    });
                    // セレクトボックスを初期化する
                    this.initRoadNameList(roadTypeCd);
                }
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 路線名のセレクトボックスを初期化する。
             */
            initRoadNameList: function (roadTypeCd) {
                // 路線名のセレクトボックスができたらアクションをとりたいことがあるので、Deferredを作る
                var dfd = new Deferred();
                // 路線名のセレクトボックスをクリアする
                this.clearRoadNameList();
                // 道路種別が空だったらそこで終了
                if (!roadTypeCd) {
                    dfd.resolve();
                    return dfd;
                }
                // 道路種別をキーに路線を取得
                var promise = Requester.get('/data/traffic/' + roadTypeCd + '.geojson');
                // ローダーの表示
                Loader.wait(promise).then(lang.hitch(this, function (data) {
                    // セレクトボックスに設定するデータの配列を作成
                    var newOptions = [{
                        label: '&nbsp;',
                        value: null
                    }];
                    // configから道路種別の設定をとる
                    var roadConfig = road[roadTypeCd];
                    // サーバーレスポンスの各要素をselectへ追加
                    data.features.forEach(lang.hitch(this, function (item) {
                        if (!item.properties[roadConfig.nameKey]) {
                            return;
                        }
                        var roadNum = item.properties[roadConfig.key];
                        var roadName = item.properties[roadConfig.nameKey];
                        newOptions.push({
                            label: roadConfig.prefix + (roadNum ? '(' + roadNum + ') ' : '') + roadName + roadConfig.suffix,
                            value: roadConfig.prefix + roadName + roadConfig.suffix,
                            roadNum: roadNum,
                            geojson: item
                        });
                    }));
                    // セレクトボックスにオプションを設定する
                    registry.byId('roadName').set('options', newOptions).reset();
                    // Deferredをresolveして待っているアクションを実行させる
                    dfd.resolve();
                }), function (error) {
                    console.debug(error);
                    dfd.reject();
                });
                return dfd;
            },

            /**
             * 路線名が変更されたときに呼ばれる。
             */
            roadNameChanged: function (name) {
                // 初期ロード時はここでは何もしない
                if (this.isInStartup) {
                    return;
                }
                // 事前規制区間設定時はここでは何もしない
                if (this.isSettingRegSection) {
                    return;
                }
                // 路線名セレクトボックスのoptionを取得
                var option = registry.byId('roadName').getOptions(name);
                // 路線番号を設定
                if (option.roadNum) {
                    this.roadNum.set('value', option.roadNum);
                } else {
                    this.roadNum.set('value', '');
                }
                // 路線全選択モードフラグをクリアする
                this.isSelectAllRoadMode = false;
                // 事前定義済み規制区間モードフラグをクリアする
                this.isRegSectionMode = false;
                // 切り出しモードフラグをクリアする
                this.isCutMode = false;
                // 既に表示されている路線がある場合は消す
                this.removeRoadLayerGroup();
                // value(路線名)が空(無選択状態)だったら終了
                if (!option.value) {
                    return;
                }
                // 地図上に路線を表示する
                this.drawRoadByName(name);
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 地図上の通行規制関連ボタンの表示状態を更新する
             */
            updateTrafficButtons: function () {
                var dmgPointFlg = false;
                var constPointFlg = false;
                // 規制要因によって入力項目を変更
                switch (this.regReasonCd.value) {
                    // 災害
                    case '01':
                        dmgPointFlg = true;
                        break;
                    // 工事
                    case '02':
                        constPointFlg = true;
                        break;
                    // 事前
                    case '03':
                    // その他
                    case '99':
                        break;
                    default:
                        break;
                }

                // 被災箇所設定ボタン、被災箇所追加ボタンをいったん非表示
                domStyle.set(this.enableSelectDamagedSectionButton, 'display', 'none');
                domStyle.set(this.enableAddDamagedSectionButton, 'display', 'none');
                // 工事区間設定ボタンをいったん非表示
                domStyle.set(this.enableSelectConstructedSectionButton, 'display', 'none');

                // 路線全選択モードまたは事前定義済み規制区間モード時
                if (this.isSelectAllRoadMode || this.isRegSectionMode) {
                    // 路線登録ボタンを非表示
                    domStyle.set(this.registerRoadButton, 'display', 'none');
                    // 路線全選択ボタンを非表示
                    // domStyle.set(this.selectAllRoadButton, 'display', 'none');
                    // 規制区間設定ボタンを非表示
                    domStyle.set(this.enableSelectRegulatedSectionButton, 'display', 'none');
                    // 被災箇所設定ボタン、被災箇所追加ボタンを非表示
                    domStyle.set(this.enableSelectDamagedSectionButton, 'display', 'none');
                    domStyle.set(this.enableAddDamagedSectionButton, 'display', 'none');
                    // 工事区間設定ボタンを非表示
                    domStyle.set(this.enableSelectConstructedSectionButton, 'display', 'none');
                    // クリアボタンを表示
                    domStyle.set(this.clearRegulatedSectionButton, 'display', '');
                    return;
                }

                // 切り出しモードの場合
                if (this.isCutMode) {
                    // 路線登録ボタンを非表示
                    domStyle.set(this.registerRoadButton, 'display', 'none');
                    // 路線全選択ボタンを非表示
                    // domStyle.set(this.selectAllRoadButton, 'display', 'none');
                    // 規制区間設定ボタンを表示
                    domStyle.set(this.enableSelectRegulatedSectionButton, 'display', '');
                    // 被災箇所設定ボタン、被災箇所追加ボタンを表示
                    if (dmgPointFlg) {
                        domStyle.set(this.enableSelectDamagedSectionButton, 'display', '');
                        domStyle.set(this.enableAddDamagedSectionButton, 'display', '');
                    }
                    // 工事区間設定ボタンを表示
                    if (constPointFlg) {
                        domStyle.set(this.enableSelectConstructedSectionButton, 'display', '');
                    }
                    // クリアボタンを表示
                    domStyle.set(this.clearRegulatedSectionButton, 'display', '');
                    return;
                }

                // モード未選択の場合
                // 路線が描画されている場合
                if (this.roadLayerGroup !== null) {
                    // 路線登録ボタンを非表示
                    domStyle.set(this.registerRoadButton, 'display', 'none');
                    // 路線全選択ボタンを表示
                    // domStyle.set(this.selectAllRoadButton, 'display', '');
                    // 規制箇所設定ボタンを表示
                    domStyle.set(this.enableSelectRegulatedSectionButton, 'display', '');
                    // 被災箇所設定ボタン、被災箇所追加ボタンを表示
                    if (dmgPointFlg) {
                        domStyle.set(this.enableSelectDamagedSectionButton, 'display', '');
                        domStyle.set(this.enableAddDamagedSectionButton, 'display', '');
                    }
                    // 工事区間設定ボタンを表示
                    if (constPointFlg) {
                        domStyle.set(this.enableSelectConstructedSectionButton, 'display', '');
                    }
                    // 路線が描画されていない場合
                } else {
                    // 道路種別がその他の場合
                    if (this.roadTypeCd.get('value') === '99') {
                        // 路線登録ボタンを表示
                        domStyle.set(this.registerRoadButton, 'display', '');
                        // 道路種別がその他以外の場合
                    } else {
                        // 路線登録ボタンを非表示
                        domStyle.set(this.registerRoadButton, 'display', 'none');
                    }
                    // 路線全選択ボタンを非表示
                    // domStyle.set(this.selectAllRoadButton, 'display', 'none');
                    // 規制区間設定ボタンを非表示
                    domStyle.set(this.enableSelectRegulatedSectionButton, 'display', 'none');
                    // 被災箇所設定ボタン、被災箇所追加ボタンを非表示
                    domStyle.set(this.enableSelectDamagedSectionButton, 'display', 'none');
                    domStyle.set(this.enableAddDamagedSectionButton, 'display', 'none');
                    // 工事区間設定ボタンを非表示
                    domStyle.set(this.enableSelectConstructedSectionButton, 'display', 'none');
                }

                // 規制区間または被災箇所がある場合
                if (this.regulatedLayers[0] || this.damagedLayer) {
                    // 路線全選択ボタンを非表示
                    // domStyle.set(this.selectAllRoadButton, 'display', 'none');
                    // クリアボタンを表示
                    domStyle.set(this.clearRegulatedSectionButton, 'display', '');
                    // 規制区間、被災区間のいずれもない場合
                } else {
                    // クリアボタンを非表示
                    domStyle.set(this.clearRegulatedSectionButton, 'display', 'none');
                }
            },

            /**
             * 地図上に路線を表示する。
             */
            drawRoadByName: function (name) {
                // 地図上に路線を表示できたらアクションをとりたいことがあるので、Deferredを作る
                var dfd = new Deferred();
                // 路線名セレクトボックスのoptionを取得
                var option = registry.byId('roadName').getOptions(name);
                // value(路線名)が空(無選択状態)だったらそこで終了
                if (!option || !option.value) {
                    dfd.resolve();
                    return dfd;
                }
                // 道路を描画する
                this.drawSelectedRoad(option.geojson).then(lang.hitch(this, function () {
                    // 初期ロード時はここでは地図を移動しない
                    if (this.isInStartup) {
                        dfd.resolve();
                        return;
                    }
                    // 事前規制区間設定時はここでは地図を移動しない
                    if (this.isSettingRegSection) {
                        dfd.resolve();
                        return;
                    }
                    // 地図を移動
                    this.map.fitBounds(this.roadLayerGroup.getBounds(), {
                        padding: [50, 50]
                    });
                    // Deferredをresolveして待っているアクションを実行させる
                    dfd.resolve();
                }));

                return dfd;
            },

            /**
             * 選択された道路のshapeを地図に表示する
             * shapeはgeojsonで提供される
             * {"type": "feature", "properties": {}, "geometry": {"coodinates": []}}
             * が構成要素として必要
             * @param  {Object} geojson 道路を表すオブジェクト
             */
            drawSelectedRoad: function (geojson) {
                var dfd = new Deferred();

                this.roadLayerGroup = new leaflet.geoJson().addTo(this.map);
                this.roadLayerGroup.once('layeradd', lang.hitch(this, function () {
                    dfd.resolve();
                }));
                this.roadLayerGroup.addData(geojson);
                this.roadLayerGroup.setStyle(this.selectedStyle);

                return dfd;
            },

            /**
             * 路線登録ボタンが押されたときのアクション
             * @return {[type]} [description]
             */
            registerRoad: function () {
                // 作図からLineStringのgeojsonをもらう
                this.getLineStringGeoJSON().then(lang.hitch(this, function (geojson) {
                    // 作図データがあるかチェック
                    if (!geojson || geojson.features.length === 0) {
                        this.chain.info('線の作図が必要です。', '情報');
                        return;
                    }
                    // 路線がある場合は消す
                    this.removeRoadLayerGroup();
                    // 路線レイヤーグループを作り直す
                    // セレクトボックスから路線レイヤーグループが作成された場合は
                    // MultiLineStringであるため階層が一つ深くなっている
                    // それに合わせるためにフィーチャーグループを作ってその中にいれる
                    this.roadLayerGroup = new leaflet.featureGroup().addTo(this.map);
                    var drawedRoadLayer = new leaflet.geoJson().addData(geojson);
                    this.roadLayerGroup.addLayer(drawedRoadLayer);
                    this.roadLayerGroup.setStyle(this.selectedStyle);
                    // 地図上の通行規制関連ボタンの表示状態を更新する
                    this.updateTrafficButtons();
                }));
            },

            /**
             * 路線全選択モードで路線をクリックしたときのアクション
             * 路線を地図から削除する
             *
             * @param  {Object} event クリックイベント、クリックされた路線のレイヤーなどが入っている
             */
            onAllRoadClick: function (event) {
                // クリックされた路線を規制区間から除外する
                var newRegulatedLayers = [];
                this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                    if (leaflet.stamp(event.target) === leaflet.stamp(layer)) {
                        // 地図からレイヤーを削除
                        this.map.removeLayer(layer);
                    } else {
                        newRegulatedLayers.push(layer);
                    }
                }));
                // 作り直した配列
                this.regulatedLayers = newRegulatedLayers;
            },

            /**
             * 規制区間全選択ボタンが押されたときのアクション
             * @return {[type]} [description]
             */
            selectAllRoad: function () {
                if (!this.roadLayerGroup) {
                    this.chain.info('路線の選択が必要です。', '情報');
                    return;
                }
                // 規制区間の選択状態をクリアする
                this.clearRegulatedSection();
                // 路線のレイヤーを全てクローンし、すべて規制区間の配列に入れる
                this.roadLayerGroup.getLayers()[0].getLayers().forEach(lang.hitch(this, function (layer) {
                    // cloneFuncが関数を返すので、即時関数として実行してレイヤーを得る
                    this.regulatedLayers.push(layer.cloneFunc()());
                }));
                // ハイライトして地図上に表示する
                this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                    layer.setStyle(this.extractedStyle).addTo(this.map);
                    // 各規制区間にイベントを付与
                    layer.on('click', lang.hitch(this, this.onAllRoadClick));
                }));
                // 路線レイヤーグループからイベントを除去
                this.roadLayerGroup.off('click');
                // 路線全選択モードフラグをセットする
                this.isSelectAllRoadMode = true;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 選択マーカーを返す
             */
            getSelectedMarker: function (latlng) {
                return leaflet.marker(latlng, {
                    icon: new leaflet.icon({
                        iconUrl: 'images/draw/icon/085.png',
                        iconAnchor: [10, 10]
                    }),
                    draggable: true
                });
            },

            /**
             * 被災地点マーカーを返す
             */
            getDamagedMarker: function (latlng) {
                return leaflet.marker(latlng, {
                    icon: new leaflet.icon({
                        iconUrl: 'images/draw/icon/089.png',
                        iconAnchor: [10, 10]
                    }),
                    draggable: true
                });
            },

            /**
             * 被災地点（追加）マーカーを返す
             */
            getAddDamagedMarker: function (latlng) {
                return leaflet.marker(latlng, {
                    icon: new leaflet.icon({
                        iconUrl: 'images/draw/icon/093.png',
                        iconAnchor: [10, 10]
                    }),
                    draggable: true
                });
            },

            /**
             * 規制区間設定ボタンが押された時のアクション
             */
            enableSelectRegulatedSection: function () {
                // 路線が選択されていない場合は何もしない
                if (this.roadLayerGroup === null) {
                    this.chain.info('路線の選択が必要です。', '情報');
                    return;
                }
                // 終点が未設定ならボタンを作図中の色に変更、終点設定済みなら色戻す
                this.toggleSelectButtonColor(this.endRegulation ? null : this.enableSelectRegulatedSectionButton);
                // すでに規制区間が登録されていたらマーカーをセットし直して終了
                if (this.regulatedLayers[0]) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 規制区間からイベントをはずす
                    this.regulatedLayers[0].off('click');
                    // 起点マーカーを追加
                    if (this.beginRegulation) {
                        this.selectedMarkers.addLayer(this.beginRegulation);
                        this.beginRegulation.on('dragend', lang.hitch(this, this.moveRegulatedMarker));
                    }
                    // 終点マーカーを追加
                    if (this.endRegulation) {
                        this.selectedMarkers.addLayer(this.endRegulation);
                        this.endRegulation.on('dragend', lang.hitch(this, this.moveRegulatedMarker));
                    }
                    return;
                }
                // マーカーを削除
                this.selectedMarkers.clearLayers();
                // 路線レイヤーグループからイベントをはずす
                this.roadLayerGroup.off('click');
                // 路線レイヤグループにイベントを付与する
                this.roadLayerGroup.on('click', lang.hitch(this, this.onRoadClick));
                // 切り出しモードフラグをセット
                this.isCutMode = true;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 被災箇所設定ボタンが押された時のアクション
             */
            enableSelectDamagedSection: function () {
                // 路線が選択されていない場合は何もしない
                if (this.roadLayerGroup === null) {
                    this.chain.info('路線の選択が必要です。', '情報');
                    return;
                }
                // すでに被災箇所が登録されていたらマーカーをセットし直して終了
                if (this.damagedLayer) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 規制区間がある場合
                    if (this.regulatedLayers[0]) {
                        // 規制区間にイベントを付与する
                        this.regulatedLayers[0].off('click');
                        this.regulatedLayers[0].on('click', lang.hitch(this, this.onRegulatedClick));
                        // 規制区間がない場合
                    } else {
                        this.roadLayerGroup.off('click');
                        this.roadLayerGroup.on('click', lang.hitch(this, this.onRoadClickToDamaged));
                    }
                    // マーカーを追加
                    if (this.damaged) {
                        this.damaged.on('dragend', lang.hitch(this, this.moveDamagedMarker));
                    }
                    // ボタンの色戻す
                    this.toggleSelectButtonColor();
                    return;
                }
                // 規制区間がある場合
                if (this.regulatedLayers[0]) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 規制区間にイベントを付与する
                    this.regulatedLayers[0].off('click');
                    this.regulatedLayers[0].on('click', lang.hitch(this, this.onRegulatedClick));
                    // ボタン色変更
                    this.toggleSelectButtonColor(this.enableSelectDamagedSectionButton);
                    // 規制区間がない場合
                } else {
                    // 規制区間がない場合は確認ダイアログを表示
                    this.chain.confirm('規制区間が設定されていません。規制区間を設定せずに被災箇所を設定しますか？', function (chain) {
                        // 確認ダイアログでOKを押した場合
                        chain.hide();
                        // 路線レイヤーグループにイベントを付与する
                        this.roadLayerGroup.off('click');
                        this.roadLayerGroup.on('click', lang.hitch(this, this.onRoadClickToDamaged));
                        // ボタン色変更
                        this.toggleSelectButtonColor(this.enableSelectDamagedSectionButton);
                    });
                }
                // 切り出しモードフラグをセット
                this.isCutMode = true;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 被災箇所追加ボタンが押された時のアクション
             */
            enableAddDamagedSection: function () {
                // 路線が選択されていない場合は何もしない
                if (this.roadLayerGroup === null) {
                    this.chain.info('路線の選択が必要です。', '情報');
                    return;
                }
                if (this.damagedLayer === null) {
                    this.chain.info('被災箇所の設定が必要です。', '情報');
                    return;
                }

                // すでに被災箇所が登録されていたらマーカーをセットし直して終了
                if (this.addDamagedLayers.length !== 0) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 規制区間がある場合
                    if (this.regulatedLayers[0]) {
                        // 規制区間にイベントを付与する
                        this.regulatedLayers[0].off('click');
                        this.regulatedLayers[0].on('click', lang.hitch(this, this.onRegulatedAddDamageClick));
                        // 規制区間がない場合
                    } else {
                        // 路線レイヤーグループにイベントを付与する
                        this.roadLayerGroup.off('click');
                        this.roadLayerGroup.on('click', lang.hitch(this, this.onRoadClickToAddDamaged));
                    }
                    // マーカーを追加
                    this.addDamaged.forEach(lang.hitch(this, function (damage) {
                        if (damage) {
                            damage.on('dragend', lang.hitch(this, this.moveAddDamagedMarker));
                        }
                    }));
                    // ボタン色変更
                    this.toggleSelectButtonColor(this.enableAddDamagedSectionButton);
                    return;
                }
                // 規制区間がある場合
                if (this.regulatedLayers[0]) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 規制区間にイベントを付与する
                    this.regulatedLayers[0].off('click');
                    this.regulatedLayers[0].on('click', lang.hitch(this, this.onRegulatedAddDamageClick));
                    // ボタン色変更
                    this.toggleSelectButtonColor(this.enableAddDamagedSectionButton);
                    // 規制区間がない場合
                } else {
                    // 規制区間がない場合は確認ダイアログを表示
                    this.chain.confirm('規制区間が設定されていません。規制区間を設定せずに被災箇所を追加しますか？', function (chain) {
                        // 確認ダイアログでOKを押した場合
                        chain.hide();
                        // 路線レイヤーグループにイベントを付与する
                        this.roadLayerGroup.off('click');
                        this.roadLayerGroup.on('click', lang.hitch(this, this.onRoadClickToAddDamaged));
                        // ボタン色変更
                        this.toggleSelectButtonColor(this.enableAddDamagedSectionButton);
                    });
                }
                // 切り出しモードフラグをセット
                this.isCutMode = true;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 工事区間設定ボタンが押された時のアクション
             */
            enableSelectConstructedSection: function () {
                // 路線が選択されていない場合は何もしない
                if (this.roadLayerGroup === null) {
                    this.chain.info('路線の選択が必要です。', '情報');
                    return;
                }
                // すでに被災区間が登録されていたらマーカーをセットし直して終了
                if (this.constructedLayer) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 起点マーカーを追加
                    if (this.beginConstructed) {
                        this.selectedMarkers.addLayer(this.beginConstructed);
                        this.beginConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    }
                    // 終点マーカーを追加
                    if (this.endConstructed) {
                        this.selectedMarkers.addLayer(this.endConstructed);
                        this.endConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    }
                    // 終点が未設定ならボタンを作図中の色に変更、終点設定済みなら色戻す
                    this.toggleSelectButtonColor(this.endConstructed ? null :
                        this.enableSelectConstructedSectionButton);
                    return;
                }
                // 規制区間がある場合
                if (this.regulatedLayers[0]) {
                    // マーカーを削除
                    this.selectedMarkers.clearLayers();
                    // 規制区間にイベントを付与する
                    this.regulatedLayers[0].off('click');
                    this.regulatedLayers[0].on('click', lang.hitch(this, this.onRegulatedConstructedClick));
                    // ボタン色変更
                    this.toggleSelectButtonColor(this.enableSelectConstructedSectionButton);
                    // 規制区間がない場合
                } else {
                    // 規制区間がない場合は確認ダイアログを表示
                    this.chain.confirm('規制区間が設定されていません。規制区間を設定せずに工事区間を設定しますか？', function (chain) {
                        // 確認ダイアログでOKを押した場合
                        chain.hide();
                        // 路線レイヤーグループにイベントを付与する
                        this.roadLayerGroup.off('click');
                        this.roadLayerGroup.on('click', lang.hitch(this, this.onRoadClickToConstructed));
                        // ボタン色変更
                        this.toggleSelectButtonColor(this.enableSelectConstructedSectionButton);
                    });
                }
                // 切り出しモードフラグをセット
                this.isCutMode = true;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 規制区間を設定するために、路線をクリックしたときのアクション
             * @param  {Object} event クリックイベント、クリックされた路線のレイヤーなどが入っている
             */
            onRoadClick: function (event) {
                // 押されたレイヤー(道路1本)が先に押されたものと違う場合、前回の状態をご破算にする
                if (this.selectedLayer && leaflet.stamp(event.layer) !== leaflet.stamp(this.selectedLayer)) {
                    this.clearRegulatedSection();
                    this.enableSelectRegulatedSection();
                }
                // クリックされたレイヤーを選択レイヤーとする
                this.selectedLayer = event.layer;
                // 最初のクリック: 範囲選択開始

                if (!this.beginRegulation) {
                    // markerを書く
                    this.beginRegulation = this.getSelectedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.beginRegulation);
                    this.beginRegulation.on('dragend', lang.hitch(this, this.moveRegulatedMarker));
                    // 選択されたpolylineを目立たせる
                    this.highlightLine(event.layer);
                    // ジオコード
                    this.fillGeoGraphicForm('regStartPoint', this.beginRegulation);
                    // 2回目のクリック: 範囲選択終了
                } else if (!this.endRegulation) {
                    // markerを書く
                    this.endRegulation = this.getSelectedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.endRegulation);
                    this.endRegulation.on('dragend', lang.hitch(this, this.moveRegulatedMarker));
                    // 選択範囲の切り出し
                    this.regulatedLayers[0] = this.extractSelectedRoad(
                        this.selectedLayer, this.beginRegulation, this.endRegulation)
                        .setStyle(this.extractedStyle);
                    this.regulatedLayers[0].addTo(this.map);
                    // ジオコード
                    this.fillGeoGraphicForm('regEndPoint', this.endRegulation);
                    // もし被災箇所がある場合は被災区間のレイヤーを前に持ってくる
                    if (this.damagedLayer) {
                        this.damagedLayer.bringToFront();
                    }
                    // もし工事区間がある場合は工事区間のレイヤーを前に持ってくる
                    if (this.constructedLayer) {
                        this.constructedLayer.bringToFront();
                    }
                    // ボタン色変更
                    this.toggleSelectButtonColor();
                }
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 道路をクリックして被災箇所を指定する時のアクション
             * ※規制区間がない場合
             * @param  {Object} event ILayerのonClickイベント
             */
            onRoadClickToDamaged: function (event) {
                // 押されたレイヤー(道路1本)が先に押されたものと違う場合、前回の状態をご破算にする
                if (this.selectedLayer && leaflet.stamp(event.layer) !== leaflet.stamp(this.selectedLayer)) {
                    this.clearDamagedSection();
                }
                // クリックされたレイヤーを選択レイヤーとする
                this.selectedLayer = event.layer;
                // クリック: 地点選択
                if (!this.damaged) {
                    // markerを書く
                    this.damaged = this.getDamagedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.damaged);
                    this.damaged.on('dragend', lang.hitch(this, this.moveDamagedMarker));
                    // 選択されたpolylineを目立たせる
                    this.highlightLine(event.layer);
                    // レイヤー
                    this.damagedLayer = this.damaged;
                    this.damagedLayer.addTo(this.map);
                    // ジオコード
                    this.fillGeoGraphicForm('dmgPoint', this.damaged);
                    // ボタン色変更
                    this.toggleSelectButtonColor();
                }
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 道路をクリックして被災箇所を追加する時のアクション
             * ※規制区間がない場合
             * @param  {Object} event ILayerのonClickイベント
             */
            onRoadClickToAddDamaged: function (event) {
                // 押されたレイヤー(道路1本)が先に押されたものと違う場合、前回の状態をご破算にする
                if (this.selectedLayer && leaflet.stamp(event.layer) !== leaflet.stamp(this.selectedLayer)) {
                    this.clearDamagedSection();
                }
                // クリックされたレイヤーを選択レイヤーとする
                this.selectedLayer = event.layer;
                // クリック: 地点選択
                // markerを書く
                this.addDamaged.push(this.getAddDamagedMarker(event.latlng));
                this.addDamaged[this.addDamaged.length - 1].on('dragend', lang.hitch(this, this.moveAddDamagedMarker));
                // 選択されたpolylineを目立たせる
                this.highlightLine(event.layer);
                // レイヤー
                this.addDamagedLayers.push(this.addDamaged[this.addDamaged.length - 1]);
                this.addDamagedLayers[this.addDamagedLayers.length - 1].addTo(this.map);

                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 道路をクリックして工事区間を指定する時のアクション
             * ※規制区間がない場合
             * @param  {Object} event ILayerのonClickイベント
             */
            onRoadClickToConstructed: function (event) {
                // 押されたレイヤー(道路1本)が先に押されたものと違う場合、前回の状態をご破算にする
                if (this.selectedLayer && leaflet.stamp(event.layer) !== leaflet.stamp(this.selectedLayer)) {
                    this.clearConstructedSection();
                }
                // クリックされたレイヤーを選択レイヤーとする
                this.selectedLayer = event.layer;
                // 最初のクリック: 範囲選択開始
                if (!this.beginConstructed) {
                    // markerを書く
                    this.beginConstructed = this.getSelectedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.beginConstructed);
                    this.beginConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    // 選択されたpolylineを目立たせる
                    this.highlightLine(event.layer);
                    // ジオコード
                    this.fillGeoGraphicForm('constStartPoint', this.beginConstructed);
                    // 2回目のクリック: 範囲選択終了
                } else if (!this.endConstructed) {
                    // markerを書く
                    this.endConstructed = this.getSelectedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.endConstructed);
                    this.endConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    // 選択範囲の切り出し
                    this.constructedLayer = this.extractSelectedRoad(
                        this.selectedLayer, this.beginConstructed, this.endConstructed)
                        .setStyle(this.constructedStyle);
                    this.constructedLayer.addTo(this.map);
                    // ジオコード
                    this.fillGeoGraphicForm('constEndPoint', this.endConstructed);
                    // ボタン色変更
                    this.toggleSelectButtonColor();
                }
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 規制区間をクリックして被災箇所を指定する時のアクション
             * ※規制区間がある場合
             * @param  {Object} event ILayerのonClickイベント
             */
            onRegulatedClick: function (event) {
                if (!this.damaged) {
                    // markerを書く
                    this.damaged = this.getDamagedMarker(event.latlng);
                    this.damaged.on('dragend', lang.hitch(this, this.moveDamagedMarker));
                    // レイヤー
                    this.damagedLayer = this.damaged;
                    this.damagedLayer.addTo(this.map);
                    // ジオコード
                    this.fillGeoGraphicForm('dmgPoint', this.damaged);
                }

                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 規制区間をクリックして被災箇所を追加する時のアクション
             * ※規制区間がある場合
             * @param  {Object} event ILayerのonClickイベント
             */
            onRegulatedAddDamageClick: function (event) {
                // markerを書く
                this.addDamaged.push(this.getAddDamagedMarker(event.latlng));
                var target = this.addDamaged[this.addDamaged.length - 1];
                target.on('dragend', lang.hitch(this, this.moveAddDamagedMarker));
                target.on('click', lang.hitch(this, this.removeAddDamagedMarker));
                // レイヤー
                this.addDamagedLayers.push(target);
                this.addDamagedLayers[this.addDamagedLayers.length - 1].addTo(this.map);

                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 規制区間をクリックして工事区間を指定する時のアクション
             * ※規制区間がある場合
             * @param  {Object} event ILayerのonClickイベント
             */
            onRegulatedConstructedClick: function (event) {
                // 最初のクリック: 範囲選択開始
                if (!this.beginConstructed) {
                    // markerを書く
                    this.beginConstructed = this.getSelectedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.beginConstructed);
                    this.beginConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    // ジオコード
                    this.fillGeoGraphicForm('constStartPoint', this.beginConstructed);
                    // 2回目のクリック: 範囲選択終了
                } else if (!this.endConstructed) {
                    // markerを書く
                    this.endConstructed = this.getSelectedMarker(event.latlng);
                    this.selectedMarkers.addLayer(this.endConstructed);
                    this.endConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    // 選択範囲の切り出し
                    this.constructedLayer = this.extractSelectedRoad(
                        this.regulatedLayers[0], this.beginConstructed, this.endConstructed)
                        .setStyle(this.constructedStyle);
                    this.constructedLayer.addTo(this.map);
                    // ジオコード
                    this.fillGeoGraphicForm('constEndPoint', this.endConstructed);
                }
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 規制の始点と終点から規制区間の道路を切り出し、新しいレイヤーを生成する
             * @param  {Polyline} layer 切り出す対象となる道路のPolyline
             * @param  {Marker} begin 始点となるMarker
             * @param  {Marker} end   終点となるMarker
             */
            extractSelectedRoad: function (layer, begin, end) {
                // 始点、終点の位置がpolyline上のどの部分にいるか、を[0..1]の実数表現で戻る
                var beginPoint = geometryutil.locateOnLine(this.map, layer, begin.getLatLng());
                var endPoint = geometryutil.locateOnLine(this.map, layer, end.getLatLng());
                // 上で求めた2点で切り出す
                var selectedLatLngs =
                    geometryutil.extract(this.map, layer, beginPoint, endPoint);
                // 切り出した部分で1つのレイヤーを生成
                return new leaflet.Polyline(selectedLatLngs)
                    .setStyle({
                        description: this.roadName.value,
                        _regulated: 'yes'
                    });
            },

            /**
             * 1つのpolylineが選択された時の動作
             * @param  {ILAYER} layer clickされたLayerオブジェクト
             */
            highlightLine: function (layer) {
                // 全レイヤーをhiddenを表す色に
                this.roadLayerGroup.setStyle(this.hiddenStyle);

                // 指定されたレイヤーだけハイライト
                layer.setStyle(this.selectedStyle);
            },

            /**
             * 規制区間の始点、終点を表すmarkerの位置を移動した時のイベントハンドラ
             * @param  {DragEndEvent} event markerをドラッグし終わった時のイベント
             */
            moveRegulatedMarker: function (event) {
                // 移動先の位置が道路から外れていた場合のために
                // markerの位置を道路レイヤーの一番近い位置(closest)へ移動する(setLatLng)
                event.target.setLatLng(
                    geometryutil.closest(this.map, this.selectedLayer, event.target.getLatLng()));
                // 道路を切り出していたらmarkerの位置でレイヤーを更新する
                if (this.regulatedLayers[0]) {
                    this.updateRegulatedLayer();
                }
                // formの緯度経度などを更新
                switch (event.target) {
                    case this.beginRegulation:
                        this.fillGeoGraphicForm('regStartPoint', event.target);
                        break;
                    case this.endRegulation:
                        this.fillGeoGraphicForm('regEndPoint', event.target);
                        break;
                }
            },

            /**
             * 被災箇所の地点を表すmarkerの位置を移動した時のイベントハンドラ
             * @param  {DragEndEvent} event markerをドラッグし終わった時のイベント
             */
            moveDamagedMarker: function (event) {
                // 移動先の位置が道路から外れていた場合のために
                // markerの位置を道路レイヤーの一番近い位置(closest)へ移動する(setLatLng)
                if (this.regulatedLayers[0]) {
                    // 規制区間がある場合
                    event.target.setLatLng(
                        geometryutil.closest(this.map, this.regulatedLayers[0], event.target.getLatLng()));
                } else {
                    // 規制区間がない場合
                    event.target.setLatLng(
                        geometryutil.closest(this.map, this.selectedLayer, event.target.getLatLng()));
                }
                // 地点を切り出していたらmarkerの位置でレイヤーを更新する
                // if (this.damagedLayer) {
                //     this.updateDamagedLayer();
                // }
                // formの緯度経度などを更新
                switch (event.target) {
                    case this.damaged:
                        this.fillGeoGraphicForm('dmgPoint', event.target);
                        break;
                }
            },

            /**
             * 被災箇所の追加地点を表すmarkerの位置を移動した時のイベントハンドラ
             * @param  {DragEndEvent} event markerをドラッグし終わった時のイベント
             */
            moveAddDamagedMarker: function (event) {
                // 移動先の位置が道路から外れていた場合のために
                // markerの位置を道路レイヤーの一番近い位置(closest)へ移動する(setLatLng)
                if (this.regulatedLayers[0]) {
                    // 規制区間がある場合
                    event.target.setLatLng(
                        geometryutil.closest(this.map, this.regulatedLayers[0], event.target.getLatLng()));
                } else {
                    // 規制区間がない場合
                    event.target.setLatLng(
                        geometryutil.closest(this.map, this.selectedLayer, event.target.getLatLng()));
                }
            },

            /**
             * 工事区間の始点、終点を表すmarkerの位置を移動した時のイベントハンドラ
             * @param  {DragEndEvent} event markerをドラッグし終わった時のイベント
             */
            moveConstructedMarker: function (event) {
                // 移動先の位置が道路から外れていた場合のために
                // markerの位置を道路レイヤーの一番近い位置(closest)へ移動する(setLatLng)
                if (this.regulatedLayers[0]) {
                    // 規制区間がある場合
                    event.target.setLatLng(
                        geometryutil.closest(this.map, this.regulatedLayers[0], event.target.getLatLng()));
                } else if (this.selectedLayer) {
                    // 規制区間がない場合
                    event.target.setLatLng(
                        geometryutil.closest(this.map, this.selectedLayer, event.target.getLatLng()));
                }
                // 道路を切り出していたらmarkerの位置でレイヤーを更新する
                if (this.constructedLayer) {
                    this.updateConstructedLayer();
                }
                // formの緯度経度などを更新
                switch (event.target) {
                    case this.beginConstructed:
                        this.fillGeoGraphicForm('constStartPoint', event.target);
                        break;
                    case this.endConstructed:
                        this.fillGeoGraphicForm('constEndPoint', event.target);
                        break;
                }
            },

            /**
             * 追加した被災箇所の地点を表すmarkerの位置をクリックした時のイベントハンドラ
             * @param  {DragEndEvent} event markerをクリックした時のイベント
             */
            removeAddDamagedMarker: function (event) {
                // クリックした地点を削除
                var newAddDamaged = this.addDamaged.filter(function (item) {
                    return item !== event.target;
                });
                this.addDamaged = newAddDamaged;
                // レイヤーから削除
                var newLayers = this.addDamagedLayers.filter(function (item) {
                    return item !== event.target;
                });
                this.addDamagedLayers = newLayers;
                // 地図からレイヤーを削除
                this.map.removeLayer(event.target);
            },

            /**
             * 規制区間のレイヤーを更新する
             */
            updateRegulatedLayer: function () {
                this.removeRegulatedLayers();
                this.regulatedLayers[0] = this.extractSelectedRoad(
                    this.selectedLayer, this.beginRegulation, this.endRegulation)
                    .setStyle(this.extractedStyle);
                this.regulatedLayers[0].addTo(this.map);
            },

            /**
             * 工事区間のレイヤーを更新する
             */
            updateConstructedLayer: function () {
                this.map.removeLayer(this.constructedLayer);
                this.constructedLayer = null;
                this.constructedLayer = this.extractSelectedRoad(
                    this.selectedLayer, this.beginConstructed, this.endConstructed)
                    .setStyle(this.constructedStyle);
                this.constructedLayer.addTo(this.map);
            },

            /**
             * 規制区間を地図上に表示する
             * {"type": "feature", "properties": {}, "geometry": {"coodinates": []}}
             * が構成要素として必要
             * 規制区間が複数の場合は単に規制区間のみを描画する
             * 規制区間が1つの場合は、起点と終点を表すオブジェクトも描画する
             * @param  {Object} geojson 規制区間を表すオブジェクト
             * @param  {Object} begin 起点の緯度経度
             * @param  {Object} end 終点の緯度経度
             */
            drawRegulationSection: function (geojson, begin, end) {
                // GeoJSONをレイヤーに表す、ただし地図には出さない
                var geoJsonLayer = new leaflet.geoJson(geojson);

                // レイヤーがない場合は終了
                if (geoJsonLayer.getLayers().length === 0) {
                    return;

                    // 事前定義済み規制区間の場合
                    // 規制区間を描画するのみ
                } else if (geoJsonLayer.getLayers()[0].feature.properties.isPreDefined) {
                    // レイヤーが複数の場合は全ての規制区間を描画
                    geoJsonLayer.eachLayer(lang.hitch(this, function (layer) {
                        this.regulatedLayers.push(layer);
                    }));
                    this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                        layer.setStyle(this.extractedStyle).addTo(this.map);
                    }));
                    // 事前定義済み規制区間モードフラグをセット
                    this.isRegSectionMode = true;

                    // 事前定義済み規制区間ではなく、レイヤーが複数の場合
                    // 規制区間を描画するのみ
                } else if (geoJsonLayer.getLayers().length !== 1) {
                    // レイヤーが複数の場合は全ての規制区間を描画
                    geoJsonLayer.eachLayer(lang.hitch(this, function (layer) {
                        this.regulatedLayers.push(layer);
                    }));
                    this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                        layer.setStyle(this.extractedStyle).addTo(this.map);
                    }));
                    // 路線全選択モードフラグをセット
                    this.isSelectAllRoadMode = true;

                    // レイヤーが事前定義済み規制区間ではなく、複数でもない場合
                    // 起点と終点のマーカーと規制区間を描画
                } else {
                    geoJsonLayer.eachLayer(lang.hitch(this, function (layer) {
                        // 規制区間起点のマーカーを足す
                        if (begin && begin[0] !== null && begin[1] !== null) {
                            this.beginRegulation = this.getSelectedMarker(
                                geometryutil.closest(this.map, layer, begin));
                            this.selectedMarkers.addLayer(this.beginRegulation);
                            this.beginRegulation.on('dragend', lang.hitch(this, this.moveRegulatedMarker));
                        }
                        // 規制区間終点のマーカーを足す
                        if (end && end[0] !== null && end[1] !== null) {
                            this.endRegulation = this.getSelectedMarker(
                                geometryutil.closest(this.map, layer, end));
                            this.selectedMarkers.addLayer(this.endRegulation);
                            this.endRegulation.on('dragend', lang.hitch(this, this.moveRegulatedMarker));
                        }
                        // 路線レイヤーグループがあり、規制区間起点と規制区間終点がある場合
                        if (this.roadLayerGroup !== null && this.beginRegulation && this.endRegulation) {
                            // 路線レイヤーグループの中から、
                            // 現在の起点マーカーが指し示している位置から一番近いlayerを探す
                            var closest;
                            if (this.roadLayerGroup.getLayers()[0]._layers) {
                                // 路線がMultiLineString
                                closest = geometryutil.closestLayer(
                                    this.map, this.roadLayerGroup.getLayers()[0].getLayers(),
                                    this.beginRegulation.getLatLng());
                            } else {
                                // 路線がLineString
                                closest = geometryutil.closestLayer(
                                    this.map, this.roadLayerGroup.getLayers(),
                                    this.beginRegulation.getLatLng());
                            }
                            // 見つけた路線を選択レイヤーとしてハイライトし、規制区間規制区間レイヤーとする
                            if (closest) {
                                this.selectedLayer = closest.layer;
                                this.highlightLine(closest.layer);
                                this.regulatedLayers[0] = this.extractSelectedRoad(
                                    closest.layer, this.beginRegulation, this.endRegulation);
                            }
                            // 路線レイヤーグループがない（その他の路線）か、規制区間起点または規制区間終点がない場合
                        } else {
                            this.regulatedLayers[0] = layer;
                        }
                        this.regulatedLayers[0].setStyle(this.extractedStyle).addTo(this.map);
                    }));
                    // どちらかのマーカーが欠けていたら再編集を不可とする
                    if (!this.beginRegulation || !this.endRegulation) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    } else {
                        // 切り出しモードフラグをセット
                        this.isCutMode = true;
                    }
                }
            },

            /**
             * 被災箇所を地図上に表示する
             * {"type": "feature", "properties": {}, "geometry": {"coodinates": []}}
             * が構成要素として必要
             * @param  {Object} geojson 被災箇所を表すオブジェクト
             * @param  {Object} point 地点の緯度経度
             */
            drawDamagedSection: function (geojson, point) {
                // GeoJSONをレイヤーに表す、ただし地図には出さない
                var geoJsonLayer = new leaflet.geoJson(geojson);

                geoJsonLayer.eachLayer(lang.hitch(this, function (layer) {
                    // 規制区間を切り出した部分のレイヤーを探し、始点、終点のマーカーを足したりする
                    if (layer.feature.properties.name !== '被災区間') {
                        return;
                    }
                    // 規制区間のマーカーを削除する
                    this.selectedMarkers.clearLayers();
                    // 被災箇所のマーカーを追加する
                    if (point && point[0] !== null && point[1] !== null) {
                        this.damaged = this.getDamagedMarker(point);
                        this.damaged.on('dragend', lang.hitch(this, this.moveDamagedMarker));
                    }
                    // 規制区間があり、被災箇所がある場合
                    if (this.regulatedLayers[0] && this.damaged) {
                        // 規制区間から被災区間のレイヤーを抽出する
                        this.damagedLayer = this.damaged;
                        this.damagedLayer.addTo(this.map);
                        // 規制区間がないが、被災箇所がある場合
                    } else if (!this.regulatedLayers[0] && this.damaged) {
                        // 路線レイヤーグループの中から、
                        // 現在の被災箇所マーカーが指し示している位置から一番近いlayerを探す
                        var closest;
                        if (this.roadLayerGroup.getLayers()[0]._layers) {
                            // 路線がMultiLineString
                            closest = geometryutil.closestLayer(
                                this.map, this.roadLayerGroup.getLayers()[0].getLayers(),
                                this.damaged.getLatLng());
                        } else {
                            // 路線がLineString
                            closest = geometryutil.closestLayer(
                                this.map, this.roadLayerGroup.getLayers(),
                                this.damaged.getLatLng());
                        }
                        // 見つけた路線を選択レイヤーとしてハイライトし、被災箇所レイヤーとする
                        if (closest) {
                            this.selectedLayer = closest.layer;
                            this.highlightLine(closest.layer);
                            this.damagedLayer = this.damaged;
                            this.damagedLayer.addTo(this.map);
                        }
                        // 規制区間がないか、被災箇所がない場合
                    } else {
                        // そのまま描画する
                        this.damagedLayer = layer;
                        this.damagedLayer.addTo(this.map);
                    }
                    // 路線レイヤーグループがない場合（その他の路線の場合）
                    if (!this.roadLayerGroup) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    }
                    // マーカーが欠けていたら再編集を不可とする
                    if (!this.damaged) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    }
                }));
            },

            /**
             * 被災箇所（追加）を地図上に表示する
             * {"type": "feature", "properties": {}, "geometry": {"coodinates": []}}
             * が構成要素として必要
             * @param  {Object} geojson 被災箇所を表すオブジェクト
             */
            drawAddDamagedSection: function (geojson) {
                // GeoJSONをレイヤーに表す、ただし地図には出さない
                var geoJsonLayer = new leaflet.geoJson(geojson);

                geoJsonLayer.eachLayer(lang.hitch(this, function (layer) {
                    // 規制区間を切り出した部分のレイヤーを探し、始点、終点のマーカーを足したりする
                    if (layer.feature.properties.name !== '被災区間（追加）') {
                        return;
                    }
                    var point = [layer.feature.geometry.coordinates[1], layer.feature.geometry.coordinates[0]];
                    // 規制区間のマーカーを削除する
                    // this.selectedMarkers.clearLayers();
                    // 被災箇所のマーカーを追加する
                    if (point && point[0] !== null && point[1] !== null) {
                        this.addDamaged.push(this.getAddDamagedMarker(point));
                        var target = this.addDamaged[this.addDamaged.length - 1];
                        target.on('dragend', lang.hitch(this, this.moveAddDamagedMarker));
                        target.on('click', lang.hitch(this, this.removeAddDamagedMarker));
                    }
                    // 規制区間があり、被災箇所がある場合
                    if (this.regulatedLayers[0] && this.addDamaged.length !== 0) {
                        // 規制区間から被災区間のレイヤーを抽出する
                        this.addDamagedLayers.push(this.addDamaged[this.addDamaged.length - 1]);
                        this.addDamagedLayers[this.addDamagedLayers.length - 1].addTo(this.map);
                        // 規制区間がないが、被災箇所がある場合
                    } else if (!this.regulatedLayers[0] && this.addDamaged.length !== 0) {
                        // 路線レイヤーグループの中から、
                        // 現在の被災箇所マーカーが指し示している位置から一番近いlayerを探す
                        var closest;
                        if (this.roadLayerGroup.getLayers()[0]._layers) {
                            // 路線がMultiLineString
                            closest = geometryutil.closestLayer(
                                this.map, this.roadLayerGroup.getLayers()[0].getLayers(),
                                this.addDamaged[this.addDamaged.length - 1].getLatLng());
                        } else {
                            // 路線がLineString
                            closest = geometryutil.closestLayer(
                                this.map, this.roadLayerGroup.getLayers(),
                                this.addDamaged[this.addDamaged.length - 1].getLatLng());
                        }
                        // 見つけた路線を選択レイヤーとしてハイライトし、被災箇所レイヤーとする
                        if (closest) {
                            this.selectedLayer = closest.layer;
                            this.highlightLine(closest.layer);
                            this.addDamagedLayers.push(this.addDamaged[this.addDamaged.length - 1]);
                            this.addDamagedLayers[this.addDamagedLayers.length - 1].addTo(this.map);
                        }
                        // 規制区間がないか、被災箇所がない場合
                    } else {
                        // そのまま描画する
                        this.addDamagedLayers.push(layer);
                        this.addDamagedLayers[this.addDamagedLayers.length - 1].addTo(this.map);
                    }
                    // 路線レイヤーグループがない場合（その他の路線の場合）
                    if (!this.roadLayerGroup) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    }
                    // マーカーが欠けていたら再編集を不可とする
                    if (this.addDamaged.length === 0) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    }
                }));
            },

            /**
             * 工事区間を地図上に表示する
             * {"type": "feature", "properties": {}, "geometry": {"coodinates": []}}
             * が構成要素として必要
             * @param  {Object} geojson 工事区間を表すオブジェクト
             * @param  {Object} begin 起点の緯度経度
             * @param  {Object} end 終点の緯度経度
             */
            drawConstructedSection: function (geojson, begin, end) {
                // GeoJSONをレイヤーに表す、ただし地図には出さない
                var geoJsonLayer = new leaflet.geoJson(geojson);

                geoJsonLayer.eachLayer(lang.hitch(this, function (layer) {
                    // 規制区間を切り出した部分のレイヤーを探し、始点、終点のマーカーを足したりする
                    if (layer.feature.properties.name !== '工事区間') {
                        return;
                    }
                    // 規制区間のマーカーを削除する
                    this.selectedMarkers.clearLayers();
                    // 工事区間起点のマーカーを追加する
                    if (begin && begin[0] !== null && begin[1] !== null) {
                        this.beginConstructed = this.getSelectedMarker(
                            geometryutil.closest(this.map, layer, begin));
                        this.selectedMarkers.addLayer(this.beginConstructed);
                        this.beginConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    }
                    // 工事区間終点のマーカーを追加する
                    if (end && end[0] !== null && end[1] !== null) {
                        this.endConstructed = this.getSelectedMarker(
                            geometryutil.closest(this.map, layer, end));
                        this.selectedMarkers.addLayer(this.endConstructed);
                        this.endConstructed.on('dragend', lang.hitch(this, this.moveConstructedMarker));
                    }
                    // 規制区間があり、工事区間起点と工事区間終点がある場合
                    if (this.regulatedLayers[0] && this.beginConstructed && this.endConstructed) {
                        // 規制区間から工事区間のレイヤーを抽出してスタイルを設定する
                        this.constructedLayer = this.extractSelectedRoad(
                            this.regulatedLayers[0], this.beginConstructed, this.endConstructed)
                            .setStyle(this.constructedStyle);
                        this.constructedLayer.addTo(this.map);
                        // 規制区間がないが、工事区間起点と工事区間終点がある場合
                    } else if (!this.regulatedLayers[0] && this.beginConstructed && this.endConstructed) {
                        // 路線レイヤーグループの中から、
                        // 現在の起点マーカーが指し示している位置から一番近いlayerを探す
                        var closest;
                        if (this.roadLayerGroup.getLayers()[0]._layers) {
                            // 路線がMultiLineString
                            closest = geometryutil.closestLayer(
                                this.map, this.roadLayerGroup.getLayers()[0].getLayers(),
                                this.beginConstructed.getLatLng());
                        } else {
                            // 路線がLineString
                            closest = geometryutil.closestLayer(
                                this.map, this.roadLayerGroup.getLayers(),
                                this.beginConstructed.getLatLng());
                        }
                        // 見つけた路線を選択レイヤーとしてハイライトし、被災箇所レイヤーとする
                        if (closest) {
                            this.selectedLayer = closest.layer;
                            this.highlightLine(closest.layer);
                            this.constructedLayer = this.extractSelectedRoad(
                                closest.layer, this.beginConstructed, this.endConstructed)
                                .setStyle(this.constructedStyle);
                            this.constructedLayer.addTo(this.map);
                        }
                        // 規制区間がないか、工事区間起点または工事区間終点がない場合
                    } else {
                        // そのまま描画する
                        this.constructedLayer = layer;
                        this.constructedLayer.setStyle(this.constructedStyle).addTo(this.map);
                    }
                    // 路線レイヤーグループがない場合（その他の路線の場合）
                    if (!this.roadLayerGroup) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    }
                    // どちらかのマーカーが欠けていたら再編集を不可とする
                    if (!this.beginConstructed || !this.endConstructed) {
                        // 編集させたくないのでマーカーを削除する
                        this.selectedMarkers.clearLayers();
                        // 編集させたくないので路線全選択モードフラグをセットする
                        this.isSelectAllRoadMode = true;
                    }
                }));
            },

            /**
             * 画面フォームの地理情報を地図から取得する
             * @param  {String} name 入力する data-dojo-attach-point のbasename
             *                          住所: name + 'Name'
             *                          経度: name + 'Lat'
             *                          緯度: name + 'Lon'
             *                       というルールで付与すること
             * @param  {Marker} marker 地理情報が入ったmarker
             */
            fillGeoGraphicForm: function (name, marker) {
                // 指定がない時は何もしない
                if (!name || !marker) {
                    return;
                }

                // 緯度経度を入れる
                this[name + 'Lat'].set('value', marker.getLatLng().lat);
                this[name + 'Lng'].set('value', marker.getLatLng().lng);

                // 住所を入れる
                geocoder.Services.geocoding({
                    url: config.geocode && config.geocode.url[UserInfo.getRunningMode()]
                }).reverse().latlng(marker.getLatLng()).distance(10000).run(
                    lang.hitch(this, function (error, result) {
                        if (error) {
                            //  InfoDialog.show('失敗', '住所を取得できませんでした。登録は実施できます。');
                            this[name + 'Name'].set('value', '');
                            return;
                        }
                        if (result.address.LongLabel && result.address.LongLabel === '日本, JPN') {
                            this.chain.info('対応する住所が見つかりません。地名を入力してください。');
                            this[name + 'Name'].set('value', '');
                            return;
                        }
                        if (result.address.LongLabel) {
                            this[name + 'Name'].set('value', result.address.LongLabel.split(',')[0] === '日本' ?
                                config.municInfo.prefName : result.address.LongLabel.split(',')[0]);
                        } else {
                            this[name + 'Name'].set('value', result.address.Address);
                        }
                    })
                );
            },

            /**
             * 路線名セレクトボックスをクリアする。
             *
             * 路線名を空にする、路線番号を空にする、
             * 路線名のオプションを空にする、路線名その他入力欄を空にする、地図から選択路線を消す
             */
            clearRoadNameList: function () {
                // 路線名セレクトボックスの選択状態をクリア
                this.form.set('value', {
                    roadName: '',
                    roadNameOther: '',
                    roadNum: ''
                });
                // 路線セレクトボックスに空のオプションを設定
                var newOptions = [{
                    label: '&nbsp;',
                    value: null
                }];
                registry.byId('roadName').set('options', newOptions).reset();
                // 地図から選択路線を消す
                this.removeRoadLayerGroup();
            },

            /**
             * 路線自体を地図から削除する
             */
            removeRoadLayerGroup: function () {
                // 選択したマーカーや切り出した道路などがあったら削除
                this.clearRegulatedSection();
                // 路線自体を地図から消す
                if (this.roadLayerGroup) {
                    // 地図から非表示にする
                    this.map.removeLayer(this.roadLayerGroup);
                    // 路線自体を消す
                    this.roadLayerGroup = null;
                }
            },

            /**
             * 規制区間・被災箇所・工事区間をクリアする
             * クリアボタンを押したときに呼ばれる
             * 他にonRoadClick、removeRoadLayerGroup、selectAllRoadからも呼ばれる
             *
             * @return {[type]} [description]
             */
            clearRegulatedSection: function () {
                // マーカーを削除する
                this.selectedMarkers.clearLayers();

                // 工事区間起点・工事区間終点をクリアする
                this.beginConstructed = null;
                this.endConstructed = null;
                // 工事区間のレイヤーを削除する
                this.removeConstructedLayer();
                // 工事区間のフォームをクリアする
                this.clearConstructedForm();

                // 被災箇所をクリアする
                this.damaged = null;
                this.addDamaged = [];
                // 被災箇所のレイヤーを削除する
                this.removeDamagedLayer();
                // 被災箇所のフォームをクリアする
                this.clearDamagedForm();

                // 規制区間起点・規制区間終点をクリアする
                this.beginRegulation = null;
                this.endRegulation = null;
                // 選択されている道路のハイライトを戻す
                if (this.selectedLayer) {
                    this.selectedLayer.setStyle(this.selectedStyle);
                }
                if (this.roadLayerGroup) {
                    this.roadLayerGroup.setStyle(this.selectedStyle);
                }
                // 規制区間を地図から削除する
                this.removeRegulatedLayers();
                // フォームの値をクリア
                this.clearRegulatedForm();
                // 路線レイヤーグループに設定されているイベントを外す
                if (this.roadLayerGroup) {
                    this.roadLayerGroup.off('click');
                }
                // 路線全選択モードフラグをクリアする
                this.isSelectAllRoadMode = false;
                // 事前定義済み規制区間モードフラグをクリアする
                this.isRegSectionMode = false;
                // 切り出しモードフラグをクリアする
                this.isCutMode = false;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
                // ボタン色変更
                this.toggleSelectButtonColor();
            },

            /**
             * 被災箇所をクリアする
             *
             * @return {[type]} [description]
             */
            clearDamagedSection: function () {
                // マーカーを削除する
                this.selectedMarkers.clearLayers();

                // 被災箇所をクリアする
                this.damaged = null;
                this.addDamaged = [];
                // 被災箇所のレイヤーを削除する
                this.removeDamagedLayer();
                // 被災箇所のフォームをクリアする
                this.clearDamagedForm();

                // 路線全選択モードフラグをクリアする
                this.isSelectAllRoadMode = false;
                // 事前定義済み規制区間モードフラグをクリアする
                this.isRegSectionMode = false;
                // 切り出しモードフラグをクリアする
                this.isCutMode = false;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 工事区間をクリアする
             *
             * @return {[type]} [description]
             */
            clearConstructedSection: function () {
                // マーカーを削除する
                this.selectedMarkers.clearLayers();

                // 工事区間起点・工事区間終点をクリアする
                this.beginConstructed = null;
                this.endConstructed = null;
                // 工事区間のレイヤーを削除する
                this.removeConstructedLayer();
                // 工事区間のフォームをクリアする
                this.clearConstructedForm();

                // 路線全選択モードフラグをクリアする
                this.isSelectAllRoadMode = false;
                // 事前定義済み規制区間モードフラグをクリアする
                this.isRegSectionMode = false;
                // 切り出しモードフラグをクリアする
                this.isCutMode = false;
                // 地図上の通行規制関連ボタンの表示状態を更新する
                this.updateTrafficButtons();
            },

            /**
             * 規制区間のレイヤーを地図から削除する
             */
            removeRegulatedLayers: function () {
                this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                    this.map.removeLayer(layer);
                }));
                this.regulatedLayers = [];
            },

            /**
             * 被災箇所のレイヤーを地図から削除する
             */
            removeDamagedLayer: function () {
                if (this.damagedLayer) {
                    this.map.removeLayer(this.damagedLayer);
                    this.damagedLayer = null;
                }
                this.addDamagedLayers.forEach(lang.hitch(this, function (layer) {
                    this.map.removeLayer(layer);
                }));
                this.addDamagedLayers = [];
            },

            /**
             * 工事区間のレイヤーを地図から削除する
             */
            removeConstructedLayer: function () {
                if (this.constructedLayer) {
                    this.map.removeLayer(this.constructedLayer);
                    this.constructedLayer = null;
                }
            },

            /**
             * 画面フォームの規制区間情報をクリアする
             */
            clearRegulatedForm: function () {
                this.form.set('value', {
                    regStartPointName: '',
                    regStartPointNameKana: '',
                    regStartPointLat: '',
                    regStartPointLng: '',
                    regEndPointName: '',
                    regEndPointNameKana: '',
                    regEndPointLat: '',
                    regEndPointLng: ''
                });
            },

            /**
             * 画面フォームの被災箇所情報をクリアする
             */
            clearDamagedForm: function () {
                this.form.set('value', {
                    dmgPointName: '',
                    dmgPointNameKana: '',
                    dmgPointLat: '',
                    dmgPointLng: ''
                });
            },

            /**
             * 画面フォームの工事区間情報をクリアする
             */
            clearConstructedForm: function () {
                this.form.set('value', {
                    constStartPointName: '',
                    constStartPointNameKana: '',
                    constStartPointLat: '',
                    constStartPointLng: '',
                    constEndPointName: '',
                    constEndPointNameKana: '',
                    constEndPointLat: '',
                    constEndPointLng: ''
                });
            },

            /**
             * drawPanelから作図の情報をGeoJsonとしてもらう
             */
            getGeoJSON: function () {
                // DrawPanelからGeoJSONをもらう
                topic.publish(this.JSONIZE_RQST);

                // GeoJSONが来た時にresolveされるpromiseを戻す
                return this.dfdJsonize;
            },

            /**
             * drawPanelからラインをGeoJsonとしてもらう
             * もらったラインは作図からは消える
             */
            getLineStringGeoJSON: function () {
                // DrawPanelからGeoJSONをもらう
                topic.publish(this.ROAD_REG_REQ);

                // GeoJSONが来た時にresolveされるpromiseを戻す
                return this.dfdJsonize;
            },

            /**
             * 規制区間レイヤーからGeoJSONオブジェクトを作る
             *  toGeoJSON()を呼んで基本構成を作り、ポップアップ用の属性(name, description)を入れる
             * @param  {ILayer} layer GeoJSON化するレイヤー
             * @return {Object}       GeoJSONを表すオブジェクト
             */
            regulatedLayerToGeoJSON: function (layer) {
                if (!layer) {
                    return null;
                }

                // マーカーの位置（中間地点）
                var markerPosition = geometryutil.interpolateOnLine(this.map, layer, 0.5);

                var regStartTimestamp;
                if (this.regStartTimestamp.get('value')) {
                    regStartTimestamp = new Date(this.regStartTimestamp.get('value')).getTime();
                }

                var geojson = layer.toGeoJSON();

                // 渡されたレイヤーが事前定義済み規制区間であるかどうかのフラグ
                var isPreDefined = geojson.properties.isPreDefined;

                geojson.type = 'Feature';
                geojson.properties = {
                    name: '規制区間',
                    roadName: this.roadName.value,
                    roadType: (this.getLabel(this.roadTypeCd) === void 0) ?
                        '' : this.getLabel(this.roadTypeCd),
                    regReason: (this.getLabel(this.regReasonCd) === void 0) ?
                        '' : this.getLabel(this.regReasonCd),
                    regContent: (this.getLabel(this.regContentCd) === void 0) ?
                        '' : this.getLabel(this.regContentCd),
                    startPointName: this.regStartPointName.get('value'),
                    endPointName: this.regEndPointName.get('value'),
                    regStartTimestamp: regStartTimestamp,
                    markerPosition: markerPosition.latLng
                };

                // 事前定義済み規制区間の場合はフラグを足す
                if (isPreDefined) {
                    geojson.properties.isPreDefined = true;
                }

                return geojson;
            },

            /**
             * 被災箇所レイヤーからGeoJSONオブジェクトを作る
             *  toGeoJSON()を呼んで基本構成を作り、ポップアップ用の属性(name, description)を入れる
             * @param  {ILayer} layer GeoJSON化するレイヤー
             * @return {Object}       GeoJSONを表すオブジェクト
             */
            damagedLayerToGeoJSON: function (layer) {
                if (!layer) {
                    return null;
                }

                var geojson = layer.toGeoJSON();
                geojson.type = 'Feature';
                geojson.properties = {
                    name: '被災区間',
                    roadName: this.roadName.value,
                    roadType: (this.getLabel(this.roadTypeCd) === void 0) ?
                        '' : this.getLabel(this.roadTypeCd),
                    dmgCategory: (this.getLabel(this.dmgCategoryCd) === void 0) ?
                        '' : this.getLabel(this.dmgCategoryCd),
                    pointName: this.dmgPointName.get('value')
                };

                return geojson;
            },

            /**
             * 被災箇所（追加）レイヤーからGeoJSONオブジェクトを作る
             *  toGeoJSON()を呼んで基本構成を作り、ポップアップ用の属性(name, description)を入れる
             * @param  {ILayer} layer GeoJSON化するレイヤー
             * @return {Object}       GeoJSONを表すオブジェクト
             */
            addDamagedLayerToGeoJSON: function (layer) {
                if (!layer) {
                    return null;
                }

                var geojson = layer.toGeoJSON();
                geojson.type = 'Feature';
                geojson.properties = {
                    name: '被災区間（追加）',
                    roadName: this.roadName.value,
                    roadType: (this.getLabel(this.roadTypeCd) === void 0) ?
                        '' : this.getLabel(this.roadTypeCd),
                    dmgCategory: (this.getLabel(this.dmgCategoryCd) === void 0) ?
                        '' : this.getLabel(this.dmgCategoryCd),
                    pointName: this.dmgPointName.get('value')
                };

                return geojson;
            },

            /**
             * 工事区間レイヤーからGeoJSONオブジェクトを作る
             *  toGeoJSON()を呼んで基本構成を作り、ポップアップ用の属性(name, description)を入れる
             * @param  {ILayer} layer GeoJSON化するレイヤー
             * @return {Object}       GeoJSONを表すオブジェクト
             */
            constructedLayerToGeoJSON: function (layer) {
                if (!layer) {
                    return null;
                }

                // マーカーの位置（中間地点）
                var markerPosition = geometryutil.interpolateOnLine(this.map, layer, 0.5);

                var geojson = layer.toGeoJSON();
                geojson.type = 'Feature';
                geojson.properties = {
                    name: '工事区間',
                    roadName: this.roadName.value,
                    roadType: (this.getLabel(this.roadTypeCd) === void 0) ?
                        '' : this.getLabel(this.roadTypeCd),
                    dmgCategory: (this.getLabel(this.dmgCategoryCd) === void 0) ?
                        '' : this.getLabel(this.dmgCategoryCd),
                    startPointName: this.constStartPointName.get('value'),
                    endPointName: this.constEndPointName.get('value'),
                    markerPosition: markerPosition.latLng
                };

                return geojson;
            },

            /**
             * selectの選択された値からラベルを取得する
             * @param  {Object} select dijit/form/Select
             * @return {String}        選択されたラベル
             */
            getLabel: function (select) {
                return select.getOptions(select.get('value')).label;
            },

            /**
             * レイヤーのJSONをサーバーから取得して描画する
             *
             * @param  {Object} item 通行規制情報のオブジェクト
             */
            getLayerJsonAndDraw: function (item) {

                var dfd = new Deferred();

                // 地図情報を読み込んで書く
                Requester.get(['/data/layer/data', item.layerId, item.layerUrl].join('/'))
                    .then(lang.hitch(this, function (json) {

                        // 規制区間と被災箇所と工事区間と作図のfeatureを分離する
                        var regulatedFeatures = [];
                        var damagedFeatures = [];
                        var addDamagedFeatures = [];
                        var constructedFeatures = [];
                        var drawFeatures = [];
                        json.features.forEach(function (feature) {
                            if (feature.properties.name === '規制区間') {
                                regulatedFeatures.push(feature);
                            } else if (feature.properties.name === '被災区間') {
                                damagedFeatures.push(feature);
                            } else if (feature.properties.name === '被災区間（追加）') {
                                addDamagedFeatures.push(feature);
                            } else if (feature.properties.name === '工事区間') {
                                constructedFeatures.push(feature);
                            } else {
                                drawFeatures.push(feature);
                            }
                        });
                        //エラーを吐くので一旦回避
                        console.log(json.features);

                        // 作図のfeatureがある場合は描画する
                        if (drawFeatures.length !== 0) {
                            json.features = drawFeatures;
                            topic.publish(this.DRAW_BY_JSON, json);
                        }
                        // 規制区間のfeatureがある場合は描画する
                        if (regulatedFeatures.length !== 0) {
                            json.features = regulatedFeatures;
                            // 規制区間を表すオブジェクトを地図に載せる
                            this.drawRegulationSection(json, [item.regStartPointLat, item.regStartPointLng],
                                [item.regEndPointLat, item.regEndPointLng]);
                        }
                        // 被災箇所のfeatureがある場合は描画する
                        if (damagedFeatures.length !== 0) {
                            json.features = damagedFeatures;
                            // 被災箇所を表すオブジェクトを地図に載せる
                            this.drawDamagedSection(json, [item.dmgPointLat, item.dmgPointLng]);
                        }
                        // 被災箇所（追加）のfeatureがある場合は描画する
                        if (addDamagedFeatures.length !== 0) {
                            json.features = addDamagedFeatures;
                            // 被災箇所（追加）を表すオブジェクトを地図に載せる
                            this.drawAddDamagedSection(json);
                        }
                        // 工事区間のfeatureがある場合は描画する
                        if (constructedFeatures.length !== 0) {
                            json.features = constructedFeatures;
                            // 工事区間を表すオブジェクトを地図に載せる
                            this.drawConstructedSection(json, [item.constStartPointLat, item.constStartPointLng],
                                [item.constEndPointLat, item.constEndPointLng]);
                        }
                        dfd.resolve();
                    }), function (error) {
                        console.debug(error);
                        dfd.reject();
                    });

                return dfd;
            },

            /**
             * 事前定義済み規制区間グリッドを初期化する。
             */
            initRegSectionGrid: function () {
                // ダイアログの最初の子要素が事前定義済み規制区間選択画面
                var page = this.regSectionDialog.getChildren()[0];
                // 初期表示用のフィルターを作成
                var filter = new this.regSectionStore.Filter();
                // filterに対応するcollectionを取得
                var collection = this.regSectionStore.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                page.regSectionGrid.set('collection', collection);
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.regSectionGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    // this.showDetailPage(evt.item);
                    this.regSectionSelected(evt.item);
                }));
            },

            /**
             * 事前定義済み規制区間を選択するダイアログを表示する。
             */
            showRegSectionDialog: function () {
                // ダイアログの最初の子要素が登録画面
                var page = this.regSectionDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
                page.regSectionForm.reset();
                // レイアウト崩れ防止のためダイアログ内のborderContainerをリサイズ
                page.regSectionBorderContainer.resize();
                // ダイアログを表示
                this.regSectionDialog.show();
            },

            /**
             * 事前定義済み規制区間が選択された。
             */
            regSectionSelected: function (item) {
                // 事前定義済み規制区間設定中フラグをtrueにする
                this.isSettingRegSection = true;
                // 道路種別が設定されている場合
                if (item.roadTypeCd) {
                    // 道路種別を設定する
                    this.roadTypeCd.set('value', item.roadTypeCd);
                    // 道路種別がその他の場合はその他入力欄に路線名を設定
                    if (this.roadTypeCd.get('value') === '99') {
                        // 路線名セレクトボックスを非表示
                        this.roadName.set('style', {
                            display: 'none'
                        });
                        // 路線名その他入力欄を表示
                        this.roadNameOther.set('style', {
                            display: ''
                        });
                        // その他入力欄に路線名を設定
                        this.roadNameOther.set('value', item.roadName);

                        // 規制区間CDをキーにjsonを取得する
                        Requester.get('/data/traffic/regSection/' + item.regSectionCd + '.geojson')
                            .then(lang.hitch(this, function (data) {
                                var geojson = data;
                                // geojsonの各featureに事前定義済み規制区間であるフラグをつける
                                geojson.features.forEach(function (feature) {
                                    feature.properties.isPreDefined = true;
                                });
                                // 地図上に事前定義済み規制区間を表示する
                                this.drawRegSection(geojson).then(lang.hitch(this, function () {
                                    // 描画できたら地図を移動
                                    var group = new leaflet.featureGroup(this.regulatedLayers);
                                    this.map.fitBounds(group.getBounds(), {
                                        padding: [50, 50]
                                    });
                                    // 後でセットし直すために変数を保管しておく
                                    var tmpRoadTypeCd = item.roadTypeCd;
                                    var tmpRoadName = item.roadName;
                                    // 道路種別、路線名は入力したので削除
                                    delete item.roadTypeCd;
                                    delete item.roadName;
                                    // その他の通行規制情報を画面上のフォームにセットする
                                    this.form.set('value', item);
                                    // 2回目の選択時に必要となるためセットし直す
                                    item.roadTypeCd = tmpRoadTypeCd;
                                    item.roadName = tmpRoadName;
                                    // 事前定義済み規制区間設定モードフラグをtrueにする
                                    this.isRegSectionMode = true;
                                    // 地図上の通行規制関連ボタンの表示状態を更新する
                                    this.updateTrafficButtons();
                                    // 遅延を入れてから、通行規制情報設定中フラグをfalseにする
                                    setTimeout(lang.hitch(this, function () {
                                        this.isSettingRegSection = false;
                                    }), 1000);
                                }));
                            }), lang.hitch(this, function (error) {
                                console.debug(error);
                                // 事前定義済み規制区間設定モードフラグをtrueにする
                                this.isRegSectionMode = true;
                                // 地図上の通行規制関連ボタンの表示状態を更新する
                                this.updateTrafficButtons();
                                // 遅延を入れてから、通行規制情報設定中フラグをfalseにする
                                setTimeout(lang.hitch(this, function () {
                                    this.isSettingRegSection = false;
                                }), 1000);
                            }));
                        // 道路種別がその他以外の場合
                    } else {
                        // 路線名セレクトボックスを表示
                        this.roadName.set('style', {
                            display: ''
                        });
                        // 路線名その他入力欄を非表示
                        this.roadNameOther.set('style', {
                            display: 'none'
                        });
                        // 路線名のoptionsを作る
                        this.initRoadNameList(item.roadTypeCd).then(lang.hitch(this, function () {
                            // 国道の路線名のサフィックス変更に伴う一時対応
                            if (item.roadTypeCd === '03' || item.roadTypeCd === '05') {
                                // 補助国道または直轄国道の場合
                                // 路線名の末尾の一文字を取得
                                var ch = item.roadName.substr(item.roadName.length - 1);
                                if (ch === '線') {
                                    // 末尾の一文字が'線'の場合は末尾の一文字を削除
                                    item.roadName = item.roadName.substr(0, item.roadName.length - 1);
                                }
                            }
                            // 路線名を設定する
                            this.roadName.set('value', item.roadName);
                            // 地図上に路線を表示する
                            this.drawRoadByName(item.roadName).then(lang.hitch(this, function () {
                                // 路線を表示できたら規制区間CDをキーにjsonを取得する
                                Requester.get('/data/traffic/regSection/' + item.regSectionCd + '.geojson')
                                    .then(lang.hitch(this, function (data) {
                                        var geojson = data;
                                        // geojsonの各featureに事前定義済み規制区間であるフラグをつける
                                        geojson.features.forEach(function (feature) {
                                            feature.properties.isPreDefined = true;
                                        });
                                        // 地図上に事前定義済み規制区間を表示する
                                        this.drawRegSection(geojson).then(lang.hitch(this, function () {
                                            // 描画できたら地図を移動
                                            var group = new leaflet.featureGroup(this.regulatedLayers);
                                            this.map.fitBounds(group.getBounds(), {
                                                padding: [50, 50]
                                            });
                                            // 後でセットし直すために変数を保管しておく
                                            var tmpRoadTypeCd = item.roadTypeCd;
                                            var tmpRoadName = item.roadName;
                                            // 道路種別、路線名は入力したので削除
                                            delete item.roadTypeCd;
                                            delete item.roadName;
                                            // その他の通行規制情報を画面上のフォームにセットする
                                            this.form.set('value', item);
                                            // 2回目の選択時に必要となるためセットし直す
                                            item.roadTypeCd = tmpRoadTypeCd;
                                            item.roadName = tmpRoadName;
                                            // 事前定義済み規制区間設定モードフラグをtrueにする
                                            this.isRegSectionMode = true;
                                            // 地図上の通行規制関連ボタンの表示状態を更新する
                                            this.updateTrafficButtons();
                                            // 遅延を入れてから、通行規制情報設定中フラグをfalseにする
                                            setTimeout(lang.hitch(this, function () {
                                                this.isSettingRegSection = false;
                                            }), 1000);
                                        }));
                                    }), lang.hitch(this, function (error) {
                                        console.debug(error);
                                        // 事前定義済み規制区間設定モードフラグをtrueにする
                                        this.isRegSectionMode = true;
                                        // 地図上の通行規制関連ボタンの表示状態を更新する
                                        this.updateTrafficButtons();
                                        // 遅延を入れてから、通行規制情報設定中フラグをfalseにする
                                        setTimeout(lang.hitch(this, function () {
                                            this.isSettingRegSection = false;
                                        }), 1000);
                                    }));
                            }));
                        }));
                    }

                    // 道路種別が設定されていない場合
                } else {
                    // 事前定義済み規制区間設定モードフラグをtrueにする
                    this.isRegSectionMode = true;
                    // 地図上の通行規制関連ボタンの表示状態を更新する
                    this.updateTrafficButtons();
                    // 遅延を入れてから、通行規制情報設定中フラグをfalseにする
                    setTimeout(function () {
                        this.isSettingRegSection = false;
                    }, 1000);
                }
                // ダイアログを非表示
                this.regSectionDialog.hide();
            },

            /**
             * 地図上に事前定義済み規制区間を表示して規制区間とする。
             */
            drawRegSection: function (geojson) {
                // 地図上に事前定義済み規制区間を表示できたらアクションをとりたいことがあるので、Deferredを作る
                var dfd = new Deferred();
                // geojsonが空だったらそこで終了
                if (!geojson) {
                    dfd.resolve();
                    return dfd;
                }
                var layerGroup = new leaflet.geoJson()
                    .once('layeradd', lang.hitch(this, function () {
                        dfd.resolve();
                    }));
                layerGroup.addData(geojson);
                // レイヤーグループを規制区間とする
                // 事前規制区間のデータが複数のLineStringのfeatureの場合は以下でレイヤーの配列が取り出せる
                // layerGroup.getLayers();
                // もし事前規制区間のデータがMultiLineStringの場合は以下のようにする必要がある
                // layerGroup.getLayers()[0].getLayers()
                this.regulatedLayers = layerGroup.getLayers();
                // ハイライトして地図上に表示する
                this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                    layer.setStyle(this.extractedStyle);
                    layer.addTo(this.map);
                }));
                return dfd;
            },

            /**
             * 選択中ボタン切替えによる色変更
             * @param {Object} selectButton 選択中ボタン(未指定なら全未選択)
             */
            toggleSelectButtonColor: function (selectButton) {
                domClass.remove(this.enableSelectRegulatedSectionButton, 'is-active');
                domClass.remove(this.enableSelectDamagedSectionButton, 'is-active');
                domClass.remove(this.enableAddDamagedSectionButton, 'is-active');
                domClass.remove(this.enableSelectConstructedSectionButton, 'is-active');
                if (selectButton) {
                    domClass.add(selectButton, 'is-active');
                }
            },

            /**
             * 災害名が変更された際に呼ばれる。
             */
            disasterIdChanged: function (evt) {
                // 初期ロード時は何もしない
                if (this.isInStartup) {
                    return;
                }
                // disasterIdが設定されていない時は何もしない
                if (!evt.value) {
                    // parentLayerIdを初期化
                    this.parentLayerId.set('value', '');
                    return;
                }
                // サーバーからデフォルトの格納先を取得する
                this.getDefaultParent(evt.value);
            },

            /**
             * 規制解除予定日時を設定する
             */
            updateRegPlanedEndTimestampInput: function () {

                // 規制解除予定が予定日時の場合、規制解除予定日時を入力可能とする
                if (dom.byId('regPlanedEndCd1').checked) {
                    domStyle.set(this.regPlanedEndTimestampArea, 'display', '');
                } else {
                    domStyle.set(this.regPlanedEndTimestampArea, 'display', 'none');
                }

            },

            /**
             * 規制解除予定が変更された際に呼ばれる。
             */
            regPlanedEndCdChanged: function () {

                // 初期ロード時はここでは何もしない
                if (this.isInStartup) {
                    return;
                }

                this.updateRegPlanedEndTimestampInput();
            },

            /**
             *  規制解除予定日時の入力内容をクリアする
             */
            clearRegPlanedEndTimestampInput: function (formData) {

                if (!dom.byId('regPlanedEndCd1').checked) {
                    formData.regPlanedEndTimestamp = '';
                }
            },

            /**
             * 戸数を設定する
             */
            updateIsolatedHouseCountInput: function () {

                // 孤立集落がありの場合、戸数を入力可能とする
                if (dom.byId('isolatedHouseFlg1').checked) {
                    domStyle.set(this.isolatedHouseCountArea, 'display', '');
                } else {
                    domStyle.set(this.isolatedHouseCountArea, 'display', 'none');
                }

            },

            /**
             * 孤立集落が変更された際に呼ばれる。
             */
            isolatedHouseFlgChanged: function () {

                // 初期ロード時はここでは何もしない
                if (this.isInStartup) {
                    return;
                }

                this.updateIsolatedHouseCountInput();
            },

            /**
             *  戸数の入力内容をクリアする
             */
            clearIsolatedHouseCountInput: function (formData) {

                if (!dom.byId('isolatedHouseFlg1').checked) {
                    formData.isolatedHouseCount = '';
                }
            },

            /**
             * 被害人数を設定する
             */
            updateHumanDmgCountInput: function () {

                // 人的被害がありの場合、人数を入力可能とする
                if (dom.byId('humanDmgFlg1').checked) {
                    domStyle.set(this.humanDmgCountArea1, 'display', '');
                    domStyle.set(this.humanDmgCountArea2, 'display', '');
                } else {
                    domStyle.set(this.humanDmgCountArea1, 'display', 'none');
                    domStyle.set(this.humanDmgCountArea2, 'display', 'none');
                }

            },

            /**
             * 人的被害が変更された際に呼ばれる。
             */
            humanDmgFlgChanged: function () {

                // 初期ロード時はここでは何もしない
                if (this.isInStartup) {
                    return;
                }

                this.updateHumanDmgCountInput();
            },

            /**
             *  被害人数の入力内容をクリアする
             */
            clearHumanDmgCountInput: function (formData) {

                if (!dom.byId('humanDmgFlg1').checked) {
                    formData.diedCount = '';
                    formData.lostCount = '';
                    formData.seriousInjuredCount = '';
                    formData.minorInjuredCount = '';
                    formData.notClassifiedCount = '';
                }
            },

            /**
             * デフォルトの格納先を取得する。
             */
            getDefaultParent: function (disasterId) {
                // サーバーからデフォルトの格納先を取得する
                var url = '/api/traffic/defaultParent';
                if (disasterId) {
                    url = url + '?disasterId=' + disasterId;
                }
                Requester.get(url).then(lang.hitch(this, function (item) {
                    // デバッグ用
                    // 格納先をセット
                    this.parentLayerId.set('value', item);
                }), function (error) {
                    console.debug(error);
                });
            },

            onTrafficRegulationPageLinkClick: function (evt) {
                evt.preventDefault();
                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('traffic');
            },

            /**
             * 公開するボタン
             */
            reportedStatusOpen: function (item) {
                item.releaseFlg = '1';
                this.releaseFlg.set('value', '1');
                domStyle.set(this.reportedStatus0, 'display', 'none');
                domStyle.set(this.reportedStatus1, 'display', '');
            },

            /**
             * 非公開にするボタン
             */
            reportedStatusClose: function (item) {
                item.releaseFlg = '0';
                this.releaseFlg.set('value', '0');
                domStyle.set(this.reportedStatus0, 'display', '');
                domStyle.set(this.reportedStatus1, 'display', 'none');
            },

            /**
             * 規制区間を地図上で設定します。
             */
            mapToRegurationAddress: function () {
                // 地図タブを表示
                this.trafficContainer.selectChild(this.mapTab);
                // 規制区間設定
                this.enableSelectRegulatedSection();
            },

            /**
             * 被災箇所を地図上で設定します。
             */
            mapToDamageAddress: function () {
                // 地図タブを表示
                this.trafficContainer.selectChild(this.mapTab);
                // 被災箇所設定
                this.enableSelectDamagedSection();
            },

            /**
             * 工事区間を地図上で設定します。
             */
            mapToConstructAddress: function () {
                // 地図タブを表示
                this.trafficContainer.selectChild(this.mapTab);
                // 工事区間設定
                this.enableSelectConstructedSection();
            },

            /**
             * 住所検索で入力された住所の地図を表示します。
             */
            searchAddress: function (event) {
                // エンターキーが押された場合
                if (event.keyCode === 13) {
                    if (this.input.get('value')) {
                        geocoder.Services.geocoding({
                            url: config.geocode && config.geocode.url[UserInfo.getRunningMode()]
                        }).geocode().text(this.input.get('value'))
                            .run(lang.hitch(this, function (err, result) {
                                if (err) {
                                    // エラー時はダイアログを出して終了
                                    this.chain.infoError(err);
                                    return;
                                }
                                var results = result.results;
                                if (!results.length) {
                                    // 該当位置情報なし
                                    this.chain.info('入力された住所が見つかりません。');
                                    return;
                                }
                                // 地図を移動する
                                var latlng = lang.mixin(null, results[0].latlng);
                                this.map.panTo(latlng);
                            }));
                    }
                }
            },

            /**
             * TipsPopupをセットする。
             */
            setTipsPopups: function () {
                // 被災規模
                this.setDmgScaleTips();
            },

            /**
             * 被災規模のtips
             */
            setDmgScaleTips: function () {
                // 被災規模
                var dmgScaleTips = new TooltipDialog({
                    id: 'dmgScaleTips',
                    style: 'width: 300px; height:100px',
                    content: '<p>入力例</p>'
                });
                this.own(dmgScaleTips);
                on(dom.byId('dmgScaleLabel'), 'mouseover', function () {
                    popup.open({
                        popup: dmgScaleTips,
                        around: dom.byId('dmgScaleLabel')
                    });
                });
                on(dom.byId('dmgScaleLabel'), 'mouseleave', function () {
                    popup.close(dmgScaleTips);
                });
            },

            /**
             * 規制区間の距離を自動算出する。
             */
            calcDistance: function () {

                var distance = 0; // 規制区間の距離（メートル）
                var begin = null;
                var end = null;

                // LineStringの各区間ごとの距離を計測する
                this.regulatedLayers.forEach(lang.hitch(this, function (layer) {
                    layer.getLatLngs().forEach(lang.hitch(this, function (lanlng) {
                        begin = end; // 前区間の終点を始点にする
                        end = lanlng;
                        if (begin) {
                            distance += begin.distanceTo(end);
                        }
                    }));
                }));

                // km単位で規制延長に表示
                this.regLength.set('value', Math.round(distance) / 1000);

            },

            /**
             * 背景地図ダイアログを表示する。
             */
            showBaseLayerDialog: function () {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({ map: this.map })
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            }

        });
});

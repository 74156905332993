/**
 * チェック可能なツリー用モジュール。
 * @module app/evacorder/EvacOrderCheckTree
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/topic',
    'dojo/has',
    'idis/view/tree/CheckTree'
], function(module, declare, topic, has, CheckTree) {
    /**
     * 各要素をチェック可能なツリー
     * @class EvacOrderCheckTree
     * @extends module:idis/view/tree/CheckTree~CheckTree
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), CheckTree,
    /** @lends module:idis/view/tree/CheckTree~EvacOrderCheckTree# */ {

        // 初期表示でツリーを展開する
        autoExpand: true,

        /**
         * ツリー要素がチェックされたときに呼ばれる。
         * CheckTreeクラスをのonCheckChangeをオーバーライドしている。
         * チェック時にレイヤーの選択状態・非選択状態を設定する。
         *
         * @param {Object} item ツリー要素
         * @param {boolean} checked チェック状態
         * @returns {None|Promise} 非同期処理を実行する場合はPromiseを返す。
         * override
         */
        onCheckChange: function(item, checked) {
            var id = this.model.getIdentity(item);
            console.debug(module.id + '#onCheckChange: id=' + id + ', checked=' + checked);
            topic.publish(module.id + '::selectDistrict', id);
            topic.publish(module.id + '::calcEvacOrderTarget');
        }
    });
});

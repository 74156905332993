/**
 * 地震情報画面用モジュール。
 * @module app/earthquake/view/EarthquakePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/EarthquakePage.html',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    '../../config',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './EarthquakeGrid'
], function(module, declare, lang, Router, template, IdisRest, _PageBase, config) {
    /**
     * 地震情報画面
     * @class EarthquakePage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/earthquake/view/EarthquakePage~EarthquakePage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--earthquake',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'kisyoHeaderId',
                target: '/api/earthquakes',
                rangeStartParam: 'offset'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 検索時刻を初期化する
            this.initForm();
            // グリッドを初期化する
            this.initGrid();
        },

        initForm: function(){
            // 県名表示
            this.prefName.innerHTML = config.municInfo.prefName;
            // 検索時刻を初期化する
            this.initTime();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            var reportDatetimeFrom = new Date(this.reportDatetimeFrom._getValueAttr()).getTime();
            filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);

            var reportDatetimeTo = new Date(this.reportDatetimeTo._getValueAttr()).getTime();
            filter = filter.eq('reportDatetimeTo', reportDatetimeTo);

            filter = filter.eq('prefFlg', this.prefFlg.checked);

            this.earthquakeGrid.set('collection', this.store.filter(filter));
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.earthquakeGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            if (value.reportDatetimeFrom) {
                var reportDatetimeFrom = new Date(value.reportDatetimeFrom).getTime();
                filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
            }

            if (value.reportDatetimeTo) {
                var reportDatetimeTo = new Date(value.reportDatetimeTo).getTime();
                filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
            }

            filter = filter.eq('prefFlg', this.prefFlg.checked);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.earthquakeGrid.set('collection', collection);
        },

        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        // 検索時刻の初期値をセット
        initTime: function(){
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(startDate.getDate()-7);
            this.reportDatetimeTo._setValueAttr(endDate);
            this.reportDatetimeFrom._setValueAttr(startDate);
        },

        showDetailDialog: function(item) {
            var url = '/data/adess-xml-xslt/' + item.kisyoHeaderId + '.xml';
            window.open(url, '_blank');
        },

        onEvacOrderRegisterButtonClick: function(evt) {
            console.log('[新規登録]ボタンがクリックされました。');
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 避難状況登録登録画面へ遷移
            Router.moveTo('evacorder/register');
        }
    });
});

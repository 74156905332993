/**
* 体制状況・報告一覧画面用モジュール。
* @module app/disasterprevention/view/DisasterPreventionPrefStatusPage
*/
define([
    'module',
    'app/disaster/model/DisasterType',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DisasterPreventionPrefStatusPage.html',
    'idis/control/Locator',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/control/Router',
    './model/DistributionType',
    './_DisasterPreventionPageBase',
    '../../config',
    // 以下、変数として受け取らないモジュール
    'app/disasterprevention/view/PrefPromotionBureauListSelector',
    'app/provide/ProvidePreventionRegisterDialog',
    'app/view/form/OrganizationSelector',
    'app/view/form/UserSelector',
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './DisasterPreventionPrefStatusGrid',
    './DisasterPreventionRegisterPage',
    './DisasterPreventionDetailPage',
    './DisasterPreventionFormDialog'
], function(module, DisasterType, declare, lang, json, template, Locator, Requester, IdisRest, DialogChain,
                Loader, UserInfo, Router, DistributionType, _PageBase, config) {

    /**
     * 検索条件保管store
     */
    var disasterPreventionPrefStatusFilterStore = {};

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--disasterprevention',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 自治体コード(デフォルト：所属振興局)
         */
        municipalityCd: null,

        /**
        * 職員参集管理パラメータ格納用オブジェクト
        * @type {Object}
        */
        DisasterPrevInfItems : null,

        /**
         * 新規登録ボタン表示/非表示切り替えフラグ
         * ボタンを表示する場合はtrue
         */
        _buttonFlg: true,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.confirmDialog);
        },

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention/pref'
            });
            // 自治体コード(デフォルト：所属振興局)
            // 市町村と共通関数を使用するため振興局も変数名をmunicipalityCdとする。
            // HTMLやAPIの要素名はareaCdのため、注意すること
            this.municipalityCd = UserInfo.getRegionCd() || config.municInfo.prefMunicCd;
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initForm();
            this.changeButton();
            this.initDetailPage();
            this.initGrid();
        },

        /**
         * 検索条件が保管されている場合、storeから取得して検索条件を設定する。
         * 検索条件が保管されていない項目は初期条件を設定する。
         */
        initForm: function() {
            var getFormFlg = true;

            // 県・振興局
            this.municipalityCd = UserInfo.getRegionCd() || config.municInfo.prefMunicCd;
            if(Locator.getQuery().areaCd){
                // 県コードは画面上はPrefectureCdで扱う
                this.municipalityCd = Locator.getQuery().areaCd;
            }
            this.municipalitySelector.set('value', this.municipalityCd);

            // storeに保管されている県・振興局と検索対象が異なる場合、
            // 検索条件は必ず初期化する。
            if (disasterPreventionPrefStatusFilterStore.areaCd !== this.municipalityCd) {
                getFormFlg = false;
                disasterPreventionPrefStatusFilterStore = {};
            }
            // 体制状況
            //  -- 体制状況は自治体と災害種別から特定されるが、非同期で処理され制御不可のため、
            //     保管の対象外とする。
            // 報告日時
            if (getFormFlg && disasterPreventionPrefStatusFilterStore.reportDateTimeFrom) {
                this.reportDateTimeFrom.set('value', disasterPreventionPrefStatusFilterStore.reportDateTimeFrom);
            }
            if (getFormFlg && disasterPreventionPrefStatusFilterStore.reportDateTimeTo) {
                this.reportDateTimeTo.set('value', disasterPreventionPrefStatusFilterStore.reportDateTimeTo);
            }
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                evt.item.areaCd = evt.item.areaCd;
                this.showDetailDialog(evt.item);
            }));
            this.updateGridQuery();
        },

        /**
         * 詳細ダイアログのフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('put-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                var provideFlg = sendData.provideFlg;
                delete sendData.provideFlg;
                // 災害IDをセット
                sendData.disasterId = this._disasterId;

                this.distributionType = sendData.disPrevDistributionType;

                // 配信種別：更新ボタン（insert）→'04' 訂正ボタン（update）→'05'
                var messg = 'この報告';
                if(sendData.updateFlg===1){
                    messg += 'を訂正します。よろしいですか？';
                }else{
                    messg += 'を更新します。よろしいですか？';
                }

                // 配信対象であれば配信内容確認画面を表示
                if (provideFlg) {
                    // 登録情報確認ダイアログ
                    this.showConfirmDialog(sendData);
                    return;
                } else {
                    delete sendData.isUnsentLalert;
                }

                // 外部配信対象外の場合、共通の登録確認画面
                this.chain.confirm(messg, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.registerStore.put(sendData)).then(lang.hitch(this, function() {
                        this.initGrid();
                        chain.infoComplete(lang.hitch(this, function() {
                            // 防災ポータル用JSON作成処理を呼び出す
                            // （今回の操作以前のデータが出力対象の場合に今回のデータが抽出されることがあるため、別トランで再出力）
                            var promise = Requester.get('/api/disasterPrevention/createPublicPageJson/');
                            Loader.wait(promise).then(lang.hitch(this, function () {
                                dialog.hide();
                                chain.hide();
                            }), function (err) {
                                chain.infoError(err);
                            });
                            dialog.hide();
                            chain.hide();
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細ダイアログの取消処理を受ける
            page.on('put-revert-send', lang.hitch(this, function(evt) {
                this._clickCancelBtn = true;
                var sendData = evt.value;
                // 配信対象であれば配信内容確認画面を表示
                if (sendData.provideFlg) {
                    // 配信種別
                    this.distributionType = sendData.disPrevDistributionType;
                    // 配信フラグ
                    this._lastDetailItem.provideFlg = sendData.provideFlg;
                    // 削除対象フラグ
                    this._lastDetailItem.allDelete = sendData.allDelete;
                    // 登録情報確認ダイアログ
                    this.showConfirmDialog(this._lastDetailItem);
                    return false;
                }

                // 外部配信対象外の場合、共通の確認ダイアログを開く
                this.chain.confirmDel('この報告', lang.hitch(this, function(chain) {
                    // OKなら取消リクエストを実施
                    Loader.wait(this.deleteStore.put(sendData)).then(lang.hitch(this, function() {
                        chain.infoComplete(lang.hitch(this, function() {
                            // 取消成功時
                            this.initGrid();
                            // 各ダイアログを閉じる
                            this.detailDialog.hide();
                            chain.hide();
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                }));
            }));
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                    // 検索条件を保管する
                    this.setFilterToStore();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            this.municipalityCd = value.areaCd;
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);
            filter = filter.eq('disasterTypes',value.disasterType);
           // 県・振興局コード
            filter = filter.eq('areaCd', this.municipalityCd);
            //体制状況
            // if (value.status) {
            //     filter = filter.eq('status', value.status);
            // }
            // 過去報表示
            // NOTE:長崎県案件では検索条件対象外（常に過去報表示true）とする。
            // filter = filter.eq('pastReportMode', this.pastReport.checked);
            filter = filter.eq('pastReportMode', true);
            // 報告日時FROM
            if (value.reportDateTimeFrom) {
                var reportDateTimeFrom = new Date(value.reportDateTimeFrom).getTime();
                filter = filter.eq('reportDateTimeFrom', reportDateTimeFrom);
            }
            // 報告日時TO
            if (value.reportDateTimeTo) {
                var reportDateTimeTo = new Date(value.reportDateTimeTo).getTime();
                filter = filter.eq('reportDateTimeTo', reportDateTimeTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);

            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    // データがあれば新規登録ボタンを非表示にする
                    this._noRecordFlg = false;
                }else{
                    // データがあれば新規登録ボタンを表示にする
                    this._noRecordFlg = true;
                }
                this.changeButton();
            }));
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionPrefListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention/pref');
        },

        /**
         * 体制マスタ管理画面へ遷移
         */
        moveToDisasterPreventionStatus: function() {
            var param =  {'from': 'disasterprevention/status/pref'};
            if (Locator.getQuery().areaCd) {
                param.areaCd = Locator.getQuery().areaCd;
            }
            Router.moveTo('disasterpreventionstatus', param);
        },

        /**
         * 職員参集管理画面へ遷移
         */
        moveToEmpGatherStatus: function(){
            Router.moveTo('employgathering/pref', {'areaCd':this.municipalityCd});
        },

        /**
         * 検索条件をstoreに保管する。
         */
        setFilterToStore: function() {
            // 変数の初期化
            disasterPreventionPrefStatusFilterStore = {};
            // 現在の検索条件
            var value = this.form.get('value');
            // 市町村
            disasterPreventionPrefStatusFilterStore.areaCd = value.areaCd;
            // 体制状況
            //  -- 体制状況は自治体と災害種別から特定されるが、非同期で処理され制御不可のため、
            //     保管の対象外とする。
            // 報告日時
            if (value.reportDateTimeFrom) {
                disasterPreventionPrefStatusFilterStore.reportDateTimeFrom = value.reportDateTimeFrom;
            }
            if (value.reportDateTimeTo) {
                disasterPreventionPrefStatusFilterStore.reportDateTimeTo = value.reportDateTimeTo;
            }
        }
    });
});

/**
* バイナリベクトルタイルの表示・非表示設定ダイアログ用パーツ
* @module app/map/vectorTile/vectorTileDialog
*/
define([
    'module',
    'dojo',
    'dojo/aspect',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/store/Memory',
    'dojo/text!./templates/vectorTileDialog.html',
    'dijit/Dialog',
    'dijit/tree/ObjectStoreModel',
    'idis/view/tree/CheckTree',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/InfoDialog',
    './VectorTileLayerTreeNode',
    // 以下、変数で受けないモジュール
    'app/map/vectorTile/vectorTileStyle'
], function(module, dojo, aspect, array, declare, lang, Memory, template, 
    Dialog, ObjectStoreModel, CheckTree, _IdisWidgetBase, InfoDialog, VectorTileLayerTreeNode) {
        var content = declare('_vectorTileDialogContent', _IdisWidgetBase, {
            templateString : template,
            baseClass: 'vectorTileDialog-Container',
            widgetsInTemplate : true
        });
        
        var container = declare(module.id.replace(/\//g, '.'), [Dialog], {

        'class': 'vectorTileDialog-NonModal',
        
        _addedImageList:[],
        
        _stdTreeCreated: false,
        _optstdTreeCreated: false,
        _optstdPhotoTreeCreated: false,
        _riverTreeCreated: false,
        _roadTreeCreated: false,
        _railwayTreeCreated: false,
        _portTreeCreated: false,
        _aviationTreeCreated: false,
        _cityTreeCreated: false,
        _masterTreeCreated: false,
        
        _stdTree: {},
        _optstdTree: {},
        _optstdPhotoTree: {},
        _roadTree: {},
        _railwayTree: {},
        _portTree: {},
        _aviationTree: {},
        _cityTree: {},
        _masterTree: {},
        
        _stdStyle: {},
        _optstdStyle: {},
        _optstdPhotoStyle: {},
        _riverStyle: {},
        _roadStyle: {},
        _railwayStyle: {},
        _portStyle: {},
        _aviationStyle: {},
        _cityStyle: {},
        _masterStyle: {},
        
        style: 'position:absolute;top:75px;left:200px;width:400px;height:500px;',

        _mapObj: null,
        
        /**
        * コンストラクター
        */
        constructor : function(options){
            lang.mixin(this, options);
            
            this.inherited(arguments);
            this.inner = new content();
        },
        
        /**
        * DOM生成後の処理
        * このモジュールはwidgetbaseを継承しないので, constructorから
        * 直接呼んでいる
        */
        postCreate : function(){
            this.inherited(arguments);
            this.set('content', this.inner);
            
            if (this.inner.styleDialog) {
                var styleDialog = this.inner.styleDialog.getChildren()[0];
                
                styleDialog.okButton.on('click', lang.hitch(this, function(styleDialog) {
                    var result = this.getDialogFormValue(styleDialog);
                    
                    var treeType = this.getTabInfo();
                    var style = this.getTileStyle(treeType);
                    var idList = result.id.split(',');
                    
                    array.forEach(idList, lang.hitch(this, function(id) {
                        this.saveTileStyle(id, style, null, null, null, result.styleSetting);
                    }));
                    
                    InfoDialog.show('成功', 'レイヤのスタイルを変更しました。');
                }));

                styleDialog.okButton.on('click', lang.hitch(this, function(styleDialog) {
                    var result = this.getDialogFormValue(styleDialog);
                    
                    var treeType = this.getTabInfo();
                    var style = this.getTileStyle(treeType);
                    var idList = result.id.split(',');
                    
                    array.forEach(idList, lang.hitch(this, function(id) {
                        this.saveTileStyle(id, style, null, null, null, result.styleSetting);
                    }));
                    
                    InfoDialog.show('成功', 'レイヤのスタイルを変更しました。');
                }));

                styleDialog.iconSelectButton.on('click', lang.hitch(this, function(){
                    styleDialog.iconSelectDialog.show();
                }));

                styleDialog.iconSelectDialog.inner.iconSelectDone.on('click', lang.hitch(this, function(){
                    // 初回だけデータがUndefinedになる対策
                    if (!!styleDialog.iconSelectDialog.selectedIcon) {
                        styleDialog.iconImage.src = styleDialog.iconSelectDialog.selectedIcon.src;
                    }
                }));
            }
            
            this.inner.resetButton.on('click', lang.hitch(this, function() {
                var treeType = this.getTabInfo();
                if (this[treeType + 'CheckTree']) {
                    //  ツリーを一度破棄する
                    this[treeType + 'CheckTree'].destroyRecursive();
                    delete this[treeType + 'CheckTree'];

                    this.switchTreeMap(treeType, true);
                }
            }));
            
            aspect.after(this.inner.tabs, 'selectChild', lang.hitch(this, function() {
                var treeType = this.getTabInfo();
                var isCreate = this['_' + treeType + 'TreeCreated'] ? false : true;
                this.switchTreeMap(treeType, isCreate);
            }));
        },
        
        // モジュールの開始処理
        startup: function() {
            this.inherited(arguments);
        },
        
        buildRendering: function() {
            this.inherited(arguments);
        },
        
        // ダイアログを開く時の処理
        show : function(){
            this.inherited(arguments);
            var treeType = this.getTabInfo();
            var isCreate = this['_' + treeType + 'TreeCreated'] ? false : true;
            this.switchTreeMap(treeType, isCreate);

            this.inner.borderContainer.resize();
        },
        
        // ダイアログを閉じた時の処理
        hide: function() {
            this.inherited(arguments);
        },

        getTargetLayer : function(mapObj) {
            var result;
            Object.keys(mapObj._layers).forEach(lang.hitch(this, function (idx) {
                var layer = lang.mixin(null, mapObj._layers[idx]);
                if (layer.options && (layer.options.baseLayerId || layer.options.baseLayerId === 0)) {
                    result = layer._glMap;
                }
            }));
            return result;
        },
        
        /**
        * 処理名：表示設定用ツリーと地図の切り替え
        * 処理概要：表示設定用ツリーと地図を切り替える
        *
        * @param treeType 種別
        * @param isCreate ツリー初期化フラグ
        * @return なしCreate
        */
        switchTreeMap :function(treeType, isCreate) {
            if (isCreate) {
                this.resetTileStyle(treeType);
                this.createTree(treeType);
            }
            
            var style = this.getTileStyle(treeType);
            
            var targetGl = this.getTargetLayer(this._mapObj);
            targetGl.setStyle(style);

            this._addedImageList.forEach(function(addedImage) {
                targetGl.loadImage(addedImage.url, function (error, image) {
                    if (error) {
                        throw error;
                    }
                    if (!targetGl.hasImage(addedImage.name)) {
                        targetGl.addImage(addedImage.name, image);
                    }
                });
            });
        },
        
        /**
        * 処理名：Tree生成。
        * 処理概要：Treeを生成する。
        *
        * @param treeData Tree階層データ
        * @return なしCreate
        */
        createTree :function(treeType) {
            var obj = this.getTree(treeType);
            // storeの作成
            var store = new Memory({
                data: obj,
                getChildren: function(object) {
                    return this.query({parentId: object.id});
                },
                getParentIdentity: function(object) {
                    var result = null;
                    var parent = this.query({id: object.parentId});
                    if (parent[0] && parent[0].id) {
                        result = parent[0].id;
                    }
                    return result;
                }
            });
            var style = this.getTileStyle(treeType);
            this.styleStore = new Memory({
                data: style.layers
            });

            // modelの作成
            var model = new ObjectStoreModel({
                store: store,
                query: {'id': 'root'}
            });
            
            var self = this;
            // treeの作成
            self[treeType + 'CheckTree'] = new CheckTree({
                style: 'height: auto',
                model: model,
                showRoot:false,
                openOnClick: false,
                branchCheckBox: true,
                leafCheckBox: true,
                autoExpand: true,
                onClick: function(item, widget, evt) {
                    if (this.isIconNode(evt.target, widget)) {
                        this.setChecked(item, !this.isChecked(item));
                        self.selectTree4CheckBox(item.layerIdList, widget, this.isChecked(item));
                    } else if (evt.target && evt.target.type === 'button') {
                        // 何もしない
                        ;
                    } else {
                        this._onExpandoClick({ node: widget });
                    }
                },
                _onNodeMouseEnter: function(widget) {
                    widget.detailButton.domNode.style.display = '';
                },
                _onNodeMouseLeave: function(widget) {
                    widget.detailButton.domNode.style.display = 'none';
                },
                _createTreeNode: function (kwArgs) {
                    var tileLayerTreeNode = new VectorTileLayerTreeNode(kwArgs);

                    tileLayerTreeNode.detailButton.on('click', lang.hitch(this, function (evt) {
                        var targetGl = self.getTargetLayer(self._mapObj);
                        var idList = evt.target.value;
                        var styleDialog = self.inner.styleDialog.getChildren()[0];
                        if(styleDialog && styleDialog.dialog) {
                            styleDialog.targetLayerIdList.set('value', idList);
                            styleDialog.title.set('value', this.selectedItem.name);
                            styleDialog.toggleForm(targetGl);

                            if (!styleDialog.dialog.open) {
                                styleDialog.dialog.show();
                            }
                        }
                    }));
                    return tileLayerTreeNode;
                },
                onOpen: function(event) {
                    var children = store.getChildren(event);

                    // ツリー初期化直後のツリー展開時にvalueがsetされていたら、以降の処理は不要となる
                    if (children.length) {
                        var firstNode = this.getNodesByItem(children[0]);
                        if (firstNode[0].detailButton && firstNode[0].detailButton.get('value')) {
                            return;
                        }
                    }
                    for (var i = 0; i < children.length; i++) {
                        var tNode = this.getNodesByItem(children[i]);

                        if (children[i].id.substring(0,1) === "L") {
                            if (children[i].visibility === 'visible') {
                                this.setChecked(tNode[0].item, true, '');
                            }
                        }
                        var layerIdList = "";
                        if (children[i].id.substring(0,1) === "N") {
                            // ノードの場合、ぶら下がる全ての小要素のidを取得する(一括で表示・非表示、スタイルを切り替える際に使用する)
                            var parentNode = children[i].id;
                                var queryResult = store.query({parentId: parentNode});
                                queryResult.forEach(function(result, idx) {
                                    layerIdList = !idx ? result.layerIdList : layerIdList + ',' + result.layerIdList;
                                });
                            children[i].layerIdList = layerIdList;
                        }

                        tNode[0].detailButton.set('value', layerIdList ? layerIdList : children[i].layerIdList);
                    }
                }
            });
            self[treeType + 'CheckTree'].placeAt(this.inner[treeType + 'TreeNode']);
            self[treeType + 'CheckTree'].startup();
            self[treeType + 'CheckTree'].on('load', lang.hitch(this, function() {
                self[treeType + 'CheckTree'].collapseAll();
            }));
            self['_' + treeType + 'TreeCreated'] = true;
        },
    
        /**
        * 処理名：Treeのチェックボックス選択。
        * 処理概要：Treeのチェックボックスをクリックした時の処理。
        *
        * @param idList 選択した情報(カンマ区切りの文字列)
        * @param treeNode
        * @param isChecked
        * @return なし
        */
        selectTree4CheckBox: function(idList, treeNode, isChecked) {
            if (!treeNode) {
                return;
            }
            var visibility = isChecked ? 'visible' : 'none';
            if (treeNode.item.id.substring(0,1) === "N") {
                var children = treeNode.getChildren();
                Object.keys(children).forEach(lang.hitch(this, function (idx) {
                    var child = children[idx];
                    child.item.visibility = visibility;
                    if (child.item.id.substring(0,1) === "N") {
                        this.selectTree4CheckBox(null, child, isChecked);
                    }
                }));
            }

            if (idList) {
                idList = idList.split(',');
                var treeType = this.getTabInfo();
                var style = this.getTileStyle(treeType);
                var tree = this.getTree(treeType);
                
                array.forEach(idList, lang.hitch(this, function(id) {
                    this.saveTileStyle(id, style, tree, treeNode.item.id, visibility, null);
                }));
            }
        },
        
        /**
        * 処理名：タブの選択状態取得
        * 処理概要：タブの選択状態を取得する
        *
        * @param なし
        * @return タブ名
        */
        getTabInfo: function() {
            var tab = this.inner.tabs.selectedChildWidget.dojoAttachPoint;
            tab = tab.replace('Tab', '');
            return tab;
        },
        
        /**
        * 処理名：styleの設定の初期化
        * 処理概要：styleの設定ファイルを読み込み直し設定を初期化する
        *
        * @param treeType ツリー種別
        * @return なし
        */
        resetTileStyle: function(treeType) {
            var url = location.protocol + '//' + location.hostname;
            url = location.port ? url + ':' + location.port : url;

            this['_' + treeType + 'Style'] = JSON.parse(JSON.stringify(this.getJsonFile('/data/maplibre-gl/gsiTile/style/' + treeType + '_style.json')));
            Object.keys(this['_' + treeType + 'Style'].sources).forEach(lang.hitch(this, function (source) {
                this['_' + treeType + 'Style'].sources[source].tiles[0] = this['_' + treeType + 'Style'].sources[source].tiles[0];
            }));
            this['_' + treeType + 'Style'].sprite = url + this['_' + treeType + 'Style'].sprite;

            this['_' + treeType + 'Tree'] = JSON.parse(JSON.stringify(this.getJsonFile('/data/maplibre-gl/gsiTile/tree/' + treeType + '_tree.json')));
        },
        
        getJsonFile: function(targetFile) {
            // JSONファイルの読込
            var xhrArgs = {
                url: targetFile,
                handleAs: "json",
                sync: true,
                preventCache : true,
                load: function(data){
                },
                error: function(error){
                    // エラーダイアログ
                    console.log('JSON取得に失敗しました。' + error);
                    return null;
                }
            };
            return dojo.xhrGet(xhrArgs).results[0];
        },

        /**
        * 処理名：styleの取得
        * 処理概要：画面上で保持しているstyle設定情報を取得する
        *
        * @param treeType ツリー種別
        * @return style設定
        */
        getTileStyle: function(treeType) {
            return this['_' + treeType + 'Style'];
        },
        
        /**
        * 処理名：ツリーの取得
        * 処理概要：画面上で保持しているツリー情報を取得する
        *
        * @param treeType ツリー種別
        * @return ツリー
        */
        getTree: function(treeType) {
            return this['_' + treeType + 'Tree'];
        },
        
        /**
        * 処理名：style設定ダイアログのform情報取得
        * 処理概要：style設定ダイアログのform情報を取得する
        *
        * @param なし
        * @return idとstyle設定情報のObject
        */
        getDialogFormValue: function() {
            var styleDialog = this.inner.styleDialog.getChildren()[0];
            
            var id = styleDialog.targetLayerIdList.get('value');
            var targetGl = this.getTargetLayer(this._mapObj);
            var type = targetGl.getLayer(id) ? targetGl.getLayer(id).type : '';
            
            var styleSetting = {
                type : type,
                iconImage : {},
                textSize : null,
                textColor : null,
                iconSize : null,
                lineSize : null,
                lineDashArray : [],
                lineColor : null,
                lineOpacity : null,
                fillColor : null,
                fillOpacity : null
            };
            if (styleDialog.iconImage && styleDialog.iconImage.src) {
                    styleSetting.iconImage.url = styleDialog.iconImage.src;
                    styleSetting.iconImage.name = this.getIconName(styleDialog.iconImage.src);
                }
                styleSetting.textSize = parseFloat(styleDialog.textSize.get('value'));
                styleSetting.textColor = styleDialog.textColor.getColor('value');
                styleSetting.iconSize = parseFloat(styleDialog.iconSize.get('value'));
                
                var lineStyle = styleDialog.lineStyle.get('value');
                styleSetting.lineSize = parseFloat(styleDialog.lineSize.get('value'));
                styleSetting.lineColor = styleDialog.lineColor.getColor('value');
                styleSetting.lineOpacity = styleDialog.lineOpacity.getMapOpacity('value');
                if (this.getDashArray(styleSetting.lineSize, lineStyle)) {
                    var dasharray = this.getDashArray(styleSetting.lineSize, lineStyle);
                    dasharray = dasharray.split(',');
                    dasharray.forEach(function (value) {
                        styleSetting.lineDashArray.push(parseFloat(value));
                    });
                } else if (lineStyle === 'solid') {
                    styleSetting.lineDashArray.push(1);
                }

                styleSetting.fillColor = styleDialog.fillColor.getColor('value');
                styleSetting.fillOpacity = styleDialog.fillOpacity.getMapOpacity('value');
                
                return {
                    id : id,
                    styleSetting : styleSetting
                };
            },

        /**
		 * 選択されている線種のDashArray表現を返す。
		 * @public
		 */
		getDashArray: function(weight, lineStyle){
			var dashArrayPattern = null;
			if		(lineStyle==='line'){dashArrayPattern = null;}
			else if (lineStyle==='dashed'){dashArrayPattern = ""+(3*weight) +","+ (2*weight);}
			else if (lineStyle==='oneDotChain'){dashArrayPattern = ""+(3*weight) +","+ (2*weight)+","+ (1*weight)+","+ (2*weight);}
			else if (lineStyle==='twoDotChain'){dashArrayPattern = ""+(3*weight) +","+ (2*weight)+","+ (1*weight)+","+ (1*weight)+","+ (1*weight)+","+ (2*weight);}
			else if (lineStyle==='railway'){dashArrayPattern = null;}
			return dashArrayPattern;
		},
        
        /**
        * 処理名：ポイントデータのアイコン名取得
        * 処理概要：ポイントデータのアイコン名を取得する
        *
        * @param iconUrl アイコンのパス
        * @return iconName アイコン名
        */
        getIconName: function(iconUrl) {
            var iconName = '';
            iconName = iconUrl.substr(iconUrl.lastIndexOf('/'));
            iconName = iconName.replace('/', '');
            iconName = iconName.indexOf('.') > -1 ? iconName.substr(0, iconName.indexOf('.')) : iconName;
            iconName = 'dimapsIcon_' + iconName;
            return iconName;
        },
        
        /**
        * 処理名：タイルの表示設定の保持
        * 処理概要：タイルの表示設定を画面上で保持する
        *
        * @param id id
        * @param style 保存対象のstyle
        * @param tree アイコンのパス
        * @param treeId ツリーのID
        * @param visibility 表示・非表示のフラグ {visible:表示 ,none:非表示}
        * @param styleSetting styleの設定情報
        * @return なし
        */
        saveTileStyle: function (id, style, tree, treeId, visibility, styleSetting) {
            if (visibility) {
                array.forEach(tree, function(treeNode) {
                    if(treeNode.id === treeId) {
                        treeNode.visibility = visibility;
                    }
                });
            }
            
            var targetGl = this.getTargetLayer(this._mapObj);
            var type = targetGl.getLayer(id) ? targetGl.getLayer(id).type : '';
            for(var idx = 0; idx < style.layers.length; idx++) {
                var layer = style.layers[idx];
                if (layer.id === id) {
                    if (visibility) {
                        targetGl.setLayoutProperty(id, 'visibility', visibility);
                        layer.layout.visibility = visibility === 'visible' ? 'visible' : 'none';
                    }
                    
                    if (styleSetting) {
                        if (type === 'symbol') {
                            this.setProperty(id, layer.layout, 'layout', 'icon-image', styleSetting.iconImage);
                            this.setProperty(id, layer.layout, 'layout', 'text-size', styleSetting.textSize);
                            this.setProperty(id, layer.paint, 'paint', 'text-color', styleSetting.textColor);
                            this.setProperty(id, layer.layout, 'layout', 'icon-size', styleSetting.iconSize);
                        } else if (type === 'line') {
                            this.setProperty(id, layer.paint, 'paint', 'line-width', styleSetting.lineSize);
                            this.setProperty(id, layer.paint, 'paint', 'line-color', styleSetting.lineColor);
                            this.setProperty(id, layer.paint, 'paint', 'line-opacity', styleSetting.lineOpacity);
                            this.setProperty(id, layer.paint, 'paint', 'line-dasharray', styleSetting.lineDashArray);
                        } else if (type === 'fill') {
                            this.setProperty(id, layer.paint, 'paint', 'fill-color', styleSetting.fillColor);
                            this.setProperty(id, layer.paint, 'paint', 'fill-opacity', styleSetting.fillOpacity);
                        }
                    }
                    break;
                }
            }
        },
        
        /**
        * 処理名：レイヤのstyle変更
        * 処理概要：レイヤのstyleを変更する
        *
        * @param id id
        * @param targetProp 対象のレイヤプロパティ
        * @param type プロパティの種別
        * @param styleProp styleプロパティの種別
        * @param newStyle 変更後のstyle設定
        * @return なし
        */
        setProperty: function (id, targetProp, type, styleProp, newStyle) {
            if (newStyle === null) {
                return;
            }
            if (!targetProp) {
                return;
            }

            var targetGl = this.getTargetLayer(this._mapObj);
            if (type === 'layout') {
                if (styleProp === 'icon-image') {
                    if (!newStyle.url) {
                        return;
                    }
                    targetGl.loadImage(newStyle.url, lang.hitch(this, function (error, image) {
                        if (error) {
                            throw error;
                        }
                        if (!targetGl.hasImage(newStyle.name)) {
                            targetGl.addImage(newStyle.name, image);
                        }
                        targetGl.setLayoutProperty(id, 'icon-image', newStyle.name);
                        targetProp[styleProp] = newStyle.name;
                        this._addedImageList.push(newStyle);
                    }));
                } else {
                    targetGl.setLayoutProperty(id, styleProp, newStyle);
                    targetProp[styleProp] = newStyle;
                }
            } else if (type === 'paint') {
                targetGl.setPaintProperty(id, styleProp, newStyle);
                targetProp[styleProp] = newStyle;
            }
        }
    });
    
    return container;
});
/**
* 被害状況の新規登録・詳細・続報登録画面用の基底モジュール。
* @module app/sendingHisotry/_SendingHistoryPageBase
*/
define([
    'module',
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-construct',
    'dojo/dom-style',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'dijit/form/Textarea',
    'idis/view/form/WordCountTextarea',
    './SendingHistoryConfig',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, dojo, array, declare, lang, domConstruct, domStyle, Locator,
        UserInfo, DialogChain, _PageBase, Textarea, WordCountTextarea, SendingConfig) {

    /**
     * 配信情報新規登録・詳細画面。
     * @class _SendingPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sendingHisotry/_SendingHistoryPageBase~_SendingHistoryPageBase# */ {

        /**
         * 配信情報ID
         * @private
         */
        _sendingId: null,

        TWITTER_MAX_LENGTH: 140,

        EMERGENCY_MAIL_MAX_LENGTH: 180,

        EMERGENCY_MAIL_BODY_ID_LENGTH: 17,

        EMERGENCY_MAIL_LIMIT: 3,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            // URLから被害状況IDを取得
            this._sendingId = Locator.getQuery().sendingId;
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 入力値の妥当性をチェックする。
         */
        validateForm: function(data) {
            if (!data.subject || data.subject === '') {
                this.chain.info('件名を入力してください。', '入力エラー');
                return false;
            }
            return true;
        },

        // Twitter残り文字数を表示
        showLength: function(evt) {
            // 残り文字数計算
            var text = evt.srcElement.value;
            var num = evt.srcElement.name.substr(11);
            var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
            dojo.byId('twitterLeft'+ num).innerHTML = '残り' + words+ '文字';
        },

        // 本文の文字数に従ってTwitter用の入力項目を作成
        createTweetElements: function(oneTweetText, i) {
            // 枠を追加
            var div = dojo.create('div');
            div.name = 'addedTweet';

            // テキストボックスを作成
            var addedText = new Textarea({
                name: 'twitterBody' + i,
                style: 'height:40px',
                value: oneTweetText
            });

            addedText.startup();

            // 残り文字数を作成
            var leftWords = dojo.create('div');
            var leftWordLength = this.TWITTER_MAX_LENGTH - this.getLen(oneTweetText);
            leftWords.innerHTML = '残り' + leftWordLength + '文字';
            leftWords.id = 'twitterLeft' + i;
            leftWords.name = 'twitterLeft';
            leftWords.align = 'right';

            // 新規作成した要素を親に加える
            this.twitterOuterFrame.appendChild(div);
            div.appendChild(addedText.domNode);
            div.appendChild(leftWords);
        },

        // Tweet枠を削除
        deleteTweet: function(){
            if (this._tweetNum > 1) {
                var element = dojo.byId('twitterOuterFrame');
                element.removeChild(element.lastChild);
                this._tweetNum -= 1;
            }
        },

        // 文字を1文字入力するたびにTwitterの残り文字数を更新
        onBodyTextChange: function(evt){
            setTimeout(lang.hitch(this, this.showLength(evt)), 10);
        },

        /**
         * Twitterの文字数をTwitter基準でカウントする。
         * 日本語は1文字3バイト、英語は1文字1バイトだが
         * バイト数とは関係なく最大文字数は日本語で140字、英語で280文字。
         */
        getLen: function(str){
            var result = 0;
            for(var i=0;i<str.length;i++){
                // 文字コードを取得
                var chr = str.charCodeAt(i);
                // 半角は0.5文字としてカウント
                if((chr >= 0x00 && chr < 0x81) ||
                    (chr === 0xf8f0) ||
                    (chr >= 0xff61 && chr < 0xffa0) ||
                    (chr >= 0xf8f1 && chr < 0xf8f4)){
                    //半角文字の場合は0.5を加算
                    result += 0.5;
                }else{
                    // 全角は1文字としてカウント
                    result += 1;
                }
            }
            //文字数を返す
            return result;
          },

        // Lアラートのカテゴリカスケード選択
        onChangeLalertCategorySelect: function() {
            //オプションのn番目が選択されている場合
            var sendingTypeList = SendingConfig.sendingTypeList;
            var typeNum = this.lalertCategory.get('value');
            this.sendingDetailType.set('options', sendingTypeList[typeNum -1 ]);
            if (this._lalertDetailType) {
                this.sendingDetailType.set('value', this._lalertDetailType);
            } else {
                this.sendingDetailType.set('value','0');
            }
        },

        // 本文の文字数に従ってTwitter用の入力項目を作成
        createEmergencyMailElements: function(oneEmergencyText, i) {
            // 枠を追加
            var div = dojo.create('div');
            div.name = 'addedEmergencyMail';

            // テキストボックスを作成
            var addedText = new WordCountTextarea({
                name: 'emergencyMailBody' + i,
                style: 'width: 80%; height:5em;',
                constraints:{max:180},
                value: oneEmergencyText.bodyText
            });

            addedText.startup();
            // 残り文字数を作成
            var rightWords = dojo.create('div');
            var rightWordsStr = oneEmergencyText.bodyText !== undefined ? oneEmergencyText.bodyText : '';
            var rightWordLength = this.EMERGENCY_MAIL_MAX_LENGTH - this.getLen(rightWordsStr);
            rightWords.innerHTML = '残り' + rightWordLength + '文字';
            rightWords.id = 'emergencyMailRight' + i;
            rightWords.name = 'emergencyMailRight';
            rightWords.align = 'right';

            // 新規追加ではなく、既存のレコードであるか判断するための枠を追加
            var idDiv = dojo.create('input');
            idDiv.type = 'hidden';
            idDiv.name = 'emergencyMailId';
            idDiv.id = 'emergencyMailId' + i;
            var lalertSendHistId = (oneEmergencyText.lalertSendHistId !== undefined && 
                oneEmergencyText.lalertSendHistId !== null) ? oneEmergencyText.lalertSendHistId :
                null;
            idDiv.value = lalertSendHistId;

            // 新規作成した要素を親に加える
            this.emergencyMailOuterFrame.appendChild(div);
            div.appendChild(addedText.domNode);
            div.appendChild(rightWords);
            div.appendChild(idDiv);
        },

        // 緊急速報メール枠を削除
        deleteEmergencyMail: function(){
            if (this._emergencyMailNum > 1) {
                var element = dojo.byId('emergencyMailOuterFrame');
                element.removeChild(element.lastChild);
                this._emergencyMailNum -= 1;
            }
            // 「+」ボタンが隠れている状態でメール枠を削除した場合、「+」ボタンを再表示する
            if(this._emergencyMailNum < this.EMERGENCY_MAIL_LIMIT &&
                this.addEmergencyMailButton.domNode.style.display === 'none') {
                domStyle.set(this.addEmergencyMailButton.domNode, 'display', '');
            }
        },

        // 緊急速報メール残り文字数を表示
        showEmergencyMailLength: function(evt) {
            // 残り文字数計算
            var text = evt.srcElement.value;
            var num = evt.srcElement.name.substr(
                this.EMERGENCY_MAIL_BODY_ID_LENGTH);
            var words =  this.EMERGENCY_MAIL_MAX_LENGTH - this.getLen(text);
            dojo.byId('emergencyMailRight'+ num).innerHTML = '残り' + words+ '文字';
        },

        // 文字を1文字入力するたびに緊急速報メールの残り文字数を更新
        onEmergencyMailBodyTextChange: function(evt){
            this.showEmergencyMailLength(evt);
        }
    });
});

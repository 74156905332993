/**
 * 職員招集 グループ選択部分の共通モジュール
 * @module app/convocation/view/condition/_ConvoAutoMailPageBase
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/_base/array',
    'dstore/Memory',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/ConvoGroup',
    //以下、変数で受けないモジュール
    'dijit/form/CheckBox',
    'app/convocation/view/condition/ConvoAddGroupDialog',
    'app/convocation/view/condition/ConvoAutoMailRegisterGrid'
], function(module, declare, lang, array, Memory, Router, DialogChain, InfoDialog, _PageBase, ConvoGroup) {
    /**
     * 職員招集 グループ選択部分
     * @class _ConvoAutoMailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/_ConvoAutoMailPageBase~_ConvoAutoMailPageBase# */
        {
            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化
             */
            constructor: function() {
                // 条件対象グループ情報のデータ用オブジェクト
                this.store = new Memory({
                    idProperty: 'groupCd'
                });
                // ダイアログの連鎖関係を取得
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoAutoMailRegisterGrid();
                this.initSelectGroupDialog();
                this.own(this.selectGroupDialog);
            },

            /**
             * グループ選択ダイアログの初期化。
             */
            initSelectGroupDialog: function() {
                // thisは各画面を指す
                var page = this.selectGroupDialog.getChildren()[0];
                // ダイアログのregisterイベントをキャッチする
                page.on('register', lang.hitch(this, function(evt) {
                    // ツリーのデータを取得
                    var codes = [];
                    if (Array.isArray(evt.groupCd)) {
                        codes = evt.groupCd;
                    } else {
                        codes = [evt.groupCd];
                    }
                    // gridのstore内に同じコードのグループがあれば追加しない
                    for (var i = 0; i < codes.length; i++) {
                        if (this.store.getSync(codes[i])) {
                            this.chain.info('選択したグループは既に追加されています。', 'エラー');
                            break;
                        }
                    }
                    for (var i = 0; i < codes.length; i++) {
                        ConvoGroup.get(codes[i]).then(lang.hitch(this, function(item) {
                            this.addDistGroupInfo({
                                id: '' + item.id,
                                name: item.name,
                                empNum: item.empNum
                            });
                        }));
                    }
                    page.leave();
                    // form情報のリセット
                    page.form.reset();
                }));
            },

            checkBoxItems: [
                'question1CheckBox',      // 質問1
                'question2CheckBox',      // 質問2
                'question3CheckBox',      // 質問3
                'question4CheckBox',      // 質問4
                'question5CheckBox'       // 質問5
            ],

            parseCheckBoxValue: function(data, value) {
                this.checkBoxItems.forEach(lang.hitch(this, function(item) {
                    if (data[item] === '01') {
                        value[item] = ['on'];
                    } else {
                        value[item] = [];
                    }
                }));
            },

            /**
             * パンくずリストのボタンを押下したときの挙動
             */
            onConvoAutoMailLinkClick: function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/mail');
            },

            /**
             * 対象グループgridの初期化。
             */
            initConvoAutoMailRegisterGrid: function() {
                // gridに設定する
                this.convoAutoMailRegisterGrid.set('collection', this.store);
                // ボタンが押された際の処理を定義
                this.convoAutoMailRegisterGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    var cd = evt.item.groupCd;
                    //helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.moveConvoGroupDetailPage(cd);
                }));
                this.convoAutoMailRegisterGrid.on('deleteButtonClick', lang.hitch(this, function(evt) {
                    var cd = evt.item.groupCd;
                    // 指定したコードのグループをgridから削除する
                    this.deleteDistGroupInfo(cd);
                }));
            },

            /**
             * @param item 追加対象のグループの情報
             * グループをGridに追加する。
             */
            addDistGroupInfo: function(item) {
                // gridに追加
                this.store.addSync({
                    groupCd: item.id,
                    convoGroup: item.name,
                    convoStaffNum: item.empNum
                });
                // 削除した後のstoreをgridに設定
                this.convoAutoMailRegisterGrid.set('collection', this.store);
                // 設定内容を反映するため、gridを更新
                this.convoAutoMailRegisterGrid.refresh();
            },

            /**
             * @param cd グループコード
             * コードに合致したグループをグリッド上から削除する。
             */
            deleteDistGroupInfo: function(cd) {
                var messege = 'グループを削除します。よろしいですか？<br>' + '※この処理ではデータベース上の情報は削除されません。';
                this.chain.confirm(messege, lang.hitch(this, function(chain) {
                    // storeからグループを削除
                    this.store.remove(cd);
                    // 削除した後のstoreをgridに設定
                    this.convoAutoMailRegisterGrid.set('collection', this.store);
                    // 設定内容を反映するため、gridを更新
                    this.convoAutoMailRegisterGrid.refresh();
                    // 確認ダイアログを閉じる
                    chain.hide();
                }));
            },

            deleteDistGroupInfoAll: function() {
                if (this.store.data.length > 0) {
                    var messege = 'グループを削除します。よろしいですか？<br>' + '※この処理ではデータベース上の情報は削除されません。';
                    this.chain.confirm(messege, lang.hitch(this, function(chain) {
                        // storeからグループを削除
                        array.map(this.store.data, function(item) {
                            return item.groupCd;
                        }).forEach(lang.hitch(this, function(cd) {
                            this.store.remove(cd);
                        }));
                        // 削除した後のstoreをgridに設定
                        this.convoAutoMailRegisterGrid.set('collection', this.store);
                        // 設定内容を反映するため、gridを更新
                        this.convoAutoMailRegisterGrid.refresh();
                        // 確認ダイアログを閉じる
                        chain.hide();
                    }));
                }
            },

            /**
             * gridのstore内のグループコード群をformに設定する。
             */
            addGroupsToForm: function() {
                var cdList = [];
                // storeから取得
                this.store.fetch().forEach(function(item) {
                    // グループコードを1つずつリストに格納する
                    cdList.push(item.groupCd);
                });
                // グループコードリストを画面のformに設定
                this.form.value.groupCd = cdList;
            },

            getGroupCdList: function() {
                var list = [];
                this.convoAutoMailRegisterGrid.collection.data.forEach(lang.hitch(this, function(row) {
                    list.push(row.groupCd);
                }));
                return list;
            },

            /**
             * グループ詳細画面へ遷移する
             */
            moveConvoGroupDetailPage: function(groupCd){
                var url = '?id=' + groupCd;
                url += '&p=convocation/groupDetail';
                window.open(url);
            },

            /**
             * 入力されたデータの妥当性をチェックする。
             */
            validate: function() {
                var formData = this.form.get('value');
                // 配信設定名が入力されていない場合
                if (formData.settingName === '') {
                    InfoDialog.show('入力エラー', '配信設定名が入力されていません。');
                    return false;
                }
                // 件名が入力されていない場合
                if (formData.distributionTitle === '') {
                    InfoDialog.show('入力エラー', '件名が入力されていません。');
                    return false;
                }
                // 本文が入力されていない場合
                if (formData.distributionBody === '') {
                    InfoDialog.show('入力エラー', '本文が入力されていません。');
                    return false;
                }

                // 気象注警報と気象エリアがともに入力されていること、またはともに入力されていないこと
                if (
                    (formData.kisyoConditions.length > 0 && formData.kisyoAreas.length === 0) ||
                    (formData.kisyoConditions.length === 0 && formData.kisyoAreas.length > 0)
                ) {
                    InfoDialog.show('入力エラー', '気象警報・注意報と対象市町はともに選択するか、ともに未選択としてください。');
                    return false;
                }

                // 招集メールの場合
                if (this.convoMailType.value === '1') {
                    // 質問1が入力されていない場合
                    if (this.question1.focusNode.checked === false ||
                        formData.question1Text === '') {
                        InfoDialog.show('入力エラー', '質問が入力されていません。');
                        return false;
                    }

                    // 質問1の回答が入力されていない場合
                    if ((this.answer1a.focusNode.checked === false &&
                        this.answer1b.focusNode.checked === false &&
                        this.answer1c.focusNode.checked === false &&
                        this.answer1d.focusNode.checked === false &&
                        this.answer1e.focusNode.checked === false) ||
                        (formData.answer1aText === '' || formData.answer1bText === '' ||
                            formData.answer1cText === '' || formData.answer1dText === '' ||
                            formData.answer1eText === '')) {
                        InfoDialog.show('入力エラー', '回答が入力されていません。');
                        return false;
                    }
                }

                // グループが選択されていない場合
                if (!this.store.data[0]) {
                    InfoDialog.show('入力エラー', 'グループが選択されていません。');
                    return false;
                }
                return true;
            },

            /**
             * 入力した質問文と回答文を一括クリアする。
             */
            resetQAText: function(formValue) {
                formValue.question1Text = null;
                formValue.answer1aText = null;
                formValue.answer1bText = null;
                formValue.answer1cText = null;
                formValue.answer1dText = null;
                formValue.answer1eText = null;
                formValue.question2Text = null;
                formValue.answer2aText = null;
                formValue.answer2bText = null;
                formValue.answer2cText = null;
                formValue.answer2dText = null;
                formValue.answer2eText = null;
                formValue.question3Text = null;
                formValue.answer3aText = null;
                formValue.answer3bText = null;
                formValue.answer3cText = null;
                formValue.answer3dText = null;
                formValue.answer3eText = null;
                formValue.question4Text = null;
                formValue.answer4aText = null;
                formValue.answer4bText = null;
                formValue.answer4cText = null;
                formValue.answer4dText = null;
                formValue.answer4eText = null;
                formValue.question5Text = null;
                formValue.answer5aText = null;
                formValue.answer5bText = null;
                formValue.answer5cText = null;
                formValue.answer5dText = null;
                formValue.answer5eText = null;
            }
        });
});

/**
 * FAX送信用ダイアログモジュール。
 * @module app/broadnotify/BroadnotifyRegisterDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dijit/Viewport',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/on',
    'dojo/text!./templates/BroadnotifyRegisterDialog.html',
    'idis/view/Loader',
    'idis/service/Requester',
    'app/config',
    'idis/view/page/_PageBase',
    'idis/service/GeoService',
    'dojo/request/iframe',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    'idis/store/withEmptyOption',
    'app/model/NotifyType',
    'idis/util/FilesUtils',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/ConfirmDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Textarea',
    'dijit/layout/AccordionContainer',
    'dojox/form/Uploader',
    'idis/view/form/WordCountTextarea',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/BroadnotifyGroupSelector',
    'app/view/form/BroadnotifyTerminalMultiChecker',
    'app/view/form/ChatGroupSelector'
], function (
    module,
    leaflet,
    declare,
    lang,
    domStyle,
    Viewport,
    domClass,
    domConstruct,
    on,
    template,
    Loader,
    Requester,
    config,
    _PageBase,
    GeoService,
    iframe,
    InfoDialog,
    UserInfo,
    withEmptyOption,
    NotifyType,
    FilesUtils,
    Menu,
    MenuItem,
    popup,
    TooltipDialog,
    DialogChain,
    ConfirmDialog
) {
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

        // 添付ファイル
        attachFileList: [],

        constructor: function () {
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function () {
            this.inherited(arguments);
            // テンプレート選択ダイアログを初期化
            this.initTemplateSlectorDialog();
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.templateSelectorDialog);
            // テンプレート種別選択リストを初期化
            this.notifyTypeSelector.set('store', withEmptyOption(NotifyType));
            // 通知元を設定する
            this.initForm();
        },

        /**
         * 一覧画面上部のコンテンツを生成するためのメソッド
         */
        initForm: function () {
            this.broadnotifySender.innerHTML = UserInfo._userInfo.userName;
        },
        /**
         * テンプレート選択ダイアログを表示する
         */
        showTemplateSelectorDialog: function () {
            // テンプレート選択ダイアログを表示
            this.templateSelectorDialog.show();

            // リサイズイベントの発行
            // この処理を入れておかないと、子画面のテンプレート選択ダイアログにて、
            // Gridのヘッダーが表示されないが、ウィンドウのリサイズをすると表示される。
            // digit/Viewport のウィンドウリサイズ時のイベントハンドラのソースを参考にして、
            // ウィンドウリサイズ時と同様に、Viewport に対して resize イベントを発行。
            Viewport.emit('resize');
        },

        /**
         * テンプレート選択ダイアログからの戻りイベントの定義
         */
        initTemplateSlectorDialog: function () {
            // ダイアログの最初の子要素がテンプレート選択画面
            var page = this.templateSelectorDialog.getChildren()[0];

            // ダイアログ側で明細が選択された場合のイベント
            page.on(
                'select',
                lang.hitch(this, function (event) {
                    var formValues = this.form.get('value');

                    // コールバック関数から参照するための変数
                    var self = this;
                    if (formValues.newTitle !== '' || formValues.newContent !== '') {
                        // 既に入力がある場合は、追加確認ダイアログを表示

                        // 確認ダイアログを作成
                        var confirmDialog = new ConfirmDialog({
                            title: '確認',
                            content: '記載されていた内容を破棄し、テンプレートの内容を反映します。<br>よろしいですか。',
                            hideOnOK: true,
                            hideOnCancel: true,
                            // ダイアログでOKボタンが押された場合の動作
                            onOK: function () {
                                // 入力が無い場合は、そのままテンプレートの内容を反映

                                self.templateItems = event.data;

                                // this(self).templateItemsのアイテムを展開
                                self.applyTemplateById(self.templateItems.broadnotifyTemplateId);

                                // テンプレート選択ダイアログは閉じる
                                self.templateSelectorDialog.hide();
                                self.templateSelectButton.set('disabled', true);
                                // ダイアログを非表示にする
                                this.hide();
                            }
                        });

                        // 確認ダイアログを表示
                        confirmDialog.show();
                    } else {
                        // 入力が無い場合は、そのままテンプレートの内容を反映

                        self.templateItems = event.data;

                        // this(self).templateItemsのアイテムを展開
                        this.applyTemplateById(self.templateItems.broadnotifyTemplateId);

                        // テンプレート選択ダイアログは閉じる
                        this.templateSelectorDialog.hide();
                        this.templateSelectButton.set('disabled', true);
                    }
                })
            );
            // ダイアログ画面のフォームをリセットする
            page.form.reset();
        },

        applyTemplateById: function (id) {
            var self = this;
            var promise = Requester.get('/api/broadnotify/templates/' + id, {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(
                function (data) {
                    // 成功時
                    self.applyTemplate(data);
                },
                function (error) {
                    console.error(error);
                }
            );

            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 選択されたテンプレートをフォームに反映する
         * @param data 選択されていた明細のデータ
         */
        applyTemplate: function (data) {
            // 選択されていたテンプレートの項目を個別にコピー
            this.broadnotifySender.innerHTML = data.admin;
            this.notifyTypeSelector.set('value', data.notifyType);
            this.newTitle.set('value', data.subject);
            this.newContent.set('value', data.body);
            var notifyGroupList = data.notifyGroupSendId;
            var notifyUserList = data.notifyUserSendId;
            if (notifyGroupList !== null) {
                this.notifyGroupSendIdSelector.set('value', notifyGroupList);
            } else {
                this.notifyGroupSendIdSelector.set('value', []);
            }
            if (notifyUserList !== null) {
                this.notifyUserSendIdChecker.set('value', notifyUserList);
            } else {
                this.notifyUserSendIdChecker.set('value', []);
            }
        },

        // 日付をゼロパディング
        padZero: function (num) {
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        },

        /**
         * 緊急通知を送信する。
         */
        onSubmit: function () {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // 送信パラメータを設定
                var value = this.form.get('value');
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }
                // 通知先ユーザを選択せず、グループ配下のユーザが存在しているかの確認
                if (
                    (!value.notifyUserSendId || value.notifyUserSendId.length === 0) &&
                    (!value.notifyChatSendId || value.notifyChatSendId.length === 0)
                ) {
                    if (!this.checkGroupTerminal(value)) {
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '配下にユーザが存在しないグループを選択しています。'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                }

                if (this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for (var i = 0; i < this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].broadnotifyAtcFileId);
                    }
                    value.attachFile = attachFileIds.join(',');
                } else {
                    value.attachFile = '';
                }
                this.emit('register', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        /**
         * グループ配下にユーザが存在しているかのチェック
         */
        checkGroupTerminal: function (value) {
            // グループ配下の合計
            var sumNumP = 0;
            for (var n = 0; n < value.notifyGroupSendId.length; n++) {
                this.notifyGroupSendIdSelector.model.store.get(value.notifyGroupSendId[n]).then(
                    lang.hitch(this, function (item) {
                        sumNumP = sumNumP + item.numP;
                    })
                );
            }
            // 選択したグループ配下のユーザ数
            return sumNumP > 0;
        },

        /**
         * ファイルをアップロードする
         */
        loadAttachFile: function () {
            // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
            if (this.attachFile._files.length === 0) {
                //!FilesUtils.isAttachFile(this.attachFile)) {
                return;
            }
            // ファイルがonloadされたときにサーバーに一時保存する
            var self = this;
            self.attachFile.set('disabled', false);
            var promise = iframe
                .post('/api/broadnotify/uploadFile', {
                    form: this.form.id,
                    handleAs: 'json'
                })
                .then(
                    function (data) {
                        //uploaderをリセット
                        self.attachFile.reset();
                        self.attachFileList.push(data);
                        self.showPreview(data);
                        self.attachFile.set('disabled', true);
                    },
                    function (error) {
                        console.log(error);
                        //uploaderをリセット
                        self.attachFile.reset();
                        self.chain.infoError(error);
                    }
                );
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function (data) {
            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = '0';
            var self = this;

            // 画像ファイルの場合
            if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self, false);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self, false);
            }
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function (newNode, uri, fileName, id, self, pic) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', { href: '#' });
                //クリックでファイル取得処理に入る
                download.onclick = function () {
                    self.downloadFile(url, fileName);
                };
            } else {
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            if (pic) {
                var previewUri = self.getPreviewUri(uri);
                domConstruct.place('<img src="' + previewUri + '" width="110">', contentNode);
            }

            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function () {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if (
                fileName.indexOf('.jpg') !== -1 ||
                fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.png') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||
                fileName.indexOf('.JPEG') !== -1 ||
                fileName.indexOf('.PNG') !== -1 ||
                fileName.indexOf('.gif') !== -1
            ) {
                menu.addChild(
                    new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function () {
                            window.open(uri);
                        }
                    })
                );
            }

            menu.addChild(
                new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function () {
                        //IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                })
            );

            menu.addChild(
                new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function () {
                        self.chain.confirmDel(function (chain) {
                            var promise = Requester.del('/api/broadnotify/uploadFile/' + id).then(
                                function () {
                                    chain.infoComplete(function () {
                                        this.chain.hide();
                                        // 該当ファイルを削除
                                        self.attachFileList = [];
                                        // サムネイルとメニューを削除
                                        domConstruct.destroy(newNode);
                                        popup.close(tooltip);
                                        self.attachFile.set('disabled', false);
                                    });
                                },
                                function (error) {
                                    console.log(error);
                                    chain.infoError(error);
                                }
                            );

                            //ローダーの表示
                            Loader.wait(promise);
                        });
                    }
                })
            );

            menu.startup();
            //メニュー表示処理
            this.own(
                on(
                    newNode,
                    'mouseover',
                    lang.hitch(this, function () {
                        popup.open({
                            popup: tooltip,
                            around: newNode,
                            orient: ['above-centered']
                        });
                    })
                )
            );
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function (url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {
                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }
            };
            xhr.send();

            return false;
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function () {
            var formData = this.form.get('value');

            if (!formData.notifyType || formData.notifyType.length === 0) {
                InfoDialog.show('入力チェック', '通知種別が選択されていません');
                return false;
            }

            if (
                (!formData.notifyGroupSendId || formData.notifyGroupSendId.length === 0) &&
                (!formData.notifyUserSendId || formData.notifyUserSendId.length === 0) &&
                (!formData.notifyChatSendId || formData.notifyChatSendId.length === 0)
            ) {
                InfoDialog.show('入力チェック', '通知先が選択されていません');
                return false;
            }
            return true;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function () {
            this.form.reset();  // フォームをリセットする。
            this.notifyGroupSendIdSelector._refresh();
            this.notifyChatSendIdSelector._refresh();
            // 該当ファイルを削除
            this.attachFileList = [];
            this.attachFile.set('disabled', false);
            // プレビュー表示を削除
            this.preview.innerHTML = '';
            // ボタンの活性/非活性初期化
            this.templateSelectButton.set('disabled', false);
        }
    });
});

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'dojo/topic',
    'dojo/on',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'idis/view/form/Button'
], function (module, declare, lang, IdisGrid, topic, on, Selection, Selector, Keyboard, Button) {

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',


        DRAW_CIRCLE: 'app.damage.DamageReportAdminPage' + '::drawCircle',


        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            { field: 'regionName', label: '地域', sortable: false },
            {
                field: 'municipalityName', label: '管轄', sortable: false
            },
            {
                field: '_item', label: '最終更新日時', className: 'updTimestamp',
                formatter: lang.hitch(this, function (item) {
                    if (item.lastUpdTimestamp) {
                        return item.lastUpdTimestamp;
                    } else {
                        return '-';
                    }
                }),
                sortable: false
            },
            { field: 'damageNum', label: '件数', sortable: false },
            {
                label: '人的被害', field: 'human', sortable: false,
                children: [
                    { field: 'deadNum', label: '死者', sortable: false },
                    { field: 'unknownNum', label: '行方不明者', sortable: false },
                    { field: 'seriousNum', label: '重傷者', sortable: false },
                    { field: 'mildNum', label: '軽傷者', sortable: false },
                    { field: 'unknownSympNum', label: '調査中', sortable: false }
                ]
            },
            {
                label: '物的被害', field: 'property', sortable: false,
                children: [
                    { field: 'fullDestlHm', label: '全壊', sortable: false },
                    // {field: 'lhalfDestlHm', label: '大規模半壊', sortable: false},
                    { field: 'halfDestlHm', label: '半壊', sortable: false },
                    { field: 'partDestlHm', label: '一部損壊', sortable: false },
                    { field: 'fldAbvHm', label: '床上浸水', sortable: false },
                    { field: 'fldBlwHm', label: '床下浸水', sortable: false },
                    { field: 'unknownHm', label: '分類未確定', sortable: false }
                ]
            },
            // ボタン
            {
                field: 'list', label: '一覧', sortable: false,
                renderCell: function (item) {
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: '一覧',
                        onClick: function () {
                            on.emit(gridNode, 'listButtonClick', { item: item });
                        }
                    });
                    return button.domNode;
                }
            }


        ],

        renderRow: function (item) {
            var div = this.inherited(arguments);
            topic.publish(this.DRAW_CIRCLE, item);
            return div;
        }
    });
});

/**
 * 洪水予報情報画面用モジュール。
 *
 * @module app/view/page/FloodForecastPage
 */
define([ 'module', 'dojo/_base/declare', 'dojo/_base/lang', 'dojo/date/locale',
		'dojo/text!./templates/FloodForecastPage.html',
		'idis/view/page/_PageBase', 'idis/store/IdisRest',
		// 以下、変数で受けないモジュール
		'dijit/Dialog', 'dijit/form/Form', 'dijit/form/Select',
		'dijit/form/TextBox', 'dijit/layout/BorderContainer',
		'dijit/layout/ContentPane', 'dijit/layout/TabContainer',
		'idis/view/form/AclButton', 'idis/view/form/Button',
		'idis/view/form/DateTimeInput', '../../common/view/StatementDialog',
		'./FloodForecastGrid' ], function(module, declare, lang, locale,
		template, _PageBase, IdisRest) {
	/**
	 * 洪水予報情報画面
	 *
	 * @class FloodForecastPage
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase,
	/** @lends module:app/view/page/FloodForecastPage~FloodForecastPage# */
	{
		// テンプレート文字列
		templateString : template,

		// ルート要素に付与されるCSS
		baseClass : 'idis-Page idis-Page--floodforecast',

		/**
		 * データ格納用オブジェクト
		 *
		 * @type {module:dstore/Store}
		 */
		store : null,

		// DOMノードを生成するためのメソッド
		buildRendering : function() {
			this.inherited(arguments);
			// 自分が削除された時は関連ダイアログも削除する
			this.own(this.statementDialog);
			// 検索時刻を初期化する
			this.initTime();
			// グリッドを初期化する
			this.initGrid();
		},

		constructor : function() {
			// データ格納用オブジェクト
			this.store = new IdisRest({
				idProperty : 'kisyoHeaderId',
				target : '/api/floodForecasts'
			});
		},

		/**
		 * グリッドを初期化する。
		 */
		initGrid : function() {
			// 入力値を元にグリッド用フィルターを作成
			var filter = new this.store.Filter();

			var reportDatetimeFrom = new Date(this.reportDatetimeFrom._getValueAttr()).getTime();
			filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);

			var reportDatetimeTo = new Date(this.reportDatetimeTo._getValueAttr()).getTime();
			filter = filter.eq('reportDatetimeTo', reportDatetimeTo);

			this.floodGrid.set('collection', this.store.filter(filter));
			// グリッドの詳細ボタンクリック時の動作を設定する
			this.floodGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
				// helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
				this.showDetailDialog(evt.item);
			}));
		},
		// 検索時刻の初期値をセット
		initTime : function() {
			var endDate = new Date();
			var startDate = new Date();
			startDate.setDate(startDate.getDate() - 7);
			this.reportDatetimeTo._setValueAttr(endDate);
			this.reportDatetimeFrom._setValueAttr(startDate);
		},
		/**
		 * グリッドの検索条件を指定された値で更新する。
		 *
		 * @param {Object}
		 *            value name属性と値のマッピング
		 */
		updateGridQuery : function(value) {
			// 入力値を元にグリッド用フィルターを作成
			var filter = new this.store.Filter();

			if (value.reportDatetimeFrom) {
				var reportDatetimeFrom = new Date(value.reportDatetimeFrom)
						.getTime();
				filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
			}

			if (value.reportDatetimeTo) {
				var reportDatetimeTo = new Date(value.reportDatetimeTo)
						.getTime();
				filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
			}

			// filterに対応するcollectionを取得
			var collection = this.store.filter(filter);
			// collectionをグリッドにセットする（サーバーにリクエストされる）
			this.floodGrid.set('collection', collection);
		},

		onSubmit : function() {
			try {
				if (this.form.isValid()) {
					// 入力値が正常ならグリッドの検索条件を更新
					this.updateGridQuery(this.form.get('value'));
				}
			} catch (e) {
				console.error(e);
			} finally {
				return false;
			}
		},

		showDetailDialog : function(item) {
			var url = '/data/adess-xml-xslt/' + item.kisyoHeaderId + '.xml';
			window.open(url, '_blank');
		},

        /**
         * 'yyyy-MM-dd HH:mm:ss' を表示形式に変換
         */
        formatDateTime: function(datetime) {
            var reportDateTime = new Date(datetime.replace(/-/g, '/'));
                var dateLabel = locale.format(reportDateTime, {
                    selector: 'date',
                    datePattern: 'MM月dd日'
                });
                var timeLabel = locale.format(reportDateTime, {
                    selector: 'time',
                    timePattern: 'HH時mm分'
                });
            return dateLabel + '&nbsp;' + timeLabel;
        }
	});
});

/**
* 部隊活動登録画面用モジュール。
* @module app/rescue/RescueRegisterPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/text!./templates/RescueRegisterPage.html',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/Loader',
    'leaflet',
    './_RescuePageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector'
], function(module, LayerStore, declare, lang, ItemFileWriteStore, domClass, domConstruct, domGeometry,
    domStyle, json, on, iframe, template, topic, winUtils, Rest, Menu, MenuItem, popup, TooltipDialog,
    registry, DisasterInfo, Locator, Router, IdisMap, UserInfo, Requester, DialogChain, IdisDialog, DrawUtil,
    Loader, leaflet, _RescuePageBase) {
        /**
        * 部隊活動新規登録画面。
        * @class RescueRegisterPage
        * @extends module:app/rescue/_RescuePageBase~_RescuePageBase
        */
        return declare(module.id.replace(/\//g, '.'), _RescuePageBase,
        /** @lends module:app/rescue/RescueRegisterPage~RescueRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 部隊活動新規登録画面を初期化
            this.initRegisterPage();
        },

        /**
         * 部隊活動新規登録画面を初期化する。
         */
        initRegisterPage: function() {
	        //被害情報レイヤーを非表示として初期化
            this.showMarkersFlg = false;

            // 市町村を取得
            var self = this;
            var promise = Requester.get('/api/rescue', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                // 地図を初期化
                self.initMap(data.latitude, data.longitude);

                //初期化
                self.pointLat = '';
                self.pointLng = '';
                self.marker = '';
                self.attachFileList = [];

                // プレビューを初期化
                self.clearPreview();

                // ログインユーザの組織コードを取得する
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

                // 続報番号を初期化
                self._seqNum = 1;

                var selectData = [];

                selectData.push({
                    rescueId  :'',
                    seqNum          :String(self._seqNum)
                });

                var selectContent = {
                    identifier  :'rescueId',
                    label       :'seqNum',
                    items       :selectData
                };

                var rescueItemFileWriteStore = new ItemFileWriteStore({
                        data:  selectContent
                    });
                self.seqNumSelect.set('sortByLabel', false);
                self.seqNumSelect.set('store', rescueItemFileWriteStore);
                self.seqNumSelect.set('value', null);
                self.seqNumSelect.set('disabled', 'disabled');

                // 報告部署にログインユーザの組織情報を設定
                self.organization._setValueAttr(orgCd);

            }, function(error) {
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 部隊活動を登録する。
         */
        registerRescue: function() {
            var sendData = this.form.get('value');

            // 作図をJSON化する
            topic.publish(this.JSONIZE_RQST);

            if(!sendData.layerName) {
                this.setLayerName(sendData);
            }

            if(sendData.activityArea) {
                sendData.actLatitude = this._rescueAddressPointLat;
                sendData.actLongitude = this._rescueAddressPointLng;
            } else {
                sendData.actLatitude = '';
                sendData.actLongitude = '';
            }

            sendData.disasterId = DisasterInfo.getDisasterId();
            sendData.seqNum = this._seqNum;
            if(this._extAdmNum) {
                sendData.extAdmNum = this._extAdmNum;
            }

            //添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].rescueAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            delete sendData.attachFile;
            delete sendData.organizationName;

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
             // フォームのバリデーションを行う（独自チェック）
            if(!this.validateForm(sendData)) {
                return false;
            }

            var jsonStr = json.stringify(sendData);

            var self = this;
            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/rescue', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    // 作図情報を登録
                    var param = '?rescueId=' + data.rescueId;
                    return Requester.post('/api/rescue/uploadjson' + param, {
                        data: self.drawJson,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function() {
                        chain.infoComplete(function() {
                            //ツリーの更新
                            LayerStore.refreshAll();
                            // 一覧画面に移動
                            Router.moveTo('rescue');
                        });
                    }, function(err) {
                        console.log(err);
                        chain.info('作図ファイルの登録に失敗しました。', 'エラー', function() {
                            // 一覧画面に移動
                            Router.moveTo('rescue');
                        });
                    });
                }, function(error) {
                    console.log('error register rescuerescue');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        }
    });
});

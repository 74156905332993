/**
 * 潮位状況情報詳細画面用モジュール。
 * @module app/tide/TideLevelObservationDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/topic',
    'dojo/text!./templates/TideLevelObservationDetailPage.html',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/util/DateUtils',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    './chart/TideWindChart',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/tide/TideLevelCorrectDialog',
    './grid/TideLevelObservationDetailPageGrid',
    './TideLegend'
], function(module, array, declare, lang, domStyle, domClass, topic, template,
    _PageBase, Router,Requester, IdisRest, Locator, Loader, DateUtils,
    DateTimeSelect, DataKind, TideWindChart)  {
    /**
     * 潮位状況情報詳細画面
     * @class TideLevelObservationDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/tide/TideLevelObservationDetailPage~TideLevelObservationDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--tidelevelobs',

        // ストア
        store: null,

        lastRequestDateTime: '',

        // 時間モード
        mode: '10',

        _changeFlg : false,

        constructor: function() {
            this.store = new IdisRest({
                target: '/api/tide/detail'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 初期表示は、時間モード10分で最新の時間データ
            // 最新の観測情報を取得する
            Requester.get('/api/tide/latestDataTime?timeMode=10').then(lang.hitch(this, function(data){
                // 雨量データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'tideId',
                    target: '/api/tide/detail'
                });
                // 日付セレクトボックスを生成・設置する
                var dateQuery = Number(Locator.getQuery().date);
                if(dateQuery){
                    data = dateQuery;
                }
                // 日付セレクトボックスを生成・設置する
                this.dateTimeSelect = new DateTimeSelect({
                    to: data
                });
                //
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
            }));
        },

        startup: function() {
            this.inherited(arguments);
            // 基準面によるグリット列の表示初期化
            this.onChangeDatumModeSelect(this.datumMode.get('value'));
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, function(datetime) {
                // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
                // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみデータを取得するようにする
                if (this.lastRequestDateTime !== (datetime.date + ' ' + datetime.time)) {
                    // データを取得する
                    this.store = new IdisRest({
                        idProperty: 'tideId',
                        target: '/api/tide/detail'
                    });
                    var filter = new this.store.Filter();
                    // 観測日時をフィルターにセット
                    var date = new Date(datetime.date + ' ' + datetime.time).getTime();
                    filter = filter.eq('date', date);
                    // [観測局]のセット
                    var value = this.form.get('value');
                    if (value.observationStationsSelectBox) {
                        filter = filter.eq('observatoryId', value.observationStationsSelectBox);
                    }else{
                    	// 初期化時に非同期処理によりobservatoryIdが取得できていない場合、Locatorからセット
                        filter = filter.eq('observatoryId', Locator.getQuery().observatoryId);
                    }
                    filter = filter.eq('timeMode',this.mode);
                    var collection = this.store.filter(filter);
                    this.grid.set('collection', collection);
                    collection.fetch().then(lang.hitch(this, function(data) {
                        if(data.totalLength !== 0){
                            this.initText(data);
                            this.initChart(data);
                        }
                    }));

                }
                this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
            })));
            // 最新ボタンクリックを監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, function() {
                // 最新の観測情報を取得
                Requester.get('/api/tide/latestDataTime?timeMode=' + this.mode).then(
                        lang.hitch(this, function(data){
                    // 観測日時セレクトボックスを再構築する
                    // セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
                    this.dateTimeSelect.rebuild(data);
                }));
            })));
            this.initSelectBox();
            // グラフエリアを作る
            this.tideWindChart = new TideWindChart();
            this.chartContainer.on('show', lang.hitch(this, function() {
                this.tideWindChart.create(this.chartNode, this.legendNode);
            }));
        },

        /**
         * 観測所の基準値を初期化する。
         */

        initText: function(data) {
            if(data.totalLength !== 0){
                this.tideLevelAlert.innerHTML =  (this.datumMode.get('value')==='CDL' ?
                    data[0].tideLevelAlertDl : data[0].tideLevelAlertTp) || '-';
                this.tideLevelReport.innerHTML =  (this.datumMode.get('value')==='CDL' ?
                    data[0].tideLevelReportDl : data[0].tideLevelReportTp) || '-';
                this.maxTideLevelRecord.innerHTML =  (this.datumMode.get('value')==='CDL' ?
                    data[0].maxTideLevelRecord : data[0].tpMaxTideLevelRecord) || '-';
                this.regionName.innerHTML = data[0].regionName;
                this.observatoryAddress.innerHTML = data[0].observatoryAddress;
                // 最高潮位
                var maxTideLevelRecord = this.datumMode.get('value')==='CDL' ?
                        data[0].maxTideLevelRecord : data[0].tpMaxTideLevelRecord;
                if (maxTideLevelRecord) {
                    // 最高潮位更新日付
                    var maxTideLevelRecordDate = this.datumMode.get('value')==='CDL' ?
                            data[0].maxTideLevelRecordDate : data[0].tpMaxTideLevelRecordDate;
                    this.maxTideLevelRecord.innerHTML = maxTideLevelRecord +
                            '&nbsp;(' + DateUtils.formatDate(new Date(maxTideLevelRecordDate)) + ')';
                } else {
                    this.maxTideLevelRecord.innerHTML =  '-';
                }
            } else {
                this.tideLevelSpAlert.innerHTML =  '-';
                this.tideLevelAlert.innerHTML =  '-';
                this.tideLevelReport.innerHTML =  '-';
                this.maxTideLevelRecord.innerHTML =  '-';
                this.regionName.innerHTML = '-';
                this.observatoryAddress.innerHTML = '-';
            }
            this.borderContainer.resize();
        },

		/**
		 * 時間モードの初期化を行う
		 */
		setTimeModeSelect: function(){
			var obsInfos = this.observationStationsSelectBox.options;
			var observatoryId = this.observationStationsSelectBox.get('value');
			array.forEach(obsInfos, function(item){
				if(item.value===observatoryId){
					var timeMode;
					// 時間観測局の場合、正時のみ
					if (item.isHourObs) {
						domStyle.set(this.timeModeSelect.domNode, 'display', 'none');
						domStyle.set(this.hourMode, 'display', '');
						timeMode = 'hourly';
					} else {
						domStyle.set(this.timeModeSelect.domNode, 'display', '');
						domStyle.set(this.hourMode, 'display', 'none');
						timeMode = this.timeModeSelect.get('value');
					}
					// 時間モードによって、グリッド更新
					this.onChangeModeSelect(timeMode);
				}
			}, this);
		},

        // セレクトボックスの初期化を行う
        initSelectBox: function(){
            var promise =
                Requester.get('/api/observationStation/selectBox/?dataKind='+ DataKind.TIDE_LEVEL +'&regionCd=');
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                this.observationStationsSelectBox.options = data;
                // 観測局の初期化
                var observatoryId = Locator.getQuery().observatoryId;
                this.observationStationsSelectBox.set('value',observatoryId);
				// 時間モードをセット
				this.setTimeModeSelect();
                }));

        },

        /**
        * Formの値をチェックし、表の更新を行う。
        */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 初回のセットのときはリクエストしない
                    if (!this._changeFlg) {
                        this._changeFlg = true;
                    } else {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
        * 表の更新を行う。
        */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            filter = filter.eq('timeMode', this.mode);

            if (value.observationStationsSelectBox) {
                filter = filter.eq('observatoryId', value.observationStationsSelectBox);
            }

            // 観測日時をフィルターにセット
            var date = new Date(this.lastRequestDateTime).getTime();
            filter = filter.eq('date', date);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
            collection.fetch().then(lang.hitch(this, function(data){
                if(data.totalLength !== 0){
                    this.initText(data);
                    this.initChart(data);
                }
            }));
        },

        /**
         * グラフを作成する
         * @param data 検索結果の観測データ
         */
        initChart: function(data){
            // 潮位グラフ
            this.tideWindChart.create(this.chartNode, this.legendNode,
                {mode:this.mode, data:data, isTpMode: this.datumMode.get('value')==='TP'});
        },

        setCorrectMode: function(){
            this.grid.styleColumn('0-6', 'display: none;');
        },

        onCorrectButtonClick: function(){
            if (!this._correctFlg){
                this.grid.styleColumn('0-6', 'display: table-cell;');
                this._correctFlg = true;
            }else{
                this.grid.styleColumn('0-6', 'display: none;');
                this._correctFlg = false;
            }
        },

        /**
        * [観測局]を変更し、表の更新を行う。
        */
        onObservationStationChange: function(){
            // 時間モードをセット
            this.setTimeModeSelect();
        },

        /**
        * 時間モードが変更された際に呼ばれる
        */
        onChangeModeSelect: function(value) {
			// 正時の場合、1時間モードとする
			this.mode = value === 'hourly' ? '60' : value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);
            this.store.target = '/api/tide/detail';
            // サーバーにリクエストする
            var filter = this.store.Filter();
            // 観測日時をフィルターにセット
            var date = new Date(this.lastRequestDateTime).getTime();
            filter = filter.eq('date', date);
            filter = filter.eq('timeMode',this.mode);
            var timeModeStr = null;
            if(value === '10'){
                timeModeStr = '[10分モード]';
            }
            if(value === '60'){
                timeModeStr = '[正時モード]';
            }

            // 観測局をフィルターにセット
            var observatoryId = this.form.get('value').observationStationsSelectBox;
            filter = filter.eq('observatoryId', observatoryId);

            var collection = this.store.filter(filter);
            this.grid.set('collection', collection);
            // チャートの再生成を行う
            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    this.initText(data);
                    this.initChart(data);
                }
            }));
        },

        // 観測日時の初期時刻を表示するためのメソッド
        initDateTime: function(){
            var date = this.date;

            Requester.get('/api/tide/latestDataTime').then(function(data) {
                date._setValueAttr(data);
            });
        },

        // 一つ前の観測局を取得する
        onPreviousButtonClick: function() {
            var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最初の観測局の場合はなにもしない
			if(value !== options[0].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i - 1].value);
					}
				}));
			}
        },
        // 一つ後の観測極を取得する
        onNextButtonClick: function() {
            var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最後の観測局の場合はなにもしない
			if(value !== options[options.length - 1].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i + 1].value);
					}
				}));
			}
        },

        onTideLevelObservationLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('observation/tide');
        },

        onChangeDatumModeSelect: function(item) {
            switch (item) {
                case 'TP':
                    domClass.add(this.grid.domNode, 'is-tp-mode');
                    domClass.remove(this.grid.domNode, 'is-cdl-mode');
                    break;
                case 'CDL':
                    domClass.add(this.grid.domNode, 'is-cdl-mode');
                    domClass.remove(this.grid.domNode, 'is-tp-mode');
                    break;
            }
            // チャートの再生成を行う
            var collection = this.grid.get('collection');
            if (collection) {
                collection.fetch().then(lang.hitch(this, function(data) {
                    if(data.totalLength !== 0){
                        this.initText(data);
                        this.initChart(data);
                    }
                }));
            }
        }
    });
});

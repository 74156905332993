define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/text!./templates/DistrictDialog.html',
    'idis/view/_IdisWidgetBase',
    'dojo/Deferred',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/_base/array',
    'dojox/layout/FloatingPane',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/IdisDialog',
    'dijit',
    'leaflet',
    './DistrictSelectDialog',
    './CsvFileReadDialog',
    // 以下、変数で受けないモジュール
    'dijit/registry',
    'dijit/Dialog',
    'dijit/TooltipDialog',
    'idis/view/form/RadioGroup',
    'dojox/widget/Standby'
], function (module, dojo, declare, template, _IdisWidgetBase, Deferred, lang, domStyle,
    topic, array, FloatingPane, Requester, Loader, IdisDialog, dijit, L, DistrictSelectDialog, CsvFileReadDialog
) {

    // トピック
    var TOPIC = {
        REMOVE_FEATURE: module.id + '::removeFeature',
        ADD_FEATURE: module.id + '::addFeature',
        // ダイアログサイズ変更
        RESIZE_END: '_districtDialog::resizeEnd'
    };

    var content = declare('_DistrictDialogContent', _IdisWidgetBase, {

        templateString: template,
        baseClass: '_districtDialog-Container',
        widgetsInTemplate: true,
        resize: function (data) {
            // 変更後のダイアログサイズに合わせて地図を載せるdivの高さを変更
            // 100px -> 100 と数字だけにする
            var headerHeight = parseInt(domStyle.get(this.header).height.replace(/px/, ''), 10);
            var footerHeight = parseInt(domStyle.get(this.footer).height.replace(/px/, ''), 10);
            var height = data.h - headerHeight - footerHeight - 50; // 50はborder/margin分

            domStyle.set(this.mapContainer, {
                height: height + 'px',
                width: '100%'
            });

            // 地図部分のサイズを変更
            domStyle.set(this.map, {
                width: domStyle.get(this.mapContainer).width,
                height: domStyle.get(this.mapContainer).height
            });
            topic.publish(TOPIC.RESIZE_END, data);
        }
    });

    // スタイル
    var STYLE = {
        selectedColor: '#ff4500', // 選択状態（オレンジ）
        unselectedColor: '#808080', // 未選択状態（グレー）
        weight: 1
    };

    var container = declare(module.id.replace(/\//g, '.'), [], {
        // 地図
        _map: null,

        // 都道府県レイヤー
        _prefectureLayer: null,

        // 都道府県GeoJsonロード
        _promise: null,

        // 選択されている都道府県コードリスト
        _selectedPrefectures: [],

        // 選択されている市区町村コードリスト
        _selectedCities: [],

        // レイヤー生成済みの市区町村レイヤーマップ <都道府県コード, cityLayer>
        _loadedCityLayers: {},

        // 選択されている市区町村レイヤー
        _selectedCityLayer: null,

        // 表示されているポップアップ
        _popup: null,

        // DrawPanelのz-index
        _drawPanelzIndex: null,

        resizable: true,
        _style: 'position:absolute;top:75px;left:200px;width:600px;height:600px;visibility:hidden;',

        // メインダイアログ(floatingpane)
        pane: null,

        // 行政区域選択ダイアログ
        _districtSelectDialog: null,

        // ファイル読み込みダイアログ
        _csvFileReadDialog: null,

        /**
         * コンストラクター
         */
        constructor: function (options) {
            lang.mixin(this, options);

            this.inherited(arguments);
            this.inner = new content();
            this._popup = L.popup();
            this._events = [];
            this.postCreate();
        },

        /**
         * DOM生成後の処理
         */
        postCreate: function () {
            var self = this;
            this.pane = new FloatingPane(
                {
                    id: '_districtDialog',
                    title: '行政区域',
                    content: this.inner,
                    style: this._style,
                    draggable: true,
                    preload: true,
                    dockable: false,
                    resizable: true,
                    close: function () {
                        self.hide();
                        self._wasShown = false;
                    },
                    bringToTop: function () {
                        // summary:
                        //		bring this FloatingPane above all other panes
                        var windows = array.filter(
                            this._allFPs,
                            function (i) {
                                return i !== this;
                            },
                            this
                        );
                        windows.sort(function (a, b) {
                            return a.domNode.style.zIndex - b.domNode.style.zIndex;
                        });
                        windows.push(this);

                        // z-indexを変更しないよう無効にする
                        //arrayUtil.forEach(windows, function(w, x){
                        //	w.domNode.style.zIndex = this._startZ + (x * 2);
                        //	domClass.remove(w.domNode, "dojoxFloatingPaneFg");
                        //}, this);
                        //domClass.add(this.domNode, "dojoxFloatingPaneFg");
                    }
                },
                dojo.byId('DistrictSelectDialog')
            );

            domStyle.set(this.inner.map, 'height', '400px');
            domStyle.set(this.inner.map, 'width', '100%');

            dojo.connect(
                dijit.registry.byId('_districtDialog'),
                'resize',
                lang.hitch(this, function () {
                    var dialog = dijit.registry.byId('_districtDialog');
                    var widthStr = dialog.domNode.style.width;
                    var heightStr = dialog.domNode.style.height; //不必要になった
                    var leftStr = dialog.domNode.style.left;
                    var topStr = dialog.domNode.style.top;

                    if (widthStr === '' || heightStr === '' || leftStr === '' || topStr === '') {
                        ;
                    } else {
                        var width = parseInt(widthStr.replace('px', ''));
                        var left = parseInt(leftStr.replace('px', ''));
                        var top = parseInt(topStr.replace('px', ''));

                        //いずれかの条件でダイアログを再配置する
                        //下方向に隠れた場合
                        if (this.GetWindowInnerHeight() < top) {
                            //配置位置
                            //縦方向：上から140px
                            //横方向：左から(画面の横幅 - ダイアログの横幅 - 50)px (右から50px)
                            dojo.style(dialog.domNode, 'top', '140px');
                            dojo.style(dialog.domNode, 'left', this.GetWindowInnerWidth() - width - 200 + 'px');
                        }
                        //右方向に隠れた場合
                        if (this.GetWindowInnerWidth() < left) {
                            //配置位置
                            //縦方向：上から140px
                            //横方向：左から(画面の横幅 - ダイアログの横幅 - 50)px (右から50px)
                            dojo.style(dialog.domNode, 'top', '140px');
                            dojo.style(dialog.domNode, 'left', this.GetWindowInnerWidth() - width - 200 + 'px');
                        }
                        //左方向に隠れた場合
                        if (left + width < 0) {
                            //配置位置
                            //縦方向：上から140px
                            //横方向：左から(画面の横幅 - ダイアログの横幅 - 50)px (右から50px)
                            dojo.style(dialog.domNode, 'top', '140px');
                            dojo.style(dialog.domNode, 'left', this.GetWindowInnerWidth() - width - 200 + 'px');
                        }
                        //上方向にメニューバーが隠れた場合
                        if (top + dialog.focusNode.clientHeight < 0) {
                            //配置位置
                            //縦方向：上から140px
                            //横方向：左から(画面の横幅 - ダイアログの横幅 - 50)px (右から50px)
                            dojo.style(dialog.domNode, 'top', '140px');
                            dojo.style(dialog.domNode, 'left', this.GetWindowInnerWidth() - width - 200 + 'px');
                        }
                    }
                })
            );

            this.inner.maxSizeButton.on(
                'click',
                lang.hitch(this, function () {
                    this.changeDialogSize('max');
                })
            );
            this.inner.minSizeButton.on(
                'click',
                lang.hitch(this, function () {
                    this.changeDialogSize('min');
                })
            );
            // 行政区域種別変更を監視
            this.pane.own(
                this.inner.typeRadio.watch(
                    'value',
                    lang.hitch(this, function () {
                        if (this._checkType() === '0') {
                            // 都道府県を選択した場合

                            // 市区町村レイヤーを地図から削除
                            if (this._selectedCityLayer && this._map.hasLayer(this._selectedCityLayer)) {
                                this._map.removeLayer(this._selectedCityLayer);
                                this._selectedCityLayer = null;
                            }

                            // 選択状態で都道府県レイヤーを更新
                            // ダイアログが開いているうちは都道府県レイヤーの選択状態はダイアログ内で保持されているため不要
                            //this._updatePrefectureLayer();

                            // 都道府県レイヤーを地図に表示
                            this._addLayerToMap(this._prefectureLayer);
                        } else {
                            // 市区町村を選択した場合
                            // 都道府県区域選択時にレイヤー切り替えが行われるためここでは何も実施しない
                        }
                    })
                )
            );
            this._addEventHandlers();
            topic.subscribe(TOPIC.RESIZE_END, lang.hitch(this, this._resize));
        },

        // モジュールの開始処理
        // 中ではfloatingpaneの開始をやっている
        startup: function () {
            this.pane.startup();
            this._makeCityLayer();
        },

        // Override
        show: function () {
            this.inherited(arguments);

            dojo.style(
                dijit.byId('_districtDialog').domNode,
                'left',
                this.GetWindowInnerWidth() -
                    parseInt(dijit.registry.byId('_districtDialog').domNode.style.width.replace('px', '')) -
                    200 +
                    'px'
            );
            dojo.style(dijit.byId('_districtDialog').domNode, 'top', '140px');

            this._initMap();
            this._loadPrefectureLayer().then(
                lang.hitch(this, function () {
                    // 市区町村レイヤーが地図に追加されている場合は削除
                    if (this._selectedCityLayer && this._map.hasLayer(this._selectedCityLayer)) {
                        this._map.removeLayer(this._selectedCityLayer);
                        this._selectedCityLayer = null;
                    }

                    // 選択済み区域でダイアログ内の都道府県レイヤーを更新
                    // メイン地図での削除処理があるため、ダイアログで保持している選択リストとそれに応じたレイヤーを更新する必要がある
                    this._updatePrefectureLayer();

                    // 都道府県レイヤーが地図に追加されていない場合は追加
                    if (!this._map.hasLayer(this._prefectureLayer)) {
                        this._addLayerToMap(this._prefectureLayer);
                    }

                })
            );
            // エラー発生時
            this._loadPrefectureLayer().otherwise(function () {
                alert('エラーが発生しました。ダイアログを閉じてもう一度やり直してください。');
            });
            
            this.pane.show();
        },

        // ダイアログを閉じた時の処理
        hide: function () {
            this.inner.emit('activate-draw-panel');
            this.pane.hide();
        },

        // ダイアログのサイズを変更したときに動く処理
        // leafletのmapサイズを合わせて変える
        _resize: function (data) {
            if (this._map) {
                var mapContainerWidth = parseInt(domStyle.get(this.inner.mapContainer).width.replace(/px/, ''), 10);
                var mapWidth = parseInt(domStyle.get(this.inner.map).width.replace(/px/, ''), 10);

                // ダイアログの縮小により, 地図の領域が想定より小さくなる場合がある
                // その場合はもう一度resizeさせることにより補正する.
                // innerのresize操作で手動で領域のサイズを変更させることで
                // floatingpaneの想定サイズとずれてしまうのが根本原因
                if (mapContainerWidth !== mapWidth) {
                    this.pane.resize({
                        w: data.w,
                        h: data.h
                    });
                    return;
                }
                this._map.invalidateSize();
            }
        },

        changeDialogSize: function (mode) {
            var width;
            var height;
            if (mode === 'max') {
                width = this.GetWindowInnerWidth() - 40;
                height = this.GetWindowInnerHeight() - 40;
                dojo.style(dijit.byId('_districtDialog').domNode, 'left', '0px');
                dojo.style(dijit.byId('_districtDialog').domNode, 'top', '-30px');
            } else {
                width = 200;
                height = 90;
                dojo.style(dijit.byId('_districtDialog').domNode, 'left', '0px');
                dojo.style(
                    dijit.byId('_districtDialog').domNode,
                    'top',
                    this.GetWindowInnerHeight() - parseInt(height) - 70 + 'px'
                );
            }

            domStyle.set(this.inner.mapContainer, {
                width: width + 'px',
                height: height + 'px'
            });

            var data = {
                w: width,
                h: height
            };
            this._resize(data);
        },

        /**
         * 選択済み都道府県リストおよび都道府県レイヤーを更新
         * メイン地図でのフィーチャー削除処理があるためそれをダイアログ内の選択リストおよびレイヤーに反映
         */
        _updatePrefectureLayer: function () {
            this.inner.emit('district-update-prefecture-layer', { value: STYLE });
        },

        /**
         * 選択済み市区町村リストおよび市区町村レイヤーを更新
         * メイン地図でのフィーチャー削除処理があるためそれをダイアログ内の選択リストおよびレイヤーに反映
         * @Param 表示対象のCityLayer
         */
        _updateCityLayer: function (layer) {
            this.inner.emit('district-update-city-layer', { value: STYLE, layer: layer });
        },

        /**
         * 市区町村レイヤーをロードする
         * @param {String} 都道府県地区コード '01' ~ '47'
         * @return {Promise}
         */
        _loadCityLayer: function (code) {
            var deferred = new Deferred();
            var self = this;

            if (this._loadedCityLayers.hasOwnProperty(code)) {
                // 選択レイヤーを保持
                self._selectedCityLayer = self._loadedCityLayers[code];

                // 既にレイヤー生成されている場合はキャッシュから取得
                deferred.resolve(self._loadedCityLayers[code]);
                self.cityLayer = self._loadedCityLayers[code];
            } else {
                // レイヤー生成がされていない場合は新規取得してキャッシュ
                Requester.get('../../../data/master/draw/' + code + '.geojson').then(
                    function (response) {
                        self.cityLayer = L.geoJson(response, {
                            style: function (feature) {
                                return {
                                    color: STYLE.unselectedColor,
                                    weight: STYLE.weight,
                                    fillColor: STYLE.unselectedColor,
                                    fillOpacity: 0.5
                                };
                            },
                            onEachFeature: lang.hitch(self, self._onEachCityFeature)
                        });

                        // 市区町村レイヤーキャッシュマップに追加
                        self._loadedCityLayers[code] = self.cityLayer;

                        // 選択レイヤーを保持
                        self._selectedCityLayer = self.cityLayer;

                        deferred.resolve(self.cityLayer);
                    },
                    function () {
                        deferred.reject();
                    }
                );
            }

            return deferred.promise;
        },

        /**
         * 都道府県レイヤーをロードする
         * @return {Promise}
         */
        _loadPrefectureLayer: function () {
            if (!this._promise) {
                this._promise = Requester.get('../../../data/master/draw/prefectures.geojson').then(
                    lang.hitch(this, function (response) {
                        this._prefectureLayer = L.geoJson(response, {
                            style: function (feature) {
                                return {
                                    color: STYLE.unselectedColor,
                                    weight: STYLE.weight,
                                    fillColor: STYLE.unselectedColor,
                                    fillOpacity: 0.5
                                };
                            },
                            onEachFeature: lang.hitch(this, this._onEachPrefectureFeature)
                        });
                    })
                );
            }

            return this._promise;
        },

        /**
         * 都道府県区域フィーチャーへの個別処理を定義
         */
        _onEachPrefectureFeature: function (feature, layer) {
            // 地区コードを付与
            layer.districtCode = feature.properties.dcode;

            // 都道府県フィーチャークリック時の処理を追加
            layer.on(
                'click',
                lang.hitch(this, function () {
                    this._onClickPrefecture(feature, layer, '0');
                })
            );
        },

        /**
         * 都道府県フィーチャークリック時の処理
         */
        _onClickPrefecture: function (feature, layer, type, value, textBox) {
            if (this._checkType() === '0' && type === '0') {
                // 都道府県モード
                // 都道府県の選択状態に応じてレイヤー表示を更新
                this._updateSelected(feature, layer);
            } else {
                // 市区町村モード
                var self = this;

                // 選択された都道府県コードから該当の市区町村レイヤーを生成する
                self._loadCityLayer(feature.properties.dcode).then(
                    function (layer) {
                        // 都道府県レイヤーを地図から削除する
                        self._map.removeLayer(self._prefectureLayer);

                        // 選択済み区域でダイアログ内の市区町村レイヤーを更新
                        // メイン地図での削除処理があるため、ダイアログで保持している選択リストとそれに応じたレイヤーを更新する必要がある
                        self._updateCityLayer(layer);

                        // 市区町村レイヤーを地図に追加
                        self._addLayerToMap(layer);

                        self._selectedCitiesTree(value, textBox);
                    },
                    function () {
                        //　エラー表示
                        alert('エラーが発生しました。ダイアログを閉じてもう一度やり直してください。');
                    }
                );
            }
        },

        /**
         * 市区町村区域フィーチャーへの個別処理を定義
         */
        _onEachCityFeature: function (feature, layer) {
            // 地区コードを付与
            layer.districtCode = feature.properties.dcode;

            // 市区町村フィーチャークリック時の処理を追加
            // 選択状態に応じてフィーチャーの色、選択リストを更新
            layer.on(
                'click',
                lang.hitch(this, function () {
                    // 市区町村の選択状態に応じてレイヤー表示を更新
                    this._updateSelected(feature, layer);
                })
            );
        },

        /**
         * レイヤーを地図に追加
         */
        _addLayerToMap: function (layer) {
            // 地図領域を表示レイヤーの領域に合わせる
            this._map.fitBounds(layer.getBounds());

            // 上記の領域以上に地図を移動できないようにする
            this._map.setMaxBounds(layer.getBounds());

            // レイヤーが地図に追加されていない場合は追加
            if (!this._map.hasLayer(layer)) {
                this._map.addLayer(layer);
            }
        },

        /**
         * 各区域フィーチャーの選択状態に応じてレイヤー表示を設定
         */
        _updateSelected: function (feature, layer, textBox) {
            var self = this;
            if (textBox === null) {
                textBox = true;
            }

            // 選択リストにある場合のインデックス
            var index = -1;

            // 選択済みリスト
            var targetList = [];
            if (textBox === 'file') { // csvファイル読み込み
                if (feature.properties.hasOwnProperty('pname')) {
                    targetList = self._selectedPrefectures;
                } else {
                    targetList = self._selectedCities;
                }
            } else {
                if (self._checkType() === '0') {
                    targetList = self._selectedPrefectures;
                } else {
                    targetList = self._selectedCities;
                }
            }

            // 選択済みかチェック
            var isSelected = array.some(targetList, function (item) {
                index++;
                // イテレーションはヒットするまで実施される
                return item.districtCode === feature.properties.dcode;
            });
            if (isSelected && textBox === 'file') {
                //ファイルの内容反映で選択済みレイヤーが入力された時にここに来る
            } else if (isSelected && textBox !== true) {
                // 既に選択されている場合は選択解除

                // フィーチャーの色を変更
                layer.setStyle({
                    color: STYLE.unselectedColor,
                    fillColor: STYLE.unselectedColor
                });
                // 選択リストから削除
                targetList.splice(index, 1);
                // トピックを発行
                this.inner.emit('remove-feature', { feature });
            } else if (isSelected && textBox === true) {
                //表示とラジオボタンの整合性がないときにここに来る
            } else {
                // 未選択の場合は選択

                // フィーチャーの色を変更
                layer.setStyle({
                    color: STYLE.selectedColor,
                    fillColor: STYLE.selectedColor
                });
                // 選択リストに追加
                targetList.push({ districtCode: feature.properties.dcode });
                // トピックを発行
                this.inner.emit('add-feature', { feature });
                //属性選択ダイヤログの表示
            }
        },

        // 地図初期化
        _initMap: function () {
            if (this._map === null) {
                // 背景地図タイル
                var basemap = L.tileLayer('http://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png');
                //var basemap = L.tileLayer("/xyz/blank/{z}/{x}/{y}.png");

                // 領域はレイヤーのfitBoundsを設定
                this._map = L.map(this.inner.map);

                // 背景地図を地図に追加
                basemap.addTo(this._map);
            }
        },

        /**
         * 選択されている行政区域種別を確認する
         * @return {String} '0'（都道府県） '1'（市区町村）
         */
        _checkType: function () {
            if (this.inner.typeRadio.get('value') === '0') {
                return '0';
            } else {
                return '1';
            }
        },
        /**
         * 処理名：WindowInnerWidth取得。
         * 処理概要：WindowオブジェクトのInnerWidthを取得する。
         *
         * @param なし
         * @return innerWidth
         */
        GetWindowInnerWidth: function () {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        },

        /**
         * 処理名：WindowInnerHeight取得。
         * 処理概要：WindowオブジェクトのInnerHeightを取得する。
         *
         * @param なし
         * @return innerWidth
         */
        GetWindowInnerHeight: function () {
            return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        },

        _addEventHandlers: function () {
            if (!this.inner) {
                return;
            }

            if (this.inner.hideButton) {
                this.inner.hideButton.on(
                    'click',
                    lang.hitch(this, function () {
                        this._onHide();
                    })
                );
            }

            if (this.inner.selectButton) {
                this.inner.selectButton.on(
                    'click',
                    lang.hitch(this, function () {
                        this._onSelect();
                    })
                );
            }

            if (this.inner.fileReadButton) {
                this.inner.fileReadButton.on(
                    'click',
                    lang.hitch(this, function () {
                        this._onFileRead();
                    })
                );
            }
        },

        _onConfirm: function (item, filename) {
            //データの整形
            var checkFlg = false;
            var data = item[0].data.split(',');
            var distdata = '';
            var value = '';
            self = this;
            self.inner.fileName.set('value', filename);
            for (var i = 0; i * 2 < data.length - 1; i++) {
                if (i === 0) {
                    distdata = ['distName', 'distCode'];
                }
                distdata.push([data[i * 2], data[i * 2 + 1]]);
            }
            if (distdata.length >= 2) {
                for (var i = 2; i < distdata.length; i++) {
                    //地区コードがある場合
                    if (distdata[i][1] !== '') {
                        value = distdata[i][1];
                        //2桁だと都道府県
                        if (value.length === 2) {
                            self._prefectureLayer.eachLayer(function (layer) {
                                if (value === layer.districtCode) {
                                    self._updateSelected(layer.feature, layer, 'file');
                                    checkFlg = true;
                                }
                            });
                            //2桁以上は市区町村
                        } else {
                            //6桁だったりするのでDiMAPSで使っている5桁に合わせる
                            value = value.substr(0, 5);
                            self._selectedCitiesFile('code', value);
                        }
                        //地区コードがなくて地域名がある場合
                    } else if (distdata[i][0] !== '') {
                        //まず都道府県のマッチングチェック
                        var distName = distdata[i][0];
                        var matchCount = 0;
                        var prefcode = 1;
                        var distLayer;
                        var distType;
                        self._prefectureLayer.eachLayer(function (layer) {
                            if (distName === layer.feature.properties.pname) {
                                matchCount++;
                                distLayer = layer;
                                distType = 'pref';
                            }
                        });
                        if (distType) {
                            self._allCityJson.forEach(function (item) {
                                if (distName === item.dname) {
                                    matchCount++;
                                    distType = 'city';
                                }
                            });
                        }
                        //matchCountが1の時のみ色付け（地区名の重複なし）
                        if (distType === 'pref' && matchCount === 1) {
                            self._updateSelected(distLayer.feature, distLayer, 'file');
                        } else if (distType === 'city' && matchCount === 1) {
                            self._selectedCitiesFile('name', distName);
                        } else {
                            alert(
                                'エラーが発生しました。同じ地区名複数存在しているか、地区名が見つかりません。ファイルの内容を確認してください。'
                            );
                            return;
                        }
                    }
                }
            }
        },
        _onDistChange: function (value, TextBox) {
            //都道府県の時
            if (this._checkType() === '0') {
                var self = this;
                this._prefectureLayer.eachLayer(function (layer) {
                    if (value === layer.districtCode) {
                        self._updateSelected(layer.feature, layer, TextBox);
                    }
                });
            } else {
                //市区町村の時
                if (this.parent !== value.substr(0, 2) && this.parent !== undefined) {
                    if (this._selectedCityLayer && this._map.hasLayer(this._selectedCityLayer)) {
                        this._map.removeLayer(this._selectedCityLayer);
                    }
                    this._selectedCityLayer = null;
                }
                this.parent = value.substr(0, 2);
                var self = this;
                this.tree = true;
                this._prefectureLayer.eachLayer(function (layer) {
                    if (self.parent === layer.districtCode) {
                        self._onClickPrefecture(layer.feature, layer, '1', value, TextBox);
                    }
                });
            }
        },
        _selectedCitiesTree: function (value, textBox) {
            var self = this;
            if (self.tree) {
                self.cityLayer.eachLayer(function (layer) {
                    if (value === layer.districtCode) {
                        self._updateSelected(layer.feature, layer, textBox);
                    }
                });
            }
            self.tree = false;
        },
        _selectedCitiesFile: function (type, value) {
            var self = this;
            // geojsonを取得する
            self._allCityJson.forEach(function (item) {
                if ((type === 'name' && value === item.dname) || (type === 'code' && value === item.dcode)) {
                    // typeがnameかつ、名称が一致する場合 OR typeがcodeかつ、コードが一致する場合
                    if (item) {
                        // 市区町村コードを取得する
                        var citycode = item.dcode;
                        if (citycode) {
                            var citycodeStr = citycode.toString();
                            // 先頭の2文字が都道府県コードに該当する 都道府県コードとして切り出す
                            var prefCode = citycodeStr.substr(0, 2);

                            // 市区町村geojson(特定の都道府県配下の全市区町村が入ったgeojson)を取得する
                            self._loadCityLayer(prefCode).then(
                                function (citiesLayer) {
                                    var cityLayer = null;

                                    // 都道府県配下の市区町村layerのうち、市区町村コード(citycode)と一致するlayerを取得する
                                    for (var id in citiesLayer._layers) {
                                        if (citiesLayer._layers.hasOwnProperty(id)) {
                                            var layer = citiesLayer._layers[id];
                                            if (layer.districtCode === citycodeStr) {
                                                cityLayer = layer;
                                            }
                                        }
                                    }
                                    self._updateSelected(cityLayer.feature, cityLayer, 'file');
                                },
                                function () {
                                    //　エラー表示
                                    alert('エラーが発生しました。ダイアログを閉じてもう一度やり直してください。');
                                }
                            );
                        }
                    }
                }
            });
            self.tree = false;
        },
        _checkCityName: function (dcode, cityName) {
            self = this;
            var matchCount = 0;
            self._loadCityLayer(dcode);
            self.cityLayer.eachLayer(function (layer) {
                if (cityName === layer.districtName) {
                    matchCount++;
                }
            });

            return matchCount;
        },
        _makeCityLayer: function () {
            var self = this;
            // 全都道府県・市区町村jsonを取得する
            Loader.wait(
                Requester.get('../../../data/master/draw/allCity.json').then(function (data) {
                    // 市区町村分だけを取り出す
                    // 抽出したデータ
                    var filteredData = [];
                    array.forEach(
                        data,
                        lang.hitch(self, function (item) {
                            if (item && item.parent && item.parent !== 'root') {
                                filteredData.push(item);
                            }
                        })
                    );
                    self._allCityJson = filteredData;
                })
            );
        },
        _onSelect: function () {
            if (!this._districtSelectDialog) {
                var distNode = document.createElement('div');
                //distNode.innerHTML = dojo.byId('DistrictSelectDialog').innerHTML;
                dojo.body().appendChild(distNode);
                this._districtSelectDialog = new DistrictSelectDialog(
                    {
                        'id': 'DistrictSelectDialog',
                        'title': '行政区域',
                        'style': 'width: 350px; height:0px; z-index:954;',
                        'draggable': true,
                        'preload': true,
                        'dockable': false,
                        'close': function () {
                            this.hide();
                            this._wasShown = false;
                        }
                    },
                    distNode
                );

                this._districtSelectDialog.on(
                    'district-on-dist-change',
                    lang.hitch(this, function (evt) {
                        this._onDistChange(evt.layers, evt.textBox);
                    })
                );
                this._districtSelectDialog.on(
                    'district-load-city-layer',
                    lang.hitch(this, function (evt) {
                        this._districtSelectDialog._cityLayerPromise = this._loadCityLayer(evt.code);
                    })
                );
                this._districtSelectDialog.on(
                    'district-get-type',
                    lang.hitch(this, function () {
                        this._districtSelectDialog._distType = this._checkType() === '0' ? 'pref' : 'city';
                    })
                );
            }

            this._districtSelectDialog.show();
        },
        _onHide: function () {
            this.hide();
        },

        _onFileRead: function () {
            if (!this._csvFileReadDialog) {
                this._csvFileReadDialog = new CsvFileReadDialog({
                    'title': 'ファイルを開く',
                    'style': 'width:300px; z-index:954;',
                    'draggable': true,
                    'preload': true
                });

                this._csvFileReadDialog.on(
                    'district-csv-change',
                    lang.hitch(this, function (evt) {
                        this._onConfirm(evt.data, evt.filename);
                    })
                );
            }

            this._csvFileReadDialog.dispDialog();
        }
    });

    return container;
});

/**
 * 防災ポータル情報配信テンプレートの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/emergency/_EmergencyNewsTemplateDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/view/form/MunicipalitySelector'
], function(module, declare, InfoDialog, _PageBase) {
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // 市町村
            if (formData.municipalityCd === '') {
                InfoDialog.show('入力チェック', '市町村が選択されていません');
                return false;
            }

            // 件名
            if (formData.tmplSubject === '') {
                InfoDialog.show('入力チェック', '件名が入力されていません');
                return false;
            }

            // 本文
            if (formData.tmplBody === '') {
                InfoDialog.show('入力チェック', '本文が入力されていません');
                return false;
            }

            return true;
        }
    });
});

/**
 * 招集グループ一覧グリッド
 * @module app/convocation/view/employee/ConvoEmployeeListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/_base/lang',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
], function(module, declare, IdisGrid, lang, helper, DateUtils) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            {label: '組織',
                field: 'organizationName',
                children: [
                    helper.column('parentName', '部局', {sortable: false}),
                    helper.column('divisionName', '課', {sortable: false})
                ],
                sortable: false
            },
            helper.column('name', '氏名', {sortable: false}),
            helper.column('emailAddress01', 'メールアドレス1', {sortable: false}),
            helper.column('emailAddress02', 'メールアドレス2', {sortable: false}),
            {field: 'distributionGroupName', label: '配信グループ名', sortable: false,
                formatter: lang.hitch(this, function(item) {
                    return item;
                })
            },
            {field: 'distSettingName', label: '配信設定名', sortable: false,
                formatter: lang.hitch(this, function(item) {
                    return item;
                })
            },
            {field: 'crtTimestamp', label: '登録日時', sortable: true,
                formatter: lang.hitch(this, function(item) {
                    if (!item) {
                        return '-';
                    } else {
                        return DateUtils.format(item, {time: 'HH:mm'});
                    }
                })
            },
            {field: 'updTimestamp', label: '更新日時', sortable: true,
                formatter: lang.hitch(this, function(item) {
                    if (!item) {
                        return '-';
                    } else {
                        return DateUtils.format(item, {time: 'HH:mm'});
                    }
                })
            }
        ]
    });
});

/**
 * 再配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingReConfirmDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/text!./templates/SpecteeMobileCategoryDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, declare, domStyle, template, WidgetBase) {

    /**
     * 配信確認ダイアログ
     * @class SendingConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // 各種フラグ
        _fireFlg: '',
        _lifelineFlg: '',
        _weatherFlg: '',
        _accidentFlg: '',
        _railwayFlg: '',
        _sincidentFlg: '',
        _otherFlg: '',

        /**
         * フォームに値をセットする。
         */
        setValue: function (fire, lifeline, weather, accident, railway, incident, other) {
            // 火災
            if (fire===false){
                this._fireFlg = '';
                domStyle.set(this.checkFire, 'display', 'none');
                domStyle.set(this.fire, 'color', '#000000');
            } else {
                this._fireFlg = '1';
                domStyle.set(this.checkFire, 'display', '');
                domStyle.set(this.fire, 'color', '#314e84');
            }
            this.fireFlg.set('value', fire);
            // ライフライン
            if (lifeline===false){
                this._lifelineFlg = '';
                domStyle.set(this.checkLifeline, 'display', 'none');
                domStyle.set(this.lifeline, 'color', '#000000');
            } else {
                this._lifelineFlg = '1';
                domStyle.set(this.checkLifeline, 'display', '');
                domStyle.set(this.lifeline, 'color', '#314e84');
            }
            this.lifelineFlg.set('value', lifeline);
            // 気象
            if (weather===false){
                this._weatherFlg = '';
                domStyle.set(this.checkWeather, 'display', 'none');
                domStyle.set(this.weather, 'color', '#000000');
            } else {
                this._weatherFlg = '1';
                domStyle.set(this.checkWeather, 'display', '');
                domStyle.set(this.weather, 'color', '#314e84');
            }
            this.weatherFlg.set('value', weather);
            // 事故・道路情報
            if (accident===false){
                this._accidentFlg = '';
                domStyle.set(this.checkAccident, 'display', 'none');
                domStyle.set(this.accident, 'color', '#000000');
            } else {
                this._accidentFlg = '1';
                domStyle.set(this.checkAccident, 'display', '');
                domStyle.set(this.accident, 'color', '#314e84');
            }
            this.accidentFlg.set('value', accident);
            // 鉄道情報
            if (railway===false){
                this._railwayFlg = '';
                domStyle.set(this.checkRailway, 'display', 'none');
                domStyle.set(this.railway, 'color', '#000000');
            } else {
                this._railwayFlg = '1';
                domStyle.set(this.checkRailway, 'display', '');
                domStyle.set(this.railway, 'color', '#314e84');
            }
            this.railwayFlg.set('value', railway);
            // 事件
            if (incident===false){
                this._incidentFlg = '';
                domStyle.set(this.checkIncident, 'display', 'none');
                domStyle.set(this.incident, 'color', '#000000');
            } else {
                this._incidentFlg = '1';
                domStyle.set(this.checkIncident, 'display', '');
                domStyle.set(this.incident, 'color', '#314e84');
            }
            this.incidentFlg.set('value', incident);
            // その他
            if (other===false){
                this._otherFlg = '';
                domStyle.set(this.checkOther, 'display', 'none');
                domStyle.set(this.other, 'color', '#000000');
            } else {
                this._otherFlg = '1';
                domStyle.set(this.checkOther, 'display', '');
                domStyle.set(this.other, 'color', '#314e84');
            }
            this.otherFlg.set('value', other);
        },

        /**
         * 事象が選択された際の挙動。
         */
        onEnterCategory: function() {
            // 市町選択ダイアログを開く
            var municCd = this.form.get('value');
            this.munic.innerHTML = municCd;
        },

        /**
         * 各種類のボタンが押された時の挙動。
         */
        onEnterFire: function () {
            if (this._fireFlg === '1') {
                this._fireFlg = '';
                domStyle.set(this.checkFire, 'display', 'none');
                domStyle.set(this.fire, 'color', '#000000');
                this.fireFlg.set('value', false);
            } else {
                this._fireFlg = '1';
                domStyle.set(this.checkFire, 'display', '');
                domStyle.set(this.fire, 'color', '#314e84');
                this.fireFlg.set('value', true);
            }
        },
        onEnterLifeline: function () {
            if (this._lifelineFlg === '1') {
                this._lifelineFlg = '';
                domStyle.set(this.checkLifeline, 'display', 'none');
                domStyle.set(this.lifeline, 'color', '#000000');
                this.lifelineFlg.set('value', false);
            } else {
                this._lifelineFlg = '1';
                domStyle.set(this.checkLifeline, 'display', '');
                domStyle.set(this.lifeline, 'color', '#314e84');
                this.lifelineFlg.set('value', true);
            }
        },
        onEnterWeather: function () {
            if (this._weatherFlg === '1') {
                this._weatherFlg = '';
                domStyle.set(this.checkWeather, 'display', 'none');
                domStyle.set(this.weather, 'color', '#000000');
                this.weatherFlg.set('value', false);
            } else {
                this._weatherFlg = '1';
                domStyle.set(this.checkWeather, 'display', '');
                domStyle.set(this.weather, 'color', '#314e84');
                this.weatherFlg.set('value', true);
            }
        },
        onEnterAccident: function () {
            if (this._accidentFlg === '1') {
                this._accidentFlg = '';
                domStyle.set(this.checkAccident, 'display', 'none');
                domStyle.set(this.accident, 'color', '#000000');
                this.accidentFlg.set('value', false);
            } else {
                this._accidentFlg = '1';
                domStyle.set(this.checkAccident, 'display', '');
                domStyle.set(this.accident, 'color', '#314e84');
                this.accidentFlg.set('value', true);
            }
        },
        onEnterRailway: function () {
            if (this._railwayFlg === '1') {
                this._railwayFlg = '';
                domStyle.set(this.checkRailway, 'display', 'none');
                domStyle.set(this.railway, 'color', '#000000');
                this.railwayFlg.set('value', false);
            } else {
                this._railwayFlg = '1';
                domStyle.set(this.checkRailway, 'display', '');
                domStyle.set(this.railway, 'color', '#314e84');
                this.railwayFlg.set('value', true);
            }
        },
        onEnterIncident: function () {
            if (this._incidentFlg === '1') {
                this._incidentFlg = '';
                domStyle.set(this.checkIncident, 'display', 'none');
                domStyle.set(this.incident, 'color', '#000000');
                this.incidentFlg.set('value', false);
            } else {
                this._incidentFlg = '1';
                domStyle.set(this.checkIncident, 'display', '');
                domStyle.set(this.incident, 'color', '#314e84');
                this.incidentFlg.set('value', true);
            }
        },
        onEnterOther: function () {
            if (this._otherFlg === '1') {
                this._otherFlg = '';
                domStyle.set(this.checkOther, 'display', 'none');
                domStyle.set(this.other, 'color', '#000000');
                this.otherFlg.set('value', false);
            } else {
                this._otherFlg = '1';
                domStyle.set(this.checkOther, 'display', '');
                domStyle.set(this.other, 'color', '#314e84');
                this.otherFlg.set('value', true);
            }
        },

        /**
         * 「設定する」ボタンが押された時の挙動。
         */
        onSubmit: function () {
            try {
                // 送信パラメータを設定
                var value = this.form.get('value');
                this.emit('category', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

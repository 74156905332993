/**
 * クロノロジ概況画面用モジュール。
 * @module app/chronology/ChronologyAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/control/Router',
    'dojo/text!./templates/ChronologyAdminPage.html',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/consts/ACL',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/form/AclButton',
    'idis/view/grid/IdisGrid',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/consts/USER_TYPE',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/chronology/ChronologyAdminGrid'
], function(module, declare, lang, domStyle, Router, template, _PageBase,
    IdisRest, ACL, Requester, Loader, AclButton, IdisGrid, UserInfo, DisasterInfo, USER_TYPE, config) {
    /**
     * クロノロジ概況画面
     * @class ChronologyAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        _disasterId:null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        },

        constructor: function() {
            this.store = new IdisRest({
                idProperty: 'organizationCd',
                target: '/api/chronologies/overview',
                sortParam: 'sort',
                rangeStartParam: 'offset'
            });
        },

        /**
         * グリッドを初期化する。
         */
        startup: function() {
            this.inherited(arguments);

            // 災害IDを取得する。
            this._disasterId = DisasterInfo.getDisasterId();
            this._municipalityCd = (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) ?
                            UserInfo.getMunicipalityCds()[0] : config.municInfo.prefMunicCd;

            // グリッドを初期表示
            this.initGrid();

            this.setSummaryNum();
        },

        /**
         * グリッドの初期化処理。
         */
        initGrid: function() {
            this.grid.on('listButtonClick', lang.hitch(this, function(evt) {
                this.onListButtonClick(evt.item);
            }));
            var filter = new this.store.Filter();
            filter = filter.eq('disasterId', this._disasterId);
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        setSummaryNum: function(){
            var promise = Requester.get('/api/chronologies/summary?disasterId=' + this._disasterId +
                '&municipalityCd=' + this._municipalityCd , {
                preventCache: false
            }).then(lang.hitch(this,function(obj) {
                this.onDemandNum.innerHTML = obj.onDemandNum;
                this.notYetNum.innerHTML = obj.notYetNum;
                this.onGoingNum.innerHTML = obj.onGoingNum;
                this.completedNum.innerHTML = obj.completedNum;
            }), lang.hitch(this, function(err){
                console.error(err);
            }));

            Loader.wait(promise);
        },

        /**
         * 避難情報概況を取得する。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
        * グリッドの検索条件を指定された値で更新する。
        * @param {Object} value name属性と値のマッピング
        */
        updateGridQuery: function(value){
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害IDはコンストラクタで存在チェックをしているため常にfilterにセット
            filter = filter.eq('disasterId', this._disasterId);

            if (value.evacOrderTimestamp) {
                filter = filter.eq('evacOrderTimestamp', value.evacOrderTimestamp.getTime());
            }

            if (value.displayAllFlg && value.displayAllFlg.length !== 0) {
                filter = filter.eq('displayAllFlg', true);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 一覧ボタンクリック時の処理。
         */
        onListButtonClick: function() {
            // 避難状況・報告管理画面へ遷移
            Router.moveTo('chronology');
        }
    });
});

/**
 * お知らせ登録ダイアログ内用。
 * @module app/provide/ProvideTemplatePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ProvideTemplatePage.html',
    'idis/view/dialog/DialogChain',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    './ProvideTemplateGrid',
    './ProvideTempRegisterDialog',
    './ProvideTempDetailDialog'
], function(module, declare, lang, template, DialogChain, IdisRest, Loader, Router, _PageBase) {
    /**
     * テンプレート管理画面。
     * @class ProvideTemplatePage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {
        // テンプレート文字列
        templateString: template,
        
        store: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--provide',

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
            idProperty: 'chnlTmplId',
            target: '/api/informations/temp'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },
        
        /**
         * 検索ボタンが押されたときに呼び出される。 テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * テンプレート一覧グリッドを初期化する。
         */
        initTempGrid: function(){
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('tmpDetailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },
        
        /**
         * 詳細ダイアログを表示する。
         */
        showDetailDialog: function(item){
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // ダイアログを表示
            this.detailDialog.show();
            
            page.form.set('value',item);
        },

        /**
         * 新規登録ダイアログを表示する。
         */
        showRegTempDialog: function(){
            var page = this.regTempDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // ダイアログを表示
            this.regTempDialog.show();
        },
        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.regTempDialog;
            var page = dialog.getChildren()[0];
            
            // 県職員向け登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログのフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            
            //対象のテンプレートを更新する
            page.on('update', lang.hitch(this, function(evt) {
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.put(evt.value)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
            
            // 対象のテンプレートを削除する
            page.on('delete', lang.hitch(this, function(evt) {
                // 追加確認ダイアログを表示
                this.chain.confirmDel(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.remove(evt.value.chnlTmplId)).then(function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },
        
        onProvideInfomationLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('provideinformation');
        },
        
        buildRendering: function() {
            this.inherited(arguments);
            //グリッドの初期化
            this.initTempGrid();
            this.initRegisterPage();
            this.initDetailPage();
        }
    });
});


/**
* 体制状況・報告概況画面用モジュール。
* @module app/employgathering/EmployGatheringStatusPrefListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/EmployGatheringStatusPrefListPage.html',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/control/Router',
    'idis/store/IdisRest',
    './_EmployGatheringStatusPageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/disaster/model/DisasterType',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/employgathering/EmployGatheringFormDialog',
    './EmployGatheringStatusListPrefGrid'
], function(module, declare, lang, json, template, Memory, Trackable,
    Router, IdisRest, _PageBase, DialogChain, Loader, DisasterInfo, Locator, UserInfo, USER_TYPE, DisasterType) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/employgathering/EmployGatheringStatusPrefListPage~EmployGatheringStatusPrefListPage # */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--employgathering',

        /**
        * 職員参集管理パラメータ格納用オブジェクト
        * @type {Object}
        */
        DisasterPrevInfItems : null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        summaryStore: null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        constructor: function() {
            // 体制情報用データ格納用オブジェクト
            this.summaryStore = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention/pref',
                rangeStartParam: 'offset'
            });
            // データ格納用オブジェクト
            this.tempStore = new IdisRest({
                idProperty: 'empGatherId',
                target: '/api/employGathering/pref'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'empGatherId',
                target: '/api/employGathering'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this.disasterId = DisasterInfo.getDisasterId();
            if(!this.disasterId) {
                this.disasterId = 1;
            }
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.DisasterPrevInfItems = Locator.getQuery();
            this._municipalityCd = this.DisasterPrevInfItems.areaCd;
            this.initDisasterPreventionStatusInf();
        },

        /**
         * 体制情報と本部設置情報をセット
         */
        initDisasterPreventionStatusInf: function(){
            var filter = new this.summaryStore.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this.disasterId);
            // 市町村CDのみで最新報を取得
            filter = filter.eq('areaCd', this._municipalityCd);
            // 最新報のみ取得
            filter = filter.eq('pastReportMode', false);
            // filterに対応するcollectionを取得
            var collection = this.summaryStore.filter(filter);
            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    // 取得したデータを共通情報部分に設定する
                    this._disasterPreventionId = data[0].disasterPrevId;
                    this.reportNum.innerHTML = '第' + data[0].seqNum + '報';
                    this.areaName.innerHTML = data[0].municipalityName ?
                                    data[0].municipalityName : data[0].regionName;
                    this.reportTimestamp.innerHTML = data[0].reportTimestamp.replace(/-/g, '/');
                    this.disasterType.innerHTML = DisasterType.get(data[0].disasterType).label;
                    this.statusName.innerHTML = data[0].statusName;
                    this.comment.innerHTML = data[0].comment === null ? '' : data[0].comment;
                    this.hqName.innerHTML = data[0].hqName === null ? '' : data[0].hqName;
                    if(data[0].hqSetTimestamp !== null){
                        this.hqSetTimestamp.innerHTML = data[0].hqSetTimestamp.replace(/-/g, '/');
                    }else{
                        this.hqSetTimestamp.innerHTML = '-';
                    }
                    if(data[0].hqAbolishedTimestamp !== null){
                        this.hqAbolishedTimestamp.innerHTML = data[0].hqAbolishedTimestamp.replace(/-/g, '/');
                    }else{
                        this.hqAbolishedTimestamp.innerHTML = '-';
                    }
                    this.hqSetPlace.innerHTML = data[0].hqSetPlace === null ? '' : data[0].hqSetPlace;
                }else{
                    // データがない場合一覧画面に遷移する
                    Router.moveTo('disasterprevention/status/pref', {areaCd : this._municipalityCd});
                }
                this.initGrid(); // 同期化のため当関数で実行する
            }));
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 編集グリッドの初期化
            this.store = Trackable.create(new Memory({
                idProperty: 'empGatherId',
                data: {
                    items: []
                }
            }));
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            var filter = new this.tempStore.Filter();
            filter = filter.eq('disasterId', this.disasterId);
            filter = filter.eq('disasterPrevId', this._disasterPreventionId);
            filter = filter.eq('areaCd', this.DisasterPrevInfItems.areaCd);
            this.tempStore.filter(filter)
                // APIで取得したデータをTrackableStoreに代入する
                .forEach(lang.hitch(this, function(item){
                    this.store.put(item);
                // collectionをグリッドにセットする
                })).then(lang.hitch(this, function(){
                    this.grid.set('collection', this.store.track());
                }));
        },

        /**
         * グリッドから登録
         */
        //
        onSubmit: function() {
            var recItems = [];
            this.grid.collection.forEach(lang.hitch(this, function(item) {
                // 新規登録の場合は、IDをnullとする
                var empGatherId = parseInt(item.empGatherId,10)!==0 ? item.empGatherId : null;
                // IDを持つ、又は入力ありの場合、更新対象
                if(empGatherId!==null || (item.gatherDisaster!==0 || item.gatherFlood!==0 ||
                    item.reportTimestampMS!==null || item.comment!==null)){
                        recItems.push({
                            empGatherId: empGatherId,
                            empCategory: '01',      // 職員カテゴリー　01:道職員
                            gatherDisaster : item.gatherDisaster,
                            gatherFlood: item.gatherFlood,
                            reportTimestamp: item.reportTimestampMS,
                            deptCd: item.deptCd,
                            sectCd: item.sectCd,
                            unitCd: item.unitCd,
                            comment: item.comment,
                            version : item.version,
                            orgName : item.orgName
                        });
                }
            }));

            // 固有のバリデーションを実施
            if(!this.validate(recItems)) {
                return false;
            }

            // 以下、バリデーションチェック用要素削除
            recItems.forEach(function(item){
                delete item.orgName;
            });

            // 登録
            this.chain.confirmPut(function(chain) {
                var sendData = {
                    disasterId : this.disasterId,
                    disasterPrevId : this._disasterPreventionId,
                    municipalityCd : this.DisasterPrevInfItems.areaCd,
                    empGatheringList : recItems
                };

                Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                    this.initGrid();
                    chain.infoComplete(lang.hitch(this, function() {
                        // this.distributionType = '02';
                        chain.hide();
                    }));
                }), function(err) {
                    // 失敗時
                    if(err.response.data && typeof(err.response.data) === 'string'){
                        err.response.data = json.parse(err.response.data);
                    }
                    chain.infoError(err);
                });
            });
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionPrefListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention/pref');
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionStatusPagePrefLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention/status/pref', {areaCd : this.DisasterPrevInfItems.areaCd});
        }
    });
});

/**
 * 「河川・沿岸カメラ実況情報」画面用のモジュール
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/RiverCameraObservationPage.html',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/store/JsonFileMemory',
    'app/config',
    'idis/util/DateUtils',
    // 以下、変数で受けないモジュール,
    'app/view/form/CustomizableMunicipalitySelector',
    './grid/RiverCameraObservationPageGrid',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function (module, declare, lang, template, USER_TYPE, UserInfo, _PageBase, Router,
    JsonFileMemory, config, DateUtils) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--rivercameraobs idis-Page--obsList',

        store: null,

        // 最新一時は水位最新日時JSONから取得
        LATEST_DATETIME_URL: '/data/river/data.json',

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function () {
            this.inherited(arguments);
            // データを取得する
            this.store = new JsonFileMemory({
                idProperty: 'cameraId',
                target: '/data/camera/cctv.geojson'
            });
            // 市町村セレクタ初期化
            this.initMunicipalitySelector();
            // グリッドを初期化する
            this.initGrid();
        },

        initGrid: function () {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
                this.onDetailButtonClick(evt.item);
            }));
            this.updateGrid();
        },

        initMunicipalitySelector: function () {
            // 未選択時、全域表示ラベルセット
            this.municipalitySelector.set('emptyValueLabel', config.municInfo.prefName + '内全域');
            // 市町村ユーザーの場合、ユーザー所属の市町村コードをセット&未選択ラベル反映
            this.municipalitySelector._setValueAttr(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY ?
                UserInfo.getMunicipalityCd() : '');
            // セレクタ変更イベント
            this.municipalitySelector.on('change', lang.hitch(this, this.updateGrid));
        },

        /**
         * 「詳細」ボタンクリック時に呼ばれる
         */
        onDetailButtonClick: function (object) {
            // 現在日時のX0分を取得
            var date = new Date();
            date.setMinutes(Math.floor(date.getMinutes() / 10) * 10);
            // yyyy-MM-dd-HH-mm形式に変換
            var target = String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' +
                String(date.getDate()) + '-' + String(date.getHours()) + '-' +
                String(date.getMinutes());
            var dateFormat = DateUtils.getArroundDate(target, true, 1000, 0, target)[0].pathFormatDate;
            // 河川カメラ詳細画面へ遷移
            Router.moveTo('observation/rivercamera/detail', {
                id: object.id,
                date: dateFormat
            });
        },

        /**
         * グリッドを更新する
         */
        updateGrid: function () {
            var filter = new this.store.Filter();
            // 市町村コード
            var municipalityCd = this.municipalitySelector.get('value');
            if (municipalityCd) {
                // 政令指定都市対応
                if (municipalityCd === config.municInfo.cityMunicCd) {
                    // inはJSの予約語のため使用不可
                    filter = filter['in']('municipalityCd',
                        config.municInfo.cityDistrictMunicCds.concat([municipalityCd]));
                } else {
                    filter = filter.eq('municipalityCd', municipalityCd);
                }
            }
            this.grid.set('collection', this.store.filter(filter));
        }
    });
});

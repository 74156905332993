/**
 * ユーザーに紐付く市町村選択用入力パーツ避難情報画面では変更後にtopicを発行する。
 * @module app/view/form/CustomizableMunicipalitySelector
 */
 define([
    'module',
    'dojo/topic',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/MunicipalityListSelector',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function(module, topic, declare, lang, when, USER_TYPE, UserInfo, CacheStoreModel, CacheTreeRest, MunicipalityListSelector,
    InfoDialog, config) {
    /**
     * 市町選択用パーツ
     * @class CustomizableMunicipalitySelector
     * @extends module:app/view/form/MunicipalityListSelector~MunicipalityListSelector
     */
    return declare(module.id.replace(/\//g, '.'), MunicipalityListSelector,
        /** @lends module:app/view/form/CustomizableMunicipalitySelector~CustomizableMunicipalitySelector */ {

        // 選択ダイアログのタイトル
        title: '市町',

        // ツリー・モデル
        model: null,

        // true: ログインユーザーに応じて、市町村コードを初期セットする
        defaultSelected: false,

        startup: function() {
            this.inherited(arguments);
            this.dialog.hideOnOK = false;
            // 所属自治体を初期値とする
            this.setDefault();
        },

        postMixInProperties : function() {
            // デフォルトは県・振興局・市町のうち、管理対象のものを表示
            var prefFlg = this.prefFlg || '1';
            var regionFlg = this.regionFlg || '1';
            var municFlg = this.municFlg || '1';
            var manageFlg = this.manageFlg || '1';
            // manageFlgが立っている場合、管理対象の上位組織を表示するためのフラグ(manageFlgが0ならば無効)
            var manageParentFlg = this.manageParentFlg || '0';
            // municFlgが立っている場合に、行政区の表示・非表示を制御するためのフラグ(municFlgが0ならば無効)
            var wardMunicFlg = this.wardMunicFlg || '1';
            var option = 'prefFlg=' + prefFlg + '&regionFlg=' + regionFlg;
            option += '&municFlg=' + municFlg + '&manageFlg=' + manageFlg;
            option += '&manageParentFlg=' + manageParentFlg;
            option += '&wardMunicFlg=' + wardMunicFlg;
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/customizableMunicipality4Tree?' + option
                })
            });
        },

        /**
         * 選択した値の設定
         */
         // 継承元との差分は以下。
         // (1) prefUnselectable/regionUnselectableが指定された場合、県/振興局は選択不可。
         // (2) 親の名前を表示させない。ex. [長崎県/長崎市/長崎市尾上町] → [長崎市尾上町]
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.prefUnselectable){
                    //県選択不可
                    if(value === config.municInfo.prefMunicCd){
                        InfoDialog.show('エラー', '県は選択することができません。');
                        return false;
                    }
                }
                if(this.regionUnselectable){
                    //振興局選択不可
                    if(value !== config.municInfo.prefMunicCd &&
                        value.substring(0,3) === config.municInfo.prefRegCdGudge){
                        InfoDialog.show('エラー', '振興局は選択することができません。');
                        return false;
                    }
                }
                if(this.cityUnselectable){
                    //政令指定都市選択不可
                    if(value === config.municInfo.cityMunicCd){
                        InfoDialog.show('エラー', '政令指定都市全体を指定することはできません。<br>区を選択してください。');
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function(name) {
                        // 親の情報は不要なので省略する。
                        var lastIndex = name.lastIndexOf('/');
                        if(lastIndex !== -1){
                            var excludeParentName = name.substring(lastIndex + 1);
                            name = excludeParentName;
                        }
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
        },
        
        /**
         * ツリーから市町が選択されてonOKをクリックした場合、変更されていれば変更後の市町コードを渡すトピックを発行する。
         */
        applySelectedItem : function() {
            // 要素が選択されていない場合は何もしない
            var item = this.tree.selectedItem;
            if (item) {
                var value = this.tree.model.store.getIdentity(item);
                // 市町が変更されていた場合、トピックを発行する。
                if (this.value !== value) {
                    topic.publish(module.id + '::selected', value);
                }
                // 要素が選択されている場合、選択されている要素をウィジェットのvalueとして設定
                this.set('value', value);
            }
        },

        /**
         * ツリーから市町を選択する（値をセットするだけでなく変更イベントも発生させる）
         * @param {*} value 市町村コード
         */
        selectedItem: function(value) {
            this.tree.model.store.get(value).then(lang.hitch(this, function(item){
                this.tree.selectedItem = item;
                this.applySelectedItem();
            }));
        },

        /**
         * 県コードが選択されている場合、trueを返す
         */
        isPrefectureSelected: function() {
            return this.value === config.municInfo.prefMunicCd;
        },

        /**
         * 地域コードが選択されている場合、trueを返す
         */
        isRegionSelected: function() {
            return this.value !== config.municInfo.prefMunicCd &&
                this.value.substring(0,3) === config.municInfo.prefRegCdGudge;
        },

        /**
         * 初期セットするコードを返す
         * @returns 市町コード
         */
        setDefault: function() {
            if (!this.defaultSelected) { return; }
            switch (UserInfo.getUserType()) {
                case USER_TYPE.PREFECTURE:
                case  USER_TYPE.OTHER_ORGAN:
                    this.set('value', (this.prefFlg === '0' || this.prefUnselectable) ?
                        config.municInfo.defaultMunicCd : config.municInfo.prefCd);
                    break;
                case USER_TYPE.REGION:
                    this.set('value', (this.regionFlg === '0' || this.regionUnselectable) ?
                        UserInfo.getMunicipalityCds()[0] : UserInfo.getRegionCd());
                    break;
                case USER_TYPE.MUNICIPALITY:
                    if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                        this.set('noAllButton', true);
                    }
                    this.set('value',
                        this.cityUnselectable && UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd ?
                        UserInfo.getMunicipalityCds()[1] : UserInfo.getMunicipalityCd());
                break;
            }
            return this.get('value');
        },
        
        reset: function(){
            this.inherited(arguments);
            topic.publish(module.id + '::selected');
        }

    });
});

/**
 * 防災情報リンク集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/promise/all',
    'dojo/dom-style',
    'dojo/text!./templates/EvacRecommendPage.html',
    'dojo/topic',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/store/JsonFileMemory',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/evacrecommend/DistrictLayerFactory4EvacRecommend',
    'app/model/DisasterInfo',
    '../config',
    'idis/consts/USER_TYPE',
    './consts/EVAC_RECOMMEND_STATUS',
    // 以下、変数で受けないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/layout/TabContainer',
    'idis/view/form/WordCountTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'app/map/LayerSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    './EvacRecommendGrid',
    './UnnecessaryStatusRegisterDialog'
], function (module, array, declare, lang, domClass, all, domStyle, template, topic, Memory, Trackable, Locator,
    Router, DialogChain, _PageBase, Requester, IdisRest, JsonFileMemory, IdisMap, UserInfo, DistrictLayerFactory,
    DisasterInfo, config, USER_TYPE, STATUS) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var evacRecommendFilterStore = {};

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--evacrecommend',

        INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },

        _layerControl: null,

        _layerControl4adminMap: null,

        _municipalityCd: null,

        _isEvacOrderPresent: false,

        _selectedDistCdList: [],

        _selectedDistNameList: [],

        _selectedDataMap: {},

        _districtTreeCache: null,

        _currentEvacOrderType: null,

        _currentIssueReasonType: null,

        _selectedMunicipalityCd: null,

        _latestLayerState: null,

        _latestAllDistrictLayerState: null,

        _isShowingAllDist: false,

        // デフォルトのズームサイズ
        _zoomSize: 10,

        LATEST_STATE_LAYER_NO: null,

        LATEST_LAYER_STATE: {
            ON: '1',
            OFF: '0'
        },

        ALLDISTRICT_LAYER_STATE: {
            ON: '1',
            OFF: '0'
        },

        // DOM要素を構築する
        buildRendering: function () {
            this.inherited(arguments);
        },

        constructor: function () {
            this.chain = DialogChain.get(this);
            // データ格納用オブジェクト
            this._disasterId = DisasterInfo.getDisasterId();
            this.store = Trackable.create(new Memory({
                idProperty: 'evacRecommendId',
                target: '/data/evacorder/recommend/' + this._disasterId + '/evacRecommend.json'
            }));
        },

        startup: function () {
            this.inherited(arguments);

            // Cookieから取得した災害IDを取得する。
            this._disasterId = DisasterInfo.getDisasterId();

            this._municipalityCd = (UserInfo.getUserType() === USER_TYPE.PREFECTURE) ? config.municInfo.prefCd :
                (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) ? UserInfo.getMunicipalityCd() : null;
            this._regionCd = (UserInfo.getUserType() === USER_TYPE.REGION) ? UserInfo.getRegionCd() : null;

            if (UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION) {
                domStyle.set(this.mapCntlBtn, 'display', 'none');
                this._isShowingAllDist = true;
                // 県全体を表示する場合は、ズームレベルを下げる。
                this._zoomSize = 9;
            }

            if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                this.municipalitySelector.set('noAllButton', true);
            }

            if (this._municipalityCd) {
                this.municipalitySelector.set('value', this._municipalityCd);
            } else if (this._regionCd) {
                this.municipalitySelector.set('value', this._regionCd);
            }

            // 県だったら、クエリにはnullを仕込む
            var childMunicList = (this._municipalityCd === config.municInfo.prefCd) ?
                null : UserInfo.getMunicipalityCds();

            Requester.get('/data/evacorder/recommend/' + this._disasterId + '/evacRecommend.json', {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(lang.hitch(this, function (data) {
                // JSONの中身で画面・グリッドを生成する
                this.initPage(childMunicList, data.items);
            }), lang.hitch(this, function (error) {
                console.log(error);
                if (error.response.status === 404) { //まだevacRecommend.jsonが未生成だった場合
                    // グリッドは空で画面を生成する
                    this.initPage(childMunicList, []);
                }
            }));
        },

        initPage: function (childMunicList, gridData) {
            this.initGrid(childMunicList, gridData);
            this.initMap();
            this.initLatlng();
            this.toggleQuery(false);
            this.toggleDistrict(false);
            this.toggleColumnStyle();
            this.initDistrictLayer().then(lang.hitch(this, function () {
                this._latestLayerState = this.LATEST_LAYER_STATE.OFF;
            }));

            // 初期状態では選択されているレコードがないので、発令ボタンを押せないようにする
            this.submitButton.set('disabled', true);

            this.borderContainer.resize();
        },

        onSubmit: function () {
            try {
                var childMunicList = null;
                var selectedCd = this.municipalitySelector.get('value');

                if (!selectedCd || selectedCd === config.municInfo.prefCd) {
                    // 「未選択」あるいは「県」が選ばれた場合
                    domStyle.set(this.mapCntlBtn, 'display', 'none');
                    this._municipalityCd = config.municInfo.prefCd;
                    this._zoomSize = 9;

                } else {
                    this.municipalitySelector.set('value', selectedCd);

                    childMunicList = this.getChildMunicCd(selectedCd);
                    if (childMunicList.length === 0) {
                        // 一つの市町が選ばれた場合
                        // 現況情報を表示できるようにする
                        domStyle.set(this.mapCntlBtn, 'display', '');
                        this._regionCd = null;
                        this._municipalityCd = selectedCd;
                        this._zoomSize = 10;
                        childMunicList.push(this._municipalityCd);
                    } else {
                        // 振興局が選ばれた場合
                        // 現況情報を表示できないようにする
                        domStyle.set(this.mapCntlBtn, 'display', 'none');
                        this._regionCd = selectedCd;

                        this._municipalityCd = null;
                        this._zoomSize = 9;
                    }
                }

                // 地図の初期位置を設定する。
                this.initLatlng();
                this.reCreateLayers();

                this.toggleColumnStyle();
                this.updateGridQuery(childMunicList);
            } catch (e) {
                console.error(e);
            } finally {
                // onSubmitのデフォルトのイベントをキャンセルする。
                return false;
            }
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function (municLists, items) {

            // 読み取ったJSONの中身をストアに保存する
            array.forEach(items, function (item) {
                this.store.add(item);
            }, this);

            // グリッドの選択時の動作を設定する
            this.grid.on('dgrid-select', lang.hitch(this, function (evt) {
                var row = evt.rows[0];
                // 現在選択中のレコードと、新しく選択したレコードが一致するか判定する
                if (this.isValidSelection(row.data)) {
                    // 現在選択中のレコードと、新しく選択したレコードが一致する場合＋初回選択時
                    // 選択中のレコードと、条件(避難区分・発令理由)の異なるレコードを、選択不能にする
                    this.makeRecordsUnselectable();
                    // 選択されたレコメンドのデータを保管する
                    this.addEvacRecommendData(row);
                    // レコメンド対象地域のデータを保管し、文字列表示ペイン・地図に反映
                    this.updateEvacArea(row.data.evacOrderType);
                    // 選択された市町村にズームイン
                    this._zoomSize = 11;
                    this.initLatlng(row.data.municipalityCd);
                    // 発令ボタンをクリック可能にする
                    this.submitButton.set('disabled', false);
                } else {
                    // 現在選択中のレコードと、新しく選択したレコードが一致しない場合
                    // 内部的な選択処理をリバースする
                    this.reverseInvalidSelection(row);
                }
            }));

            // グリッドの選択解除時の動作を設定する
            this.grid.on('dgrid-deselect', lang.hitch(this, function (evt) {
                // 選択中のレコメンドのデータを更新する
                this.removeEvacRecommendData(evt);
                // レコメンド対象地域のデータを更新し、文字列表示ペイン・地図に反映
                this.updateEvacArea(evt.rows[0].data.evacOrderType);
                if (Object.keys(this.grid.selection).length === 0) {
                    // 選択中のレコードがなくなった場合、グリッドの全てのレコードの状態を元に戻す
                    this._currentEvacOrderType = null;
                    this._currentIssueReasonType = null;
                    this._selectedMunicipalityCd = null;
                    this.makeAllRecordsSelectable();
                    // デフォルト位置に戻る
                    this._zoomSize = 9;
                    this.initLatlng(config.municInfo.prefCd);
                    // 発令ボタンを無効化する
                    this.submitButton.set('disabled', true);
                }
            }));

            // 各セル押下時の動作を設定する
            this.grid.on('dgrid-cellfocusin', lang.hitch(this, function(evt) {
                var row = evt.cell.row;
                // 発令不要登録列を選択かつ有効情報の場合、発令不要登録ダイアログを表示
                if (!(evt.cell.column.field === 'unnecessaryReason' && row.data.status === STATUS.ACTIVE)) {
                    return;
                }
                // 選択の場合、選択解除する
                if (this.grid.isSelected(row)) {
                    this.grid.deselect(row);
                }
                this.unnecessaryStatusRegisterDialog.getChildren()[0].initDialog(row.data);
                this.unnecessaryStatusRegisterDialog.show();
            }));

            this.updateGridQuery(municLists);
        },

        // regionCdであれば、子供の地区コードリストを返す。municipalityCdであれば市町村コードを返す。
        getChildMunicCd: function (cd) {
            var childList = this.municipalitySelector.model.childrenCache[cd];
            if (!childList) {
                return [];
            }
            var childCdList = [];
            array.forEach(childList, function (municObj) {
                childCdList.push(municObj.id);
            });
            return childCdList;
        },

        /**
         * クリックされたレコードが選択可能かどうか判定する
         * 発令済レコード・撤回レコード・自動判定対象外レコードはfalse
         * 現在選択中のレコードと条件が一致する場合と、現在選択中のレコードがない場合に、trueを返す
         */
        isValidSelection: function (item) {
            if ([STATUS.ISSUED, STATUS.SETTLED, STATUS.MANUAL].indexOf(item.status) > -1) {
                return false;
            }
            if (!this._currentEvacOrderType && !this._currentIssueReasonType && !this._selectedMunicipalityCd) {
                // 現在選択中のレコードがなければ、クリックされたレコードの条件を保存
                this._currentEvacOrderType = item.evacOrderType;
                this._currentIssueReasonType = item.issueReasonType;
                this._selectedMunicipalityCd = item.municipalityCd;
                return true;
            } else if (item.evacOrderType === this._currentEvacOrderType &&
                item.issueReasonType === this._currentIssueReasonType &&
                item.municipalityCd === this._selectedMunicipalityCd) {
                return true;
            }
            return false;
        },

        /**
         * 無効なレコードが選択された場合に、選択処理を取り消す
         */
        reverseInvalidSelection: function (row) {
            var evacRecommendId = row.data.evacRecommendId;
            // 選択解除する
            delete this.grid.selection[evacRecommendId];
            // 選択中クラスが付与されてしまうので、強制的にリムーブする
            domClass.remove(row.element, 'dgrid-selected');

            var message = '';
            switch (row.data.status) {
                case STATUS.ISSUED:
                    message += '発令済の情報は、発令することができません';
                    break;
                case STATUS.SETTLED:
                    message += '撤回した情報は、発令することができません';
                    break;
                case STATUS.MANUAL:
                    message += '自動判定対象外の情報は、発令することができません';
                    break;
                default:
                    message += '同時に発令できるのは、<br>市町・発令理由・避難区分が同一の情報のみです';
                    break;
            }

            // ユーザに選択できない旨を表示
            this.chain.info(message, '', function () {
                this.chain.hide();
            });
        },

        /**
         * グリッドで選択された避難レコメンドを元に、避難情報を発令する
         */
        createEvacOrder: function () {
            var evacRecommendIds = this.grid.getSelectedIdList().join(',');
            Router.moveTo('evacorder/registerFromRecommend', {
                evacRecommendIds: evacRecommendIds
            });
        },

        /**
         * 避難レコメンドの条件が一致しないレコードを、選択不能にする
         */
        makeRecordsUnselectable: function () {
            array.forEach(this.grid._rows, function (row) {
                if (!this.isValidSelection(row.evacRecommendType)) {
                    domClass.add(row, 'row-invalid');
                    var inputtags = row.getElementsByTagName('input');
                    for (var j = 0; j < inputtags.length; j++) {
                        inputtags[j].disabled = true;
                    }
                }
                return row;
            }, this);
        },

        /**
         * グリッド上の全てのレコードを選択可能の状態にする
         */
        makeAllRecordsSelectable: function () {
            array.forEach(this.grid._rows, function (row) {

                domClass.remove(row, 'row-invalid');
                var inputtags = row.getElementsByTagName('input');
                for (var j = 0; j < inputtags.length; j++) {
                    inputtags[j].disabled = false;
                }
                return row;
            }, this);
        },

        /**
         * 選択されたレコードを、選択中レコードマップに保存する
         */
        addEvacRecommendData: function (row) {
            var item = row.data;
            // グリッドで選択されたデータを退避・保管
            this._selectedDataMap[item.evacRecommendId] = item;
        },

        /**
         * 選択解除されたレコードを、選択中レコードマップから削除する
         */
        removeEvacRecommendData: function (evt) {
            var item = evt.rows[0].data;
            // グリッドで選択解除されたデータを、保管先から削除
            delete this._selectedDataMap[item.evacRecommendId];
        },

        /**
         * 現在選択中のレコードの発令対象地区を、漏れ・被りがないようマージし、地区一覧表示ペインに表示＋地図上に反映
         */
        updateEvacArea: function (evacOrderType) {

            var lastDistCdList = this._selectedDistCdList;

            this._selectedDistCdList = [];
            this._selectedDistNameList = [];

            // グリッド上で現在選択中のID(evacRecommendId)のリストを取得
            var selectedIdList = this.grid.getSelectedIdList();

            // グリッドで現在選択中の全データの、対象地区コード一覧と対象地区名一覧をリストにマージする
            for (var i = 0; i < selectedIdList.length; i++) {
                var item = this._selectedDataMap[selectedIdList[i]];

                // 地区コード
                array.forEach(item.districtList, function (district) {
                    if (this._selectedDistCdList.indexOf(district) === -1) {
                        this._selectedDistCdList.push(district);
                    }
                }, this);

                // 地区名
                array.forEach(item.districtNameList, function (districtName) {
                    if (this._selectedDistNameList.indexOf(districtName) === -1) {
                        this._selectedDistNameList.push(districtName);
                    }
                }, this);
            }

            // 地区名を画面右側の対象地区一覧表示ペインに表示する
            this._selectedDistNameList.sort();
            var distNamesStr = this._selectedDistNameList.join('、');
            this.district.innerHTML = distNamesStr;

            // 選択解除により、対象から外れた地区のリストを作成
            var deselectedDistList = [];
            array.forEach(lastDistCdList, function (distCd) {
                if (this._selectedDistCdList.indexOf(distCd) === -1) {
                    deselectedDistList.push(distCd);
                }
            }, this);

            // 避難区分に応じて、対象地区の色を決定
            var color = this.getColor4EvacOrderType(evacOrderType);
            // 対象地区を地図上に色付けて表示
            array.forEach(this._selectedDistCdList, function (districtCd) {
                this.selectDistrictLayerFromGridCheck(districtCd, true, color, this._layerControl);
            }, this);

            // 対象外になった地図の色を戻す
            array.forEach(deselectedDistList, function (districtCd) {
                this.selectDistrictLayerFromGridCheck(districtCd, false, null, this._layerControl);
            }, this);
        },

        getColor4EvacOrderType: function (evacOrderType) {
            var color = '';
            switch (evacOrderType) {
                case '11':
                    color = '_prepareColor';
                    break;
                case '13':
                    color = '_orderColor';
                    break;
                case '14':
                    color = '_disasterColor';
                    break;
            }
            return color;
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function (municipalityCds) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 市町村
            if (municipalityCds) {
                filter = filter['in']('municipalityCd', municipalityCds);
            }

            // 発令理由
            if (value.issueReasonType) {
                filter = filter.eq('issueReasonType', value.issueReasonType);
            }

            // 避難区分
            if (value.evacOrderType) {
                filter = filter.eq('evacOrderType', value.evacOrderType);
            }

            // 対象日時FROM
            if (value.evacRecommendTimestampFrom) {
                var evacRecommendTimestampFrom = new Date(value.evacRecommendTimestampFrom).getTime();
                filter = filter.gt('updTimestamp', evacRecommendTimestampFrom);
            }

            // 対象日時TO
            if (value.evacRecommendTimestampTo) {
                var evacRecommendTimestampTo = new Date(value.evacRecommendTimestampTo).getTime();
                filter = filter.lt('updTimestamp', evacRecommendTimestampTo);
            }

            // アクティブは常に表示
            var statusQuery = [STATUS.ACTIVE, STATUS.MANUAL];
            // 発令済表示
            if (this.dealtFlg.get('checked')) {
                statusQuery.push(STATUS.ISSUED);
            }
            // 撤回表示
            if (this.settledFlg.get('checked')) {
                statusQuery.push(STATUS.SETTLED);
            }
            // 発令不要表示
            if (this.unnecessaryFlg.get('checked')) {
                statusQuery.push(STATUS.UNNECESSARY);
            }
            filter = filter['in']('status', statusQuery);

            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function () {

            // 発信日時FROM
            if (evacRecommendFilterStore.registerTimestampFrom) {
                this.registerTimestampFrom.set('value', evacRecommendFilterStore.registerTimestampFrom);
            }

            // 発信日時TO
            if (evacRecommendFilterStore.registerTimestampTo) {
                this.registerTimestampTo.set('value', evacRecommendFilterStore.registerTimestampTo);
            }

            // 市町村コード
            if (evacRecommendFilterStore.municipalityCd) {
                this.municipalityCd.set('value', evacRecommendFilterStore.municipalityCd);
            }
        },

        /**
         * 検索条件に合わせて表スタイルを変更
         */
        toggleColumnStyle: function () {
            // 「現況」列
            // 発令済も撤回済も検索対象外の場合、表示しない
            if (!this.dealtFlg.get('checked') && !this.settledFlg.get('checked')) {
                domClass.add(this.grid.domNode, 'is-hide-status');
            } else {
                domClass.remove(this.grid.domNode, 'is-hide-status');
            }
            // 「発令不要登録」列
            // 発令不要情報表示中は幅を拡張
            this.grid.shownUnnecessaryReason = this.unnecessaryFlg.get('checked');
            if (this.grid.shownUnnecessaryReason) {
                domClass.add(this.grid.domNode, 'is-shown-unnecessary-reason');
            } else {
                domClass.remove(this.grid.domNode, 'is-shown-unnecessary-reason');
            }
        },

        /**
         * 地図を初期生成する。
         */
        initMap: function () {
            // 地図を生成する。
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false,
                minZoom: 8,
                drawControlTooltips: false
            }).setView(this.INIT_LATLNG, this._zoomSize);

            // 描画する際に、マップのリサイズを行う
            this.map.invalidateSize();

            // destroy時にmapを破棄するよう設定
            this.own(this.map);

            // 生成したmapのlayerControlを画面にセットする。
            this._layerControl = this.map.layerControl;

        },

        /**
         * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
         */
        initLatlng: function (municipalityCd) {
            // 市町村の緯度経度情報の一覧を取得する。
            Requester.get('/data/municipality/municipalityList.json', { preventCache: false })
                .then(lang.hitch(this, function (obj) {

                    var municipalities = obj.municipalities;
                    var latlng = this.INIT_LATLNG;

                    // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                    municipalities.some(lang.hitch(this, function (item) {
                        if (item.municipalityCd === (municipalityCd || this._municipalityCd)) {
                            latlng = item.latlng;
                            return true; //break
                        }
                    }));
                    this.map.setView(latlng, this._zoomSize);
                }));
        },

        onShowQueryButtonClick: function () {
            this.toggleQuery(true);
        },

        onHideQueryButtonClick: function () {
            this.toggleQuery(false);
        },

        onShowDistrictButtonClick: function () {
            this.toggleDistrict(true);
        },

        onHideDistrictButtonClick: function () {
            this.toggleDistrict(false);
        },

        toggleQuery: function (show) {
            domStyle.set(this.hideQueryLabel, 'display', show ? '' : 'none');
            domStyle.set(this.showQueryLabel, 'display', show ? 'none' : '');
            domStyle.set(this.queryPane.domNode, 'display', show ? '' : 'none');
            this.borderContainer.resize();
            this.subBorderContainer.resize();

            // borderContainerのリサイズが終わってから、mapNodeのリサイズを行う
            setTimeout(lang.hitch(this, function () {
                this.map.invalidateSize();
            }), 400);
        },

        toggleDistrict: function (show) {
            domStyle.set(this.hideDistrictLabel, 'display', show ? '' : 'none');
            domStyle.set(this.showDistrictLabel, 'display', show ? 'none' : '');
            domStyle.set(this.districtArea.domNode, 'display', show ? '' : 'none');
            this.borderContainer.resize();
            this.subBorderContainer.resize();

            // borderContainerのリサイズが終わってから、mapNodeのリサイズを行う
            setTimeout(lang.hitch(this, function () {
                this.map.invalidateSize();
            }), 400);
        },

        /**
         * 地図レイヤーを再作成する。
         * 市町変更時にレイヤーを削除して再作成する。
         */
        reCreateLayers: function () {
            // 地図上のレイヤーを全て削除する。
            var layerControl = this._layerControl;
            Object.keys(layerControl.layers).forEach(lang.hitch(this, function (key) {
                layerControl.removeLayerById(key);

            }));
            // 地区レイヤーを追加する。
            this.initDistrictLayer();
        },

        /**
         * 地図上の地区選択レイヤーを初期化する。
         * @returns {Promise} 完了後に解決するPromise
         */
        // 登録画面では現況レイヤーを表示する。
        initDistrictLayer: function () {
            return all({
                // 選択レイヤーを取得して地図上に表示する。
                select: this.showSelectLayer(),
                // 現況レイヤーを取得して地図上に表示する。
                state: this.showLatestStateLayer().otherwise(function () {
                    // 未登録時は404になるため、失敗も成功扱いにする
                    this._isEvacOrderPresent = false;
                })

            });
        },

        /**
         * 現況レイヤーを取得して地図上に表示する。
         * @returns {Promise} 完了後に解決するPromise
         */
        showLatestStateLayer: function () {
            // 市町村の避難情報「現況」レイヤーのidを取得する。
            return Requester.get('/api/evacorders/layerid', {
                query: {
                    disasterId: this._disasterId,
                    municipalityCd: this._municipalityCd
                }
            }).then(lang.hitch(this, function (id) {
                // 市町村の「現況」レイヤーを取得する。
                this.LATEST_STATE_LAYER_NO = id;
                this._isEvacOrderPresent = true;
                return Requester.get('/data/layer/tree/' + id + '.json');
            })).then(lang.hitch(this, function (layerInfo) {
                // 現在の避難情報発令状況を地図上に表示する。
                var opacity = 0.7;
                return this._layerControl.addGeneralLayer(layerInfo, opacity);
            })).then(lang.hitch(this, function () {
                // 「現況」レイヤーは全面に移動する。
                this._layerControl.toFront(this.LATEST_STATE_LAYER_NO);
                // 初期状態は非表示とする
                var targetLayer = this._layerControl.layers[this.LATEST_STATE_LAYER_NO];
                this._layerControl.removeLayer(targetLayer);
            }));
        },

        /**
         * 避難情報発令地区「選択」レイヤーを地図上に表示する。
         * 選択レイヤーの初期値は、詳細情報のstyle.urlから取得する。
         *「選択」レイヤーはgeojsonからDistrictSelectLayerFactoryによって生成する。
         * @returns {Promise} 追加完了後に解決するPromise
         */
        showSelectLayer: function () {
            var municipalityCd = null;
            if (this._regionCd || this._municipalityCd === config.map.prefCd) {
                municipalityCd = config.municInfo.prefCd;
            } else if (this._municipalityCd) {
                municipalityCd = this._municipalityCd;
            } else {
                municipalityCd = config.municInfo.prefCd;
            }

            return Requester.get('/data/layer/data/evacorder/area/' + municipalityCd + '.geojson')
                .then(lang.hitch(this, function (selectLayer) {
                    // 地区レイヤーファクトリクラスにツリーの地区情報も渡して、geojsonに登録されている地区をfilterする。
                    var layer = DistrictLayerFactory.getGeoJsonLayers(selectLayer);
                    this._layerControl.addLayer(layer, this.SELECT_LAYER_NO);
                    // this._layerControl4adminMap.addLayer(layer, this.SELECT_LAYER_NO);
                    // 現況レイヤーのポップアップを表示させるため、選択レイヤーは背面に持っていく
                    this._layerControl.layers[this.SELECT_LAYER_NO].bringToBack();
                }));
        },

        /**
         *  現況レイヤーの表示をトグルする
         */
        toggleLayer: function () {
            if (!this._isEvacOrderPresent) {
                this.chain.info('現在発令されている避難情報はありません', '', function () {
                    this.chain.hide();
                });
                return;
            }

            var targetLayer = this._layerControl.layers[this.LATEST_STATE_LAYER_NO];
            if (this._latestLayerState === this.LATEST_LAYER_STATE.ON) {
                this._latestLayerState = this.LATEST_LAYER_STATE.OFF;
                this.mapCntlBtn.innerText = '発令済避難情報表示';
                this._layerControl.removeLayer(targetLayer);
            } else {
                this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                this.mapCntlBtn.innerText = '発令済避難情報非表示';
                this._layerControl.addLayer(targetLayer, this.LATEST_STATE_LAYER_NO);
                // this._layerControl.layers[this.LATEST_STATE_LAYER_NO].bringToBack();
                this._layerControl.toFront(this.LATEST_STATE_LAYER_NO);
            }
        },

        toggleAllDistricts: function () {
            if (this._latestAllDistrictLayerState === this.ALLDISTRICT_LAYER_STATE.ON) {
                this.hideAllDistrict();
            } else {
                this.showAllDistrict();
            }
        },

        showAllDistrict: function () {
            this._latestAllDistrictLayerState = this.ALLDISTRICT_LAYER_STATE.ON;
            array.forEach(this.grid._rows, function (row) {
                var data = row.evacRecommendType;
                if (['', STATUS.ACTIVE].indexOf(data.status) > -1) {
                    array.forEach(data.districtCdList, function (districtCd) {
                        this.selectDistrictLayerFromGridCheck(districtCd, true,
                            this.getColor4EvacOrderType(data.evacOrderType), this._layerControl4adminMap);
                    }, this);
                }
            }, this);
        },

        hideAllDistrict: function () {
            this._latestAllDistrictLayerState = this.ALLDISTRICT_LAYER_STATE.OFF;
            // this.showAllDistrictsBtn.innerText = '全対象地区を表示';
            array.forEach(this.grid._rows, function (row) {
                var data = row.evacRecommendType;
                if (['', STATUS.ACTIVE].indexOf(data.status) > -1) {
                    array.forEach(data.districtCdList, function (districtCd) {
                        this.selectDistrictLayerFromGridCheck(
                            districtCd, false, null, this._layerControl4adminMap);
                    }, this);
                }
            }, this);
        },

        /**
         * 地区コードを受け取り、地図上での地区の選択状態をトグルする
         */
        selectDistrictLayerFromGridCheck: function (districdCd, isSelected, color, layerControl) {
            // 対応する避難地区情報をもつレイヤーを選択する
            var layerList = layerControl.layers[this.SELECT_LAYER_NO].getLayers();
            array.some(layerList, function (layer) {
                // 背景地図レイヤーはfeatureを持っていないため事前に確認する
                // D_CDは地区レイヤーに設定する地区ID。geojson、レイヤーのプロパティjson、treejsonに設定されている。
                var layerId = layer.feature && layer.feature.properties.D_CD;
                if (layerId === districdCd) {
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    if (isSelected) {
                        options.selectDistrictLayer(layer, color);
                    } else {
                        options.deselectDistrictLayer(layer);
                    }
                    return true; // break
                }
            });
        },

        /**
         * ルール管理画面に遷移
         */
        onRulePageButtonClick: function (evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('evacrecommend/rule');
        },
    });
});

/**
 * 防災体制・報告の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/disasterprevention/view/_DisasterPreventionStatusDialogPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'exif-js/exif',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'app/disasterprevention/view/model/HqType',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector'
], function(module, declare, lang, dom, domClass, domConstruct, domStyle, on, iframe,
    Menu, MenuItem, popup, TooltipDialog, exif, Requester, UserInfo,
    _PageBase, InfoDialog, Loader, HqType) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * 呼び出し元
         */
        _parent: null,

        _postCreate: function() {
            // 体制状況の変更時、本部設置情報欄の表示有無を切り替える
            this.own(this.hqTypeSelect.on('change', lang.hitch(this, function(hqType) {
                this._setHqNameNode(hqType);
            })));
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            if (!this.form.isValid()) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                return false;
            }
            return true;
        },

        /**
         * 本部種別のtips
         */
        setHqTypeTips: function(id) {
            // 本部種別
            var hqTypeTips = new TooltipDialog({
                style: 'width: 300px;',
                content: '<p>「対策本部」「警戒本部」を選択した情報はLアラートに連携します。</p>'
            });
            this.own(hqTypeTips);
            on(dom.byId(id), 'mouseover', function() {
                popup.open({
                    popup: hqTypeTips,
                    around: dom.byId(id)
                });
            });
            on(dom.byId(id), 'mouseleave', function() {
                popup.close(hqTypeTips);
            });
        },

        /**
         * レベルのtips
         */
        setLevelTips: function(id) {
            // レベル
            var levelTips = new TooltipDialog({
                style: 'width: 300px;',
                content: '<p>下位の体制から順に0番から昇順でレベルをつけてください。</p>'
            });
            this.own(levelTips);
            on(dom.byId(id), 'mouseover', function(){
                popup.open({
                    popup: levelTips,
                    around: dom.byId(id)
                });
            });
            on(dom.byId(id), 'mouseleave', function(){
                popup.close(levelTips);
            });
        },

        /**
         * 本部設置情報入力欄表示切替え、諸々の変数を初期化する
         * 切替え要否の判定は当ファンクション呼び出し元にて実施すること
         * @param {*} hqType 変更後の本部種別コード
         */
        _setHqNameNode: function(hqType) {
            switch(hqType) {
                case HqType.RESPONSE_HQ:
                    // 災害対策本部設置対象の体制の場合
                    this.hqInfo.innerHTML = 'あり(災害対策本部)';
                    domStyle.set(this.hqNameNode, 'display', '');
                    this.hqName.set('disabled', false);
                    break;
                case HqType.ALERT_HQ:
                    // 災害警戒本部設置対象の体制の場合
                    this.hqInfo.innerHTML = 'あり(災害警戒本部)';
                    domStyle.set(this.hqNameNode, 'display', '');
                    this.hqName.set('disabled', false);
                    break;
                case HqType.OTHER_HQ:
                    // その他本部設置対象の場合
                    this.hqInfo.innerHTML = 'あり';
                    domStyle.set(this.hqNameNode, 'display', '');
                    this.hqName.set('disabled', false);
                    break;
                case HqType.NOT_YET:
                case HqType.RELEASE:
                    this.hqInfo.innerHTML = 'なし';
                    domStyle.set(this.hqNameNode, 'display', 'none');
                    this.hqName.set('disabled', true);
                    break;
            }
        }
    });
});

/**
 * 雨量観測局選択用入力パーツ
 * @module app/view/form/RainObservationSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, TreeChecker, CacheStoreModel, CacheTreeRest) {
    /**
     * 雨量観測局選択用パーツ。
     * @class RainObservationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/form/RainObservationSelector~RainObservationSelector# */ {
        // 選択ダイアログのタイトル
        title: '雨量観測局選択',

        constructor: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/observationStation/rainObservation4Tree'
                })
            });
        }
    });
});

/**
 * 招集状況管理画面用モジュール。
 *
 * @module app/convocation/view/mail/NotificationDetailPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/text!./templates/NotificationDetailPage.html',
        'idis/view/page/_PageBase',
        'idis/view/dialog/DialogChain',
        'idis/store/IdisRest',
        'idis/control/Locator',
        'idis/service/Requester',
        'idis/control/Router',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/convocation/view/mail/NotificationDetailGrid',
        'app/convocation/view/mail/NotificationDetailDivisionGrid'
], function(module, declare, lang, locale, domStyle, template,
        _PageBase, DialogChain, IdisRest, Locator, Requester, Router) {
    /**
     * 招集状況管理画面。
     *
     * @class ConvoStatusDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
    /** @lends module:app/convocation/view/mail/NotificationDetailPage# */

        store: null,
        divisionStore: null,

        // グリッド更新したときのDateTime
        // チャートを表示するときに表示するため保持する
        gridUpdDatetime: null,

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        _groupName: null,

        displayStatus: 0,

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // URLから配信IDを取得
            this.distributionHistId = Locator.getQuery().id;
            var url = '/api/convoMails/mails/list/';
            url += this.distributionHistId;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'id',
                target: url
            });
            var divisionUrl = '/api/convoMails/mails/list/division/' + this.distributionHistId;
            this.divisionStore = new IdisRest({
                idProperty: 'id',
                target: divisionUrl
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            this.display = Locator.getQuery().display;
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.initPage();
            this.initConvoStatusDetailGrid();
            this.initConvoDivisionStatusDetailGrid();
            this.displayGrid();
        },

        /**
         * ページの初期化。
         */
        initPage: function() {
            var now = new Date();
            this.aggregateDateTime._date.set('value', now);
            this.aggregateDateTime._time.set('value', now);
            var url = '/api/convoMails/mails/forms/';
            url += this.distributionHistId;
            Requester.get(url).then(lang.hitch(this, function(data) {
                var item = data;

                var date = locale.parse(data.distributeTimeStamp, {
                    datePattern: 'yyyy-MM-dd',
                    timePattern: 'HH:mm:ss'
                });
                this.distributeTimestamp.innerText = locale.format(date, {
                    datePattern: 'yyyy/MM/dd',
                    timePattern: 'HH:mm:ss'
                });
                this.convoTitle.innerText = item.distTitle;
            }), lang.hitch(this, function(error) {
                console.log(error);
                this.chain.info('情報の取得に失敗しました。', 'エラー');
            }));
        },

        /**
         * gridの初期化。
         */
        initConvoStatusDetailGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.convoStatusDetailGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.moveStatusDetailPage(evt.item);
            }));
            this.updateConvoStatusDetailGrid();
        },

        /**
         * gridの初期化。
         */
        initConvoDivisionStatusDetailGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.convoDivisionStatusDetailGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.moveDivisionStatusDetailPage(evt.item);
            }));
            this.updateConvoDivisionStatusDetailGrid();
        },

        /**
         * gridの更新。
         */
        updateConvoStatusDetailGrid: function() {
            // 招集状況をgridにセット
            var filter = new this.store.Filter();
            var datetime = new Date(this.form.get('value').aggregateDateTime);
            datetime.setSeconds(59);
            this.gridUpdDatetime = datetime;
            filter = filter.eq('timestamp', datetime.getTime());
            var collection = this.store.filter(filter);
            this.convoStatusDetailGrid.set('collection', collection);
        },

        /**
         * gridの更新。
         */
        updateConvoDivisionStatusDetailGrid: function() {
            // 招集状況をgridにセット
            var filter = new this.divisionStore.Filter();
            var datetime = new Date(this.form.get('value').aggregateDateTime);
            datetime.setSeconds(59);
            this.gridUpdDatetime = datetime;
            filter = filter.eq('timestamp', datetime.getTime());
            var collection = this.divisionStore.filter(filter);
            this.convoDivisionStatusDetailGrid.set('collection', collection);
        },

        /**
         * 職員招集状況詳細への遷移を行うメソッド
         */
        moveStatusDetailPage: function(item){
            // Gridの情報(item)が引数となっている
            var groupCd = item.id;
            // メールのIDと職員のIDをURLに追加し、管理ページへ遷移
            Router.moveTo('convocation/notificationList',{
                mid : this.distributionHistId,
                gcd : groupCd
            });
        },

        /**
         * 職員招集状況詳細への遷移を行うメソッド
         */
        moveDivisionStatusDetailPage: function(item){
            // Gridの情報(item)が引数となっている
            var divisionCd = item.id;
            // メールのIDと職員のIDをURLに追加し、管理ページへ遷移
            Router.moveTo('convocation/notificationList',{
                mid : this.distributionHistId,
                dcd : divisionCd
            });
        },

        /**
         * グリッドの表示制御
         */
        displayGrid: function() {
            if (!this.display || this.display==='group') {
                this.displayGroup();
            } else {
                this.displayDivision();
            }
        },

        displayDivision: function() {
            if (this.display !== 'division') {
                this.display = 'division';
                Router.moveTo('convocation/notificationDetail',{
                    id : this.distributionHistId,
                    display : this.display
                });
            }
            domStyle.set(this.displayDivisionButton.domNode, 'display', 'none');
            domStyle.set(this.displayGroupButton.domNode, 'display', '');
            domStyle.set(this.convoStatusDetailGrid.domNode, 'height', '0px');
            domStyle.set(this.convoDivisionStatusDetailGrid.domNode, 'height', '93%');
        },

        displayGroup: function() {
            if (this.display !== 'group') {
                this.display = 'group';
                Router.moveTo('convocation/notificationDetail',{
                    id : this.distributionHistId,
                    display : this.display
                });
            }
            domStyle.set(this.displayDivisionButton.domNode, 'display', '');
            domStyle.set(this.displayGroupButton.domNode, 'display', 'none');
            domStyle.set(this.convoStatusDetailGrid.domNode, 'height', '93%');
            domStyle.set(this.convoDivisionStatusDetailGrid.domNode, 'height', '0px');
        }
    });
});

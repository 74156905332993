/**
 * 職員招集 職員登録・詳細画面部分の共通モジュール
 * @module app/convocation/view/employee/_ConvoEmployeePageBase
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojox/validate/web',
    'idis/service/Requester',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/model/UserInfo',
    'idis/consts/ACL',
    //以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'app/convocation/view/employee/EmployeeDivisionSelector'
], function(module, declare, lang, domStyle, validate, Requester, Router, DialogChain, InfoDialog, Loader, _PageBase, UserInfo, ACL) {
    /**
     * 職員招集 職員登録・詳細画面部分の共通モジュール
     * @class _ConvoEmployeePageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/employee/_ConvoEmployeePageBase~_ConvoEmployeePageBase# */
        {
            store: null,

            /**
             * @param evt ボタンクリックイベント
             * メールアドレス1に対する「テスト送信」ボタンが押下された際の挙動
             */
            onSendTestMail01: function() {
                var value = this.form.get('value');
                this._onSendTestMail(value.emailAddress01);
            },

            /**
             * @param evt ボタンクリックイベント
             * メールアドレス2に対する「テスト送信」ボタンが押下された際の挙動
             */
            onSendTestMail02: function() {
                var value = this.form.get('value');
                this._onSendTestMail(value.emailAddress02);
            },

            /**
             * @param mailAddress 入力されたメールアドレス
             * 「テスト送信」ボタンが押下された際の挙動を共通化
             */
            _onSendTestMail: function(mailAddress) {
                // 入力チェック
                if (!this.validateEmailAddress(mailAddress)) {
                    InfoDialog.show('入力チェックエラー', '不正な形式のメールアドレスです<br>' + mailAddress);
                    return false;
                }
                var message = 'テストメールを下記アドレスに送信します。よろしいですか？<br>' + mailAddress;
                this.chain.confirm(message, function(chain) {
                    // メールアドレスをpathParamとしてサーバサイドへリクエスト
                    var promise = Requester.post('/api/convoMails/employees/testMails/' + mailAddress).then(function() {
                        // 処理成功時
                        chain.info('テストメールを送信いたしました。', '送信');
                    }, function(error) {
                        // 処理失敗時
                        console.error(error);
                        chain.infoError(error);
                    });
                    Loader.wait(promise);
                });
            },

            /**
             * 画面から入力された項目の妥当性をチェックする。
             */
            validate: function() {
                var formData = this.form.get('value');
                var msg = [];
                var updateFlg = false;
                if (formData.sectCd !== undefined) {
                    updateFlg = true;
                }

                // 課コード
                if (formData.divisionCd === '') {
                    msg.push('部署が選択されていません');
                } else if (formData.sectCd === '') {
                    msg.push('部署が選択されていません');
                } else {
                    var role = UserInfo.getRoleCd();
                    if (role !== ACL.ADMIN_USER) {
                        if (updateFlg) {
                            if (UserInfo.getOrganization().sectCd !== null &&
                                UserInfo.getOrganization().sectCd !== '' &&
                                UserInfo.getOrganization().sectCd !== formData.sectCd) {
                                msg.push('管轄外の部署です');
                            } else if (UserInfo.getOrganization().deptCd !== null &&
                                UserInfo.getOrganization().deptCd !== '' &&
                                UserInfo.getOrganization().deptCd !== formData.sectCd) {
                                if (UserInfo.getOrganization().deptCd.substr(0, 4) !==
                                        formData.sectCd.substr(0, 4)) {
                                    msg.push('管轄外の部署です');
                                }
                            }
                        } else {
                            if (UserInfo.getOrganization().sectCd !== null &&
                                UserInfo.getOrganization().sectCd !== '' &&
                                UserInfo.getOrganization().sectCd !== formData.divisionCd) {
                                msg.push('管轄外の部署です');
                            } else if (UserInfo.getOrganization().deptCd !== null &&
                                UserInfo.getOrganization().deptCd !== '' &&
                                UserInfo.getOrganization().deptCd !== formData.divisionCd) {
                                if (UserInfo.getOrganization().deptCd.substr(0, 4) !==
                                        formData.divisionCd.substr(0, 4)) {
                                    msg.push('管轄外の部署です');
                                }
                            }
                        }
                        updateFlg = false;
                    }
                }

                // emailAddress01
                if (formData.emailAddress01 && !this.validateEmailAddress(formData.emailAddress01)) {
                    msg.push('不正な形式のメールアドレスです<br>' + formData.emailAddress01);
                }

                // emailAddress02
                if (formData.emailAddress02 && !this.validateEmailAddress(formData.emailAddress02)) {
                    msg.push('不正な形式のメールアドレスです<br>' + formData.emailAddress02);
                }

                if (msg.length>0) {
                    InfoDialog.show('入力チェックエラー', msg.join('<br>'));
                    return false;
                }

                return true;
            },

            /**
             * アドレス形式チェック
             */
            validateEmailAddress: function(emailAddress) {
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddress)) {
                    return false;
                }
                return validate.isEmailAddress(emailAddress);
            }
        });
});

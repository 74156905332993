/**
 * 避難発令判断支ルールセット登録画面用モジュール。
 * @module app/evacrecommendRule/EvacRecommendRuleRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    './_EvacRecommendRuleRegisterPageBase',
], function (module, declare, lang, domStyle, _EvacRecommendRuleRegisterPageBase) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacrecommendRule/_EvacRecommendRuleRegisterPageBase~_EvacRecommendRuleRegisterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacRecommendRuleRegisterPageBase,
    /** @lends module:app/evacrecommendRule/EvacRecommendRuleRegisterPage~EvacRecommendRuleRegisterPage# */ {
            startup: function () {
                this.inherited(arguments);
                // タイトル
                this.title.innerHTML = "新規登録";

                // ボタン表示制御
                this.initButtonArea();

                // 市町セレクタに初期セット（ツリーの初期化に間に合わないので、ここで実行する）
                var municipalityCd = this.municipalityCd.setDefault();
                // 地区ツリーを初期化
                this.initTree(municipalityCd)
                    // ツリーに表示中の地区を地図上に表示
                    .then(lang.hitch(this, this.showSelectDistrictLayer, municipalityCd));
            },

            /**
             * 登録ボタン表示切り替え
             */
            initButtonArea: function () {
                domStyle.set(this.registerButtonArea, 'display', '');
                domStyle.set(this.updButtonArea, 'display', 'none');
            },
        });
});

/**
 * モーダルダイアログ用モジュール。
 * @module idis/view/dialog/IdisModal
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-class',
    'dojo/text!./templates/IdisModal.html',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin'
], function(module, array, declare, lang, Deferred, domClass, template, _WidgetBase, _TemplatedMixin) {

    // メッセージ文字列
    var MSG = {
        CONFIRM: '送信してもよろしいですか？',
        CONFIRM_TITLE: '確認',
        INFO_TITLE: '情報',
        COMPLETE: '処理を完了しました。',
        COMPLETE_TITLE: '完了',
        PROGRESS: 'お待ち下さい...',
        PROGRESS_TITLE: '送信中'
    };

    /**
     * 表示モード。
     * 以下3種が存在する。
     *
     * | 値       | 内容         |
     * | -------- | ------------ |
     * | INFO     | 情報モード   |
     * | CONFIRM  | 確認モード   |
     * | PROGRESS | 処理中モード |
     * @typedef MODE
     */
    var MODE = {
        INFO: 0,
        CONFIRM: 1,
        PROGRESS: 2
    };

    /**
     * ダイアログの各ボタンクリック時の動作を定義する。
     * @callback ReactorCallback
     * @returns {boolean} trueの場合は実行後にダイアログを閉じる
     */

    /**
     * ダイアログの各ボタンをクリックした際の動作を管理するオブジェクト。
     * @typedef Reactor
     * @property {module:idis/view/dialog/IdisModal~ReactorCallback} [onOK] OKボタンクリック時の動作
     * @property {module:idis/view/dialog/IdisModal~ReactorCallback} [onCancel] キャンセルボタンクリック時の動作
     * @property {module:idis/view/dialog/IdisModal~ReactorCallback} [onClose] 右上の閉じるボタンクリック時の動作
     */

    /**
     * 共通モーダル・ダイアログ。
     * @class IdisModal
     * @extends module:dijit/_WidgetBase~_WidgetBase
     * @extends module:dijit/_TemplatedMixin~_TemplatedMixin
     */
    var IdisModal = declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin],
                            /** @lends module:idis/view/dialog/IdisModal~IdisModal# */ {
        /**
         * ボタンが押されたときの各動作
         * @type {module:idis/view/dialog/IdisModal~Reactor}
         * @private
         */
        _reactor: null,

        /**
         * 表示モード
         * @type {module:idis/view/dialog/IdisModal~MODE}
         * @private
         */
        _mode: null,

        /**
         * ルート要素のCSSクラス
         * @member module:idis/view/dialog/IdisModal~IdisModal#~baseClass
         * @type {string}
         * @protected
         */
        baseClass: 'idis-IdisModal',

        /**
         * テンプレート文字列
         * @member module:idis/view/dialog/IdisModal~IdisModal#~templateString
         * @type {string}
         * @protected
         */
        templateString: template,

        /**
         * コンテンツ部分を設定する。
         * @see {@link module:dijit/_WidgetBase~_WidgetBase#~set}
         * @param {string} content コンテンツ内容
         * @private
         * @example
         * // 以下の形式で間接的に呼び出される
         * this.set('content', content);
         */
        _setContentAttr: function(content) {
            this.content.innerHTML = content;
        },

        /**
         * タイトルを設定する。
         * @see {@link module:dijit/_WidgetBase~_WidgetBase#~set}
         * @param {string} title タイトル文字列
         * @private
         * @example
         * // 以下の形式で間接的に呼び出される
         * this.set('title', title);
         */
        _setTitleAttr: function(title) {
            this.title.innerHTML = title;
        },

        /**
         * 表示モードを設定する。
         * @see {@link module:dijit/_WidgetBase~_WidgetBase#~set}
         * @param {module:idis/view/dialog/IdisModal~MODE} mode ダイアログの表示モード
         * @private
         * @example
         * // 以下の形式で間接的に呼び出される
         * this.set('mode', mode);
         */
        _setModeAttr: function(mode) {
            this._mode = mode;
        },

        /**
         * 画面上へ設置する。
         * @function module:idis/view/dialog/IdisModal~IdisModal#~postCreate
         * @protected
         */
        postCreate: function() {
            this.inherited(arguments);
            // 画面へ設置
            this.ownerDocumentBody.appendChild(this.domNode);
        },

        /**
         * ダイアログを表示する。
         */
        show: function() {
            // ダイアログ種別によって表示を切り替える
            if (this._mode === MODE.PROGRESS) {
                // 処理中はボタンの代わりに表示を出す
                domClass.add(this.domNode, 'is-progress');
            } else {
                domClass.remove(this.domNode, 'is-progress');
                // 情報ダイアログはキャンセルボタンを出さない
                domClass.toggle(this.domNode, 'is-info', this._mode === MODE.INFO);
            }
            // 画面上に表示
            domClass.remove(this.domNode, 'is-hidden');
            // 誤操作防止のため、強制的にフォーカスを解除する
            document.activeElement.blur();
        },

        /**
         * ダイアログを隠す。
         */
        hide: function() {
            domClass.add(this.domNode, 'is-hidden');
        },

        /**
         * OKボタンクリック時に呼び出される。
         * コールバック関数が指定されている場合は実行する。
         * 関数が指定されていない場合や、関数が真値を返した場合はダイアログを閉じる。
         * @protected
         */
        onOK: function() {
            if (!this._reactor || !this._reactor.onOK || this._reactor.onOK.apply(null)) {
                // 処理が指示されていない場合は単に閉じる
                this.hide();
            }
        },

        /**
         * Cancelボタンクリック時に呼び出される。
         * コールバック関数が指定されている場合は実行する。
         * 関数が指定されていない場合や、関数が真値を返した場合はダイアログを閉じる。
         * @protected
         */
        onCancel: function() {
            if (!this._reactor || !this._reactor.onCancel || this._reactor.onCancel.apply(null)) {
                // 処理が指示されていない場合は単に閉じる
                this.hide();
            }
        },

        /**
         * 右上の×ボタンクリック時に呼び出される。
         * コールバック関数が指定されている場合は実行する。
         * 関数が指定されていない場合や、関数が真値を返した場合はダイアログを閉じる。
         * @protected
         */
        onClose: function() {
            if (!this._reactor || !this._reactor.onClose || this._reactor.onClose.apply(null)) {
                // 処理が指示されていない場合は単に閉じる
                this.hide();
            }
        },

        /**
         * 確認ダイアログを開く。
         * @param {string} [content] コンテンツ内容
         * @param {string} [title] タイトル
         * @param {module:idis/view/dialog/IdisModal~ReactorCallback} [onOK] OKが押されたときの動作
         * @param {module:idis/view/dialog/IdisModal~ReactorCallback} [onCancel] Cancel or 右上の×が押されたときの動作
         */
        confirm: function(content, title, onOK, onCancel) {
            // 引数の省略を判定
            if (lang.isFunction(content)) {
                // content, title 省略
                onCancel = title;
                onOK = content;
                title = void 0;
                content = void 0;
            } else if (lang.isFunction(title)) {
                // title 省略
                onCancel = onOK;
                onOK = title;
                title = void 0;
            }

            // 表示内容を設定
            this.set({
                mode: MODE.CONFIRM,
                content: content || MSG.CONFIRM,
                // タイトル文字は空文字を許容
                title: (title === void 0 ? MSG.CONFIRM_TITLE : title)
            });

            // ボタンクリック時の動作を登録
            this._reactor = {
                onOK: onOK,
                onClose: onCancel,
                onCancel: onCancel
            };

            // ダイアログを表示
            this.show();
        },

        /**
         * 情報ダイアログを開く。
         * @param {string} content コンテンツ内容
         * @param {string} [title] タイトル
         * @param {module:idis/view/dialog/IdisModal~ReactorCallback} [onClose] OK or 右上の×が押されたときの動作
         */
        info: function(content, title, onClose) {
            // 引数の省略を判定
            if (arguments.length < 1 || lang.isFunction(content)) {
                console.error(module.id + '::info: content must be specified');
            }
            if (lang.isFunction(title)) {
                // title 省略
                onClose = title;
                title = void 0;
            }

            // 表示内容を設定
            this.set({
                mode: MODE.INFO,
                content: content,
                // タイトル文字は空文字を許容
                title: (title === void 0 ? MSG.INFO_TITLE : title)
            });

            // ボタンクリック時の動作を登録
            this._reactor = {
                onOK: onClose,
                onClose: onClose
            };

            // ダイアログを表示
            this.show();
        },

        /**
         * 完了時用メッセージを設定し情報ダイアログを開く。
         * @param {module:idis/view/dialog/IdisModal~ReactorCallback} [onClose] OK or 右上の×が押されたときの動作
         */
        complete: function(onClose) {
            this.info(MSG.COMPLETE, MSG.COMPLETE_TITLE, onClose);
        },

        /**
         * 処理中ダイアログを開く。
         * @param {string} [content=送信中...] コンテンツ内容
         * @param {string} [title] タイトル
         */
        progress: function(content, title) {
            // 表示内容を設定
            this.set({
                mode: MODE.PROGRESS,
                content: content || MSG.PROGRESS,
                // タイトル文字は空文字を許容
                title: (title === void 0 ? MSG.PROGRESS_TITLE : title)
            });

            // ボタンクリック時の動作を登録
            this._reactor = null;

            // ダイアログを表示
            this.show();
        }
    });

    /**
     * モーダル・ダイアログのシングルトン
     * @member _singleton
     * @type {module:idis/view/dialog/IdisModal}
     * @private
     */
    var _singleton = null;

    /**
     * シングルトンを返す。
     * @function _getInstance
     * @returns {module:idis/view/dialog/IdisModal~IdisModal} ダイアログのシングルトン・インスタンス
     * @private
     */
    function _getInstance() {
        _singleton = _singleton;
        if (!_singleton) {
            _singleton = new IdisModal();
        }
        return _singleton;
    }

    /**
     * シングルトンの同名メソッドを呼び出す。
     * @see {@link module:idis/view/dialog/IdisModal~IdisModal}
     * @function module:idis/view/dialog/IdisModal.confirm
     */

    /**
     * シングルトンの同名メソッドを呼び出す。
     * @see {@link module:idis/view/dialog/IdisModal~IdisModal}
     * @function module:idis/view/dialog/IdisModal.info
     */

    /**
     * シングルトンの同名メソッドを呼び出す。
     * @see {@link module:idis/view/dialog/IdisModal~IdisModal}
     * @function module:idis/view/dialog/IdisModal.complete
     */

    /**
     * シングルトンの同名メソッドを呼び出す。
     * @see {@link module:idis/view/dialog/IdisModal~IdisModal}
     * @function module:idis/view/dialog/IdisModal.progress
     */

    /**
     * シングルトンの同名メソッドを呼び出す。
     * @see {@link module:idis/view/dialog/IdisModal~IdisModal}
     * @function module:idis/view/dialog/IdisModal.hide
     */

    // シングルトンの一部メソッドをスタティックなメソッドとして公開する
    array.forEach([
        'confirm',
        'info',
        'complete',
        'progress',
        'hide'
    ], function(method) {
        IdisModal[method] = function() {
            // シングルトンを取得
            var modal = _getInstance();
            // 引数を引き渡して実行
            return modal[method].apply(modal, arguments);
        };
    });

    return IdisModal;
});


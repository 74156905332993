/**
 * 気象情報パネル
 * @module app/monitor/WeatherPanel
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/WeatherPanel.html',
    'dojo/topic',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    '../config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, declare, lang, domStyle, JSON, template, topic, Router, UserInfo, Requester,
    ConfirmDialog, DialogChain, Loader, _PageBase, config) {
    /**
     * 気象情報パネル
     * @class WeatherPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/WeatherPanel~WeatherPanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('気象情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に気象情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));
        },

        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 気象情報を設定
            this.setWeatherInfo();

            // 洪水情報を設定
            this.setFloodInfo();
        },

        /**
         * 気象情報を設定します
         */
        setWeatherInfo: function() {
            var self = this;

            // var promise = Requester.get('/data/weatherinfo/summary/' + this._municipalityCd + '.json', {
            var param = '';
            if (this._municipalityCd) {
                param = param + '?municipalityCd=' + this._municipalityCd;
            }
            var promise = Requester.get('/api/WeatherInformations/summary' + param, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('気象情報（' + self._municipalityCd + '）：' +
                    JSON.stringify(item));

                // 特別警報・警報・注意報を設定
                self.setWeatherAlerm(self, item);

                // 土砂災害警戒情報を設定
                self.setSedimentDisaster(self, item);

                // 記録的短時間大雨情報を設定
                self.setHeavyRain(self, item);

            }, function(error) {
                console.log('気象情報JSON読み込みでエラー param:' + param);
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },


        /**
         * 洪水情報を設定します
         */
        setFloodInfo: function() {
            var self = this;
            var param = '';
            if (this._municipalityCd) {
                param = param + '?municipalityCd=' + this._municipalityCd;
            }
            var promise = Requester.get('/api/floodForecasts/summary' + param, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('洪水情報（' + self._municipalityCd + '）：'+
                    JSON.stringify(item));

                // 洪水予報情報を設定
                self.setFlood(self, item);
            }, function(error) {
                console.log('洪水情報の取得でエラー:/api/floodForecasts/summary' + param);
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 特別警報・警報・注意報を設定します
         */
        setWeatherAlerm: function(self, item) {
            if (self.weatherAlarm) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if (!item.specialWarningFlg &&
                    !item.warningFlg &&
                    !item.advisoryFlg) {
                    domStyle.set(self.weatherAlarm, 'backgroundColor', 'white');
                    domStyle.set(self.weatherAlarm, 'color', 'black');
                    html.push('なし');
                } else {
                    var backgroundColor = 'white';
                    var color = 'black';
                    if (item.specialWarningFlg) {
                        backgroundColor = '#0C000C';
                        color = 'white';
                    } else if(item.warningFlg) {
                        backgroundColor = '#FF2800';
                        color = 'white';
                    } else if(item.advisoryFlg) {
                        backgroundColor = '#FAF500';
                    }
                    domStyle.set(self.weatherAlarm, 'backgroundColor', backgroundColor);
                    domStyle.set(self.weatherAlarm, 'color', color);
                    if (item.specialWarningFlg) {
                        html.push('特別警報発表');
                    } else if(item.warningFlg) {
                        html.push('警報発表');
                    } else {
                        html.push('注意報発表');
                    }
                }
                html.push('</span>');
                self.weatherAlarm.innerHTML = html.join('');
            }
        },

        /**
         * 土砂災害警戒情報を設定します
         */
        setSedimentDisaster: function(self, item) {
            if (self.sedimentDisaster) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if (!item.sedimentAlertFlg) {
                    domStyle.set(self.sedimentDisaster, 'backgroundColor', 'white');
                    domStyle.set(self.sedimentDisaster, 'color', 'black');
                    html.push('なし');
                } else {
                    domStyle.set(self.sedimentDisaster, 'backgroundColor', '#AA00AA');
                    domStyle.set(self.sedimentDisaster, 'color', 'white');
                    html.push('【警戒レベル4相当】発表');
                }
                html.push('</span>');
                self.sedimentDisaster.innerHTML = html.join('');
            }
        },

        /**
         * 記録的短時間大雨情報を設定します
         */
        setHeavyRain: function(self, item) {
            if(self.heavyRain) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if(!item.heavyRainAlertFlg) {
                    domStyle.set(self.heavyRain, 'backgroundColor', 'white');
                    domStyle.set(self.heavyRain, 'color', 'black');
                    html.push('なし');
                } else {
                    domStyle.set(self.heavyRain, 'backgroundColor', '#FF2800');
                    domStyle.set(self.heavyRain, 'color', 'white');
                    html.push('発表');
                }
                html.push('</span>');
                self.heavyRain.innerHTML = html.join('');
            }
        },

        /**
         * 洪水予報情報を設定します
         */
        setFlood: function(self, item) {
            if(self.flood) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if(item.floodWarningLevel  === 5) {
                    domStyle.set(self.flood, 'backgroundColor', '#0C000C');
                    domStyle.set(self.flood, 'color', 'white');
                    html.push('【警戒レベル5相当】発表');
                } else if(item.floodWarningLevel  === 4) {
                    domStyle.set(self.flood, 'backgroundColor', '#AA00AA');
                    domStyle.set(self.flood, 'color', 'white');
                    html.push('【警戒レベル4相当】発表');
                } else if(item.floodWarningLevel  === 3) {
                    domStyle.set(self.flood, 'backgroundColor', '#FF2800');
                    domStyle.set(self.flood, 'color', 'white');
                    html.push('【警戒レベル3相当】発表');
                } else if(item.floodWarningLevel  === 2) {
                    domStyle.set(self.flood, 'backgroundColor', '#FAF500');
                    domStyle.set(self.flood, 'color', 'black');
                    html.push('【警戒レベル2相当】発表');
                } else {
                    domStyle.set(self.flood, 'backgroundColor', 'white');
                    domStyle.set(self.flood, 'color', 'black');
                    html.push('なし');
                }
                html.push('</span>');
                self.flood.innerHTML = html.join('');
            }
        },

        onWeatherAlarmLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo');
        },

        onSedimentDisasterLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo/history',
                {areaCode : this._municipalityCd.substr(0, 5) + '00'});
        },

        onHeavyRainLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo/history',
                {areaCode : this._municipalityCd.substr(0, 5) + '00'});
        },

        onFloodForecastLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 洪水予報一覧へ遷移
            Router.moveTo('floodforecast');
        }

    });
});

/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterAdminPage.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ShelterAdminPage.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'dojo/dom',
    'dojo/dom-style',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'dstore/Memory',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/grid/helper',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/control/Router',
    'app/config',
    'idis/consts/USER_TYPE',
    'app/model/Municipality',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/ContentPane',
    'app/shelter/ShelterFormDialog',
    'app/shelter/ShelterAdminGrid'
], function (module, declare, lang, template, IdisRest, _PageBase, dom, domStyle,
    registry, IdisGrid, Memory, ACL, AclButton, helper, Locator,
    UserInfo, UserType, DisasterInfo, Router, config, USER_TYPE, Municipality) {
    /**
     * 避難所概況画面
     * @class ShelterAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/shelter/ShelterAdminPage~ShelterAdminPage# */
        {
            // テンプレート文字列
            templateString: template,
            store: null,
            shelterGrid: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--shelter',

            constructor: function () {
                this.store = new IdisRest({
                    target: '/api/shelters/admin'
                });
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            startup: function () {
                this.inherited(arguments);
                this.condRegionCd.set('value', Locator.getQuery().municipalityCd);
                this.condRegionCd.set('clear', true);

                this.initMenu();
                if (UserInfo.getUserType() !== UserType.MUNICIPALITY) {
                    this.initShelterGrid();
                }
                this.setButtonStatus();
            },

            initMenu: function () {
                // 振興局セレクタの「クリア」ボタンを右寄せにする
                domStyle.set(this.condRegionCd.clearButton.domNode, 'float', 'right');

                // 振興局セレクタの「-」ボタンを非表示にする
                domStyle.set(this.condRegionCd.collapseAllButton.domNode, 'visibility', 'hidden');

                // 県ユーザは未選択
                // 振興局ユーザの場合は自身の振興局
                if (UserInfo.getUserType() === UserType.REGION) {
                    // 振興局ユーザの場合は自身の振興局を初期設定
                    this.condRegionCd.set('value', UserInfo.getRegionCd());
                    this.condRegionCd.set('noAllButton', true);
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    // 市町村ユーザの場合は所属市町村の振興局を取得して設定(政令指定都市のみアクセス可能)
                    Municipality.store.get(UserInfo.getMunicipalityCd()).then(
                        lang.hitch(this, function (municipality) {
                            if (municipality.regionCd !== null && municipality.regionCd !== '') {
                                this.condRegionCd.set('value', municipality.regionCd);
                                this.initShelterGrid();
                            }
                        }));
                }
            },

            setButtonStatus: function () {
                // 避難所管理の登録権限を持たない場合、新規開設ボタンと一括操作ボタンを非表示にする。
                // 施設追加ボタンについて開設時にマスタ登録が必要になったとき用のボタンを想定、開設権限がない場合に非表示とする
                if (!UserInfo.hasWriteAuthz('F05006')) {
                    domStyle.set(this.RegisterButton.domNode, 'display', 'none');
                    domStyle.set(this.BulkOperatingButton.domNode, 'display', 'none');
                    domStyle.set(this.RegisterFacilityButton.domNode, 'display', 'none');
                }
            },

            initShelterGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.shelterGrid.on('listButtonClick', lang.hitch(this, function (evt) {
                    this.onListButtonClick(evt.item);
                }));
                //表を初期条件で表示
                this.updateGridQuery();
            },

            //一覧ボタンを押下したときの挙動
            onListButtonClick: function (object) {
                // 避難所情報一覧画面へ遷移
                Router.moveTo('shelter', {
                    municipalityCd: object.municipalityCd,
                    type: 'admin'
                });
            },
            //新規登録ボタンを押下したときの挙動
            onRegisterButtonClick: function () {
                // 避難所情報新規登録画面へ遷移
                if (!this._municipalityCd) {
                    this._municipalityCd = config.municInfo.defaultMunicCd;
                }
                Router.moveTo('shelter/register', {
                    municipalityCd: this._municipalityCd
                });
            },
            //施設追加ボタンを押下したときの挙動
            onRegisterFacilityButtonClick: function () {
                // 施設新規登録画面へ遷移
                Router.moveTo('facility/register', {
                    type: 'shelter'
                });
            },
            //一括操作ボタンを押下したときの挙動
            onBulkOperatingButtonClick: function (object) {
                console.debug(object);
                // 避難所情報一括操作画面へ遷移
                Router.moveTo('shelter/operating', {
                    municipalityCd: this._municipalityCd
                });
            },
            showFormDialog: function () {
                this.formDialog.show();
            },
            /**
             * 検索ボタンが押されたときに呼び出される。
             */
            onSubmit: function () {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function () {
                var value = this.form.get('value');
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);
                // 振興局
                filter = filter.eq('regionCd', value.condRegionCd);
                //集計日時
                if (value.countTime) {
                    var countTime = new Date(value.countTime).getTime();
                    filter = filter.eq('countTime', countTime);
                }
                // すべての市区町村を表示：
                var openOnlyFlg = '1';
                //チェックがついていなければopenOnlyフラグを1にする
                if (value.openAllFlg.length === 0) {
                    // 特別区の場合(別の振興局を検索している場合は市町村を限定しない)
                    if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                        UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd &&
                        UserInfo.getRegionCd() === value.condRegionCd) {
                        filter = filter.eq('municipalityCd', config.municInfo.cityMunicCd);
                        openOnlyFlg = '0';
                    } else if (UserInfo.getUserType() === USER_TYPE.REGION) {
                        if (!value.condRegionCd || value.condRegionCd.length === 0) {
                            // 振興局ユーザかつ振興局未選択だった場合、デフォルト表示は市内各区とする
                            filter = filter.eq('regionCd', UserInfo.getRegionCd());
                            openOnlyFlg = '0';
                        }
                    } else {
                        openOnlyFlg = '1';
                    }
                } else {//チェックがついているときは全件表示のため、openOnlyフラグを0にする。
                    openOnlyFlg = '0';
                }
                filter = filter.eq('openOnlyFlg', openOnlyFlg);

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.shelterGrid.set('collection', collection);
            }

        });
});

/**
 * FAX送信履歴詳細画面用モジュール。
 *
 * @module app/broadnotify/BroadnotifyDetailPage
 */
define(['module',
        'dojo',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/dom-construct',
        'dojo/text!./templates/BroadnotifyDetailPage.html',
        'idis/view/dialog/DialogChain',
        './_BroadnotifyPageBase',
        'idis/view/dialog/InfoDialog',
        'idis/control/Router',
        'idis/store/IdisRest',
        'idis/service/Requester',
        'idis/control/Locator',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/view/form/EmployeeDivisionSelector',
        'app/broadnotify/BroadnotifyDetailGrid'
    ],
        function(module, dojo, declare, lang, locale, domStyle, domConstruct, template, DialogChain,
            _BroadnotifyPageBase, InfoDialog, Router, IdisRest, Requester, Locator) {
    /**
     * FAX送信履歴詳細画面。
     *
     * @class FaxSendDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BroadnotifyPageBase,
    /** @lends module:app/broadnotify/BroadnotifyDetailPage# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotify',

        /**
         * グローバル変数の初期化を行うメソッド。
         */
        constructor: function() {
            this.broadnotifyDetailId = Locator.getQuery().broadnotifyDetailId;
           // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'broadnotifyDetailId',
                target: '/api/broadnotify/list/' + this.broadnotifyDetailId
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 件名、本文、配信時刻を設定する
            this.initForm();
            this.initBroadnotifyDetailGrid();
        },

        /**
         * 一覧画面上部のコンテンツを生成するためのメソッド
         */
        initForm: function(){
            Requester.get('/api/broadnotify/forms/' + this.broadnotifyDetailId)
            .then(lang.hitch(this,function(data) {
                this.senderName.innerHTML = data.senderName;
                this.sendTimestamp.innerHTML = locale.format(new Date(data.sendTimestamp));

                this.notifyTypeName.innerHTML = data.notifyTypeName;
                var type = null;
                switch(data.necessityResponse) {
                    case '0':
                        type = '不要';
                        break;
                    case '1':
                        type = '要';
                        break;
                }
                this.necessityResponse.innerHTML = type;
                this.title.innerHTML =  data.title;
                this.content.set('value', data.content);
                if(data.attachFile.broadnotifyAtcFileId !== null) {
                    this.attachFile.innerHTML = data.attachFile.attachFileName;
                } else {
                    this.attachFileDowloadButton.setDisabled(true);
                }
                this._attachFile = data.attachFile;

                this.BroadnotifyDetailGrid.renderHeader();

            }));

        },

        /**
         * 受信確認ダイアログでダウンロードボタンがクリックされた時の挙動。
         */
        onBroadnotifyDlbutton: function() {
            var self = this;
            var download = null;
            var uri = this._attachFile.attachFilePath.replace('out/', 'data/');
            var fileName = this._attachFile.attachFileName;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }
            //IE対策
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                download.onclick();
            } else {
                download.click();
            }
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function (url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * Gridの初期化を行うメソッド
         */
        initBroadnotifyDetailGrid: function() {
            this.BroadnotifyDetailGrid.set('collection', this.store);
        },

        /**
         * @param evt ボタンクリックイベント
         * パンくずリスト（緊急通知一覧画面）が押された際に呼ばれるメソッド
         */
        onFaxSendLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('broadnotify', {
                broadnotifyDetailId : this.broadnotifyDetailId
            });
        }
    });
});

/**
 * 避難状況登録用ベースモジュール。
 * @module app/view/page/_EvacOrderRegisterPageBase
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderPageBase',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    './EvacOrderStoreModel',
    './Reason',
    './_EvacOrderProvidePageBase',
    'idis/consts/USER_TYPE',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/form/CheckBox',
    'app/shelter/ShelterOpeningGrid',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/map/LayerSelector',
    'app/view/form/DistrictSelector',
    'app/provide/ProvideEvacOrderRegisterDialog',
    './EvacOrderPopulationGrid'
], function (module, array, declare, lang, domStyle, all, topic, template,
    json, _EvacOrderPageBase, ACL, Loader, Locator, Router, Requester, IdisRest,
    InfoDialog, UserInfo, EvacOrderStoreModel, Reason, _EvacOrderProvidePageBase, USER_TYPE, config) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_EvacOrderPageBase, _EvacOrderProvidePageBase],
    /** @lends module:app/evacorder/_EvacOrderRegisterPageBase~_EvacOrderRegisterPageBase# */ {
            // テンプレート文字列
            templateString: template,

            /**
             * 各開設フラグ
             */
            _floodShFlg: '0',
            _sedimentDisasterShFlg: '0',
            _stormSurgeShFlg: '0',
            _earthquakeShFlg: '0',
            _tsunamiShFlg: '0',
            _fireShFlg: '0',
            _landsideWaterShFlg: '0',
            _volcanoShFlg: '0',

            isFixPage: false,

            _districtArray: [],

            // 緊急速報メール配信の判断に用いる。
            // 避難情報が自主避難より格上であり、格上げまたは範囲拡大の場合trueとする。
            _isUrgentMailTarget: false,

            // 災害シミュレーション用
            _scenarioId: null,
            _simulateFlg: false,
            _eventType: null,
            _content: null,
            _timing: null,

            constructor: function () {
                // form用のストアを生成する。
                this.formStore = new IdisRest({
                    idProperty: 'evacOrderId',
                    target: '/api/evacorders'
                });

                // 避難所一覧用のストアを生成する。
                this.shelterStore = new IdisRest({
                    idProperty: 'facilityId',
                    target: '/api/shelters/opening/districts'
                });

                this._scenarioId = Locator.getQuery().scenarioId;
                this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
                this._eventType = Locator.getQuery().eventType;
                this._content = Locator.getQuery().content;
                this._timing = Locator.getQuery().timing;
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);

                // 戻るボタン（発令判断支援用）はデフォルト非表示
                domStyle.set(this.cancelButtonArea, 'display', 'none');

                // 市町選択セレクターの初期表示及びボタンの出しわけを設定する。
                this.initMunicipalitySelector();

                // 組織コードを設定する。
                this.organization.set('value', this._orgCd);

                // 地図の初期位置を設定する。
                this.initLatlng();

                // 現況情報を最初から表示しておくのでボタンの色を変えておく
                domStyle.set(this.mapCntlBtn, 'backgroundColor', '#F90');

                //初期状態では、避難所検索ボタンは無効化しておく
                document.getElementById('searchCloseShelterDisabled').style.display = '';
                document.getElementById('searchCloseShelter').style.display = 'none';

                domStyle.set(this.detailShelterSearchArea, 'display', 'none');
                domStyle.set(this.detailSearchOpen, 'display', '');
                domStyle.set(this.detailSearchClose, 'display', 'none');

                // 市町ユーザの場合、「代理報告」チェックボックスを非表示
                if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                    domStyle.set(this.representedFlgNode, 'display', 'none');
                }

                // 市町村変更時の処理
                this.own(topic.subscribe('app/view/form/ManageRegionMunicipalitySelector::selected',
                    lang.hitch(this, function (payload) {
                        this._municipalityCd = payload;

                        // 県、県民局、政令指定都市だと、処理を進めない。
                        if (this._municipalityCd === config.municInfo.prefMunicCd ||
                            this._municipalityCd === config.municInfo.cityMunicCd ||
                            config.municInfo.prefRegCdGudge.indexOf(this._municipalityCd) > -1) {
                            return;
                        }

                        // 地図の初期位置を設定する。
                        this.initLatlng();

                        // 地区選択ツリーを再生成して、ツリーの情報に基づいて避難情報選択レイヤー再生成する。
                        this.reCreateTree().then(lang.hitch(this, 'reCreateLayers'));

                        // 避難所一覧グリッドを初期化する。
                        this.initShelterGrid();

                        // 管内市町でなければ、代理登録チェックする
                        this.representedFlg.set('checked', UserInfo.getMunicipalityCd() !== this._municipalityCd);
                    })
                ));

                // 管内市町でなければ、代理登録チェックする
                this.representedFlg.set('checked', UserInfo.getMunicipalityCd() !== this._municipalityCd);

                // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
                if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                    domStyle.set(this.overviewLabel, 'display', 'none');
                }

                // 詳細画面で利用するTipsをセットする
                this.setTipsPopups();

                // 災害シミュレーション時、一時保存ボタン非表示
                if (this._simulateFlg) {
                    domStyle.set(this.saveButtonArea, 'display', 'none');
                }

                // テンプレート適用ダイアログ初期化
                this.initTemplateDialog();
            },

            /**
             * TipsPopupをセットする。
             */
            setTipsPopups: function () {
                // 補足情報
                this.setNoteTips();
                // 発令理由詳細
                this.setIssueReasonTips();
                // 地区情報
                this.setDistrictTips();
            },

            //発令理由が変更された時
            _toggleOtherReason: function (value) {
                var form = this.form.get('value');
                if (form.issueReasonType !== null) { //何かが発令理由として選ばれた時
                    domStyle.set('searchCloseShelterDisabled', 'display', 'none');
                    domStyle.set('searchCloseShelter', 'display', '');
                    this.issueReason.set('value', Reason.get(value).lalertReason);
                } else { //発令理由が何も指定されなかった時
                    domStyle.set('searchCloseShelterDisabled', 'display', '');
                    domStyle.set('searchCloseShelter', 'display', 'none');
                    this.issueReason.set('value', '');
                }
                this.setEvacOrderTypeSelector(form.issueReasonType, this._evacOrderType);
                // 発令理由に応じて、対象世帯数・人数の欄が変わるので、世帯数・人数タブを開く。
                this.tabContainer.selectChild(this.populationTab);

            },

            /**
             * 地図上の地区選択レイヤーを初期化する。
             * @returns {Promise} 完了後に解決するPromise
             */
            // 登録画面では現況レイヤーを表示する。
            initDistrictLayer: function () {
                return all({
                    // 現況レイヤーを取得して地図上に表示する。
                    state: this.showLatestStateLayer().otherwise(function () {
                        // 未登録時は404になるため、失敗も成功扱いにする
                    }),
                    // 選択レイヤーを取得して地図上に表示する。
                    select: this.showSelectLayer()
                });
            },

            /**
             * ツリーを初期化する。
             */
            initTree: function () {
                // ツリーを生成する。
                if (!this.tree) {
                    this.tree = new this.treeClass({
                        model: new EvacOrderStoreModel({
                            disasterId: this._disasterId,
                            municipalityCd: this._municipalityCd
                        })
                    }, this.treeNode);
                    this.tree.startup();
                }
                this.own(this.tree);
                this.tree.onLoadDeferred.then(lang.hitch(this, '_updatePopulationStore'));
                // ツリーの作成が終了したら、地図のfilter用に地区ツリーのキャッシュを取得しておく
                this.tree.onLoadDeferred.then(lang.hitch(this, function () {
                    this.tree.model.store._getCache().then(lang.hitch(this, function (items) {
                        this._districtTreeCache = items.data;
                    }));
                }));
                return this.tree.onLoadDeferred;
            },

            /**
             * 地区ツリーを再作成する。
             * 市町変更時にツリーを破棄して再作成する。
             */
            reCreateTree: function () {
                // ツリーをdata-dojo-attach-pointで紐付けるノードを保持する。
                this._treeNode = this.treeNode;

                //  ツリーを一度破棄する
                this.tree.destroyRecursive();
                delete this.tree;

                // ツリーの親ノードの子供として、保持しておいたノードを追加する。
                this.treeParentNode.appendChild(this._treeNode);

                // 避難情報ツリーを追加する。
                return this.initTree();

            },
            /**
             * 地図レイヤーを再作成する。
             * 市町変更時にレイヤーを削除して再作成する。
             */
            reCreateLayers: function () {
                // 地図上のレイヤーを全て削除する。
                var layerControl = this._layerControl;
                Object.keys(layerControl.layers).forEach(lang.hitch(this, function (key) {
                    layerControl.removeLayerById(key);

                }));
                // 地区レイヤーを追加する。
                this.initDistrictLayer();
            },

            /**
             * 初期表示する市町村のコードを取得する。1
             */
            initMunicipalitySelector: function () {
                if (Locator.getQuery().municipalityCd) {
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                } else if (UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                    this._municipalityCd = this.DEFAULT_MUNICIPALITY_CODE;
                } else if (UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                    this._municipalityCd = UserInfo.getMunicipalityCds()[0];
                } else if (this._userMunicipalityCds && this._userMunicipalityCds.length > 0) {
                    this._municipalityCd = this._userMunicipalityCds[0];
                } else {
                    this._municipalityCd = this.DEFAULT_MUNICIPALITY_CODE;
                }

                if (this._userMunicipalityCds.length === 1) {
                    this.municipalitySelector.set('noAllButton', true);
                }
            },

            /**
             * 発令ボタンが押下されてFormのサブミットイベントが発生した際に呼ばれる
             */
            onRegisterButtonClick: function () {
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) { return false; }
                // フォームを取得
                this._evacOrderForm = this.generateForm();
                // 地区のバリデーションを行う
                if (!this.validateDistrictInfo(true)) { return false; }

                // 災害シミュレーション
                if (this._simulateFlg) {
                    this.registerEvent();
                    return;
                }
                // 配信確認ダイアログ表示
                this.showEvacOrderDialog4Register(this._evacOrderForm);
            },

            /**
             * 一時保存ボタンが押下されてFormのサブミットイベントが発生した際に呼ばれる
             */
            onSaveButtonClick: function () {
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) { return false; }
                // フォームを取得
                this._evacOrderForm = this.generateForm();
                // 地区のバリデーションを行う
                if (!this.validateDistrictInfo(true)) { return false; }

                // FIXME メッセージ内容
                if (this.shelterGrid.getSelectedIdList().length > 0) {
                    return this.showErrDialog('避難所を開設した状態で一時保存を実施することはできません。');
                }

                // 登録のみ、配信しない
                this.register();
            },

            /**
             * 送信用フォームの作成
             * @returns form
             */
            generateForm: function() {
                // formデータに災害IDと地区リストをセットする。
                var form = this.form.get('value');
                // 避難情報の登録
                // 市町村をセットする。
                form.municipalityCd = this._municipalityCd;
                // 災害IDをセットする。
                form.disasterId = this._disasterId;
                // 代理登録のフラグの値を設定
                form.representedFlg = this.representedFlg.get('checked') && UserInfo.getUserType() !== USER_TYPE.MUNICIPALITY ? 1 : 0;
                // 発令理由をセット
                if (!form.issueReasonType) {
                    form.issueReasonType = this._issueReasonType;
                }
                // 発令内容をセット
                form.evacOrderType = this._evacOrderType;
                // 地区リストをカンマ区切りの文字列としてセットする。
                var districtArray = this._districtArray = this.tree.getCheckedLeafs();
                // 選択された地区を変換する。
                form.districtList = this.convertDistrictArray(districtArray, form.evacOrderType,
                    form.issueReasonType, true);
                // Lアラート連携用の地区リストを作成する。
                form.evacOrderLalertAreaMap =
                    this.createLalertAreaList(this._districtTreeCache, districtArray, null, form, false, false);

                // 避難対象人数、避難対象世帯数をセットする。
                form.evaqueeNum = this.evaqueeNumNode.innerHTML;
                form.evacHouseholdNum = this.evacHouseholdNumNode.innerHTML;

                // 避難所情報
                var selectedIdList = this.shelterGrid.getSelectedIdList();
                // 避難所の施設IDをカンマ区切りの文字列で設定する。
                form.facilityIds = selectedIdList.join(',');
                // 「発令理由」を「開設理由」として登録
                // 「その他」が選択されている場合は「開設理由」を登録しない
                this.convertOpeningReason();
                // 個別の避難所登録情報を格納する
                form.data = {
                    items: array.map(selectedIdList, function (facilityId) {
                        return {
                            disasterId: this._disasterId,
                            facilityId: facilityId,
                            shelterStartTime: form.evacOrderTimestamp
                            // floodShFlg: this._floodShFlg,
                            // sedimentDisasterShFlg: this._sedimentDisasterShFlg,
                            // stormSurgeShFlg: this._stormSurgeShFlg,
                            // earthquakeShFlg: this._earthquakeShFlg,
                            // tsunamiShFlg: this._tsunamiShFlg,
                            // fireShFlg: this._fireShFlg,
                            // volcanoShFlg: this._volcanoShFlg
                        };
                    }, this)
                };
                // 避難所の開設日時は避難情報の発令日時と同じにする。
                form.shelterStartTime = form.evacOrderTimestamp;
                return form;
            },

            // 災害シミュレーションイベントの登録
            registerEvent: function() {
                delete this._evacOrderForm.evacOrderLalertAreaMap;
                var subSendData = {};
                subSendData.municipalityName = this.municipalitySelector.domNode.innerText.replace('選択','');
                subSendData.evacOrderTypeName = this.evacOrderType.domNode.innerText;
                subSendData.issueReasonTypeName = this.issueReasonType.domNode.innerText;
                // 災害シミュレーションのイベントとして登録する場合
                var jsonStr = json.stringify({
                    evacOrderPostForm: this._evacOrderForm,
                    evacOrderConversionForm: subSendData,
                    scenarioId: this._scenarioId,
                    eventType: this._eventType,
                    content: this._content,
                    timing: this._timing
                });
                var message = 'イベント登録します。';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.post('/api/simulations/event/', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function() {
                        chain.infoComplete(function() {
                            Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                        });
                    }), function(err) {
                        if (err.response.data && typeof (err.response.data) === 'string') {
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 避難情報の登録を行う
             */
            register: function () {
                var message = null;

                delete this._evacOrderForm.evacOrderLalertAreaMap;
                delete this._evacOrderForm._districtName;
                delete this._evacOrderForm._issueReasonTypeName;
                delete this._evacOrderForm._evacOrderTypeName;
                delete this._evacOrderForm._evacOrderTimestamp;

                // 避難所開設情報が設定されているか確認する。
                // 設定されていない場合はダイアログを警告メッセージに変更する。
                message = '選択した避難情報と避難所開設情報を登録します。<br>' +
                    '最新の世帯数・人口となっているか、また発令日時が正しいか、ご確認ください。<br>' +
                    'よろしければ、OKボタンを押してください。';
                if (!this._evacOrderForm.facilityIds || this._evacOrderForm.facilityIds === '') {
                    message = '<font color="red">避難所開設情報が登録されていません。</font> 避難情報のみを登録します。<br>' +
                        '最新の世帯数・人口となっているか、また発令日時が正しいか、ご確認ください。<br>' +
                        'よろしければ、OKボタンを押してください。';
                }
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                    var promise = this.formStore.add(this._evacOrderForm).then(lang.hitch(this, function () {

                        chain.info('登録が完了しました。', '完了', lang.hitch(this, function () {
                            // ヘッダーに反映
                            topic.publish(module.id + '::registered');
                            this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                            Router.moveTo('evacorder', {
                                municipalityCd: this._municipalityCd
                            });
                        }));
                    }), lang.hitch(this, function (error) {
                        console.error(error);
                        if (error.response.data && typeof (error.response.data) === 'string') {
                            error.response.data = json.parse(error.response.data);
                        }
                        chain.infoError(error);
                    }));
                    Loader.wait(promise);
                }));
            },

            // 避難所情報を初期表示する。
            initShelterGrid: function () {
                this.updateGridQuery();
            },

            queryShelterSimply: function () {

                // 地区をセットする。避難地区はカンマ区切りに変換してから
                var districtArray = this.tree.getCheckedLeafs();
                var districtCds = this.parseArray2String(districtArray);

                // 「その他」が選択されている場合は全避難所を表示
                // 選択された開設理由からフラグをセット
                //var shelterType = (this.issueReasonType.getValue() !== '00') ?
                //    this.issueReasonType.getValue() : null;

                // 東京では、シェルターフラグが活用されてないため、抽出条件から除外するため明示的にnullを設定する。
                var shelterType = null;

                this.updateGridQuery(districtCds, shelterType, null);
            },

            queryShelterAdvanced: function () {
                var districtCds = this.districtCd.get('value');
                if (!districtCds) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '避難所検索対象の地区が選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }

                //対象災害
                var shelterTypeList = [];
                if (this.floodShSchFlg.checked) {
                    shelterTypeList.push('02');
                }
                if (this.sedimentDisasterShSchFlg.checked) {
                    shelterTypeList.push('01');
                }
                if (this.stormSurgeShSchFlg.checked) {
                    shelterTypeList.push('05');
                }
                if (this.earthquakeShSchFlg.checked) {
                    shelterTypeList.push('03');
                }
                if (this.tsunamiShSchFlg.checked) {
                    shelterTypeList.push('04');
                }
                if (this.fireShSchFlg.checked) {
                    shelterTypeList.push('06');
                }
                if (this.landsideWaterShSchFlg.checked) {
                    shelterTypeList.push('07');
                }
                if (this.volcanoShSchFlg.checked) {
                    shelterTypeList.push('08');
                }
                var shelterTypes = shelterTypeList.join(',');

                //避難所区分
                var shelterCategoryList = [];
                if(this.eDesignatedEvacShFlg.checked){
                    shelterCategoryList.push('01');
                }
                //  if(this.designatedEvacShFlg.checked){
                //      shelterCategoryList.push('02');
                //  }
                if(this.welfareEvacShFlg.checked){
                    shelterCategoryList.push('03');
                }
                // if(this.temporaryEvacShFlg.checked){
                //     shelterCategoryList.push('04');
                // }
                // if (this.secondaryEvacShFlg.checked) {
                //     shelterCategoryList.push('08');
                // }
                // if (this.plannedNotSiteEvacShFlg.checked) {
                //     shelterCategoryList.push('09');
                // }
                // if (this.wardsEvacShFlg.checked) {
                //     shelterCategoryList.push('10');
                // }
                if (this.compmentPrelinEvacShFlg.checked) {
                    shelterCategoryList.push('12');
                }
                // if (this.eDesignatedPlaceEvacShFlg.checked) {
                //     shelterCategoryList.push('13');
                // }
                var shelterCategories = shelterCategoryList.join(',');

                this.updateGridQuery(districtCds, shelterTypes, shelterCategories);
            },

            // 避難所グリッドを更新する
            updateGridQuery: function (districtCds, shelterTypes, shelterCategories) {
                // 検索条件を格納するGrid用フィルターを作成
                var filter = new this.shelterStore.Filter();

                // 災害IDをセットする。
                filter = filter.eq('disasterId', this._disasterId);

                filter = filter.eq('districtCds', districtCds ? districtCds : '');
                // 「発令理由」に一致する「対象災害」を持つ避難所を検索
                if (shelterTypes) {
                    filter = filter.eq('shelterTypes', shelterTypes);
                }

                //「避難所区分」に一致する「対象災害」を持つ避難所を検索
                if (shelterCategories) {
                    filter = filter.eq('shelterCategories', shelterCategories);
                }

                // filterに対応するcollectionを取得
                var collection = this.shelterStore.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.shelterGrid.set('collection', collection);

            },

            /**
            /* 選択された開設理由に応じてフラグをセット
            */
            convertOpeningReason: function () {
                // 避難情報の種別に応じて避難所の開設理由をセットする。
                // その他の場合には開設理由をセットしない。
                var openingReason = this.issueReasonType.getValue();
                this._sedimentDisasterShFlg = (openingReason === '01') ? '1' : '0';
                this._floodShFlg = (openingReason === '02') ? '1' : '0';
                this._earthquakeShFlg = (openingReason === '03') ? '1' : '0';
                this._tsunamiShFlg = (openingReason === '04') ? '1' : '0';
                this._stormSurgeShFlg = (openingReason === '05') ? '1' : '0';
            },

            //詳細検索を表示ボタンをクリックした時の挙動
            onOpenFilterClick: function () {
                domStyle.set(this.detailShelterSearchArea, 'display', '');
                domStyle.set(this.detailSearchOpen, 'display', 'none');
                domStyle.set(this.detailSearchClose, 'display', '');
                domStyle.set(this.searchButton, 'display', '');

                domStyle.set('searchCloseShelterDisabled', 'display', 'none');
                domStyle.set('searchCloseShelter', 'display', 'none');
                this.borderContainer.resize();
            },

            //詳細検索を閉じるボタンをクリックした時の挙動
            onCloseFilterClick: function () {
                domStyle.set(this.detailShelterSearchArea, 'display', 'none');
                domStyle.set(this.detailSearchOpen, 'display', '');
                domStyle.set(this.detailSearchClose, 'display', 'none');
                domStyle.set(this.searchButton, 'display', 'none');

                if (this.issueReasonType.get('value') !== null) {
                    domStyle.set('searchCloseShelterDisabled', 'display', 'none');
                    domStyle.set('searchCloseShelter', 'display', '');
                } else {
                    domStyle.set('searchCloseShelterDisabled', 'display', '');
                    domStyle.set('searchCloseShelter', 'display', 'none');
                }
                this.borderContainer.resize();
            },

            // 人口タブを開く。
            showPopulationTab: function (evt) {
                evt.preventDefault();
                this.tabContainer.selectChild(this.populationTab);
            },

            applySelectedItem: function () {
                // 要素が選択されていない場合は何もしない
                var item = this.municipalitySelector.get('value');

                if (item) {
                    if (!this._selectedItem) { // 初回
                        this._selectedItem = this.municipalitySelector.get('value');
                        return;
                    }
                    // 市町が変更されていた場合、トピックを発行する。
                    if (this._selectedItem !== item) {
                        topic.publish('app/view/form/ManageRegionMunicipalitySelector::selected', item);
                    }
                    this._selectedItem = this.municipalitySelector.get('value');
                }
            },

            /**
             * テンプレートダイアログを初期化する
             */
            initTemplateDialog: function() {
                // ダイアログ中のページウィジェットを取得
                var page = this.dialog.getChildren()[0];
                page.initContent(this._isEmergency, this._municipalityCd);

                // ダイアログ側でOKボタンが押された際のイベント
                page.on('update', lang.hitch(this, function(evt) {
                    // テンプレート変更
                    this._changeTemplate(evt.data);
                    // ダイアログを非表示にする
                    this.dialog.hide();
                }));
            },

            /**
             * テンプレートダイアログを表示する
             */
            showTemplateDialog: function() {
                // ダイアログを表示
                this.dialog.show();
                // ダイアログ中のページウィジェットを取得
                var page = this.dialog.getChildren()[0];
                // ダイアログの画面を再表示する
                page.refresh();
            },

            /**
             * テンプレートを変更する
             */
            _changeTemplate: function(data) {
                // 選択されたテンプレートを画面へセット
                if (data.municipalityCd !== config.municInfo.prefMunicCd) {  // 県は選択不可
                    this.municipalitySelector.set('value', data.municipalityCd);
                }
                this.issueReasonType.set('value', data.issueReasonType);
                this.evacGuidance.set('value',data.evacGuidance);
                this.note.set('value',data.supplementInfo);
                //テンプレート内容の発令理由詳細を反映させる処理（_toggleOtherReasonの後にissueReasonを反映させる）
                setTimeout(lang.hitch(this, function() {
                    this.issueReason.set('value',data.issueReasonDetail);
                }),20);

                // 選択しているテンプレートIDを設定する。
                topic.publish(module.id + '::selected', data.admNum);
            },

            /**
             * ファイル取り込みダイアログを表示する
             */
            showFileImportDialog: function() {
                this.fileImportDialog.getChildren()[0].initDialog(this._municipalityCd);
                this.fileImportDialog.show();
            },

            onCancelButtonClick: function () {
                Router.moveTo('evacrecommend');
            }
        });
});

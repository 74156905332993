/**
 * 通行規制情報の新規作成・コピー新規作成・続報登録画面用モジュール。
 * @module app/traffic/view/TrafficRegulationRegisterPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/Deferred',
    'dijit/registry',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/Loader',
    'leaflet',
    './_TrafficRegulationDetailPageBase'
], function (module, array, declare, lang, domStyle, topic, Deferred, registry, Locator, Router, USER_TYPE, UserInfo,
    IdisRest, Requester, Loader, leaflet, _TrafficRegulationDetailPageBase) {
    /**
     * 通行規制情報の新規作成・コピー新規作成・続報登録画面。
     *
     * @class TrafficRegulationRegisterPage
     * @extends module:app/traffic/view/_TrafficRegulationDetailPageBase~_TrafficRegulationDetailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _TrafficRegulationDetailPageBase,
        /** @lends module:app/traffic/view/TrafficRegulationRegisterPage~TrafficRegulationRegisterPage# */
        {

            // drawpanelとのpubsub用
            DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
            REMOVE_LAYER: '/app/draw/DrawPanel::removeLayer',

            /**
             * 画面の初期表示項目を設定する。
             * 新規作成・コピー新規作成・続報登録画面で共通。
             */
            initDetailPage: function () {
                this.inherited(arguments);

                var dfd = new Deferred();

                this.form.reset();
                this.trafficAttachments = [];

                // URLから通行規制情報IDを取得
                var trafficRegulationId = Locator.getQuery().id;

                // URLからコピー新規作成フラグ取得
                var copyCreateFlg = Locator.getQuery().copyCreate;

                // URLから続報フラグ取得
                var followUpFlg = Locator.getQuery().followUp;

                // ページのタイトルを設定する
                if (followUpFlg) {
                    this.pageTitle.innerHTML = '更新';
                } else if (copyCreateFlg) {
                    this.pageTitle.innerHTML = '複製';
                } else {
                    this.pageTitle.innerHTML = '新規登録';

                    // 続報番号
                    this.seqNumStr.innerHTML = '1';
                    this.seqNum.set('value', 1);

                    // 最新フラグ
                    this.activeFlg.set('value', '1');
                }

                // 登録ボタンを表示する
                this.registerTrafficRegulationButton.set('style', { display: '' });
                // 更新ボタンを表示しない
                this.updateTrafficRegulationButton.set('style', { display: 'none' });
                // 削除ボタンを表示しない
                this.deleteTrafficRegulationButton.set('style', { display: 'none' });
                // 複製ボタンを表示しない
                this.copyCreateTrafficRegulationButton.set('style', { display: 'none' });
                // 続報登録ボタンを表示しない
                this.createFollowUpTrafficRegulationButton.set('style', { display: 'none' });
                // 復元ボタンを表示しない
                this.restoreTrafficRegulationButton.set('style', { display: 'none' });
                // ユーザー所属の振興局をセットする
                switch (UserInfo.getUserType()) {
                    case USER_TYPE.MUNICIPALITY:
                        Requester.get('/data/municipality/municipalityList.json', { preventCache: false })
                            .then(lang.hitch(this, function (data) {
                                array.forEach(data.municipalities, function (municInfo) {
                                    if (municInfo.municipalityCd === UserInfo.getMunicipalityCd()) {
                                        this.regionCd.set('value', municInfo.regionCd);
                                    }
                                }, this);
                            }));
                        break;
                    case USER_TYPE.REGION:
                        this.regionCd.set('value', UserInfo.getRegionCd());
                        break;
                    default:
                        break;
                }

                if (followUpFlg) {
                    // 続報登録画面では災害は変更不可なのでボタンを非表示にする
                    this.disasterId.set('noAllButton', true);
                }

                // 参照用ユーザは添付ファイルのアップローダを表示しない
                if (UserInfo.getAcl().WRITE) {
                    domStyle.set(this.attachFileArea, 'display', '');
                } else {
                    domStyle.set(this.attachFileArea, 'display', 'none');
                }

                // 帳票出力フラグを表示しない
                domStyle.set(this.exportForm, 'display', 'none');
                // 管理番号を表示しない
                domStyle.set(this.admNumForm, 'display', 'none');

                // 通行規制情報の設定
                // 新規作成ではサーバーから情報を取得しない
                // コピー新規作成・続報の場合はサーバーから情報を取得する
                if (followUpFlg || copyCreateFlg) {
                    // サーバーから通行規制情報を取得する
                    Requester.get('/api/traffic/regulations/' + trafficRegulationId)
                        .then(lang.hitch(this, function (item) {

                            console.debug(item);

                            // 報告番号の制御
                            if (copyCreateFlg) {
                                // コピー新規作成時は報告番号は1
                                item.seqNum = 1;
                            } else {
                                // 続報登録時は+1する
                                item.seqNum = item.seqNum + 1;
                            }
                            this.seqNumStr.innerHTML = item.seqNum;

                            // 道路種別が設定されている場合
                            if (item.roadTypeCd) {
                                // 道路種別をセットする
                                this.roadTypeCd.set('value', item.roadTypeCd);
                                // 道路種別がその他の場合はその他入力欄に路線名を設定
                                if (this.roadTypeCd.get('value') === '99') {
                                    // 路線名セレクトボックスを非表示
                                    this.roadName.set('style', {
                                        display: 'none'
                                    });
                                    // 路線名その他入力欄を表示
                                    this.roadNameOther.set('style', {
                                        display: ''
                                    });
                                    // その他入力欄に路線名を設定
                                    this.roadNameOther.set('value', item.roadName);
                                    // 道路種別、路線名は入力したので削除
                                    delete item.roadTypeCd;
                                    delete item.roadName;
                                    // その他の通行規制情報を画面上のフォームにセットする
                                    this.form.set('value', item);
                                    // 地図情報を読み込んで書く
                                    this.getLayerJsonAndDraw(item).then(lang.hitch(this, function () {
                                        dfd.resolve();
                                    }));
                                    // 道路種別がその他以外の場合
                                } else {
                                    // 路線名セレクトボックスを表示
                                    this.roadName.set('style', {
                                        display: ''
                                    });
                                    // 路線名その他入力欄を非表示
                                    this.roadNameOther.set('style', {
                                        display: 'none'
                                    });
                                    // 路線名のoptionsを作る
                                    this.initRoadNameList(item.roadTypeCd).then(lang.hitch(this, function () {
                                        // 路線名をセットする
                                        this.roadName.set('value', item.roadName);
                                        // 地図上に路線を表示する
                                        this.drawRoadByName(item.roadName).then(lang.hitch(this, function () {
                                            // 道路種別、路線名は入力したので削除
                                            delete item.roadTypeCd;
                                            delete item.roadName;
                                            // その他の通行規制情報を画面上のフォームにセットする
                                            this.form.set('value', item);
                                            // 地図情報を読み込んで書く
                                            this.getLayerJsonAndDraw(item).then(lang.hitch(this, function () {
                                                dfd.resolve();
                                            }));
                                        }));
                                    }));
                                }
                                // 道路種別が設定されていない場合
                            } else {
                                // その他の通行規制情報を画面上のフォームにセットする
                                this.form.set('value', item);
                                // 地図情報を読み込んで書く
                                this.getLayerJsonAndDraw(item).then(lang.hitch(this, function () {
                                    dfd.resolve();
                                }));
                            }

                            // 対応状況をactionTimestampでソートしてからメモリーストアにセットする
                            if (item.trafficActions) {
                                item.trafficActions.sort(function (a, b) {
                                    if (a.actionTimestamp < b.actionTimestamp) {
                                        return -1;
                                    }
                                    if (a.actionTimestamp > b.actionTimestamp) {
                                        return 1;
                                    }
                                    return 0;
                                }).forEach(function (object) {
                                    // 削除済みでない場合のみセットする
                                    if (object.delFlg !== '1') {
                                        // コピー新規作成・続報登録では対応状況は作り直しなので、trafficActionIdを削除する
                                        delete object.trafficActionId;
                                        this.trafficActionStore.add(object);
                                    }
                                }, this);
                            }

                            // 添付ファイルを設定
                            if (item.trafficAttachments && item.trafficAttachments.length !== 0) {
                                item.trafficAttachments.forEach(function (object) {
                                    // 削除済みでない場合のみセットする
                                    if (object.delFlg !== '1') {
                                        // 添付ファイルの配列に追加
                                        this.trafficAttachments.push(object);
                                        // プレビューを表示
                                        this.showPreview(object);
                                    }
                                }, this);
                            } else {
                                // 添付ファイルがない場合で、参照用ユーザの場合は、メッセージを表示
                                if (UserInfo.getAcl().WRITE) {
                                    domStyle.set(this.attachFileAreaDummy, 'display', 'none');
                                } else {
                                    domStyle.set(this.attachFileAreaDummy, 'display', '');
                                }
                            }

                        }), function (error) {
                            console.debug(error);
                            dfd.reject();
                        });
                } else {
                    dfd.resolve();
                }
                return dfd;
            },

            /**
             * 続報登録・コピー新規作成表示時、前報の添付ファイルをコピーする
             **/
            copyAttachment: function (item) {

                var url = '/api/traffic/attachments/' + item.trafficAttachmentId;

                Requester.post(url).then(lang.hitch(this, function (data) {
                    // 添付ファイルの配列に追加
                    this.trafficAttachments.push(data);
                    // プレビューを表示
                    this.showPreview(data);
                }), function (error) {
                    console.debug(error);
                });

            },

            /**
             * キャンセルボタンを押したときに呼ばれる。
             */
            leave: function () {
                //作図イベントを破棄
                topic.publish(this.DRAW_EVT_OFF);
                //一覧画面に移動
                Router.moveTo('traffic');
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                this.inherited(arguments);

                // 初期ロード中フラグをtrueにする
                this.isInStartup = true;
                // 画面の初期表示
                this.initDetailPage().then(lang.hitch(this, function () {
                    // 詳細画面で利用するTipsをセットする
                    // this.setTipsPopups();
                    // 規制要因によって入力項目を変更
                    this.updateSectionInput();
                    // 規制区間がある場合は地図を移動
                    if (this.regulatedLayers.length !== 0) {
                        var group = new leaflet.featureGroup(this.regulatedLayers);
                        this.map.fitBounds(group.getBounds(), { padding: [50, 50] });
                        // 規制区間がなく被災区間がある場合は地図を移動
                    } else if (this.damagedLayer) {
                        this.map.panTo(this.damagedLayer.getLatLng());
                        // 規制区間がなく工事区間がある場合は地図を移動
                    } else if (this.constructedLayer) {
                        this.map.fitBounds(this.constructedLayer, { padding: [50, 50] });
                    }
                    // 地図上の通行規制関連ボタンの表示状態を更新する
                    this.updateTrafficButtons();
                    // 災害名を設定する
                    this.updateDisasterInput();
                    // 規制解除予定日時の入力可/不可を設定
                    this.updateRegPlanedEndTimestampInput();
                    // 戸数の入力可/不可を設定
                    this.updateIsolatedHouseCountInput();
                    // 被害人数の入力可/不可を設定
                    this.updateHumanDmgCountInput();
                    // 処理が完了したら、遅延を入れてから、初期ロードフラグをfalseにする
                    setTimeout(lang.hitch(this, function () {
                        this.isInStartup = false;
                    }), 1000);
                }));

                // レイアウト崩れ対策のためリサイズを実施
                this.borderContainer.resize();

            }
        });
});

/**
 * 流域平均雨量共通カラム一覧
 * @module app/riverBasin/grid/RiverBasinCommonColumns
 */
define([
    'app/view/grid/CommonColumn',
    'idis/view/grid/helper'
], function(CommonColumn, helper) {
    /**
     * 基準値超過フラグを返す
     * @param {*} value     観測値
     * @param {*} type      観測種別
     */
    var getExcess = function(value, type){
        switch (type) {
            case '10min':
                if(value>=30){ return 3;}
                if(value>=20){ return 2;}
                if(value>=10){ return 1;}
            break;
            case 'cumulative':
                if(value>=250){ return 3;}
                if(value>=200){ return 2;}
                if(value>=150){ return 1;}
            break;
        }
    };
    return {
        rainfall10Min: helper.column('rainfall10Min', '10分雨量', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall10Min');
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall10Min',
                    getExcess(item.rainfall10Min, '10min'));
            },
            sortable: false }),
        rainfall60Min: helper.column('rainfall60Min', '流域平均雨量[mm/h]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall60Min', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall60Min',
                    getExcess(item.rainfall60Min, '60min'));
            },
            sortable: false }),
        rainfallHourly: helper.column('rainfallHourly', '時間雨量', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfallHourly');
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfallHourly',
                    getExcess(item.rainfallHourly, '60min'));
            },
            sortable: false }),
        rainfallCumulative: helper.column('rainfallCumulative', '累加雨量', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfallCumulative');
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfallCumulative',
                    getExcess(item.rainfallCumulative, 'cumulative'));
            },
            sortable: false })
    };
});


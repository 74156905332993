/**
 * クリック可能なボタンを持つTreeNode拡張
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-class',
    'dijit/Tree',
    'idis/view/form/Button',
    // 以下、変数で受けないモジュール
], function(module, declare, domClass, Tree, Button) {
    /**
     * クリック可能なボタンを持つTreeNode拡張
     * @class LayerPaneTreeNode
     */
    return declare(module.id.replace(/\//g, '.'), Tree._TreeNode, {
        /**
         * 現在詳細ペインに表示されているかどうか
         * @type {boolean}
         * @private
         */
        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 情報種別コードに応じて追加CSSクラスを付与（現地写真の表示制御等に利用）
            domClass.add(this.domNode, 'map-TreeNode-VectorTile');
            // 情報種別コードに対応するウィジェットがあれば詳細ボタンを設置
            this.detailButton = new Button({
                // 現在の表示状態によってラベルを設定
                label: '<img src="../../images/draw/settings.png" width="15px" height="15px">',
                style: 'display:none;'
            });
            this.detailButton.placeAt(this.contentNode);
        }
    });
});


/**
 * 「災害概況帳票出力」画面用のモジュール
 */
define([
    'module',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/text!./templates/DisasterOverviewPage.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/Button',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput'
], function(module, lang, Deferred, JSON, declare, template, Requester,
            DialogChain, InfoDialog, _PageBase, Loader, DisasterInfo) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 災害発生期間(開始)
         */
        _startTimestamp: null,

        /**
         * PDFファイルPATH
         */
        PDF_PATH: '/data/manual/',

        /**
         * ダウンロードファイル名
         * code
         * 01:災害概況(xlsx)
         * 02:災害概況(pdf)
         */
        _fileMappin: [
            {
                'code' : '01',
                'reqid' : 'DISASTER_OVERVIEW_NO7-1_EX',
                'fileName' : 'DisasterOverview.xlsx',
                'fileNameJp' : '災害概況.xlsx'
            },
            {
                'code' : '02',
                'reqid' : 'DISASTER_OVERVIEW_NO7-1_EX',
                'fileName' : 'DisasterOverview.pdf',
                'fileNameJp' : '災害概況.pdf'
            }
        ],

        constructor: function(){
            this.chain = DialogChain.get(this);

            // 災害IDを設定
            this._disasterId = DisasterInfo.getDisasterId();

            // apiから_disasterId の災害の発生期間(開始)を取得する
            Requester.get('/api/disasters/' + this._disasterId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }) .then(lang.hitch(this, function(res) {
                this._startTimestamp = res.startTimestamp;
            }));
        },

        // DOM要素を構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
        },

        // Excel出力ボタン押下時の処理
        onExcelOutputButtonClick: function() {
            this.downloadFile('01');
            return false;
        },

        // PDF出力ボタン押下時の処理
        onPDFOutputButtonClick: function() {
            this.downloadFile('02');
            return false;
        },

        // ファイルダウンロードの処理
        downloadFile: function(fileType){

            // 日付が入力されていなければダイアログ表示を行う
            if(!this.form.validate() ||
                !this.repdatetime._date.displayedValue || !this.repdatetime._time.displayedValue){
                this.chain.info('日時が選択されていません。', 'エラー');
                return false;
            }

            // 災害の発生期間(開始) をUnix時刻から YYYY/MM/DDHH:mm:SSに変換
            var timestamp = new Date(this._startTimestamp);
            var year = timestamp.getFullYear();
            var month = timestamp.getMonth()+1 < 10 ? ('0' + (timestamp.getMonth()+1)): (timestamp.getMonth()+1);
            var date = timestamp.getDate() < 10 ? ('0' + timestamp.getDate()): timestamp.getDate();
            var hour = timestamp.getHours() < 10 ? ('0' + timestamp.getHours()): timestamp.getHours();
            var minute = timestamp.getMinutes() < 10 ? ('0' + timestamp.getMinutes()): timestamp.getMinutes();
            var second = timestamp.getSeconds() < 10 ? ('0' + timestamp.getSeconds()): timestamp.getSeconds();
            var startDatetime = year + '/' + month + '/' + date + hour + ':' + minute + ':' + second;

            // 集計時刻を YYYY/MM/DDHH:mm:SS に変換
            var endDatetime = this.repdatetime._date.displayedValue + this.repdatetime._time.displayedValue;

            // xoblosの各種設定と紐づくID・ファイル名
            var fileInfs = this._fileMappin.filter(function(item){
                if(item.code===fileType) { return true; }
            });

            var reqid = fileInfs[0].reqid;
            var fileName = fileInfs[0].fileName;
            var paramList = [
                {key: 'disid', value: this._disasterId},
                {key: 'startDatetime', value: startDatetime},
                {key: 'endDatetime', value: endDatetime}
            ];
            var type = fileType === '01' ? 'excel' : 'pdf';

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList,
                fileType: type
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        download: function(data){
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            var self = this;
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                // ファイル名を取得
                var baseFileName = self._fileMappin.filter(function(item){
                    if(item.fileName===inputFileName) { return true; }
                })[0].fileNameJp;
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        }
    });
});

/**
 * 災害体制の列定義一覧。
 * @module app/disasterprevention/view/EmployGatheringColumn
 */
define([
    'idis/view/form/DateTimeInput',
    'dijit/form/ValidationTextBox',
    'dojo/when',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'app/disasterprevention/view/model/PrefPromotionBureau',
    'app/model/Municipality',
    'app/config',
    'idis/model/UserInfo'
], function(DateTimeInput, ValidationTextBox, when,
        helper, LazyNode, PrefPromotionBureau, Municipality, config) {
    // 編集対象列用のオプション
    var editorColumn4num = {
        sortable: false,
        className: 'gathringNum',
        editor: ValidationTextBox,
        autoSave: true,
        editorArgs: {
            pattern: '(0|[0-9]{1,10})',
            invalidMessage: '10桁以内の整数を入力してください',
            style: 'width:auto;',
            type: 'number'
        },
        formatter: function(data){
            return data.toLocaleString();
        }
    };
    var editorColumn4text = {
        sortable: false,
        editor: ValidationTextBox,
        autoSave: true,
        editorArgs: {
            maxLength: 256,
            style: 'width:30em;'
        },
        formatter: function(data){
                return data.toLocaleString();
        }
    };
    var getMunicipalityLazyNode = function(municipalityCd){
        var itemPromise = Municipality.store.get(municipalityCd);
        return new LazyNode({
            promise: when(itemPromise).then(function(item) {
                return Municipality.getLabel(item);
            })
        });
    };
    var getPrefPromotionBureauLazyNode = function(regionCd){
        var itemPromise = PrefPromotionBureau.store.get(regionCd);
        return new LazyNode({
            promise: when(itemPromise).then(function(item) {
                return PrefPromotionBureau.getLabel(item);
            })
        });
    };
    var regionNameCell = function(item, value, node) {
        // 県の場合は、'鳥取県'を返す
        if(item.municipalityCd === config.municInfo.prefMunicCd){
            node.innerText = '鳥取県';
            return;
        }
        var lazyNode = getPrefPromotionBureauLazyNode(item.municipalityCd);
        return lazyNode.domNode;
    };
    return {
        gathringNum: helper.column('gathering', '参集人数', {
            sortable: false,
            children: [
                helper.column('gatherDisaster', '災害待機', editorColumn4num),
                helper.column('gatherFlood', '水防待機', editorColumn4num)
            ]
        }),
        gatherDisaster: helper.column('gatherDisaster', '参集人数', editorColumn4num),
        municipalityName: {
            field: 'municipalityCd',
            label: '市町',
            sortable: false,
            renderCell: function(item) {
                var lazyNode = getMunicipalityLazyNode(item.municipalityCd);
                return lazyNode.domNode;
            }
        },
        areaName: {
            field: 'areaCd',
            label: '県・振興局',
            sortable: false,
            renderCell: regionNameCell
        },
        reportTimestamp:  {
            field: 'reportTimestamp',
            label: '報告日時',
            renderCell: function(item) {
                var widget = new DateTimeInput({
                    required: true,
                    now: true,
                    name: 'reportTimestamp',
                    value: item.reportTimestampMS || ' '
                });
                widget._date.on('change', function() {
                    item.reportTimestampMS = widget._date.isValid()||widget._time.isValid() ?
                        Date.parse(widget._date.displayedValue + ' ' + widget._time.displayedValue) : null;
                });
                widget._time.on('change', function() {
                    item.reportTimestampMS = widget._date.isValid()||widget._time.isValid() ?
                        Date.parse(widget._date.displayedValue + ' ' + widget._time.displayedValue) : null;
                });
                widget.startup();
                return widget.domNode;
            },
            sortable: false
        },
        comment: helper.column('comment', '参集者', editorColumn4text)
    };
});

/**
 * 招集状況管理画面用モジュール。
 *
 * @module app/convocation/view/mail/ConvoStatusDetailPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/number',
        'dojo/text!./templates/ConvoStatusDetailPage.html',
        'dojox/charting/Chart',
        'idis/view/draw/StackedBars',
        'idis/view/page/_PageBase',
        'idis/view/dialog/DialogChain',
        'idis/store/IdisRest',
        'idis/control/Locator',
        'idis/service/Requester',
        'idis/control/Router',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/convocation/view/mail/ConvoStatusDetailGrid',
        'app/convocation/view/mail/ConvoDivisionStatusDetailGrid'
], function(module, declare, lang, locale, domStyle, number, template, Chart, StackedBars,
        _PageBase, DialogChain, IdisRest, Locator, Requester, Router) {
    /**
     * 招集状況管理画面。
     *
     * @class ConvoStatusDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
    /** @lends module:app/convocation/view/mail/ConvoStatusDetailPage# */

        store: null,
        divisionStore: null,

        // グリッド更新したときのDateTime
        // チャートを表示するときに表示するため保持する
        gridUpdDatetime: null,

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        _groupName: null,

        displayStatus: 0,

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // URLから配信IDを取得
            this.distributionHistId = Locator.getQuery().id;
            var url = '/api/convoMails/mails/list/';
            url += this.distributionHistId;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'id',
                target: url
            });
            var divisionUrl = '/api/convoMails/mails/list/division/' + this.distributionHistId;
            this.divisionStore = new IdisRest({
                idProperty: 'id',
                target: divisionUrl
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            this.display = Locator.getQuery().display;
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.initPage();
            this.initConvoStatusDetailGrid();
            this.initConvoDivisionStatusDetailGrid();
            // ここでdisplayDivisionかdisplayGroupを実行すると、エラーになる
            if (!this.display || this.display==='group') {
                this.updateChart('全体', 0);
            } else {
                this.updateDivisionChart('全体', '0');
            }
        },

        /**
         * ページの初期化。
         */
        initPage: function() {
            var now = new Date();
            this.aggregateDateTime._date.set('value', now);
            this.aggregateDateTime._time.set('value', now);
            var url = '/api/convoMails/mails/forms/';
            url += this.distributionHistId;
            Requester.get(url).then(lang.hitch(this, function(data) {
                var item = data;

                var date = locale.parse(data.distributeTimeStamp, {
                    datePattern: 'yyyy-MM-dd',
                    timePattern: 'HH:mm:ss'
                });
                this.distributeTimestamp.innerText = locale.format(date, {
                    datePattern: 'yyyy/MM/dd',
                    timePattern: 'HH:mm:ss'
                });
                this.convoTitle.innerText = item.distTitle;
            }), lang.hitch(this, function() {
                this.chain.info('情報の取得に失敗しました。', 'エラー');
            }));
        },

        /**
         * gridの初期化。
         */
        initConvoStatusDetailGrid: function() {
            this.convoStatusDetailGrid.on('.dgrid-row:click', lang.hitch(this, function(evt) {
                var item = this.convoStatusDetailGrid.row(evt).data;
                this.updateChart(item.groupName, item.id);
            }));
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.convoStatusDetailGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.moveStatusDetailPage(evt.item);
            }));
            this.updateConvoStatusDetailGrid();
        },

        /**
         * gridの初期化。
         */
        initConvoDivisionStatusDetailGrid: function() {
            this.convoDivisionStatusDetailGrid.on('.dgrid-row:click', lang.hitch(this, function(evt) {
                var item = this.convoDivisionStatusDetailGrid.row(evt).data;
                this.updateDivisionChart(item.groupName, item.id);
            }));
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.convoDivisionStatusDetailGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.moveDivisionStatusDetailPage(evt.item);
            }));
            this.updateConvoDivisionStatusDetailGrid();
        },

        /**
         * gridの更新。
         */
        updateConvoStatusDetailGrid: function() {
            // 招集状況をgridにセット
            var filter = new this.store.Filter();
            var datetime = new Date(this.form.get('value').aggregateDateTime);
            datetime.setSeconds(59);
            this.gridUpdDatetime = datetime;
            filter = filter.eq('timestamp', datetime.getTime());
            var collection = this.store.filter(filter);
            this.convoStatusDetailGrid.set('collection', collection);
        },

        /**
         * gridの更新。
         */
        updateConvoDivisionStatusDetailGrid: function() {
            // 招集状況をgridにセット
            var filter = new this.divisionStore.Filter();
            var datetime = new Date(this.form.get('value').aggregateDateTime);
            datetime.setSeconds(59);
            this.gridUpdDatetime = datetime;
            filter = filter.eq('timestamp', datetime.getTime());
            var collection = this.divisionStore.filter(filter);
            this.convoDivisionStatusDetailGrid.set('collection', collection);
        },

        createChart: function(data) {
            this.lblContent0.innerHTML = '';
            this.lblContent1.innerHTML = '';
            this.lblContent2.innerHTML = '';
            this.lblContent3.innerHTML = '';
            this.lblContent4.innerHTML = '';
            this.chartNode0.innerHTML = '';
            this.chartNode1.innerHTML = '';
            this.chartNode2.innerHTML = '';
            this.chartNode3.innerHTML = '';
            this.chartNode4.innerHTML = '';
            this.chartNode5.innerHTML = '';
            this.chartNode6.innerHTML = '';
            this.chartNode7.innerHTML = '';
            this.chartNode8.innerHTML = '';
            this.chartNode9.innerHTML = '';
            // 隠れているチャートを表示しないとサイズがおかしくなるため
            this.displayAll();
            var questionCount = 0;
            var answerCount = 0;
            var lblContents = [this.lblContent0, this.lblContent1,
                this.lblContent2, this.lblContent3, this.lblContent4];
            var chartNodes = [this.chartNode0, this.chartNode1, this.chartNode2, this.chartNode3, this.chartNode4];
            // パーセンテージ表示用
            var chartNodes2 = [this.chartNode5, this.chartNode6, this.chartNode7, this.chartNode8, this.chartNode9];
            data.questionList.forEach(lang.hitch(this, function(question) {

                // 既にチャートがある場合は廃棄
                this.chart = new Chart(chartNodes[questionCount]);
                this.chart.titlePos = 'top';
                this.chart.titleGap = 20;
                this.chart.titleAlign = 'left';
                this.chart.titleFont = 'normal normal normal 10pt Arial';

                this.chart.addPlot('stackedBarsPlot', {
                    type: StackedBars,
                    gap:6,
                    lines: true,
                    areas: true,
                    markers: true,
                    labels: true,
                    fixed: true,
                    precision: 0,
                    labelStyle:'inside',
                    tension: '2'
                });
                this.chart.addAxis('x', {
                    dropLabels: false,
                    labelSizeChange: true,
                    rotation:0,
                    fixUpper: 'major',
                    majorTicks:true,
                    minorTicks:false,
                    includeZero: true,
                    fontColor: 'black'
                });
                // パーセンテージ用チャート
                this.chart2 = new Chart(chartNodes2[questionCount]);
                this.chart2.titlePos = 'top';
                this.chart2.titleGap = 20;
                this.chart2.titleAlign = 'left';
                this.chart2.titleFont = 'normal normal normal 10pt Arial';

                this.chart2.addPlot('stackedBarsPlot2', {
                    type: StackedBars,
                    gap:6,
                    lines: true,
                    areas: true,
                    markers: true,
                    labels: true,
                    labelStyle:'inside',
                    labelFunc: function(value, fixed, precision) {
                        return number.round(value, precision) + '%';
                    },
                    tension: '2'
                });
                this.chart2.addAxis('x', {
                    dropLabels: false,
                    labelSizeChange: true,
                    rotation:0,
                    majorTickStep:5,
                    majorTicks:true,
                    minorTicks:false,
                    includeZero: true,
                    to: 100.0,
                    fontColor: 'black'
                });
                answerCount = 0;
                var colorCodes = ['#00B0F0', '#92D050', '#FFFF00', '#FFAEAE', '#FFEC94'];
                var html = [];
                var unanswered = data.count;
                question.answers.forEach(lang.hitch(this, function(answer) {
                    var colorCode = colorCodes[answerCount];
                    html.push('<label style="text-shadow: 0px 0px 2px black;">' +
                            '<font size="5" color=' + colorCode + '>■</font></label>');
                    html.push('<label>' + answer.content + '</label>');
                    if (data.response[questionCount][answerCount] !== 0){
                        this.chart.addSeries(answer.content,
                        {
                            data: [data.response[questionCount][answerCount]],
                            label: 'a'
                        } ,
                        {
                            plot: 'stackedBarsPlot',
                            stroke: {
                                color: '#FFFFFF'
                            },
                            fill: colorCode
                        });

                        this.chart2.addSeries(answer.content,
                        {
                            data: [data.response[questionCount][answerCount]*100.0/data.count],
                            label: 'a'
                        } ,
                        {
                            plot: 'stackedBarsPlot2',
                            stroke: {
                                color: '#FFFFFF'
                            },
                            labelOffset: 25,
                            fill: colorCode
                        });
                    }
                    html.push('<label>&nbsp;&nbsp;/&nbsp;&nbsp;</label>');
                    unanswered -= data.response[questionCount][answerCount];
                    answerCount++;
                }));
                html.push('<label style="text-shadow: 0px 0px 2px black;">' +
                '<font size="5" color="#D3D3D3">■</font></label>');
                html.push('<label>未回答</label>');
                if (unanswered > 0) {
                    this.chart.addSeries('未回答',
                    {
                        data: [unanswered],
                        label: 'a'
                    } ,
                    {
                        plot: 'stackedBarsPlot',
                        stroke: {
                            color: '#FFFFFF'
                        },
                        fill: '#D3D3D3'
                    });
                    this.chart2.addSeries('未回答',
                    {
                        data: [unanswered*100.0/data.count],
                        label: 'a'
                    } ,
                    {
                        plot: 'stackedBarsPlot2',
                        stroke: {
                            color: '#FFFFFF'
                        },
                        labelOffset: 25,
                        fill: '#D3D3D3'
                    });
                }
                lblContents[questionCount].innerHTML = '質問' + (questionCount + 1).toString(10) +
                        ':' + question.distributionQContent +
                        '<br>' + html.join('');
                this.chart.render();
                this.chart2.render();
                questionCount++;
            }));
        },

        /**
         * 回答状況一覧の更新を行うメソッド
         */
        updateChart: function(groupName, groupCd){
            this.groupName.innerText = groupName;
            var url = '/api/convoMails/mails/forms/';
            url += this.distributionHistId;
            url += '/' + groupCd;
            Requester.get(url + '?timestamp=' + this.gridUpdDatetime.getTime()
            ).then(lang.hitch(this, function(data) {
                this.answer.innerText = data.answer;
                this.count.innerText = data.count;
                this.createChart(data);
                if (this.displayStatus === 0) {
                    this.displayCount();
                } else {
                    this.displayPercentage();
                }
                // Gridの更新
            }), lang.hitch(this, function() {
                this.chain.info('情報の取得に失敗しました。', 'エラー');
            }));
        },

        /**
         * 回答状況一覧の更新を行うメソッド
         */
        updateDivisionChart: function(divisionName, divisionCd){
            this.groupName.innerText = divisionName;
            var url = '/api/convoMails/mails/forms/';
            url += this.distributionHistId;
            url += '/division/' + divisionCd;
            Requester.get(url + '?timestamp=' + this.gridUpdDatetime.getTime()
            ).then(lang.hitch(this, function(data) {
                this.answer.innerText = data.answer;
                this.count.innerText = data.count;
                this.createChart(data);
                if (this.displayStatus === 0) {
                    this.displayCount();
                } else {
                    this.displayPercentage();
                }
                // Gridの更新
            }), lang.hitch(this, function() {
                this.chain.info('情報の取得に失敗しました。', 'エラー');
            }));
        },

        /**
         * 職員招集状況詳細への遷移を行うメソッド
         */
        moveStatusDetailPage: function(item){
            // Gridの情報(item)が引数となっている
            var groupCd = item.id;
            // メールのIDと職員のIDをURLに追加し、管理ページへ遷移
            Router.moveTo('convocation/statusList',{
                mid : this.distributionHistId,
                gcd : groupCd
            });
        },

        /**
         * 職員招集状況詳細への遷移を行うメソッド
         */
        moveDivisionStatusDetailPage: function(item){
            // Gridの情報(item)が引数となっている
            var divisionCd = item.id;
            // メールのIDと職員のIDをURLに追加し、管理ページへ遷移
            Router.moveTo('convocation/statusList',{
                mid : this.distributionHistId,
                dcd : divisionCd
            });
        },

        /**
         * 検索ボタンが押下された際の挙動。
         */
        onSubmit: function() {
            if (!this.display || this.display==='group') {
                this.updateConvoStatusDetailGrid();
                this.updateChart('全体', 0);
            } else {
                this.updateConvoDivisionStatusDetailGrid();
                this.updateDivisionChart('全体', '0');
            }
            return false;
        },

        /**
         * 帳票出力ボタンが押された際に呼ばれるメソッド
         */
        outputListExcel: function() {
            var url = '/api/convoMails/mails/answerState.csv';
            url += '?distributionHistId=' + this.distributionHistId;

            // 確認ダイアログを表示
            this.chain.confirm('一覧に表示されているデータを出力します。<br>' +
                'よろしいですか？', function(chain) {
                // 確認ダイアログでOKを押した場合
                chain.hide();
                // URLを遷移する
                window.location.href=url;
            });
        },

        /**
         * 再読込ボタンを押下したときの挙動。
         */
        onReloadButton: function() {
            // ページのリロード
            window.location.reload();
        },

        /**
         * 回答数を表示
         */
        displayCount: function() {
            this.displayStatus = 0;
            ['displayPercentageButton', 'countLabel', 'chartNode0',
             'chartNode1', 'chartNode2', 'chartNode3', 'chartNode4'
            ].forEach(function(id) {
                domStyle.set(id, 'display', '');
            });
            ['displayCountButton', 'percentageLabel', 'chartNode5',
             'chartNode6', 'chartNode7', 'chartNode8', 'chartNode9'
            ].forEach(function(id) {
                domStyle.set(id, 'display', 'none');
            });
        },

        /**
         * 回答数の割合(%)を表示
         */
        displayPercentage: function() {
            this.displayStatus = 1;
            ['displayCountButton', 'percentageLabel', 'chartNode5',
             'chartNode6', 'chartNode7', 'chartNode8', 'chartNode9'
            ].forEach(function(id) {
                domStyle.set(id, 'display', '');
            });
            ['displayPercentageButton', 'countLabel', 'chartNode0',
             'chartNode1', 'chartNode2', 'chartNode3', 'chartNode4'
            ].forEach(function(id) {
                domStyle.set(id, 'display', 'none');
            });
        },

        /**
         * すべてのチャートを表示
         */
        displayAll: function() {
            ['chartNode0', 'chartNode1', 'chartNode2', 'chartNode3', 'chartNode4',
            'chartNode5', 'chartNode6', 'chartNode7', 'chartNode8', 'chartNode9'
            ].forEach(function(id) {
                domStyle.set(id, 'display', '');
            });
            if (!this.display || this.display==='group') {
                this.displayGroup();
            } else {
                this.displayDivision();
            }
        },

        displayDivision: function() {
            if (this.display !== 'division') {
                this.display = 'division';
                Router.moveTo('convocation/statusDetail',{
                    id : this.distributionHistId,
                    display : this.display
                });
            }
            domStyle.set('displayDivisionButton', 'display', 'none');
            domStyle.set('displayGroupButton', 'display', '');
            domStyle.set('convoStatusDetailGrid', 'height', '0px');
            domStyle.set('convoDivisionStatusDetailGrid', 'height', '93%');
        },

        displayGroup: function() {
            if (this.display !== 'group') {
                this.display = 'group';
                Router.moveTo('convocation/statusDetail',{
                    id : this.distributionHistId,
                    display : this.display
                });
            }
            domStyle.set('displayDivisionButton', 'display', '');
            domStyle.set('displayGroupButton', 'display', 'none');
            domStyle.set('convoStatusDetailGrid', 'height', '93%');
            domStyle.set('convoDivisionStatusDetailGrid', 'height', '0px');
        }
    });
});

/**
 * キキクル危険度レベル
 * @module app/model/KikikuruRiskLevel
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        idProperty: 'level',
        data: [
            {
                level: "5",
                label: "災害切迫"
            }, {
                level: "4",
                label: "危険"
            }, {
                level: "3",
                label: "警戒"
            }, {
                level: "2",
                label: "注意"
            }
        ]
    });
});
/**
 * <通知グループ詳細画面>
 * @module app/broadnotify/BroadnotifyGroupDetailPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/BroadnotifyGroupDetailPage.html', // テンプレート文字列
    'idis/control/Router',
    'idis/store/IdisRest',
    'dstore/Memory',
    'idis/service/Requester',
    'idis/control/Locator',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    './_BroadnotifyGroupPageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/broadnotify/BroadnotifyGroupTerminalsGrid',
    'app/broadnotify/BroadnotifyAddGroupDialog'
], function(module, declare, template, Router,
    IdisRest, Memory, Requester, Locator, ACL, Loader, DialogChain, _BroadnotifyGroupPageBase) {
    /**
     * 通知グループ詳細画面
     * @class BroadnotifyGroupDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BroadnotifyGroupPageBase,
        /** @lends module:app/broadnotify/BroadnotifyGroupDetailPage~BroadnotifyGroupDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            constructor: function() {
                this.store = new Memory({
                    idProperty: 'terminalId'
                });
    
                this.groupId = Locator.getQuery().id;

                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
                this.initForm();
                this.initConvoAutoMailRegisterGrid();
                this.initSelectGroupDialog();
                this.own(this.selectGroupDialog);
            },

            /**
             * フォームを初期化する。
             */
            initForm: function() {
                var self = this;
                Requester.get('/api/broadnotify/group/' + this.groupId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    self.initFormValue(data);
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    self.chain.infoError(err);
                });

                // 通知対象ユーザ情報の取得
                Requester.get('/api/broadnotify/group/terminals/' + this.groupId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                })
                .then(function(data) {
                    // グリッドに一件ずつ追加
                    data.items.forEach(function(item) {
                        self.addDistGroupInfo({
                            id: '' + item.broadnotifyTerminalId,
                            name: item.terminalName
                        });

                    });
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    self.chain.infoError(err);
                });
            },

            /**
             * 詳細情報を設定する
             */
            initFormValue: function(data) {
                this.form.set('value', data);
            },

            // 更新ダイアログを表示する
            onSubmit: function() {

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                if(!this.validate()){
                    return false;
                }

                this.chain.confirmPut(function(chain) {
                    var sendData = this.form.get('value');
                    sendData.broadnotifyGroupId = this.groupId;

                    sendData.terminalList = this.getGroupCdList();

                    var promise = Requester.put('/api/broadnotify/group/' + this.groupId,{
                        data: sendData
                    }).then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('broadnotify/group');
                        });
                    }, function(error) {
                        // 失敗時
                        console.error(error);
                        chain.infoError(error);
                    });
                    Loader.wait(promise);
                });
            },

            deleteBroadnotifyGroup: function(){
                // 削除確認ダイアログの表示
                var messege = '削除します。よろしいですか？<br>' + '対象グループが0件となった配信テンプレートは合わせて削除されます。';
                this.chain.confirm(messege, function(chain) {
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    var promise = Requester.del('/api/broadnotify/group/' + this.groupId)
                    .then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('broadnotify/group');
                        });
                    }, function(err) {
                        // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                        console.error(err);
                        chain.infoError(err);
                    });
                    // ローダの表示
                    Loader.wait(promise);
                });

            }
        
    });
});

/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterOpeningGrid.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-class',
    'dijit/registry',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'dstore/Memory',
    'dijit/form/CheckBox',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'idis/model/UserInfo',
    // 以下、変数で受けないモジュール
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'dijit/layout/BorderContainer'
], function (module, declare, lang, dom, domClass,
    registry, df, IdisGrid, Memory, CheckBox, Selection, Selector, Keyboard, UserInfo) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard],
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {

            selectionMode: 'none',
            allowSelectAll: true,

            rowClassName: function (item) {
                return item && (item.availableFlg === '0') ? 'gray-row' : '';
            },

            // ユーザーの市町村内の避難所のみを登録可能としている
            renderRow: function (item) {
                var userMunicipalityCds = UserInfo.getMunicipalityCds();
                var div = this.inherited(arguments);
                // デフォルトではチェックボックスを非活性に
                var inputtags = div.getElementsByTagName('input');
                for (var j = 0; j < inputtags.length; j++) {
                    inputtags[j].disabled = true;
                }
                // ユーザーの市町内の避難所かつ、避難所の利用可否フラグが１である場合、チェックボックスを活性に
                userMunicipalityCds.forEach(function (value) {
                    if (item.municipalityCd === value && item.availableFlg === '1') {
                        for (var i = 0; i < inputtags.length; i++) {
                            inputtags[i].disabled = false;
                        }
                    }
                });

                // rowClassNameが指定されている場合
                if (this.rowClassName) {
                    var className = this.rowClassName;
                    // 関数の場合は行データを与えて実行する
                    if (lang.isFunction(className)) {
                        className = className(item);
                    }
                    // 偽値でなければCSSクラスとして追加
                    if (className) {
                        domClass.add(div, className);
                    }
                }
                return div;
            },

            // SelectorのallowSelectAllオプションがエラーとなるので、
            // 仮の値としてメモリーストアをおく
            constructor: function () {
                this.collection = new Memory();
            },
            columns: [
                // チェックボックス
                {
                    field: 'checkbox', label: 'checkbox',
                    selector: 'checkbox'
                },
                // {field: 'municipalityName', label: '市町村', sortable: false},
                { field: 'districtName', label: '地区', sortable: false },
                { field: 'facilityName', label: '避難所', sortable: false },
                { field: 'address', label: '住所', sortable: false },
                {
                    field: 'capacity', label: '収容人数', sortable: false,
                    formatter: lang.hitch(this, function (item) {
                        if (item === null) {
                            return '-';
                        }
                        return item;
                    })
                },
                {
                    field: 'shelterCategory', label: '避難所区分', sortable: false,
                    formatter: lang.hitch(this, function (value, item) {
                        var shelterCategory = '';
                        if (item.edesignatedEvacShFlg === '1') {
                            shelterCategory += '指定緊急 ';
                        }
                        if (item.designatedEvacShFlg === '1') {
                            shelterCategory += '指定 ';
                        }
                        if (item.welfareEvacShFlg === '1') {
                            shelterCategory += '福祉　';
                        }
                        if (item.civilProtectionEvacShFlg === '1') {
                            shelterCategory += '国民保護';
                        }
                        if (item.temporaryEvacShFlg === '1') {
                            shelterCategory += 'その他';
                        }

                        return shelterCategory;
                    })
                },
                {
                    field: 'shelterType', label: '対象災害', sortable: false,
                    formatter: lang.hitch(this, function (value, item) {
                        var shelterType = '';
                        if (item.floodShFlg === '1') {
                            shelterType += '洪水&nbsp';
                        }
                        if (item.tsunamiShFlg === '1') {
                            shelterType += '津波&nbsp';
                        }
                        if (item.earthquakeShFlg === '1') {
                            shelterType += '地震&nbsp';
                        }
                        if (item.stormSurgeShFlg === '1') {
                            shelterType += '高潮&nbsp';
                        }
                        if (item.sedimentDisasterShFlg === '1') {
                            shelterType += '土砂&nbsp';
                        }
                        if (item.fireShFlg === '1') {
                            shelterType += '火災&nbsp';
                        }
                        if (item.landsideWaterShFlg === '1') {
                            shelterType += '内水&nbsp';
                        }
                        if (item.volcanoShFlg === '1') {
                            shelterType += '火山&nbsp';
                        }
                        return shelterType;
                    })
                }
            ],

            // DOMノードを構築する
            buildRendering: function () {
                this.inherited(arguments);
                domClass.add(this.domNode, 'evacorder-shelterOpeningGrid');
            },

            /**
             * 選択した施設のID一覧を返す。
             * @returns {string[]} 選択した施設のID一覧
             */
            getSelectedIdList: function () {
                // 値がtrueのものに絞り込む（一度選択したものはfalseが入る）
                var selected = df.filterIn(this.selection, function (v) { return v; });
                // 残ったキー一覧を返す
                return df.keys(selected);
            }
        });
});
